import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';

import Button, { ButtonSizeEnum } from 'components/stateless/Button/Button';
import GuideMessage from 'components/stateless/GuideMessage/GuideMessage';
import Select from 'components/stateless/Select/Select';
import StepWizard from 'components/stateless/StepWizard/StepWizard';
import TableBody from 'components/stateless/Table/TableBody';
import TableBorder from 'components/stateless/Table/TableBorder';
import type { HeaderType } from 'components/stateless/Table/TableHeader';
import TableHeader from 'components/stateless/Table/TableHeader';
import Td from 'components/stateless/Table/Td';
import TdLink from 'components/stateless/Table/TdLink';
import Tr from 'components/stateless/Table/Tr';
import { HeaderTitle } from 'components/stateless/Title/HeaderTitle';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { ROUTES_AC } from 'constants/routes/anchor';
import getSelectOptions from 'constants/selectOptions';
import type { CURRENCY_TYPE } from 'enums';
import useMounted from 'hooks/useMounted';
import type { AnchorAgreementVOModel } from 'models/vo/AnchorAgreementVO';
import { requestAnchorAgreementListForPartners } from 'utils/http/api/anchor/anchor-agreements';
import useModal from 'utils/modal/useModal';
import { getPaymentSupportText } from 'utils/text';

function AnchorPartnerRegistrationStep1() {
  const { t } = useTranslation(['format']);
  const mounted = useMounted();
  const modal = useModal();
  const [selectedKeyName, setSelectedKeyName] = useState<string>('contractNo');
  const [selectedKeyValue, setSelectedKeyValue] = useState<string>('');
  const [anchorAgreementPageData, setAnchorAgreementPageData] = useState<AnchorAgreementVOModel[]>();

  useEffect(() => {
    if (mounted) {
      fetchAll();
    }
  }, [mounted]);

  async function fetchAll() {
    try {
      const anchorAgreementPageResponse = await requestAnchorAgreementListForPartners({
        contractNo: selectedKeyName === 'contractNo' ? selectedKeyValue : undefined,
        currencyType: selectedKeyName === 'currencyType' ? (selectedKeyValue as CURRENCY_TYPE) : undefined,
        financierName: selectedKeyName === 'financierName' ? selectedKeyValue : undefined,
        branchName: selectedKeyName === 'branchName' ? selectedKeyValue : undefined,
      });

      ReactDOM.unstable_batchedUpdates(() => {
        setAnchorAgreementPageData(anchorAgreementPageResponse);
      });
    } catch (error) {
      modal.show(error);
    }
  }

  const TABLE_HEADERS: HeaderType[] = [
    {
      headerText: t('text:Anchor_Master_Agreement_Number'),
      colWidths: 100,
    },
    {
      headerText: t('text:Automatic_Settlement'),
      colWidths: 100,
    },
    {
      headerText: t('text:Currency'),
      colWidths: 80,
    },
    {
      headerText: t('text:Responsible_Financier_Name'),
      colWidths: 120,
    },
    {
      headerText: t('text:Responsible_Branch_Name'),
      colWidths: 120,
    },
    {
      headerText: t('text:Agreement_Effective_Date'),
      colWidths: 120,
    },
    {
      headerText: t('text:Agreement_Expiration_Date'),
      colWidths: 120,
    },
    {
      headerText: '',
      colWidths: 60,
    },
  ];

  const handleAnchorAgreementSearchSubmit = async (e: any) => {
    e.preventDefault();

    await fetchAll();
  };

  const renderResultTable = (): JSX.Element[] | undefined => {
    return anchorAgreementPageData?.map((item, index) => (
      <Tr key={index}>
        <Td data={item.contractNo} />
        <Td data={getPaymentSupportText(item.paymentSupport)} />
        <Td data={item.currencyType} />
        <Td data={item.financierName} />
        <Td data={item.branchName} />
        <Td data={item.startDate} format="date" />
        <Td data={item.expiryDate} format="date" />
        <TdLink
          path={ROUTES_AC.MANAGE_PARTNER.REGISTRATION_STEP2_BUILD_PATH(item.financierId, item.anchorAgreementId)}
        />
      </Tr>
    ));
  };

  return (
    <>
      <HeaderTitle title={t('text:Partner_Registration')} />
      <div className="content-area">
        <StepWizard
          nth={1}
          title={[t('text:Select_Anchor_Master_Agreement'), t('text:Register_Partner_Information')]}
        />
      </div>

      <GuideMessage
        message={[
          t('text:Below_is_the_list_of_Anchor_Master_Agreements'),
          t('text:Select_the_Anchor_Master_Agreement_that_the_Partners_are_associated_with'),
        ]}
      />
      <div className="content-area">
        <SectionTitle title={t('text:Search_Agreement')} />
        <form>
          <div className="d-flex information-filter mt-0">
            <Select
              name="selectedKey"
              className="information-form__select"
              selectOptions={getSelectOptions<string>('ANCHOR_AGREEMENT_LIST_FOR_PARTNERS_REQUEST')}
              onChange={e => {
                setSelectedKeyName(e.target.value);
              }}
            />
            <input
              type="text"
              placeholder={t('text:Please_type_here')}
              onChange={e => setSelectedKeyValue(e.target.value)}
            />
            <Button size={ButtonSizeEnum.XS} onClick={handleAnchorAgreementSearchSubmit}>
              {t('text:Search')}
            </Button>
          </div>
        </form>
        <TableBorder>
          <TableHeader header={TABLE_HEADERS} />
          <TableBody numOfCol={TABLE_HEADERS.length}>{renderResultTable()}</TableBody>
        </TableBorder>
      </div>
    </>
  );
}

export default AnchorPartnerRegistrationStep1;
