import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Button, { ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import Exporter from 'components/stateless/Exporter/Exporter';
import StatusDescriptionModal from 'components/stateless/Modal/common/status/StatusDescriptionModal';
import Pagination from 'components/stateless/Pagination/Pagination';
import QuestionButton from 'components/stateless/QuestionButton';
import SearchBorder from 'components/stateless/SearchForm/SearchBorder';
import SearchDatePicker from 'components/stateless/SearchForm/SearchDatePicker';
import SearchEmpty from 'components/stateless/SearchForm/SearchEmpty';
import SearchInput from 'components/stateless/SearchForm/SearchInput';
import SearchLabel from 'components/stateless/SearchForm/SearchLabel';
import SearchSelect from 'components/stateless/SearchForm/SearchSelect';
import TableBody from 'components/stateless/Table/TableBody';
import TableBorder from 'components/stateless/Table/TableBorder';
import type { HeaderType } from 'components/stateless/Table/TableHeader';
import TableHeader from 'components/stateless/Table/TableHeader';
import Td from 'components/stateless/Table/Td';
import TdLink from 'components/stateless/Table/TdLink';
import Tr from 'components/stateless/Table/Tr';
import { HeaderTitle } from 'components/stateless/Title/HeaderTitle';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { ROUTES_DE } from 'constants/routes/dealer';
import getSelectOptions from 'constants/selectOptions';
import type { COLLATERAL_TYPE, CURRENCY_TYPE } from 'enums';
import { LOAN_STATUS } from 'enums';
import useMounted from 'hooks/useMounted';
import usePageable from 'hooks/usePageable';
import useProperty from 'hooks/useProperty';
import type Pageable from 'models/Pageable';
import type { LoanVOModel } from 'models/vo/LoanVO';
import getStatusTextClass from 'utils/classNames/getStatusTextClass';
import type { PDFExporterProps } from 'utils/exportFile/exportPDF.d';
import { setFormValues } from 'utils/form/setFormValues';
import { requestDealerLoanList } from 'utils/http/api/dealer/loans';
import type { DealerLoanListRequest } from 'utils/http/api/dealer/loans/request';
import { ModalSize, ModalType } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';
import { getParsedSearchParams, updateSearchParams } from 'utils/searchParams';
import type { ColumnOption, ExportSpreadSheetProps } from 'utils/spreadSheet/types';
import { getFilteredDisbursedAmount, tableValueManage } from 'utils/valueManager/ValueManager';

const EXCEL_EXPORT_MAX_ROW_COUNT = 1000;
const PDF_EXPORT_MAX_ROW_COUNT = 150;
const DE_FINANCING_LIST_QS_KEY = 'de-financing-list';

function DealerViewTransactionFinancingList() {
  const { t } = useTranslation(['format']);
  const mounted = useMounted();
  const { pageable, setPageable } = usePageable(DE_FINANCING_LIST_QS_KEY);
  const [financingPage, setFinancingPage] = useState<Pageable<LoanVOModel[]>>();
  const getProperty = useProperty<DealerLoanListRequest>();
  const modal = useModal();

  const LoanStatusCheckBoxOptions: LOAN_STATUS[] = [
    LOAN_STATUS.DEALER_CREATED,
    LOAN_STATUS.DEALER_REQUESTED,
    LOAN_STATUS.DEALER_CANCELLED,
    LOAN_STATUS.REJECTED,
    LOAN_STATUS.APPROVED,
    LOAN_STATUS.DISBURSED,
    LOAN_STATUS.REPAID,
    LOAN_STATUS.OVERDUE,
    LOAN_STATUS.EXPIRATION,
    LOAN_STATUS.RECOURSE_ACTIVATED,
  ];

  const NUM_OF_CHECKBOX = LoanStatusCheckBoxOptions.length; // 체크박스 옵션 개수

  const { register, reset, getValues, watch, setValue, control } = useForm<DealerLoanListRequest>();

  const { loanStatusConditions: watchCheckbox } = watch(); // watch for checkbox

  useEffect(() => {
    if (mounted) {
      setFormValues<DealerLoanListRequest>(setValue, getParsedSearchParams(DE_FINANCING_LIST_QS_KEY).formSearchData);
      fetchDeLoanList(pageable.currentPage, pageable.sizePerPage, getValues());
    }

    return () => {};
  }, [mounted]);

  async function fetchDeLoanList(pageNumber: number = 1, rowCount: number = 10, data: DealerLoanListRequest) {
    try {
      const financingPage = await requestDealerLoanList(pageNumber, rowCount, data);
      updateSearchParams(
        {
          ...data,
          pageNumber,
          rowCount,
        },
        DE_FINANCING_LIST_QS_KEY,
      );

      ReactDOM.unstable_batchedUpdates(() => {
        setFinancingPage(financingPage);
        setPageable(financingPage);
      });
    } catch (error) {
      modal.show(error);
    }
  }

  const onSearchSubmit = async () => {
    await fetchDeLoanList(1, pageable.sizePerPage, getValues());
  };

  const paginate = async (page: number, sizePerPage: number) => {
    await fetchDeLoanList(page, sizePerPage, getParsedSearchParams(DE_FINANCING_LIST_QS_KEY).formSearchData);
  };

  const handleCheckAll = (e: any) => {
    if (e.target.checked) {
      setValue(getProperty('loanStatusConditions'), LoanStatusCheckBoxOptions);
    } else {
      setValue(getProperty('loanStatusConditions'), []);
    }
  };

  const onClickLoanStatus = (): void => {
    modal.show(
      <StatusDescriptionModal
        statusDescriptionEnum={Object.values(LOAN_STATUS)}
        statusDescriptionModalType="TEXT"
        statusDescriptionEnumType="LOAN_STATUS"
      />,
      {
        modalSize: ModalSize.XL,
        modalType: ModalType.ALERT,
        closeBtnText: t('text:Close'),
      },
    );
  };

  const onRemoveFilter = (event: any) => {
    reset({});
    event.preventDefault();
  };

  const onClickExportButton = async (e: any) => {
    e.preventDefault();

    const excelColumns: ColumnOption<LoanVOModel>[] = [
      {
        header: t('text:Program_Type'),
        key: 'collateralType',
        width: 30,
      },
      {
        header: t('text:Platform_Financing_ID'),
        key: 'loanId',
        width: 30,
      },
      {
        header: t('text:Financier_Financing_ID'),
        key: 'financierLoanId',
        width: 35,
      },
      {
        header: t('text:Invoice_Number'),
        key: 'invoiceNumber',
        width: 25,
      },
      {
        header: t('text:Invoice_Reference_Number'),
        key: 'referenceNumber',
        width: 30,
      },
      {
        header: t('text:AR_Number'),
        key: 'arNumber',
        width: 20,
      },
      {
        header: t('text:Currency'),
        key: 'currencyType',
        width: 13,
      },
      {
        header: t('text:Associated_Anchor_Name'),
        key: 'anchorClientName',
        width: 30,
      },
      {
        header: t('text:Associated_Anchor_Code'),
        key: 'anchorClientCode',
        width: 30,
      },
      {
        header: t('text:Requested_Financing_Amount'),
        key: 'principalAmount',
        width: 25,
        style: {
          alignment: { horizontal: 'right' },
        },
      },
      {
        header: t('text:Financing_Amount'),
        key: 'disbursedAmount',
        width: 25,
        style: {
          alignment: { horizontal: 'right' },
        },
      },
      {
        header: t('text:Advance_Disbursement'),
        key: 'netDisbursementAmount',
        width: 25,
        style: {
          alignment: { horizontal: 'right' },
        },
      },
      {
        header: t('text:Applied_Date'),
        key: 'requestedDateTime',
        width: 20,
      },
      {
        header: t('text:Disbursed_Date'),
        key: 'disbursedDate',
        width: 20,
      },
      {
        header: t('text:Scheduled_Repayment_Date'),
        key: 'repaymentDate',
        width: 30,
      },
      {
        header: t('text:Remaining_Financing_Term'),
        key: 'remainingFinancingTerm',
        width: 30,
        style: {
          alignment: { horizontal: 'right' },
          numFmt: `"D"+0;"D"-0;0`,
        },
      },
      {
        header: t('text:Repaid_Date'),
        key: 'repaidDate',
        width: 15,
      },
      {
        header: t('text:Repaid_Amount'),
        key: 'repaidAmount',
        width: 30,
      },
      {
        header: t('text:Outstanding_Balance'),
        key: 'outstandingBalance',
        width: 30,
      },
      {
        header: t('text:Accrued_Interest_Amount'),
        key: 'accruedInterestAmount',
        width: 30,
      },
      {
        header: t('text:Outstanding_Accrued_Interest_Amount'),
        key: 'outstandingAccruedInterestAmount',
        width: 30,
      },
      {
        header: t('text:Financing_Status'),
        key: 'loanStatus',
        width: 20,
      },
    ];

    const pdfTableHeaders: HeaderType[] = [
      {
        headerText: t('text:Program_Type'),
        colWidths: 100,
      },
      {
        headerText: t('text:Platform_Financing_ID'),
        colWidths: 100,
      },
      {
        headerText: t('text:Financier_Financing_ID'),
        colWidths: 100,
      },
      {
        headerText: t('text:Invoice_Number'),
        colWidths: 100,
      },
      {
        headerText: t('text:Invoice_Reference_Number'),
        colWidths: 100,
      },
      {
        headerText: t('text:AR_Number'),
        colWidths: 100,
      },
      {
        headerText: t('text:Currency'),
        colWidths: 96,
      },
      {
        headerText: t('text:Associated_Anchor_Name'),
        colWidths: 140,
      },
      {
        headerText: t('text:Associated_Anchor_Code'),
        colWidths: 100,
      },
      {
        headerText: t('text:Requested_Financing_Amount'),
        colWidths: 120,
      },
      {
        headerText: t('text:Financing_Amount'),
        colWidths: 120,
      },
      {
        headerText: t('text:Advance_Disbursement'),
        colWidths: 120,
      },
      {
        headerText: t('text:Applied_Date'),
        colWidths: 120,
      },
      {
        headerText: t('text:Disbursed_Date'),
        colWidths: 120,
      },
      {
        headerText: t('text:Scheduled_Repayment_Date'),
        colWidths: 120,
      },
      {
        headerText: t('text:Repaid_Date'),
        colWidths: 120,
      },
      {
        headerText: t('text:Repaid_Amount'),
        colWidths: 120,
      },
      {
        headerText: t('text:Outstanding_Balance'),
        colWidths: 120,
      },
      {
        headerText: t('text:Accrued_Interest_Amount'),
        colWidths: 120,
      },
      {
        headerText: t('text:Outstanding_Accrued_Interest_Amount'),
        colWidths: 120,
      },
      {
        headerText: t('text:Financing_Status'),
        colWidths: 100,
      },
    ];

    const renderPDFTableBodyResult = (data?: LoanVOModel[]): JSX.Element[] | undefined => {
      return data?.map((item, index) => (
        <tr key={index} className="virtual-table-row">
          <Td data={t(`code:collateral-type.${item?.collateralType}`)} format="code" />
          <Td data={item.loanId} />
          <Td data={item.financierLoanId} />
          <Td data={item.invoiceNumber} />
          <Td data={item.referenceNumber} />
          <Td data={item.arNumber} />
          <Td data={item.currencyType} />
          <Td data={item.anchorClientName} />
          <Td data={item.anchorClientCode} />
          <Td data={item.principalAmount} format="number" />
          <Td data={getFilteredDisbursedAmount(item.loanStatus, item.disbursedAmount)} format="number" />
          <Td data={item.netDisbursementAmount} format="number" />
          <Td data={item.requestedDateTime} format="date" />
          <Td data={item.disbursedDate} format="date" />
          <Td>
            <>
              {t('format:date', {
                value: item.repaymentDate,
                key: 'date',
              })}
              <div className="text-bold-brick-red">{item.remainingFinancingTerm}</div>
            </>
          </Td>
          <Td data={item.repaidDate} format="date" />
          <Td data={item.repaidPrincipalAmount} format="number" />
          <Td data={item.outstandingBalance} format="number" />
          <Td data={item.accruedInterestAmount} format="number" />
          <Td data={item.outstandingAccruedInterestAmount} format="number" />
          <Td
            data={t(`code:financing-status.${item.loanStatus}`)}
            className={getStatusTextClass('LOAN_STATUS', item.loanStatus)}
            format="code"
          />
        </tr>
      ));
    };

    try {
      const searchData = getParsedSearchParams(DE_FINANCING_LIST_QS_KEY).formSearchData;
      const [deFinancingPage, deFinancingPDFPage] = await Promise.all([
        requestDealerLoanList(0, EXCEL_EXPORT_MAX_ROW_COUNT, searchData),
        requestDealerLoanList(0, PDF_EXPORT_MAX_ROW_COUNT, searchData),
      ]);
      const jsonArrayData: any[] = deFinancingPage.content.map(item => ({
        collateralType: tableValueManage(t(`code:collateral-type.${item?.collateralType}`)),
        loanId: tableValueManage(item.loanId),
        financierLoanId: tableValueManage(item.financierLoanId),
        invoiceNumber: tableValueManage(item.invoiceNumber),
        referenceNumber: tableValueManage(item.referenceNumber),
        arNumber: tableValueManage(item.arNumber),
        currencyType: tableValueManage(item.currencyType),
        anchorClientName: tableValueManage(item.anchorClientName),
        anchorClientCode: tableValueManage(item.anchorClientCode),
        principalAmount: tableValueManage(item.principalAmount),
        disbursedAmount: tableValueManage(getFilteredDisbursedAmount(item.loanStatus, item.disbursedAmount)),
        netDisbursementAmount: tableValueManage(item.netDisbursementAmount),
        requestedDateTime: t('format:original-date', {
          value: item.requestedDateTime,
          key: 'original-date',
        }),
        disbursedDate: t('format:original-date', {
          value: item.disbursedDate,
          key: 'original-date',
        }),
        repaymentDate: t('format:original-date', {
          value: item.repaymentDate,
          key: 'original-date',
        }),
        remainingFinancingTerm: item.remainingFinancingTerm,
        repaidDate: t('format:original-date', {
          value: item.repaidDate,
          key: 'original-date',
        }),
        repaidAmount: tableValueManage(item.repaidPrincipalAmount),
        outstandingBalance: tableValueManage(parseFloat(item.outstandingBalance)),
        accruedInterestAmount: tableValueManage(item.accruedInterestAmount),
        outstandingAccruedInterestAmount: tableValueManage(item.outstandingAccruedInterestAmount),
        loanStatus: tableValueManage(item.loanStatus, t(`code:financing-status.${item.loanStatus}`)),
      }));

      const excelExporterProps: ExportSpreadSheetProps<any> = {
        jsonArrayData: jsonArrayData,
        columns: excelColumns,
        options: {
          rowHeight: [{ position: 1, height: 30 }],
        },
      };

      const pdfExporterProps: PDFExporterProps = {
        tableHeaders: pdfTableHeaders,
        tableBody: renderPDFTableBodyResult(deFinancingPDFPage.content),
      };

      modal.show(<Exporter spreadSheetExporterProps={excelExporterProps} pdfExporterProps={pdfExporterProps} />, {
        title: t('text:Export_File'),
        closeBtnText: t('text:Close'),
      });
    } catch (e) {
      modal.show(e);
    }
  };
  const tableHeaders: HeaderType[] = [
    {
      headerText: t('text:Program_Type'),
      colWidths: 100,
    },
    {
      headerText: t('text:Platform_Financing_ID'),
      colWidths: 100,
    },
    {
      headerText: t('text:Financier_Financing_ID'),
      colWidths: 100,
    },
    {
      headerText: t('text:Invoice_Number'),
      colWidths: 100,
    },
    {
      headerText: t('text:Invoice_Reference_Number'),
      colWidths: 100,
    },
    {
      headerText: t('text:AR_Number'),
      colWidths: 100,
    },
    {
      headerText: t('text:Currency'),
      colWidths: 96,
    },
    {
      headerText: t('text:Associated_Anchor_Name'),
      colWidths: 140,
    },
    {
      headerText: t('text:Associated_Anchor_Code'),
      colWidths: 100,
    },
    {
      headerText: t('text:Requested_Financing_Amount'),
      colWidths: 120,
    },
    {
      headerText: t('text:Financing_Amount'),
      colWidths: 120,
    },
    {
      headerText: t('text:Advance_Disbursement'),
      colWidths: 120,
    },
    {
      headerText: t('text:Applied_Date'),
      colWidths: 120,
    },
    {
      headerText: t('text:Disbursed_Date'),
      colWidths: 120,
    },
    {
      headerText: t('text:Scheduled_Repayment_Date'),
      colWidths: 120,
    },
    {
      headerText: t('text:Repaid_Date'),
      colWidths: 120,
    },
    {
      headerText: t('text:Repaid_Amount'),
      colWidths: 120,
    },
    {
      headerText: t('text:Outstanding_Balance'),
      colWidths: 120,
    },
    {
      headerText: t('text:Financing_Status'),
      colWidths: 100,
      hasStatusDescription: true,
      showStatusDescriptionFunc: onClickLoanStatus,
    },
    {
      headerText: '',
      colWidths: 50,
    },
  ];

  const renderResultTable = () => {
    return financingPage?.content.map((item, i) => {
      return (
        <Tr key={i}>
          <Td data={t(`code:collateral-type.${item?.collateralType}`)} format="code" />
          <Td data={item.loanId} />
          <Td data={item.financierLoanId} />
          <Td data={item.invoiceNumber} />
          <Td data={item.referenceNumber} />
          <Td data={item.arNumber} />
          <Td data={item.currencyType} />
          <Td data={item.anchorClientName} />
          <Td data={item.anchorClientCode} />
          <Td data={item.principalAmount} format="number" />
          <Td data={getFilteredDisbursedAmount(item.loanStatus, item.disbursedAmount)} format="number" />
          <Td data={item.netDisbursementAmount} format="number" />
          <Td data={item.requestedDateTime} format="date" />
          <Td data={item.disbursedDate} format="date" />
          <Td>
            <>
              {t('format:date', {
                value: item.repaymentDate,
                key: 'date',
              })}
              <div className="text-bold-brick-red">{item.remainingFinancingTerm}</div>
            </>
          </Td>
          <Td data={item.repaidDate} format="date" />
          <Td data={item.repaidPrincipalAmount} format="number" />
          <Td data={item.outstandingBalance} format="number" />
          <Td
            data={t(`code:financing-status.${item.loanStatus}`)}
            className={getStatusTextClass('LOAN_STATUS', item.loanStatus)}
            format="code"
          />
          <TdLink path={ROUTES_DE.VIEW_TRANSACTION.FINANCING_DETAIL_BUILD_PATH(item.loanId)} />
        </Tr>
      );
    });
  };

  return (
    <>
      <HeaderTitle title={t('text:Financing')} />
      <div className="content-area">
        <form>
          <SectionTitle title={t('text:Search')}>
            <Button variant={ButtonVariantEnum.OUTLINED} color={ButtonColorEnum.SECONDARY} onClick={onRemoveFilter}>
              {t('text:Remove_Filter')}
            </Button>
          </SectionTitle>
          <SearchBorder>
            <div className="row">
              <SearchLabel label={t('text:Platform_Financing_ID')} />
              <SearchInput name={getProperty('loanId')} ref={register} />
              <SearchLabel label={t('text:Financier_Financing_ID')} />
              <SearchInput name={getProperty('financierLoanId')} ref={register} />
            </div>
            <div className="row">
              <SearchLabel label={t('text:Invoice_Number')} />
              <SearchInput name={getProperty('invoiceNumber')} ref={register} />
              <SearchLabel label={t('text:Invoice_Reference_Number')} />
              <SearchInput name={getProperty('referenceNumber')} ref={register} />
            </div>
            <div className="row">
              <SearchLabel label={t('text:AR_Number')} />
              <SearchInput name={getProperty('arNumber')} ref={register} />
              <SearchLabel label={t('text:Currency')} />
              <SearchSelect
                selectOptions={getSelectOptions<CURRENCY_TYPE>('CURRENCY_TYPE', 'ALL', true)}
                name={getProperty('currencyType')}
                ref={register}
              />
            </div>
            <div className="row">
              <SearchLabel label={t('text:Associated_Anchor_Name')} />
              <SearchInput name={getProperty('anchorFinancierClientName')} ref={register} />
              <SearchLabel label={t('text:Associated_Anchor_Code')} />
              <SearchInput name={getProperty('anchorFinancierClientCode')} ref={register} />
            </div>
            <div className="row">
              <SearchLabel label={t('text:Applied_Date')} />
              <SearchDatePicker
                placeholder={t('text:from')}
                name={getProperty('requestedDateFrom')}
                control={control}
              />
              <SearchDatePicker placeholder={t('text:to')} name={getProperty('requestedDateTo')} control={control} />
              <SearchLabel label={t('text:Disbursed_Date')} />
              <SearchDatePicker
                placeholder={t('text:from')}
                name={getProperty('disbursedDateFrom')}
                control={control}
              />
              <SearchDatePicker placeholder={t('text:to')} name={getProperty('disbursedDateTo')} control={control} />
            </div>
            <div className="row">
              <SearchLabel label={t('text:Scheduled_Repayment_Date')} />
              <SearchDatePicker
                placeholder={t('text:from')}
                name={getProperty('repaymentDateFrom')}
                control={control}
              />
              <SearchDatePicker placeholder={t('text:to')} name={getProperty('repaymentDateTo')} control={control} />
              <SearchLabel label={t('text:Repaid_Date')} />
              <SearchDatePicker placeholder={t('text:from')} name={getProperty('repaidDateFrom')} control={control} />
              <SearchDatePicker placeholder={t('text:to')} name={getProperty('repaidDateTo')} control={control} />
            </div>
            <div className="row">
              <SearchLabel label={t('text:Program_Type')} />
              <SearchSelect
                name="collateralType"
                selectOptions={getSelectOptions<COLLATERAL_TYPE>('COLLATERAL_TYPE', 'ALL', true)}
                ref={register}
              />
              <SearchEmpty />
            </div>
            <div className="row">
              <div className="search-form__label-wrap auto-height col-2">
                <div className="search-form__label">
                  {t('text:Financing_Status')}
                  <QuestionButton onClick={onClickLoanStatus} />
                </div>
              </div>
              <div className="search-form__item__input col-10">
                <div className="checkbox-form">
                  <div className="form-check-inline">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                      id="allCheck"
                      onChange={handleCheckAll}
                      checked={watchCheckbox?.length === NUM_OF_CHECKBOX}
                    />
                    <label className="form-check-label" htmlFor="allCheck">
                      {t('text:All')}
                    </label>
                  </div>
                  {LoanStatusCheckBoxOptions?.map((value, index) => {
                    return (
                      <div className="form-check-inline" key={index}>
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value={value}
                          id={`flexCheckDefault${index}`}
                          name={getProperty('loanStatusConditions')}
                          ref={register}
                        />
                        <label className="form-check-label" htmlFor={`flexCheckDefault${index}`}>
                          {t(`code:financing-status.${LoanStatusCheckBoxOptions[index]}`)}
                        </label>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </SearchBorder>
          <div className="flex-center mt-4">
            <Button size={ButtonSizeEnum.LG} onClick={onSearchSubmit}>
              {t('text:Search')}
            </Button>
          </div>
        </form>
      </div>
      <div className="division-border" />
      <div className="content-area">
        <SectionTitle title={t('text:Result')} />
        <div className="d-flex mb-2">
          <p className="total-data me-auto">
            {t('text:Total')}&nbsp;
            {financingPage?.totalElements}
          </p>
          <Button onClick={onClickExportButton} variant={ButtonVariantEnum.OUTLINED}>
            {t('text:Export')}
          </Button>
        </div>
        <TableBorder>
          <TableHeader header={tableHeaders} />
          <TableBody numOfCol={tableHeaders.length}>{renderResultTable()}</TableBody>
        </TableBorder>
        <Pagination pageable={pageable} paginate={paginate} />
      </div>
    </>
  );
}

export default DealerViewTransactionFinancingList;
