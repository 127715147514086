import { useTranslation } from 'react-i18next';

import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Button, { ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import { FormBorder } from 'components/stateless/CommonForm/FormBorder';
import { FormContents } from 'components/stateless/CommonForm/FormContents';
import { FormValue } from 'components/stateless/CommonForm/FormValue';
import SectionMessage from 'components/stateless/SectionMessage/SectionMessage';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { ROUTES_FI } from 'constants/routes/financier';
import type { PotentialPartnerVOModel } from 'models/vo/PotentialPartnerVO';
import { hasValue } from 'utils/valueManager/ValueManager';

interface FinancierSCPartnerAcquisitionDetailPartnerInfoProps {
  partnerInfo: PotentialPartnerVOModel;
}

function FinancierSCPartnerAcquisitionDetailPartnerInfo({
  partnerInfo,
}: FinancierSCPartnerAcquisitionDetailPartnerInfoProps) {
  const { t } = useTranslation(['format']);

  // 잠재 파트너 정보와 앵커 파트너가 등록한 정보가 다를 경우 파란색으로 표시
  const setBgBlueByCompareInfo = (tar1: any, tar2: any) => {
    if (tar1 !== tar2) {
      if (!hasValue(tar1) && !hasValue(tar2)) return;

      return 'information-form__input updated__bg__input';
    }
  };

  return (
    <>
      <div className="content-area mb-0">
        <SectionTitle title={t('text:Submitted_Partner_Information')}>
          <div className="flex-end align-items-center">
            <span className="text-bold-sub700 me-1">{t('text:Go_to_Company_Details')}</span>
            <Button
              size={ButtonSizeEnum.SM}
              variant={ButtonVariantEnum.OUTLINED}
              color={ButtonColorEnum.SECONDARY}
              className="flex-column-center"
              to={ROUTES_FI.MANAGE_UPLOADED_PARTNER.REGISTRATION_CONFIRMED_DETAIL_BUILD_PATH(
                partnerInfo.anchorPartnerId,
              )}
            >
              <FontAwesomeIcon icon={faArrowRight} />
            </Button>
          </div>
        </SectionTitle>
      </div>
      <div className="content-area">
        <SectionMessage
          message={[
            t(
              'text:If_the_Uploaded_Partner_revised_its_company_information_when_submitting_the_documents_the_revised_information_will_be_marked_in_blue',
            ),
          ]}
        />
      </div>

      <div className="content-area">
        <FormBorder>
          <FormContents>
            <div className="row">
              <FormValue
                label={t('text:Company_Name')}
                className={setBgBlueByCompareInfo(partnerInfo.anchorPartnerName, partnerInfo.potentialPartnerName)}
                value={partnerInfo.potentialPartnerName}
              />
              <FormValue
                label={t('text:Tax_Code')}
                className={setBgBlueByCompareInfo(
                  partnerInfo.anchorPartnerTaxCode,
                  partnerInfo.potentialPartnerTaxCode,
                )}
                value={partnerInfo.potentialPartnerTaxCode}
              />
            </div>
            <div className="row">
              <FormValue
                label={t('text:Company_Registration_Number')}
                className={setBgBlueByCompareInfo(
                  partnerInfo.anchorPartnerBusinessCode,
                  partnerInfo.potentialPartnerBusinessCode,
                )}
                value={partnerInfo.potentialPartnerBusinessCode}
              />
              <FormValue
                label={t('text:Telephone')}
                className={setBgBlueByCompareInfo(
                  partnerInfo.anchorPartnerTelephone,
                  partnerInfo.potentialPartnerTelephone,
                )}
                col={3}
                value={partnerInfo.potentialPartnerTelephone}
              />
              <FormValue
                label={t('text:Fax')}
                className={setBgBlueByCompareInfo(partnerInfo.anchorPartnerFax, partnerInfo.potentialPartnerFax)}
                col={3}
                value={partnerInfo.potentialPartnerFax}
              />
            </div>
            <div className="row">
              <FormValue
                label={t('text:Legal_Representative_Name')}
                className={setBgBlueByCompareInfo(
                  partnerInfo.anchorPartnerRepresentativeName,
                  partnerInfo.potentialPartnerRepresentativeName,
                )}
                value={partnerInfo.potentialPartnerRepresentativeName}
              />
              <FormValue
                label={t('text:Legal_Representative_Title')}
                className={setBgBlueByCompareInfo(
                  partnerInfo.anchorPartnerRepresentativeTitle,
                  partnerInfo.potentialPartnerRepresentativeTitle,
                )}
                col={3}
                value={partnerInfo.potentialPartnerRepresentativeTitle}
              />
              <FormValue
                label={t('text:Legal_Representative_Email')}
                className={setBgBlueByCompareInfo(
                  partnerInfo.anchorPartnerRepresentativeEmail,
                  partnerInfo.potentialPartnerRepresentativeEmail,
                )}
                col={3}
                value={partnerInfo.potentialPartnerRepresentativeEmail}
              />
            </div>
            <div className="row">
              <FormValue
                label={t('text:Registered_Office_Address')}
                className={setBgBlueByCompareInfo(
                  partnerInfo.anchorPartnerAddress,
                  partnerInfo.potentialPartnerAddress,
                )}
                col={12}
                value={partnerInfo.potentialPartnerAddress}
              />
            </div>
          </FormContents>
        </FormBorder>
      </div>
    </>
  );
}

export default FinancierSCPartnerAcquisitionDetailPartnerInfo;
