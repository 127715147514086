import { useTranslation } from 'react-i18next';

import type { BigHeaderType, HeaderType } from 'components/stateless/Table/TableHeader';
import type { StatisticsOfDealerAgreementVOModel } from 'models/vo/StatisticsOfDealerAgreementVO';
import type { ColumnOption, MergedCellTypes } from 'utils/spreadSheet/types';

export function useSystemOverviewBySCPartnerListChannelTabConstants() {
  const { t } = useTranslation(['format']);
  const excelColumnsInvoice: ColumnOption<StatisticsOfDealerAgreementVOModel>[] = [
    {
      header: t('text:Financier_Name'),
      key: 'financierName',
      width: 20,
    },
    {
      header: t('text:Associated_Anchor_Name'),
      key: 'anchorClientName',
      width: 30,
    },
    {
      header: t('text:Associated_Anchor_Client_Code'),
      key: 'anchorClientCode',
      width: 35,
    },
    {
      header: t('text:Partner_Name'),
      key: 'dealerClientName',
      width: 40,
    },
    {
      header: t('text:Partner_Client_Code'),
      key: 'dealerClientCode',
      width: 25,
    },
    {
      header: t('text:Partner_Master_Agreement_Number'),
      key: 'contractNo',
      width: 40,
    },
    {
      header: t('text:Currency'),
      key: 'currencyType',
      width: 10,
    },
    {
      header: t('text:Effective_Date'),
      key: 'startDate',
      width: 20,
    },
    {
      header: t('text:Expiration_Date'),
      key: 'expiryDate',
      width: 20,
    },
    {
      header: t('text:Base_Interest_Rate'),
      key: 'basisInterestRate',
      width: 35,
    },
    {
      header: t('text:Financing_Limit'),
      key: 'loanLimitAmount',
      width: 20,
      style: {
        alignment: { horizontal: 'right' },
      },
    },
    {
      header: t('text:Ad_hoc_Limit_Start_Date'),
      key: 'adhocLimitStartDate',
      width: 40,
    },
    {
      header: t('text:Ad_hoc_Limit_End_Date'),
      key: 'adhocLimitEndDate',
      width: 40,
    },
    {
      header: t('text:Ad_hoc_Limit'),
      key: 'adhocLimitAmount',
      width: 20,
      style: {
        alignment: { horizontal: 'right' },
      },
    },
    {
      header: t('text:Outstanding_Financing_Balance'),
      key: 'currentLoanBalanceAmount',
      width: 40,
      style: {
        alignment: { horizontal: 'right' },
      },
    },
    {
      header: t('text:Number_of_Outstanding_Financing'),
      key: 'currentDisbursedLoanCount',
      width: 40,
      style: {
        alignment: { horizontal: 'right' },
      },
    },
    {
      header: t('text:Remaining_Limit'),
      key: 'remainingLimit',
      width: 20,
      style: {
        alignment: { horizontal: 'right' },
      },
    },
    {
      header: t('text:Total_Remaining_Limit'),
      key: 'totalRemainingLimit',
      width: 30,
      style: {
        alignment: { horizontal: 'right' },
      },
    },
    {
      header: t('text:Invoice_Amount_to_be_paid'),
      key: 'currentSettlementWaitingInvoiceAmount',
      width: 40,
      style: {
        alignment: { horizontal: 'right' },
      },
    },
    {
      header: t('text:Number_of_Invoices_to_be_paid'),
      key: 'currentSettlementWaitingInvoiceCount',
      width: 40,
      style: {
        alignment: { horizontal: 'right' },
      },
    },
    {
      header: t('text:Delinquent_Status'),
      key: 'currentOverdueLoanCount',
      width: 22,
    },
    {
      header: t('text:Registered_Invoice_Amount'),
      key: 'registeredInvoiceAmount',
      width: 40,
      style: {
        alignment: { horizontal: 'right' },
      },
    },
    {
      header: t('text:Number_of_Registered_Invoices'),
      key: 'registeredInvoiceCount',
      width: 40,
      style: {
        alignment: { horizontal: 'right' },
      },
    },
    {
      header: t('text:Total_Financing_Amount'),
      key: 'totalDisbursedLoanAmount',
      width: 40,
      style: {
        alignment: { horizontal: 'right' },
      },
    },
    {
      header: t('text:Total_Number_of_Financing'),
      key: 'totalDisbursedLoanCount',
      width: 40,
      style: {
        alignment: { horizontal: 'right' },
      },
    },
    {
      header: t('text:Total_Amount_of_Invoices_Not_Proceeded'),
      key: 'totalUnsettledInvoiceAmount',
      width: 48,
      style: {
        alignment: { horizontal: 'right' },
      },
    },
    {
      header: t('text:Total_Number_of_Invoices_Not_Proceeded'),
      key: 'totalUnsettledInvoiceCount',
      width: 48,
      style: {
        alignment: { horizontal: 'right' },
      },
    },
  ];

  const invoiceMergedCells: MergedCellTypes[] = [
    {
      target: 'A:A',
      rowIndex: 1,
      value: t('text:Financier_Information'),
    },
    {
      target: 'B:C',
      rowIndex: 1,
      value: t('text:Anchor_Information'),
    },
    {
      target: 'D:N',
      rowIndex: 1,
      value: t('text:Partner_Information'),
    },
    {
      target: 'O:U',
      rowIndex: 1,
      value: t('text:Current_Program_Utilization'),
      style: {
        fill: {
          fgColor: { argb: 'FFFFFCDE' },
        },
      },
    },
    {
      target: 'V:AA',
      rowIndex: 1,
      value: t('text:Accumulated_Program_Utilization'),
      style: {
        fill: {
          fgColor: { argb: 'FFFFF8BD' },
        },
      },
    },
  ];

  const invoiceMergedTableHeader: BigHeaderType[] = [
    {
      headerText: t('text:Financier_Information'),
      colSpan: 1,
    },
    {
      headerText: t('text:Anchor_Information'),
      colSpan: 2,
    },
    {
      headerText: t('text:Partner_Information'),
      colSpan: 11,
    },
    {
      headerText: t('text:Current_Program_Utilization'),
      colSpan: 7,
      className: 'main100',
    },
    {
      headerText: t('text:Accumulated_Program_Utilization'),
      colSpan: 6,
      className: 'main200',
    },
  ];

  const invoiceTableHeader: HeaderType[] = [
    {
      headerText: t('text:Financier_Name'),
      colWidths: 165,
    },
    {
      headerText: t('text:Associated_Anchor_Name'),
      colWidths: 165,
    },
    {
      headerText: t('text:Associated_Anchor_Client_Code'),
      colWidths: 165,
    },
    {
      headerText: t('text:Partner_Name'),
      colWidths: 165,
    },
    {
      headerText: t('text:Partner_Client_Code'),
      colWidths: 165,
    },
    {
      headerText: t('text:Partner_Master_Agreement_Number'),
      colWidths: 165,
    },
    {
      headerText: t('text:Currency'),
      colWidths: 100,
    },
    {
      headerText: t('text:Effective_Date'),
      colWidths: 180,
    },
    {
      headerText: t('text:Expiration_Date'),
      colWidths: 180,
    },
    {
      headerText: t('text:Base_Interest_Rate'),
      colWidths: 165,
    },
    {
      headerText: t('text:Financing_Limit'),
      colWidths: 180,
    },
    {
      headerText: t('text:Ad_hoc_Limit_Start_Date'),
      colWidths: 180,
    },
    {
      headerText: t('text:Ad_hoc_Limit_End_Date'),
      colWidths: 180,
    },
    {
      headerText: t('text:Ad_hoc_Limit'),
      colWidths: 180,
    },
    {
      headerText: t('text:Outstanding_Financing_Balance'),
      colWidths: 180,
      className: 'main100',
    },
    {
      headerText: t('text:Number_of_Outstanding_Financing'),
      colWidths: 180,
      className: 'main100',
    },
    {
      headerText: t('text:Remaining_Limit'),
      colWidths: 180,
      className: 'main100',
    },
    {
      headerText: t('text:Total_Remaining_Limit'),
      colWidths: 180,
      className: 'main100',
    },
    {
      headerText: t('text:Invoice_Amount_to_be_paid'),
      colWidths: 180,
      className: 'main100',
    },
    {
      headerText: t('text:Number_of_Invoices_to_be_paid'),
      colWidths: 180,
      className: 'main100',
    },
    {
      headerText: t('text:Delinquent_Status'),
      colWidths: 100,
      className: 'main100',
    },
    {
      headerText: t('text:Registered_Invoice_Amount'),
      colWidths: 180,
      className: 'main200',
    },
    {
      headerText: t('text:Number_of_Registered_Invoices'),
      colWidths: 180,
      className: 'main200',
    },
    {
      headerText: t('text:Total_Financing_Amount'),
      colWidths: 180,
      className: 'main200',
    },
    {
      headerText: t('text:Total_Number_of_Financing'),
      colWidths: 180,
      className: 'main200',
    },
    {
      headerText: t('text:Total_Amount_of_Invoices_Not_Proceeded'),
      colWidths: 180,
      className: 'main200',
    },
    {
      headerText: t('text:Total_Number_of_Invoices_Not_Proceeded'),
      colWidths: 180,
      className: 'main200',
    },
  ];

  const INVOICE_TABLE_HEADER: HeaderType[] = [
    {
      headerText: t('text:Financier_Name'),
      colWidths: 170,
    },
    {
      headerText: t('text:Associated_Anchor_Name'),
      colWidths: 170,
    },
    {
      headerText: t('text:Associated_Anchor_Client_Code'),
      colWidths: 100,
    },
    {
      headerText: t('text:Partner_Name'),
      colWidths: 170,
    },
    {
      headerText: t('text:Partner_Client_Code'),
      colWidths: 100,
    },
    {
      headerText: t('text:Partner_Master_Agreement_Number'),
      colWidths: 100,
    },
    {
      headerText: t('text:Currency'),
      colWidths: 80,
    },
    {
      headerText: t('text:Effective_Date'),
      colWidths: 120,
    },
    {
      headerText: t('text:Expiration_Date'),
      colWidths: 120,
    },
    {
      headerText: t('text:Base_Interest_Rate'),
      colWidths: 160,
    },
    {
      headerText: t('text:Financing_Limit'),
      colWidths: 140,
    },
    {
      headerText: t('text:Ad_hoc_Limit_Effective_Period'),
      colWidths: 160,
    },
    {
      headerText: t('text:Ad_hoc_Limit'),
      colWidths: 160,
    },
    {
      headerText: t('text:Financing_Balance_/_Count'),
      colWidths: 155,
      className: 'main100',
    },
    {
      headerText: t('text:Remaining_Limit'),
      colWidths: 120,
      className: 'main100',
    },
    {
      headerText: t('text:Total_Remaining_Limit'),
      colWidths: 165,
      className: 'main100',
    },
    {
      headerText: t('text:Invoice_Amount_to_be_paid_/_Count'),
      colWidths: 150,
      className: 'main100',
    },
    {
      headerText: t('text:Delinquent_Status'),
      colWidths: 100,
      className: 'main100',
    },
    {
      headerText: t('text:Registered_Invoice_Amount_/_Count'),
      colWidths: 165,
      className: 'main200',
    },
    {
      headerText: t('text:Total_Financing_Amount_/_Count'),
      colWidths: 165,
      className: 'main200',
    },
    {
      headerText: t('text:Total_Non_proceeded_Invoice_Amount_/_Count'),
      colWidths: 165,
      className: 'main200',
    },
  ];

  const INVOICE_BIG_TABLE_HEADER: BigHeaderType[] = [
    {
      headerText: t('text:Financier_Information'),
      colSpan: 1,
    },
    {
      headerText: t('text:Anchor_Information'),
      colSpan: 2,
    },
    {
      headerText: t('text:Partner_Information'),
      colSpan: 10,
    },
    {
      headerText: t('text:Current_Program_Utilization'),
      colSpan: 5,
      className: 'main100',
    },
    {
      headerText: t('text:Accumulated_Program_Utilization'),
      colSpan: 3,
      className: 'main200',
    },
  ];

  return {
    excelColumnsInvoice,
    invoiceMergedCells,
    invoiceMergedTableHeader,
    invoiceTableHeader,
    INVOICE_TABLE_HEADER,
    INVOICE_BIG_TABLE_HEADER,
  };
}
