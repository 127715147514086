import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import Button, { ButtonColorEnum, ButtonSizeEnum } from 'components/stateless/Button/Button';
import { FormBorder } from 'components/stateless/CommonForm/FormBorder';
import { FormContents } from 'components/stateless/CommonForm/FormContents';
import { FormValue } from 'components/stateless/CommonForm/FormValue';
import GuideMessage, { MessageType } from 'components/stateless/GuideMessage/GuideMessage';
import ReasonModal from 'components/stateless/Modal/common/ReasonModal';
import Pagination from 'components/stateless/Pagination/Pagination';
import TableBody from 'components/stateless/Table/TableBody';
import TableBorder from 'components/stateless/Table/TableBorder';
import type { HeaderType } from 'components/stateless/Table/TableHeader';
import TableHeader from 'components/stateless/Table/TableHeader';
import Td from 'components/stateless/Table/Td';
import Tr from 'components/stateless/Table/Tr';
import { BackHeaderTitle } from 'components/stateless/Title/BackHeaderTitle';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { AUTHORITY_TYPE, COMMON_APPROVAL_TYPE } from 'enums';
import useMounted from 'hooks/useMounted';
import usePageable from 'hooks/usePageable';
import type { AnchorAgreementVOModel } from 'models/vo/AnchorAgreementVO';
import type { AnchorDealerVOModel } from 'models/vo/AnchorDealerVO';
import type { WaitingAnchorDealerVOModel } from 'models/vo/WaitingAnchorDealerVO';
import { requestFinancierAnchorAgreementList } from 'utils/http/api/financier/anchor-agreements';
import {
  requestFinancierAnchorDealerDecline,
  requestFinancierAnchorDealerDetail,
  requestFinancierAnchorDealerReinstate,
} from 'utils/http/api/financier/anchor-dealers';
import { requestFinancierWaitingAnchorDealerList } from 'utils/http/api/financier/waiting-anchor-dealers';
import { ModalType } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';
import { getSignIn } from 'utils/storage/LocalStorage';

import { scrollToTopOfElement } from '../../../../../utils/scroll';
import RelatedAnchorAgreementList from '../../components/RelatedAnchorAgreementList';

function FinancierAnchorDealerAssignmentDetail() {
  const { t } = useTranslation(['format']);
  const mounted = useMounted();
  const modal = useModal();
  const { anchorDealerId } = useParams() as any;
  const { pageable: anchorAgreementPageable, setPageable: setAnchorAgreementPageable } = usePageable();
  const { pageable: historyPageable, setPageable: setHistoryPageable } = usePageable();

  const isAdmin = getSignIn()?.authorityType === AUTHORITY_TYPE.ADMIN;

  const [anchorDealerInfo, setAnchorDealerInfo] = useState<AnchorDealerVOModel>();
  const [relatedAnchorAgreement, setRelatedAnchorAgreement] = useState<AnchorAgreementVOModel[]>();
  const [anchorDealerHistoryList, setAnchorDealerHistoryList] = useState<WaitingAnchorDealerVOModel[]>([]);

  useEffect(() => {
    if (mounted) {
      fetchAll();
    }
  }, [mounted]);

  async function fetchAll() {
    try {
      const [anchorDealerResponse, anchorDealerHistoryResponse] = await Promise.all([
        requestFinancierAnchorDealerDetail(anchorDealerId),
        requestFinancierWaitingAnchorDealerList(historyPageable.currentPage, historyPageable.sizePerPage, {
          hasTargetAnchorDealer: true,
          targetAnchorDealerId: anchorDealerId,
          approvalTypes: [COMMON_APPROVAL_TYPE.OPERATOR_CANCEL, COMMON_APPROVAL_TYPE.AUTHORIZER_APPROVED],
        }),
      ]);

      const relatedAnchorAgreementResponse = await requestFinancierAnchorAgreementList(1, 10, {
        anchorClientId: anchorDealerResponse.anchorClientId,
      });

      setAnchorDealerInfo(anchorDealerResponse);

      setRelatedAnchorAgreement(relatedAnchorAgreementResponse.content);
      setAnchorAgreementPageable(relatedAnchorAgreementResponse);

      setAnchorDealerHistoryList(anchorDealerHistoryResponse.content);
      setHistoryPageable(anchorDealerHistoryResponse);
    } catch (error) {
      modal.show(error);
    }
  }

  const anchorAgreementPaginate = async (pageNumber: number, sizePerPage: number) => {
    const relatedAnchorAgreementResponse = await requestFinancierAnchorAgreementList(pageNumber, sizePerPage, {
      anchorClientId: anchorDealerInfo?.anchorClientId,
    });

    setRelatedAnchorAgreement(relatedAnchorAgreementResponse.content);
    setAnchorAgreementPageable(relatedAnchorAgreementResponse);
  };

  const historyPaginate = async (pageNumber: number, sizePerPage: number) => {
    const historyResponse = await requestFinancierWaitingAnchorDealerList(pageNumber, sizePerPage, {
      hasTargetAnchorDealer: true,
      targetAnchorDealerId: anchorDealerId,
      approvalTypes: [COMMON_APPROVAL_TYPE.OPERATOR_CANCEL, COMMON_APPROVAL_TYPE.AUTHORIZER_APPROVED],
    });

    setAnchorDealerHistoryList(historyResponse.content);
    setHistoryPageable(historyResponse);
  };

  const handleClickReinstate = (e: any) => {
    e.preventDefault();

    const onClickConfirm = async (): Promise<void> => {
      try {
        await requestFinancierAnchorDealerReinstate(anchorDealerId);

        modal.show(<h6>{t('text:The_dealer_has_been_reinstated')}</h6>, {
          closeBtnText: t('text:OK'),
          closeBtnCb: () => fetchAll(),
        });
      } catch (error) {
        modal.show(error);
      }
    };

    modal.show(<h6>{t('text:Would_you_like_to_reinstate_dealer_assignment?')}</h6>, {
      modalType: ModalType.CONFIRM,
      closeBtnText: t('text:Cancel'),
      confirmBtnText: t('text:Confirm'),
      confirmBtnCb: () => onClickConfirm(),
    });
  };

  const handleClickDeclineAssignment = (e: any) => {
    e.preventDefault();

    let reason: string = '';

    const onClickConfirm = async (): Promise<void> => {
      try {
        await requestFinancierAnchorDealerDecline([anchorDealerId], reason);

        modal.show(<h6>{t('text:The_dealer_has_been_declined')}</h6>, {
          modalType: ModalType.ALERT,
          closeBtnText: t('text:OK'),
          closeBtnCb: () => {
            fetchAll();
            scrollToTopOfElement();
          },
        });
      } catch (error) {
        modal.show(error);
      }
    };

    modal.show(
      <div className="modal-container">
        <h6>
          {t('text:Would_you_like_to_decline_dealer_assignment?')}
          <br />
          {t('text:Please_type_below_the_reason_for_the_decline')}
          <br />
          {t('text:Click_on_Confirm_to_decline_the_assignment')}
        </h6>
        <ReasonModal
          modalId={modal.id}
          getReturnReason={(returnReason: string) => {
            reason = returnReason;
          }}
        />
      </div>,
      {
        modalType: ModalType.CONFIRM,
        closeBtnText: t('text:Cancel'),
        confirmBtnText: t('text:Confirm'),
        confirmBtnCb: () => onClickConfirm(),
      },
    );
  };

  const INFORMATION_EDIT_HISTORY_HEADERS: HeaderType[] = [
    {
      headerText: t('text:Edited_Date'),
      colWidths: 100,
    },
    {
      headerText: t('text:Associated_Anchor_Name'),
      colWidths: 100,
    },
    {
      headerText: t('text:Declined'),
      colWidths: 100,
    },
  ];

  return (
    <>
      <BackHeaderTitle title={anchorDealerInfo?.name} />
      {!isAdmin &&
        (anchorDealerInfo?.declined ? (
          <GuideMessage
            message={[t('text:Please_click_on_the_Reinstate_button_to_restart_the_assignment_process_for_this_dealer')]}
            messageType={MessageType.ALERT}
            reasonTitle={t('text:REASON_OF_DECLINE')}
            reason={anchorDealerInfo?.declinedReason}
          >
            {{
              button: <Button onClick={handleClickReinstate}>{t('text:Reinstate')}</Button>,
            }}
          </GuideMessage>
        ) : (
          <GuideMessage
            message={[
              `${t(
                'text:To_decline_the_assignment_click_on_the_Decline_Assignment_button_at_the_bottom_of_the_page',
              )} ${t('text:Declined_dealer_can_be_reinstated')}`,
              t('text:Click_on_the_arrow_button_to_view_detailed_information_of_the_Uploaded_Dealers'),
            ]}
          />
        ))}
      <div className="content-area">
        <SectionTitle title={t(`text:Uploaded_Dealer_Information`)} />
        <FormBorder>
          <FormContents>
            <div className="row">
              <FormValue label={t(`text:Uploaded_Dealer_Name`)} value={anchorDealerInfo?.name} />
              <FormValue label={t(`text:Tax_Code`)} value={anchorDealerInfo?.taxCode} />
            </div>
            <div className="row">
              <FormValue label={t(`text:Company_Registration_Number`)} value={anchorDealerInfo?.businessCode} />
              <FormValue col={3} label={t(`text:Telephone`)} value={anchorDealerInfo?.telephone} />
              <FormValue col={3} label={t(`text:Fax`)} value={anchorDealerInfo?.fax} />
            </div>
            <div className="row">
              <FormValue label={t(`text:Legal_Representative_Name`)} value={anchorDealerInfo?.representativeName} />
              <FormValue
                col={3}
                label={t(`text:Legal_Representative_Title`)}
                value={anchorDealerInfo?.representativePosition}
              />
              <FormValue
                col={3}
                label={t(`text:Legal_Representative_Email`)}
                value={anchorDealerInfo?.representativeEmail}
              />
            </div>
            <div className="row">
              <FormValue col={12} label={t(`text:Registered_Office_Address`)} value={anchorDealerInfo?.address} />
            </div>
          </FormContents>
        </FormBorder>
      </div>

      <RelatedAnchorAgreementList
        relatedAnchorAgreement={relatedAnchorAgreement}
        pageable={anchorAgreementPageable}
        paginate={anchorAgreementPaginate}
      />

      {!isAdmin && anchorDealerInfo?.declined === false && (
        <div className="content-area flex-end mt-3">
          <Button onClick={handleClickDeclineAssignment} color={ButtonColorEnum.RED} size={ButtonSizeEnum.LG}>
            {t('text:Decline_Assignment')}
          </Button>
        </div>
      )}
      <div className="content-area">
        <SectionTitle title={t(`text:Information_Edit_History`)} />
        <TableBorder>
          <TableHeader header={INFORMATION_EDIT_HISTORY_HEADERS} />
          <TableBody numOfCol={INFORMATION_EDIT_HISTORY_HEADERS.length}>
            {anchorDealerHistoryList?.map((item, index) => (
              <Tr key={index}>
                <Td data={item.updatedDateTime} format="datetime" />
                <Td data={item.anchorName} />
                <Td data={item.declined ? 'Y' : 'N'} />
              </Tr>
            ))}
          </TableBody>
        </TableBorder>
        <Pagination pageable={historyPageable} paginate={historyPaginate} />
      </div>
    </>
  );
}

export default FinancierAnchorDealerAssignmentDetail;
