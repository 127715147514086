import type Pageable from 'models/Pageable';
import type { CalculateForLoanRequestVO, CalculateForLoanRequestVOModel } from 'models/vo/CalculateForLoanRequestVO';
import { formattingToCalculateForLoanRequestVOModel } from 'models/vo/CalculateForLoanRequestVO';
import type { LoanDetailVO, LoanDetailVOModel } from 'models/vo/LoanDetailVO';
import { formattingToLoanDetailVOModel } from 'models/vo/LoanDetailVO';
import type { SuccessInvoiceDetailVO, SuccessInvoiceDetailVOModel } from 'models/vo/SuccessInvoiceDetailVO';
import { formattingToSuccessInvoiceDetailVOModel } from 'models/vo/SuccessInvoiceDetailVO';
import { formattingToSSuccessInvoiceForRequestLoanVOModel } from 'models/vo/SuccessInvoiceForRequestLoanVO';
import type {
  SuccessInvoiceForRequestLoanVO,
  SuccessInvoiceForRequestLoanVOModel,
} from 'models/vo/SuccessInvoiceForRequestLoanVO';
import type { SuccessInvoiceVO, SuccessInvoiceVOModel } from 'models/vo/SuccessInvoiceVO';
import { formattingToSuccessInvoiceVOModel } from 'models/vo/SuccessInvoiceVO';
import type { TempLoanVO, TempLoanVOModel } from 'models/vo/TempLoanVO';
import { formattingToTempLoanVOModel } from 'models/vo/TempLoanVO';
import http, { ContentType } from 'utils/http';
import API_DE from 'utils/http/api/dealer';
import type {
  CreateDeTempLoanByInvoiceRequest,
  DealerSuccessInvoiceListForLoanRequest,
  DealerSuccessInvoiceListRequest,
  DealerSuccessInvoiceLoanRequest,
} from 'utils/http/api/dealer/success-invoices/request';

// 송장 목록 조회
export async function requestDealerSuccessInvoiceList(
  pageNumber: number,
  rowCount: number,
  searchValue: DealerSuccessInvoiceListRequest,
): Promise<Pageable<SuccessInvoiceVOModel[]>> {
  const response = await http.get<Pageable<SuccessInvoiceVO[]>>({
    url: API_DE.SUCCESS_INVOICES.SUCCESS_INVOICE,
    data: {
      pageNumber,
      rowCount,
      ...searchValue,
    },
  });
  const successInvoiceList: SuccessInvoiceVOModel[] = response.content.map((data: SuccessInvoiceVO) =>
    formattingToSuccessInvoiceVOModel(data),
  );
  const successInvoicePage: Pageable<SuccessInvoiceVOModel[]> = {
    ...response,
    content: successInvoiceList,
  };

  return successInvoicePage;
}

// 대출 신청 가능 송장 목록 조회
export async function requestDealerLoanSuccessInvoiceList(
  pageNumber: number,
  rowCount: number,
  searchValue?: DealerSuccessInvoiceListForLoanRequest,
): Promise<Pageable<SuccessInvoiceForRequestLoanVOModel[]>> {
  const response = await http.get<Pageable<SuccessInvoiceForRequestLoanVO[]>>({
    url: API_DE.SUCCESS_INVOICES.SUCCESS_INVOICE_LIST_FOR_REQUEST_LOAN,
    data: {
      pageNumber,
      rowCount,
      ...searchValue,
    },
  });
  const loanSuccessInvoiceList: SuccessInvoiceForRequestLoanVOModel[] = response.content.map(
    (data: SuccessInvoiceForRequestLoanVO) => formattingToSSuccessInvoiceForRequestLoanVOModel(data),
  );
  const loanSuccessInvoicePage: Pageable<SuccessInvoiceForRequestLoanVOModel[]> = {
    ...response,
    content: loanSuccessInvoiceList,
  };

  return loanSuccessInvoicePage;
}

// 송장 목록 상세 조회
export async function requestDealerInvoiceDetail(successInvoiceId: number): Promise<SuccessInvoiceDetailVOModel> {
  const response = await http.get<SuccessInvoiceDetailVO>({
    url: API_DE.SUCCESS_INVOICES.SUCCESS_INVOICE_DETAIL(successInvoiceId),
  });

  return formattingToSuccessInvoiceDetailVOModel(response);
}

// 송장 원본 다운로드
export async function requestDealerSuccessInvoiceDownload(successInvoiceId: number) {
  await http.download(API_DE.SUCCESS_INVOICES.DOWNLOAD_SUCCESS_INVOICE(successInvoiceId));
}

export async function requestDealerLoanRegistration(
  successInvoiceId: number,
  data: DealerSuccessInvoiceLoanRequest,
): Promise<LoanDetailVOModel> {
  const response = await http.post<LoanDetailVO>({
    url: API_DE.SUCCESS_INVOICES.REGISTER_LOAN(successInvoiceId),
    data,
    contentType: ContentType.MULTIPART,
  });

  return formattingToLoanDetailVOModel(response);
}

export async function requestDealerInvoiceCalculateLoanRequest(
  successInvoiceId: number,
  repaymentDate: string,
  requestedAmount: number,
): Promise<CalculateForLoanRequestVOModel> {
  const response = await http.get<CalculateForLoanRequestVO>({
    url: API_DE.SUCCESS_INVOICES.CALCULATE_LOAN_REQUEST,
    data: {
      successInvoiceId,
      repaymentDate,
      requestedAmount,
    },
  });

  return formattingToCalculateForLoanRequestVOModel(response);
}

export async function requestDealerTempLoanByInvoice(successInvoiceId: number): Promise<TempLoanVOModel> {
  const response = await http.get<TempLoanVO>({
    url: API_DE.SUCCESS_INVOICES.TEMP_LOAN(successInvoiceId),
  });

  return formattingToTempLoanVOModel(response);
}

export async function requestDealerTempLoanByInvoiceRegistration(
  successInvoiceId: number,
  data: CreateDeTempLoanByInvoiceRequest,
): Promise<TempLoanVOModel> {
  const response = await http.post<TempLoanVO>({
    url: API_DE.SUCCESS_INVOICES.TEMP_LOAN(successInvoiceId),
    data,
    contentType: ContentType.MULTIPART,
  });

  return formattingToTempLoanVOModel(response);
}
