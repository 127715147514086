import { useEffect, useState } from 'react';
import type { UseFormMethods } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import Exporter from 'components/stateless/Exporter/Exporter';
import SearchFinancierModal from 'components/stateless/Modal/system/SearchFinancierModal';
import Tab from 'components/stateless/TabManager/Tab';
import { HeaderTitle } from 'components/stateless/Title/HeaderTitle';
import { FINANCIER_CLIENT_TYPE } from 'enums';
import useMounted from 'hooks/useMounted';
import type Pageable from 'models/Pageable';
import type { EnterpriseVOModel } from 'models/vo/EnterpriseVO';
import type { FinancierClientVOModel } from 'models/vo/FinancierClientVO';
import type { PlatformTransactionVOModel } from 'models/vo/PlatformTransactionVO';
import {
  requestSystemPlatformTransactionExecutionList,
  requestSystemPlatformTransactionFeeList,
} from 'utils/http/api/system/platform-transaction';
import type { SystemPlatformTransactionListRequest } from 'utils/http/api/system/platform-transaction/requests';
import { ModalSize, ModalType } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';
import { getParsedSearchParams } from 'utils/searchParams';
import type { ExportSpreadSheetProps } from 'utils/spreadSheet/types';

import SystemSearchFinancierClientModal from './SystemSearchFinancierClientModal';
import { systemTransactionPerformanceListPageExcelExportPropsData } from './features/systemTransactionPerformanceListPageExcelExportPropsData';
import {
  SY_FEE_LIST_QS_KEY,
  SY_PERFORMANCE_LIST_QS_KEY,
  useSystemTransactionPerformanceListState,
} from './features/useSystemTransactionPerformanceListState';
import SystemTransactionPerformanceListTabView from './sections/SystemTransactionPerformanceListTabView';

import type { SystemTransactionPerformanceListSearchedModalNameInfoDataType } from './features/types';

const getConstants = () => {
  const mounted = useMounted();
  const modal = useModal();
  const history = useHistory();
  const { t } = useTranslation(['format']);

  const EXPORT_MAX_ROW_COUNT = 1000;

  return {
    mounted,
    modal,
    t,
    history,
    EXPORT_MAX_ROW_COUNT,
  };
};

function SystemTransactionPerformanceListPage() {
  const { history, mounted, modal, t, EXPORT_MAX_ROW_COUNT } = getConstants();

  // performance list state
  const [performanceSearchedModalNameInfo, setPerformanceSearchedModalNameInfo] = useState<
    SystemTransactionPerformanceListSearchedModalNameInfoDataType | undefined
  >();
  const [performanceSearchedFinancierName, setPerformanceSearchedFinancierName] = useState<string | undefined>();

  // fee list state
  const [feeSearchedModalNameInfo, setFeeSearchedModalNameInfo] =
    useState<SystemTransactionPerformanceListSearchedModalNameInfoDataType>();
  const [feeSearchedFinancierName, setFeeSearchedFinancierName] = useState<string | undefined>();
  const performanceListUseForm = useForm<SystemPlatformTransactionListRequest>();
  const feeListUseForm = useForm<SystemPlatformTransactionListRequest>();

  const {
    state,
    getSystemPerformanceList,
    performanceListPageable,
    getSystemPerformanceLisPaginate,
    getSystemFeeList,
    getSystemFeeListPaginate,
    transactionFeeListPageable,
  } = useSystemTransactionPerformanceListState();

  useEffect(() => {
    if (mounted) {
      history.push({ search: '' });
    }
  }, [mounted]);

  const resetForm = (useForm: UseFormMethods<SystemPlatformTransactionListRequest>, isPerformanceListTab: boolean) => {
    useForm.reset({});
    if (isPerformanceListTab) {
      setPerformanceSearchedModalNameInfo(prevState => ({
        ...prevState,
        financierName: undefined,
        anchorName: undefined,
        dealerName: undefined,
      }));
    } else {
      setFeeSearchedModalNameInfo(prevState => ({
        ...prevState,
        financierName: undefined,
        anchorName: undefined,
        dealerName: undefined,
      }));
    }
  };

  const showSearchFinancierNameModal = (
    useForm: UseFormMethods<SystemPlatformTransactionListRequest>,
    isPerformanceListTab: boolean,
  ) => {
    const setSelectedFinancierInfo = (data: EnterpriseVOModel) => {
      useForm.setValue('financierId', data.enterpriseId);
      // reset hidden input
      useForm.setValue('anchorClientId', undefined);
      useForm.setValue('dealerClientId', undefined);
      if (isPerformanceListTab) {
        setPerformanceSearchedModalNameInfo(prevState => ({
          ...prevState,
          financierName: data.enterpriseName,
          anchorName: undefined,
          dealerName: undefined,
        }));
      } else {
        setFeeSearchedModalNameInfo(prevState => ({
          ...prevState,
          financierName: data.enterpriseName,
          anchorName: undefined,
          dealerName: undefined,
        }));
      }
    };

    modal.show(<SearchFinancierModal modalId={modal.id} getSelectedData={setSelectedFinancierInfo} />, {
      modalSize: ModalSize.XL,
      title: t('text:Search_Financier'),
      closeBtnText: t('text:Close'),
    });
  };

  const showSearchAnchorNameModal = (
    useForm: UseFormMethods<SystemPlatformTransactionListRequest>,
    isPerformanceListTab: boolean,
  ) => {
    const setSelectedAnchorInfo = (data: FinancierClientVOModel) => {
      useForm.setValue('anchorClientId', data.financierClientId);
      if (isPerformanceListTab) {
        setPerformanceSearchedModalNameInfo(prevState => ({
          ...prevState,
          anchorName: data.financierClientName,
        }));
      } else {
        setFeeSearchedModalNameInfo(prevState => ({
          ...prevState,
          anchorName: data.financierClientName,
        }));
      }
    };

    modal.show(
      <SystemSearchFinancierClientModal
        modalId={modal.id}
        clientType={FINANCIER_CLIENT_TYPE.ANCHOR}
        getSelectedData={setSelectedAnchorInfo}
        financierId={useForm.getValues('financierId')}
      />,
      {
        title: t('text:Search_Anchor'),
        modalType: ModalType.ALERT,
        modalSize: ModalSize.XL,
        closeBtnText: t('text:Close'),
      },
    );
  };

  const showSearchPartnerNameModal = (
    useForm: UseFormMethods<SystemPlatformTransactionListRequest>,
    isPerformanceListTab: boolean,
  ) => {
    const setSelectedDealerInfo = (data: FinancierClientVOModel) => {
      useForm.setValue('dealerClientId', data.financierClientId);
      if (isPerformanceListTab) {
        setPerformanceSearchedModalNameInfo(prevState => ({
          ...prevState,
          dealerName: data.financierClientName,
        }));
      } else {
        setFeeSearchedModalNameInfo(prevState => ({
          ...prevState,
          dealerName: data.financierClientName,
        }));
      }
    };

    modal.show(
      <SystemSearchFinancierClientModal
        modalId={modal.id}
        clientType={FINANCIER_CLIENT_TYPE.DEALER}
        getSelectedData={setSelectedDealerInfo}
        financierId={useForm.getValues('financierId')}
      />,
      {
        title: t('text:Search_Partner'),
        closeBtnText: t('text:Close'),
        modalType: ModalType.ALERT,
        modalSize: ModalSize.XL,
      },
    );
  };

  const onClickSearch = async (isPerformanceListTab: boolean) => {
    if (isPerformanceListTab) {
      await getSystemPerformanceList(1, performanceListPageable.sizePerPage, performanceListUseForm.getValues());
      setPerformanceSearchedFinancierName(performanceSearchedModalNameInfo?.financierName);
    } else {
      await getSystemFeeList(1, transactionFeeListPageable.sizePerPage, feeListUseForm.getValues());
      setFeeSearchedFinancierName(feeSearchedModalNameInfo?.financierName);
    }
  };

  const onClickExport = async (e: any, isPerformanceListTab: boolean) => {
    e.preventDefault();

    const {
      platformTransactionListMergedCells,
      platformTransactionListColumns,
      platformTransactionListJsonArrayData,
      searchedResultInfoCells,
      totalAmountColumnsCells,
      totalAmountDataCells,
    } = systemTransactionPerformanceListPageExcelExportPropsData(
      EXPORT_MAX_ROW_COUNT,
      isPerformanceListTab ? performanceSearchedFinancierName : feeSearchedFinancierName,
      isPerformanceListTab
        ? getParsedSearchParams(SY_PERFORMANCE_LIST_QS_KEY).formSearchData
        : getParsedSearchParams(SY_FEE_LIST_QS_KEY).formSearchData,
      t,
    );

    let excelExportResponse: Pageable<PlatformTransactionVOModel[]>;
    let data;

    try {
      if (isPerformanceListTab) {
        data = getParsedSearchParams(SY_PERFORMANCE_LIST_QS_KEY).formSearchData;

        excelExportResponse = await requestSystemPlatformTransactionExecutionList(0, EXPORT_MAX_ROW_COUNT, data);
      } else {
        data = getParsedSearchParams(SY_FEE_LIST_QS_KEY).formSearchData;
        excelExportResponse = await requestSystemPlatformTransactionFeeList(0, EXPORT_MAX_ROW_COUNT, data);
      }

      const excelExporterProps: ExportSpreadSheetProps<any> = {
        jsonArrayData: platformTransactionListJsonArrayData(excelExportResponse),
        columns: platformTransactionListColumns,
        options: {
          mergedCells: [...platformTransactionListMergedCells],
          rowHeight: [
            { position: 13, height: 30 },
            { position: 14, height: 15 },
          ],
          columnLineNumber: 13,
          etcInsertData: [
            ...searchedResultInfoCells,
            ...totalAmountColumnsCells,
            ...totalAmountDataCells('disbursed'),
            ...totalAmountDataCells('collateral'),
            ...totalAmountDataCells('fee'),
          ],
        },
      };

      modal.show(<Exporter spreadSheetExporterProps={excelExporterProps} />, {
        title: t('text:Export_File'),
        closeBtnText: t('text:Close'),
      });
    } catch (e: any) {
      modal.show(e);
    }
  };

  return (
    <>
      <HeaderTitle title={t('text:Transaction_Performance')} />
      <Tab
        tabList={[
          {
            tabName: t('text:Performance'),
          },
          {
            tabName: t('text:Transaction_Fee'),
          },
        ]}
        tabViewList={[
          <SystemTransactionPerformanceListTabView
            key="performance"
            data={state.performanceList}
            useForm={performanceListUseForm}
            searchedModalNameInfo={performanceSearchedModalNameInfo}
            searchedFinancierName={performanceSearchedFinancierName}
            searchedResultInfo={getParsedSearchParams(SY_PERFORMANCE_LIST_QS_KEY).formSearchData}
            onClickSearch={() => onClickSearch(true)}
            onClickReset={() => {
              resetForm(performanceListUseForm, true);
            }}
            onClickExport={e => onClickExport(e, true)}
            showSearchFinancierNameModal={() => {
              showSearchFinancierNameModal(performanceListUseForm, true);
            }}
            showSearchAnchorNameModal={() => {
              showSearchAnchorNameModal(performanceListUseForm, true);
            }}
            showSearchPartnerNameModal={() => {
              showSearchPartnerNameModal(performanceListUseForm, true);
            }}
            pageable={performanceListPageable}
            paginate={getSystemPerformanceLisPaginate}
          />,
          <SystemTransactionPerformanceListTabView
            key="fee"
            data={state.feeList}
            useForm={feeListUseForm}
            searchedModalNameInfo={feeSearchedModalNameInfo}
            searchedFinancierName={feeSearchedFinancierName}
            searchedResultInfo={getParsedSearchParams(SY_FEE_LIST_QS_KEY).formSearchData}
            onClickSearch={() => onClickSearch(false)}
            onClickReset={() => {
              resetForm(feeListUseForm, false);
            }}
            onClickExport={e => onClickExport(e, false)}
            showSearchFinancierNameModal={() => {
              showSearchFinancierNameModal(feeListUseForm, false);
            }}
            showSearchAnchorNameModal={() => {
              showSearchAnchorNameModal(feeListUseForm, false);
            }}
            showSearchPartnerNameModal={() => {
              showSearchPartnerNameModal(feeListUseForm, false);
            }}
            pageable={transactionFeeListPageable}
            paginate={getSystemFeeListPaginate}
          />,
        ]}
      />
    </>
  );
}
export default SystemTransactionPerformanceListPage;
