import type { SelectOptionType } from 'components/stateless/Select/Select';
import {
  ANCHOR_AGREEMENT_STATUS,
  ANCHOR_DEALER_STATUS,
  AR_COMMISSION_CREATE_PHASE,
  AUTHORITY_TYPE,
  AUTHORIZER_STATUS,
  BANK_CODE_APPROVAL_TYPE,
  BASIS_INTEREST_TYPE,
  COLLATERAL_TYPE,
  COMMON_APPROVAL_TYPE,
  CURRENCY_TYPE,
  DEALER_AGREEMENT_STATUS,
  DEALER_IDENTIFIER_TYPE,
  DISBURSEMENT_ACCOUNT_TYPE,
  DOCUMENT_FORM_TYPE,
  EARLY_REPAYMENT_REQUEST_STATUS,
  ENTERPRISE_STATUS,
  ENTERPRISE_TYPE,
  EXECUTION_STATUS,
  EXPORT_DOCUMENT_TYPE,
  EXTENSION_REQUEST_STATUS,
  FINANCIAL_SETTING_TYPE,
  FINANCIER_TYPE,
  LOAN_LIMIT_CHECK_TYPE,
  LOAN_OPTION,
  LOAN_TERM_TYPE,
  OTP_TYPE,
  PURPOSE_TYPE,
  REPORT_TYPE,
  SUCCESS_AR_STATUS,
  SUCCESS_INVOICE_STATUS,
  SUPPORTED_COLLATERAL_TYPE,
  USER_STATUS,
  WAITING_AR_APPROVAL_PHASE_STATUS,
  WAITING_INVOICE_APPROVAL_PHASE_STATUS,
} from 'enums';
import i18n from 'locales/i18n';

export const getSelectOptionList = (key: OptionKeyTypes): SelectOptionType[] => {
  switch (key) {
    case 'ACTIVE_STATUS':
      return [
        { label: i18n.t('text:Active'), value: true },
        { label: i18n.t('text:Deactivated'), value: false },
      ];
    case 'DELETE_ACTIVATION_STATUS':
      return [
        { label: i18n.t('text:Active'), value: false },
        { label: i18n.t('text:Deactivated'), value: true },
      ];
    case 'FINANCING_ELIGIBILITY':
      return [
        { label: i18n.t('text:Eligible'), value: false },
        { label: i18n.t('text:Ineligible'), value: true },
      ];
    case 'ANCHOR_AGREEMENT_STATUS':
      return [
        { label: i18n.t('text:Not_Suspended'), value: ANCHOR_AGREEMENT_STATUS.ACTIVATED },
        { label: i18n.t('text:Suspended'), value: ANCHOR_AGREEMENT_STATUS.SUSPENDED },
      ];
    case 'AUTHORITY_TYPE':
      return [
        { label: i18n.t('text:Admin'), value: AUTHORITY_TYPE.ADMIN },
        { label: i18n.t('text:Admin_Operator'), value: AUTHORITY_TYPE.HQ_OPERATOR },
        { label: i18n.t('text:Authorizer'), value: AUTHORITY_TYPE.AUTHORIZER },
        { label: i18n.t('text:Operator'), value: AUTHORITY_TYPE.OPERATOR },
      ];
    case 'AUTHORIZER_STATUS':
      return [
        { label: i18n.t('text:Active'), value: AUTHORIZER_STATUS.ACTIVATED },
        { label: i18n.t('text:Invitation_Email_Sent'), value: AUTHORIZER_STATUS.MAIL_SENT },
        { label: i18n.t('text:Input'), value: AUTHORIZER_STATUS.NONE },
      ];
    case 'BANK_ACCOUNT_VALIDITY_OPTIONS':
      return [
        { label: i18n.t('text:Yes'), value: 'Yes' },
        { label: i18n.t('text:No'), value: 'No' },
      ];
    case 'BANK_CODE_APPROVAL_TYPE':
      return [
        { label: i18n.t('text:Requested'), value: BANK_CODE_APPROVAL_TYPE.FINANCIER_REQUEST },
        { label: i18n.t('text:Cancelled'), value: BANK_CODE_APPROVAL_TYPE.FINANCIER_CANCEL },
        { label: i18n.t('text:Rejected'), value: BANK_CODE_APPROVAL_TYPE.SYSTEM_REJECTED },
      ];
    case 'BASIS_INTEREST_TYPE':
      return [
        { label: BASIS_INTEREST_TYPE.FIXED, value: BASIS_INTEREST_TYPE.FIXED },
        { label: BASIS_INTEREST_TYPE.LIBOR, value: BASIS_INTEREST_TYPE.LIBOR },
        { label: BASIS_INTEREST_TYPE.STIME, value: BASIS_INTEREST_TYPE.STIME },
        { label: BASIS_INTEREST_TYPE.CUSTOM, value: BASIS_INTEREST_TYPE.CUSTOM },
      ];
    case 'BLOCK_STATUS':
      return [
        { label: i18n.t('text:Active'), value: false },
        { label: i18n.t('text:Blocked'), value: true },
      ];
    case 'BOOLEAN':
      return [
        { label: i18n.t('text:Yes'), value: true },
        { label: i18n.t('text:No'), value: false },
      ];
    case 'COLLATERAL_TYPE':
      return [
        { label: i18n.t('text:Vendor_Finance'), value: COLLATERAL_TYPE.AR },
        { label: i18n.t('text:Dealer_Finance'), value: COLLATERAL_TYPE.INVOICE },
      ];
    case 'COMMON_APPROVAL_TYPE':
      return [
        { label: i18n.t('text:Requested'), value: COMMON_APPROVAL_TYPE.OPERATOR_REQUEST },
        { label: i18n.t('text:Reverted'), value: COMMON_APPROVAL_TYPE.AUTHORIZER_RETURNED },
        { label: i18n.t('text:Cancelled'), value: COMMON_APPROVAL_TYPE.OPERATOR_CANCEL },
      ];
    case 'CURRENCY_TYPE':
      return [
        { label: i18n.t('text:USD'), value: CURRENCY_TYPE.USD },
        { label: i18n.t('text:IDR'), value: CURRENCY_TYPE.IDR },
        { label: i18n.t('text:INR'), value: CURRENCY_TYPE.INR },
        { label: i18n.t('text:JPY'), value: CURRENCY_TYPE.JPY },
        { label: i18n.t('text:KRW'), value: CURRENCY_TYPE.KRW },
        { label: i18n.t('text:VND'), value: CURRENCY_TYPE.VND },
      ];
    case 'DATE_BUSINESS_DAY_CONVENTION':
      return [
        { label: i18n.t('text:None'), value: 0 },
        { label: i18n.t('text:Previous_Business_Day'), value: -1 },
        { label: i18n.t('text:Next_Business_Day'), value: 1 },
      ];
    case 'DEALER_AGREEMENT_STATUS':
      return [
        { label: i18n.t('text:Not_Suspended'), value: DEALER_AGREEMENT_STATUS.ACTIVATED },
        { label: i18n.t('text:Suspended'), value: DEALER_AGREEMENT_STATUS.SUSPENDED },
      ];
    case 'DEALER_IDENTIFIER_TYPE':
      return [
        { label: i18n.t('text:Tax_Code'), value: DEALER_IDENTIFIER_TYPE.TAX_CODE },
        { label: i18n.t('text:Partner_Code_of_Anchor'), value: DEALER_IDENTIFIER_TYPE.DEALER_CODE_BY_ANCHOR },
      ];
    case 'DOCUMENT_FORM_TYPE':
      return [
        {
          label: i18n.t('text:Dealer_Finance_-_Financing_Application'),
          value: DOCUMENT_FORM_TYPE.DEALER_FINANCING_LOAN_REQUEST,
        },
        {
          label: i18n.t('text:Dealer_Finance_Financing_Agreement'),
          value: DOCUMENT_FORM_TYPE.DEALER_FINANCING_LOAN_AGREEMENT,
        },
        {
          label: i18n.t('text:Vendor_Finance_-_Financing_Application'),
          value: DOCUMENT_FORM_TYPE.VENDOR_FINANCING_LOAN_REQUEST,
        },
        {
          label: i18n.t('text:Vendor_Finance_Financing_Agreement'),
          value: DOCUMENT_FORM_TYPE.VENDOR_FINANCING_LOAN_AGREEMENT,
        },
        {
          label: i18n.t('text:Additional_Document'),
          value: DOCUMENT_FORM_TYPE.ADDITIONAL_DOCUMENT,
        },
        { label: i18n.t('text:Bulk_Transfer_Application'), value: DOCUMENT_FORM_TYPE.BULK_TRANSFER },
        { label: i18n.t('text:Remittance_Application'), value: DOCUMENT_FORM_TYPE.REMITTANCE },
      ];
    case 'EARLY_REPAYMENT_REQUEST_STATUS':
      return [
        { label: i18n.t('text:Requested'), value: EARLY_REPAYMENT_REQUEST_STATUS.REQUESTED },
        { label: i18n.t('text:Completed'), value: EARLY_REPAYMENT_REQUEST_STATUS.COMPLETED },
        { label: i18n.t('text:Rejected'), value: EARLY_REPAYMENT_REQUEST_STATUS.EXPIRED },
      ];
    case 'ENTERPRISE_TYPE':
      return [
        { label: i18n.t('text:Financier'), value: ENTERPRISE_TYPE.FI },
        { label: i18n.t('text:Anchor'), value: ENTERPRISE_TYPE.AC },
        { label: i18n.t('text:Partner'), value: ENTERPRISE_TYPE.DE },
      ];
    case 'EXTENSION_REQUEST_STATUS':
      return [
        { label: i18n.t('text:Requested'), value: EXTENSION_REQUEST_STATUS.REQUESTED },
        { label: i18n.t('text:Reviewed'), value: EXTENSION_REQUEST_STATUS.PREAPPROVED },
        { label: i18n.t('text:Approved'), value: EXTENSION_REQUEST_STATUS.APPROVED },
        { label: i18n.t('text:Approval_Reverted'), value: EXTENSION_REQUEST_STATUS.RETURNED },
        { label: i18n.t('text:Rejected'), value: EXTENSION_REQUEST_STATUS.REJECTED },
        { label: i18n.t('text:Extended'), value: EXTENSION_REQUEST_STATUS.EXTENDED },
      ];
    case 'FINANCIER_TYPE':
      return [
        { label: i18n.t('text:KB'), value: FINANCIER_TYPE.KB },
        { label: i18n.t('text:KB_Capital'), value: FINANCIER_TYPE.KBC },
        { label: i18n.t('text:Woori_Bank'), value: FINANCIER_TYPE.WOORI },
        { label: i18n.t('text:Shinhan_Bank'), value: FINANCIER_TYPE.SHINHAN },
        { label: i18n.t('text:KEB_Hana'), value: FINANCIER_TYPE.KEBHANA },
        { label: i18n.t('text:Citi_Bank'), value: FINANCIER_TYPE.CITIBANK },
        { label: i18n.t('text:Manual'), value: FINANCIER_TYPE.MANUAL },
      ];
    case 'LOAN_LIMIT_CHECK_TYPE':
      return [
        { label: i18n.t('text:Both'), value: LOAN_LIMIT_CHECK_TYPE.BOTH },
        { label: i18n.t('text:Invoice_Registration'), value: LOAN_LIMIT_CHECK_TYPE.INVOICE },
        { label: i18n.t('text:Loan_Application'), value: LOAN_LIMIT_CHECK_TYPE.LOAN },
        { label: i18n.t('text:None'), value: LOAN_LIMIT_CHECK_TYPE.NONE },
      ];
    case 'LOAN_OPTION':
      return [
        { label: i18n.t('text:Yes'), value: LOAN_OPTION.AUTO_APPROVE },
        { label: i18n.t('text:No'), value: LOAN_OPTION.AUTO_REQUEST },
      ];
    case 'LOAN_TERM_TYPE':
      return [
        { label: i18n.t('text:days'), value: LOAN_TERM_TYPE.DAILY },
        { label: i18n.t('text:month'), value: LOAN_TERM_TYPE.MONTHLY },
      ];
    case 'OTP_TYPE':
      return [
        { label: i18n.t('text:None'), value: OTP_TYPE.NONE },
        { label: i18n.t('text:AOTP'), value: OTP_TYPE.AOTP },
        { label: i18n.t('text:Email'), value: OTP_TYPE.EMAIL },
        { label: i18n.t('text:OTP'), value: OTP_TYPE.OTP },
        { label: i18n.t('text:SMS'), value: OTP_TYPE.SMS },
      ];
    case 'PAYMENT_SUPPORT_TYPE':
      return [
        { label: i18n.t('text:Yes_(Co-operation_&_Payment)'), value: true },
        { label: i18n.t('text:No_(Co-operation)'), value: false },
      ];
    case 'REPORT_TYPE':
      return [
        { label: i18n.t('text:Repayment_Report'), value: REPORT_TYPE.REPAYMENT_REPORT },
        { label: i18n.t('text:Utilization_Report'), value: REPORT_TYPE.UTILIZATION_REPORT },
      ];
    case 'SUCCESS_AR_STATUS':
      return [
        { label: i18n.t('text:Pending'), value: SUCCESS_AR_STATUS.REGISTERED },
        { label: i18n.t('text:Completed'), value: SUCCESS_AR_STATUS.SETTLED },
        { label: i18n.t('text:Not_Proceeded'), value: SUCCESS_AR_STATUS.UNSETTLED },
        { label: i18n.t('text:Failed'), value: SUCCESS_AR_STATUS.FAILED },
      ];
    case 'SUCCESS_INVOICE_STATUS':
      return [
        { label: i18n.t('text:Pending'), value: SUCCESS_INVOICE_STATUS.REGISTERED },
        { label: i18n.t('text:Completed'), value: SUCCESS_INVOICE_STATUS.SETTLED },
        { label: i18n.t('text:Not_Proceeded'), value: SUCCESS_INVOICE_STATUS.UNSETTLED },
      ];
    case 'SUPPORTED_COLLATERAL_TYPE':
      return [
        { label: i18n.t('text:Vendor_Finance'), value: SUPPORTED_COLLATERAL_TYPE.AR },
        { label: i18n.t('text:Dealer_Finance'), value: SUPPORTED_COLLATERAL_TYPE.INVOICE },
        { label: i18n.t('text:Both'), value: SUPPORTED_COLLATERAL_TYPE.BOTH },
      ];
    case 'SEND_REPORT':
      return [
        { label: i18n.t('text:Active'), value: true },
        { label: i18n.t('text:Deactivated'), value: false },
      ];
    case 'USER_STATUS':
      return [
        { label: i18n.t('code:user-status.REGISTERED'), value: USER_STATUS.REGISTERED },
        { label: i18n.t('code:user-status.INVITED'), value: USER_STATUS.INVITED },
        { label: i18n.t('code:user-status.ACTIVATED'), value: USER_STATUS.ACTIVATED },
        { label: i18n.t('code:user-status.SUSPENDED'), value: USER_STATUS.SUSPENDED },
      ];
    case 'WAITING_AR_APPROVAL_PHASE_STATUS':
      return [
        { label: i18n.t('text:Input'), value: WAITING_AR_APPROVAL_PHASE_STATUS.INITIATED },
        { label: i18n.t('text:Registration_Requested'), value: WAITING_AR_APPROVAL_PHASE_STATUS.REQUESTED },
        { label: i18n.t('text:Reviewed'), value: WAITING_AR_APPROVAL_PHASE_STATUS.REGISTERED },
        { label: i18n.t('text:Registered'), value: WAITING_AR_APPROVAL_PHASE_STATUS.CONFIRMED },
      ];
    case 'WAITING_INVOICE_APPROVAL_PHASE_STATUS':
      return [
        { label: i18n.t('text:Input'), value: WAITING_INVOICE_APPROVAL_PHASE_STATUS.INITIATED },
        { label: i18n.t('text:Registration_Requested'), value: WAITING_INVOICE_APPROVAL_PHASE_STATUS.REQUESTED },
        { label: i18n.t('text:Reviewed'), value: WAITING_INVOICE_APPROVAL_PHASE_STATUS.REGISTERED },
        { label: i18n.t('text:Registered'), value: WAITING_INVOICE_APPROVAL_PHASE_STATUS.CONFIRMED },
      ];
    case 'ANCHOR_AGREEMENT_LIST_FOR_PARTNERS_REQUEST':
      return [
        { label: i18n.t('text:Anchor_Master_Agreement_Number'), value: 'contractNo' },
        { label: i18n.t('text:Currency'), value: 'currencyType' },
        { label: i18n.t('text:Responsible_Financier_Name'), value: 'financierName' },
        { label: i18n.t('text:Responsible_Branch_Name'), value: 'branchName' },
      ];
    case 'FINANCIER_ANCHOR_AGREEMENT_LIST_REQUEST':
      return [
        { label: i18n.t('text:Anchor_Master_Agreement_Number'), value: 'contractNo' },
        { label: i18n.t('text:Currency'), value: 'currencyType' },
        { label: i18n.t('text:Anchor_Client_Code'), value: 'anchorClientCode' },
        { label: i18n.t('text:Anchor_Name'), value: 'anchorClientName' },
        { label: i18n.t('text:Effective_Date'), value: 'startDate' },
        { label: i18n.t('text:Expiration_Date'), value: 'expiryDate' },
        { label: i18n.t('text:Financier_Name'), value: 'financierName' },
      ];
    case 'FINANCIER_CLIENT_LIST_REQUEST':
      return [
        { label: i18n.t('text:Client_Code'), value: 'financierClientCode' },
        { label: i18n.t('text:Company_Name'), value: 'financierClientName' },
      ];
    case 'COMMON_BANK_CODE_LIST_REQUEST':
      return [
        { label: i18n.t('text:Bank_Name'), value: 'bankName' },
        { label: i18n.t('text:Bank_Code'), value: 'bankCode' },
        { label: i18n.t('text:Branch_Name'), value: 'branchName' },
      ];
    case 'ANCHOR_USER_NOT_RELATED_ANCHOR_AGREEMENT_LIST_REQUEST':
      return [
        { label: i18n.t('text:Anchor_Master_Agreement_Number'), value: 'contractNo' },
        { label: i18n.t('text:Responsible_Financier_Name'), value: 'financierName' },
        { label: i18n.t('text:Responsible_Branch_Name'), value: 'branchName' },
      ];
    case 'ANCHOR_AGREEMENT_LIST_FOR_ARS_REQUEST':
      return [
        { label: i18n.t('text:Financier_Name'), value: 'financierName' },
        { label: i18n.t('text:Anchor_Master_Agreement_Number'), value: 'contractNo' },
      ];

    case 'DEALER_ANCHOR_AGREEMENT_LIST_FOR_ARS_REQUEST':
      return [
        { label: i18n.t('text:Financier_Name'), value: 'financierName' },
        { label: i18n.t('text:Anchor_Master_Agreement_Number'), value: 'contractNo' },
      ];

    case 'FINANCIER_ANCHOR_AGREEMENT_LIST_FOR_PARTNERS_REQUEST':
      return [
        { label: i18n.t('text:Client_Code'), value: 'anchorClientCode' },
        { label: i18n.t('text:Anchor_Master_Agreement_Number'), value: 'contractNo' },
        { label: i18n.t('text:Anchor_Name'), value: 'anchorClientName' },
      ];
    case 'FINANCIER_DEALER_AGREEMENT_LIST_FOR_REPORT_RECEIVER_REQUEST':
      return [
        { label: i18n.t('text:Partner_Name'), value: 'dealerClientName' },
        { label: i18n.t('text:Partner_Client_Code'), value: 'dealerClientCode' },
        { label: i18n.t('text:Partner_Master_Agreement_Number'), value: 'contractNo' },
      ];
    case 'EXPORT_DOCUMENT_TYPE':
      return [
        { label: i18n.t('text:Excel'), value: EXPORT_DOCUMENT_TYPE.EXCEL },
        { label: i18n.t('text:CSV'), value: EXPORT_DOCUMENT_TYPE.CSV },
        { label: i18n.t('text:Adobe_PDF_in_A4'), value: EXPORT_DOCUMENT_TYPE.PDF_A4 },
        { label: i18n.t('text:Adobe_PDF_in_Letter'), value: EXPORT_DOCUMENT_TYPE.PDF_LETTER },
      ];
    case 'BULK_IMPORT_EXPORT_DOCUMENT_TYPE':
      return [
        { label: i18n.t('text:Excel'), value: EXPORT_DOCUMENT_TYPE.EXCEL },
        { label: i18n.t('text:CSV'), value: EXPORT_DOCUMENT_TYPE.CSV },
      ];
    case 'EXECUTION_STATUS':
      return [
        { label: i18n.t('text:None'), value: EXECUTION_STATUS.NONE },
        { label: i18n.t('text:Waiting'), value: EXECUTION_STATUS.REGISTERED },
        { label: i18n.t('text:In_Progress'), value: EXECUTION_STATUS.RUNNING },
        { label: i18n.t('text:Failed'), value: EXECUTION_STATUS.FAILED },
      ];
    case 'ENTERPRISE_STATUS':
      return [
        { label: i18n.t('text:Registered'), value: ENTERPRISE_STATUS.REGISTERED },
        { label: i18n.t('text:Activated'), value: ENTERPRISE_STATUS.ACTIVATED },
        { label: i18n.t('text:Suspended'), value: ENTERPRISE_STATUS.SUSPENDED },
      ];
    case 'OTP_VERIFICATION':
      return [
        { label: i18n.t('text:Applied'), value: false },
        { label: i18n.t('text:Not_Applied'), value: true },
      ];
    case 'PURPOSE_TYPE':
      return [
        { label: i18n.t('text:Buy_raw_material'), value: PURPOSE_TYPE.BUY_RAW_MATERIALS },
        { label: i18n.t('text:Labor_cost'), value: PURPOSE_TYPE.LABOR_COST },
        { label: i18n.t('text:Taxes_and_Fees'), value: PURPOSE_TYPE.TAXES_AND_FEES },
        { label: i18n.t('text:Utility_cost'), value: PURPOSE_TYPE.UTILITY_COST },
        { label: i18n.t('text:Other_cost'), value: PURPOSE_TYPE.OTHER_COST },
      ];
    case 'DISBURSEMENT_ACCOUNT_TYPE':
      return [
        { label: i18n.t('text:Designated_Disbursement_Account'), value: DISBURSEMENT_ACCOUNT_TYPE.DESIGNATED },
        { label: i18n.t('text:Beneficiary_Account'), value: DISBURSEMENT_ACCOUNT_TYPE.BENEFICIARY },
      ];
    case 'FINANCIAL_SETTING_TYPE':
      return [
        { label: i18n.t('code:financier-setting-type.DEFAULT'), value: FINANCIAL_SETTING_TYPE.DEFAULT },
        { label: i18n.t('code:financier-setting-type.ANCHOR'), value: FINANCIAL_SETTING_TYPE.ANCHOR },
        { label: i18n.t('code:financier-setting-type.PARTNER'), value: FINANCIAL_SETTING_TYPE.PARTNER },
      ];
    case 'AR_COMMISSION_CREATE_PHASE':
      return [
        { label: i18n.t('code:ar-commission-create-phase.AR_CREATED'), value: AR_COMMISSION_CREATE_PHASE.AR_CREATED },
        {
          label: i18n.t('code:ar-commission-create-phase.LOAN_DISBURSED'),
          value: AR_COMMISSION_CREATE_PHASE.LOAN_DISBURSED,
        },
      ];
    case 'ANCHOR_DEALER_STATUS':
      return [
        { label: i18n.t('code:anchor-dealer-status.REGISTERED'), value: ANCHOR_DEALER_STATUS.REGISTERED },
        {
          label: i18n.t('code:anchor-dealer-status.ALREADY_REGISTERED_ANCHOR_DEALER'),
          value: ANCHOR_DEALER_STATUS.ALREADY_REGISTERED_ANCHOR_DEALER,
        },
        {
          label: i18n.t('code:anchor-dealer-status.ON_APPROVAL_PROGRESS_ANCHOR_DEALER'),
          value: ANCHOR_DEALER_STATUS.ON_APPROVAL_PROGRESS_ANCHOR_DEALER,
        },
      ];
    default:
      throw new Error('키에 해당하는 선택 옵션 리스트가 없습니다.');
  }
};

export type OptionKeyTypes =
  | 'ACTIVE_STATUS'
  | 'DELETE_ACTIVATION_STATUS'
  | 'FINANCING_ELIGIBILITY'
  | 'ANCHOR_AGREEMENT_STATUS'
  | 'AUTHORITY_TYPE'
  | 'AUTHORIZER_STATUS'
  | 'BANK_ACCOUNT_VALIDITY_OPTIONS'
  | 'BANK_CODE_APPROVAL_TYPE'
  | 'BASIS_INTEREST_TYPE'
  | 'BLOCK_STATUS'
  | 'BOOLEAN'
  | 'COLLATERAL_TYPE'
  | 'COMMON_APPROVAL_TYPE'
  | 'CURRENCY_TYPE'
  | 'DATE_BUSINESS_DAY_CONVENTION'
  | 'DEALER_AGREEMENT_STATUS'
  | 'DEALER_IDENTIFIER_TYPE'
  | 'DOCUMENT_FORM_TYPE'
  | 'EARLY_REPAYMENT_REQUEST_STATUS'
  | 'ENTERPRISE_TYPE'
  | 'EXTENSION_REQUEST_STATUS'
  | 'FINANCIER_TYPE'
  | 'LOAN_LIMIT_CHECK_TYPE'
  | 'LOAN_OPTION'
  | 'LOAN_TERM_TYPE'
  | 'OTP_TYPE'
  | 'PAYMENT_SUPPORT_TYPE'
  | 'REPORT_TYPE'
  | 'SUCCESS_AR_STATUS'
  | 'SUCCESS_INVOICE_STATUS'
  | 'SUPPORTED_COLLATERAL_TYPE'
  | 'SEND_REPORT'
  | 'USER_STATUS'
  | 'WAITING_AR_APPROVAL_PHASE_STATUS'
  | 'WAITING_INVOICE_APPROVAL_PHASE_STATUS'
  | 'ANCHOR_AGREEMENT_LIST_FOR_PARTNERS_REQUEST'
  | 'FINANCIER_ANCHOR_AGREEMENT_LIST_REQUEST'
  | 'FINANCIER_CLIENT_LIST_REQUEST'
  | 'COMMON_BANK_CODE_LIST_REQUEST'
  | 'ANCHOR_USER_NOT_RELATED_ANCHOR_AGREEMENT_LIST_REQUEST'
  | 'ANCHOR_AGREEMENT_LIST_FOR_ARS_REQUEST'
  | 'DEALER_ANCHOR_AGREEMENT_LIST_FOR_ARS_REQUEST'
  | 'FINANCIER_ANCHOR_AGREEMENT_LIST_FOR_PARTNERS_REQUEST'
  | 'FINANCIER_DEALER_AGREEMENT_LIST_FOR_REPORT_RECEIVER_REQUEST'
  | 'EXPORT_DOCUMENT_TYPE'
  | 'BULK_IMPORT_EXPORT_DOCUMENT_TYPE'
  | 'EXECUTION_STATUS'
  | 'ENTERPRISE_STATUS'
  | 'OTP_VERIFICATION'
  | 'PURPOSE_TYPE'
  | 'DISBURSEMENT_ACCOUNT_TYPE'
  | 'FINANCIAL_SETTING_TYPE'
  | 'AR_COMMISSION_CREATE_PHASE'
  | 'ANCHOR_DEALER_STATUS';
