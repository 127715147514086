import { useTranslation } from 'react-i18next';

import Pagination from 'components/stateless/Pagination/Pagination';
import TableBody from 'components/stateless/Table/TableBody';
import TableBorder from 'components/stateless/Table/TableBorder';
import type { HeaderType } from 'components/stateless/Table/TableHeader';
import TableHeader from 'components/stateless/Table/TableHeader';
import Td from 'components/stateless/Table/Td';
import TdLink from 'components/stateless/Table/TdLink';
import Tr from 'components/stateless/Table/Tr';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { ROUTES_FI } from 'constants/routes/financier';
import type { PageableType } from 'hooks/usePageable';
import type Pageable from 'models/Pageable';
import type { PotentialPartnerFinancingApplicationVOModel } from 'models/vo/PotentialPartnerFinancingApplicationVO';
import getStatusTextClass from 'utils/classNames/getStatusTextClass';

import {
  renderBranchCodeData,
  renderBranchNameData,
  renderPotentialPartnerFinancingApplicationStatusText,
} from './logics';
import { renderBranchCodeText, renderBranchNameText } from '../logics';

import type { AcquisitionTabType } from '../../FinancierScPartnerAcquisitionListTabWrapper';

interface FinancierScPartnerAcquisitionListTableProps {
  data: Pageable<PotentialPartnerFinancingApplicationVOModel[]>;
  tab: AcquisitionTabType;
  pageable: PageableType;
  paginate: (pageNumber: number, sizePerPage: number) => Promise<void>;
}

function FinancierScPartnerAcquisitionListTable({
  data,
  tab,
  pageable,
  paginate,
}: FinancierScPartnerAcquisitionListTableProps) {
  const { t } = useTranslation(['format']);

  const SEARCHED_SUBMITTED_TABLE_HEADERS: HeaderType[] = [
    {
      headerText: t('text:Uploaded_Partner_Name'),
    },
    {
      headerText: t('text:Uploaded_Partner_Tax_Code'),
    },
    {
      headerText: t('text:Anchor_Name'),
    },
    {
      headerText: t('text:Anchor_Client_Code'),
    },
    {
      headerText: t('text:Anchor_Master_Agreement_Number'),
    },
    {
      headerText: t('text:Currency'),
    },
    {
      headerText: renderBranchCodeText(tab),
    },
    {
      headerText: renderBranchNameText(tab),
    },
    ...(tab === 'submitted' ? [{ headerText: t('text:Submission_Cancellation') }] : []),
    {
      headerText: '',
      colWidths: 50,
    },
  ];

  return (
    <>
      <div className="division-border" />
      <div className="content-area">
        <SectionTitle title={t('text:Result')} />
        <p className="total-data">
          {t('text:Total')} : {data.totalElements}
        </p>
        <TableBorder>
          <TableHeader header={SEARCHED_SUBMITTED_TABLE_HEADERS} />
          <TableBody numOfCol={SEARCHED_SUBMITTED_TABLE_HEADERS.length}>
            {data?.content?.map((item: PotentialPartnerFinancingApplicationVOModel, index: number) => (
              <Tr key={index}>
                <Td data={item.name} />
                <Td data={item.taxCode} />
                <Td data={item.anchorClientName} />
                <Td data={item.anchorClientCode} />
                <Td data={item.anchorAgreementContractNo} />
                <Td data={item.currencyType} />
                <Td data={renderBranchCodeData(tab, item)} />
                <Td data={renderBranchNameData(tab, item)} />
                {tab === 'submitted' && (
                  <Td
                    className={getStatusTextClass(
                      'POTENTIAL_PARTNER_FINANCING_APPLICATION_STATUS',
                      item.potentialPartnerFinancingApplicationStatus,
                    )}
                    data={renderPotentialPartnerFinancingApplicationStatusText(
                      item.potentialPartnerFinancingApplicationStatus,
                    )}
                  />
                )}
                <TdLink
                  path={ROUTES_FI.MANAGE_UPLOADED_PARTNER.ACQUISITION_DETAIL_BUILD_PATH(
                    item.potentialPartnerFinancingApplicationId,
                  )}
                />
              </Tr>
            ))}
          </TableBody>
        </TableBorder>
        <Pagination pageable={pageable} paginate={paginate} />
      </div>
    </>
  );
}

export default FinancierScPartnerAcquisitionListTable;
