import { useTranslation } from 'react-i18next';

import {
  AR_COMMISSION_STATUS,
  AUTHORIZER_STATUS,
  AWS_EMAIL_EVENT_TYPE,
  COMMON_APPROVAL_TYPE,
  EARLY_REPAYMENT_REQUEST_STATUS,
  EMAIL_SENT_STATUS,
  EXECUTION_STATUS,
  EXTENSION_REQUEST_STATUS,
  LOAN_APPROVAL_TYPE,
  LOAN_STATUS,
  MULTIPLE_LOAN_REQUEST_STATUS,
  SUCCESS_AR_STATUS,
  SUCCESS_INVOICE_STATUS,
  USER_STATUS,
  WAITING_INVOICE_APPROVAL_PHASE_STATUS,
} from 'enums';
import getStatusBadgeClass from 'utils/classNames/getStatusBadgeClass';
import getStatusTextClass from 'utils/classNames/getStatusTextClass';

import './StatusDescriptionModal.scss';

interface StatusDescriptionModalPropsType {
  statusDescriptionModalType: 'TEXT' | 'BOX';
  statusDescriptionEnum:
    | LOAN_STATUS[]
    | LOAN_APPROVAL_TYPE[]
    | WAITING_INVOICE_APPROVAL_PHASE_STATUS[]
    | SUCCESS_INVOICE_STATUS[]
    | COMMON_APPROVAL_TYPE[]
    | EARLY_REPAYMENT_REQUEST_STATUS[]
    | EXTENSION_REQUEST_STATUS[]
    | USER_STATUS[]
    | AUTHORIZER_STATUS[]
    | SUCCESS_AR_STATUS[]
    | EXECUTION_STATUS[]
    | AR_COMMISSION_STATUS[]
    | MULTIPLE_LOAN_REQUEST_STATUS[]
    | EMAIL_SENT_STATUS[]
    | AWS_EMAIL_EVENT_TYPE[];

  statusDescriptionEnumType:
    | 'WAITING_INVOICE_APPROVAL_PHASE_STATUS'
    | 'LOAN_STATUS'
    | 'LOAN_APPROVAL_TYPE'
    | 'SUCCESS_INVOICE_STATUS'
    | 'COMMON_APPROVAL_TYPE'
    | 'EARLY_REPAYMENT_REQUEST_STATUS'
    | 'EXTENSION_REQUEST_STATUS'
    | 'USER_STATUS'
    | 'USER_COMMON_APPROVAL_TYPE'
    | 'AUTHORIZER_STATUS'
    | 'SUCCESS_AR_STATUS'
    | 'EXECUTION_STATUS'
    | 'AR_COMMISSION_STATUS'
    | 'MULTIPLE_LOAN_REQUEST_STATUS'
    | 'MULTIPLE_LOAN_REQUEST_APPROVAL_TYPE' // part of COMMON_APPROVAL_TYPE
    | 'EMAIL_SENT_STATUS'
    | 'AWS_EMAIL_EVENT_TYPE';
}

function StatusDescriptionModal({
  statusDescriptionModalType,
  statusDescriptionEnum,
  statusDescriptionEnumType,
}: StatusDescriptionModalPropsType): JSX.Element {
  const { t } = useTranslation(['format']);

  const renderStatusDescriptionComponent = (): JSX.Element[] => {
    const transTitleText = (title: string): string => {
      switch (statusDescriptionEnumType) {
        case 'WAITING_INVOICE_APPROVAL_PHASE_STATUS':
          switch (title) {
            case WAITING_INVOICE_APPROVAL_PHASE_STATUS.INITIATED:
              return t('text:Input');
            case WAITING_INVOICE_APPROVAL_PHASE_STATUS.REQUESTED:
              return t('text:Registration_Requested');
            case WAITING_INVOICE_APPROVAL_PHASE_STATUS.REGISTERED:
              return t('text:Reviewed');
            case WAITING_INVOICE_APPROVAL_PHASE_STATUS.CONFIRMED:
              return t('text:Registered');
            case WAITING_INVOICE_APPROVAL_PHASE_STATUS.REJECTED:
              return t('text:Rejected');
            case WAITING_INVOICE_APPROVAL_PHASE_STATUS.IN_PROGRESS:
              return t('text:In_Progress');
            default:
              return '';
          }
        case 'LOAN_STATUS':
          switch (title) {
            case LOAN_STATUS.DEALER_CREATED:
              return t('text:Application_Created');
            case LOAN_STATUS.DEALER_REQUESTED:
              return t('text:Applied');
            case LOAN_STATUS.DEALER_CANCELLED:
              return t('text:Application_Cancelled');
            case LOAN_STATUS.REJECTED:
              return t('text:Rejected');
            case LOAN_STATUS.APPROVED:
              return t('text:Approved');
            case LOAN_STATUS.DISBURSED:
              return t('text:Disbursed');
            case LOAN_STATUS.REPAID:
              return t('text:Repaid');
            case LOAN_STATUS.OVERDUE:
              return t('text:Delinquent');
            case LOAN_STATUS.EXPIRATION:
              return t('text:Expired');
            case LOAN_STATUS.RECOURSE_ACTIVATED:
              return t('code:financing-status.RECOURSE_ACTIVATED');
            default:
              return '';
          }
        case 'LOAN_APPROVAL_TYPE':
          switch (title) {
            case LOAN_APPROVAL_TYPE.OPERATOR_APPROVAL:
              return t('text:Financing_Approval_Request');
            case LOAN_APPROVAL_TYPE.AUTHORIZER_RETURNED_APPROVAL:
              return t('text:Financing_Approval_Request_Reverted');
            case LOAN_APPROVAL_TYPE.OPERATOR_OVERDUE:
              return t('text:Delinquent_Status_Request');
            case LOAN_APPROVAL_TYPE.OPERATOR_REQUEST_REJECTED:
              return t('text:Financing_Rejection_Requested');
            case LOAN_APPROVAL_TYPE.AUTHORIZER_RETURNED_REJECTED:
              return t('text:Financing_Rejection_Request_Reverted');
            case LOAN_APPROVAL_TYPE.AUTHORIZER_RETURNED_OVERDUE:
              return t('text:Delinquent_Status_Request_Reverted');
            case LOAN_APPROVAL_TYPE.OPERATOR_REPAID:
              return t('text:Repaid_Status_Request');
            case LOAN_APPROVAL_TYPE.AUTHORIZER_RETURNED_REPAID:
              return t('text:Repaid_Status_Request_Reverted');
            case LOAN_APPROVAL_TYPE.OPERATOR_CLEAR_OVERDUE:
              return t('text:Delinquent_Cancellation_Request');
            case LOAN_APPROVAL_TYPE.AUTHORIZER_RETURNED_CLEAR_OVERDUE:
              return t('text:Delinquent_Cancellation_Request_Reverted');
            case LOAN_APPROVAL_TYPE.OPERATOR_EXPIRATION:
              return t('text:Expired_Status_Request');
            case LOAN_APPROVAL_TYPE.AUTHORIZER_RETURNED_EXPIRATION:
              return t('text:Expired_Status_Request_Reverted');
            case LOAN_APPROVAL_TYPE.OPERATOR_REQUEST_CANCELLATION_DISBURSEMENT:
              return t('code:approval-status.OPERATOR_REQUEST_CANCELLATION_DISBURSEMENT');
            case LOAN_APPROVAL_TYPE.AUTHORIZER_RETURNED_CANCELLATION_DISBURSEMENT:
              return t('code:approval-status.AUTHORIZER_RETURNED_CANCELLATION_DISBURSEMENT');
            default:
              return '';
          }
        case 'SUCCESS_INVOICE_STATUS':
          switch (title) {
            case SUCCESS_INVOICE_STATUS.REGISTERED:
              return t('text:Pending');
            case SUCCESS_INVOICE_STATUS.SETTLED:
              return t('text:Completed');
            case SUCCESS_INVOICE_STATUS.UNSETTLED:
              return t('text:Not_Proceeded');
            default:
              return '';
          }
        case 'COMMON_APPROVAL_TYPE':
          switch (title) {
            case COMMON_APPROVAL_TYPE.OPERATOR_REQUEST:
              return t('text:Requested');
            case COMMON_APPROVAL_TYPE.AUTHORIZER_RETURNED:
              return t('text:Request_Reverted');
            case COMMON_APPROVAL_TYPE.OPERATOR_CANCEL:
              return t('text:Request_Cancelled');
            case COMMON_APPROVAL_TYPE.AUTHORIZER_APPROVED:
              return t('text:Registered');
            default:
              return '';
          }
        case 'EARLY_REPAYMENT_REQUEST_STATUS':
          switch (title) {
            case EARLY_REPAYMENT_REQUEST_STATUS.REQUESTED:
              return t('text:Requested');
            case EARLY_REPAYMENT_REQUEST_STATUS.COMPLETED:
              return t('text:Completed');
            case EARLY_REPAYMENT_REQUEST_STATUS.EXPIRED:
              return t('text:Rejected');
            default:
              return '';
          }
        case 'EXTENSION_REQUEST_STATUS':
          switch (title) {
            case EXTENSION_REQUEST_STATUS.REQUESTED:
              return t('text:Requested');
            case EXTENSION_REQUEST_STATUS.PREAPPROVED:
              return t('text:Reviewed');
            case EXTENSION_REQUEST_STATUS.APPROVED:
              return t('text:Approved');
            case EXTENSION_REQUEST_STATUS.REJECTED:
              return t('text:Rejected');
            case EXTENSION_REQUEST_STATUS.RETURNED:
              return t('text:Approval_Reverted');
            case EXTENSION_REQUEST_STATUS.EXTENDED:
              return t('text:Extended');
            default:
              return '';
          }
        case 'USER_STATUS':
          switch (title) {
            case USER_STATUS.REGISTERED:
              return t('text:Input');
            case USER_STATUS.INVITED:
              return t('text:Invitation_Email_Sent');
            case USER_STATUS.ACTIVATED:
              return t('text:Active');
            case USER_STATUS.SUSPENDED:
              return t('text:Deactivated');
            default:
              return '';
          }
        case 'USER_COMMON_APPROVAL_TYPE':
          switch (title) {
            case COMMON_APPROVAL_TYPE.OPERATOR_REQUEST:
              return t('text:Requested');
            case COMMON_APPROVAL_TYPE.AUTHORIZER_RETURNED:
              return t('text:Request_Reverted');
            case COMMON_APPROVAL_TYPE.OPERATOR_CANCEL:
              return t('text:Request_Cancelled');
            default:
              return '';
          }
        case 'AUTHORIZER_STATUS':
          switch (title) {
            case AUTHORIZER_STATUS.ACTIVATED:
              return t('text:Active');
            case AUTHORIZER_STATUS.MAIL_SENT:
              return t('text:Invitation_Email_Sent');
            case AUTHORIZER_STATUS.NONE:
              return t('text:Input');
            default:
              return '';
          }
        case 'SUCCESS_AR_STATUS':
          switch (title) {
            case SUCCESS_AR_STATUS.REGISTERED:
              return t('text:Pending');
            case SUCCESS_AR_STATUS.SETTLED:
              return t('text:Completed');
            case SUCCESS_AR_STATUS.FAILED:
              return t('text:Failed');
            case SUCCESS_AR_STATUS.UNSETTLED:
              return t('text:Not_Proceeded');
            default:
              return '';
          }
        case 'EXECUTION_STATUS':
          switch (title) {
            case EXECUTION_STATUS.NONE:
              return t('text:None');
            case EXECUTION_STATUS.REGISTERED:
              return t('text:Waiting');
            case EXECUTION_STATUS.RUNNING:
              return t('text:In_Progress');
            case EXECUTION_STATUS.FAILED:
              return t('text:Failed');
            default:
              return '';
          }
        case 'AR_COMMISSION_STATUS':
          switch (title) {
            case AR_COMMISSION_STATUS.CREATED:
              return t(`code:ar-commission-status.${AR_COMMISSION_STATUS.CREATED}`);
            case AR_COMMISSION_STATUS.PAID:
              return t(`code:ar-commission-status.${AR_COMMISSION_STATUS.PAID}`);
            case AR_COMMISSION_STATUS.WAIVED:
              return t(`code:ar-commission-status.${AR_COMMISSION_STATUS.WAIVED}`);
            default:
              return '';
          }
        case 'MULTIPLE_LOAN_REQUEST_STATUS':
          switch (title) {
            case MULTIPLE_LOAN_REQUEST_STATUS.CREATED:
              return t(`code:multiple-loan-request-status.${MULTIPLE_LOAN_REQUEST_STATUS.CREATED}`);
            case MULTIPLE_LOAN_REQUEST_STATUS.APPLIED:
              return t(`code:multiple-loan-request-status.${MULTIPLE_LOAN_REQUEST_STATUS.APPLIED}`);
            case MULTIPLE_LOAN_REQUEST_STATUS.CANCELLED:
              return t(`code:multiple-loan-request-status.${MULTIPLE_LOAN_REQUEST_STATUS.CANCELLED}`);
            case MULTIPLE_LOAN_REQUEST_STATUS.REJECTED:
              return t(`code:multiple-loan-request-status.${MULTIPLE_LOAN_REQUEST_STATUS.REJECTED}`);
            case MULTIPLE_LOAN_REQUEST_STATUS.APPROVED:
              return t(`code:multiple-loan-request-status.${MULTIPLE_LOAN_REQUEST_STATUS.APPROVED}`);
            default:
              return '';
          }
        case 'MULTIPLE_LOAN_REQUEST_APPROVAL_TYPE':
          switch (title) {
            case COMMON_APPROVAL_TYPE.OPERATOR_REQUEST:
              return t(`code:multiple-loan-request-approval-type.${COMMON_APPROVAL_TYPE.OPERATOR_REQUEST}`);
            case COMMON_APPROVAL_TYPE.AUTHORIZER_RETURNED:
              return t(`code:multiple-loan-request-approval-type.${COMMON_APPROVAL_TYPE.AUTHORIZER_RETURNED}`);
            default:
              return '';
          }

        case 'EMAIL_SENT_STATUS':
          switch (title) {
            case EMAIL_SENT_STATUS.IN_PROGRESS:
              return t(`code:email-sent-status.${EMAIL_SENT_STATUS.IN_PROGRESS}`);
            case EMAIL_SENT_STATUS.SUCCESS:
              return t(`code:email-sent-status.${EMAIL_SENT_STATUS.SUCCESS}`);
            case EMAIL_SENT_STATUS.FAIL:
              return t(`code:email-sent-status.${EMAIL_SENT_STATUS.FAIL}`);
            default:
              return '';
          }
        case 'AWS_EMAIL_EVENT_TYPE':
          switch (title) {
            case AWS_EMAIL_EVENT_TYPE.BOUNCE:
              return t(`code:aws-email-event-type.${AWS_EMAIL_EVENT_TYPE.BOUNCE}`);
            case AWS_EMAIL_EVENT_TYPE.NETWORK_ERROR:
              return t(`code:aws-email-event-type.${AWS_EMAIL_EVENT_TYPE.NETWORK_ERROR}`);
            case AWS_EMAIL_EVENT_TYPE.MAILBOX_FULL:
              return t(`code:aws-email-event-type.${AWS_EMAIL_EVENT_TYPE.MAILBOX_FULL}`);
            case AWS_EMAIL_EVENT_TYPE.MESSAGE_TOO_LARGE:
              return t(`code:aws-email-event-type.${AWS_EMAIL_EVENT_TYPE.MESSAGE_TOO_LARGE}`);
            default:
              return '';
          }
        default:
          return '';
      }
    };

    const transContentsText = (contents: string): string => {
      switch (statusDescriptionEnumType) {
        case 'WAITING_INVOICE_APPROVAL_PHASE_STATUS':
          switch (contents) {
            case WAITING_INVOICE_APPROVAL_PHASE_STATUS.INITIATED:
              return t(
                'text:The_Partner_Operator_registered_the_invoice_and_submitted_request_for_confirmation_to_the_Partner_Authorizer',
              );
            case WAITING_INVOICE_APPROVAL_PHASE_STATUS.REQUESTED:
              return t(
                'text:Partner_Authorizer_has_requested_approval_of_the_invoice_registration_from_Financier_and_or_Anchor',
              );
            case WAITING_INVOICE_APPROVAL_PHASE_STATUS.REGISTERED:
              return t(
                'text:Anchor_or_Financier_Operator_has_requested_confirmation_of_invoice_registration_to_Anchor_or_Financier_Authorizer',
              );
            case WAITING_INVOICE_APPROVAL_PHASE_STATUS.CONFIRMED:
              return t(
                'text:Anchor_or_Financier_Authorizer_has_confirmed_invoice_registration_completing_the_invoice_registration_process',
              );
            case WAITING_INVOICE_APPROVAL_PHASE_STATUS.REJECTED:
              return t('text:The_invoice_registration_request_is_rejected');
            case WAITING_INVOICE_APPROVAL_PHASE_STATUS.IN_PROGRESS:
              return t('text:The_registration_request_has_been_completed_and_the_result_is_waiting');
            default:
              return '';
          }
        case 'LOAN_STATUS':
          switch (contents) {
            case LOAN_STATUS.DEALER_CREATED:
              return t(
                'text:The_Partner_Operator_has_requested_the_Partner_Authorizer_to_review_the_financing_application',
              );
            case LOAN_STATUS.DEALER_REQUESTED:
              return t('text:The_Partner_Authorizer_has_applied_for_financing_to_the_Financier');
            case LOAN_STATUS.DEALER_CANCELLED:
              return t('text:The_Partner_Authorizer_has_cancelled_the_financing_application');
            case LOAN_STATUS.REJECTED:
              return t('text:The_Financier_has_rejected_the_Partners_financing_application');
            case LOAN_STATUS.APPROVED:
              return t('text:The_Financier_has_approved_the_Partners_financing_application');
            case LOAN_STATUS.DISBURSED:
              return t('text:The_Financier_has_successfully_disbursed_the_financing');
            case LOAN_STATUS.REPAID:
              return t('text:The_financing_principal_and_interest_amount_was_successfully_repaid');
            case LOAN_STATUS.OVERDUE:
              return t(
                'text:The_financing_is_delinquent_as_the_repayment_has_not_been_made_by_the_scheduled_repayment_date',
              );
            case LOAN_STATUS.EXPIRATION:
              return t('text:The_delinquent_financing_was_closed_by_the_financier');
            case LOAN_STATUS.RECOURSE_ACTIVATED:
              return t('text:The_financing_principal_and_interest_amount_will_be_collected_from_partner');
            default:
              return '';
          }
        case 'LOAN_APPROVAL_TYPE':
          switch (contents) {
            case LOAN_APPROVAL_TYPE.OPERATOR_APPROVAL:
              return t(
                'text:The_financing_application_submitted_by_Partner_Authorizer_has_been_reviewed_by_the_Financier_Operator_Final_approval_request_has_been_sent_to_the_Financier_Authorizer',
              );
            case LOAN_APPROVAL_TYPE.AUTHORIZER_RETURNED_APPROVAL:
              return t(
                'text:The_Financier_Authorizer_has_reverted_the_financing_approval_request_to_the_Financier_Operator',
              );
            case LOAN_APPROVAL_TYPE.OPERATOR_OVERDUE:
              return t(
                'text:The_Financier_Operator_has_requested_the_Financier_Authorizer_to_change_the_status_of_the_financing_to_Delinquent_Status',
              );
            case LOAN_APPROVAL_TYPE.OPERATOR_REQUEST_REJECTED:
              return t(
                'text:The_Financier_Operator_has_requested_the_Financier_Authorizer_to_cancel_the_approval_and_change_the_status_of_the_financing_to_the_Rejected_Status',
              );
            case LOAN_APPROVAL_TYPE.AUTHORIZER_RETURNED_REJECTED:
              return t(
                'text:The_Financier_Operator’s_request_to_cancel_the_approval_and_reject_the_financing_has_been_reverted_by_the_Financier_Authorizer',
              );
            case LOAN_APPROVAL_TYPE.AUTHORIZER_RETURNED_OVERDUE:
              return t(
                'text:The_Financier_Operators_request_to_change_the_status_of_the_financing_to_Delinquent_Status_has_been_reverted_by_the_Financier_Authorizer',
              );
            case LOAN_APPROVAL_TYPE.OPERATOR_REPAID:
              return t(
                'text:The_Financier_Operator_has_requested_the_Financier_Authorizer_to_change_the_status_of_the_financing_to_Repaid_Status',
              );
            case LOAN_APPROVAL_TYPE.AUTHORIZER_RETURNED_REPAID:
              return t(
                'text:The_Financier_Operators_request_to_change_the_status_of_the_financing_to_Repaid_Status_has_been_reverted_by_the_Financier_Authorizer',
              );
            case LOAN_APPROVAL_TYPE.OPERATOR_CLEAR_OVERDUE:
              return t(
                'text:The_Financier_Operator_has_submitted_a_request_to_the_Financier_Authorizer_to_remove_the_Delinquent_Status',
              );
            case LOAN_APPROVAL_TYPE.AUTHORIZER_RETURNED_CLEAR_OVERDUE:
              return t(
                'text:The_Financier_Operator_s_request_to_remove_the_Delinquent_Status_has_been_reverted_by_the_Financier_Authorizer',
              );
            case LOAN_APPROVAL_TYPE.OPERATOR_EXPIRATION:
              return t(
                'text:The_Financier_Operator_has_requested_the_Financier_Authorizer_to_change_the_status_of_the_financing_to_Expired_status',
              );
            case LOAN_APPROVAL_TYPE.AUTHORIZER_RETURNED_EXPIRATION:
              return t(
                'text:The_Financier_Operators_request_to_change_the_status_of_the_financing_to_Expired_status_has_been_reverted_by_the_Financier_Authorizer',
              );
            case LOAN_APPROVAL_TYPE.OPERATOR_REQUEST_CANCELLATION_DISBURSEMENT:
              return t(
                'text:The_Financier_Operator_has_requested_the_Financier_Authorizer_to_cancel_the_disbursement_and_change_the_status_of_the_financing_to_Approved_Status',
              );
            case LOAN_APPROVAL_TYPE.AUTHORIZER_RETURNED_CANCELLATION_DISBURSEMENT:
              return t(
                'text:The_Financier_Operators_request_to_cancel_the_disbursement_has_been_reverted_by_the_Financier_Authorizer',
              );
            default:
              return '';
          }
        case 'SUCCESS_INVOICE_STATUS':
          switch (contents) {
            case SUCCESS_INVOICE_STATUS.REGISTERED:
              return t(
                'text:The_invoice_payment_date_has_not_elapsed_or_the_Partners_financing_application_was_not_disbursed',
              );
            case SUCCESS_INVOICE_STATUS.SETTLED:
              return t('text:The_financing_has_been_disbursed_completing_the_invoice_payment');
            case SUCCESS_INVOICE_STATUS.UNSETTLED:
              return t('text:Invoice_payment_was_not_processed_as_there_was_no_financing_application_from_the_Partner');
            default:
              return '';
          }
        case 'COMMON_APPROVAL_TYPE':
          switch (contents) {
            case COMMON_APPROVAL_TYPE.OPERATOR_REQUEST:
              return t(
                'text:The_Financier_Operator_has_requested_the_Financier_Authorizer_to_approve_the_master_agreement_registration',
              );
            case COMMON_APPROVAL_TYPE.AUTHORIZER_RETURNED:
              return t(
                'text:The_Financier_Operators_request_to_approve_the_master_agreement_registration_has_been_rejected_by_the_Financier_Authorizer',
              );
            case COMMON_APPROVAL_TYPE.OPERATOR_CANCEL:
              return t(
                'text:The_Financier_Operator_has_cancelled_the_request_to_approve_the_master_agreement_registration',
              );
            case COMMON_APPROVAL_TYPE.AUTHORIZER_APPROVED:
              return t('text:The_Financier_Authorizer_has_approved_the_request_to_register_the_master_agreement');
            default:
              return '';
          }
        case 'EARLY_REPAYMENT_REQUEST_STATUS':
          switch (contents) {
            case EARLY_REPAYMENT_REQUEST_STATUS.REQUESTED:
              return t('text:The_Partners_early_repayment_request_has_been_registered_with_the_Financier');
            case EARLY_REPAYMENT_REQUEST_STATUS.COMPLETED:
              return t('text:The_early_repayment_requested_by_the_Partner_has_been_completed');
            case EARLY_REPAYMENT_REQUEST_STATUS.EXPIRED:
              return t(
                'text:The_Financier_has_rejected_the_Partners_early_repayment_requests_or_the_early_repayment_has_not_been_completed_on_the_requested_repayment_date',
              );
            default:
              return '';
          }
        case 'EXTENSION_REQUEST_STATUS':
          switch (contents) {
            case EXTENSION_REQUEST_STATUS.REQUESTED:
              return t('text:The_Partner_Authorizer_has_requested_maturity_extension_to_the_Financier');
            case EXTENSION_REQUEST_STATUS.PREAPPROVED:
              return t(
                'text:The_Financier_Operator_has_reviewed_the_Partners_maturity_extension_request_and_requested_approval_from_the_Financier_Authorizer',
              );
            case EXTENSION_REQUEST_STATUS.APPROVED:
              return t('text:The_Financier_Authorizer_has_approved_the_Partners_maturity_extension_request');
            case EXTENSION_REQUEST_STATUS.REJECTED:
              return t('text:The_Financier_Operator_has_rejected_the_Partners_maturity_extension_request');
            case EXTENSION_REQUEST_STATUS.RETURNED:
              return t(
                'text:The_Financier_Operators_request_to_approve_the_Partners_maturity_extension_has_been_reverted_by_the_Financier_Authorizer',
              );
            case EXTENSION_REQUEST_STATUS.EXTENDED:
              return t('text:The_financing_maturity_has_been_extended');
            default:
              return '';
          }
        case 'USER_STATUS':
          switch (contents) {
            case USER_STATUS.REGISTERED:
              return t('text:User_registration_has_been_completed_but_the_email_invitation_has_not_yet_been_sent');
            case USER_STATUS.INVITED:
              return t('text:User_registration_has_been_completed_and_the_email_invitation_has_been_sent');
            case USER_STATUS.ACTIVATED:
              return t('text:The_registered_user’s_account_has_been_activated');
            case USER_STATUS.SUSPENDED:
              return t('text:The_registered_user’s_account_has_been_suspended_and_platform_usage_is_restricted');
            default:
              return '';
          }
        case 'USER_COMMON_APPROVAL_TYPE':
          switch (contents) {
            case COMMON_APPROVAL_TYPE.OPERATOR_REQUEST:
              return t(
                'text:The_Financier_Operator_has_requested_the_Financier_Authorizer_to_approve_the_master_agreement_registration',
              );
            case COMMON_APPROVAL_TYPE.AUTHORIZER_RETURNED:
              return t(
                'text:The_Financier_Operators_approval_request_of_the_master_agreement_registration_has_been_rejected_by_the_Financier_Authorizer',
              );
            case COMMON_APPROVAL_TYPE.OPERATOR_CANCEL:
              return t(
                'text:The_Financier_Operator_has_cancelled_the_approval_request_of_the_master_agreement_registration',
              );
            default:
              return '';
          }
        case 'AUTHORIZER_STATUS':
          switch (contents) {
            case AUTHORIZER_STATUS.ACTIVATED:
              return t('text:The_Partner_Authorizer_has_been_registered_and_the_account_has_been_activated');
            case AUTHORIZER_STATUS.MAIL_SENT:
              return t('text:The_invitation_email_has_been_sent_to_the_Partner_Authorizer');
            case AUTHORIZER_STATUS.NONE:
              return t(
                'text:Partner_Master_Agreement_has_been_registered,_but_the_invitation_email_has_not_been_sent_to_the_Partner_Authorizer',
              );
            default:
              return '';
          }
        case 'SUCCESS_AR_STATUS':
          switch (contents) {
            case SUCCESS_AR_STATUS.REGISTERED:
              return t('text:The_AR_settlement_date_has_not_elapsed_or_the_settlement_has_not_yet_been_made');
            case SUCCESS_AR_STATUS.SETTLED:
              return t('text:The_AR_settlement_was_completed_successfully');
            case SUCCESS_AR_STATUS.FAILED:
              return t('text:The_AR_settlement_failed');
            case SUCCESS_AR_STATUS.UNSETTLED:
              return t(
                'text:The_AR_settlement_was_not_processed_as_the_Anchor_Master_Agreement_does_not_have_automatic_settlement',
              );
            default:
              return '';
          }
        case 'EXECUTION_STATUS':
          switch (contents) {
            case EXECUTION_STATUS.NONE:
              return t('text:The_invoice_confirmation_has_not_been_requested');
            case EXECUTION_STATUS.REGISTERED:
              return t('text:The_invoice_confirmation_has_been_requested_and_they_are_waiting_to_be_processed');
            case EXECUTION_STATUS.RUNNING:
              return t('text:The_invoice_confirmation_is_in_progress');
            case EXECUTION_STATUS.FAILED:
              return t('text:The_invoice_confirmation_failed');
            default:
              return '';
          }
        case 'AR_COMMISSION_STATUS':
          switch (contents) {
            case AR_COMMISSION_STATUS.CREATED:
              return t('text:Transaction_Fee_payment_has_not_yet_occurred');
            case AR_COMMISSION_STATUS.PAID:
              return t('text:Transaction_Fee_payment_was_completely_successful');
            case AR_COMMISSION_STATUS.WAIVED:
              return t('text:Transaction_Fee_waived');
            default:
              return '';
          }
        case 'MULTIPLE_LOAN_REQUEST_STATUS':
          switch (contents) {
            case MULTIPLE_LOAN_REQUEST_STATUS.CREATED:
              return t(
                'text:The_Partner_Operator_has_requested_the_Partner_Authorizer_to_review_the_financing_application',
              );
            case MULTIPLE_LOAN_REQUEST_STATUS.APPLIED:
              return t('text:The_Partner_Authorizer_has_applied_for_financing_to_the_Financier');
            case MULTIPLE_LOAN_REQUEST_STATUS.CANCELLED:
              return t('text:The_Partner_Authorizer_has_cancelled_the_financing_application');
            case MULTIPLE_LOAN_REQUEST_STATUS.REJECTED:
              return t('text:The_Financier_has_rejected_the_Partners_financing_application');
            case MULTIPLE_LOAN_REQUEST_STATUS.APPROVED:
              return t('text:The_Financier_has_approved_the_Partners_financing_application');
            default:
              return '';
          }
        case 'MULTIPLE_LOAN_REQUEST_APPROVAL_TYPE':
          switch (contents) {
            case COMMON_APPROVAL_TYPE.OPERATOR_REQUEST:
              return t(
                'text:The_financing_application_submitted_by_Partner_Authorizer_has_been_reviewed_by_the_Financier_Operator_Final_approval_request_has_been_sent_to_the_Financier_Authorizer',
              );
            case COMMON_APPROVAL_TYPE.AUTHORIZER_RETURNED:
              return t(
                'text:The_Financier_Authorizer_has_reverted_the_financing_approval_request_to_the_Financier_Operator',
              );
            default:
              return '';
          }
        case 'EMAIL_SENT_STATUS':
          switch (contents) {
            case EMAIL_SENT_STATUS.IN_PROGRESS:
              return t('text:Sending_Debt_Selling_notification_mail_to_anchor_user');
            case EMAIL_SENT_STATUS.SUCCESS:
              return t('text:Anchor_user_successfully_receives_Debt_Selling_notification_mail');
            case EMAIL_SENT_STATUS.FAIL:
              return t(
                'text:The_anchor_user_failed_to_receive_the_Debt_Selling_notification_mail_due_to_the_indicated_failure_reason',
              );
            default:
              return '';
          }
        case 'AWS_EMAIL_EVENT_TYPE':
          switch (contents) {
            case AWS_EMAIL_EVENT_TYPE.BOUNCE:
              return t('text:Sending_email_failed_because_the_email_address_of_the_anchor_company_user_was_incorrect');
            case AWS_EMAIL_EVENT_TYPE.NETWORK_ERROR:
              return t('text:A_network_error_occurred_and_sending_an_email_to_the_anchor_company_user_failed');
            case AWS_EMAIL_EVENT_TYPE.MAILBOX_FULL:
              return t('text:Mailbox_of_anchor_user_is_no_longer_acceptable');
            case AWS_EMAIL_EVENT_TYPE.MESSAGE_TOO_LARGE:
              return t('text:The_transfer_failed_because_the_size_of_the_email_was_too_large');
            default:
              return '';
          }

        default:
          return '';
      }
    };

    const transTextClassName = (enumString: string): string => {
      switch (statusDescriptionEnumType) {
        case 'WAITING_INVOICE_APPROVAL_PHASE_STATUS':
          return getStatusTextClass(
            'WAITING_INVOICE_APPROVAL_PHASE_STATUS',
            enumString as WAITING_INVOICE_APPROVAL_PHASE_STATUS,
          );
        case 'LOAN_STATUS':
          return getStatusTextClass('LOAN_STATUS', enumString as LOAN_STATUS);
        case 'LOAN_APPROVAL_TYPE':
          return getStatusTextClass('LOAN_APPROVAL_TYPE', enumString as LOAN_APPROVAL_TYPE);
        case 'SUCCESS_INVOICE_STATUS':
          return getStatusTextClass('SUCCESS_INVOICE_STATUS', enumString as SUCCESS_INVOICE_STATUS);
        case 'COMMON_APPROVAL_TYPE':
        case 'USER_COMMON_APPROVAL_TYPE':
          return getStatusTextClass('COMMON_APPROVAL_TYPE', enumString as COMMON_APPROVAL_TYPE);
        case 'EARLY_REPAYMENT_REQUEST_STATUS':
          return getStatusTextClass('EARLY_REPAYMENT_REQUEST_STATUS', enumString as EARLY_REPAYMENT_REQUEST_STATUS);
        case 'EXTENSION_REQUEST_STATUS':
          return getStatusTextClass('EXTENSION_REQUEST_STATUS', enumString as EXTENSION_REQUEST_STATUS);
        case 'USER_STATUS':
          return getStatusTextClass('USER_STATUS', enumString as USER_STATUS);
        case 'AUTHORIZER_STATUS':
          return getStatusTextClass('AUTHORIZER_STATUS', enumString as AUTHORIZER_STATUS);
        case 'SUCCESS_AR_STATUS':
          return getStatusTextClass('SUCCESS_AR_STATUS', enumString as SUCCESS_AR_STATUS);
        case 'EXECUTION_STATUS':
          return getStatusTextClass('EXECUTION_STATUS', enumString as EXECUTION_STATUS);
        case 'AR_COMMISSION_STATUS':
          return getStatusTextClass('AR_COMMISSION_STATUS', enumString as AR_COMMISSION_STATUS);
        case 'MULTIPLE_LOAN_REQUEST_STATUS':
          return getStatusTextClass('MULTIPLE_LOAN_REQUEST_STATUS', enumString as MULTIPLE_LOAN_REQUEST_STATUS);
        case 'MULTIPLE_LOAN_REQUEST_APPROVAL_TYPE':
          return getStatusTextClass('MULTIPLE_LOAN_REQUEST_APPROVAL_TYPE', enumString as COMMON_APPROVAL_TYPE);
        case 'EMAIL_SENT_STATUS':
          return getStatusTextClass('EMAIL_SENT_STATUS', enumString as EMAIL_SENT_STATUS);
        default:
          return '';
      }
    };

    const transBoxClassName = (enumString: string): string => {
      switch (statusDescriptionEnumType) {
        case 'WAITING_INVOICE_APPROVAL_PHASE_STATUS':
          return getStatusBadgeClass(
            'WAITING_INVOICE_APPROVAL_PHASE_STATUS',
            enumString as WAITING_INVOICE_APPROVAL_PHASE_STATUS,
          );
        case 'LOAN_STATUS':
          return getStatusBadgeClass('LOAN_STATUS', enumString as LOAN_STATUS);
        case 'LOAN_APPROVAL_TYPE':
          return getStatusBadgeClass('LOAN_APPROVAL_TYPE', enumString as LOAN_APPROVAL_TYPE);
        case 'COMMON_APPROVAL_TYPE':
          return getStatusBadgeClass('COMMON_APPROVAL_TYPE', enumString as COMMON_APPROVAL_TYPE);
        case 'EARLY_REPAYMENT_REQUEST_STATUS':
          return getStatusBadgeClass('EARLY_REPAYMENT_REQUEST_STATUS', enumString as EARLY_REPAYMENT_REQUEST_STATUS);
        case 'EXTENSION_REQUEST_STATUS':
          return getStatusBadgeClass('EXTENSION_REQUEST_STATUS', enumString as EXTENSION_REQUEST_STATUS);
        case 'USER_COMMON_APPROVAL_TYPE':
          return getStatusBadgeClass('COMMON_APPROVAL_TYPE', enumString as COMMON_APPROVAL_TYPE);
        case 'EXECUTION_STATUS':
          return getStatusBadgeClass('EXECUTION_STATUS', enumString as EXECUTION_STATUS);
        default:
          return '';
      }
    };

    const renderTextComponent = (item: string): JSX.Element => {
      return (
        <>
          <div className={`col-3 status-type-modal-description-title-text ${transTextClassName(item)}`}>
            {transTitleText(item)}
          </div>
          <div className="col-9 status-type-modal-description-text">{transContentsText(item)}</div>
        </>
      );
    };

    const renderBoxComponent = (item: string): JSX.Element => {
      return (
        <>
          <div className={`col-3 status-type-modal-description-title-box ${transBoxClassName(item)}`}>
            {transTitleText(item)}
          </div>
          <div className="col-9 status-type-modal-description-text">{transContentsText(item)}</div>
        </>
      );
    };

    return (statusDescriptionEnum as any[]).map((item, index: number) => {
      return (
        <div className="row status-type-modal-description-container" key={index}>
          {statusDescriptionModalType === 'TEXT' ? renderTextComponent(item) : renderBoxComponent(item)}
        </div>
      );
    });
  };

  return (
    <>
      <div className="row">
        <div className="col-3 status-type-modal-status-header-text">{t('text:Status')}</div>
        <div className="col-9 status-type-modal-status-header-text">{t('text:Description')}</div>
      </div>
      <div className="status-type-modal-border" />
      <div
        className="scrollbar"
        style={statusDescriptionModalType === 'TEXT' ? { height: '200px' } : { height: '300px' }}
      >
        {renderStatusDescriptionComponent()}
      </div>
    </>
  );
}

export default StatusDescriptionModal;
