import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Button, { ButtonSizeEnum } from 'components/stateless/Button/Button';
import { FormBorder } from 'components/stateless/CommonForm/FormBorder';
import { FormContents } from 'components/stateless/CommonForm/FormContents';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { AUTHORITY_TYPE } from 'enums';
import useMounted from 'hooks/useMounted';
import { getSignIn } from 'utils/storage/LocalStorage';

import DocumentForm from './document-form';
import { useDocumentForm } from './useDocumentForm';

import type { PPAcquisitionDocumentType } from '../../useFinancierSCPartnerAcquisitionSettingState';

interface FinancierSCPartnerAcquisitionSettingDocumentFormProps {
  activatedList: PPAcquisitionDocumentType[];
  deactivatedList: PPAcquisitionDocumentType[];
}

function FinancierSCPartnerAcquisitionSettingDocumentForm({
  activatedList,
  deactivatedList,
}: FinancierSCPartnerAcquisitionSettingDocumentFormProps) {
  const signInInfo = getSignIn();
  const isAdmin = signInInfo?.authorityType === AUTHORITY_TYPE.ADMIN;
  const { t } = useTranslation();
  const mounted = useMounted();

  const { activatedFormUtils, deactivatedFormUtils, sortFormRowByDocumentSettingId, updateEditable } =
    useDocumentForm();
  const {
    activatedRowState,
    activatedRowInitialize,
    appendEmptyActivatedRow,
    deleteActivatedRow,
    saveActivatedRow,
    isSortActivatedRow,
  } = activatedFormUtils;

  const {
    deactivatedRowState,
    deactivatedRowInitialize,
    deleteDeactivatedRow,
    appendEmptyDeactivatedRow,
    saveDeactivatedRow,
    isSortDeactivatedRow,
  } = deactivatedFormUtils;

  useEffect(() => {
    if (mounted) {
      if (isAdmin) {
        activatedList?.length > 0 ? activatedRowInitialize(activatedList) : appendEmptyActivatedRow();
        deactivatedList?.length > 0 ? deactivatedRowInitialize(deactivatedList) : appendEmptyDeactivatedRow();
      } else {
        activatedList?.length > 0 && activatedRowInitialize(activatedList);
        deactivatedList?.length > 0 && deactivatedRowInitialize(deactivatedList);
      }
    }

    return () => {};
  }, [activatedList, deactivatedList]);

  useEffect(() => {
    if (isSortActivatedRow) sortFormRowByDocumentSettingId(activatedRowState);
  }, [isSortActivatedRow]);

  useEffect(() => {
    if (isSortDeactivatedRow) sortFormRowByDocumentSettingId(deactivatedRowState);
  }, [isSortDeactivatedRow]);

  const renderNoDataAvailableMessage = () => {
    return (
      <div className="mb-2">
        <FormBorder>
          <FormContents>
            <h3 className="text-center">{t('text:no_data_available')}</h3>
          </FormContents>
        </FormBorder>
      </div>
    );
  };

  const renderActivateDocumentForm = () => {
    const activatedRowLength = activatedRowState?.length;
    if (activatedRowLength > 0) {
      return activatedRowState.map((item, index) => {
        return (
          <DocumentForm
            key={index}
            formKey={`activate[${index}]`}
            data={item}
            index={index}
            saveRow={saveActivatedRow}
            deleteRow={deleteActivatedRow}
            updateEditable={updateEditable}
            activateDefaultValue={true}
          />
        );
      });
    } else {
      return renderNoDataAvailableMessage();
    }
  };

  const renderDeactivateDocumentForm = () => {
    const deactivatedRowLength = deactivatedRowState?.length;
    if (deactivatedRowLength > 0) {
      return deactivatedRowState.map((item, index) => {
        return (
          <DocumentForm
            key={index}
            formKey={`deactivate[${index}]`}
            data={item}
            index={index}
            saveRow={saveDeactivatedRow}
            deleteRow={deleteDeactivatedRow}
            updateEditable={updateEditable}
            activateDefaultValue={false}
          />
        );
      });
    } else {
      return renderNoDataAvailableMessage();
    }
  };

  return (
    <>
      <div className="content-area">
        <SectionTitle title={t('text:Register_the_types_of_documents_Uploaded_Partners_should_submit')} />
        {renderActivateDocumentForm()}
        {isAdmin && (
          <div className="text-end mb-4">
            <span className="me-3 text-bold-orange-main700">{t('text:Add')}</span>
            <Button onClick={() => appendEmptyActivatedRow()} size={ButtonSizeEnum.SM}>
              <FontAwesomeIcon icon={faPlus} />
            </Button>
          </div>
        )}
      </div>

      <div className="content-area">
        <SectionTitle title={t('text:These_documents_have_been_hidden_from_the_Uploaded_Partner')} />
        {renderDeactivateDocumentForm()}
        {isAdmin && (
          <div className="text-end mb-4">
            <span className="me-3 text-bold-orange-main700">{t('text:Add')}</span>
            <Button onClick={() => appendEmptyDeactivatedRow()} size={ButtonSizeEnum.SM}>
              <FontAwesomeIcon icon={faPlus} />
            </Button>
          </div>
        )}
      </div>
    </>
  );
}

export default FinancierSCPartnerAcquisitionSettingDocumentForm;
