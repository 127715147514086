import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import dayjs from 'dayjs';
import { isNil } from 'lodash-es';

import Button, { ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import Exporter from 'components/stateless/Exporter/Exporter';
import GuideMessage from 'components/stateless/GuideMessage/GuideMessage';
import StatusDescriptionModal from 'components/stateless/Modal/common/status/StatusDescriptionModal';
import Pagination from 'components/stateless/Pagination/Pagination';
import SearchBorder from 'components/stateless/SearchForm/SearchBorder';
import SearchInput from 'components/stateless/SearchForm/SearchInput';
import SearchLabel from 'components/stateless/SearchForm/SearchLabel';
import SearchSelect from 'components/stateless/SearchForm/SearchSelect';
import TableBody from 'components/stateless/Table/TableBody';
import TableBorder from 'components/stateless/Table/TableBorder';
import type { HeaderType } from 'components/stateless/Table/TableHeader';
import TableHeader from 'components/stateless/Table/TableHeader';
import Td from 'components/stateless/Table/Td';
import TdLink from 'components/stateless/Table/TdLink';
import Tr from 'components/stateless/Table/Tr';
import { HeaderTitle } from 'components/stateless/Title/HeaderTitle';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { ROUTES_DE } from 'constants/routes/dealer';
import getSelectOptions from 'constants/selectOptions';
import { AUTHORITY_TYPE, EXTENSION_REQUEST_STATUS } from 'enums';
import useMounted from 'hooks/useMounted';
import usePageable from 'hooks/usePageable';
import useProperty from 'hooks/useProperty';
import type Pageable from 'models/Pageable';
import type { ExtensionRequestVOModel } from 'models/vo/ExtensionRequestVO';
import getStatusTextClass from 'utils/classNames/getStatusTextClass';
import type { PDFExporterProps } from 'utils/exportFile/exportPDF.d';
import { setFormValues } from 'utils/form/setFormValues';
import { requestDealerExtensionList } from 'utils/http/api/dealer/extension-requests';
import type { DealerExtensionListRequest } from 'utils/http/api/dealer/extension-requests/request';
import { getTotalInterestRate } from 'utils/logic';
import { ModalSize, ModalType } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';
import { getParsedSearchParams, updateSearchParams } from 'utils/searchParams';
import type { ColumnOption, ExportSpreadSheetProps } from 'utils/spreadSheet/types';
import { getSignIn } from 'utils/storage/LocalStorage';
import { tableValueManage } from 'utils/valueManager/ValueManager';

const DE_EXTENSION_LIST_QS_KEY = 'de-extension-list';
const EXCEL_EXPORT_MAX_ROW_COUNT = 1000;
const PDF_EXPORT_MAX_ROW_COUNT = 150;

function DealerExtensionList() {
  const { t } = useTranslation(['format']);
  const mounted = useMounted();
  const modal = useModal();
  const { pageable, setPageable } = usePageable(DE_EXTENSION_LIST_QS_KEY);
  const getProperty = useProperty<DealerExtensionListRequest>();
  const history = useHistory();
  const CURRENT_USER_AUTHORITY: AUTHORITY_TYPE | undefined = getSignIn()?.authorityType;

  const [extensionList, setExtensionList] = useState<Pageable<ExtensionRequestVOModel[]>>();

  const { register, handleSubmit, getValues, reset, setValue } = useForm<DealerExtensionListRequest>();

  useEffect(() => {
    if (mounted) {
      setFormValues<DealerExtensionListRequest>(
        setValue,
        getParsedSearchParams(DE_EXTENSION_LIST_QS_KEY).formSearchData,
      );
      fetchDeExtensionList(pageable.currentPage, pageable.sizePerPage, getValues());
    }

    return () => {};
  }, [mounted]);

  async function fetchDeExtensionList(pageNumber: number = 1, rowCount: number = 10, data: DealerExtensionListRequest) {
    try {
      const fetchExtensionData = await requestDealerExtensionList(pageNumber, rowCount, data);
      updateSearchParams(
        {
          ...data,
          pageNumber,
          rowCount,
        },
        DE_EXTENSION_LIST_QS_KEY,
      );

      ReactDOM.unstable_batchedUpdates(() => {
        setExtensionList(fetchExtensionData);
        setPageable(fetchExtensionData);
      });
    } catch (error) {
      modal.show(error);
    }
  }

  const onSearchSubmit = async () => {
    await fetchDeExtensionList(1, pageable.sizePerPage, getValues());
  };

  const paginate = async (page: number, sizePerPage: number) => {
    await fetchDeExtensionList(page, sizePerPage, getParsedSearchParams(DE_EXTENSION_LIST_QS_KEY).formSearchData);
  };

  const onRemoveFilter = (event: any) => {
    reset({});
    event.preventDefault();
  };

  const onClickExtensionRequestStatus = (): void => {
    modal.show(
      <StatusDescriptionModal
        statusDescriptionEnum={Object.values(EXTENSION_REQUEST_STATUS)}
        statusDescriptionModalType="TEXT"
        statusDescriptionEnumType="EXTENSION_REQUEST_STATUS"
      />,
      {
        modalSize: ModalSize.XL,
        modalType: ModalType.ALERT,
        closeBtnText: t('text:Close'),
      },
    );
  };

  const renderRequestButton = () => {
    const onClickRequestButton = (e: any) => {
      e.preventDefault();
      history.push(ROUTES_DE.MANAGE_FINANCING.REQUEST_FOR_EXTENSION_REGISTER);
    };

    if (CURRENT_USER_AUTHORITY === AUTHORITY_TYPE.AUTHORIZER)
      return <Button onClick={e => onClickRequestButton(e)}>{t('text:Request')}</Button>;
  };

  const getOriginalRepaymentDate = (extensionStartDate: string | null | undefined): dayjs.Dayjs | undefined => {
    if (isNil(extensionStartDate)) return;

    return dayjs(extensionStartDate, 'YYYY-MM-DD').subtract(1, 'days');
  };

  const getAddedSpread = (extensionRequestStatus: EXTENSION_REQUEST_STATUS, additionalRate: number) => {
    return extensionRequestStatus !== EXTENSION_REQUEST_STATUS.REQUESTED
      ? tableValueManage(additionalRate, `${t('format:number', { value: additionalRate })}%`)
      : '-';
  };

  const renderExtensionRequestList = () => {
    return extensionList?.content.map((item, i) => {
      return (
        <Tr key={i}>
          <Td data={item.createdDateTime} format="datetime" />
          <Td data={item.financierName} />
          <Td data={item.disbursedAmount} format="number" />
          <Td data={item.disbursedDate} format="date" />
          <Td data={getOriginalRepaymentDate(item.extensionStartDate)} format="date" />
          <Td data={item.extendedRepaymentDate} format="date" />
          <Td
            className="text-end"
            data={getTotalInterestRate(item.disbursedBasisInterestRate, item.disbursedTotalInterestRateWithoutBasis)}
          />
          <Td data={getAddedSpread(item.extensionRequestStatus, item.additionalRate)} />
          <Td
            className={getStatusTextClass('EXTENSION_REQUEST_STATUS', item.extensionRequestStatus)}
            data={t(`code:extension-request-status.${item.extensionRequestStatus}`)}
            format="code"
          />
          <TdLink path={ROUTES_DE.MANAGE_FINANCING.REQUEST_FOR_EXTENSION_DETAIL_BUILD_PATH(item.extensionRequestId)} />
        </Tr>
      );
    });
  };

  const tableHeaders: HeaderType[] = [
    {
      headerText: t('text:Requested_Date'),
      colWidths: 150,
    },
    {
      headerText: t('text:Financier_Name'),
      colWidths: 120,
    },
    {
      headerText: t('text:Financing_Amount'),
      colWidths: 120,
    },
    {
      headerText: t('text:Disbursed_Date'),
      colWidths: 120,
    },
    {
      headerText: t('text:Original_Repayment_Date'),
      colWidths: 100,
    },
    {
      headerText: t('text:Requested_Repayment_Date'),
      colWidths: 120,
    },
    {
      headerText: t('text:Total_Interest_Rate_(APR)'),
      colWidths: 120,
    },
    {
      headerText: t('text:Added_Spread_(APR)'),
      colWidths: 100,
    },

    {
      headerText: t('text:Status'),
      colWidths: 100,
      hasStatusDescription: true,
      showStatusDescriptionFunc: onClickExtensionRequestStatus,
    },
    {
      headerText: '',
      colWidths: 50,
    },
  ];

  const onClickExportButton = async () => {
    const data = getParsedSearchParams(DE_EXTENSION_LIST_QS_KEY).formSearchData;

    try {
      const [extensionListResponse, extensionListPdfResponse] = await Promise.all([
        requestDealerExtensionList(0, EXCEL_EXPORT_MAX_ROW_COUNT, data),
        requestDealerExtensionList(0, PDF_EXPORT_MAX_ROW_COUNT, data),
      ]);

      const jsonArrayData: unknown[] = extensionListResponse.content.map(item => ({
        createdDateTime: tableValueManage(
          item.createdDateTime,
          t('format:datetime', { value: item.createdDateTime, key: 'datetime' }),
        ),
        financierName: tableValueManage(item.financierName),
        disbursedAmount: Number(item.disbursedAmount),
        disbursedDate: tableValueManage(
          item.disbursedDate,
          t('format:date', { value: item.disbursedDate, key: 'date' }),
        ),
        extensionStartDate: tableValueManage(
          getOriginalRepaymentDate(item.extensionStartDate),
          t('format:date', {
            value: getOriginalRepaymentDate(item.extensionStartDate),
            key: 'date',
          }),
        ),
        extendedRepaymentDate: tableValueManage(
          item.extendedRepaymentDate,
          t('format:date', { value: item.extendedRepaymentDate, key: 'date' }),
        ),
        disbursedBasisInterestRate: tableValueManage(
          getTotalInterestRate(item.disbursedBasisInterestRate, item.disbursedTotalInterestRateWithoutBasis),
        ),
        additionalRate: getAddedSpread(item.extensionRequestStatus, item.additionalRate),
        extensionRequestStatus: tableValueManage(
          item.extensionRequestStatus,
          t(`code:extension-request-status.${item.extensionRequestStatus}`),
        ),
      }));

      const excelColumns: ColumnOption<ExtensionRequestVOModel>[] = [
        {
          header: t('text:Requested_Date'),
          key: 'createdDateTime',
          width: 30,
        },
        {
          header: t('text:Financier_Name'),
          key: 'financierName',
          width: 30,
        },
        {
          header: t('text:Financing_Amount'),
          key: 'disbursedAmount',
          width: 30,
        },
        {
          header: t('text:Disbursed_Date'),
          key: 'disbursedDate',
          width: 30,
        },
        {
          header: t('text:Original_Repayment_Date'),
          key: 'extensionStartDate',
          width: 30,
        },
        {
          header: t('text:Requested_Repayment_Date'),
          key: 'extendedRepaymentDate',
          width: 30,
        },
        {
          header: t('text:Total_Interest_Rate_(APR)'),
          key: 'disbursedBasisInterestRate',
          width: 30,
        },
        {
          header: t('text:Added_Spread_(APR)'),
          key: 'additionalRate',
          width: 30,
        },

        {
          header: t('text:Status'),
          key: 'extensionRequestStatus',
          width: 30,
        },
      ];

      const excelExporterProps: ExportSpreadSheetProps<any> = {
        jsonArrayData,
        columns: excelColumns,
        options: {
          rowHeight: [{ position: 1, height: 30 }],
        },
      };

      const pdfTableHeaders: HeaderType[] = [
        {
          headerText: t('text:Requested_Date'),
          colWidths: 150,
        },
        {
          headerText: t('text:Financier_Name'),
          colWidths: 120,
        },
        {
          headerText: t('text:Financing_Amount'),
          colWidths: 120,
        },
        {
          headerText: t('text:Disbursed_Date'),
          colWidths: 120,
        },
        {
          headerText: t('text:Original_Repayment_Date'),
          colWidths: 100,
        },
        {
          headerText: t('text:Requested_Repayment_Date'),
          colWidths: 120,
        },
        {
          headerText: t('text:Total_Interest_Rate_(APR)'),
          colWidths: 120,
        },
        {
          headerText: t('text:Added_Spread_(APR)'),
          colWidths: 100,
        },

        {
          headerText: t('text:Status'),
          colWidths: 100,
        },
      ];

      const renderPDFTableBodyResult = () => {
        return extensionListPdfResponse.content.map((item, i) => {
          return (
            <Tr key={i} className="virtual-table-row">
              <Td data={item.createdDateTime} format="datetime" />
              <Td data={item.financierName} />
              <Td data={item.disbursedAmount} format="number" />
              <Td data={item.disbursedDate} format="date" />
              <Td data={getOriginalRepaymentDate(item.extensionStartDate)} format="date" />
              <Td data={item.extendedRepaymentDate} format="date" />
              <Td
                className="text-end"
                data={getTotalInterestRate(
                  item.disbursedBasisInterestRate,
                  item.disbursedTotalInterestRateWithoutBasis,
                )}
              />
              <Td data={getAddedSpread(item.extensionRequestStatus, item.additionalRate)} />
              <Td
                className={getStatusTextClass('EXTENSION_REQUEST_STATUS', item.extensionRequestStatus)}
                data={t(`code:extension-request-status.${item.extensionRequestStatus}`)}
                format="code"
              />
            </Tr>
          );
        });
      };

      const pdfExporterProps: PDFExporterProps = {
        tableHeaders: pdfTableHeaders,
        tableBody: renderPDFTableBodyResult(),
      };

      modal.show(<Exporter spreadSheetExporterProps={excelExporterProps} pdfExporterProps={pdfExporterProps} />, {
        title: t('text:Export_File'),
        closeBtnText: t('text:Close'),
      });
    } catch (e) {
      modal.show(e);
    }
  };

  return (
    <>
      <HeaderTitle title={t('text:Financing_Extension')} />
      <GuideMessage
        message={
          CURRENT_USER_AUTHORITY === AUTHORITY_TYPE.AUTHORIZER
            ? [
                t('text:Click_the_button_on_the_right_to_request_for_an_extension'),
                t('text:Financing_extension_requests_can_be_found_in_the_list_below'),
              ]
            : [t('text:Financing_extension_requests_can_be_found_in_the_list_below')]
        }
      >
        {{
          button: renderRequestButton(),
        }}
      </GuideMessage>

      <div className="content-area">
        <form>
          <SectionTitle title={t('text:Search')}>
            <Button variant={ButtonVariantEnum.OUTLINED} color={ButtonColorEnum.SECONDARY} onClick={onRemoveFilter}>
              {t('text:Remove_Filter')}
            </Button>
          </SectionTitle>
          <SearchBorder>
            <div className="row">
              <SearchLabel label={t('text:Financier_Name')} />
              <SearchInput ref={register} name={getProperty('financierName')} />
              <SearchLabel
                label={t('text:Status')}
                hasStatusDescription={true}
                showStatusDescriptionFunc={onClickExtensionRequestStatus}
              />
              <SearchSelect
                ref={register}
                name={getProperty('extensionRequestStatus')}
                selectOptions={getSelectOptions<EXTENSION_REQUEST_STATUS>('EXTENSION_REQUEST_STATUS', 'ALL', true)}
              />
            </div>
          </SearchBorder>
          <div className="flex-center mt-4">
            <Button size={ButtonSizeEnum.LG} onClick={handleSubmit(onSearchSubmit)}>
              {t('text:Search')}
            </Button>
          </div>
        </form>
      </div>
      <div className="division-border" />
      <div className="content-area">
        <SectionTitle title={t('text:Result')} />
        <div className="d-flex mb-2">
          <p className="total-data me-auto">
            {t('text:Total')} {extensionList?.totalElements}
          </p>
          <Button onClick={onClickExportButton} variant={ButtonVariantEnum.OUTLINED}>
            {t('text:Export')}
          </Button>
        </div>
        <TableBorder>
          <TableHeader header={tableHeaders} />
          <TableBody numOfCol={tableHeaders.length}>{renderExtensionRequestList()}</TableBody>
        </TableBorder>
        <Pagination pageable={pageable} paginate={paginate} />
      </div>
    </>
  );
}

export default DealerExtensionList;
