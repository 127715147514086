import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Tab from 'components/stateless/TabManager/Tab';
import { HeaderTitle } from 'components/stateless/Title/HeaderTitle';
import { COLLATERAL_TYPE } from 'enums';
import useMounted from 'hooks/useMounted';
import usePageable from 'hooks/usePageable';
import type Pageable from 'models/Pageable';
import type { StatisticsOfAnchorAgreementVOModel } from 'models/vo/StatisticsOfAnchorAgreementVO';
import { setFormValues } from 'utils/form/setFormValues';
import { requestFiAnchorStatisticsOfAnchorAgreementsList } from 'utils/http/api/financier/statistics-of-anchor-agreements';
import type { FinancierStatisticsOfAnchorAgreementListRequest } from 'utils/http/api/financier/statistics-of-anchor-agreements/request';
import useModal from 'utils/modal/useModal';
import {
  addSearchParams,
  getParsedSearchParams,
  makeSearchParamsPattern,
  updateSearchParams,
} from 'utils/searchParams';

import FinancierOverviewByAnchorListChannelTab from './sections/channel-tab';
import FinancierOverviewByAnchorListVendorTab from './sections/vendor-tab';

export const FI_OVERVIEW_BY_ANCHOR_EXCEL_EXPORT_MAX_ROW_COUNT = 1000;
export const FI_OVERVIEW_BY_ANCHOR_PDF_EXPORT_MAX_ROW_COUNT = 150;

export const FI_OVERVIEW_BY_ANCHOR_CHANNEL_TAB_QS_KEY = 'fi-overview-by-anchor-channel-tab';
export const FI_OVERVIEW_BY_ANCHOR_VENDOR_TAB_QS_KEY = 'fi-overview-by-anchor-vendor-tab';

function FinancierOverviewByAnchorList() {
  const { t } = useTranslation(['format']);
  const mounted = useMounted();
  const modal = useModal();

  const [overviewByAnchorVendorPage, setOverviewByAnchorVendorPage] =
    useState<Pageable<StatisticsOfAnchorAgreementVOModel[]>>();
  const [overviewByAnchorChannelPage, setOverviewByAnchorChannelPage] =
    useState<Pageable<StatisticsOfAnchorAgreementVOModel[]>>();

  const { pageable: overviewByAnchorVendorPageable, setPageable: setOverviewByAnchorVendorPageable } = usePageable(
    FI_OVERVIEW_BY_ANCHOR_VENDOR_TAB_QS_KEY,
  );
  const { pageable: overviewByAnchorChannelPageable, setPageable: setOverviewByAnchorChannelPageable } = usePageable(
    FI_OVERVIEW_BY_ANCHOR_CHANNEL_TAB_QS_KEY,
  );

  const vendorTabSearchForm = useForm<FinancierStatisticsOfAnchorAgreementListRequest>();

  const channelTabSearchForm = useForm<FinancierStatisticsOfAnchorAgreementListRequest>();

  useEffect(() => {
    if (mounted) {
      setFormValues<FinancierStatisticsOfAnchorAgreementListRequest>(
        vendorTabSearchForm.setValue,
        getParsedSearchParams(FI_OVERVIEW_BY_ANCHOR_VENDOR_TAB_QS_KEY).formSearchData,
      );
      setFormValues<FinancierStatisticsOfAnchorAgreementListRequest>(
        channelTabSearchForm.setValue,
        getParsedSearchParams(FI_OVERVIEW_BY_ANCHOR_CHANNEL_TAB_QS_KEY).formSearchData,
      );
      fetchAll();
    }
  }, [mounted]);

  const fetchAll = async () => {
    const addFiOverviewByAnchorPageQs = () => {
      const vendorTabQs = makeSearchParamsPattern(
        {
          ...vendorTabSearchForm.getValues(),
          pageNumber: overviewByAnchorVendorPageable.currentPage,
          rowCount: overviewByAnchorVendorPageable.sizePerPage,
        },
        FI_OVERVIEW_BY_ANCHOR_VENDOR_TAB_QS_KEY,
      );
      const dealerTabQs = makeSearchParamsPattern(
        {
          ...channelTabSearchForm.getValues(),
          pageNumber: overviewByAnchorChannelPageable.currentPage,
          rowCount: overviewByAnchorChannelPageable.sizePerPage,
        },
        FI_OVERVIEW_BY_ANCHOR_CHANNEL_TAB_QS_KEY,
      );
      addSearchParams(vendorTabQs + dealerTabQs);
    };
    addFiOverviewByAnchorPageQs();

    try {
      const [fetchVendorStatisticsList, fetchDealerStatisticsList] = await Promise.all([
        requestFiAnchorStatisticsOfAnchorAgreementsList(
          overviewByAnchorVendorPageable.currentPage,
          overviewByAnchorVendorPageable.sizePerPage,
          { ...vendorTabSearchForm.getValues(), collateralType: COLLATERAL_TYPE.AR },
        ),
        requestFiAnchorStatisticsOfAnchorAgreementsList(
          overviewByAnchorChannelPageable.currentPage,
          overviewByAnchorChannelPageable.sizePerPage,
          { ...channelTabSearchForm.getValues(), collateralType: COLLATERAL_TYPE.INVOICE },
        ),
      ]);

      ReactDOM.unstable_batchedUpdates(() => {
        setOverviewByAnchorVendorPage(fetchVendorStatisticsList);
        setOverviewByAnchorChannelPage(fetchDealerStatisticsList);

        setOverviewByAnchorVendorPageable(fetchVendorStatisticsList);
        setOverviewByAnchorChannelPageable(fetchDealerStatisticsList);
      });
    } catch (error) {
      modal.show(error);
    }
  };

  const fetchFiAnchorStatisticsListVendorTab = async (
    pageNumber: number = 0,
    rowCount: number = 10,
    data: FinancierStatisticsOfAnchorAgreementListRequest,
  ): Promise<void> => {
    const searchData = {
      ...data,
      collateralType: COLLATERAL_TYPE.AR,
    };
    try {
      const response = await requestFiAnchorStatisticsOfAnchorAgreementsList(pageNumber, rowCount, searchData);
      updateSearchParams(
        {
          ...searchData,
          pageNumber,
          rowCount,
        },
        FI_OVERVIEW_BY_ANCHOR_VENDOR_TAB_QS_KEY,
      );

      ReactDOM.unstable_batchedUpdates(() => {
        setOverviewByAnchorVendorPage(response);
        setOverviewByAnchorVendorPageable(response);
      });
    } catch (error) {
      modal.show(error);
    }
  };

  const onClickSearchVendorTab = async (e: any): Promise<void> => {
    e.preventDefault();
    await fetchFiAnchorStatisticsListVendorTab(
      1,
      overviewByAnchorVendorPageable.sizePerPage,
      vendorTabSearchForm.getValues(),
    );
  };

  const paginateVendorTab = (selectedPageNumber: number, selectedRowCount: number) => {
    fetchFiAnchorStatisticsListVendorTab(
      selectedPageNumber,
      selectedRowCount,
      getParsedSearchParams(FI_OVERVIEW_BY_ANCHOR_VENDOR_TAB_QS_KEY).formSearchData,
    );
  };

  const fetchFiAnchorStatisticsListChannelTab = async (
    pageNumber: number = 0,
    rowCount: number = 10,
    data: FinancierStatisticsOfAnchorAgreementListRequest,
  ): Promise<void> => {
    const searchData = {
      ...data,
      collateralType: COLLATERAL_TYPE.INVOICE,
    };
    try {
      const response = await requestFiAnchorStatisticsOfAnchorAgreementsList(pageNumber, rowCount, searchData);
      updateSearchParams(
        {
          ...searchData,
          pageNumber,
          rowCount,
        },
        FI_OVERVIEW_BY_ANCHOR_CHANNEL_TAB_QS_KEY,
      );

      ReactDOM.unstable_batchedUpdates(() => {
        setOverviewByAnchorChannelPage(response);
        setOverviewByAnchorChannelPageable(response);
      });
    } catch (error) {
      modal.show(error);
    }
  };

  const onClickSearchChannelTab = async (e: any): Promise<void> => {
    e.preventDefault();
    const data = channelTabSearchForm.getValues();
    await fetchFiAnchorStatisticsListChannelTab(1, overviewByAnchorChannelPageable.sizePerPage, data);
  };

  const paginateChannelTab = (selectedPageNumber: number, selectedRowCount: number) => {
    fetchFiAnchorStatisticsListChannelTab(
      selectedPageNumber,
      selectedRowCount,
      getParsedSearchParams(FI_OVERVIEW_BY_ANCHOR_CHANNEL_TAB_QS_KEY).formSearchData,
    );
  };

  return (
    <>
      <HeaderTitle title={t('text:Overview_by_Anchor')} />
      <Tab
        tabType="financeType"
        tabViewList={[
          <FormProvider {...vendorTabSearchForm} key="vendor">
            <FinancierOverviewByAnchorListVendorTab
              onClickSearch={onClickSearchVendorTab}
              overviewByAnchorPageData={overviewByAnchorVendorPage}
              pageable={overviewByAnchorVendorPageable}
              paginate={paginateVendorTab}
            />
          </FormProvider>,
          <FormProvider {...channelTabSearchForm} key="dealer">
            <FinancierOverviewByAnchorListChannelTab
              onClickSearch={onClickSearchChannelTab}
              overviewByAnchorPageData={overviewByAnchorChannelPage}
              pageable={overviewByAnchorChannelPageable}
              paginate={paginateChannelTab}
            />
          </FormProvider>,
        ]}
      />
    </>
  );
}

export default FinancierOverviewByAnchorList;
