import { useState } from 'react';
import ReactDOM from 'react-dom';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { isEmpty } from 'lodash-es';

import type { SelectOptionType } from 'components/stateless/Form/Select/Select';
import ReasonModal from 'components/stateless/Modal/common/ReasonModal';
import type { UserVerificationCodeRequest } from 'components/stateless/Modal/common/UserVerificationModal';
import UserVerificationModal from 'components/stateless/Modal/common/UserVerificationModal';
import AnchorPartnerRegistrationConfirmModal from 'components/stateless/Modal/common/partner/AnchorPartnerRegistrationConfirmModal';
import AnchorPartnerRegistrationResultModal from 'components/stateless/Modal/common/partner/AnchorPartnerRegistrationResultModal';
import { AUTHORITY_TYPE, COMMON_APPROVAL_TYPE, ENTERPRISE_TYPE, OTP_TYPE, PARTNER_STATUS } from 'enums';
import usePageable from 'hooks/usePageable';
import type Pageable from 'models/Pageable';
import type { AnchorPartnerVOModel } from 'models/vo/AnchorPartnerVO';
import type { FinancierClientAuthSettingVOModel } from 'models/vo/FinancierClientAuthSettingVO';
import type { WaitingAnchorPartnerVOModel } from 'models/vo/WaitingAnchorPartnerVO';
import { setFormValues } from 'utils/form/setFormValues';
import { requestAnchorPartnersPage } from 'utils/http/api/anchor/anchor-partners';
import type { AnchorPartnersPageRequest } from 'utils/http/api/anchor/anchor-partners/requests';
import { requestAnchorClientAuthByFinancierId } from 'utils/http/api/anchor/client-auth-setting';
import { requestRelatedAnchorUsers } from 'utils/http/api/anchor/users';
import {
  requestWaitingAnchorPartnerApprove,
  requestWaitingAnchorPartnerCancel,
  requestWaitingAnchorPartnerPage,
  requestWaitingAnchorPartnerReturn,
} from 'utils/http/api/anchor/waiting-anchor-partners';
import type {
  WaitingAnchorPartner,
  WaitingAnchorPartnerPageRequest,
} from 'utils/http/api/anchor/waiting-anchor-partners/requests';
import { setApprovalTypesValue } from 'utils/logic/setApprovalTypesValue';
import { ModalSize, ModalType } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';
import {
  addSearchParams,
  getParsedSearchParams,
  makeSearchParamsPattern,
  updateSearchParams,
} from 'utils/searchParams';
import { getSignIn } from 'utils/storage/LocalStorage';

interface RenderManagerOption {
  targetAuthorityType?: AUTHORITY_TYPE | AUTHORITY_TYPE[];
  targetApprovalType?: COMMON_APPROVAL_TYPE | COMMON_APPROVAL_TYPE[];
}

const getConstant = () => {
  const AC_PARTNER_REGISTRATION_WAITING_LIST_QS_KEY = 'ac-partner-registration-waiting-list';
  const AC_PARTNER_REGISTRATION_CONFIRMED_LIST_QS_KEY = 'ac-partner-registration-confirmed-list';
  const AC_PARTNER_REGISTRATION_FAILED_LIST_QS_KEY = 'ac-partner-registration-failed-list';

  return {
    AC_PARTNER_REGISTRATION_WAITING_LIST_QS_KEY,
    AC_PARTNER_REGISTRATION_CONFIRMED_LIST_QS_KEY,
    AC_PARTNER_REGISTRATION_FAILED_LIST_QS_KEY,
  };
};

interface CheckBoxWaitingAnchorPartnerVOModel extends WaitingAnchorPartnerVOModel {
  disabled?: boolean;
  pageNumber?: number;
  sizePerPage?: number;
  itemIndex?: number;
}

export function useAnchorPartnerRegistrationListState() {
  const {
    AC_PARTNER_REGISTRATION_WAITING_LIST_QS_KEY,
    AC_PARTNER_REGISTRATION_CONFIRMED_LIST_QS_KEY,
    AC_PARTNER_REGISTRATION_FAILED_LIST_QS_KEY,
  } = getConstant();

  const { t } = useTranslation(['format']);
  const modal = useModal();
  const authorityType = getSignIn()?.authorityType;

  const { pageable: waitingAnchorPartnerPageable, setPageable: setWaitingAnchorPartnerPageable } = usePageable(
    AC_PARTNER_REGISTRATION_WAITING_LIST_QS_KEY,
  );
  const WaitingAnchorPartnerPageRequestForm = useForm<WaitingAnchorPartnerPageRequest>({
    mode: 'onSubmit',
  });

  const { pageable: anchorPartnerPageable, setPageable: setAnchorPartnerPageable } = usePageable(
    AC_PARTNER_REGISTRATION_CONFIRMED_LIST_QS_KEY,
  );
  const AnchorPartnersPageRequestForm = useForm<AnchorPartnersPageRequest>({
    mode: 'onSubmit',
  });

  const { pageable: failedAnchorPartnerPageable, setPageable: setFailedAnchorPartnerPageable } = usePageable(
    AC_PARTNER_REGISTRATION_FAILED_LIST_QS_KEY,
  );
  const FailedAnchorPartnerPageForm = useForm<WaitingAnchorPartnerPageRequest>({
    mode: 'onSubmit',
  });

  const [waitingAnchorPartnerPage, setWaitingAnchorPartnerPage] = useState<Pageable<WaitingAnchorPartnerVOModel[]>>();
  const [waitingAnchorPartnerForTabCount, setWaitingAnchorPartnerForTabCount] =
    useState<Pageable<WaitingAnchorPartnerVOModel[]>>();
  const [checkedWaitingAnchorPartner, setCheckedWaitingAnchorPartner] = useState<CheckBoxWaitingAnchorPartnerVOModel[]>(
    [],
  );
  const [anchorRelatedFinancierNames, setAnchorRelatedFinancierNames] = useState<SelectOptionType[]>([
    {
      label: t('text:All'),
      value: '',
    },
  ]);

  const [anchorPartnerPage, setAnchorPartnerPage] = useState<Pageable<AnchorPartnerVOModel[]>>();

  const [failedAnchorPartnerPage, setFailedAnchorPartnerPage] = useState<Pageable<WaitingAnchorPartnerVOModel[]>>();

  const setWaitingTabSearchValue = (formSearchData?: WaitingAnchorPartnerPageRequest) => {
    const getSearchFormData: WaitingAnchorPartnerPageRequest = formSearchData
      ? formSearchData
      : WaitingAnchorPartnerPageRequestForm.getValues();

    const { waitingForApprovalTypeData, notCancelledWaitingForApprovalTypeData } = setApprovalTypesValue(
      getSearchFormData.approvalTypes,
    );

    const waitingForApprovalSearchData = {
      ...getSearchFormData,
      approvalTypes: waitingForApprovalTypeData,
      anchorPartnerStatuses: [PARTNER_STATUS.REGISTERED],
      hasTargetAnchorPartner: false,
    };

    const notCancelledWaitingForApprovalSearchData = {
      ...getSearchFormData,
      approvalTypes: notCancelledWaitingForApprovalTypeData,
      anchorPartnerStatuses: [PARTNER_STATUS.REGISTERED],
      hasTargetAnchorPartner: false,
    };

    return {
      waitingForApprovalSearchData,
      notCancelledWaitingForApprovalSearchData,
    };
  };

  async function fetchAll() {
    setFormValues<WaitingAnchorPartnerPageRequest>(
      WaitingAnchorPartnerPageRequestForm.setValue,
      getParsedSearchParams(AC_PARTNER_REGISTRATION_WAITING_LIST_QS_KEY).formSearchData,
    );

    setFormValues<AnchorPartnersPageRequest>(
      AnchorPartnersPageRequestForm.setValue,
      getParsedSearchParams(AC_PARTNER_REGISTRATION_CONFIRMED_LIST_QS_KEY).formSearchData,
    );

    setFormValues<WaitingAnchorPartnerPageRequest>(
      FailedAnchorPartnerPageForm.setValue,
      getParsedSearchParams(AC_PARTNER_REGISTRATION_FAILED_LIST_QS_KEY).formSearchData,
    );

    const { waitingForApprovalSearchData, notCancelledWaitingForApprovalSearchData } = setWaitingTabSearchValue();

    const anchorPageSearchValue = AnchorPartnersPageRequestForm.getValues();

    const failedSearchFormValues = FailedAnchorPartnerPageForm.getValues();

    const failedAnchorPartnerSearchFormValue = { ...failedSearchFormValues, hasTargetAnchorPartner: false };

    const waitingQs = makeSearchParamsPattern(
      {
        ...waitingForApprovalSearchData,
        pageNumber: waitingAnchorPartnerPageable.currentPage,
        rowCount: waitingAnchorPartnerPageable.sizePerPage,
      },
      AC_PARTNER_REGISTRATION_WAITING_LIST_QS_KEY,
    );
    const completedQs = makeSearchParamsPattern(
      {
        ...anchorPageSearchValue,
        pageNumber: anchorPartnerPageable.currentPage,
        rowCount: anchorPartnerPageable.sizePerPage,
      },
      AC_PARTNER_REGISTRATION_CONFIRMED_LIST_QS_KEY,
    );
    const failedQs = makeSearchParamsPattern(
      {
        ...failedAnchorPartnerSearchFormValue,
        pageNumber: failedAnchorPartnerPageable.currentPage,
        rowCount: failedAnchorPartnerPageable.sizePerPage,
      },
      AC_PARTNER_REGISTRATION_FAILED_LIST_QS_KEY,
    );
    addSearchParams(waitingQs + completedQs + failedQs);

    try {
      const [
        waitingAnchorPartnerPage,
        anchorPartnerPage,
        waitingAnchorPartnerForTabCount,
        relatedAnchorUsers,
        failedAnchorPartnerPage,
      ] = await Promise.all([
        requestWaitingAnchorPartnerPage(
          waitingAnchorPartnerPageable.currentPage,
          waitingAnchorPartnerPageable.sizePerPage,
          waitingForApprovalSearchData,
        ),
        requestAnchorPartnersPage(
          anchorPartnerPageable.currentPage,
          anchorPartnerPageable.sizePerPage,
          anchorPageSearchValue,
        ),
        requestWaitingAnchorPartnerPage(0, 1, notCancelledWaitingForApprovalSearchData),
        requestRelatedAnchorUsers(),
        requestWaitingAnchorPartnerPage(
          failedAnchorPartnerPageable.currentPage,
          failedAnchorPartnerPageable.sizePerPage,
          failedAnchorPartnerSearchFormValue,
        ),
      ]);

      const uniqueFinancierNames = relatedAnchorUsers.reduce(
        (anchorRelatedFinancierNames: SelectOptionType[], anchorInfo) => {
          const { financierName } = anchorInfo;
          if (!anchorRelatedFinancierNames.some(item => item.label === financierName)) {
            anchorRelatedFinancierNames.push({ label: financierName, value: financierName });
          }

          return anchorRelatedFinancierNames;
        },
        [
          {
            label: t('text:All'),
            value: '',
          },
        ],
      );

      ReactDOM.unstable_batchedUpdates(() => {
        setWaitingAnchorPartnerPage(waitingAnchorPartnerPage);
        setWaitingAnchorPartnerPageable(waitingAnchorPartnerPage);
        setAnchorPartnerPage(anchorPartnerPage);
        setAnchorPartnerPageable(anchorPartnerPage);
        setWaitingAnchorPartnerForTabCount(waitingAnchorPartnerForTabCount);
        setFailedAnchorPartnerPage(failedAnchorPartnerPage);
        setFailedAnchorPartnerPageable(failedAnchorPartnerPage);
        setAnchorRelatedFinancierNames(uniqueFinancierNames);
      });
    } catch (error) {
      modal.show(error);
    }
  }

  const renderManager = (option: RenderManagerOption, item: WaitingAnchorPartnerVOModel): boolean => {
    let authorityTypeList = undefined;
    let approvalTypeList = undefined;

    if (option.targetAuthorityType) {
      authorityTypeList = Array.isArray(option.targetAuthorityType)
        ? option.targetAuthorityType
        : [option.targetAuthorityType];
    }

    if (option.targetApprovalType) {
      approvalTypeList = Array.isArray(option.targetApprovalType)
        ? option.targetApprovalType
        : [option.targetApprovalType];
    }

    if (authorityTypeList) {
      if (authorityType && authorityTypeList.includes(authorityType)) {
        if (approvalTypeList) {
          return !!(item?.approvalType && approvalTypeList.includes(item.approvalType));
        } else {
          return true;
        }
      } else {
        return false;
      }
    } else {
      if (approvalTypeList) {
        return !!(item?.approvalType && approvalTypeList.includes(item.approvalType));
      }

      return false;
    }
  };

  const waitingPaginate = async (page: number, sizePerPage: number) => {
    const { waitingForApprovalSearchData } = setWaitingTabSearchValue(
      getParsedSearchParams(AC_PARTNER_REGISTRATION_WAITING_LIST_QS_KEY).formSearchData,
    );

    try {
      const waitingAnchorPartnerPage = await requestWaitingAnchorPartnerPage(
        page,
        sizePerPage,
        waitingForApprovalSearchData,
      );

      updateSearchParams(
        {
          ...waitingForApprovalSearchData,
          pageNumber: page,
          rowCount: sizePerPage,
        },
        AC_PARTNER_REGISTRATION_WAITING_LIST_QS_KEY,
      );

      ReactDOM.unstable_batchedUpdates(() => {
        setWaitingAnchorPartnerPage(waitingAnchorPartnerPage);
        setWaitingAnchorPartnerPageable(waitingAnchorPartnerPage);
      });
    } catch (error) {
      modal.show(error);
    }
  };

  const completePaginate = async (page: number, sizePerPage: number) => {
    try {
      const anchorPartnerPage = await requestAnchorPartnersPage(
        page,
        sizePerPage,
        getParsedSearchParams(AC_PARTNER_REGISTRATION_CONFIRMED_LIST_QS_KEY).formSearchData,
      );

      updateSearchParams(
        {
          ...getParsedSearchParams(AC_PARTNER_REGISTRATION_CONFIRMED_LIST_QS_KEY).formSearchData,
          pageNumber: page,
          rowCount: sizePerPage,
        },
        AC_PARTNER_REGISTRATION_CONFIRMED_LIST_QS_KEY,
      );

      ReactDOM.unstable_batchedUpdates(() => {
        setAnchorPartnerPage(anchorPartnerPage);
        setAnchorPartnerPageable(anchorPartnerPage);
      });
    } catch (error) {
      modal.show(error);
    }
  };

  const failedPaginate = async (page: number, sizePerPage: number) => {
    try {
      const failedAnchorPartnerPage = await requestWaitingAnchorPartnerPage(page, sizePerPage, {
        ...getParsedSearchParams(AC_PARTNER_REGISTRATION_FAILED_LIST_QS_KEY).formSearchData,
        hasTargetAnchorPartner: false,
      });

      updateSearchParams(
        {
          ...getParsedSearchParams(AC_PARTNER_REGISTRATION_FAILED_LIST_QS_KEY).formSearchData,
          hasTargetAnchorPartner: false,
          pageNumber: page,
          rowCount: sizePerPage,
        },
        AC_PARTNER_REGISTRATION_FAILED_LIST_QS_KEY,
      );

      ReactDOM.unstable_batchedUpdates(() => {
        setFailedAnchorPartnerPage(failedAnchorPartnerPage);
        setFailedAnchorPartnerPageable(failedAnchorPartnerPage);
      });
    } catch (error) {
      modal.show(error);
    }
  };

  const onClickConfirm = async () => {
    let anchorClientAuthInfo: FinancierClientAuthSettingVOModel;
    const financierId = checkedWaitingAnchorPartner?.[0].responsibleFinancierId ?? NaN;

    if (financierId) {
      anchorClientAuthInfo = await requestAnchorClientAuthByFinancierId(financierId);
    }

    const onClickConfirmConfirm = async (verificationCode?: UserVerificationCodeRequest): Promise<void> => {
      try {
        const data = {
          financierId,
          otpCode: verificationCode?.otpCode,
          queryValue: verificationCode?.queryValue,
          waitingAnchorPartnerIds: checkedWaitingAnchorPartner.map(data => data.waitingAnchorPartnerId),
        };

        const response = await requestWaitingAnchorPartnerApprove(data);

        modal.show(
          <AnchorPartnerRegistrationResultModal
            requestResult={response.content}
            currentAuthorityType={authorityType}
          />,
          {
            modalSize: ModalSize.XL,
            closeBtnText: t('text:OK'),
            closeBtnCb: () => fetchAll(),
          },
        );
      } catch (error) {
        modal.show(error);
      }
    };

    if (checkedWaitingAnchorPartner.length === 0) {
      modal.show(
        <h6>
          {t('text:No_partner_has_been_selected')}
          <br />
          {t('text:Select_the_partner_to_confirm_or_revert')}
        </h6>,
        {
          modalSize: ModalSize.NONE,
          modalType: ModalType.ALERT,
          closeBtnText: t('text:OK'),
        },
      );
    } else {
      const showVerificationOtpModal = () => {
        const verificationCode: UserVerificationCodeRequest = {};

        modal.show(
          <UserVerificationModal
            modalId={modal.id}
            verificationCode={verificationCode}
            requestIdType="financierId"
            requestId={financierId}
            clientAuthSetting={anchorClientAuthInfo}
          />,
          {
            modalType: ModalType.CONFIRM,
            title: t('text:User_Verification'),
            closeBtnText: t('text:Cancel'),
            confirmBtnCb: () => onClickConfirmConfirm(verificationCode),
          },
        );
      };

      const mapWaitingAnchorPartnerModelToWaitingAnchorPartner = (): WaitingAnchorPartner[] => {
        return checkedWaitingAnchorPartner.map(data => {
          return {
            partnerTaxCode: data.partnerTaxCode,
            name: data.name,
            address: data.address,
            telephone: data.telephone,
            representativeName: data.representativeName,
            representativeEmail: data.representativeEmail,
            account: data.account,
            accountOwner: data.accountOwner,
            requestedAccountBankName: data.accountBankName,
            requestedAccountBranchName: data.accountBranchName,
            bankCode: data.accountBankCode,
            registrationResult: '',
          };
        });
      };

      modal.show(
        <AnchorPartnerRegistrationConfirmModal anchorPartners={mapWaitingAnchorPartnerModelToWaitingAnchorPartner()} />,
        {
          modalSize: ModalSize.XL,
          modalType: ModalType.CONFIRM,
          closeBtnText: t('text:Cancel'),
          confirmBtnCb: () =>
            anchorClientAuthInfo?.otpType !== OTP_TYPE.NONE ? showVerificationOtpModal() : onClickConfirmConfirm(),
        },
      );
    }
  };

  const onClickRevert = () => {
    let reason = '';

    const onClickConfirm = async (): Promise<void> => {
      try {
        const waitingAnchorPartnerIds = checkedWaitingAnchorPartner.map(data => data.waitingAnchorPartnerId);

        await requestWaitingAnchorPartnerReturn({
          returnReason: reason,
          waitingAnchorPartnerIds,
        });

        modal.show(<h6>{t('text:The_Supply_Chain_Partner_registration_request_has_been_reverted')}</h6>, {
          modalSize: ModalSize.NONE,
          modalType: ModalType.ALERT,
          closeBtnText: t('text:OK'),
          closeBtnCb: () => fetchAll(),
        });
      } catch (error) {
        modal.show(error);
      }
    };

    if (checkedWaitingAnchorPartner.length === 0) {
      modal.show(
        <h6>
          {t('text:No_partner_has_been_selected')}
          <br />
          {t('text:Select_the_partner_to_confirm_or_revert')}
        </h6>,
        {
          modalSize: ModalSize.NONE,
          modalType: ModalType.ALERT,
          closeBtnText: t('text:OK'),
        },
      );
    } else {
      modal.show(
        <>
          <h6>
            {t('text:Would_you_like_to_revert_the_Supply_Chain_Partner_registration_request')}
            <br />
            {t('text:Please_type_below_the_reason_for_the_revert')}
            <br />
            {t('text:Click_on_Confirm_to_revert_the_request')}
          </h6>
          <ReasonModal
            modalId={modal.id}
            getReturnReason={(returnReason: string) => {
              reason = returnReason;
            }}
          />
        </>,
        {
          modalSize: ModalSize.NONE,
          modalType: ModalType.CONFIRM,
          closeBtnText: t('text:Cancel'),
          confirmBtnText: t('text:Confirm'),
          confirmBtnCb: () => onClickConfirm(),
        },
      );
    }
  };

  const onClickCancelRequest = () => {
    const onClickConfirm = async (): Promise<void> => {
      try {
        await requestWaitingAnchorPartnerCancel({
          waitingAnchorPartnerIds: checkedWaitingAnchorPartner.map(data => data.waitingAnchorPartnerId),
        });

        fetchAll();
      } catch (error) {
        modal.show(error);
      }
    };

    if (checkedWaitingAnchorPartner.length === 0) {
      modal.show(
        <h6>
          {t('text:No_partner_has_been_selected')}
          <br />
          {t('text:Select_the_partner_whose_registration_you_would_like_to_cancel')}
        </h6>,
        {
          modalSize: ModalSize.NONE,
          modalType: ModalType.ALERT,
          closeBtnText: t('text:OK'),
        },
      );
    } else {
      modal.show(
        <h6>
          {t('text:Would_you_like_to_cancel_the_registration')}{' '}
          {t('text:The_information_will_not_be_saved_if_registration_is_cancelled')}
        </h6>,
        {
          modalSize: ModalSize.NONE,
          modalType: ModalType.CONFIRM,
          closeBtnText: t('text:Close'),
          confirmBtnText: t('text:Confirm'),
          confirmBtnCb: () => onClickConfirm(),
        },
      );
    }
  };

  const onClickWaitingViewSearch = async (e: any) => {
    e.preventDefault();

    setCheckedWaitingAnchorPartner([]);
    const { waitingForApprovalSearchData, notCancelledWaitingForApprovalSearchData } = setWaitingTabSearchValue();

    try {
      const [waitingAnchorPartnerPage, waitingAnchorPartnerForTabCount] = await Promise.all([
        requestWaitingAnchorPartnerPage(1, waitingAnchorPartnerPageable.sizePerPage, waitingForApprovalSearchData),
        requestWaitingAnchorPartnerPage(0, 1, notCancelledWaitingForApprovalSearchData),
      ]);

      updateSearchParams(
        {
          ...waitingForApprovalSearchData,
          pageNumber: 1,
          rowCount: waitingAnchorPartnerPageable.sizePerPage,
        },
        AC_PARTNER_REGISTRATION_WAITING_LIST_QS_KEY,
      );

      ReactDOM.unstable_batchedUpdates(() => {
        setWaitingAnchorPartnerPage(waitingAnchorPartnerPage);
        setWaitingAnchorPartnerPageable(waitingAnchorPartnerPage);
        setWaitingAnchorPartnerForTabCount(waitingAnchorPartnerForTabCount);
      });
    } catch (error) {
      modal.show(error);
    }
  };

  const onClickCompleteViewSearch = async (e: any) => {
    e.preventDefault();

    const anchorPartnerSearchFormValue = AnchorPartnersPageRequestForm.getValues();

    try {
      const anchorPartnerPage = await requestAnchorPartnersPage(
        1,
        anchorPartnerPageable.sizePerPage,
        anchorPartnerSearchFormValue,
      );
      updateSearchParams(
        {
          ...anchorPartnerSearchFormValue,
          pageNumber: 1,
          rowCount: anchorPartnerPageable.sizePerPage,
        },
        AC_PARTNER_REGISTRATION_CONFIRMED_LIST_QS_KEY,
      );

      ReactDOM.unstable_batchedUpdates(() => {
        setAnchorPartnerPage(anchorPartnerPage);
        setAnchorPartnerPageable(anchorPartnerPage);
      });
    } catch (error) {
      modal.show(error);
    }
  };

  const onClickFailedViewSearch = async (e: any) => {
    e.preventDefault();
    const failedSearchFormValues = FailedAnchorPartnerPageForm.getValues();
    const failedAnchorPartnerSearchFormValue = { ...failedSearchFormValues, hasTargetAnchorPartner: false };

    try {
      const failedAnchorPartnerPage = await requestWaitingAnchorPartnerPage(
        1,
        failedAnchorPartnerPageable.sizePerPage,
        failedAnchorPartnerSearchFormValue,
      );
      updateSearchParams(
        {
          ...failedAnchorPartnerSearchFormValue,
          pageNumber: 1,
          rowCount: failedAnchorPartnerPageable.sizePerPage,
        },
        AC_PARTNER_REGISTRATION_FAILED_LIST_QS_KEY,
      );

      ReactDOM.unstable_batchedUpdates(() => {
        setFailedAnchorPartnerPage(failedAnchorPartnerPage);
        setFailedAnchorPartnerPageable(failedAnchorPartnerPage);
      });
    } catch (error) {
      modal.show(error);
    }
  };

  const onChangeCheckbox = async (e: any, item: WaitingAnchorPartnerVOModel): Promise<void> => {
    const isChecked = e.target.checked;

    if (isChecked) {
      if (
        checkedWaitingAnchorPartner.map(data => data.waitingAnchorPartnerId).indexOf(item.waitingAnchorPartnerId) < 0
      ) {
        if (
          (isEmpty(checkedWaitingAnchorPartner) ||
            checkedWaitingAnchorPartner[0].responsibleFinancierId === item.responsibleFinancierId) &&
          (renderManager(
            {
              targetAuthorityType: AUTHORITY_TYPE.HQ_OPERATOR,
              targetApprovalType: [COMMON_APPROVAL_TYPE.OPERATOR_REQUEST, COMMON_APPROVAL_TYPE.AUTHORIZER_RETURNED],
            },
            item,
          ) ||
            renderManager(
              {
                targetAuthorityType: AUTHORITY_TYPE.ADMIN,
                targetApprovalType: COMMON_APPROVAL_TYPE.OPERATOR_REQUEST,
              },
              item,
            ))
        ) {
          const currentArr = [...checkedWaitingAnchorPartner];
          currentArr.push(item);

          ReactDOM.unstable_batchedUpdates(() => {
            setCheckedWaitingAnchorPartner(currentArr);
          });
        } else {
          modal.show(
            <h6>
              {t(
                'text:To_confirm_multiple_partner_accounts_the_associated_Anchor_Agreements_of_the_selected_accounts_must_all_be_registered_with_the_same_financier',
              )}
            </h6>,
            {
              modalType: ModalType.ALERT,
              closeBtnText: t('text:OK'),
            },
          );
        }
      }
    } else {
      setCheckedWaitingAnchorPartner(prev => {
        if (prev.map(data => data.waitingAnchorPartnerId).indexOf(item.waitingAnchorPartnerId) >= 0) {
          const currentArr = [...prev];
          currentArr.splice(prev.map(data => data.waitingAnchorPartnerId).indexOf(item.waitingAnchorPartnerId), 1);

          return currentArr;
        }

        return prev;
      });
    }
  };

  const onClickAllCheckbox = async (e: any) => {
    if (waitingAnchorPartnerPage?.content === undefined) return;

    if (e.target.checked) {
      const checkedCheckboxes = [...checkedWaitingAnchorPartner];

      const availAbleCheckedList = waitingAnchorPartnerPage?.content.filter(item => {
        return (
          renderManager(
            {
              targetAuthorityType: undefined,
              targetApprovalType: [COMMON_APPROVAL_TYPE.OPERATOR_REQUEST],
            },
            item,
          ) && item.operatorEnterpriseType === ENTERPRISE_TYPE.AC
        );
      });

      let showErrorModal = false;

      const checkLogic = (item: WaitingAnchorPartnerVOModel) => {
        if (
          checkedWaitingAnchorPartner.map(data => data.waitingAnchorPartnerId).indexOf(item.waitingAnchorPartnerId) < 0
        ) {
          if (
            availAbleCheckedList[0]?.responsibleFinancierId === item.responsibleFinancierId &&
            (isEmpty(checkedCheckboxes) ||
              checkedCheckboxes[0].responsibleFinancierId === item.responsibleFinancierId) &&
            (renderManager(
              {
                targetAuthorityType: AUTHORITY_TYPE.HQ_OPERATOR,
                targetApprovalType: [COMMON_APPROVAL_TYPE.OPERATOR_REQUEST, COMMON_APPROVAL_TYPE.AUTHORIZER_RETURNED],
              },
              item,
            ) ||
              renderManager(
                {
                  targetAuthorityType: AUTHORITY_TYPE.ADMIN,
                  targetApprovalType: COMMON_APPROVAL_TYPE.OPERATOR_REQUEST,
                },
                item,
              ))
          ) {
            checkedCheckboxes.push(item);
          } else {
            showErrorModal = true;
          }
        }
      };

      waitingAnchorPartnerPage.content.map(async item => {
        if (
          renderManager(
            {
              targetAuthorityType: undefined,
              targetApprovalType: [COMMON_APPROVAL_TYPE.OPERATOR_REQUEST],
            },
            item,
          ) &&
          item.operatorEnterpriseType === ENTERPRISE_TYPE.AC
        ) {
          checkLogic(item);
        }
      });

      if (showErrorModal) {
        modal.show(
          <h6>
            {t(
              'text:To_confirm_multiple_partner_accounts_the_associated_Anchor_Agreements_of_the_selected_accounts_must_all_be_registered_with_the_same_financier',
            )}
          </h6>,
          {
            modalType: ModalType.ALERT,
            closeBtnText: t('text:OK'),
          },
        );
      }

      ReactDOM.unstable_batchedUpdates(() => {
        setCheckedWaitingAnchorPartner(checkedCheckboxes);
      });
    } else {
      const resetChecked = checkedWaitingAnchorPartner.filter(
        item => item.pageNumber !== waitingAnchorPartnerPageable.currentPage,
      );
      setCheckedWaitingAnchorPartner(resetChecked);
    }
  };

  return {
    useForms: { WaitingAnchorPartnerPageRequestForm, AnchorPartnersPageRequestForm, FailedAnchorPartnerPageForm },
    waitingViewStates: {
      waitingAnchorPartnerPage,
      waitingAnchorPartnerForTabCount,
      checkedWaitingAnchorPartner,
      anchorRelatedFinancierNames,
      waitingAnchorPartnerPageable,
      setCheckedWaitingAnchorPartner,
    },
    completeViewStates: { anchorPartnerPage, anchorPartnerPageable },
    failedViewStates: { failedAnchorPartnerPage, failedAnchorPartnerPageable },
    fetchAll,
    waitingViewUtils: {
      onClickConfirm,
      onClickRevert,
      onClickCancelRequest,
      onClickWaitingViewSearch,
      onChangeCheckbox,
      onClickAllCheckbox,
      waitingPaginate,
      renderManager,
    },
    completeViewUtils: { onClickCompleteViewSearch, completePaginate },
    failedViewUtils: { onClickFailedViewSearch, failedPaginate },
    getConstant,
  };
}
