import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import Button, { ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import Exporter from 'components/stateless/Exporter/Exporter';
import Pagination from 'components/stateless/Pagination/Pagination';
import SearchBorder from 'components/stateless/SearchForm/SearchBorder';
import SearchDatePicker from 'components/stateless/SearchForm/SearchDatePicker';
import SearchEmpty from 'components/stateless/SearchForm/SearchEmpty';
import SearchInput from 'components/stateless/SearchForm/SearchInput';
import SearchLabel from 'components/stateless/SearchForm/SearchLabel';
import SearchSelect from 'components/stateless/SearchForm/SearchSelect';
import TableBody from 'components/stateless/Table/TableBody';
import TableBorder from 'components/stateless/Table/TableBorder';
import type { HeaderType } from 'components/stateless/Table/TableHeader';
import TableHeader from 'components/stateless/Table/TableHeader';
import Td from 'components/stateless/Table/Td';
import TdLink from 'components/stateless/Table/TdLink';
import Tr from 'components/stateless/Table/Tr';
import { HeaderTitle } from 'components/stateless/Title/HeaderTitle';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { ROUTES_SY } from 'constants/routes/system';
import getSelectOptions from 'constants/selectOptions';
import type { CURRENCY_TYPE, SUCCESS_AR_STATUS } from 'enums';
import useMounted from 'hooks/useMounted';
import usePageable from 'hooks/usePageable';
import type Pageable from 'models/Pageable';
import type { SuccessArVOModel } from 'models/vo/SuccessArVO';
import { getBlockedStatusClassName } from 'utils/classNames';
import getStatusTextClass from 'utils/classNames/getStatusTextClass';
import type { PDFExporterProps } from 'utils/exportFile/exportPDF.d';
import { setFormValues } from 'utils/form/setFormValues';
import { requestSystemSuccessArsList } from 'utils/http/api/system/success-ars';
import type { SystemSuccessArsListRequest } from 'utils/http/api/system/success-ars/requests';
import useModal from 'utils/modal/useModal';
import { getParsedSearchParams, updateSearchParams } from 'utils/searchParams';
import type { ColumnOption, ExportSpreadSheetProps } from 'utils/spreadSheet/types';
import { tableValueManage } from 'utils/valueManager/ValueManager';

const EXCEL_EXPORT_MAX_ROW_COUNT = 1000;
const PDF_EXPORT_MAX_ROW_COUNT = 150;

const SY_AR_LIST_QS_KEY = 'sy-ar-list';

function SystemArList() {
  const { t } = useTranslation(['format']);
  const { pageable, setPageable } = usePageable(SY_AR_LIST_QS_KEY);

  const mounted = useMounted();
  const modal = useModal();
  const location = useLocation<SystemSuccessArsListRequest>();
  const [successArPage, setSuccessArPage] = useState<Pageable<SuccessArVOModel[]>>();

  const filteredOptions = location.state;

  // useForm
  const { register, getValues, reset, setValue, control } = useForm<SystemSuccessArsListRequest>({
    mode: 'onSubmit',
    shouldFocusError: true, // error 발생 시, 포커스 주는 옵션
  });

  useEffect(() => {
    setFormValues<SystemSuccessArsListRequest>(
      setValue,
      filteredOptions ? filteredOptions : getParsedSearchParams(SY_AR_LIST_QS_KEY).formSearchData,
    );
    if (mounted) {
      fetchSyArList(pageable.currentPage, pageable.sizePerPage, filteredOptions ? filteredOptions : getValues());
    }
  }, [mounted]);

  async function fetchSyArList(pageNumber: number = 0, rowCount: number = 10, data: SystemSuccessArsListRequest) {
    try {
      const anchorSuccessArsPage = await requestSystemSuccessArsList(pageNumber, rowCount, data);
      updateSearchParams(
        {
          ...data,
          pageNumber,
          rowCount,
        },
        SY_AR_LIST_QS_KEY,
      );

      ReactDOM.unstable_batchedUpdates(() => {
        setSuccessArPage(anchorSuccessArsPage);
        setPageable(anchorSuccessArsPage);
      });
    } catch (e) {
      modal.show(e);
    }
  }

  const onClickSearch = async () => {
    await fetchSyArList(0, pageable.sizePerPage, getValues());
  };

  const paginate = async (page: number, sizePerPage: number) => {
    await fetchSyArList(page, sizePerPage, getParsedSearchParams(SY_AR_LIST_QS_KEY).formSearchData);
  };

  const onClickRemoveFilter = (e: any) => {
    e.preventDefault();
    reset({});
  };

  const tableHeaders: HeaderType[] = [
    {
      headerText: t('text:Financier_Name'),
      colWidths: 150,
    },
    {
      headerText: t('text:Partner_Name'),
      colWidths: 150,
    },
    {
      headerText: t('text:Tax_Code'),
      colWidths: 120,
    },
    {
      headerText: t('text:AR_Number'),
      colWidths: 120,
    },
    {
      headerText: t('text:AR_Issued_Date'),
      colWidths: 120,
    },
    {
      headerText: t('text:Payment_Date'),
      colWidths: 120,
    },
    {
      headerText: t('text:Scheduled_Settlement_Date'),
      colWidths: 120,
    },
    {
      headerText: t('text:AR_Amount'),
      colWidths: 100,
    },
    {
      headerText: t('text:Currency'),
      colWidths: 100,
    },
    {
      headerText: t('text:Active_Status'),
      colWidths: 100,
    },
    {
      headerText: t('text:Settlement_Status'),
      colWidths: 120,
    },
    {
      headerText: '',
      colWidths: 50,
    },
  ];

  const renderResultTable = () => {
    return successArPage?.content.map((item, i) => {
      return (
        <Tr key={i}>
          <Td data={item.financierName} />
          <Td data={item.anchorPartnerName} />
          <Td data={item.anchorPartnerTaxCode} />
          <Td data={item.arNumber} />
          <Td data={item.arIssuedDate} format="date" />
          <Td data={item.maturityDate} format="date" />
          <Td data={item.settlementDate} format="date" />
          <Td data={item.arAmount} format="number" />
          <Td data={item.currencyType} />
          <Td
            className={getBlockedStatusClassName(item.blockedByFinancier)}
            data={item.blockedByFinancier ? t('text:Blocked') : t('text:Active')}
          />
          <Td
            className={getStatusTextClass('SUCCESS_AR_STATUS', item.successArStatus)}
            data={t(`code:success-ar-status.${item.successArStatus}`)}
            format="code"
          />
          <TdLink path={ROUTES_SY.VIEW_TRANSACTION.AR_DETAIL_BUILD_PATH(item.successArId)} />
        </Tr>
      );
    });
  };

  const onClickExportButton = async () => {
    const data = getParsedSearchParams(SY_AR_LIST_QS_KEY).formSearchData;

    try {
      const [successArListResponse, successArListPdfResponse] = await Promise.all([
        requestSystemSuccessArsList(0, EXCEL_EXPORT_MAX_ROW_COUNT, data),
        requestSystemSuccessArsList(0, PDF_EXPORT_MAX_ROW_COUNT, data),
      ]);

      const jsonArrayData: unknown[] = successArListResponse.content.map(item => ({
        financierName: tableValueManage(item.financierName),
        anchorPartnerName: tableValueManage(item.anchorPartnerName),
        anchorPartnerTaxCode: tableValueManage(item.anchorPartnerTaxCode),
        arNumber: tableValueManage(item.arNumber),
        arIssuedDate: tableValueManage(item.arIssuedDate, t('format:date', { value: item.arIssuedDate, key: 'date' })),
        maturityDate: tableValueManage(item.maturityDate, t('format:date', { value: item.maturityDate, key: 'date' })),
        settlementDate: tableValueManage(
          item.settlementDate,
          t('format:date', { value: item.settlementDate, key: 'date' }),
        ),
        arAmount: Number(item.arAmount),
        currencyType: tableValueManage(item.currencyType),
        blockedByFinancier: item.blockedByFinancier ? t('text:Blocked') : t('text:Active'),
        successArStatus: tableValueManage(item.successArStatus, t(`code:success-ar-status.${item.successArStatus}`)),
      }));

      const excelColumns: ColumnOption<SuccessArVOModel>[] = [
        {
          header: t('text:Financier_Name'),
          key: 'financierName',
          width: 30,
        },
        {
          header: t('text:Partner_Name'),
          key: 'anchorPartnerName',
          width: 30,
        },
        {
          header: t('text:Tax_Code'),
          key: 'anchorPartnerTaxCode',
          width: 30,
        },
        {
          header: t('text:AR_Number'),
          key: 'arNumber',
          width: 30,
        },
        {
          header: t('text:AR_Issued_Date'),
          key: 'arIssuedDate',
          width: 30,
        },
        {
          header: t('text:Payment_Date'),
          key: 'maturityDate',
          width: 30,
        },
        {
          header: t('text:Scheduled_Settlement_Date'),
          key: 'settlementDate',
          width: 30,
        },
        {
          header: t('text:AR_Amount'),
          key: 'arAmount',
          width: 30,
        },
        {
          header: t('text:Currency'),
          key: 'currencyType',
          width: 30,
        },
        {
          header: t('text:Active_Status'),
          key: 'blockedByFinancier',
          width: 30,
        },
        {
          header: t('text:Settlement_Status'),
          key: 'successArStatus',
          width: 30,
        },
      ];

      const excelExporterProps: ExportSpreadSheetProps<any> = {
        jsonArrayData,
        columns: excelColumns,
        options: {
          rowHeight: [{ position: 1, height: 30 }],
        },
      };

      const pdfTableHeaders: HeaderType[] = [
        {
          headerText: t('text:Financier_Name'),
          colWidths: 150,
        },
        {
          headerText: t('text:Partner_Name'),
          colWidths: 150,
        },
        {
          headerText: t('text:Tax_Code'),
          colWidths: 120,
        },
        {
          headerText: t('text:AR_Number'),
          colWidths: 120,
        },
        {
          headerText: t('text:AR_Issued_Date'),
          colWidths: 120,
        },
        {
          headerText: t('text:Payment_Date'),
          colWidths: 120,
        },
        {
          headerText: t('text:Scheduled_Settlement_Date'),
          colWidths: 120,
        },
        {
          headerText: t('text:AR_Amount'),
          colWidths: 100,
        },
        {
          headerText: t('text:Currency'),
          colWidths: 100,
        },
        {
          headerText: t('text:Active_Status'),
          colWidths: 100,
        },
        {
          headerText: t('text:Settlement_Status'),
          colWidths: 120,
        },
      ];

      const renderPDFTableBodyResult = () => {
        return successArListPdfResponse.content.map((item, i) => {
          return (
            <Tr key={i} className="virtual-table-row">
              <Td data={item.financierName} />
              <Td data={item.anchorPartnerName} />
              <Td data={item.anchorPartnerTaxCode} />
              <Td data={item.arNumber} />
              <Td data={item.arIssuedDate} format="date" />
              <Td data={item.maturityDate} format="date" />
              <Td data={item.settlementDate} format="date" />
              <Td data={item.arAmount} format="number" />
              <Td data={item.currencyType} />
              <Td
                className={getBlockedStatusClassName(item.blockedByFinancier)}
                data={item.blockedByFinancier ? t('text:Blocked') : t('text:Active')}
              />
              <Td
                className={getStatusTextClass('SUCCESS_AR_STATUS', item.successArStatus)}
                data={t(`code:success-ar-status.${item.successArStatus}`)}
                format="code"
              />
            </Tr>
          );
        });
      };

      const pdfExporterProps: PDFExporterProps = {
        tableHeaders: pdfTableHeaders,
        tableBody: renderPDFTableBodyResult(),
      };

      modal.show(<Exporter spreadSheetExporterProps={excelExporterProps} pdfExporterProps={pdfExporterProps} />, {
        title: t('text:Export_File'),
        closeBtnText: t('text:Close'),
      });
    } catch (e) {
      modal.show(e);
    }
  };

  return (
    <>
      <HeaderTitle title={t('text:AR')} />
      <div className="content-area">
        <form>
          <SectionTitle title={t('text:Search')}>
            <Button
              variant={ButtonVariantEnum.OUTLINED}
              color={ButtonColorEnum.SECONDARY}
              onClick={onClickRemoveFilter}
            >
              {t('text:Remove_Filter')}
            </Button>
          </SectionTitle>
          <SearchBorder>
            <div className="row">
              <SearchLabel label={t('text:Financier_Name')} />
              <SearchInput name="financierName" ref={register} />
              <SearchLabel label={t('text:Partner_Name')} />
              <SearchInput name="partnerName" ref={register} />
            </div>
            <div className="row">
              <SearchLabel label={t('text:Tax_Code')} />
              <SearchInput name="partnerTaxCode" ref={register} />
              <SearchLabel label={t('text:AR_Number')} />
              <SearchInput name="arNumber" ref={register} />
            </div>
            <div className="row">
              <SearchLabel label={t('text:AR_Issued_Date')} />
              <SearchDatePicker placeholder={t('text:from')} name="arIssuedDateFrom" control={control} />
              <SearchDatePicker placeholder={t('text:to')} name="arIssuedDateTo" control={control} />
              <SearchLabel label={t('text:Payment_Date')} />
              <SearchDatePicker placeholder={t('text:from')} name="maturityDateFrom" control={control} />
              <SearchDatePicker placeholder={t('text:to')} name="maturityDateTo" control={control} />
            </div>
            <div className="row">
              <SearchLabel label={t('text:Scheduled_Settlement_Date')} />
              <SearchDatePicker placeholder={t('text:from')} name="settlementDateFrom" control={control} />
              <SearchDatePicker placeholder={t('text:to')} name="settlementDateTo" control={control} />
              <SearchLabel label={t('text:AR_Amount')} />
              <SearchInput col={2} name="minimumArAmount" ref={register} placeholder={t('text:from')} />
              <SearchInput col={2} name="maximumArAmount" ref={register} placeholder={t('text:to')} />
            </div>
            <div className="row">
              <SearchLabel label={t('text:Currency')} />
              <SearchSelect
                selectOptions={getSelectOptions<CURRENCY_TYPE>('CURRENCY_TYPE', 'ALL', true)}
                name="currencyType"
                ref={register}
              />
              <SearchLabel label={t('text:Active_Status')} />
              <SearchSelect
                selectOptions={getSelectOptions<string>('BLOCK_STATUS', 'ALL', true)}
                name="blocked"
                ref={register}
              />
            </div>
            <div className="row">
              <SearchLabel label={t('text:Settlement_Status')} />
              <SearchSelect
                selectOptions={getSelectOptions<SUCCESS_AR_STATUS>('SUCCESS_AR_STATUS', 'ALL', true)}
                name="successArStatus"
                ref={register}
              />
              <SearchEmpty />
            </div>
          </SearchBorder>
          <div className="flex-center mt-4">
            <Button size={ButtonSizeEnum.LG} onClick={onClickSearch}>
              {t('text:Search')}
            </Button>
          </div>
        </form>
      </div>
      <div className="division-border" />
      <div className="content-area">
        <SectionTitle title={t('text:Result')} />
        <div className="d-flex mb-2">
          <p className="total-data me-auto">
            {t('text:Total')} {successArPage?.totalElements}
          </p>
          <Button onClick={onClickExportButton} variant={ButtonVariantEnum.OUTLINED}>
            {t('text:Export')}
          </Button>
        </div>
        <TableBorder>
          <TableHeader header={tableHeaders} />
          <TableBody numOfCol={tableHeaders.length}>{renderResultTable()}</TableBody>
        </TableBorder>
        <Pagination pageable={pageable} paginate={paginate} />
      </div>
    </>
  );
}

export default SystemArList;
