import {
  ANCHOR_AGREEMENT_STATUS,
  AR_COMMISSION_STATUS,
  AUTHORIZER_STATUS,
  BANK_CODE_APPROVAL_TYPE,
  COMMON_APPROVAL_TYPE,
  DEALER_AGREEMENT_STATUS,
  EARLY_REPAYMENT_REQUEST_STATUS,
  EMAIL_SENT_STATUS,
  EXECUTION_STATUS,
  EXTENSION_REQUEST_STATUS,
  FACTORING_NOTICE_CONFIRMATION_STATUS,
  LOAN_APPROVAL_TYPE,
  LOAN_STATUS,
  MULTIPLE_LOAN_REQUEST_STATUS,
  POTENTIAL_PARTNER_FINANCING_APPLICATION_DOCUMENT_STATUS,
  POTENTIAL_PARTNER_FINANCING_APPLICATION_STATUS,
  SUCCESS_AR_STATUS,
  SUCCESS_INVOICE_STATUS,
  USER_STATUS,
  WAITING_AR_APPROVAL_PHASE_STATUS,
  WAITING_INVOICE_APPROVAL_PHASE_STATUS,
} from 'enums';

import type { ClassNameContainerOuter } from './types';

const WAITING_INVOICE_APPROVAL_PHASE_STATUS_EXTEND = {
  ...WAITING_INVOICE_APPROVAL_PHASE_STATUS,
};

export type StatusTextClassNameContainerStructure = {
  LOAN_STATUS: typeof LOAN_STATUS;
  LOAN_APPROVAL_TYPE: typeof LOAN_APPROVAL_TYPE;
  EXTENSION_REQUEST_STATUS: typeof EXTENSION_REQUEST_STATUS;
  EARLY_REPAYMENT_REQUEST_STATUS: typeof EARLY_REPAYMENT_REQUEST_STATUS;
  BANK_CODE_APPROVAL_TYPE: typeof BANK_CODE_APPROVAL_TYPE;
  COMMON_APPROVAL_TYPE: typeof COMMON_APPROVAL_TYPE;
  POTENTIAL_PARTNER_FINANCING_APPLICATION_STATUS: typeof POTENTIAL_PARTNER_FINANCING_APPLICATION_STATUS;
  WAITING_INVOICE_APPROVAL_PHASE_STATUS: typeof WAITING_INVOICE_APPROVAL_PHASE_STATUS_EXTEND;
  WAITING_AR_APPROVAL_PHASE_STATUS: typeof WAITING_AR_APPROVAL_PHASE_STATUS;
  EXECUTION_STATUS: typeof EXECUTION_STATUS;
  SUCCESS_AR_STATUS: typeof SUCCESS_AR_STATUS;
  USER_STATUS: typeof USER_STATUS;
  AUTHORIZER_STATUS: typeof AUTHORIZER_STATUS;
  POTENTIAL_PARTNER_FINANCING_APPLICATION_DOCUMENT_STATUS: typeof POTENTIAL_PARTNER_FINANCING_APPLICATION_DOCUMENT_STATUS;
  SUCCESS_INVOICE_STATUS: typeof SUCCESS_INVOICE_STATUS;
  DEALER_AGREEMENT_STATUS: typeof DEALER_AGREEMENT_STATUS;
  ANCHOR_AGREEMENT_STATUS: typeof ANCHOR_AGREEMENT_STATUS;
  AR_COMMISSION_STATUS: typeof AR_COMMISSION_STATUS;
  MULTIPLE_LOAN_REQUEST_STATUS: typeof MULTIPLE_LOAN_REQUEST_STATUS;
  MULTIPLE_LOAN_REQUEST_APPROVAL_TYPE: Partial<typeof COMMON_APPROVAL_TYPE>;
  FACTORING_NOTICE_CONFIRMATION_STATUS: typeof FACTORING_NOTICE_CONFIRMATION_STATUS;
  EMAIL_SENT_STATUS: typeof EMAIL_SENT_STATUS;
};

export const CLASS_NAME_CONTAINER: ClassNameContainerOuter<StatusTextClassNameContainerStructure> = {
  LOAN_STATUS: {
    [LOAN_STATUS.DEALER_CREATED]: 'text-bold-orange-main700',
    [LOAN_STATUS.DEALER_REQUESTED]: 'text-bold-blue-main600',
    [LOAN_STATUS.DEALER_CANCELLED]: 'text-bold-brick-red',
    [LOAN_STATUS.REJECTED]: 'text-bold-brick-red',
    [LOAN_STATUS.APPROVED]: 'text-bold-green',
    [LOAN_STATUS.DISBURSED]: 'text-bold-green',
    [LOAN_STATUS.REPAID]: 'text-bold-dark-green',
    [LOAN_STATUS.RECOURSE_ACTIVATED]: 'text-bold-maroon',
    [LOAN_STATUS.OVERDUE]: 'text-bold-brick-red',
    [LOAN_STATUS.EXPIRATION]: 'text-bold-purple',
  },
  LOAN_APPROVAL_TYPE: {
    [LOAN_APPROVAL_TYPE.OPERATOR_APPROVAL]: 'text-bold-green',
    [LOAN_APPROVAL_TYPE.AUTHORIZER_RETURNED_APPROVAL]: 'text-bold-brick-red',
    [LOAN_APPROVAL_TYPE.OPERATOR_OVERDUE]: 'text-bold-brick-red',
    [LOAN_APPROVAL_TYPE.AUTHORIZER_RETURNED_OVERDUE]: 'text-bold-brick-red',
    [LOAN_APPROVAL_TYPE.OPERATOR_REPAID]: 'text-bold-dark-green',
    [LOAN_APPROVAL_TYPE.OPERATOR_REQUEST_REJECTED]: 'text-bold-brick-red',
    [LOAN_APPROVAL_TYPE.AUTHORIZER_RETURNED_REJECTED]: 'text-bold-brick-red',
    [LOAN_APPROVAL_TYPE.AUTHORIZER_RETURNED_REPAID]: 'text-bold-brick-red',
    [LOAN_APPROVAL_TYPE.OPERATOR_CLEAR_OVERDUE]: 'text-bold-blue-main600',
    [LOAN_APPROVAL_TYPE.AUTHORIZER_RETURNED_CLEAR_OVERDUE]: 'text-bold-brick-red',
    [LOAN_APPROVAL_TYPE.OPERATOR_EXPIRATION]: 'text-bold-purple',
    [LOAN_APPROVAL_TYPE.AUTHORIZER_RETURNED_EXPIRATION]: 'text-bold-brick-red',
    [LOAN_APPROVAL_TYPE.OPERATOR_REQUEST_CANCELLATION_DISBURSEMENT]: 'text-bold-brick-red',
    [LOAN_APPROVAL_TYPE.AUTHORIZER_RETURNED_CANCELLATION_DISBURSEMENT]: 'text-bold-brick-red',
  },
  EXTENSION_REQUEST_STATUS: {
    [EXTENSION_REQUEST_STATUS.REQUESTED]: 'text-bold-orange-main700',
    [EXTENSION_REQUEST_STATUS.APPROVED]: 'text-bold-dark-green',
    [EXTENSION_REQUEST_STATUS.PREAPPROVED]: 'text-bold-green',
    [EXTENSION_REQUEST_STATUS.REJECTED]: 'text-bold-brick-red',
    [EXTENSION_REQUEST_STATUS.RETURNED]: 'text-bold-brick-red',
    [EXTENSION_REQUEST_STATUS.EXTENDED]: 'text-bold-purple',
  },
  EARLY_REPAYMENT_REQUEST_STATUS: {
    [EARLY_REPAYMENT_REQUEST_STATUS.REQUESTED]: 'text-bold-orange-main700',
    [EARLY_REPAYMENT_REQUEST_STATUS.COMPLETED]: 'text-bold-green',
    [EARLY_REPAYMENT_REQUEST_STATUS.EXPIRED]: 'text-bold-purple',
  },
  BANK_CODE_APPROVAL_TYPE: {
    [BANK_CODE_APPROVAL_TYPE.FINANCIER_REQUEST]: 'text-bold-orange-main700',
    [BANK_CODE_APPROVAL_TYPE.FINANCIER_CANCEL]: 'text-bold-brick-red',
    [BANK_CODE_APPROVAL_TYPE.SYSTEM_REJECTED]: 'text-bold-brick-red',
    [BANK_CODE_APPROVAL_TYPE.SYSTEM_APPROVED]: 'text-bold-blue-main600',
  },
  COMMON_APPROVAL_TYPE: {
    [COMMON_APPROVAL_TYPE.OPERATOR_REQUEST]: 'text-bold-orange-main700',
    [COMMON_APPROVAL_TYPE.OPERATOR_CANCEL]: 'text-bold-brick-red',
    [COMMON_APPROVAL_TYPE.AUTHORIZER_RETURNED]: 'text-bold-brick-red',
    [COMMON_APPROVAL_TYPE.AUTHORIZER_APPROVED]: 'text-bold-green',
  },
  SUCCESS_INVOICE_STATUS: {
    [SUCCESS_INVOICE_STATUS.REGISTERED]: 'text-bold-orange-main700',
    [SUCCESS_INVOICE_STATUS.SETTLED]: 'text-bold-dark-green',
    [SUCCESS_INVOICE_STATUS.UNSETTLED]: 'text-bold-brick-red',
  },
  SUCCESS_AR_STATUS: {
    [SUCCESS_AR_STATUS.REGISTERED]: 'text-bold-orange-main700',
    [SUCCESS_AR_STATUS.SETTLED]: 'text-bold-blue-main600',
    [SUCCESS_AR_STATUS.UNSETTLED]: 'text-bold-sub700',
    [SUCCESS_AR_STATUS.FAILED]: 'text-bold-brick-red',
  },
  POTENTIAL_PARTNER_FINANCING_APPLICATION_STATUS: {
    [POTENTIAL_PARTNER_FINANCING_APPLICATION_STATUS.SUBMITTED_CANCEL]: 'text-bold-brick-red',
    [POTENTIAL_PARTNER_FINANCING_APPLICATION_STATUS.SUBMITTED]: 'text-bold-blue-40',
    [POTENTIAL_PARTNER_FINANCING_APPLICATION_STATUS.IN_PROGRESS]: 'text-bold-green-40',
    [POTENTIAL_PARTNER_FINANCING_APPLICATION_STATUS.REJECTED]: 'text-bold-brick-red',
    [POTENTIAL_PARTNER_FINANCING_APPLICATION_STATUS.COMPLETED]: 'text-bold-blue-main600',
  },
  WAITING_INVOICE_APPROVAL_PHASE_STATUS: {
    [WAITING_INVOICE_APPROVAL_PHASE_STATUS.INITIATED]: 'text-bold-light-gray-blue',
    [WAITING_INVOICE_APPROVAL_PHASE_STATUS.REQUESTED]: 'text-bold-orange-main700',
    [WAITING_INVOICE_APPROVAL_PHASE_STATUS.CONFIRMED]: 'text-bold-green',
    [WAITING_INVOICE_APPROVAL_PHASE_STATUS.REJECTED]: 'text-bold-brick-red',
    [WAITING_INVOICE_APPROVAL_PHASE_STATUS.REGISTERED]: 'text-bold-blue-main600',
    [WAITING_INVOICE_APPROVAL_PHASE_STATUS.IN_PROGRESS]: 'text-bold-purple',
  },
  WAITING_AR_APPROVAL_PHASE_STATUS: {
    [WAITING_AR_APPROVAL_PHASE_STATUS.INITIATED]: 'text-bold-light-gray-blue',
    [WAITING_AR_APPROVAL_PHASE_STATUS.REQUESTED]: 'text-bold-orange-main700',
    [WAITING_AR_APPROVAL_PHASE_STATUS.CONFIRMED]: 'text-bold-green',
    [WAITING_AR_APPROVAL_PHASE_STATUS.REGISTERED]: 'text-bold-blue-main600',
    [WAITING_AR_APPROVAL_PHASE_STATUS.IN_PROGRESS]: 'text-bold-purple',
  },
  EXECUTION_STATUS: {
    [EXECUTION_STATUS.NONE]: 'text-bold-light-gray-blue',
    [EXECUTION_STATUS.REGISTERED]: 'text-bold-orange-main700',
    [EXECUTION_STATUS.RUNNING]: 'text-bold-blue-main600',
    [EXECUTION_STATUS.FINISHED]: 'text-bold-brick-red',
    [EXECUTION_STATUS.FAILED]: 'text-bold-brick-red',
  },
  USER_STATUS: {
    [USER_STATUS.REGISTERED]: 'text-bold-sub700',
    [USER_STATUS.INVITED]: 'text-bold-orange-main700',
    [USER_STATUS.ACTIVATED]: 'text-bold-blue-main600',
    [USER_STATUS.SUSPENDED]: 'text-bold-brick-red',
  },
  AUTHORIZER_STATUS: {
    [AUTHORIZER_STATUS.NONE]: 'text-bold-brick-red',
    [AUTHORIZER_STATUS.MAIL_SENT]: 'text-bold-orange-main700',
    [AUTHORIZER_STATUS.ACTIVATED]: 'text-bold-blue-main600',
  },
  POTENTIAL_PARTNER_FINANCING_APPLICATION_DOCUMENT_STATUS: {
    [POTENTIAL_PARTNER_FINANCING_APPLICATION_DOCUMENT_STATUS.SUBMITTED]: 'text-bold-orange-main700',
    [POTENTIAL_PARTNER_FINANCING_APPLICATION_DOCUMENT_STATUS.NEED_RESUBMISSION]: 'text-bold-brick-red',
    [POTENTIAL_PARTNER_FINANCING_APPLICATION_DOCUMENT_STATUS.CONFIRMED]: 'text-bold-dark-green',
  },
  ANCHOR_AGREEMENT_STATUS: {
    [ANCHOR_AGREEMENT_STATUS.ACTIVATED]: 'text-bold-dark-green',
    [ANCHOR_AGREEMENT_STATUS.SUSPENDED]: 'text-bold-brick-red',
  },
  DEALER_AGREEMENT_STATUS: {
    [DEALER_AGREEMENT_STATUS.ACTIVATED]: 'text-bold-dark-green',
    [DEALER_AGREEMENT_STATUS.SUSPENDED]: 'text-bold-brick-red',
  },
  AR_COMMISSION_STATUS: {
    [AR_COMMISSION_STATUS.CREATED]: 'text-bold-orange-main700',
    [AR_COMMISSION_STATUS.PAID]: 'text-bold-green',
    [AR_COMMISSION_STATUS.WAIVED]: 'text-bold-purple',
  },
  MULTIPLE_LOAN_REQUEST_STATUS: {
    [MULTIPLE_LOAN_REQUEST_STATUS.CREATED]: 'text-bold-orange-main700',
    [MULTIPLE_LOAN_REQUEST_STATUS.APPLIED]: 'text-bold-blue-main600',
    [MULTIPLE_LOAN_REQUEST_STATUS.CANCELLED]: 'text-bold-brick-red',
    [MULTIPLE_LOAN_REQUEST_STATUS.REJECTED]: 'text-bold-brick-red',
    [MULTIPLE_LOAN_REQUEST_STATUS.APPROVED]: 'text-bold-green',
  },
  MULTIPLE_LOAN_REQUEST_APPROVAL_TYPE: {
    [COMMON_APPROVAL_TYPE.OPERATOR_REQUEST]: 'text-bold-green',
    [COMMON_APPROVAL_TYPE.AUTHORIZER_RETURNED]: 'text-bold-brick-red',
  },
  FACTORING_NOTICE_CONFIRMATION_STATUS: {
    [FACTORING_NOTICE_CONFIRMATION_STATUS.WAITING]: 'text-bold-orange-main700',
    [FACTORING_NOTICE_CONFIRMATION_STATUS.CONFIRMED]: 'text-bold-green',
  },
  EMAIL_SENT_STATUS: {
    [EMAIL_SENT_STATUS.IN_PROGRESS]: 'text-bold-purple',
    [EMAIL_SENT_STATUS.FAIL]: 'text-bold-brick-red',
    [EMAIL_SENT_STATUS.SUCCESS]: 'text-bold-green',
  },
};

export default function getStatusTextClass<T extends keyof StatusTextClassNameContainerStructure>(
  enumObjectName: T,
  enumData: keyof ClassNameContainerOuter<StatusTextClassNameContainerStructure>[T] | undefined,
): string {
  return enumData ? `${CLASS_NAME_CONTAINER[enumObjectName][enumData]}` : '';
}
