import { COMMON_APPROVAL_TYPE, CURRENCY_TYPE, MULTIPLE_LOAN_REQUEST_STATUS, stringToEnum } from 'enums';
import type { BigNumber } from 'utils/bigNumber';
import { CalculatorBigNumber } from 'utils/bigNumber';

import { formattingToLoanEtcAttachmentVOModel } from './LoanEtcAttachmentVO';

import type { LoanEtcAttachmentVO, LoanEtcAttachmentVOModel } from './LoanEtcAttachmentVO';

export interface MultipleLoanRequestDetailVO {
  multipleLoanRequestId: number;
  dealerAgreementId: number;
  currencyType: string;
  financierId: number;
  financierName: string;
  dealerClientId: number;
  dealerClientName: string;
  anchorClientId: number;
  anchorClientName: string;
  totalArAmount: BigNumber;
  totalRequestAmount: BigNumber;
  averageInterestRate: number;
  totalArCount: number;
  multipleLoanRequestStatus: string;
  approvalType: string;
  factoringEnable: boolean;
  eSignatureEnable: boolean;
  totalInterestAmount: BigNumber;
  averageLoanTerm: number;
  desiredDisburseDate: string;
  cancelDateTime: string;
  cancelReason: string;
  returnDateTime: string;
  returnReason: string;
  rejectDateTime: string;
  rejectReason: string;
  requestedDateTime: string;
  approvedDateTime: string;
  settlementAccountFinancierCode: string;
  settlementAccountFinancierName: string;
  settlementAccountBranchCode: string;
  settlementAccountBranchName: string;
  settlementAccount: string;
  settlementAccountOwner: string;
  principalRepaymentAccountFinancierCode: string;
  principalRepaymentAccountFinancierName: string;
  principalRepaymentAccountBranchCode: string;
  principalRepaymentAccountBranchName: string;
  principalRepaymentAccount: string;
  principalRepaymentAccountOwner: string;
  factoringNotificationAttachmentId: number;
  factoringNotificationAttachmentName: string;
  factoringNotificationAttachmentPath: string;
  agreementAttachmentId: number;
  agreementAttachmentName: string;
  agreementAttachmentPath: string;
  loanEtcAttachment: LoanEtcAttachmentVO[];
  totalRemainingCollateralAmount: BigNumber;
  totalArCommissionAmount: BigNumber;
}

export interface MultipleLoanRequestDetailVOModel extends MultipleLoanRequestDetailVO {
  currencyType: CURRENCY_TYPE;
  multipleLoanRequestStatus: MULTIPLE_LOAN_REQUEST_STATUS;
  approvalType: COMMON_APPROVAL_TYPE;
  loanEtcAttachment: LoanEtcAttachmentVOModel[];
  totalNetDisbursementAmount: BigNumber;
  finalDisbursementAmount: BigNumber;
}

export function formattingToMultipleLoanRequestDetailVOModel(
  data: MultipleLoanRequestDetailVO,
): MultipleLoanRequestDetailVOModel {
  const calculatorBigNumber = new CalculatorBigNumber();

  return {
    ...data,
    currencyType: stringToEnum(CURRENCY_TYPE, data.currencyType),
    multipleLoanRequestStatus: stringToEnum(MULTIPLE_LOAN_REQUEST_STATUS, data.multipleLoanRequestStatus),
    approvalType: stringToEnum(COMMON_APPROVAL_TYPE, data.approvalType),
    loanEtcAttachment: data.loanEtcAttachment.map(loanEtcAttachment =>
      formattingToLoanEtcAttachmentVOModel(loanEtcAttachment),
    ),
    finalDisbursementAmount: calculatorBigNumber
      .add(data.totalRemainingCollateralAmount)
      .minus(data.totalArCommissionAmount)
      .get(),
    totalNetDisbursementAmount: calculatorBigNumber.add(data.totalRequestAmount).minus(data.totalInterestAmount).get(),
  };
}
