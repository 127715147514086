import type Pageable from 'models/Pageable';
import { formattingToFactoringAttachmentVOModel } from 'models/vo/FactoringAttachmentVO';
import type { FactoringAttachmentVO, FactoringAttachmentVOModel } from 'models/vo/FactoringAttachmentVO';
import type {
  LoanAdditionalDocumentAttachmentVO,
  LoanAdditionalDocumentAttachmentVOModel,
} from 'models/vo/LoanAdditionalDocumentAttachmentVO';
import { formattingToLoanAdditionalDocumentAttachmentVOModel } from 'models/vo/LoanAdditionalDocumentAttachmentVO';
import { formattingToMultipleLoanRequestDetailVOModel } from 'models/vo/MultipleLoanRequestDetailVO';
import type {
  MultipleLoanRequestDetailVO,
  MultipleLoanRequestDetailVOModel,
} from 'models/vo/MultipleLoanRequestDetailVO';
import { formattingToMultipleLoanRequestRelatedLoanVOModel } from 'models/vo/MultipleLoanRequestRelatedLoanVO';
import type {
  MultipleLoanRequestRelatedLoanVO,
  MultipleLoanRequestRelatedLoanVOModel,
} from 'models/vo/MultipleLoanRequestRelatedLoanVO';
import { formattingToMultipleLoanRequestRelatedSuccessArVOModel } from 'models/vo/MultipleLoanRequestRelatedSuccessArVO';
import type {
  MultipleLoanRequestRelatedSuccessArVO,
  MultipleLoanRequestRelatedSuccessArVOModel,
} from 'models/vo/MultipleLoanRequestRelatedSuccessArVO';
import { formattingToMultipleLoanRequestVOModel } from 'models/vo/MultipleLoanRequestVO';
import type { MultipleLoanRequestVO, MultipleLoanRequestVOModel } from 'models/vo/MultipleLoanRequestVO';
import http, { ContentType } from 'utils/http';
import API_FI from 'utils/http/api/financier';

import type { FinancierMultipleRequestApproveRequest } from './request';
import type { FinancierMultipleRequestLoanDownloadAttachmentRequest } from './request';
import type { FinancierMultipleRequestLoanListRequest } from './request';

export async function requestFinancierMultipleRequestLoanList(
  pageNumber: number,
  rowCount: number,
  searchValue?: FinancierMultipleRequestLoanListRequest,
): Promise<Pageable<MultipleLoanRequestVOModel[]>> {
  const response = await http.get<Pageable<MultipleLoanRequestVO[]>>({
    url: API_FI.MULTIPLE_REQUEST_LOANS.MULTIPLE_REQUEST_LOAN_LIST,
    data: {
      pageNumber,
      rowCount,
      ...searchValue,
    },
  });

  return {
    ...response,
    content: response.content.map((data: MultipleLoanRequestVO) => formattingToMultipleLoanRequestVOModel(data)),
  };
}

export async function requestFinancierMultipleRequestLoanDetail(
  multipleRequestLoansId: number,
): Promise<MultipleLoanRequestDetailVOModel> {
  const response = await http.get<MultipleLoanRequestDetailVO>({
    url: API_FI.MULTIPLE_REQUEST_LOANS.MULTIPLE_REQUEST_LOAN_DETAIL(multipleRequestLoansId),
  });

  return formattingToMultipleLoanRequestDetailVOModel(response);
}

export async function requestFinancierMultipleRequestLoanArsList(
  multipleRequestLoansId: number,
  pageNumber: number,
  rowCount: number,
): Promise<Pageable<MultipleLoanRequestRelatedSuccessArVOModel[]>> {
  const response = await http.get<Pageable<MultipleLoanRequestRelatedSuccessArVO[]>>({
    url: API_FI.MULTIPLE_REQUEST_LOANS.MULTIPLE_REQUEST_LOAN_AR_LIST(multipleRequestLoansId),
    data: {
      pageNumber,
      rowCount,
    },
  });

  return {
    ...response,
    content: response.content.map((data: MultipleLoanRequestRelatedSuccessArVO) =>
      formattingToMultipleLoanRequestRelatedSuccessArVOModel(data),
    ),
  };
}

export async function requestFinancierMultipleRequestLoanRelatedLoanList(
  multipleRequestLoansId: number,
  pageNumber: number,
  rowCount: number,
): Promise<Pageable<MultipleLoanRequestRelatedLoanVOModel[]>> {
  const response = await http.get<Pageable<MultipleLoanRequestRelatedLoanVO[]>>({
    url: API_FI.MULTIPLE_REQUEST_LOANS.MULTIPLE_REQUEST_LOAN_RELATED_FINANCING_LIST(multipleRequestLoansId),
    data: {
      pageNumber,
      rowCount,
    },
  });

  return {
    ...response,
    content: response.content.map((data: MultipleLoanRequestRelatedLoanVO) =>
      formattingToMultipleLoanRequestRelatedLoanVOModel(data),
    ),
  };
}

export async function requestFinancierMultipleRequestLoanDownloadAttachment(
  multipleRequestLoansId: number,
  data: FinancierMultipleRequestLoanDownloadAttachmentRequest,
) {
  await http.download(API_FI.MULTIPLE_REQUEST_LOANS.DOWNLOAD_ATTACHMENT(multipleRequestLoansId), data);
}

export async function requestFinancierMultipleRequestLoanDownloadAttachmentForEsign(
  multipleRequestLoansId: number,
): Promise<FactoringAttachmentVOModel> {
  const response = await http.get<FactoringAttachmentVO>({
    url: API_FI.MULTIPLE_REQUEST_LOANS.DOWNLOAD_ATTACHMENT_FOR_ESIGN(multipleRequestLoansId),
  });

  return formattingToFactoringAttachmentVOModel(response);
}

export async function requestFinancierMultipleRequestLoanRequest(multipleRequestLoansId: number): Promise<void> {
  await http.put({
    url: API_FI.MULTIPLE_REQUEST_LOANS.REQUEST(multipleRequestLoansId),
  });
}

export async function requestFinancierMultipleRequestLoanReject(
  multipleRequestLoansId: number,
  reason: string,
): Promise<void> {
  await http.put({
    url: API_FI.MULTIPLE_REQUEST_LOANS.REJECT(multipleRequestLoansId),
    data: { reason },
  });
}

export async function requestFinancierMultipleRequestLoanReturn(
  multipleRequestLoansId: number,
  reason: string,
): Promise<void> {
  await http.put({
    url: API_FI.MULTIPLE_REQUEST_LOANS.RETURN(multipleRequestLoansId),
    data: { reason },
  });
}

export async function requestFinancierMultipleRequestLoanCancel(multipleRequestLoansId: number): Promise<void> {
  await http.put({
    url: API_FI.MULTIPLE_REQUEST_LOANS.CANCEL(multipleRequestLoansId),
  });
}

export async function requestFinancierMultipleRequestLoanApprove(
  multipleRequestLoansId: number,
  data?: FinancierMultipleRequestApproveRequest,
): Promise<void> {
  await http.put({
    url: API_FI.MULTIPLE_REQUEST_LOANS.APPROVE(multipleRequestLoansId),
    data,
    contentType: ContentType.MULTIPART,
  });
}

export async function requestFinancierMultipleLoanAdditionalDocumentAttachments(
  multipleRequestLoansId: number,
  pageNumber: number,
  rowCount: number,
): Promise<Pageable<LoanAdditionalDocumentAttachmentVOModel[]>> {
  const response = await http.get<Pageable<LoanAdditionalDocumentAttachmentVO[]>>({
    url: API_FI.MULTIPLE_REQUEST_LOANS.LOAN_ADDITIONAL_DOCUMENT_ATTACHMENTS(multipleRequestLoansId),
    data: {
      pageNumber,
      rowCount,
    },
  });
  const additionalDocumentsList: LoanAdditionalDocumentAttachmentVOModel[] = response.content.map(data =>
    formattingToLoanAdditionalDocumentAttachmentVOModel(data),
  );

  return {
    ...response,
    content: additionalDocumentsList,
  };
}
