import type { MouseEventHandler, ReactNode } from 'react';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { intersectionBy } from 'lodash-es';

import Button, { ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import Exporter from 'components/stateless/Exporter/Exporter';
import ReasonModal from 'components/stateless/Modal/common/ReasonModal';
import AnchorDealerRegistrationConfirmModal from 'components/stateless/Modal/common/dealer/AnchorDealerRegistrationConfirmModal';
import Pagination from 'components/stateless/Pagination/Pagination';
import SearchBorder from 'components/stateless/SearchForm/SearchBorder';
import SearchDatePicker from 'components/stateless/SearchForm/SearchDatePicker';
import SearchEmpty from 'components/stateless/SearchForm/SearchEmpty';
import SearchInput from 'components/stateless/SearchForm/SearchInput';
import SearchLabel from 'components/stateless/SearchForm/SearchLabel';
import SearchSelect from 'components/stateless/SearchForm/SearchSelect';
import TableBody from 'components/stateless/Table/TableBody';
import TableBorder from 'components/stateless/Table/TableBorder';
import type { HeaderType } from 'components/stateless/Table/TableHeader';
import TableHeader from 'components/stateless/Table/TableHeader';
import Td from 'components/stateless/Table/Td';
import TdLink from 'components/stateless/Table/TdLink';
import Tr from 'components/stateless/Table/Tr';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { ROUTES_FI } from 'constants/routes/financier';
import getSelectOptions from 'constants/selectOptions';
import { REQUEST_RESULT } from 'enums';
import { AUTHORITY_TYPE, COMMON_APPROVAL_TYPE } from 'enums';
import type { PageableType } from 'hooks/usePageable';
import type Pageable from 'models/Pageable';
import type { WaitingAnchorDealerVOModel } from 'models/vo/WaitingAnchorDealerVO';
import getStatusTextClass from 'utils/classNames/getStatusTextClass';
import type { WaitingAnchorDealers } from 'utils/http/api/anchor/waiting-anchor-dealers/request';
import type { FinancierWaitingAnchorDealerListRequest } from 'utils/http/api/financier/waiting-anchor-dealers/request';
import { ModalSize, ModalType } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';
import type { ColumnOption, ExportSpreadSheetProps } from 'utils/spreadSheet/types';
import { getSignIn } from 'utils/storage/LocalStorage';
import { tableValueManage } from 'utils/valueManager/ValueManager';

import { WAITING_COMMON_APPROVAL_TYPE_ALL_VALUES } from '../../../../../../utils/logic/setApprovalTypesValue';

interface FinancierAnchorDealerRegistrationListWaitingTabProps {
  waitingAnchorDealerList: Pageable<WaitingAnchorDealerVOModel[]>;
  pageable: PageableType;
  paginate(page: number, sizePerPage: number): void;
  handleClickSearch: MouseEventHandler<HTMLButtonElement>;
  cancelRequest(ids: number[]): Promise<REQUEST_RESULT>;
  revertRequest: (ids: number[], reason: string) => Promise<REQUEST_RESULT>;
  approveRequest(waitingAnchorDealerIds: number[]): Promise<REQUEST_RESULT>;
  getExcelExportWaitingList(): Promise<Pageable<WaitingAnchorDealerVOModel[]>>;
}

const PDF_EXPORT_MAX_ROW_COUNT = 150;
function FinancierAnchorDealerRegistrationListWaitingTab({
  waitingAnchorDealerList,
  pageable,
  paginate,
  handleClickSearch,
  cancelRequest,
  revertRequest,
  approveRequest,
  getExcelExportWaitingList,
}: FinancierAnchorDealerRegistrationListWaitingTabProps) {
  const { register, reset, control } = useFormContext<FinancierWaitingAnchorDealerListRequest>();
  const { t } = useTranslation(['format']);
  const modal = useModal();
  const authorityType = getSignIn()?.authorityType;
  const isAdmin = authorityType === AUTHORITY_TYPE.ADMIN;

  const [checkedWaitingAnchorDealer, setCheckedWaitingAnchorDealer] = useState<WaitingAnchorDealerVOModel[]>([]);

  useEffect(() => {
    setCheckedWaitingAnchorDealer([]);
  }, [pageable.currentPage]);

  useEffect(() => {
    const newCheckedWaitingAnchorPartner = intersectionBy(availAbleCheckedList, checkedWaitingAnchorDealer, 'id');
    setCheckedWaitingAnchorDealer(newCheckedWaitingAnchorPartner);
  }, [pageable.sizePerPage]);

  const handleClickCancel = () => {
    if (checkedWaitingAnchorDealer.length === 0) {
      modal.show(
        <h6>
          {t('text:No_Dealer_has_been_selected')}
          <br />
          {t('text:Select_the_Dealer_whose_registration_you_would_like_to_cancel')}
        </h6>,
        {
          modalSize: ModalSize.NONE,
          modalType: ModalType.ALERT,
          closeBtnText: t('text:OK'),
        },
      );
    } else {
      modal.show(
        <h6>
          {t('text:Would_you_like_to_cancel_the_registration')}{' '}
          {t('text:The_information_will_not_be_saved_if_registration_is_cancelled')}
        </h6>,
        {
          modalSize: ModalSize.NONE,
          modalType: ModalType.CONFIRM,
          closeBtnText: t('text:Close'),
          confirmBtnText: t('text:Confirm'),
          confirmBtnCb: async () => {
            const isSuccess = await cancelRequest(checkedWaitingAnchorDealer.map(data => data.id));
            if (isSuccess === REQUEST_RESULT.SUCCESS) setCheckedWaitingAnchorDealer([]);
          },
        },
      );
    }
  };
  const handelClickRevert = async () => {
    let reason = '';

    if (checkedWaitingAnchorDealer.length === 0) {
      modal.show(
        <h6>
          {t('text:No_Dealer_has_been_selected')}
          <br />
          {t('text:Select_the_dealer_to_confirm_or_revert')}
        </h6>,
        {
          modalSize: ModalSize.NONE,
          modalType: ModalType.ALERT,
          closeBtnText: t('text:OK'),
        },
      );
    } else {
      modal.show(
        <>
          <h6>
            {t('text:Would_you_like_to_revert_the_registration_request?')}
            <br />
            {t('text:Please_type_below_the_reason_for_the_revert')}
            <br />
            {t('text:Click_on_Confirm_to_revert_the_request')}
          </h6>
          <ReasonModal
            modalId={modal.id}
            getReturnReason={(returnReason: string) => {
              reason = returnReason;
            }}
          />
        </>,
        {
          modalSize: ModalSize.NONE,
          modalType: ModalType.CONFIRM,
          closeBtnText: t('text:Cancel'),
          confirmBtnText: t('text:Confirm'),
          confirmBtnCb: async () => {
            const isSuccess = await revertRequest(
              checkedWaitingAnchorDealer.map(data => data.id),
              reason,
            );
            if (isSuccess === REQUEST_RESULT.SUCCESS) setCheckedWaitingAnchorDealer([]);
          },
        },
      );
    }
  };

  const handleClickConfirm = async () => {
    if (checkedWaitingAnchorDealer.length === 0) {
      modal.show(
        <h6>
          {t('text:No_Dealer_has_been_selected')}
          <br />
          {t('text:Select_the_dealer_to_confirm_or_revert')}
        </h6>,
        {
          modalSize: ModalSize.NONE,
          modalType: ModalType.ALERT,
          closeBtnText: t('text:OK'),
        },
      );
    } else {
      const mapWaitingAnchorDealerModelToWaitingAnchorDealers = (): WaitingAnchorDealers[] => {
        return checkedWaitingAnchorDealer.map(data => {
          return {
            name: data.name,
            taxCode: data.taxCode,
            address: data.address,
            telephone: data.telephone,
            representativeName: data.representativeName,
            representativeEmail: data.representativeEmail,
          };
        });
      };

      modal.show(
        <AnchorDealerRegistrationConfirmModal
          waitingAnchorDealers={mapWaitingAnchorDealerModelToWaitingAnchorDealers()}
        />,
        {
          modalSize: ModalSize.XL,
          modalType: ModalType.CONFIRM,
          closeBtnText: t('text:Cancel'),
          confirmBtnCb: async () => {
            const isSuccess = await approveRequest(checkedWaitingAnchorDealer.map(data => data.id));
            if (isSuccess === REQUEST_RESULT.SUCCESS) setCheckedWaitingAnchorDealer([]);
          },
        },
      );
    }
  };

  const renderButtons = (): ReactNode | null => {
    if (authorityType === AUTHORITY_TYPE.AUTHORIZER) {
      return (
        <div className="flex-end mt-3">
          <Button
            size={ButtonSizeEnum.LG}
            onClick={handelClickRevert}
            disabled={!checkedWaitingAnchorDealer.length}
            variant={ButtonVariantEnum.OUTLINED}
            color={ButtonColorEnum.RED}
            className="me-2"
          >
            {t('text:Revert')}
          </Button>
          <Button size={ButtonSizeEnum.LG} onClick={handleClickConfirm} disabled={!checkedWaitingAnchorDealer.length}>
            {t('text:Confirm')}
          </Button>
        </div>
      );
    } else if (authorityType === AUTHORITY_TYPE.OPERATOR) {
      return (
        <Button
          size={ButtonSizeEnum.LG}
          onClick={handleClickCancel}
          disabled={!checkedWaitingAnchorDealer.length}
          variant={ButtonVariantEnum.OUTLINED}
          color={ButtonColorEnum.SECONDARY}
        >
          {t('text:Cancel_Request')}
        </Button>
      );
    } else {
      return null;
    }
  };

  const onChangeCheckbox = async (e: any, item: WaitingAnchorDealerVOModel): Promise<void> => {
    const isChecked = e.target.checked;
    const isItemAlreadyChecked = checkedWaitingAnchorDealer.some(data => data.id === item.id);

    if (isChecked) {
      setCheckedWaitingAnchorDealer(prev => [...prev, item]);
    } else {
      setCheckedWaitingAnchorDealer(prev => {
        if (!isItemAlreadyChecked) return prev;

        return prev.filter(data => data.id !== item.id);
      });
    }
  };

  const onClickAllCheckbox = async (e: any) => {
    if (waitingAnchorDealerList?.content === undefined) return;

    if (e.target.checked) {
      const checkedCheckboxes = [...checkedWaitingAnchorDealer];

      availAbleCheckedList?.map(item => {
        if (checkedWaitingAnchorDealer?.map(data => data.id).indexOf(item.id) < 0) {
          checkedCheckboxes.push(item);
        }
      });

      setCheckedWaitingAnchorDealer(checkedCheckboxes);
    } else {
      setCheckedWaitingAnchorDealer([]);
    }
  };

  const renderCheckbox = (item: WaitingAnchorDealerVOModel): boolean => {
    return item.approvalType === COMMON_APPROVAL_TYPE.OPERATOR_REQUEST && !isAdmin;
  };

  const availAbleCheckedList = waitingAnchorDealerList?.content?.filter(item => renderCheckbox(item)) || [];

  const WAITING_TAB_TABLE_HEADERS: HeaderType[] = [
    {
      headerText: '',
      className: 'text-center',
      colWidths: 50,
      hasCheckBox: !isAdmin,
      handleClickAllCheckBox: onClickAllCheckbox,
      isAllChecked:
        availAbleCheckedList.length !== 0 && availAbleCheckedList.length === checkedWaitingAnchorDealer.length,
    },
    {
      headerText: t('text:Registration_Date'),
    },
    {
      headerText: t('text:Uploaded_Dealer_Name'),
    },
    {
      headerText: t('text:Uploaded_Dealer_Tax_Code'),
    },
    {
      headerText: t('text:Associated_Anchor_Name'),
    },
    {
      headerText: t('text:Legal_Representative_Name'),
    },
    {
      headerText: t('text:Legal_Representative_Email'),
    },
    {
      headerText: t('text:Telephone'),
    },
    {
      headerText: t('text:Registered_Office_Address'),
    },
    {
      headerText: t('text:Registration_Status'),
    },
    {
      headerText: '',
      colWidths: 50,
    },
  ];

  const onClickWaitingViewExportButton = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();

    const excelExportFailedList = await getExcelExportWaitingList();
    const getRegistrationConfirmedTableHeaders = (isPDFHeader = false) => {
      const headers = [
        {
          headerText: t('text:Registration_Date'),
          colWidths: 100,
        },
        {
          headerText: t('text:Uploaded_Dealer_Name'),
          colWidths: 100,
        },
        {
          headerText: t('text:Uploaded_Dealer_Tax_Code'),
          colWidths: 120,
        },
        {
          headerText: t('text:Associated_Anchor_Name'),
          colWidths: 100,
        },
        {
          headerText: t('text:Legal_Representative_Name'),
          colWidths: 100,
        },
        {
          headerText: t('text:Legal_Representative_Email'),
          colWidths: 100,
        },
        {
          headerText: t('text:Telephone'),
          colWidths: 100,
        },
        {
          headerText: t('text:Registered_Office_Address'),
          colWidths: 100,
        },

        {
          headerText: t('text:Registration_Status'),
          colWidths: 100,
        },
      ];

      return isPDFHeader ? headers : [...headers, { headerText: '', colWidths: 50 }];
    };

    const CONFIRMED_VIEW_EXCEL_COLUMNS: ColumnOption<WaitingAnchorDealerVOModel>[] = [
      {
        header: t('text:Registration_Date'),
        key: 'createdDateTime',
        width: 25,
      },
      {
        header: t('text:Uploaded_Dealer_Name'),
        key: 'name',
        width: 20,
      },
      {
        header: t('text:Uploaded_Dealer_Tax_Code'),
        key: 'taxCode',
        width: 30,
      },
      {
        header: t('text:Associated_Anchor_Name'),
        key: 'anchorName',
        width: 30,
      },
      {
        header: t('text:Legal_Representative_Name'),
        key: 'representativeName',
        width: 30,
      },
      {
        header: t('text:Legal_Representative_Email'),
        key: 'representativeEmail',
        width: 30,
      },
      {
        header: t('text:Telephone'),
        key: 'telephone',
        width: 20,
      },
      {
        header: t('text:Registered_Office_Address'),
        key: 'address',
        width: 30,
      },

      {
        header: t('text:Registration_Status'),
        key: 'approvalType',
        width: 30,
      },
    ];

    const renderPDFTableBodyResult = (data?: WaitingAnchorDealerVOModel[]) => {
      return data?.map((item, index) => (
        <tr key={index} className="virtual-table-row">
          <Td data={item.createdDateTime} format="datetime" />
          <Td data={item.name} />
          <Td data={item.taxCode} />
          <Td data={item.anchorName} />
          <Td data={item.representativeName} />
          <Td data={item.representativeEmail} />
          <Td data={item.telephone} />
          <Td data={item.address} />
          <Td
            className={getStatusTextClass('COMMON_APPROVAL_TYPE', item.approvalType)}
            data={t(`code:common-approval-type.${item.approvalType}`)}
          />
        </tr>
      ));
    };

    try {
      const jsonArrayData = excelExportFailedList.content.map(confirmedAnchorDealerData => ({
        createdDateTime: tableValueManage(
          confirmedAnchorDealerData.createdDateTime,
          t('format:datetime', {
            value: confirmedAnchorDealerData.createdDateTime,
            key: 'datetime',
          }),
        ),
        name: tableValueManage(confirmedAnchorDealerData.name),
        taxCode: tableValueManage(confirmedAnchorDealerData.taxCode),
        address: tableValueManage(confirmedAnchorDealerData.address),
        representativeName: tableValueManage(confirmedAnchorDealerData.representativeName),
        representativeEmail: tableValueManage(confirmedAnchorDealerData.representativeEmail),
        telephone: tableValueManage(confirmedAnchorDealerData.telephone),
        anchorName: tableValueManage(confirmedAnchorDealerData.anchorName),
        approvalType: t(`code:common-approval-type.${confirmedAnchorDealerData.approvalType}`),
      }));

      const excelExporterProps: ExportSpreadSheetProps<WaitingAnchorDealerVOModel> = {
        jsonArrayData: jsonArrayData,
        columns: CONFIRMED_VIEW_EXCEL_COLUMNS,
        options: {
          rowHeight: [{ position: 1, height: 30 }],
        },
      };

      const confirmedAnchorDealerPDFPage = excelExportFailedList.content.slice(0, PDF_EXPORT_MAX_ROW_COUNT);

      const pdfExporterProps = {
        tableHeaders: getRegistrationConfirmedTableHeaders(true),
        tableBody: renderPDFTableBodyResult(confirmedAnchorDealerPDFPage),
      };

      modal.show(<Exporter spreadSheetExporterProps={excelExporterProps} pdfExporterProps={pdfExporterProps} />, {
        title: t('text:Export_File'),
        closeBtnText: t('text:Close'),
      });
    } catch (error) {
      modal.show(error);
    }
  };

  return (
    <>
      <div className="content-area">
        <SectionTitle title={t('text:Search')}>
          <Button
            variant={ButtonVariantEnum.OUTLINED}
            color={ButtonColorEnum.SECONDARY}
            onClick={() => {
              reset({});
            }}
          >
            {t('text:Remove_Filter')}
          </Button>
        </SectionTitle>
        <form>
          <SearchBorder>
            <div className="row">
              <SearchLabel label={t('text:Registration_Date')} />
              <SearchDatePicker placeholder={t('text:from')} name="fromDate" control={control} />
              <SearchDatePicker placeholder={t('text:to')} name="toDate" control={control} />
              <SearchLabel label={t('text:Uploaded_Dealer_Name')} />
              <SearchInput name="name" ref={register} />
            </div>
            <div className="row">
              <SearchLabel label={t('text:Uploaded_Dealer_Tax_Code')} />
              <SearchInput name="taxCode" ref={register} />
              <SearchLabel label={t('text:Associated_Anchor_Name')} />
              <SearchInput name="anchorClientName" ref={register} />
            </div>
            <div className="row">
              <SearchLabel label={t('text:Legal_Representative_Name')} />
              <SearchInput name="representativeName" ref={register} />
              <SearchLabel label={t('text:Legal_Representative_Email')} />
              <SearchInput name="representativeEmail" ref={register} />
            </div>
            <div className="row">
              <SearchLabel label={t('text:Telephone')} />
              <SearchInput name="telephone" ref={register} />
              <SearchLabel label={t('text:Registered_Office_Address')} />
              <SearchInput name="address" ref={register} />
            </div>
            <div className="row">
              <SearchLabel label={t('text:Registration_Status')} />
              <SearchSelect
                name="approvalTypes"
                ref={register}
                selectOptions={[
                  {
                    label: t('text:All'),
                    value: WAITING_COMMON_APPROVAL_TYPE_ALL_VALUES,
                  },
                  {
                    label: t('text:Requested'),
                    value: COMMON_APPROVAL_TYPE.OPERATOR_REQUEST,
                  },
                  {
                    label: t('text:Reverted'),
                    value: COMMON_APPROVAL_TYPE.AUTHORIZER_RETURNED,
                  },
                  {
                    label: t('text:Cancelled'),
                    value: COMMON_APPROVAL_TYPE.OPERATOR_CANCEL,
                  },
                ]}
              />
              <SearchEmpty />
            </div>
          </SearchBorder>
          <div className="flex-center mt-4">
            <Button size={ButtonSizeEnum.LG} onClick={handleClickSearch}>
              {t('text:Search')}
            </Button>
          </div>
        </form>
      </div>
      <div className="division-border" />
      <div className="content-area">
        <SectionTitle title={t('text:Result')} />
        <div className="d-flex mb-2">
          <p className="total-data me-auto">
            {t('text:Total')} : {waitingAnchorDealerList.totalElements}
          </p>
          <Button onClick={onClickWaitingViewExportButton} variant={ButtonVariantEnum.OUTLINED}>
            {t('text:Export')}
          </Button>
        </div>
        <TableBorder>
          <TableHeader header={WAITING_TAB_TABLE_HEADERS} />
          <TableBody numOfCol={WAITING_TAB_TABLE_HEADERS.length}>
            {waitingAnchorDealerList?.content?.map((item: WaitingAnchorDealerVOModel, index: number) => (
              <Tr key={index}>
                <Td className="text-center">
                  {renderCheckbox(item) ? (
                    <input
                      className="form-check-input m-0"
                      type="checkbox"
                      onChange={e => onChangeCheckbox(e, item)}
                      checked={checkedWaitingAnchorDealer?.map(data => data.id).includes(item.id)}
                    />
                  ) : (
                    <></>
                  )}
                </Td>
                <Td data={item.createdDateTime} format="datetime" />
                <Td data={item.name} />
                <Td data={item.taxCode} />
                <Td data={item.anchorName} />
                <Td data={item.representativeName} />
                <Td data={item.representativeEmail} />
                <Td data={item.telephone} />
                <Td data={item.address} />
                <Td
                  className={getStatusTextClass('COMMON_APPROVAL_TYPE', item.approvalType)}
                  data={t(`code:common-approval-type.${item.approvalType}`)}
                />
                <TdLink path={ROUTES_FI.MANAGE_DEALER.REGISTRATION_WAITING_DETAIL_BUILD_PATH(item.id)} />
              </Tr>
            ))}
          </TableBody>
        </TableBorder>
        <Pagination pageable={pageable} paginate={paginate} />
      </div>
      <div className="content-area">{renderButtons()}</div>
    </>
  );
}

export default FinancierAnchorDealerRegistrationListWaitingTab;
