import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Button, { ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import GuideMessage from 'components/stateless/GuideMessage/GuideMessage';
import Pagination from 'components/stateless/Pagination/Pagination';
import SearchBorder from 'components/stateless/SearchForm/SearchBorder';
import SearchInput from 'components/stateless/SearchForm/SearchInput';
import SearchLabel from 'components/stateless/SearchForm/SearchLabel';
import SearchSelect from 'components/stateless/SearchForm/SearchSelect';
import Tab from 'components/stateless/TabManager/Tab';
import TableBody from 'components/stateless/Table/TableBody';
import TableBorder from 'components/stateless/Table/TableBorder';
import type { HeaderType } from 'components/stateless/Table/TableHeader';
import TableHeader from 'components/stateless/Table/TableHeader';
import Td from 'components/stateless/Table/Td';
import TdLink from 'components/stateless/Table/TdLink';
import Tr from 'components/stateless/Table/Tr';
import { HeaderTitle } from 'components/stateless/Title/HeaderTitle';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { ROUTES_FI } from 'constants/routes/financier';
import getSelectOptions from 'constants/selectOptions';
import type { CURRENCY_TYPE } from 'enums';
import { COMMON_APPROVAL_TYPE, ENTERPRISE_TYPE } from 'enums';
import useMounted from 'hooks/useMounted';
import usePageable from 'hooks/usePageable';
import type Pageable from 'models/Pageable';
import type { AnchorPartnerAccountVOModel } from 'models/vo/AnchorPartnerAccountVO';
import type { WaitingAnchorPartnerAccountVOModel } from 'models/vo/WaitingAnchorPartnerAccountVO';
import getStatusTextClass from 'utils/classNames/getStatusTextClass';
import { setFormValues } from 'utils/form/setFormValues';
import { requestFiAnchorPartnerAccountList } from 'utils/http/api/financier/anchor-partner-accounts';
import type { AnchorPartnerAccountListRequest } from 'utils/http/api/financier/anchor-partner-accounts/requests';
import { requestFinancierWaitingAnchorPartnerAccountList } from 'utils/http/api/financier/waiting-anchor-partner-accounts';
import type { FinancierWaitingAnchorPartnerAccountListRequest } from 'utils/http/api/financier/waiting-anchor-partner-accounts/requests';
import { getWaitingForApprovalCount } from 'utils/logic';
import { WAITING_COMMON_APPROVAL_TYPE_ALL_VALUES, setApprovalTypesValue } from 'utils/logic/setApprovalTypesValue';
import useModal from 'utils/modal/useModal';
import {
  addSearchParams,
  getParsedSearchParams,
  makeSearchParamsPattern,
  updateSearchParams,
} from 'utils/searchParams';

const getConstant = () => {
  const { t } = useTranslation(['format']);

  const GUIDE_MESSAGE: string[] = [
    t('text:Review_the_bank_account_information_of_Uploaded_Partners_on_the_Waiting_for_Approval_tab'),
    t('text:If_the_account_information_is_invalid_payment_will_not_be_successful'),
    t(
      'text:Once_the_Partners_financing_agreement_is_registered_on_the_platform_the_settlement_and_the_repayment_will_be_made_through_the_bank_account_information_on_the_financing_agreement',
    ),
  ];

  const FI_PARTNER_BANK_ACCOUNT_REVISION_WAITING_LIST_QS_KEY = 'ac-bank-account-revision-waiting-list';
  const FI_PARTNER_BANK_ACCOUNT_REVISION_CONFIRMED_LIST_QS_KEY = 'ac-bank-account-revision-confirmed-list';

  const WAITING_FOR_CONFIRMATION_TABLE_HEADERS: HeaderType[] = [
    {
      headerText: t('text:Uploaded_Partner_Name'),
      colWidths: 100,
    },
    {
      headerText: t('text:Uploaded_Partner_Tax_Code'),
      colWidths: 120,
    },
    {
      headerText: t('text:Associated_Anchor_Name'),
      colWidths: 100,
    },
    {
      headerText: t('text:Associated_Master_Agreement_of_Anchor_Number'),
      colWidths: 120,
    },
    {
      headerText: t('text:Currency'),
      colWidths: 100,
    },
    {
      headerText: t('text:Bank_Name'),
      colWidths: 100,
    },
    {
      headerText: t('text:Bank_Account_Number'),
      colWidths: 100,
    },
    {
      headerText: t('text:Bank_Account_Validity'),
      colWidths: 100,
    },
    {
      headerText: t('text:Revised_By'),
      colWidths: 80,
    },
    {
      headerText: t('text:Approval_Status'),
      colWidths: 90,
      // hasStatusDescription: true,
      // showStatusDescriptionFunc: onClickRegistrationStatus,
    },
    {
      headerText: '',
      colWidths: 50,
    },
  ];
  const REVISION_CONFIRMED_TABLE_HEADERS: HeaderType[] = [
    {
      headerText: t('text:Uploaded_Partner_Name'),
      colWidths: 100,
    },
    {
      headerText: t('text:Uploaded_Partner_Tax_Code'),
      colWidths: 120,
    },
    {
      headerText: t('text:Associated_Anchor_Name'),
      colWidths: 120,
    },
    {
      headerText: t('text:Associated_Master_Agreement_of_Anchor_Number'),
      colWidths: 120,
    },
    {
      headerText: t('text:Currency'),
      colWidths: 100,
    },
    {
      headerText: t('text:Bank_Name'),
      colWidths: 100,
    },
    {
      headerText: t('text:Bank_Account_Number'),
      colWidths: 100,
    },
    {
      headerText: t('text:Bank_Account_Validity'),
      colWidths: 100,
    },
    {
      headerText: '',
      colWidths: 50,
    },
  ];

  return {
    GUIDE_MESSAGE,
    WAITING_FOR_CONFIRMATION_TABLE_HEADERS,
    REVISION_CONFIRMED_TABLE_HEADERS,
    FI_PARTNER_BANK_ACCOUNT_REVISION_WAITING_LIST_QS_KEY,
    FI_PARTNER_BANK_ACCOUNT_REVISION_CONFIRMED_LIST_QS_KEY,
  };
};

function FinancierPotentialSupplyChainPartnerBankAccountRevisionList() {
  const mounted = useMounted();
  const { t } = useTranslation(['format']);
  const modal = useModal();

  const [waitingAnchorPartnerAccountPageData, setWaitingAnchorPartnerAccountPageData] =
    useState<Pageable<WaitingAnchorPartnerAccountVOModel[]>>();
  const [notCancelledWaitingAnchorPartnerAccountPageData, setNotCancelledWaitingAnchorPartnerAccountPageData] =
    useState<Pageable<WaitingAnchorPartnerAccountVOModel[]>>();
  const [anchorPartnerAccountPageData, setAnchorPartnerAccountPageData] =
    useState<Pageable<AnchorPartnerAccountVOModel[]>>();

  const {
    register: waitingForConfirmationSearchFormRegister,
    reset: waitingForConfirmationSearchFormReset,
    getValues: getWaitingForConfirmationSearchFormValues,
    setValue: setWaitingForConfirmationSearchFormValue,
  } = useForm<FinancierWaitingAnchorPartnerAccountListRequest>();

  const {
    register: revisionConfirmedSearchFormRegister,
    reset: revisionConfirmedSearchFormReset,
    getValues: getRevisionConfirmedSearchFormValues,
    setValue: setRevisionConfirmedSearchFormValue,
  } = useForm<AnchorPartnerAccountListRequest>();

  const {
    GUIDE_MESSAGE,
    WAITING_FOR_CONFIRMATION_TABLE_HEADERS,
    REVISION_CONFIRMED_TABLE_HEADERS,
    FI_PARTNER_BANK_ACCOUNT_REVISION_WAITING_LIST_QS_KEY,
    FI_PARTNER_BANK_ACCOUNT_REVISION_CONFIRMED_LIST_QS_KEY, // onClickRegistrationStatus,
  } = getConstant();

  const { pageable: waitingConfirmationPageable, setPageable: setWaitingConfirmationPageable } = usePageable(
    FI_PARTNER_BANK_ACCOUNT_REVISION_WAITING_LIST_QS_KEY,
  );
  const { pageable: revisionConfirmedPageable, setPageable: setRevisionConfirmedPageable } = usePageable(
    FI_PARTNER_BANK_ACCOUNT_REVISION_CONFIRMED_LIST_QS_KEY,
  );

  useEffect(() => {
    if (mounted) fetchAll();
  }, [mounted]);

  const fetchAll = async (): Promise<void> => {
    setFormValues<FinancierWaitingAnchorPartnerAccountListRequest>(
      setWaitingForConfirmationSearchFormValue,
      getParsedSearchParams(FI_PARTNER_BANK_ACCOUNT_REVISION_WAITING_LIST_QS_KEY).formSearchData,
    );

    setFormValues<AnchorPartnerAccountListRequest>(
      setRevisionConfirmedSearchFormValue,
      getParsedSearchParams(FI_PARTNER_BANK_ACCOUNT_REVISION_CONFIRMED_LIST_QS_KEY).formSearchData,
    );

    const addFiPotentialSCPartnerBankAccountRevisionListQs = () => {
      const waitingQs = makeSearchParamsPattern(
        {
          ...getWaitingForConfirmationSearchFormValues(),
          pageNumber: waitingConfirmationPageable.currentPage,
          rowCount: waitingConfirmationPageable.sizePerPage,
        },
        FI_PARTNER_BANK_ACCOUNT_REVISION_WAITING_LIST_QS_KEY,
      );
      const completedQs = makeSearchParamsPattern(
        {
          ...getRevisionConfirmedSearchFormValues(),
          pageNumber: revisionConfirmedPageable.currentPage,
          rowCount: revisionConfirmedPageable.sizePerPage,
        },
        FI_PARTNER_BANK_ACCOUNT_REVISION_CONFIRMED_LIST_QS_KEY,
      );
      addSearchParams(waitingQs + completedQs);
    };
    addFiPotentialSCPartnerBankAccountRevisionListQs();

    const { waitingForApprovalSearchData, notCancelledWaitingForApprovalSearchData } = setWaitingTabSearchValue();

    try {
      const [
        waitingAnchorPartnerAccountPageData,
        anchorPartnerAccountPageData,
        notCancelledWaitingAnchorPartnerAccountPageData,
      ] = await Promise.all([
        requestFinancierWaitingAnchorPartnerAccountList(
          waitingConfirmationPageable.currentPage,
          waitingConfirmationPageable.sizePerPage,
          waitingForApprovalSearchData,
        ),
        requestFiAnchorPartnerAccountList(
          revisionConfirmedPageable.currentPage,
          revisionConfirmedPageable.sizePerPage,
          getRevisionConfirmedSearchFormValues(),
        ),
        requestFinancierWaitingAnchorPartnerAccountList(1, 1, notCancelledWaitingForApprovalSearchData),
      ]);

      ReactDOM.unstable_batchedUpdates(() => {
        setWaitingAnchorPartnerAccountPageData(waitingAnchorPartnerAccountPageData);
        setWaitingConfirmationPageable(waitingAnchorPartnerAccountPageData);
        setAnchorPartnerAccountPageData(anchorPartnerAccountPageData);
        setRevisionConfirmedPageable(anchorPartnerAccountPageData);
        setNotCancelledWaitingAnchorPartnerAccountPageData(notCancelledWaitingAnchorPartnerAccountPageData);
      });
    } catch (error) {
      modal.show(error);
    }
  };

  const setWaitingTabSearchValue = (formSearchData?: FinancierWaitingAnchorPartnerAccountListRequest) => {
    const getSearchFormData = formSearchData ? formSearchData : getWaitingForConfirmationSearchFormValues();

    const { waitingForApprovalTypeData, notCancelledWaitingForApprovalTypeData } = setApprovalTypesValue(
      getSearchFormData.approvalTypes,
    );

    const waitingForApprovalSearchData = {
      ...getSearchFormData,
      approvalTypes: waitingForApprovalTypeData,
    };

    const notCancelledWaitingForApprovalSearchData = {
      ...getSearchFormData,
      approvalTypes: notCancelledWaitingForApprovalTypeData,
    };

    return {
      waitingForApprovalSearchData,
      notCancelledWaitingForApprovalSearchData,
    };
  };

  const renderWaitingForConfirmation = (): JSX.Element => {
    const onClickRemoveFilter = (e: any): void => {
      e.preventDefault();

      waitingForConfirmationSearchFormReset();
    };

    const onClickSearchWaitingTab = async (e: any): Promise<void> => {
      e.preventDefault();
      const { waitingForApprovalSearchData, notCancelledWaitingForApprovalSearchData } = setWaitingTabSearchValue();
      try {
        const [waitingAnchorPartnerAccountPageData, notCancelledWaitingAnchorPartnerAccountPageData] =
          await Promise.all([
            requestFinancierWaitingAnchorPartnerAccountList(
              0,
              waitingConfirmationPageable.sizePerPage,
              waitingForApprovalSearchData,
            ),
            requestFinancierWaitingAnchorPartnerAccountList(0, 1, notCancelledWaitingForApprovalSearchData),
          ]);

        updateSearchParams(
          {
            ...waitingForApprovalSearchData,
            pageNumber: 1,
            rowCount: waitingConfirmationPageable.sizePerPage,
          },
          FI_PARTNER_BANK_ACCOUNT_REVISION_WAITING_LIST_QS_KEY,
        );

        ReactDOM.unstable_batchedUpdates(() => {
          setWaitingAnchorPartnerAccountPageData(waitingAnchorPartnerAccountPageData);
          setWaitingConfirmationPageable(waitingAnchorPartnerAccountPageData);
          setNotCancelledWaitingAnchorPartnerAccountPageData(notCancelledWaitingAnchorPartnerAccountPageData);
        });
      } catch (error) {
        modal.show(error);
      }
    };

    const paginateWaitingTab = async (page: number, sizePerPage: number): Promise<void> => {
      const { waitingForApprovalSearchData } = setWaitingTabSearchValue(
        getParsedSearchParams(FI_PARTNER_BANK_ACCOUNT_REVISION_WAITING_LIST_QS_KEY).formSearchData,
      );
      try {
        const waitingAnchorPartnerAccountPageData = await requestFinancierWaitingAnchorPartnerAccountList(
          page,
          sizePerPage,
          waitingForApprovalSearchData,
        );
        updateSearchParams(
          {
            ...waitingForApprovalSearchData,
            pageNumber: page,
            rowCount: sizePerPage,
          },
          FI_PARTNER_BANK_ACCOUNT_REVISION_WAITING_LIST_QS_KEY,
        );

        ReactDOM.unstable_batchedUpdates(() => {
          setWaitingAnchorPartnerAccountPageData(waitingAnchorPartnerAccountPageData);
          setWaitingConfirmationPageable(waitingAnchorPartnerAccountPageData);
        });
      } catch (error) {
        modal.show(error);
      }
    };

    const renderResultTable = (): JSX.Element[] | undefined => {
      const getEnterpriseTypeText = (data?: ENTERPRISE_TYPE): string => {
        switch (data) {
          case ENTERPRISE_TYPE.AC:
            return t('text:Anchor');
          case ENTERPRISE_TYPE.DE:
            return t('text:Dealer');
          case ENTERPRISE_TYPE.FI:
            return t('text:Financier');
          case ENTERPRISE_TYPE.SY:
            return t('text:System');
          default:
            return '';
        }
      };

      const getRequestStatusText = (data?: COMMON_APPROVAL_TYPE): string => {
        switch (data) {
          case COMMON_APPROVAL_TYPE.OPERATOR_REQUEST:
            return t('text:Requested');
          case COMMON_APPROVAL_TYPE.OPERATOR_CANCEL:
            return t('text:Cancelled');
          case COMMON_APPROVAL_TYPE.AUTHORIZER_RETURNED:
            return t('text:Reverted');
          default:
            return '';
        }
      };

      return waitingAnchorPartnerAccountPageData?.content.map((item, index) => (
        <Tr key={index}>
          <Td data={item.partnerName} />
          <Td data={item.partnerTaxCode} />
          <Td data={item.anchorName} />
          <Td data={item.anchorAgreementContractNo} />
          <Td data={item.currencyType} />
          <Td data={item.accountBankName} />
          <Td data={item.account} />
          <Td data={item.accountBankCodeId ? t('text:Yes') : t('text:No')} />
          <Td data={getEnterpriseTypeText(item.operatorEnterpriseType)} />
          <Td
            data={getRequestStatusText(item.approvalType)}
            className={getStatusTextClass('COMMON_APPROVAL_TYPE', item.approvalType)}
          />
          <TdLink
            path={ROUTES_FI.MANAGE_UPLOADED_PARTNER.BANK_ACCOUNT_REVISION_WAITING_DETAIL_BUILD_PATH(
              item.waitingAnchorPartnerAccountId,
            )}
          />
        </Tr>
      ));
    };

    return (
      <>
        <div className="content-area">
          <form>
            <SectionTitle title={t('text:Search')}>
              <Button
                variant={ButtonVariantEnum.OUTLINED}
                color={ButtonColorEnum.SECONDARY}
                onClick={onClickRemoveFilter}
              >
                {t('text:Remove_Filter')}
              </Button>
            </SectionTitle>
            <SearchBorder>
              <div className="row">
                <SearchLabel label={t('text:Uploaded_Partner_Name')} />
                <SearchInput name="partnerName" ref={waitingForConfirmationSearchFormRegister} />
                <SearchLabel label={t('text:Uploaded_Partner_Tax_Code')} />
                <SearchInput name="partnerTaxCode" ref={waitingForConfirmationSearchFormRegister} />
              </div>
              <div className="row">
                <SearchLabel label={t('text:Associated_Anchor_Name')} />
                <SearchInput name="anchorName" ref={waitingForConfirmationSearchFormRegister} />
                <SearchLabel label={t('text:Associated_Master_Agreement_of_Anchor_Number')} />
                <SearchInput name="anchorAgreementNo" ref={waitingForConfirmationSearchFormRegister} />
              </div>
              <div className="row">
                <SearchLabel label={t('text:Currency')} />
                <SearchSelect
                  name="currencyType"
                  ref={waitingForConfirmationSearchFormRegister}
                  selectOptions={getSelectOptions<CURRENCY_TYPE>('CURRENCY_TYPE', 'ALL', true)}
                />
                <SearchLabel label={t('text:Bank_Name')} />
                <SearchInput name="anchorPartnerAccountBankName" ref={waitingForConfirmationSearchFormRegister} />
              </div>
              <div className="row">
                <SearchLabel label={t('text:Bank_Account_Number')} />
                <SearchInput name="anchorPartnerAccount" ref={waitingForConfirmationSearchFormRegister} />
                <SearchLabel label={t('text:Bank_Account_Validity')} />
                <SearchSelect
                  name="hasBankCode"
                  ref={waitingForConfirmationSearchFormRegister}
                  selectOptions={getSelectOptions<string>('BANK_ACCOUNT_VALIDITY_OPTIONS', 'ALL', true)}
                />
              </div>
              <div className="row">
                <SearchLabel label={t('text:Revised_By')} />
                <SearchSelect
                  name="enterpriseType"
                  ref={waitingForConfirmationSearchFormRegister}
                  selectOptions={getSelectOptions<ENTERPRISE_TYPE>(
                    'ENTERPRISE_TYPE',
                    [ENTERPRISE_TYPE.AC, ENTERPRISE_TYPE.FI],
                    true,
                  )}
                />
                <SearchLabel label={t('text:Approval_Status')} />
                <SearchSelect
                  name="approvalTypes"
                  ref={waitingForConfirmationSearchFormRegister}
                  selectOptions={[
                    {
                      label: t('text:All'),
                      value: WAITING_COMMON_APPROVAL_TYPE_ALL_VALUES,
                    },
                    {
                      label: t('text:Requested'),
                      value: COMMON_APPROVAL_TYPE.OPERATOR_REQUEST,
                    },
                    {
                      label: t('text:Reverted'),
                      value: COMMON_APPROVAL_TYPE.AUTHORIZER_RETURNED,
                    },
                    {
                      label: t('text:Cancelled'),
                      value: COMMON_APPROVAL_TYPE.OPERATOR_CANCEL,
                    },
                  ]}
                />
              </div>
            </SearchBorder>
            <div className="flex-center mt-4">
              <Button size={ButtonSizeEnum.LG} onClick={onClickSearchWaitingTab}>
                {t('text:Search')}
              </Button>
            </div>
          </form>
        </div>
        <div className="division-border" />
        <div className="content-area">
          <SectionTitle title={t('text:Result')} />
          <p className="total-data">
            {t('text:Total')} {waitingAnchorPartnerAccountPageData?.totalElements}
          </p>
          <TableBorder>
            <TableHeader header={WAITING_FOR_CONFIRMATION_TABLE_HEADERS} />
            <TableBody numOfCol={WAITING_FOR_CONFIRMATION_TABLE_HEADERS.length}>{renderResultTable()}</TableBody>
          </TableBorder>
          <Pagination pageable={waitingConfirmationPageable} paginate={paginateWaitingTab} />
        </div>
      </>
    );
  };

  const renderRevisionConfirmed = (): JSX.Element => {
    const requestRevisionConfirmedData = async (
      selectedPageNumber: number = 1,
      selectedRowCount: number = 10,
      searchedValue: AnchorPartnerAccountListRequest,
    ): Promise<void> => {
      try {
        const anchorPartnerAccountPageData = await requestFiAnchorPartnerAccountList(
          selectedPageNumber,
          selectedRowCount,
          searchedValue,
        );
        updateSearchParams(
          {
            ...searchedValue,
            pageNumber: selectedPageNumber,
            rowCount: selectedRowCount,
          },
          FI_PARTNER_BANK_ACCOUNT_REVISION_CONFIRMED_LIST_QS_KEY,
        );

        ReactDOM.unstable_batchedUpdates(() => {
          setAnchorPartnerAccountPageData(anchorPartnerAccountPageData);
          setRevisionConfirmedPageable(anchorPartnerAccountPageData);
        });
      } catch (error) {
        modal.show(error);
      }
    };

    const onClickRemoveFilter = (e: any): void => {
      e.preventDefault();

      revisionConfirmedSearchFormReset();
    };

    const onClickSearch = async (e: any): Promise<void> => {
      e.preventDefault();

      requestRevisionConfirmedData(1, revisionConfirmedPageable.sizePerPage, getRevisionConfirmedSearchFormValues());
    };

    const paginate = async (page: number, sizePerPage: number) => {
      requestRevisionConfirmedData(
        page,
        sizePerPage,
        getParsedSearchParams(FI_PARTNER_BANK_ACCOUNT_REVISION_CONFIRMED_LIST_QS_KEY).formSearchData,
      );
    };

    const renderResultTable = (): JSX.Element[] | undefined => {
      return anchorPartnerAccountPageData?.content.map((item, index) => (
        <Tr key={index}>
          <Td data={item.partnerName} />
          <Td data={item.partnerTaxCode} />
          <Td data={item.anchorName} />
          <Td data={item.anchorAgreementContractNo} />
          <Td data={item.currencyType} />
          <Td data={item.accountBankName} />
          <Td data={item.account} />
          <Td data={item.accountBankCodeId ? t('text:Yes') : t('text:No')} />
          <TdLink
            path={ROUTES_FI.MANAGE_UPLOADED_PARTNER.BANK_ACCOUNT_REVISION_CONFIRMED_DETAIL_BUILD_PATH(
              item.anchorPartnerAccountId,
            )}
          />
        </Tr>
      ));
    };

    return (
      <>
        <div className="content-area">
          <form>
            <SectionTitle title={t('text:Search')}>
              <Button
                variant={ButtonVariantEnum.OUTLINED}
                color={ButtonColorEnum.SECONDARY}
                onClick={onClickRemoveFilter}
              >
                {t('text:Remove_Filter')}
              </Button>
            </SectionTitle>
            <SearchBorder>
              <div className="row">
                <SearchLabel label={t('text:Uploaded_Partner_Name')} />
                <SearchInput name="anchorPartnerName" ref={revisionConfirmedSearchFormRegister} />
                <SearchLabel label={t('text:Uploaded_Partner_Tax_Code')} />
                <SearchInput name="anchorPartnerTaxCode" ref={revisionConfirmedSearchFormRegister} />
              </div>
              <div className="row">
                <SearchLabel label={t('text:Associated_Anchor_Name')} />
                <SearchInput name="anchorName" ref={revisionConfirmedSearchFormRegister} />
                <SearchLabel label={t('text:Associated_Master_Agreement_of_Anchor_Number')} />
                <SearchInput name="anchorAgreementNo" ref={revisionConfirmedSearchFormRegister} />
              </div>
              <div className="row">
                <SearchLabel label={t('text:Currency')} />
                <SearchSelect
                  name="currencyType"
                  ref={revisionConfirmedSearchFormRegister}
                  selectOptions={getSelectOptions<CURRENCY_TYPE>('CURRENCY_TYPE', 'ALL', true)}
                />
                <SearchLabel label={t('text:Bank_Name')} />
                <SearchInput name="anchorPartnerAccountBankName" ref={revisionConfirmedSearchFormRegister} />
              </div>
              <div className="row">
                <SearchLabel label={t('text:Bank_Account_Number')} />
                <SearchInput name="anchorPartnerAccount" ref={revisionConfirmedSearchFormRegister} />
                <SearchLabel label={t('text:Bank_Account_Validity')} />
                <SearchSelect
                  name="hasBankCode"
                  ref={revisionConfirmedSearchFormRegister}
                  selectOptions={getSelectOptions<string>('BANK_ACCOUNT_VALIDITY_OPTIONS', 'ALL', true)}
                />
              </div>
            </SearchBorder>
            <div className="flex-center mt-4">
              <Button size={ButtonSizeEnum.LG} onClick={onClickSearch}>
                {t('text:Search')}
              </Button>
            </div>
          </form>
        </div>
        <div className="division-border" />
        <div className="content-area">
          <SectionTitle title={t('text:Result')} />
          <p className="total-data">
            {t('text:Total')} {anchorPartnerAccountPageData?.totalElements}
          </p>
          <TableBorder>
            <TableHeader header={REVISION_CONFIRMED_TABLE_HEADERS} />
            <TableBody numOfCol={REVISION_CONFIRMED_TABLE_HEADERS.length}>{renderResultTable()}</TableBody>
          </TableBorder>
          <Pagination pageable={revisionConfirmedPageable} paginate={paginate} />
        </div>
      </>
    );
  };

  return (
    <>
      <HeaderTitle title={t('text:Partner_Bank_Account')} />
      <GuideMessage message={GUIDE_MESSAGE} />
      <Tab
        initialActiveTabIndex={1}
        tabType="approvalStatus"
        tabList={[
          {
            tabItemCount: getWaitingForApprovalCount(
              getWaitingForConfirmationSearchFormValues('approvalTypes'),
              notCancelledWaitingAnchorPartnerAccountPageData?.totalElements,
            ),
          },
          {
            tabItemCount: anchorPartnerAccountPageData?.totalElements ?? 0,
          },
        ]}
        tabViewList={[renderWaitingForConfirmation(), renderRevisionConfirmed()]}
      />
    </>
  );
}

export default FinancierPotentialSupplyChainPartnerBankAccountRevisionList;
