import {
  BANK_CODE_APPROVAL_TYPE,
  COMMON_APPROVAL_TYPE,
  EARLY_REPAYMENT_REQUEST_STATUS,
  EXECUTION_STATUS,
  EXTENSION_REQUEST_STATUS,
  FACTORING_NOTICE_CONFIRMATION_STATUS,
  LOAN_APPROVAL_TYPE,
  LOAN_STATUS,
  MULTIPLE_LOAN_REQUEST_STATUS,
  POTENTIAL_PARTNER_FINANCING_APPLICATION_STATUS,
  WAITING_AR_APPROVAL_PHASE_STATUS,
  WAITING_INVOICE_APPROVAL_PHASE_STATUS,
} from 'enums';

import './statusBadge.scss';

import type { ClassNameContainerOuter } from './types';

const WAITING_INVOICE_APPROVAL_PHASE_STATUS_EXTEND = {
  ...WAITING_INVOICE_APPROVAL_PHASE_STATUS,
};

export type StatusBadgeClassNameContainerStructure = {
  LOAN_STATUS: typeof LOAN_STATUS;
  LOAN_APPROVAL_TYPE: typeof LOAN_APPROVAL_TYPE;
  EXTENSION_REQUEST_STATUS: typeof EXTENSION_REQUEST_STATUS;
  EARLY_REPAYMENT_REQUEST_STATUS: typeof EARLY_REPAYMENT_REQUEST_STATUS;
  BANK_CODE_APPROVAL_TYPE: typeof BANK_CODE_APPROVAL_TYPE;
  COMMON_APPROVAL_TYPE: typeof COMMON_APPROVAL_TYPE;
  POTENTIAL_PARTNER_FINANCING_APPLICATION_STATUS: typeof POTENTIAL_PARTNER_FINANCING_APPLICATION_STATUS;
  WAITING_INVOICE_APPROVAL_PHASE_STATUS: typeof WAITING_INVOICE_APPROVAL_PHASE_STATUS_EXTEND;
  WAITING_AR_APPROVAL_PHASE_STATUS: typeof WAITING_AR_APPROVAL_PHASE_STATUS;
  EXECUTION_STATUS: typeof EXECUTION_STATUS;
  MULTIPLE_LOAN_REQUEST_STATUS: typeof MULTIPLE_LOAN_REQUEST_STATUS;
  MULTIPLE_LOAN_REQUEST_APPROVAL_TYPE: Partial<typeof COMMON_APPROVAL_TYPE>;
  FACTORING_NOTICE_CONFIRMATION_STATUS: typeof FACTORING_NOTICE_CONFIRMATION_STATUS;
};

export const CLASS_NAME_CONTAINER: ClassNameContainerOuter<StatusBadgeClassNameContainerStructure> = {
  LOAN_STATUS: {
    [LOAN_STATUS.DEALER_CREATED]: 'bg-orange-main700',
    [LOAN_STATUS.DEALER_REQUESTED]: 'bg-blue-main600',
    [LOAN_STATUS.DEALER_CANCELLED]: 'bg-light-pink text-brick-red',
    [LOAN_STATUS.REJECTED]: 'bg-brick-red',
    [LOAN_STATUS.APPROVED]: 'bg-light-green text-green',
    [LOAN_STATUS.DISBURSED]: 'bg-green',
    [LOAN_STATUS.REPAID]: 'bg-dark-green',
    [LOAN_STATUS.RECOURSE_ACTIVATED]: 'bg-maroon',
    [LOAN_STATUS.OVERDUE]: 'bg-brick-red',
    [LOAN_STATUS.EXPIRATION]: 'bg-purple',
  },
  EXTENSION_REQUEST_STATUS: {
    [EXTENSION_REQUEST_STATUS.REQUESTED]: 'bg-orange-main700',
    [EXTENSION_REQUEST_STATUS.APPROVED]: 'bg-dark-green',
    [EXTENSION_REQUEST_STATUS.PREAPPROVED]: 'bg-green',
    [EXTENSION_REQUEST_STATUS.REJECTED]: 'bg-brick-red',
    [EXTENSION_REQUEST_STATUS.RETURNED]: 'bg-brick-red',
    [EXTENSION_REQUEST_STATUS.EXTENDED]: 'bg-purple',
  },
  LOAN_APPROVAL_TYPE: {
    [LOAN_APPROVAL_TYPE.OPERATOR_APPROVAL]: 'bg-light-green text-green',
    [LOAN_APPROVAL_TYPE.AUTHORIZER_RETURNED_APPROVAL]: 'bg-light-pink text-brick-red',
    [LOAN_APPROVAL_TYPE.OPERATOR_OVERDUE]: 'bg-papaya-whip text-coral',
    [LOAN_APPROVAL_TYPE.AUTHORIZER_RETURNED_OVERDUE]: 'bg-light-pink text-brick-red',
    [LOAN_APPROVAL_TYPE.OPERATOR_REPAID]: 'bg-columbia-blue text-dark-green',
    [LOAN_APPROVAL_TYPE.OPERATOR_REQUEST_REJECTED]: 'bg-light-pink text-brick-red',
    [LOAN_APPROVAL_TYPE.AUTHORIZER_RETURNED_REJECTED]: 'bg-light-pink text-brick-red',
    [LOAN_APPROVAL_TYPE.AUTHORIZER_RETURNED_REPAID]: 'bg-light-pink text-brick-red',
    [LOAN_APPROVAL_TYPE.OPERATOR_CLEAR_OVERDUE]: 'bg-blue-main300 text-blue-main600',
    [LOAN_APPROVAL_TYPE.AUTHORIZER_RETURNED_CLEAR_OVERDUE]: 'bg-light-pink text-brick-red',
    [LOAN_APPROVAL_TYPE.OPERATOR_EXPIRATION]: 'bg-lavender text-purple',
    [LOAN_APPROVAL_TYPE.AUTHORIZER_RETURNED_EXPIRATION]: 'bg-light-pink text-brick-red',
    [LOAN_APPROVAL_TYPE.OPERATOR_REQUEST_CANCELLATION_DISBURSEMENT]: 'bg-light-pink text-brick-red',
    [LOAN_APPROVAL_TYPE.AUTHORIZER_RETURNED_CANCELLATION_DISBURSEMENT]: 'bg-light-pink text-brick-red',
  },
  EARLY_REPAYMENT_REQUEST_STATUS: {
    [EARLY_REPAYMENT_REQUEST_STATUS.REQUESTED]: 'bg-orange-main700',
    [EARLY_REPAYMENT_REQUEST_STATUS.COMPLETED]: 'bg-green',
    [EARLY_REPAYMENT_REQUEST_STATUS.EXPIRED]: 'bg-purple',
  },
  BANK_CODE_APPROVAL_TYPE: {
    [BANK_CODE_APPROVAL_TYPE.FINANCIER_REQUEST]: 'bg-orange-main700',
    [BANK_CODE_APPROVAL_TYPE.FINANCIER_CANCEL]: 'bg-brick-red',
    [BANK_CODE_APPROVAL_TYPE.SYSTEM_REJECTED]: 'bg-brick-red',
    [BANK_CODE_APPROVAL_TYPE.SYSTEM_APPROVED]: 'bg-green',
  },
  COMMON_APPROVAL_TYPE: {
    [COMMON_APPROVAL_TYPE.OPERATOR_REQUEST]: 'bg-orange-main700',
    [COMMON_APPROVAL_TYPE.OPERATOR_CANCEL]: 'bg-brick-red',
    [COMMON_APPROVAL_TYPE.AUTHORIZER_RETURNED]: 'bg-brick-red',
    [COMMON_APPROVAL_TYPE.AUTHORIZER_APPROVED]: 'bg-green',
  },
  POTENTIAL_PARTNER_FINANCING_APPLICATION_STATUS: {
    [POTENTIAL_PARTNER_FINANCING_APPLICATION_STATUS.SUBMITTED_CANCEL]: 'bg-brick-red',
    [POTENTIAL_PARTNER_FINANCING_APPLICATION_STATUS.SUBMITTED]: 'bg-orange-main700',
    [POTENTIAL_PARTNER_FINANCING_APPLICATION_STATUS.IN_PROGRESS]: 'bg-green',
    [POTENTIAL_PARTNER_FINANCING_APPLICATION_STATUS.REJECTED]: 'bg-brick-red',
    [POTENTIAL_PARTNER_FINANCING_APPLICATION_STATUS.COMPLETED]: 'bg-blue-main600',
  },
  WAITING_INVOICE_APPROVAL_PHASE_STATUS: {
    [WAITING_INVOICE_APPROVAL_PHASE_STATUS.INITIATED]: 'bg-light-gray-blue',
    [WAITING_INVOICE_APPROVAL_PHASE_STATUS.REQUESTED]: 'bg-orange-main700',
    [WAITING_INVOICE_APPROVAL_PHASE_STATUS.CONFIRMED]: 'bg-green',
    [WAITING_INVOICE_APPROVAL_PHASE_STATUS.REJECTED]: 'bg-brick-red',
    [WAITING_INVOICE_APPROVAL_PHASE_STATUS.REGISTERED]: 'bg-blue-main600',
    [WAITING_INVOICE_APPROVAL_PHASE_STATUS.IN_PROGRESS]: 'bg-purple',
  },
  WAITING_AR_APPROVAL_PHASE_STATUS: {
    [WAITING_AR_APPROVAL_PHASE_STATUS.INITIATED]: 'bg-light-gray-blue',
    [WAITING_AR_APPROVAL_PHASE_STATUS.REQUESTED]: 'bg-orange-main700',
    [WAITING_AR_APPROVAL_PHASE_STATUS.CONFIRMED]: 'bg-green',
    [WAITING_AR_APPROVAL_PHASE_STATUS.REGISTERED]: 'bg-blue-main600',
    [WAITING_AR_APPROVAL_PHASE_STATUS.IN_PROGRESS]: 'bg-purple',
  },
  EXECUTION_STATUS: {
    [EXECUTION_STATUS.NONE]: 'bg-light-gray-blue',
    [EXECUTION_STATUS.REGISTERED]: 'bg-orange-main700',
    [EXECUTION_STATUS.RUNNING]: 'bg-blue-main600',
    [EXECUTION_STATUS.FINISHED]: 'bg-brick-red',
    [EXECUTION_STATUS.FAILED]: 'bg-brick-red',
  },
  MULTIPLE_LOAN_REQUEST_STATUS: {
    [MULTIPLE_LOAN_REQUEST_STATUS.CREATED]: 'bg-orange-main700',
    [MULTIPLE_LOAN_REQUEST_STATUS.APPLIED]: 'bg-blue-main600',
    [MULTIPLE_LOAN_REQUEST_STATUS.CANCELLED]: 'bg-light-pink text-brick-red',
    [MULTIPLE_LOAN_REQUEST_STATUS.REJECTED]: 'bg-brick-red',
    [MULTIPLE_LOAN_REQUEST_STATUS.APPROVED]: 'bg-light-green text-green',
  },
  MULTIPLE_LOAN_REQUEST_APPROVAL_TYPE: {
    [COMMON_APPROVAL_TYPE.OPERATOR_REQUEST]: 'bg-light-green text-green',
    [COMMON_APPROVAL_TYPE.AUTHORIZER_RETURNED]: 'bg-light-pink text-brick-red',
  },
  FACTORING_NOTICE_CONFIRMATION_STATUS: {
    [FACTORING_NOTICE_CONFIRMATION_STATUS.CONFIRMED]: 'bg-green',
    [FACTORING_NOTICE_CONFIRMATION_STATUS.WAITING]: 'bg-orange-main700',
  },
};

export default function getStatusBadgeClass<T extends keyof StatusBadgeClassNameContainerStructure>(
  enumObjectName: T,
  enumData: keyof ClassNameContainerOuter<StatusBadgeClassNameContainerStructure>[T] | undefined,
): string {
  return enumData ? `status-badge ${CLASS_NAME_CONTAINER[enumObjectName][enumData]}` : '';
}
