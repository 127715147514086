import type React from 'react';
import { useCallback, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { intersectionBy } from 'lodash-es';

import Button, { ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import Exporter from 'components/stateless/Exporter/Exporter';
import GuideMessage from 'components/stateless/GuideMessage/GuideMessage';
import ReasonModal from 'components/stateless/Modal/common/ReasonModal';
import FinancierUploadedPartnerRegistrationConfirmModal from 'components/stateless/Modal/common/partner/FinancierUploadedPartnerRegistrationConfirmModal';
import FinancierUploadedPartnerRegistrationResultModal from 'components/stateless/Modal/common/partner/FinancierUploadedPartnerRegistrationResultModal';
import Pagination from 'components/stateless/Pagination/Pagination';
import SearchBorder from 'components/stateless/SearchForm/SearchBorder';
import SearchDatePicker from 'components/stateless/SearchForm/SearchDatePicker';
import SearchEmpty from 'components/stateless/SearchForm/SearchEmpty';
import SearchInput from 'components/stateless/SearchForm/SearchInput';
import SearchLabel from 'components/stateless/SearchForm/SearchLabel';
import SearchSelect from 'components/stateless/SearchForm/SearchSelect';
import Tab from 'components/stateless/TabManager/Tab';
import TableBody from 'components/stateless/Table/TableBody';
import TableBorder from 'components/stateless/Table/TableBorder';
import type { HeaderType } from 'components/stateless/Table/TableHeader';
import TableHeader from 'components/stateless/Table/TableHeader';
import Td from 'components/stateless/Table/Td';
import TdLink from 'components/stateless/Table/TdLink';
import Tr from 'components/stateless/Table/Tr';
import { HeaderTitle } from 'components/stateless/Title/HeaderTitle';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { ROUTES_FI } from 'constants/routes/financier';
import getSelectOptions from 'constants/selectOptions';
import type { CURRENCY_TYPE } from 'enums';
import { AUTHORITY_TYPE, COMMON_APPROVAL_TYPE, ENTERPRISE_TYPE, PARTNER_STATUS } from 'enums';
import useMounted from 'hooks/useMounted';
import usePageable from 'hooks/usePageable';
import useProperty from 'hooks/useProperty';
import type Pageable from 'models/Pageable';
import type { AnchorPartnerVOModel } from 'models/vo/AnchorPartnerVO';
import type { WaitingAnchorPartnerVOModel } from 'models/vo/WaitingAnchorPartnerVO';
import getStatusTextClass from 'utils/classNames/getStatusTextClass';
import type { PDFExporterProps } from 'utils/exportFile/exportPDF.d';
import { setFormValues } from 'utils/form/setFormValues';
import type { AnchorPartnersPageRequest } from 'utils/http/api/anchor/anchor-partners/requests';
import type { WaitingAnchorPartnerPageRequest } from 'utils/http/api/anchor/waiting-anchor-partners/requests';
import { requestFiAnchorPartnerList } from 'utils/http/api/financier/anchor-partners';
import type { AnchorPartnerListRequest } from 'utils/http/api/financier/anchor-partners/requests';
import {
  requestFiWaitingAnchorPartnerApprove,
  requestFiWaitingAnchorPartnerCancel,
  requestFiWaitingAnchorPartnerList,
  requestFiWaitingAnchorPartnerReturn,
} from 'utils/http/api/financier/waiting-anchor-partners';
import type {
  WaitingAnchorPartnerListRequest,
  WaitingAnchorPartnerRequest,
} from 'utils/http/api/financier/waiting-anchor-partners/request';
import { getWaitingForApprovalCount } from 'utils/logic';
import { WAITING_COMMON_APPROVAL_TYPE_ALL_VALUES, setApprovalTypesValue } from 'utils/logic/setApprovalTypesValue';
import { ModalSize, ModalType } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';
import {
  addSearchParams,
  getParsedSearchParams,
  makeSearchParamsPattern,
  updateSearchParams,
} from 'utils/searchParams';
import type { ColumnOption, ExportSpreadSheetProps } from 'utils/spreadSheet/types';
import { getSignIn } from 'utils/storage/LocalStorage';
import { tableValueManage } from 'utils/valueManager/ValueManager';

interface CheckBoxWaitingAnchorPartnerVOModel extends WaitingAnchorPartnerVOModel {
  disabled?: boolean;
}

const getConstant = () => {
  const { t } = useTranslation(['format']);

  const REGISTRATION_COMPLETED_TABLE_HEADERS: HeaderType[] = [
    {
      headerText: t('text:Registration_Date'),
    },
    {
      headerText: t('text:Anchor_Name'),
    },
    {
      headerText: t('text:Uploaded_Partner_Name'),
    },
    {
      headerText: t('text:Uploaded_Partner_Tax_Code'),
    },
    {
      headerText: t('text:Registered_Office_Address'),
    },
    {
      headerText: t('text:Legal_Representative_Name'),
    },
    {
      headerText: t('text:Legal_Representative_Email'),
    },
    {
      headerText: t('text:Telephone'),
    },
    {
      headerText: '',
      colWidths: 50,
    },
  ];

  const FI_PARTNER_REGISTRATION_WAITING_LIST_QS_KEY = 'fi-partner-registration-waiting-list';
  const FI_PARTNER_REGISTRATION_CONFIRMED_LIST_QS_KEY = 'fi-partner-registration-confirmed-list';

  return {
    REGISTRATION_COMPLETED_TABLE_HEADERS,
    FI_PARTNER_REGISTRATION_WAITING_LIST_QS_KEY,
    FI_PARTNER_REGISTRATION_CONFIRMED_LIST_QS_KEY,
  };
};

function FinancierPotentialSupplyChainPartnerRegistrationList() {
  const { t } = useTranslation();
  const mounted = useMounted();
  const modal = useModal();
  const {
    REGISTRATION_COMPLETED_TABLE_HEADERS,
    FI_PARTNER_REGISTRATION_WAITING_LIST_QS_KEY,
    FI_PARTNER_REGISTRATION_CONFIRMED_LIST_QS_KEY,
  } = getConstant();

  const { pageable: waitingConfirmationPageable, setPageable: setWaitingConfirmationPageable } = usePageable(
    FI_PARTNER_REGISTRATION_WAITING_LIST_QS_KEY,
  );
  const { pageable: registrationCompletedPageable, setPageable: setRegistrationCompletedPageable } = usePageable(
    FI_PARTNER_REGISTRATION_CONFIRMED_LIST_QS_KEY,
  );

  const [waitingPartnerList, setWaitingPartnerList] = useState<Pageable<WaitingAnchorPartnerVOModel[]>>();
  const [notCancelledWaitingPartnerList, setNotCancelledWaitingPartnerList] =
    useState<Pageable<WaitingAnchorPartnerVOModel[]>>();
  const [checkedItems, setCheckedItems] = useState<CheckBoxWaitingAnchorPartnerVOModel[]>([]);
  const [partnerList, setPartnerList] = useState<Pageable<AnchorPartnerVOModel[]>>();
  const [isAllChecked, setIsAllChecked] = useState<boolean>(false);
  const isSelectCheckBoxAtLeastOne = checkedItems.length <= 0;

  const {
    register: waitingConfirmationSearchFormRegister,
    getValues: getWaitingConfirmationSearchFormValues,
    setValue: setWaitingConfirmationSearchFormValue,
    reset: resetWaitingConfirmationSearchForm,
    handleSubmit: handleSubmitWaitingConfirmationSearchForm,
  } = useForm<WaitingAnchorPartnerListRequest>();

  const {
    register: registrationCompletedSearchFormRegister,
    getValues: getRegistrationCompletedSearchFormValues,
    setValue: setRegistrationCompletedSearchFormValue,
    reset: resetRegistrationCompletedSearchForm,
    control: registrationCompletedControl,
  } = useForm<AnchorPartnerListRequest>();

  const getWaitingPartnerProperty = useProperty<WaitingAnchorPartnerListRequest>();
  const getPartnerProperty = useProperty<AnchorPartnerListRequest>();

  const signInInfo = getSignIn();
  const isAdmin = signInInfo?.authorityType === AUTHORITY_TYPE.ADMIN;
  const isAuthorizer = signInInfo?.authorityType === AUTHORITY_TYPE.AUTHORIZER;
  const isOperator = signInInfo?.authorityType === AUTHORITY_TYPE.OPERATOR;

  const availAbleCheckedList = useCallback(() => {
    return waitingPartnerList?.content.filter(item => {
      return item.approvalType === COMMON_APPROVAL_TYPE.OPERATOR_REQUEST;
    });
  }, [waitingPartnerList]);

  useEffect(() => {
    if (mounted) {
      fetchAll();
    }
  }, [mounted]);

  useEffect(() => {
    setCheckedItems([]);
  }, [waitingConfirmationPageable.currentPage]);

  useEffect(() => {
    const newCheckedWaitingAnchorPartner = intersectionBy(
      availAbleCheckedList(),
      checkedItems,
      'waitingAnchorPartnerId',
    );
    setCheckedItems(newCheckedWaitingAnchorPartner);
  }, [waitingConfirmationPageable.sizePerPage]);

  useEffect(() => {
    if (availAbleCheckedList()?.length === 0 || availAbleCheckedList()?.length !== checkedItems.length) {
      setIsAllChecked(false);
    } else {
      setIsAllChecked(true);
    }
  }, [checkedItems]);

  async function fetchAll() {
    setFormValues<WaitingAnchorPartnerPageRequest>(
      setWaitingConfirmationSearchFormValue,
      getParsedSearchParams(FI_PARTNER_REGISTRATION_WAITING_LIST_QS_KEY).formSearchData,
    );
    setFormValues<AnchorPartnersPageRequest>(
      setRegistrationCompletedSearchFormValue,
      getParsedSearchParams(FI_PARTNER_REGISTRATION_CONFIRMED_LIST_QS_KEY).formSearchData,
    );
    const { waitingForApprovalSearchData, notCancelledWaitingForApprovalSearchData } = setWaitingTabSearchValue();

    const addFiPartnerRegistrationListQs = () => {
      const waitingQs = makeSearchParamsPattern(
        {
          ...waitingForApprovalSearchData,
          pageNumber: waitingConfirmationPageable.currentPage,
          rowCount: waitingConfirmationPageable.sizePerPage,
        },
        FI_PARTNER_REGISTRATION_WAITING_LIST_QS_KEY,
      );
      const completedQs = makeSearchParamsPattern(
        {
          ...getRegistrationCompletedSearchFormValues(),
          pageNumber: registrationCompletedPageable.currentPage,
          rowCount: registrationCompletedPageable.sizePerPage,
        },
        FI_PARTNER_REGISTRATION_CONFIRMED_LIST_QS_KEY,
      );
      addSearchParams(waitingQs + completedQs);
    };
    addFiPartnerRegistrationListQs();

    try {
      const [waitingPartnerList, partnerList, notCancelledWaitingPartnerList] = await Promise.all([
        requestFiWaitingAnchorPartnerList(
          waitingConfirmationPageable.currentPage,
          waitingConfirmationPageable.sizePerPage,
          waitingForApprovalSearchData,
        ),
        requestFiAnchorPartnerList(
          registrationCompletedPageable.currentPage,
          registrationCompletedPageable.sizePerPage,
          getRegistrationCompletedSearchFormValues(),
        ),
        requestFiWaitingAnchorPartnerList(0, 1, notCancelledWaitingForApprovalSearchData),
      ]);

      ReactDOM.unstable_batchedUpdates(() => {
        setWaitingPartnerList(waitingPartnerList);
        setWaitingConfirmationPageable(waitingPartnerList);
        setPartnerList(partnerList);
        setRegistrationCompletedPageable(partnerList);
        setNotCancelledWaitingPartnerList(notCancelledWaitingPartnerList);
      });
    } catch (error) {
      modal.show(error);
    }
  }

  const setWaitingTabSearchValue = (formSearchData?: WaitingAnchorPartnerListRequest) => {
    const getFormSearchData = formSearchData ? formSearchData : getWaitingConfirmationSearchFormValues();

    const { waitingForApprovalTypeData, notCancelledWaitingForApprovalTypeData } = setApprovalTypesValue(
      getFormSearchData.approvalTypes,
    );

    const waitingForApprovalSearchData = {
      ...getFormSearchData,
      approvalTypes: waitingForApprovalTypeData,
      anchorPartnerStatuses: [PARTNER_STATUS.REGISTERED],
      hasTargetAnchorPartner: false,
    };

    const notCancelledWaitingForApprovalSearchData = {
      ...getFormSearchData,
      approvalTypes: notCancelledWaitingForApprovalTypeData,
      anchorPartnerStatuses: [PARTNER_STATUS.REGISTERED],
      hasTargetAnchorPartner: false,
    };

    return {
      waitingForApprovalSearchData,
      notCancelledWaitingForApprovalSearchData,
    };
  };

  const fetchPartnerList = async (
    pageNumber: number = 1,
    rowCount: number = 10,
    searchData: AnchorPartnerListRequest,
  ) => {
    try {
      const partnerList = await requestFiAnchorPartnerList(pageNumber, rowCount, searchData);
      updateSearchParams(
        {
          ...searchData,
          pageNumber,
          rowCount,
        },
        FI_PARTNER_REGISTRATION_CONFIRMED_LIST_QS_KEY,
      );

      ReactDOM.unstable_batchedUpdates(() => {
        setPartnerList(partnerList);
        setRegistrationCompletedPageable(partnerList);
      });
    } catch (error) {
      modal.show(error);
    }
  };

  const fetchWaitingPartnerList = async (pageNumber: number = 1, rowCount: number = 10) => {
    const { waitingForApprovalSearchData } = setWaitingTabSearchValue(
      getParsedSearchParams(FI_PARTNER_REGISTRATION_WAITING_LIST_QS_KEY).formSearchData,
    );

    try {
      const waitingPartnerList = await requestFiWaitingAnchorPartnerList(
        pageNumber,
        rowCount,
        waitingForApprovalSearchData,
      );
      updateSearchParams(
        {
          ...waitingForApprovalSearchData,
          pageNumber,
          rowCount,
        },
        FI_PARTNER_REGISTRATION_WAITING_LIST_QS_KEY,
      );

      ReactDOM.unstable_batchedUpdates(() => {
        setWaitingPartnerList(waitingPartnerList);
        setWaitingConfirmationPageable(waitingPartnerList);
      });
    } catch (error) {
      modal.show(error);
    }
  };

  const renderWaitingForConfirmationView = (): JSX.Element => {
    const onClickRemoveFilter = (e: any): void => {
      e.preventDefault();
      resetWaitingConfirmationSearchForm();
    };

    const handleWaitingPartnerSearchFormSubmit = async () => {
      setCheckedItems([]);
      const { waitingForApprovalSearchData, notCancelledWaitingForApprovalSearchData } = setWaitingTabSearchValue();

      try {
        const [waitingPartnerList, notCancelledWaitingPartnerList] = await Promise.all([
          requestFiWaitingAnchorPartnerList(0, waitingConfirmationPageable.sizePerPage, waitingForApprovalSearchData),
          requestFiWaitingAnchorPartnerList(0, 1, notCancelledWaitingForApprovalSearchData),
        ]);
        updateSearchParams(
          {
            ...waitingForApprovalSearchData,
            pageNumber: 1,
            rowCount: waitingConfirmationPageable.sizePerPage,
          },
          FI_PARTNER_REGISTRATION_WAITING_LIST_QS_KEY,
        );

        ReactDOM.unstable_batchedUpdates(() => {
          setWaitingPartnerList(waitingPartnerList);
          setWaitingConfirmationPageable(waitingPartnerList);
          setNotCancelledWaitingPartnerList(notCancelledWaitingPartnerList);
        });
      } catch (error) {
        modal.show(error);
      }
    };

    const paginate = async (page: number, sizePerPage: number) => {
      await fetchWaitingPartnerList(page, sizePerPage);
    };

    const getTableHeader = () => {
      return isAdmin ? WAITING_FOR_CONFIRMATION_TABLE_HEADERS.slice(1) : WAITING_FOR_CONFIRMATION_TABLE_HEADERS;
    };

    const onChangeCheckbox = (
      e: React.ChangeEvent<HTMLInputElement>,
      waitingAnchorPartner: WaitingAnchorPartnerVOModel,
    ) => {
      if (e.target.checked) {
        if (
          checkedItems.map(data => data.waitingAnchorPartnerId).indexOf(waitingAnchorPartner.waitingAnchorPartnerId) < 0
        ) {
          setCheckedItems([...checkedItems, waitingAnchorPartner]);
        }
      } else {
        setCheckedItems(
          checkedItems.filter(
            ({ waitingAnchorPartnerId }) => waitingAnchorPartnerId !== waitingAnchorPartner.waitingAnchorPartnerId,
          ),
        );
      }
    };

    const onClickAllCheckbox = (e: any) => {
      if (waitingPartnerList?.content === undefined) return;

      if (e.target.checked) {
        const checkedCheckboxes = [...checkedItems];

        waitingPartnerList?.content.forEach(async item => {
          if (checkedItems.map(data => data.waitingAnchorPartnerId).indexOf(item.waitingAnchorPartnerId) < 0) {
            if (item.approvalType === COMMON_APPROVAL_TYPE.OPERATOR_REQUEST) {
              checkedCheckboxes.push(item);
            }
          }
        });

        setCheckedItems(checkedCheckboxes);
      } else {
        setCheckedItems([]);
      }
    };

    const WAITING_FOR_CONFIRMATION_TABLE_HEADERS: HeaderType[] = [
      {
        headerText: '',
        className: 'text-center',
        colWidths: 50,
        hasCheckBox: true,
        handleClickAllCheckBox: onClickAllCheckbox,
        checkBoxId: String(waitingConfirmationPageable.currentPage),
        isAllChecked,
      },
      {
        headerText: t('text:Uploaded_Partner_Name'),
        colWidths: 100,
      },
      {
        headerText: t('text:Uploaded_Partner_Tax_Code'),
        colWidths: 120,
      },
      {
        headerText: t('text:Associated_Anchor_Name'),
        colWidths: 100,
      },
      {
        headerText: t('text:Associated_Master_Agreement_of_Anchor_Number'),
        colWidths: 120,
      },
      {
        headerText: t('text:Currency'),
        colWidths: 100,
      },
      {
        headerText: t('text:Responsible_Branch_Code'),
        colWidths: 100,
      },
      {
        headerText: t('text:Responsible_Branch_Name'),
        colWidths: 100,
      },
      {
        headerText: t('text:Registered_By'),
        colWidths: 95,
      },
      {
        headerText: t('text:Registration_Status'),
        colWidths: 95,
      },
      {
        headerText: '',
        colWidths: 70,
      },
    ];

    return (
      <>
        <div className="content-area">
          <form>
            <SectionTitle title={t('text:Search')}>
              <Button
                variant={ButtonVariantEnum.OUTLINED}
                color={ButtonColorEnum.SECONDARY}
                onClick={onClickRemoveFilter}
              >
                {t('text:Remove_Filter')}
              </Button>
            </SectionTitle>
            <SearchBorder>
              <div className="row">
                <SearchLabel label={t('text:Uploaded_Partner_Name')} />
                <SearchInput
                  name={getWaitingPartnerProperty('partnerName')}
                  ref={waitingConfirmationSearchFormRegister}
                />
                <SearchLabel label={t('text:Uploaded_Partner_Tax_Code')} />
                <SearchInput
                  name={getWaitingPartnerProperty('partnerTaxCode')}
                  ref={waitingConfirmationSearchFormRegister}
                />
              </div>
              <div className="row">
                <SearchLabel label={t('text:Associated_Anchor_Name')} />
                <SearchInput
                  name={getWaitingPartnerProperty('anchorName')}
                  ref={waitingConfirmationSearchFormRegister}
                />
                <SearchLabel label={t('text:Associated_Master_Agreement_of_Anchor_Number')} />
                <SearchInput
                  name={getWaitingPartnerProperty('anchorAgreementNo')}
                  ref={waitingConfirmationSearchFormRegister}
                />
              </div>
              <div className="row">
                <SearchLabel label={t('text:Currency')} />
                <SearchSelect
                  name={getWaitingPartnerProperty('currencyType')}
                  ref={waitingConfirmationSearchFormRegister}
                  selectOptions={getSelectOptions<CURRENCY_TYPE>('CURRENCY_TYPE', 'ALL', true)}
                />
                <SearchLabel label={t('text:Responsible_Branch_Code')} />
                <SearchInput
                  name={getWaitingPartnerProperty('responsibleBranchCode')}
                  ref={waitingConfirmationSearchFormRegister}
                />
              </div>
              <div className="row">
                <SearchLabel label={t('text:Responsible_Branch_Name')} />
                <SearchInput
                  name={getWaitingPartnerProperty('responsibleBranchName')}
                  ref={waitingConfirmationSearchFormRegister}
                />
                <SearchLabel label={t('text:Registered_By')} />
                <SearchSelect
                  name={getWaitingPartnerProperty('enterpriseType')}
                  ref={waitingConfirmationSearchFormRegister}
                  selectOptions={getSelectOptions<ENTERPRISE_TYPE>(
                    'ENTERPRISE_TYPE',
                    [ENTERPRISE_TYPE.AC, ENTERPRISE_TYPE.FI],
                    true,
                  )}
                />
              </div>
              <div className="row">
                <SearchLabel label={t('text:Registration_Status')} />
                <SearchSelect
                  name={getWaitingPartnerProperty('approvalTypes')}
                  ref={waitingConfirmationSearchFormRegister}
                  selectOptions={[
                    {
                      label: t('text:All'),
                      value: WAITING_COMMON_APPROVAL_TYPE_ALL_VALUES,
                    },
                    {
                      label: t('text:Requested'),
                      value: COMMON_APPROVAL_TYPE.OPERATOR_REQUEST,
                    },
                    {
                      label: t('text:Reverted'),
                      value: COMMON_APPROVAL_TYPE.AUTHORIZER_RETURNED,
                    },
                    {
                      label: t('text:Cancelled'),
                      value: COMMON_APPROVAL_TYPE.OPERATOR_CANCEL,
                    },
                  ]}
                />
                <SearchEmpty />
              </div>
            </SearchBorder>
            <div className="flex-center mt-4">
              <Button
                size={ButtonSizeEnum.LG}
                onClick={handleSubmitWaitingConfirmationSearchForm(handleWaitingPartnerSearchFormSubmit)}
              >
                {t('text:Search')}
              </Button>
            </div>
          </form>
        </div>
        <div className="division-border" />
        <div className="content-area">
          <SectionTitle title={t('text:Result')} />
          <p className="total-data">
            {t('text:Total')} : {waitingPartnerList?.totalElements ?? 0}
          </p>
          <TableBorder>
            <TableHeader header={getTableHeader()} />
            <TableBody numOfCol={getTableHeader().length}>
              {waitingPartnerList?.content.map((item, i) => {
                const isRequested = item.approvalType === COMMON_APPROVAL_TYPE.OPERATOR_REQUEST;

                return (
                  <Tr key={i}>
                    {!isAdmin && (
                      <Td>
                        <div className="text-center">
                          {isRequested && (
                            <input
                              type="checkbox"
                              name={`checkbox-${item.waitingAnchorPartnerId}`}
                              className="form-check-input m-0"
                              onChange={e => onChangeCheckbox(e, item)}
                              checked={checkedItems
                                .map(({ waitingAnchorPartnerId }) => waitingAnchorPartnerId)
                                .includes(item.waitingAnchorPartnerId)}
                            />
                          )}
                        </div>
                      </Td>
                    )}
                    <Td data={item.name} />
                    <Td data={item.partnerTaxCode} />
                    <Td data={item.anchorClientName} />
                    <Td data={item.anchorAgreementContractNo} />
                    <Td data={item.currencyType} />
                    <Td data={item.responsibleBranchCode} />
                    <Td data={item.responsibleBranchName} />
                    <Td data={t(`code:enterprise-type.${item.operatorEnterpriseType}`)} />
                    <Td
                      className={getStatusTextClass('COMMON_APPROVAL_TYPE', item.approvalType)}
                      data={t(`code:common-approval-type.${item.approvalType}`)}
                      format="code"
                    />
                    <TdLink
                      path={ROUTES_FI.MANAGE_UPLOADED_PARTNER.REGISTRATION_WAITING_DETAIL_BUILD_PATH(
                        item.waitingAnchorPartnerId,
                      )}
                    />
                  </Tr>
                );
              })}
            </TableBody>
          </TableBorder>
          <Pagination pageable={waitingConfirmationPageable} paginate={paginate} />
        </div>
        <div className="content-area clearfix">{renderButtons()}</div>
      </>
    );
  };

  const renderRegistrationCompletedView = (): JSX.Element => {
    const onClickRemoveFilter = (e: any): void => {
      e.preventDefault();
      resetRegistrationCompletedSearchForm();
    };

    const handlePartnerSearchFormSubmit = async (e: any) => {
      e.preventDefault();

      await fetchPartnerList(1, registrationCompletedPageable.sizePerPage, getRegistrationCompletedSearchFormValues());
    };

    const paginate = async (page: number, sizePerPage: number) => {
      await fetchPartnerList(
        page,
        sizePerPage,
        getParsedSearchParams(FI_PARTNER_REGISTRATION_CONFIRMED_LIST_QS_KEY).formSearchData,
      );
    };

    const onClickCompletedExportButton = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.preventDefault();

      const COMPLETED_EXCEL_COLUMNS: ColumnOption<AnchorPartnerVOModel>[] = [
        {
          header: t('text:Registration_Date'),
          key: 'createdDateTime',
          width: 20,
        },
        {
          header: t('text:Anchor_Name'),
          key: 'anchorName',
          width: 20,
        },
        {
          header: t('text:Uploaded_Partner_Name'),
          key: 'name',
          width: 20,
        },
        {
          header: t('text:Uploaded_Partner_Tax_Code'),
          key: 'partnerTaxCode',
          width: 30,
        },
        {
          header: t('text:Registered_Office_Address'),
          key: 'address',
          width: 30,
        },
        {
          header: t('text:Legal_Representative_Name'),
          key: 'representativeName',
          width: 30,
        },
        {
          header: t('text:Legal_Representative_Email'),
          key: 'representativeEmail',
          width: 30,
        },
        {
          header: t('text:Telephone'),
          key: 'telephone',
          width: 20,
        },
      ];

      const EXCEL_EXPORT_MAX_ROW_COUNT = 1000;
      const PDF_EXPORT_MAX_ROW_COUNT = 150;

      const renderPDFTableBodyResult = (data?: AnchorPartnerVOModel[]) => {
        return data?.map((item, index) => (
          <tr key={index} className="virtual-table-row">
            <Td data={item.createdDateTime} format="datetime" />
            <Td data={item.anchorName} />
            <Td data={item.name} />
            <Td data={item.partnerTaxCode} />
            <Td data={item.address} />
            <Td data={item.representativeName} />
            <Td data={item.representativeEmail} />
            <Td data={item.telephone} />
          </tr>
        ));
      };

      const completedSearchFormValues = getRegistrationCompletedSearchFormValues();

      try {
        const [completedAnchorPartnerExcelPage, completedAnchorPartnerPDFPage] = await Promise.all([
          requestFiAnchorPartnerList(0, EXCEL_EXPORT_MAX_ROW_COUNT, completedSearchFormValues),
          requestFiAnchorPartnerList(0, PDF_EXPORT_MAX_ROW_COUNT, completedSearchFormValues),
        ]);

        const jsonArrayData = completedAnchorPartnerExcelPage.content.map(completedAnchorPartnerData => ({
          createdDateTime: tableValueManage(
            completedAnchorPartnerData.createdDateTime,
            t('format:datetime', {
              value: completedAnchorPartnerData.createdDateTime,
              key: 'datetime',
            }),
          ),
          anchorName: tableValueManage(completedAnchorPartnerData.anchorName),
          name: tableValueManage(completedAnchorPartnerData.name),
          partnerTaxCode: tableValueManage(completedAnchorPartnerData.partnerTaxCode),
          address: tableValueManage(completedAnchorPartnerData.address),
          representativeName: tableValueManage(completedAnchorPartnerData.representativeName),
          representativeEmail: tableValueManage(completedAnchorPartnerData.representativeEmail),
          telephone: tableValueManage(completedAnchorPartnerData.telephone),
        }));

        const excelExporterProps: ExportSpreadSheetProps<AnchorPartnerVOModel> = {
          jsonArrayData: jsonArrayData,
          columns: COMPLETED_EXCEL_COLUMNS,
          options: {
            rowHeight: [{ position: 1, height: 30 }],
          },
        };

        const pdfExporterProps: PDFExporterProps = {
          tableHeaders: REGISTRATION_COMPLETED_TABLE_HEADERS,
          tableBody: renderPDFTableBodyResult(completedAnchorPartnerPDFPage.content),
        };

        modal.show(<Exporter spreadSheetExporterProps={excelExporterProps} pdfExporterProps={pdfExporterProps} />, {
          title: t('text:Export_File'),
          closeBtnText: t('text:Close'),
        });
      } catch (error) {
        modal.show(error);
      }
    };

    return (
      <>
        <div className="content-area">
          <form>
            <SectionTitle title={t('text:Search')}>
              <Button
                variant={ButtonVariantEnum.OUTLINED}
                color={ButtonColorEnum.SECONDARY}
                onClick={onClickRemoveFilter}
              >
                {t('text:Remove_Filter')}
              </Button>
            </SectionTitle>
            <SearchBorder>
              <div className="row">
                <SearchLabel label={t('text:Registration_Date')} />
                <SearchDatePicker
                  placeholder={t('text:from')}
                  name={getPartnerProperty('fromDate')}
                  control={registrationCompletedControl}
                />
                <SearchDatePicker
                  placeholder={t('text:to')}
                  name={getPartnerProperty('toDate')}
                  control={registrationCompletedControl}
                />
                <SearchLabel label={t('text:Anchor_Name')} />
                <SearchInput name={getPartnerProperty('anchorName')} ref={registrationCompletedSearchFormRegister} />
              </div>
              <div className="row">
                <SearchLabel label={t('text:Uploaded_Partner_Name')} />
                <SearchInput name={getPartnerProperty('partnerName')} ref={registrationCompletedSearchFormRegister} />
                <SearchLabel label={t('text:Uploaded_Partner_Tax_Code')} />
                <SearchInput
                  name={getPartnerProperty('partnerTaxCode')}
                  ref={registrationCompletedSearchFormRegister}
                />
              </div>
              <div className="row">
                <SearchLabel label={t('text:Legal_Representative_Name')} />
                <SearchInput
                  name={getPartnerProperty('representativeName')}
                  ref={registrationCompletedSearchFormRegister}
                />
                <SearchLabel label={t('text:Telephone')} />
                <SearchInput
                  name={getPartnerProperty('partnerTelephone')}
                  ref={registrationCompletedSearchFormRegister}
                />
              </div>
            </SearchBorder>
            <div className="flex-center mt-4">
              <Button size={ButtonSizeEnum.LG} onClick={handlePartnerSearchFormSubmit}>
                {t('text:Search')}
              </Button>
            </div>
          </form>
        </div>
        <div className="division-border" />
        <div className="content-area">
          <SectionTitle title={t('text:Result')} />
          <div className="d-flex mb-2">
            <p className="total-data me-auto">
              {t('text:Total')} : {partnerList?.totalElements ?? 0}
            </p>
            <Button onClick={onClickCompletedExportButton} variant={ButtonVariantEnum.OUTLINED}>
              {t('text:Export')}
            </Button>
          </div>
          <TableBorder>
            <TableHeader header={REGISTRATION_COMPLETED_TABLE_HEADERS} />
            <TableBody numOfCol={REGISTRATION_COMPLETED_TABLE_HEADERS.length}>
              {partnerList?.content.map((item, i) => {
                return (
                  <Tr key={i}>
                    <Td data={item.createdDateTime} format="datetime" />
                    <Td data={item.anchorName} />
                    <Td data={item.name} />
                    <Td data={item.partnerTaxCode} />
                    <Td data={item.address} />
                    <Td data={item.representativeName} />
                    <Td data={item.representativeEmail} />
                    <Td data={item.telephone} />
                    <TdLink
                      path={ROUTES_FI.MANAGE_UPLOADED_PARTNER.REGISTRATION_CONFIRMED_DETAIL_BUILD_PATH(
                        item.anchorPartnerId,
                      )}
                    />
                  </Tr>
                );
              })}
            </TableBody>
          </TableBorder>
          <Pagination pageable={registrationCompletedPageable} paginate={paginate} />
        </div>
      </>
    );
  };

  const renderButtons = (): JSX.Element | null => {
    const onClickCancelRequest = async () => {
      const requestCancelRegistration = async () => {
        const waitingAnchorPartnerIds = checkedItems.map(({ waitingAnchorPartnerId }) => waitingAnchorPartnerId);
        try {
          await requestFiWaitingAnchorPartnerCancel({ waitingAnchorPartnerIds });
          await fetchWaitingPartnerList(0, waitingConfirmationPageable.sizePerPage);
          setCheckedItems([]);
        } catch (error) {
          modal.show(error);
        }
      };

      modal.show(
        <h6>
          {t('text:Would_you_like_to_cancel_the_registration?')}
          <br />
          {t('text:The_information_will_not_be_saved_if_registration_is_cancelled')}
        </h6>,
        {
          modalType: ModalType.CONFIRM,
          confirmBtnCb: requestCancelRegistration,
        },
      );
    };

    const onClickRevert = () => {
      let reason = '';

      const successfullyRevertedModal = () => {
        modal.show(<h6>{t('text:The_Supply_Chain_Partner_registration_request_has_been_reverted')}</h6>);
      };

      const requestRevertRegistration = async () => {
        const waitingAnchorPartnerIds = checkedItems.map(({ waitingAnchorPartnerId }) => waitingAnchorPartnerId);
        try {
          await requestFiWaitingAnchorPartnerReturn({ returnReason: reason, waitingAnchorPartnerIds });
          successfullyRevertedModal();
          await fetchWaitingPartnerList(0, waitingConfirmationPageable.sizePerPage);
          setCheckedItems([]);
        } catch (error) {
          modal.show(error);
        }
      };

      modal.show(
        <>
          <h6>
            {t('text:Would_you_like_to_revert_the_registration_request?')}
            <br />
            {t('text:Please_type_below_the_reason_for_the_revert')}
            <br />
            {t('text:Click_on_Confirm_to_revert_the_request')}
          </h6>
          <ReasonModal
            modalId={modal.id}
            getReturnReason={(returnReason: string) => {
              reason = returnReason;
            }}
          />
        </>,
        {
          modalType: ModalType.CONFIRM,
          closeBtnText: t('text:Cancel'),
          confirmBtnCb: requestRevertRegistration,
        },
      );
    };

    const onClickConfirm = () => {
      const successfullyConfirmedModal = (requestResult: WaitingAnchorPartnerVOModel[]) => {
        modal.show(
          <FinancierUploadedPartnerRegistrationResultModal
            requestResult={requestResult}
            userAuthorityType={signInInfo?.authorityType}
          />,
          {
            modalSize: ModalSize.XL,
            closeBtnText: t('text:OK'),
          },
        );
      };

      const requestConfirmRegistration = async () => {
        const waitingAnchorPartnerIds = checkedItems.map(({ waitingAnchorPartnerId }) => waitingAnchorPartnerId);
        try {
          const response = await requestFiWaitingAnchorPartnerApprove({ waitingAnchorPartnerIds });
          successfullyConfirmedModal(response.content);
          fetchAll();
          setCheckedItems([]);
        } catch (error) {
          modal.show(error);
        }
      };

      const mapWaitingAnchorPartnerRequestFormToWaitingAnchorPartner = (): WaitingAnchorPartnerRequest[] => {
        return checkedItems.map(data => {
          return {
            partnerTaxCode: data.partnerTaxCode,
            name: data.name,
            address: data.address,
            telephone: data.telephone,
            representativeName: data.representativeName,
            representativeEmail: data.representativeEmail,
            account: data.account,
            accountOwner: data.accountOwner,
            bankCode: data.accountBankCode,
          };
        });
      };

      modal.show(
        <FinancierUploadedPartnerRegistrationConfirmModal
          anchorPartners={mapWaitingAnchorPartnerRequestFormToWaitingAnchorPartner()}
          userAuthorityType={signInInfo?.authorityType}
        />,
        {
          modalSize: ModalSize.XL,
          modalType: ModalType.CONFIRM,
          closeBtnText: t('text:Cancel'),
          confirmBtnCb: requestConfirmRegistration,
        },
      );
    };

    if (isOperator) {
      return (
        <div className="flex-end mt-3">
          <Button
            onClick={onClickCancelRequest}
            size={ButtonSizeEnum.LG}
            color={ButtonColorEnum.SECONDARY}
            variant={ButtonVariantEnum.OUTLINED}
            disabled={isSelectCheckBoxAtLeastOne}
          >
            {t('text:Cancel_Request')}
          </Button>
        </div>
      );
    } else if (isAuthorizer) {
      return (
        <div className="flex-end mt-3">
          <Button
            size={ButtonSizeEnum.LG}
            color={ButtonColorEnum.SECONDARY}
            variant={ButtonVariantEnum.OUTLINED}
            className="ms-2"
            onClick={onClickRevert}
            disabled={isSelectCheckBoxAtLeastOne}
          >
            {t('text:Revert')}
          </Button>
          <Button
            onClick={onClickConfirm}
            className="ms-2"
            size={ButtonSizeEnum.LG}
            disabled={isSelectCheckBoxAtLeastOne}
          >
            {t('text:Confirm')}
          </Button>
        </div>
      );
    } else {
      return null;
    }
  };

  return (
    <>
      <HeaderTitle title={t('text:Uploaded_Partner_Registration')} />
      {isOperator && (
        <GuideMessage
          message={[
            t(
              'text:Click_on_the_Register_Uploaded_Partner_button_to_register_the_information_of_Anchors_new_partners_to_the_platform',
            ),
            t('text:Click_on_the_arrow_button_to_view_detailed_information_of_the_Uploaded_Partners'),
          ]}
        >
          {{
            button: (
              <Button to={ROUTES_FI.MANAGE_UPLOADED_PARTNER.REGISTRATION_STEP1}>
                {t('text:Register_Potential_Supply_Chain_Partner')}
              </Button>
            ),
          }}
        </GuideMessage>
      )}
      {(isAdmin || isAuthorizer) && (
        <GuideMessage
          message={[t('text:Click_on_the_arrow_button_to_view_detailed_information_of_the_Uploaded_Partners')]}
        />
      )}

      <Tab
        tabType="approvalStatus"
        initialActiveTabIndex={1}
        tabList={[
          {
            tabItemCount: getWaitingForApprovalCount(
              getWaitingConfirmationSearchFormValues('approvalTypes'),
              notCancelledWaitingPartnerList?.totalElements,
            ),
          },
          {
            tabItemCount: partnerList?.totalElements ?? 0,
          },
        ]}
        tabViewList={[renderWaitingForConfirmationView(), renderRegistrationCompletedView()]}
      />
    </>
  );
}

export default FinancierPotentialSupplyChainPartnerRegistrationList;
