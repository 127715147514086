import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import Button, { ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import ReasonModal from 'components/stateless/Modal/common/ReasonModal';
import { ROUTES_FI } from 'constants/routes/financier';
import { POTENTIAL_PARTNER_FINANCING_APPLICATION_STATUS } from 'enums';
import type { PotentialPartnerFinancingApplicationDetailVOModel } from 'models/vo/PotentialPartnerFinancingApplicationDetailVO';
import { ModalType } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';
import { hasValue } from 'utils/valueManager/ValueManager';

interface FinancierSCPartnerAcquisitionDetailButtonsProps {
  applicationStatus: POTENTIAL_PARTNER_FINANCING_APPLICATION_STATUS;
  updateApplicationSubmitStatusApis: () => {
    cancelInProgress: () => Promise<void>;
    reviewSubmitted: () => Promise<void>;
    rejectInProgress: (rejectReason: string) => Promise<void>;
    checkHasActivatedApplication: () => Promise<PotentialPartnerFinancingApplicationDetailVOModel | void>;
  };
}

function FinancierSCPartnerAcquisitionDetailButtons({
  updateApplicationSubmitStatusApis,
  applicationStatus,
}: FinancierSCPartnerAcquisitionDetailButtonsProps) {
  const { t } = useTranslation(['format']);
  const modal = useModal();
  const history = useHistory();

  const renderButtonsByStatus = (status: POTENTIAL_PARTNER_FINANCING_APPLICATION_STATUS) => {
    const { cancelInProgress, reviewSubmitted, rejectInProgress, checkHasActivatedApplication } =
      updateApplicationSubmitStatusApis();

    const onClickCancelInProgress = async (e: any) => {
      e.preventDefault();

      const onClickConfirmCancelReview = async () => {
        await cancelInProgress();
        modal.show(<h6>{t('text:The_submission_review_has_been_cancelled_successfully')}</h6>, {
          title: t('text:Cancel_Review'),
        });
      };

      modal.show(
        <h6>
          {t('text:Would_you_like_to_cancel_the_submission_review?')}
          <br />
          {t(
            'text:If_the_review_is_cancelled_the_submission_status_will_return_to_the_Submitted_status_and_other_branches_will_be_able_to_review_the_Uploaded_Partner_submission',
          )}
        </h6>,
        {
          modalType: ModalType.CONFIRM,
          title: t('text:Cancel_Review'),
          confirmBtnCb: () => {
            onClickConfirmCancelReview();
          },
        },
      );
    };

    const onClickReviewSubmitted = async (e: any) => {
      e.preventDefault();

      const onClickConfirmReviewSubmitted = async () => {
        await reviewSubmitted();
        modal.show(
          <h6>
            {t('text:The_Uploaded_Partner_submission_review_is_now_in_progress')}
            <br />
            {t(
              'text:After_reviewing_the_submitted_documents_change_the_status_of_the_documents_to_notify_the_Uploaded_Partner_whether_they_are_confirmed_or_must_be_resubmitted',
            )}
          </h6>,
        );
      };
      modal.show(<h6>{t('text:Would_you_like_to_proceed_with_the_Uploaded_Partner_s_submission_review?')}</h6>, {
        modalType: ModalType.CONFIRM,
        title: t('text:Review_Submission'),
        confirmBtnCb: () => {
          onClickConfirmReviewSubmitted();
        },
      });
    };

    const onClickRejectInProgress = async (e: any) => {
      e.preventDefault();

      let reason = '';

      const onClickConfirmReject = async () => {
        await rejectInProgress(reason);
        modal.show(<h6>{t('text:The_submission_has_been_rejected_successfully')}</h6>);
      };

      modal.show(
        <>
          <h6>
            {t('text:Would_you_like_to_reject_the_submission?')}
            <br />
            {t(
              'text:If_the_submission_is_rejected_the_Uploaded_Partner_will_have_to_resubmit_their_documents_from_the_beginning',
            )}
            <br />
            {t('text:Please_enter_the_reason_for_the_rejection_below')}
          </h6>
          <ReasonModal
            modalId={modal.id}
            getReturnReason={(returnReason: string) => {
              reason = returnReason;
            }}
            required
          />
        </>,
        {
          modalType: ModalType.CONFIRM,
          title: t('text:Reject_Submission'),
          confirmBtnCb: () => {
            onClickConfirmReject();
          },
        },
      );
    };

    const onClickCancelRejected = async (e: any) => {
      e.preventDefault();
      // 완료된 건이 있는지 확인하기
      const activatedApplication = await checkHasActivatedApplication();
      if (activatedApplication && hasValue(activatedApplication.potentialPartnerFinancingApplicationId)) {
        if (
          activatedApplication.potentialPartnerFinancingApplicationStatus ===
          POTENTIAL_PARTNER_FINANCING_APPLICATION_STATUS.COMPLETED
        ) {
          modal.show(
            <h6>
              {t(
                'text:The_rejection_cannot_be_cancelled_as_the_Uploaded_Partners_acquisition_has_been_completed_and_its_Partner_Master_Agreement_has_been_registered_on_the_platform',
              )}
            </h6>,
          );
        } else {
          modal.show(
            <h6>
              {t('text:The_rejection_cannot_be_cancelled_as_the_Uploaded_Partner_has_submitted_a_new_application')}
              <br />
              {t('text:Proceed_to_the_Uploaded_Partner_s_new_application_page_to_review_the_submission_information')}
            </h6>,
            {
              modalType: ModalType.CONFIRM,
              confirmBtnText: t('text:View_Submission'),
              confirmBtnCb: async () => {
                history.push(
                  ROUTES_FI.MANAGE_UPLOADED_PARTNER.ACQUISITION_DETAIL_BUILD_PATH(
                    activatedApplication.potentialPartnerFinancingApplicationId,
                  ),
                );
              },
            },
          );
        }
      }
    };

    switch (status) {
      case POTENTIAL_PARTNER_FINANCING_APPLICATION_STATUS.REJECTED: {
        return (
          <Button
            size={ButtonSizeEnum.LG}
            color={ButtonColorEnum.SECONDARY}
            variant={ButtonVariantEnum.OUTLINED}
            onClick={onClickCancelRejected}
          >
            {t('text:Cancel_Rejection')}
          </Button>
        );
      }
      case POTENTIAL_PARTNER_FINANCING_APPLICATION_STATUS.IN_PROGRESS: {
        return (
          <>
            <Button
              size={ButtonSizeEnum.LG}
              color={ButtonColorEnum.SECONDARY}
              variant={ButtonVariantEnum.OUTLINED}
              onClick={onClickCancelInProgress}
            >
              {t('text:Cancel_Review')}
            </Button>
            <Button
              size={ButtonSizeEnum.LG}
              color={ButtonColorEnum.RED}
              variant={ButtonVariantEnum.OUTLINED}
              className="ms-2"
              onClick={onClickRejectInProgress}
            >
              {t('text:Reject_Submission')}
            </Button>
          </>
        );
      }
      case POTENTIAL_PARTNER_FINANCING_APPLICATION_STATUS.SUBMITTED: {
        return (
          <Button size={ButtonSizeEnum.LG} onClick={onClickReviewSubmitted}>
            {t('text:Review_Submission')}
          </Button>
        );
      }
      default:
        return;
    }
  };

  return (
    <div className="content-area">
      <div className="flex-end mt-3">{renderButtonsByStatus(applicationStatus)}</div>
    </div>
  );
}

export default FinancierSCPartnerAcquisitionDetailButtons;
