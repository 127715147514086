import type { MouseEventHandler } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Button, { ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import Exporter from 'components/stateless/Exporter/Exporter';
import StatusDescriptionModal from 'components/stateless/Modal/common/status/StatusDescriptionModal';
import Pagination from 'components/stateless/Pagination/Pagination';
import SearchBorder from 'components/stateless/SearchForm/SearchBorder';
import SearchDatePicker from 'components/stateless/SearchForm/SearchDatePicker';
import SearchInput from 'components/stateless/SearchForm/SearchInput';
import SearchLabel from 'components/stateless/SearchForm/SearchLabel';
import SearchSelect from 'components/stateless/SearchForm/SearchSelect';
import TableBody from 'components/stateless/Table/TableBody';
import TableBorder from 'components/stateless/Table/TableBorder';
import type { HeaderType } from 'components/stateless/Table/TableHeader';
import TableHeader from 'components/stateless/Table/TableHeader';
import Td from 'components/stateless/Table/Td';
import TdLink from 'components/stateless/Table/TdLink';
import Tr from 'components/stateless/Table/Tr';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { ROUTES_AC } from 'constants/routes/anchor';
import getSelectOptions from 'constants/selectOptions';
import type { CURRENCY_TYPE } from 'enums';
import { SUCCESS_AR_STATUS } from 'enums';
import type { PageableType } from 'hooks/usePageable';
import type Pageable from 'models/Pageable';
import type { SuccessArVOModel } from 'models/vo/SuccessArVO';
import { getBlockedStatusClassName } from 'utils/classNames';
import getStatusTextClass from 'utils/classNames/getStatusTextClass';
import type { PDFExporterProps } from 'utils/exportFile/exportPDF.d';
import { requestAnchorSuccessArsList } from 'utils/http/api/anchor/success-ars';
import type { AnchorSuccessArsListRequest } from 'utils/http/api/anchor/success-ars/requests';
import { ModalSize, ModalType } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';
import { getParsedSearchParams } from 'utils/searchParams';
import type { ColumnOption, ExportSpreadSheetProps } from 'utils/spreadSheet/types';
import { getBlockedStatusText } from 'utils/text';
import { tableValueManage } from 'utils/valueManager/ValueManager';

import { AC_DELETED_AP_LIST_QS_KEY } from '../useAnchorApListController';

const EXCEL_EXPORT_MAX_ROW_COUNT = 1000;
const PDF_EXPORT_MAX_ROW_COUNT = 150;

interface AnchorApListRegisteredTabProps {
  data: Pageable<SuccessArVOModel[]> | undefined;
  pageable: PageableType;
  paginate(page: number, sizePerPage: number): void;
  handleClickSearch: MouseEventHandler<HTMLButtonElement>;
}

function AnchorApListRegisteredTab({ data, pageable, paginate, handleClickSearch }: AnchorApListRegisteredTabProps) {
  const { t } = useTranslation();
  const { register, reset, control } = useFormContext<AnchorSuccessArsListRequest>();
  const modal = useModal();

  const onClickRemoveFilter = (e: any) => {
    e.preventDefault();
    reset({});
  };

  const onClickSettlementStatusModal = (): void => {
    modal.show(
      <StatusDescriptionModal
        statusDescriptionEnum={Object.values(SUCCESS_AR_STATUS)}
        statusDescriptionEnumType="SUCCESS_AR_STATUS"
        statusDescriptionModalType="TEXT"
      />,
      {
        modalType: ModalType.ALERT,
        modalSize: ModalSize.XL,
      },
    );
  };

  const tableHeaders: HeaderType[] = [
    {
      headerText: t('text:Partner_Name'),
    },
    {
      headerText: t('text:Tax_Code'),
    },
    {
      headerText: t('text:AP_Number'),
    },
    {
      headerText: t('text:AP_Issued_Date'),
    },
    {
      headerText: t('text:Original_Settlement_Date'),
    },
    {
      headerText: t('text:Modified_Settlement_Date'),
    },
    {
      headerText: t('text:AP_Amount'),
    },
    {
      headerText: t('text:Currency'),
    },
    {
      headerText: t('text:Financing_Eligibility'),
    },
    {
      headerText: t('text:Settlement_Status'),
      hasStatusDescription: true,
      showStatusDescriptionFunc: onClickSettlementStatusModal,
    },
    {
      headerText: '',
      colWidths: 50,
    },
  ];

  const renderResultTable = () => {
    return data?.content.map((item, i) => {
      return (
        <Tr key={i}>
          <Td data={item.anchorPartnerName} />
          <Td data={item.anchorPartnerTaxCode} />
          <Td data={item.arNumber} />
          <Td data={item.arIssuedDate} format="date" />
          <Td data={item.maturityDate} format="date" />
          <Td data={item.settlementDate} format="date" />
          <Td data={item.arAmount} format="number" />
          <Td data={item.currencyType} />
          <Td
            className={getBlockedStatusClassName(item.blockedByFinancier)}
            data={getBlockedStatusText(item.blockedByFinancier)}
          />
          <Td
            className={getStatusTextClass('SUCCESS_AR_STATUS', item.successArStatus)}
            data={t(`code:success-ar-status.${item.successArStatus}`)}
            format="code"
          />
          <TdLink path={ROUTES_AC.VIEW_TRANSACTION.AP_DETAIL_BUILD_PATH(item.successArId)} />
        </Tr>
      );
    });
  };

  const onClickExportButton = async () => {
    const data = getParsedSearchParams(AC_DELETED_AP_LIST_QS_KEY).formSearchData;

    try {
      const successArListResponse = await requestAnchorSuccessArsList(0, EXCEL_EXPORT_MAX_ROW_COUNT, data);
      const successArListPdfResponse = successArListResponse.content.slice(0, PDF_EXPORT_MAX_ROW_COUNT);

      const jsonArrayData: unknown[] = successArListResponse.content.map(item => ({
        anchorPartnerName: tableValueManage(item.anchorPartnerName),
        anchorPartnerTaxCode: tableValueManage(item.anchorPartnerTaxCode),
        arNumber: tableValueManage(item.arNumber),
        arIssuedDate: tableValueManage(item.arIssuedDate, t('format:date', { value: item.arIssuedDate, key: 'date' })),
        maturityDate: tableValueManage(item.maturityDate, t('format:date', { value: item.maturityDate, key: 'date' })),
        settlementDate: tableValueManage(
          item.settlementDate,
          t('format:date', { value: item.settlementDate, key: 'date' }),
        ),
        arAmount: Number(item.arAmount),
        currencyType: tableValueManage(item.currencyType),
        blockedByFinancier: getBlockedStatusText(item.blockedByFinancier),
        successArStatus: tableValueManage(item.successArStatus, t(`code:success-ar-status.${item.successArStatus}`)),
      }));

      const excelColumns: ColumnOption<SuccessArVOModel>[] = [
        {
          header: t('text:Deleted_Date'),
          key: 'anchorPartnerName',
          width: 30,
        },
        {
          header: t('text:Tax_Code'),
          key: 'anchorPartnerTaxCode',
          width: 30,
        },
        {
          header: t('text:AP_Number'),
          key: 'arNumber',
          width: 30,
        },
        {
          header: t('text:AP_Issued_Date'),
          key: 'arIssuedDate',
          width: 30,
        },
        {
          header: t('text:Original_Settlement_Date'),
          key: 'maturityDate',
          width: 30,
        },
        {
          header: t('text:Modified_Settlement_Date'),
          key: 'settlementDate',
          width: 30,
        },
        {
          header: t('text:AP_Amount'),
          key: 'arAmount',
          width: 30,
        },
        {
          header: t('text:Currency'),
          key: 'currencyType',
          width: 30,
        },
      ];

      const excelExporterProps: ExportSpreadSheetProps<any> = {
        jsonArrayData,
        columns: excelColumns,
        options: {
          rowHeight: [{ position: 1, height: 30 }],
        },
      };

      const pdfTableHeaders: HeaderType[] = [
        {
          headerText: t('text:Deleted_Date'),
          colWidths: 150,
        },
        {
          headerText: t('text:Partner_Name'),
          colWidths: 150,
        },
        {
          headerText: t('text:Tax_Code'),
          colWidths: 120,
        },
        {
          headerText: t('text:AP_Number'),
          colWidths: 120,
        },
        {
          headerText: t('text:AP_Issued_Date'),
          colWidths: 120,
        },
        {
          headerText: t('text:Original_Settlement_Date'),
          colWidths: 120,
        },
        {
          headerText: t('text:Modified_Settlement_Date'),
          colWidths: 120,
        },
        {
          headerText: t('text:AP_Amount'),
          colWidths: 100,
        },
        {
          headerText: t('text:Currency'),
          colWidths: 100,
        },
      ];

      const renderPDFTableBodyResult = () => {
        return successArListPdfResponse.map((item, i) => {
          return (
            <Tr key={i} className="virtual-table-row">
              <Td data={item.arIssuedDate} format="date" />
              <Td data={item.anchorPartnerName} />
              <Td data={item.anchorPartnerTaxCode} />
              <Td data={item.arNumber} />
              <Td data={item.arIssuedDate} format="date" />
              <Td data={item.maturityDate} format="date" />
              <Td data={item.settlementDate} format="date" />
              <Td data={item.arAmount} format="number" />
              <Td data={item.currencyType} />
            </Tr>
          );
        });
      };

      const pdfExporterProps: PDFExporterProps = {
        tableHeaders: pdfTableHeaders,
        tableBody: renderPDFTableBodyResult(),
      };

      modal.show(<Exporter spreadSheetExporterProps={excelExporterProps} pdfExporterProps={pdfExporterProps} />, {
        title: t('text:Export_File'),
        closeBtnText: t('text:Close'),
      });
    } catch (e) {
      modal.show(e);
    }
  };

  return (
    <>
      <div className="content-area">
        <form>
          <SectionTitle title={t('text:Search')}>
            <Button
              variant={ButtonVariantEnum.OUTLINED}
              color={ButtonColorEnum.SECONDARY}
              onClick={onClickRemoveFilter}
            >
              {t('text:Remove_Filter')}
            </Button>
          </SectionTitle>
          <SearchBorder>
            <div className="row">
              <SearchLabel label={t('text:Partner_Name')} />
              <SearchInput name="partnerName" ref={register} />
              <SearchLabel label={t('text:Tax_Code')} />
              <SearchInput name="partnerTaxCode" ref={register} />
            </div>
            <div className="row">
              <SearchLabel label={t('text:AP_Number')} />
              <SearchInput name="arNumber" ref={register} />
              <SearchLabel label={t('text:AP_Issued_Date')} />
              <SearchDatePicker placeholder={t('text:from')} name="arIssuedDateFrom" control={control} />
              <SearchDatePicker placeholder={t('text:to')} name="arIssuedDateTo" control={control} />
            </div>
            <div className="row">
              <SearchLabel label={t('text:Original_Settlement_Date')} />
              <SearchDatePicker placeholder={t('text:from')} name="maturityDateFrom" control={control} />
              <SearchDatePicker placeholder={t('text:to')} name="maturityDateTo" control={control} />
              <SearchLabel label={t('text:Modified_Settlement_Date')} />
              <SearchDatePicker placeholder={t('text:from')} name="settlementDateFrom" control={control} />
              <SearchDatePicker placeholder={t('text:to')} name="settlementDateTo" control={control} />
            </div>
            <div className="row">
              <SearchLabel label={t('text:AP_Amount')} />
              <SearchInput col={2} name="minimumArAmount" ref={register} placeholder={t('text:from')} />
              <SearchInput col={2} name="maximumArAmount" ref={register} placeholder={t('text:to')} />
              <SearchLabel label={t('text:Currency')} />
              <SearchSelect
                selectOptions={getSelectOptions<CURRENCY_TYPE>('CURRENCY_TYPE', 'ALL', true)}
                name="currencyType"
                ref={register}
              />
            </div>
            <div className="row">
              <SearchLabel label={t('text:Financing_Eligibility')} />
              <SearchSelect
                selectOptions={getSelectOptions<string>('FINANCING_ELIGIBILITY', 'ALL', true)}
                name="blocked"
                ref={register}
              />
              <SearchLabel
                label={t('text:Settlement_Status')}
                hasStatusDescription={true}
                showStatusDescriptionFunc={onClickSettlementStatusModal}
              />
              <SearchSelect
                selectOptions={getSelectOptions<SUCCESS_AR_STATUS>('SUCCESS_AR_STATUS', 'ALL', true)}
                name="successArStatus"
                ref={register}
              />
            </div>
          </SearchBorder>
          <div className="flex-center mt-4">
            <Button size={ButtonSizeEnum.LG} onClick={handleClickSearch}>
              {t('text:Search')}
            </Button>
          </div>
        </form>
      </div>
      <div className="division-border" />
      <div className="content-area">
        <SectionTitle title={t('text:Result')} />
        <div className="d-flex mb-2">
          <p className="total-data me-auto">
            {t('text:Total')} {data?.totalElements}
          </p>
          <Button onClick={onClickExportButton} variant={ButtonVariantEnum.OUTLINED}>
            {t('text:Export')}
          </Button>
        </div>
        <TableBorder>
          <TableHeader header={tableHeaders} />
          <TableBody numOfCol={tableHeaders.length}>{renderResultTable()}</TableBody>
        </TableBorder>
        <Pagination pageable={pageable} paginate={paginate} />
      </div>
    </>
  );
}

export default AnchorApListRegisteredTab;
