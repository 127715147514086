import { useEffect } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import Button from 'components/stateless/Button/Button';
import GuideMessage from 'components/stateless/GuideMessage/GuideMessage';
import Tab from 'components/stateless/TabManager/Tab';
import { HeaderTitle } from 'components/stateless/Title/HeaderTitle';
import { ROUTES_FI } from 'constants/routes/financier';
import useMounted from 'hooks/useMounted';

import FinancierScPartnerAcquisitionListTabWrapper from './components/tabWrapper/FinancierScPartnerAcquisitionListTabWrapper';
import { useFinancierScPartnerAcquisitionListPageState } from './useFinancierScPartnerAcquisitionListPageState';

function FinancierScPartnerAcquisitionListPage() {
  const { t } = useTranslation(['format']);
  const mounted = useMounted();
  const history = useHistory();

  const { state, fetchAll, useForms, pageableObjects, fetchUtils } = useFinancierScPartnerAcquisitionListPageState();

  const { submittedListResult, inProgressListResult, rejectedListResult, completedListResult } = state;
  const { submittedListPageable, inProgressListPageable, rejectedListPageable, completedListPageable } =
    pageableObjects;

  const { submittedListSearchForm, inProgressListSearchForm, rejectedListSearchForm, completedListSearchForm } =
    useForms;

  useEffect(() => {
    if (mounted) {
      fetchAll();
    }

    return () => {};
  }, [mounted]);

  const submittedListSearchFormMethods = submittedListSearchForm;
  const inProgressListSearchFormMethods = inProgressListSearchForm;
  const rejectedListSearchFormMethods = rejectedListSearchForm;
  const completedListSearchFormMethods = completedListSearchForm;

  return (
    <>
      <HeaderTitle title={t('text:Acquisition')} />
      <GuideMessage
        message={[
          t('text:On_this_page_view_the_information_submitted_by_Uploaded_Partners_seeking_to_use_Vendor_Financing'),
          t('text:Click_on_the_arrow_button_to_view_the_submission_details'),
          t('text:Click_on_the_button_on_the_right_to_register_Acquisition_Settings'),
        ]}
      >
        {{
          button: (
            <Button
              onClick={() => {
                history.push(ROUTES_FI.MANAGE_UPLOADED_PARTNER.ACQUISITION_SETTING);
              }}
            >
              {t('text:Acquisition_Settings')}
            </Button>
          ),
        }}
      </GuideMessage>
      <Tab
        initialActiveTabIndex={1}
        tabList={[
          {
            tabName: t('text:Submitted'),
            tabItemCount: submittedListResult.totalElements ?? 0,
          },
          {
            tabName: t('text:In_Progress'),
            tabItemCount: inProgressListResult.totalElements ?? 0,
          },
          {
            tabName: t('text:Rejected'),
            tabItemCount: rejectedListResult.totalElements ?? 0,
          },
          {
            tabName: t('text:Acquisition_Completed'),
            tabItemCount: completedListResult.totalElements ?? 0,
          },
        ]}
        tabViewList={[
          <FormProvider {...submittedListSearchFormMethods} key="submitted">
            <FinancierScPartnerAcquisitionListTabWrapper
              data={submittedListResult}
              tabType="submitted"
              pageable={submittedListPageable}
              listFetchUtils={fetchUtils.submittedListFetchUtils}
            />
          </FormProvider>,
          <FormProvider {...inProgressListSearchFormMethods} key="inProgress">
            <FinancierScPartnerAcquisitionListTabWrapper
              data={inProgressListResult}
              tabType="inProgress"
              pageable={inProgressListPageable}
              listFetchUtils={fetchUtils.inProgressListFetchUtils}
            />
          </FormProvider>,
          <FormProvider {...rejectedListSearchFormMethods} key="rejected">
            <FinancierScPartnerAcquisitionListTabWrapper
              data={rejectedListResult}
              tabType="rejected"
              pageable={rejectedListPageable}
              listFetchUtils={fetchUtils.rejectedListFetchUtils}
            />
          </FormProvider>,
          <FormProvider {...completedListSearchFormMethods} key="completed">
            <FinancierScPartnerAcquisitionListTabWrapper
              data={completedListResult}
              tabType="completed"
              pageable={completedListPageable}
              listFetchUtils={fetchUtils.completedListFetchUtils}
            />
          </FormProvider>,
        ]}
      />
    </>
  );
}

export default FinancierScPartnerAcquisitionListPage;
