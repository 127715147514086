import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Button, { ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import DownloadButton from 'components/stateless/Button/DownloadButton';
import IconButton from 'components/stateless/Button/IconButton';
import { FormBorder } from 'components/stateless/CommonForm/FormBorder';
import { FormContents } from 'components/stateless/CommonForm/FormContents';
import FormInput from 'components/stateless/CommonForm/FormInput';
import { BackGroundType } from 'components/stateless/CommonForm/FormSubtitle';
import FormRadio from 'components/stateless/CommonForm/form-radio/FormRadio';
import { FormRadioWrap } from 'components/stateless/CommonForm/form-radio/FormRadioWrap';
import { AUTHORITY_TYPE } from 'enums';
import type { PotentialPartnerAcquisitionDocumentVOModel } from 'models/vo/PotentialPartnerAcquisitionDocumentVO';
import type { ClearErrors, SetError } from 'utils/error/manager';
import { requestFinancierPotentialPartnerAcquisitionDocumentDownload } from 'utils/http/api/financier/potential-partner-acquisition-documents';
import type { FinancierPotentialPartnerAcquisitionDocumentRegisterRequest } from 'utils/http/api/financier/potential-partner-acquisition-documents/request';
import { ModalType } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';
import { getSignIn } from 'utils/storage/LocalStorage';
import { hasValue } from 'utils/valueManager/ValueManager';

import type { PPAcquisitionDocumentType } from '../../../useFinancierSCPartnerAcquisitionSettingState';

interface DocumentFormProps {
  formKey: string;
  data: PPAcquisitionDocumentType;
  index: number;
  activateDefaultValue: boolean;
  updateEditable(isActivatedList: boolean, targetIndex: number, isEditable: boolean): void;
  saveRow(
    index: number,
    data: FinancierPotentialPartnerAcquisitionDocumentRegisterRequest,
    setError: SetError<FinancierPotentialPartnerAcquisitionDocumentRegisterRequest>,
    clearErrors: ClearErrors<FinancierPotentialPartnerAcquisitionDocumentRegisterRequest>,
  ): Promise<PotentialPartnerAcquisitionDocumentVOModel | void>;
  deleteRow(index: number, potentialPartnerAcquisitionDocumentSettingId?: number): Promise<void>;
}

function documentForm({
  data,
  index,
  saveRow,
  deleteRow,
  updateEditable,
  formKey,
  activateDefaultValue,
}: DocumentFormProps) {
  const signInInfo = getSignIn();
  const isAdmin = signInInfo?.authorityType === AUTHORITY_TYPE.ADMIN;
  const modal = useModal();

  const { t } = useTranslation(['format']);

  const { register, reset, getValues, setValue, errors, setError, clearErrors } =
    useForm<FinancierPotentialPartnerAcquisitionDocumentRegisterRequest>();

  const [fileName, setFileName] = useState<string>();

  const { data: formData, isEditable } = data;

  const resetFormData = useCallback(
    (data: PotentialPartnerAcquisitionDocumentVOModel) => {
      // include fileName
      setFileName(data.attachmentName);
      reset({
        name: data.documentName,
        description: data.description,
        activate: String(data.activate),
        potentialPartnerAcquisitionDocumentSettingId: data.potentialPartnerAcquisitionDocumentSettingId ?? '',
        attachmentChanged: false,
      });
    },
    [reset],
  );

  useEffect(() => {
    if (formData) resetFormData(formData);
  }, [formData, resetFormData]);

  const onChangeFile = (e: any) => {
    const changedFileName = e?.target?.files[0]?.name;
    if (changedFileName) {
      setValue(`attachmentChanged`, true);
      setFileName(changedFileName);
    }
  };

  const onClickRemoveIcon = (e: any) => {
    e.preventDefault();
    setValue('attachment', null);
    setValue(`attachmentChanged`, true);
    setFileName('');
  };

  const onClickEdit = () => {
    updateEditable(activateDefaultValue, index, true);
  };

  const onClickCancel = () => {
    const { potentialPartnerAcquisitionDocumentSettingId } = getValues();
    modal.show(
      <h6>
        {t('text:Are_you_sure_you_want_to_cancel?')}
        <br />
        {t('text:If_cancelled_the_information_you_entered_will_not_be_saved')}
      </h6>,
      {
        modalType: ModalType.CONFIRM,
        confirmBtnCb: () => {
          if (hasValue(potentialPartnerAcquisitionDocumentSettingId)) {
            resetFormData(formData);
            updateEditable(activateDefaultValue, index, false);
          } else {
            reset({
              activate: String(activateDefaultValue),
            });
          }
        },
      },
    );
  };

  const onClickSave = async () => {
    const response = await saveRow(index, getValues(), setError, clearErrors);

    if (response) {
      modal.show(<h6>{t('text:The_information_has_been_saved_successfully')}</h6>, {
        modalType: ModalType.ALERT,
      });
    }
  };

  const onClickDelete = () => {
    const { potentialPartnerAcquisitionDocumentSettingId } = getValues();

    modal.show(
      <h6>
        {t('text:Delete_the_document_type?')}
        <br />
        {t('text:You_will_have_to_register_the_document_type_again_for_Uploaded_Partners_to_submit_these_documents')}
      </h6>,
      {
        modalType: ModalType.CONFIRM,
        title: t('text:Delete_Document_Type'),
        confirmBtnCb: () => {
          deleteRow(index, potentialPartnerAcquisitionDocumentSettingId);
        },
      },
    );
  };

  const onClickDownloadDocument = async (potentialPartnerAcquisitionDocumentSettingId: number) => {
    try {
      await requestFinancierPotentialPartnerAcquisitionDocumentDownload(potentialPartnerAcquisitionDocumentSettingId);
    } catch (e) {
      modal.show(e);
    }
  };

  return (
    <form>
      {isAdmin && (
        <div className="mb-3 text-end">
          {isEditable ? (
            <>
              <Button
                size={ButtonSizeEnum.SM}
                variant={ButtonVariantEnum.OUTLINED}
                color={ButtonColorEnum.SECONDARY}
                onClick={onClickCancel}
              >
                {t('text:Cancel')}
              </Button>
              <Button size={ButtonSizeEnum.SM} onClick={onClickSave} style={{ width: '60px' }} className="ms-2">
                {t('text:Save')}
              </Button>
            </>
          ) : (
            <>
              <Button
                size={ButtonSizeEnum.SM}
                variant={ButtonVariantEnum.OUTLINED}
                color={ButtonColorEnum.SECONDARY}
                onClick={onClickDelete}
              >
                {t('text:Delete')}
              </Button>
              <Button
                size={ButtonSizeEnum.SM}
                onClick={(e: any) => {
                  e.preventDefault();
                  onClickEdit();
                }}
                className="ms-2"
                style={{ width: '60px' }}
              >
                {t('text:Edit')}
              </Button>
            </>
          )}
        </div>
      )}
      <fieldset className="mb-3">
        <FormBorder>
          <FormContents>
            <div className="row">
              <input type="hidden" name="potentialPartnerAcquisitionDocumentSettingId" ref={register} />
              <FormInput
                label={t('text:Document_Name')}
                name="name"
                requiredOptions={{ required: true }}
                ref={register}
                col={6}
                disabled={!isEditable}
                error={errors.name}
              />
              <FormRadioWrap label={t('text:Hide_from_view?')} error={errors.activate}>
                <FormRadio
                  label={t('text:Show')}
                  value="true"
                  name="activate"
                  ref={register}
                  disabled={!isEditable}
                  id={`${formKey}-activate-true`}
                />
                <FormRadio
                  label={t('text:Hide')}
                  value="false"
                  name="activate"
                  ref={register}
                  disabled={!isEditable}
                  id={`${formKey}-activate-false`}
                />
              </FormRadioWrap>
            </div>
            <div className="row">
              <FormInput
                label={t('text:Document_Description')}
                name="description"
                ref={register}
                col={12}
                disabled={!isEditable}
                error={errors.description}
              />
            </div>
          </FormContents>
          <FormContents backGroundType={BackGroundType.WHITE}>
            <div className="row">
              <div className="col-6 align-self-center">
                <div className="d-flex align-items-center">
                  <input type="hidden" name="attachmentChanged" ref={register} />
                  <input
                    type="file"
                    id={`${formKey}-file-id`}
                    name="attachment"
                    className="d-none"
                    onChange={e => onChangeFile(e)}
                    ref={register}
                    disabled={!isEditable}
                  />
                  <label
                    htmlFor={`${formKey}-file-id`}
                    className={isEditable ? 'attach-file-link-button' : 'attach-file-link-button-disable'}
                  >
                    {t('text:Attach_File')}
                  </label>
                  <div id="fileName" className="ms-3 w-100 ">
                    <div>{fileName ? fileName : t('text:No_file_attached')}</div>
                  </div>
                  {isEditable && fileName && (
                    <div className="ms-3">
                      <IconButton onClick={onClickRemoveIcon} className="delete-uploaded-excel-button">
                        <FontAwesomeIcon icon={faTimesCircle} />
                      </IconButton>
                    </div>
                  )}
                </div>
              </div>

              <div className="col-6">
                <div className="d-flex justify-content-end">
                  <DownloadButton
                    onClick={async e => {
                      e.preventDefault();
                      await onClickDownloadDocument(getValues().potentialPartnerAcquisitionDocumentSettingId);
                    }}
                    disabled={isEditable || !formData.attachmentName}
                  />
                </div>
              </div>
            </div>
          </FormContents>
        </FormBorder>
      </fieldset>
    </form>
  );
}

export default documentForm;
