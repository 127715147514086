import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Button, { ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import Exporter from 'components/stateless/Exporter/Exporter';
import Pagination from 'components/stateless/Pagination/Pagination';
import SearchBorder from 'components/stateless/SearchForm/SearchBorder';
import SearchDatePicker from 'components/stateless/SearchForm/SearchDatePicker';
import SearchEmpty from 'components/stateless/SearchForm/SearchEmpty';
import SearchInput from 'components/stateless/SearchForm/SearchInput';
import SearchLabel from 'components/stateless/SearchForm/SearchLabel';
import { TableBody, TableBorder, TableHeader, Td, TdLink, Tr } from 'components/stateless/Table';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { ROUTES_AC } from 'constants/routes/anchor';
import type { PageableType } from 'hooks/usePageable';
import useProperty from 'hooks/useProperty';
import type Pageable from 'models/Pageable';
import type { AnchorPartnerVOModel } from 'models/vo/AnchorPartnerVO';
import type { PDFExporterProps } from 'utils/exportFile/exportPDF.d';
import { requestAnchorPartnersPage } from 'utils/http/api/anchor/anchor-partners';
import type { AnchorPartnersPageRequest } from 'utils/http/api/anchor/anchor-partners/requests';
import useModal from 'utils/modal/useModal';
import type { ColumnOption, ExportSpreadSheetProps } from 'utils/spreadSheet/types';
import { tableValueManage } from 'utils/valueManager/ValueManager';

type CompleteViewProps = {
  completeViewStates: {
    anchorPartnerPage?: Pageable<AnchorPartnerVOModel[]>;
    anchorPartnerPageable: PageableType;
  };
  completeViewUtils: {
    onClickCompleteViewSearch: (e: any) => Promise<void>;
    completePaginate: (page: number, sizePerPage: number) => Promise<void>;
  };
};

const CompleteView = ({ completeViewStates, completeViewUtils }: CompleteViewProps) => {
  const { t } = useTranslation();
  const modal = useModal();
  const getAnchorPartnerProperty = useProperty<AnchorPartnersPageRequest>();
  const { getValues, reset, register, control } = useFormContext();

  const { anchorPartnerPage, anchorPartnerPageable } = completeViewStates;

  const { onClickCompleteViewSearch, completePaginate } = completeViewUtils;

  const renderRegistrationConfirmedSearchTable = (): JSX.Element => {
    const onClickRemoveFilter = (e: any) => {
      e.preventDefault();

      reset({});
    };

    return (
      <form>
        <SectionTitle title={t('text:Search')}>
          <Button variant={ButtonVariantEnum.OUTLINED} color={ButtonColorEnum.SECONDARY} onClick={onClickRemoveFilter}>
            {t('text:Remove_Filter')}
          </Button>
        </SectionTitle>
        <SearchBorder>
          <div className="row">
            <SearchLabel label={t('text:Registration_Date')} />
            <SearchDatePicker
              placeholder={t('text:from')}
              name={getAnchorPartnerProperty('fromDate')}
              control={control}
            />
            <SearchDatePicker placeholder={t('text:to')} name={getAnchorPartnerProperty('toDate')} control={control} />
            <SearchLabel label={t('text:Partner_Name')} />
            <SearchInput name={getAnchorPartnerProperty('partnerName')} ref={register} />
          </div>
          <div className="row">
            <SearchLabel label={t('text:Partner_Tax_Code')} />
            <SearchInput name={getAnchorPartnerProperty('partnerTaxCode')} ref={register} />
            <SearchLabel label={t('text:Legal_Representative_Name')} />
            <SearchInput name={getAnchorPartnerProperty('representativeName')} ref={register} />
          </div>
          <div className="row">
            <SearchLabel label={t('text:Telephone')} />
            <SearchInput name={getAnchorPartnerProperty('partnerTelephone')} ref={register} />
            <SearchEmpty />
          </div>
        </SearchBorder>
        <div className="flex-center mt-3">
          <Button size={ButtonSizeEnum.LG} onClick={onClickCompleteViewSearch}>
            {t('text:Search')}
          </Button>
        </div>
      </form>
    );
  };

  const renderRegistrationConfirmedTable = (): JSX.Element => {
    const onClickConfirmedViewExportButton = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.preventDefault();

      const CONFIRMED_VIEW_EXCEL_COLUMNS: ColumnOption<AnchorPartnerVOModel>[] = [
        {
          header: t('text:Registration_Date'),
          key: 'createdDateTime',
          width: 25,
        },
        {
          header: t('text:Partner_Name'),
          key: 'name',
          width: 20,
        },
        {
          header: t('text:Partner_Tax_Code'),
          key: 'partnerTaxCode',
          width: 30,
        },
        {
          header: t('text:Registered_Office_Address'),
          key: 'address',
          width: 30,
        },
        {
          header: t('text:Legal_Representative_Name'),
          key: 'representativeName',
          width: 30,
        },
        {
          header: t('text:Legal_Representative_Email'),
          key: 'representativeEmail',
          width: 30,
        },
        {
          header: t('text:Telephone'),
          key: 'telephone',
          width: 20,
        },
      ];

      const EXCEL_EXPORT_MAX_ROW_COUNT = 1000;
      const PDF_EXPORT_MAX_ROW_COUNT = 150;

      const renderPDFTableBodyResult = (data?: AnchorPartnerVOModel[]) => {
        return data?.map((item, index) => (
          <tr key={index} className="virtual-table-row">
            <Td data={item.createdDateTime} format="datetime" />
            <Td data={item.name} />
            <Td data={item.partnerTaxCode} />
            <Td data={item.address} />
            <Td data={item.representativeName} />
            <Td data={item.representativeEmail} />
            <Td data={item.telephone} />
          </tr>
        ));
      };

      const confirmedSearchFormValues = getValues();

      try {
        const [confirmedAnchorPartnerExcelPage, confirmedAnchorPartnerPDFPage] = await Promise.all([
          requestAnchorPartnersPage(0, EXCEL_EXPORT_MAX_ROW_COUNT, confirmedSearchFormValues),
          requestAnchorPartnersPage(0, PDF_EXPORT_MAX_ROW_COUNT, confirmedSearchFormValues),
        ]);

        const jsonArrayData = confirmedAnchorPartnerExcelPage.content.map(confirmedAnchorPartnerData => ({
          createdDateTime: tableValueManage(
            confirmedAnchorPartnerData.createdDateTime,
            t('format:datetime', {
              value: confirmedAnchorPartnerData.createdDateTime,
              key: 'datetime',
            }),
          ),
          name: tableValueManage(confirmedAnchorPartnerData.name),
          partnerTaxCode: tableValueManage(confirmedAnchorPartnerData.partnerTaxCode),
          address: tableValueManage(confirmedAnchorPartnerData.address),
          representativeName: tableValueManage(confirmedAnchorPartnerData.representativeName),
          representativeEmail: tableValueManage(confirmedAnchorPartnerData.representativeEmail),
          telephone: tableValueManage(confirmedAnchorPartnerData.telephone),
        }));

        const excelExporterProps: ExportSpreadSheetProps<AnchorPartnerVOModel> = {
          jsonArrayData: jsonArrayData,
          columns: CONFIRMED_VIEW_EXCEL_COLUMNS,
          options: {
            rowHeight: [{ position: 1, height: 30 }],
          },
        };

        const pdfExporterProps: PDFExporterProps = {
          tableHeaders: getRegistrationConfirmedTableHeaders(true),
          tableBody: renderPDFTableBodyResult(confirmedAnchorPartnerPDFPage.content),
        };

        modal.show(<Exporter spreadSheetExporterProps={excelExporterProps} pdfExporterProps={pdfExporterProps} />, {
          title: t('text:Export_File'),
          closeBtnText: t('text:Close'),
        });
      } catch (error) {
        modal.show(error);
      }
    };

    const getRegistrationConfirmedTableHeaders = (isPDFHeader = false) => {
      const headers = [
        {
          headerText: t('text:Registration_Date'),
          colWidths: 100,
        },
        {
          headerText: t('text:Partner_Name'),
          colWidths: 100,
        },
        {
          headerText: t('text:Partner_Tax_Code'),
          colWidths: 120,
        },
        {
          headerText: t('text:Registered_Office_Address'),
          colWidths: 100,
        },
        {
          headerText: t('text:Legal_Representative_Name'),
          colWidths: 100,
        },
        {
          headerText: t('text:Legal_Representative_Email'),
          colWidths: 100,
        },
        {
          headerText: t('text:Telephone'),
          colWidths: 100,
        },
      ];

      // isPDFHeader가 true이면 마지막 항목을 제외합니다.
      return isPDFHeader ? headers : [...headers, { headerText: '', colWidths: 50 }];
    };

    const renderResultTable = (): JSX.Element[] | undefined => {
      return anchorPartnerPage?.content.map((item, index) => (
        <Tr key={index}>
          <Td data={item.createdDateTime} format="datetime" />
          <Td data={item.name} />
          <Td data={item.partnerTaxCode} />
          <Td data={item.address} />
          <Td data={item.representativeName} />
          <Td data={item.representativeEmail} />
          <Td data={item.telephone} />
          <TdLink path={ROUTES_AC.MANAGE_PARTNER.REGISTRATION_CONFIRMED_DETAIL_BUILD_PATH(item.anchorPartnerId)} />
        </Tr>
      ));
    };

    return (
      <>
        <SectionTitle title={t('text:Result')} />
        <div className="d-flex mb-2">
          <p className="total-data me-auto">
            {t('text:Total')} {anchorPartnerPage?.totalElements}
          </p>
          <Button onClick={onClickConfirmedViewExportButton} variant={ButtonVariantEnum.OUTLINED}>
            {t('text:Export')}
          </Button>
        </div>
        <TableBorder>
          <TableHeader header={getRegistrationConfirmedTableHeaders()} />
          <TableBody numOfCol={getRegistrationConfirmedTableHeaders().length}>{renderResultTable()}</TableBody>
        </TableBorder>
        <Pagination pageable={anchorPartnerPageable} paginate={completePaginate} />
      </>
    );
  };

  return (
    <>
      <div className="content-area">{renderRegistrationConfirmedSearchTable()}</div>
      <div className="division-border" />
      <div className="content-area">{renderRegistrationConfirmedTable()}</div>
    </>
  );
};

export default CompleteView;
