import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';

import Button, { ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import Exporter from 'components/stateless/Exporter/Exporter';
import Pagination from 'components/stateless/Pagination/Pagination';
import SearchBorder from 'components/stateless/SearchForm/SearchBorder';
import SearchDatePicker from 'components/stateless/SearchForm/SearchDatePicker';
import SearchEmpty from 'components/stateless/SearchForm/SearchEmpty';
import SearchInput from 'components/stateless/SearchForm/SearchInput';
import SearchLabel from 'components/stateless/SearchForm/SearchLabel';
import SearchSelect from 'components/stateless/SearchForm/SearchSelect';
import TableBody from 'components/stateless/Table/TableBody';
import TableBorder from 'components/stateless/Table/TableBorder';
import TableHeader from 'components/stateless/Table/TableHeader';
import Td from 'components/stateless/Table/Td';
import TdLink from 'components/stateless/Table/TdLink';
import Tr from 'components/stateless/Table/Tr';
import { HeaderTitle } from 'components/stateless/Title/HeaderTitle';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { ROUTES_SY } from 'constants/routes/system';
import getSelectOptions from 'constants/selectOptions';
import type { COLLATERAL_TYPE, CURRENCY_TYPE } from 'enums';
import { FINANCIER_INTERFACE_TYPE } from 'enums';
import useMounted from 'hooks/useMounted';
import usePageable from 'hooks/usePageable';
import type Pageable from 'models/Pageable';
import type { LoanVOModel } from 'models/vo/LoanVO';
import getStatusTextClass from 'utils/classNames/getStatusTextClass';
import type { PDFExporterProps } from 'utils/exportFile/exportPDF.d';
import { setFormValues } from 'utils/form/setFormValues';
import type { FinancierLoanListRequest } from 'utils/http/api/financier/loans/request';
import { requestSystemLoanList } from 'utils/http/api/system/loans';
import type { SystemLoanListRequest } from 'utils/http/api/system/loans/requests';
import useModal from 'utils/modal/useModal';
import { getParsedSearchParams, updateSearchParams } from 'utils/searchParams';
import type { ExportSpreadSheetProps } from 'utils/spreadSheet/types';
import { checkInterfaceType } from 'utils/storage/LocalStorage';
import { getFilteredDisbursedAmount, tableValueManage } from 'utils/valueManager/ValueManager';

import { useSystemFinancingListConstants } from './constans';

function SystemFinancingList() {
  const mounted = useMounted();
  const modal = useModal();

  const {
    t,
    EXCEL_EXPORT_MAX_ROW_COUNT,
    PDF_EXPORT_MAX_ROW_COUNT,
    SY_FINANCING_LIST_QS_KEY,
    checkBoxOptions,
    SY_LOAN_LIST_TABLE_HEADER,
    pdfTableHeaders,
    excelColumns,
  } = useSystemFinancingListConstants();

  const {
    LOAN_STATUS_CHECK_BOX_OPTIONS,
    LOAN_APPROVAL_TYPE_CHECK_BOX_OPTIONS,
    LOAN_APPROVAL_TYPE_WITH_INTERFACE_CHECK_BOX_OPTIONS,
    NUM_OF_CHECKBOX_LOAN_STATUS,
    NUM_OF_CHECKBOX_LOAN_APPROVAL_TYPE,
    NUM_OF_CHECKBOX_LOAN_APPROVAL_TYPE_INTERFACE,
  } = checkBoxOptions;

  const [isInterface] = useState<boolean>(checkInterfaceType(FINANCIER_INTERFACE_TYPE.LOAN_RESULT));
  const { pageable, setPageable } = usePageable(SY_FINANCING_LIST_QS_KEY);

  const { register, getValues, reset, watch, setValue, control } = useForm<SystemLoanListRequest>();

  const { loanStatusConditions: loanStatusWatchCheckbox, loanApprovalConditions: loanApprovalTypeWatchCheckbox } =
    watch();

  const [loanPageData, setLoanPageData] = useState<Pageable<LoanVOModel[]>>();
  const location = useLocation<FinancierLoanListRequest>();
  const filteredOptions = location.state;

  useEffect(() => {
    if (mounted) {
      setFormValues<SystemLoanListRequest>(
        setValue,
        filteredOptions ? filteredOptions : getParsedSearchParams(SY_FINANCING_LIST_QS_KEY).formSearchData,
      );
      fetchSyLoanList(pageable.currentPage, pageable.sizePerPage, filteredOptions ? filteredOptions : getValues());
    }
  }, [mounted]);

  const fetchSyLoanList = async (
    pageNumber: number = 1,
    sizePerPage: number = 10,
    searchData: SystemLoanListRequest = {},
  ): Promise<void> => {
    try {
      const loanPageData = await requestSystemLoanList(pageNumber, sizePerPage, searchData);
      updateSearchParams(
        {
          ...searchData,
          pageNumber,
          rowCount: sizePerPage,
        },
        SY_FINANCING_LIST_QS_KEY,
      );

      ReactDOM.unstable_batchedUpdates(() => {
        setLoanPageData(loanPageData);
        setPageable(loanPageData);
      });
    } catch (error) {
      modal.show(error);
    }
  };

  const onClickRemoveFilter = (e: any) => {
    e.preventDefault();

    reset({});
  };

  const onClickSearch = (e: any) => {
    e.preventDefault();
    fetchSyLoanList(1, pageable.sizePerPage, getValues());
  };

  const paginate = (selectedPageNumber: number, selectedRowCount: number): void => {
    fetchSyLoanList(
      selectedPageNumber,
      selectedRowCount,
      getParsedSearchParams(SY_FINANCING_LIST_QS_KEY).formSearchData,
    );
  };

  const onClickExportButton = async (e: any) => {
    e.preventDefault();
    const searchData = getParsedSearchParams(SY_FINANCING_LIST_QS_KEY).formSearchData;

    const renderPDFTableBodyResult = (data?: LoanVOModel[]): JSX.Element[] | undefined => {
      return data?.map((item, index) => (
        <tr key={index} className="virtual-table-row">
          <Td data={t(`code:collateral-type.${item.collateralType}`)} format="code" />
          <Td data={item.loanId} />
          <Td data={item.financierName} />
          <Td data={item.anchorClientName} />
          <Td data={item.dealerClientName} />
          <Td data={item.currencyType} />
          <Td data={item.invoiceNumber} />
          <Td data={item.arNumber} />
          <Td data={item.requestedDateTime} format="date" />
          <Td data={item.desiredDisburseDate} format="date" />
          <Td data={item.disbursedDate} format="date" />
          <Td data={item.principalAmount} format="number" />
          <Td data={getFilteredDisbursedAmount(item.loanStatus, item.disbursedAmount)} format="number" />
          <Td data={item.netDisbursementAmount} format="number" />
          <Td>
            <>
              {t('format:date', {
                value: item.repaymentDate,
                key: 'date',
              })}
              <div className="text-bold-brick-red">{item.remainingFinancingTerm}</div>
            </>
          </Td>
          <Td data={item.repaidDate} format="date" />
          <Td data={item.repaidPrincipalAmount} format="number" />
          <Td data={item.outstandingBalance} format="number" />

          <Td
            data={t(`code:financing-status.${item.loanStatus}`)}
            className={getStatusTextClass('LOAN_STATUS', item.loanStatus)}
            format="code"
          />
          <Td
            data={t(`code:approval-status.${item.loanApprovalType}`)}
            className={getStatusTextClass('LOAN_APPROVAL_TYPE', item.loanApprovalType)}
            format="code"
          />
        </tr>
      ));
    };

    try {
      const [loanPageDataForExcel, loanPageDataForPDF] = await Promise.all([
        requestSystemLoanList(0, EXCEL_EXPORT_MAX_ROW_COUNT, searchData),
        requestSystemLoanList(0, PDF_EXPORT_MAX_ROW_COUNT, searchData),
      ]);
      const jsonArrayData: any[] = loanPageDataForExcel.content.map(loanData => ({
        collateralType: tableValueManage(t(`code:collateral-type.${loanData.collateralType}`)),
        loanId: tableValueManage(loanData.loanId),
        financierLoanId: tableValueManage(loanData.financierLoanId),
        financierName: tableValueManage(loanData.financierName),
        anchorClientName: tableValueManage(loanData.anchorClientName),
        anchorClientCode: tableValueManage(loanData.anchorClientCode),
        dealerClientName: tableValueManage(loanData.dealerClientName),
        dealerClientCode: tableValueManage(loanData.dealerClientCode),
        currencyType: tableValueManage(loanData.currencyType),
        billOfExchangeNo: tableValueManage(loanData.billOfExchangeNo),
        invoiceNumber: tableValueManage(loanData.invoiceNumber),
        referenceNumber: tableValueManage(loanData.referenceNumber),
        arNumber: tableValueManage(loanData.arNumber),
        requestedDateTime: t('format:original-date', {
          value: loanData.requestedDateTime,
          key: 'original-date',
        }),
        desiredDisburseDate: t('format:original-date', {
          value: loanData.desiredDisburseDate,
          key: 'original-date',
        }),
        disbursedDate: t('format:original-date', {
          value: loanData.disbursedDate,
          key: 'original-date',
        }),
        principalAmount: tableValueManage(loanData.principalAmount),
        disbursedAmount: tableValueManage(getFilteredDisbursedAmount(loanData.loanStatus, loanData.disbursedAmount)),
        netDisbursementAmount: tableValueManage(loanData.netDisbursementAmount),
        repaymentDate: t('format:original-date', {
          value: loanData.repaymentDate,
          key: 'original-date',
        }),
        remainingFinancingTerm: loanData.remainingFinancingTerm,
        repaidDate: t('format:original-date', {
          value: loanData.repaidDate,
          key: 'original-date',
        }),
        repaidPrincipalAmount: tableValueManage(loanData.repaidPrincipalAmount),
        outstandingBalance: tableValueManage(loanData.outstandingBalance),
        accruedInterestAmount: tableValueManage(loanData.accruedInterestAmount),
        outstandingAccruedInterestAmount: tableValueManage(loanData.outstandingAccruedInterestAmount),
        loanStatus: tableValueManage(loanData.loanStatus, t(`code:financing-status.${loanData.loanStatus}`)),
        loanApprovalType: tableValueManage(
          loanData.loanApprovalType,
          t(`code:approval-status.${loanData.loanApprovalType}`),
        ),
      }));

      const excelExporterProps: ExportSpreadSheetProps<any> = {
        jsonArrayData: jsonArrayData,
        columns: excelColumns,
        options: {
          rowHeight: [{ position: 1, height: 30 }],
        },
      };

      const pdfExporterProps: PDFExporterProps = {
        tableHeaders: pdfTableHeaders,
        tableBody: renderPDFTableBodyResult(loanPageDataForPDF.content),
      };

      modal.show(<Exporter spreadSheetExporterProps={excelExporterProps} pdfExporterProps={pdfExporterProps} />, {
        title: t('text:Export_File'),
        closeBtnText: t('text:Close'),
      });
    } catch (e) {
      modal.show(e);
    }
  };

  const renderLoanStatusCheckBoxList = (): JSX.Element[] => {
    const onChangeAllLoanStatus = (e: any) => {
      if (e.target.checked) {
        setValue('loanStatusConditions', LOAN_STATUS_CHECK_BOX_OPTIONS);
      } else {
        setValue('loanStatusConditions', []);
      }
    };

    return LOAN_STATUS_CHECK_BOX_OPTIONS.map((loanStatus, index) => (
      <div className="form-check-inline" key={index}>
        <input
          className="form-check-input"
          type="checkbox"
          value={loanStatus === 'ALL' ? '' : loanStatus}
          id={`flexCheckDefault${index}`}
          name="loanStatusConditions"
          onChange={loanStatus === 'ALL' ? onChangeAllLoanStatus : undefined}
          checked={
            loanStatus === 'ALL' ? loanStatusWatchCheckbox?.length === NUM_OF_CHECKBOX_LOAN_STATUS - 1 : undefined
          }
          ref={loanStatus === 'ALL' ? undefined : register}
        />
        <label className="form-check-label" htmlFor={`flexCheckDefault${index}`}>
          {loanStatus === 'ALL' ? t('text:All') : t(`code:financing-status.${loanStatus}`)}
        </label>
      </div>
    ));
  };

  const renderLoanApprovalCheckBoxList = (): JSX.Element[] => {
    const onChangeAllLoanApprovalWithInterface = (e: any) => {
      if (e.target.checked) {
        setValue('loanApprovalConditions', LOAN_APPROVAL_TYPE_WITH_INTERFACE_CHECK_BOX_OPTIONS);
      } else {
        setValue('loanApprovalConditions', []);
      }
    };

    const onChangeAllLoanApproval = (e: any) => {
      if (e.target.checked) {
        setValue('loanApprovalConditions', LOAN_APPROVAL_TYPE_CHECK_BOX_OPTIONS);
      } else {
        setValue('loanApprovalConditions', []);
      }
    };

    return isInterface
      ? LOAN_APPROVAL_TYPE_WITH_INTERFACE_CHECK_BOX_OPTIONS.map((loanApprovalTypeWithInterface, index) => (
          <div className="form-check-inline" key={index}>
            <input
              className="form-check-input"
              type="checkbox"
              name="loanApprovalConditions"
              value={loanApprovalTypeWithInterface === 'ALL' ? '' : loanApprovalTypeWithInterface}
              id={`loan-approval-with-interface-checkbox-${loanApprovalTypeWithInterface}`}
              onChange={loanApprovalTypeWithInterface === 'ALL' ? onChangeAllLoanApprovalWithInterface : undefined}
              checked={
                loanApprovalTypeWithInterface === 'ALL'
                  ? loanApprovalTypeWatchCheckbox?.length === NUM_OF_CHECKBOX_LOAN_APPROVAL_TYPE_INTERFACE - 1
                  : undefined
              }
              ref={loanApprovalTypeWithInterface === 'ALL' ? undefined : register}
            />
            <label
              className="form-check-label"
              htmlFor={`loan-approval-with-interface-checkbox-${loanApprovalTypeWithInterface}`}
            >
              {loanApprovalTypeWithInterface === 'ALL'
                ? t('text:All')
                : t(`code:approval-status.${loanApprovalTypeWithInterface}`)}
            </label>
          </div>
        ))
      : LOAN_APPROVAL_TYPE_CHECK_BOX_OPTIONS.map((loanApprovalType, index) => (
          <div className="form-check-inline" key={index}>
            <input
              className="form-check-input"
              type="checkbox"
              name="loanApprovalConditions"
              value={loanApprovalType === 'ALL' ? '' : loanApprovalType}
              id={`loan-approval-checkbox-${loanApprovalType}`}
              onChange={loanApprovalType === 'ALL' ? onChangeAllLoanApproval : undefined}
              checked={
                loanApprovalType === 'ALL'
                  ? loanApprovalTypeWatchCheckbox?.length === NUM_OF_CHECKBOX_LOAN_APPROVAL_TYPE - 1
                  : undefined
              }
              ref={loanApprovalType === 'ALL' ? undefined : register}
            />
            <label className="form-check-label" htmlFor={`loan-approval-checkbox-${loanApprovalType}`}>
              {loanApprovalType === 'ALL' ? t('text:All') : t(`code:approval-status.${loanApprovalType}`)}
            </label>
          </div>
        ));
  };

  const renderResultTable = (): JSX.Element[] | undefined => {
    return loanPageData && loanPageData.content && loanPageData.content.length !== 0
      ? loanPageData.content.map((item, index) => (
          <Tr key={index}>
            <Td data={t(`code:collateral-type.${item.collateralType}`)} format="code" />
            <Td data={item.loanId} />
            <Td data={item.financierName} />
            <Td data={item.anchorClientName} />
            <Td data={item.dealerClientName} />
            <Td data={item.currencyType} />
            <Td data={item.invoiceNumber} />
            <Td data={item.arNumber} />
            <Td data={item.requestedDateTime} format="date" />
            <Td data={item.desiredDisburseDate} format="date" />
            <Td data={item.disbursedDate} format="date" />
            <Td data={item.principalAmount} format="number" />
            <Td data={getFilteredDisbursedAmount(item.loanStatus, item.disbursedAmount)} format="number" />
            <Td data={item.netDisbursementAmount} format="number" />
            <Td>
              <>
                {t('format:date', {
                  value: item.repaymentDate,
                  key: 'date',
                })}
                <div className="text-bold-brick-red">{item.remainingFinancingTerm}</div>
              </>
            </Td>
            <Td data={item.repaidDate} format="date" />
            <Td data={item.repaidPrincipalAmount} format="number" />
            <Td data={item.outstandingBalance} format="number" />

            <Td
              data={t(`code:financing-status.${item.loanStatus}`)}
              className={getStatusTextClass('LOAN_STATUS', item.loanStatus)}
              format="code"
            />
            <Td
              data={t(`code:approval-status.${item.loanApprovalType}`)}
              className={getStatusTextClass('LOAN_APPROVAL_TYPE', item.loanApprovalType)}
              format="code"
            />
            <TdLink
              path={ROUTES_SY.VIEW_TRANSACTION.FINANCING_DETAIL_BUILD_PATH(item.loanId)}
              state={{
                successInvoiceId: item.successInvoiceId,
                successArId: item.successArId,
                collateralType: item.collateralType,
              }}
            />
          </Tr>
        ))
      : undefined;
  };

  return (
    <>
      <HeaderTitle title={t('text:Financing')} />
      <div className="content-area">
        <form>
          <SectionTitle title={t('text:Search')}>
            <Button
              variant={ButtonVariantEnum.OUTLINED}
              color={ButtonColorEnum.SECONDARY}
              onClick={onClickRemoveFilter}
            >
              {t('text:Remove_Filter')}
            </Button>
          </SectionTitle>
          <SearchBorder>
            <div className="row">
              <SearchLabel label={t('text:Platform_Financing_ID')} />
              <SearchInput name="loanId" ref={register} />
              <SearchLabel label={t('text:Applied_Date')} />
              <SearchDatePicker placeholder={t('text:from')} name="requestedDateFrom" control={control} />
              <SearchDatePicker placeholder={t('text:to')} name="requestedDateTo" control={control} />
            </div>
            <div className="row">
              <SearchLabel label={t('text:Program_Type')} />
              <SearchSelect
                name="collateralType"
                selectOptions={getSelectOptions<COLLATERAL_TYPE>('COLLATERAL_TYPE', 'ALL', true)}
                ref={register}
              />
              <SearchLabel label={t('text:Scheduled_Disbursement_Date')} />
              <SearchDatePicker placeholder={t('text:from')} name="desiredDisbursedDateFrom" control={control} />
              <SearchDatePicker placeholder={t('text:to')} name="desiredDisbursedDateTo" control={control} />
            </div>
            <div className="row">
              <SearchLabel label={t('text:Financier_Name')} />
              <SearchInput name="financierName" ref={register} />
              <SearchLabel label={t('text:Disbursed_Date')} />
              <SearchDatePicker placeholder={t('text:from')} name="disbursedDateFrom" control={control} />
              <SearchDatePicker placeholder={t('text:to')} name="disbursedDateTo" control={control} />
            </div>
            <div className="row">
              <SearchLabel label={t('text:Associated_Anchor_Name')} />
              <SearchInput name="anchorClientName" ref={register} />
              <SearchLabel label={t('text:Scheduled_Repayment_Date')} />
              <SearchDatePicker placeholder={t('text:from')} name="repaymentDateFrom" control={control} />
              <SearchDatePicker placeholder={t('text:to')} name="repaymentDateTo" control={control} />
            </div>
            <div className="row">
              <SearchLabel label={t('text:Partner_Name')} />
              <SearchInput name="dealerClientName" ref={register} />
              <SearchLabel label={t('text:Repaid_Date')} />
              <SearchDatePicker placeholder={t('text:from')} name="repaidDateFrom" control={control} />
              <SearchDatePicker placeholder={t('text:to')} name="repaidDateTo" control={control} />
            </div>
            <div className="row">
              <SearchLabel label={t('text:Invoice_Number')} />
              <SearchInput name="invoiceNumber" ref={register} />
              <SearchLabel label={t('text:Currency')} />
              <SearchSelect
                selectOptions={getSelectOptions<CURRENCY_TYPE>('CURRENCY_TYPE', 'ALL', true)}
                name="currencyType"
                ref={register}
              />
            </div>
            <div className="row">
              <SearchLabel label={t('text:AR_Number')} />
              <SearchInput name="arNumber" ref={register} />
              <SearchEmpty />
            </div>

            <div className="row">
              <SearchLabel className="search-form__label-wrap auto-height col-2" label={t('text:Financing_Status')} />
              <div className="col-10">
                <div className="checkbox-form">{renderLoanStatusCheckBoxList()}</div>
              </div>
            </div>
            <div className="row">
              <SearchLabel className="search-form__label-wrap auto-height col-2" label={t('text:Approval_Status')} />
              <div className="col-10">
                <div className="checkbox-form">{renderLoanApprovalCheckBoxList()}</div>
              </div>
            </div>
          </SearchBorder>
          <div className="flex-center mt-4">
            <Button size={ButtonSizeEnum.LG} onClick={onClickSearch}>
              {t('text:Search')}
            </Button>
          </div>
        </form>
      </div>
      <div className="division-border" />
      <div className="content-area">
        <SectionTitle title={t('text:Result')} />
        <div className="d-flex mb-2">
          <p className="total-data me-auto">
            {t('text:Total')} {loanPageData?.totalElements ? loanPageData?.totalElements : '0'}
          </p>
          <Button onClick={onClickExportButton} variant={ButtonVariantEnum.OUTLINED}>
            {t('text:Export')}
          </Button>
        </div>
        <TableBorder>
          <TableHeader header={SY_LOAN_LIST_TABLE_HEADER} />
          <TableBody numOfCol={SY_LOAN_LIST_TABLE_HEADER.length}>{renderResultTable()}</TableBody>
        </TableBorder>
        <Pagination pageable={pageable} paginate={paginate} />
      </div>
    </>
  );
}

export default SystemFinancingList;
