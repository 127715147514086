import type React from 'react';
import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Button, { ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import Exporter from 'components/stateless/Exporter/Exporter';
import Pagination from 'components/stateless/Pagination/Pagination';
import SearchBorder from 'components/stateless/SearchForm/SearchBorder';
import SearchDatePicker from 'components/stateless/SearchForm/SearchDatePicker';
import SearchEmpty from 'components/stateless/SearchForm/SearchEmpty';
import SearchInput from 'components/stateless/SearchForm/SearchInput';
import SearchLabel from 'components/stateless/SearchForm/SearchLabel';
import TableBody from 'components/stateless/Table/TableBody';
import TableBorder from 'components/stateless/Table/TableBorder';
import type { HeaderType } from 'components/stateless/Table/TableHeader';
import TableHeader from 'components/stateless/Table/TableHeader';
import Td from 'components/stateless/Table/Td';
import TdLink from 'components/stateless/Table/TdLink';
import Tr from 'components/stateless/Table/Tr';
import { HeaderTitle } from 'components/stateless/Title/HeaderTitle';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { ROUTES_SY } from 'constants/routes/system';
import useMounted from 'hooks/useMounted';
import usePageable from 'hooks/usePageable';
import type Pageable from 'models/Pageable';
import type { AnchorPartnerVOModel } from 'models/vo/AnchorPartnerVO';
import type { PDFExporterProps } from 'utils/exportFile/exportPDF.d';
import { setFormValues } from 'utils/form/setFormValues';
import { requestSystemAnchorPartnerList } from 'utils/http/api/system/anchor-partners';
import type { SystemAnchorPartnerListRequest } from 'utils/http/api/system/anchor-partners/requests';
import useModal from 'utils/modal/useModal';
import { getParsedSearchParams, updateSearchParams } from 'utils/searchParams';
import type { ColumnOption, ExportSpreadSheetProps } from 'utils/spreadSheet/types';
import { tableValueManage } from 'utils/valueManager/ValueManager';

const getConstants = () => {
  const mounted = useMounted();
  const modal = useModal();
  const { t } = useTranslation(['format']);

  const SEARCHED_SYSTEM_MONITOR_PLATFORM_UPLOADED_PARTNER_TABLE_HEADERS: HeaderType[] = [
    {
      headerText: t('text:Registration_Date'),
    },
    {
      headerText: t('text:Uploaded_Partner_ID'),
    },
    {
      headerText: t('text:Uploaded_Partner_Name'),
    },
    {
      headerText: t('text:Tax_Code'),
    },
    {
      headerText: t('text:Company_Registration_Number'),
    },
    {
      headerText: t('text:Associated_Anchor_ID'),
    },
    {
      headerText: t('text:Associated_Anchor_Name'),
    },
    {
      headerText: t('text:Registered_Partner_ID'),
    },
    {
      headerText: t('text:Registered_Partner_Name'),
    },
    {
      headerText: '',
      colWidths: 50,
    },
  ];

  const SY_UPLOADED_PARTNER_LIST_QS_KEY = 'sy-uploaded-partner-list';

  return {
    mounted,
    modal,
    t,
    SEARCHED_SYSTEM_MONITOR_PLATFORM_UPLOADED_PARTNER_TABLE_HEADERS,
    SY_UPLOADED_PARTNER_LIST_QS_KEY,
  };
};

function SystemMonitorPlatformUploadedPartnerList(): JSX.Element {
  const {
    mounted,
    modal,
    t,
    SEARCHED_SYSTEM_MONITOR_PLATFORM_UPLOADED_PARTNER_TABLE_HEADERS,
    SY_UPLOADED_PARTNER_LIST_QS_KEY,
  } = getConstants();

  const [systemMonitorPlatformUploadedPartnerPage, setSystemMonitorPlatformUploadedPartnerPage] =
    useState<Pageable<AnchorPartnerVOModel[]>>();

  const { register, getValues, reset, setValue, control } = useForm<SystemAnchorPartnerListRequest>();

  const {
    pageable: searchedSystemMonitorPlatformUploadedPartnerPageable,
    setPageable: setSearchedSystemMonitorPlatformUploadedPartnerPageable,
  } = usePageable(SY_UPLOADED_PARTNER_LIST_QS_KEY);

  useEffect(() => {
    if (mounted) {
      setFormValues<SystemAnchorPartnerListRequest>(
        setValue,
        getParsedSearchParams(SY_UPLOADED_PARTNER_LIST_QS_KEY).formSearchData,
      );
      fetchSyAnchorPartnerList(
        searchedSystemMonitorPlatformUploadedPartnerPageable.currentPage,
        searchedSystemMonitorPlatformUploadedPartnerPageable.sizePerPage,
        getValues(),
      );
    }
  }, [mounted]);

  const fetchSyAnchorPartnerList = async (
    pageNumber: number = 1,
    rowCount: number = 10,
    data: SystemAnchorPartnerListRequest,
  ): Promise<void> => {
    try {
      const systemMonitorPlatformUploadedPartnerPage = await requestSystemAnchorPartnerList(pageNumber, rowCount, data);
      updateSearchParams(
        {
          ...data,
          pageNumber,
          rowCount,
        },
        SY_UPLOADED_PARTNER_LIST_QS_KEY,
      );

      ReactDOM.unstable_batchedUpdates(() => {
        setSystemMonitorPlatformUploadedPartnerPage(systemMonitorPlatformUploadedPartnerPage);
        setSearchedSystemMonitorPlatformUploadedPartnerPageable(systemMonitorPlatformUploadedPartnerPage);
      });
    } catch (error) {
      modal.show(error);
    }
  };

  const onClickRemoveFilter = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    e.preventDefault();

    reset();
  };

  const onClickSearch = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    e.preventDefault();

    fetchSyAnchorPartnerList(1, searchedSystemMonitorPlatformUploadedPartnerPageable.sizePerPage, getValues());
  };

  const searchedSystemMonitorPlatformUploadedPartnerPaginate = (pageNumber: number, rowCount: number): void => {
    fetchSyAnchorPartnerList(
      pageNumber,
      rowCount,
      getParsedSearchParams(SY_UPLOADED_PARTNER_LIST_QS_KEY).formSearchData,
    );
  };

  const onClickExportButton = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();

    const EXCEL_COLUMNS: ColumnOption<AnchorPartnerVOModel>[] = [
      {
        header: t('text:Registration_Date'),
        key: 'createdDateTime',
        width: 25,
      },
      {
        header: t('text:Uploaded_Partner_ID'),
        key: 'anchorPartnerId',
        width: 20,
      },
      {
        header: t('text:Uploaded_Partner_Name'),
        key: 'name',
        width: 30,
      },
      {
        header: t('text:Tax_Code'),
        key: 'partnerTaxCode',
        width: 30,
      },
      {
        header: t('text:Company_Registration_Number'),
        key: 'partnerBusinessCode',
        width: 30,
      },
      {
        header: t('text:Associated_Anchor_ID'),
        key: 'anchorId',
        width: 20,
      },
      {
        header: t('text:Associated_Anchor_Name'),
        key: 'anchorName',
        width: 20,
      },
      {
        header: t('text:Registered_Partner_ID'),
        key: 'partnerId',
        width: 20,
      },
      {
        header: t('text:Registered_Partner_Name'),
        key: 'partnerName',
        width: 20,
      },
    ];

    const EXCEL_EXPORT_MAX_ROW_COUNT = 1000;
    const PDF_EXPORT_MAX_ROW_COUNT = 150;

    const renderPDFTableBodyResult = (data?: AnchorPartnerVOModel[]) => {
      return data?.map((item, index) => (
        <tr key={index} className="virtual-table-row">
          <Td data={item.createdDateTime} format="datetime" />
          <Td data={item.anchorPartnerId} />
          <Td data={item.name} />
          <Td data={item.partnerTaxCode} />
          <Td data={item.partnerBusinessCode} />
          <Td data={item.anchorId} />
          <Td data={item.anchorName} />
          <Td data={item.partnerId} />
          <Td data={item.partnerName} />
        </tr>
      ));
    };

    const searchFormValues = getValues();

    try {
      const [excelPage, PDFPage] = await Promise.all([
        requestSystemAnchorPartnerList(0, EXCEL_EXPORT_MAX_ROW_COUNT, searchFormValues),
        requestSystemAnchorPartnerList(0, PDF_EXPORT_MAX_ROW_COUNT, searchFormValues),
      ]);

      const jsonArrayData = excelPage.content.map(data => ({
        createdDateTime: tableValueManage(
          data.createdDateTime,
          t('format:datetime', {
            value: data.createdDateTime,
            key: 'datetime',
          }),
        ),
        anchorPartnerId: tableValueManage(data.anchorPartnerId),
        name: tableValueManage(data.name),
        partnerTaxCode: tableValueManage(data.partnerTaxCode),
        partnerBusinessCode: tableValueManage(data.partnerBusinessCode),
        anchorId: tableValueManage(data.anchorId),
        anchorName: tableValueManage(data.anchorName),
        partnerId: tableValueManage(data.partnerId),
        partnerName: tableValueManage(data.partnerName),
      }));

      const excelExporterProps: ExportSpreadSheetProps<AnchorPartnerVOModel> = {
        jsonArrayData: jsonArrayData,
        columns: EXCEL_COLUMNS,
        options: {
          rowHeight: [{ position: 1, height: 30 }],
        },
      };

      const pdfExporterProps: PDFExporterProps = {
        tableHeaders: SEARCHED_SYSTEM_MONITOR_PLATFORM_UPLOADED_PARTNER_TABLE_HEADERS,
        tableBody: renderPDFTableBodyResult(PDFPage.content),
      };

      modal.show(<Exporter spreadSheetExporterProps={excelExporterProps} pdfExporterProps={pdfExporterProps} />, {
        title: t('text:Export_File'),
        closeBtnText: t('text:Close'),
      });
    } catch (error) {
      modal.show(error);
    }
  };

  const renderSearchedSystemMonitorPlatformUploadedPartnerTableBody = (): JSX.Element[] | undefined => {
    return systemMonitorPlatformUploadedPartnerPage?.content.map((data, index) => (
      <Tr key={index}>
        <Td data={data.createdDateTime} format="datetime" />
        <Td data={data.anchorPartnerId} />
        <Td data={data.name} />
        <Td data={data.partnerTaxCode} />
        <Td data={data.partnerBusinessCode} />
        <Td data={data.anchorId} />
        <Td data={data.anchorName} />
        <Td data={data.partnerId} />
        <Td data={data.partnerName} />
        <TdLink path={ROUTES_SY.MONITOR_PLATFORM.UPLOADED_PARTNER_DETAIL_BUILD_PATH(data.anchorPartnerId)} />
      </Tr>
    ));
  };

  return (
    <>
      <HeaderTitle title={t('text:Enterprise')} />
      <div className="content-area">
        <SectionTitle title={t('text:Search')}>
          <Button variant={ButtonVariantEnum.OUTLINED} color={ButtonColorEnum.SECONDARY} onClick={onClickRemoveFilter}>
            {t('text:Remove_Filter')}
          </Button>
        </SectionTitle>
        <form>
          <SearchBorder>
            <div className="row">
              <SearchLabel label={t('text:Registration_Date')} />
              <SearchDatePicker placeholder={t('text:from')} name="fromDate" control={control} />
              <SearchDatePicker placeholder={t('text:to')} name="toDate" control={control} />
              <SearchLabel label={t('text:Uploaded_Partner_ID')} />
              <SearchInput name="uploadedPartnerId" ref={register} />
            </div>
            <div className="row">
              <SearchLabel label={t('text:Uploaded_Partner_Name')} />
              <SearchInput name="uploadedPartnerName" ref={register} />
              <SearchLabel label={t('text:Tax_Code')} />
              <SearchInput name="uploadedPartnerTaxCode" ref={register} />
            </div>
            <div className="row">
              <SearchLabel label={t('text:Company_Registration_Number')} />
              <SearchInput name="uploadedBusinessCode" ref={register} />
              <SearchLabel label={t('text:Associated_Anchor_ID')} />
              <SearchInput name="anchorId" ref={register} />
            </div>
            <div className="row">
              <SearchLabel label={t('text:Associated_Anchor_Name')} />
              <SearchInput name="anchorName" ref={register} />
              <SearchLabel label={t('text:Registered_Partner_ID')} />
              <SearchInput name="partnerId" ref={register} />
            </div>
            <div className="row">
              <SearchLabel label={t('text:Registered_Partner_Name')} />
              <SearchInput name="partnerName" ref={register} />
              <SearchEmpty />
            </div>
          </SearchBorder>
          <div className="flex-center mt-4">
            <Button size={ButtonSizeEnum.LG} onClick={onClickSearch}>
              {t('text:Search')}
            </Button>
          </div>
        </form>
      </div>
      <div className="division-border" />
      <div className="content-area">
        <SectionTitle title={t('text:Result')} />
        <div className="d-flex mb-2">
          <p className="total-data me-auto">
            {t('text:Total')} : {searchedSystemMonitorPlatformUploadedPartnerPageable.totalRows}
          </p>
          <Button onClick={onClickExportButton} variant={ButtonVariantEnum.OUTLINED}>
            {t('text:Export')}
          </Button>
        </div>
        <TableBorder>
          <TableHeader header={SEARCHED_SYSTEM_MONITOR_PLATFORM_UPLOADED_PARTNER_TABLE_HEADERS} />
          <TableBody numOfCol={SEARCHED_SYSTEM_MONITOR_PLATFORM_UPLOADED_PARTNER_TABLE_HEADERS.length}>
            {renderSearchedSystemMonitorPlatformUploadedPartnerTableBody()}
          </TableBody>
        </TableBorder>
        <Pagination
          pageable={searchedSystemMonitorPlatformUploadedPartnerPageable}
          paginate={searchedSystemMonitorPlatformUploadedPartnerPaginate}
        />
      </div>
    </>
  );
}

export default SystemMonitorPlatformUploadedPartnerList;
