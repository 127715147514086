import { useEffect } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import Button, { ButtonSizeEnum } from 'components/stateless/Button/Button';
import GuideMessage from 'components/stateless/GuideMessage/GuideMessage';
import Tab from 'components/stateless/TabManager/Tab';
import { HeaderTitle } from 'components/stateless/Title/HeaderTitle';
import { ROUTES_AC } from 'constants/routes/anchor';
import { AUTHORITY_TYPE, COMMON_APPROVAL_TYPE } from 'enums';
import useMounted from 'hooks/useMounted';
import { getSignIn } from 'utils/storage/LocalStorage';

import CompleteView from './tabs/CompleteView';
import FailedView from './tabs/FailedView';
import WaitingView from './tabs/WaitingView';
import { useAnchorPartnerRegistrationListState } from './useAnchorPartnerRegistrationListState';

export interface RenderManagerOption {
  targetAuthorityType?: AUTHORITY_TYPE | AUTHORITY_TYPE[];
  targetApprovalType?: COMMON_APPROVAL_TYPE | COMMON_APPROVAL_TYPE[];
}

function AnchorPartnerRegistrationList() {
  const { t } = useTranslation(['format']);
  const mounted = useMounted();
  const authorityType = getSignIn()?.authorityType;
  const history = useHistory();

  const {
    fetchAll,
    useForms,
    waitingViewStates,
    completeViewStates,
    failedViewStates,
    waitingViewUtils,
    completeViewUtils,
    failedViewUtils,
    getConstant,
  } = useAnchorPartnerRegistrationListState();

  const {
    AC_PARTNER_REGISTRATION_WAITING_LIST_QS_KEY,
    AC_PARTNER_REGISTRATION_CONFIRMED_LIST_QS_KEY,
    AC_PARTNER_REGISTRATION_FAILED_LIST_QS_KEY,
  } = getConstant();

  useEffect(() => {
    if (mounted) {
      fetchAll();
    }
  }, [mounted]);

  return (
    <>
      <HeaderTitle title={t('text:Partner_Registration')} />
      <GuideMessage
        message={[
          t('text:Click_on_the_Register_Partner_button_to_register_the_information_of_new_partners_to_the_platform'),
          t('text:Click_on_the_arrow_button_to_view_detailed_information_of_the_Partners'),
        ]}
      >
        {{
          button:
            authorityType === AUTHORITY_TYPE.HQ_OPERATOR || authorityType === AUTHORITY_TYPE.ADMIN ? (
              <Button
                size={ButtonSizeEnum.SM}
                onClick={() => history.push(ROUTES_AC.MANAGE_PARTNER.REGISTRATION_STEP1)}
              >
                {t('text:Register_Supply_Chain_Partners')}
              </Button>
            ) : undefined,
        }}
      </GuideMessage>
      <Tab
        tabType="approvalStatus"
        tabList={[
          {
            tabItemCount:
              (useForms.WaitingAnchorPartnerPageRequestForm.getValues('approvalTypes') as any) ===
              COMMON_APPROVAL_TYPE.OPERATOR_CANCEL
                ? 0
                : waitingViewStates.waitingAnchorPartnerForTabCount?.totalElements ?? 0,
          },
          {
            tabName: t('text:Registration_Confirmed'),
            tabItemCount: completeViewStates.anchorPartnerPage?.totalElements ?? 0,
          },
          {
            tabName: t('text:Registration_Failed'),
            tabItemCount: failedViewStates.failedAnchorPartnerPage?.totalElements ?? 0,
          },
        ]}
        tabViewList={[
          <FormProvider
            key={AC_PARTNER_REGISTRATION_WAITING_LIST_QS_KEY}
            {...useForms.WaitingAnchorPartnerPageRequestForm}
          >
            <WaitingView waitingViewStates={waitingViewStates} waitingViewUtils={waitingViewUtils} />
          </FormProvider>,
          <FormProvider key={AC_PARTNER_REGISTRATION_CONFIRMED_LIST_QS_KEY} {...useForms.AnchorPartnersPageRequestForm}>
            <CompleteView completeViewStates={completeViewStates} completeViewUtils={completeViewUtils} />
          </FormProvider>,
          <FormProvider key={AC_PARTNER_REGISTRATION_FAILED_LIST_QS_KEY} {...useForms.FailedAnchorPartnerPageForm}>
            <FailedView failedViewStates={failedViewStates} failedViewUtils={failedViewUtils} />
          </FormProvider>,
        ]}
      />
    </>
  );
}

export default AnchorPartnerRegistrationList;
