import { useTranslation } from 'react-i18next';

import Button, { ButtonColorEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import GuideMessage, { MessageType } from 'components/stateless/GuideMessage/GuideMessage';
import { AUTHORITY_TYPE, COMMON_APPROVAL_TYPE, MULTIPLE_LOAN_REQUEST_STATUS } from 'enums';
import type { SignInModel } from 'models/SignInModel';
import type { MultipleLoanRequestDetailVOModel } from 'models/vo/MultipleLoanRequestDetailVO';
import { getSignIn } from 'utils/storage/LocalStorage';

interface FinancierBulkFinancingDetailGuideMessageProps {
  bulkFinancingDetailInfo: MultipleLoanRequestDetailVOModel;
  handleOperatorBulkFinancingCancelConfirmButtonClick: () => void;
}

function FinancierBulkFinancingDetailGuideMessage({
  bulkFinancingDetailInfo,
  handleOperatorBulkFinancingCancelConfirmButtonClick,
}: FinancierBulkFinancingDetailGuideMessageProps) {
  const signInModel: SignInModel | null = getSignIn();
  const { t } = useTranslation();
  const { approvalType, multipleLoanRequestStatus, rejectReason, returnReason } = bulkFinancingDetailInfo;

  const OperatorMessage = () => {
    if (multipleLoanRequestStatus === MULTIPLE_LOAN_REQUEST_STATUS.APPLIED) {
      switch (approvalType) {
        case COMMON_APPROVAL_TYPE.OPERATOR_REQUEST:
          return (
            <GuideMessage
              message={[
                t('text:The_request_for_the_Authorizer_s_financing_approval_has_been_submitted'),
                t('text:To_cancel_the_approval_request_click_on_the_Cancel_Confirm_Request_on_the_right'),
              ]}
            >
              {{
                button: (
                  <Button
                    variant={ButtonVariantEnum.OUTLINED}
                    color={ButtonColorEnum.SECONDARY}
                    onClick={handleOperatorBulkFinancingCancelConfirmButtonClick}
                  >
                    {t('text:Cancel_Confirm_Request')}
                  </Button>
                ),
              }}
            </GuideMessage>
          );
        case COMMON_APPROVAL_TYPE.AUTHORIZER_RETURNED:
          return (
            <GuideMessage
              messageType={MessageType.ALERT}
              message={[
                t('text:The_financing_approval_request_has_been_reverted'),
                t('text:Check_the_reason_for_the_revert_below'),
              ]}
              reasonTitle={t('text:REASON_FOR_REVERT')}
              reason={returnReason}
            >
              {{
                button: (
                  <Button
                    variant={ButtonVariantEnum.OUTLINED}
                    color={ButtonColorEnum.SECONDARY}
                    onClick={handleOperatorBulkFinancingCancelConfirmButtonClick}
                  >
                    {t('text:Cancel_Confirm_Request')}
                  </Button>
                ),
              }}
            </GuideMessage>
          );
        default:
          return (
            <GuideMessage
              message={[
                t('text:Check_the_financing_request_information_below_and_proceed_with_confirmation'),
                t('text:Click_on_the_Confirm_button_to_request_approval_from_the_Financier_Authorizer'),
              ]}
            />
          );
      }
    } else if (multipleLoanRequestStatus === MULTIPLE_LOAN_REQUEST_STATUS.APPROVED) {
      return (
        <GuideMessage
          message={[
            t('text:The_financing_application_has_been_approved'),
            t('text:The_bulk_approval_process_has_been_completed') +
              ' ' +
              t('text:Check_each_financing_details_pages_for_details'),
          ]}
        />
      );
    } else if (multipleLoanRequestStatus === MULTIPLE_LOAN_REQUEST_STATUS.REJECTED) {
      return (
        <GuideMessage
          messageType={MessageType.ALERT}
          message={[
            t('text:The_financing_application_has_been_rejected'),
            t('text:Check_the_reason_for_the_rejection_below'),
          ]}
          reasonTitle={t('text:REASON_FOR_REJECTION')}
          reason={rejectReason}
        />
      );
    } else return null;
  };

  const AuthorizerMessage = () => {
    if (multipleLoanRequestStatus === MULTIPLE_LOAN_REQUEST_STATUS.APPLIED) {
      switch (approvalType) {
        case COMMON_APPROVAL_TYPE.OPERATOR_REQUEST:
          return (
            <GuideMessage message={[t('text:The_request_for_the_Authorizers_financing_approval_has_been_submitted')]} />
          );
        case COMMON_APPROVAL_TYPE.AUTHORIZER_RETURNED:
          return (
            <GuideMessage
              messageType={MessageType.ALERT}
              message={[
                t('text:The_financing_approval_request_has_been_reverted'),
                t('text:Check_the_reason_for_the_revert_below'),
              ]}
              reasonTitle={t('text:REASON_FOR_REVERT')}
              reason={returnReason}
            />
          );
        default:
          return null;
      }
    } else if (multipleLoanRequestStatus === MULTIPLE_LOAN_REQUEST_STATUS.APPROVED) {
      return (
        <GuideMessage message={[t('text:The_request_for_the_Authorizers_financing_approval_has_been_submitted')]} />
      );
    } else if (multipleLoanRequestStatus === MULTIPLE_LOAN_REQUEST_STATUS.REJECTED) {
      return (
        <GuideMessage
          messageType={MessageType.ALERT}
          message={[
            t('text:The_financing_application_has_been_rejected'),
            t('text:Check_the_reason_for_the_rejection_below'),
          ]}
          reasonTitle={t('text:REASON_FOR_REJECTION')}
          reason={rejectReason}
        />
      );
    } else return null;
  };

  return (
    <>
      {signInModel?.authorityType === AUTHORITY_TYPE.OPERATOR && <OperatorMessage />}
      {signInModel?.authorityType === AUTHORITY_TYPE.AUTHORIZER && <AuthorizerMessage />}
    </>
  );
}

export default FinancierBulkFinancingDetailGuideMessage;
