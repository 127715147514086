import { useTranslation } from 'react-i18next';

import type { BigHeaderType, HeaderType } from 'components/stateless/Table/TableHeader';
import type { StatisticsOfAnchorAgreementVOModel } from 'models/vo/StatisticsOfAnchorAgreementVO';
import type { ColumnOption, MergedCellTypes } from 'utils/spreadSheet/types';

export function useFiOverviewByAnchorListVendorTabConstants() {
  const { t } = useTranslation(['format']);

  const AR_TABLE_HEADER: HeaderType[] = [
    {
      headerText: t('text:Anchor_Name'),
      colWidths: 170,
    },
    {
      headerText: t('text:Anchor_Client_Code'),
      colWidths: 120,
    },
    {
      headerText: t('text:Anchor_Master_Agreement_Number'),
      colWidths: 120,
    },
    {
      headerText: t('text:Currency'),
      colWidths: 100,
    },
    {
      headerText: t('text:Automatic_Settlement'),
      colWidths: 180,
    },
    {
      headerText: t('text:Outstanding_Financing_Balance_/_Count'),
      colWidths: 165,
      className: 'main100',
    },
    {
      headerText: t('text:Pending_AR_Amount_/_Count'),
      colWidths: 165,
      className: 'main100',
    },
    {
      headerText: t('text:Total_Registered_AR_Amount_/_Count'),
      colWidths: 165,
      className: 'main200',
    },
    {
      headerText: t('text:Total_Financing_Amount_/_Count'),
      colWidths: 165,
      className: 'main200',
    },
  ];

  const AR_BIG_TABLE_HEADER: BigHeaderType[] = [
    {
      headerText: t('text:Anchor_Information'),
      colSpan: 5,
    },
    {
      headerText: t('text:Current_Program_Utilization'),
      colSpan: 2,
      className: 'main100',
    },
    {
      headerText: t('text:Accumulated_Program_Utilization'),
      colSpan: 2,
      className: 'main200',
    },
  ];

  const excelColumnsAr: ColumnOption<StatisticsOfAnchorAgreementVOModel>[] = [
    {
      header: t('text:Anchor_Name'),
      key: 'anchorFinancierClientName',
      width: 30,
    },
    {
      header: t('text:Anchor_Client_Code'),
      key: 'anchorFinancierClientCode',
      width: 30,
    },
    {
      header: t('text:Anchor_Master_Agreement_Number'),
      key: 'contractNo',
      width: 40,
    },
    {
      header: t('text:Currency'),
      key: 'currencyType',
      width: 20,
    },
    {
      header: t('text:Automatic_Settlement'),
      key: 'paymentSupport',
      width: 30,
    },
    {
      header: t('text:Outstanding_Financing_Balance'),
      key: 'currentLoanBalanceAmount',
      width: 40,
      style: {
        alignment: { horizontal: 'right' },
      },
    },
    {
      header: t('text:Number_of_Outstanding_Financing'),
      key: 'currentDisbursedLoanCount',
      width: 40,
      style: {
        alignment: { horizontal: 'right' },
      },
    },
    {
      header: t('text:Pending_AR_Amount'),
      key: 'currentSettlementWaitingInvoiceAmount',
      width: 35,
      style: {
        alignment: { horizontal: 'right' },
      },
    },
    {
      header: t('text:Number_of_Pending_AR'),
      key: 'currentSettlementWaitingInvoiceCount',
      width: 35,
      style: {
        alignment: { horizontal: 'right' },
      },
    },
    {
      header: t('text:Total_Registered_AR_Amount'),
      key: 'registeredInvoiceAmount',
      width: 35,
      style: {
        alignment: { horizontal: 'right' },
      },
    },
    {
      header: t('text:Total_Number_of_Registered_AR'),
      key: 'registeredInvoiceCount',
      width: 35,
      style: {
        alignment: { horizontal: 'right' },
      },
    },
    {
      header: t('text:Total_Financing_Amount'),
      key: 'totalDisbursedLoanAmount',
      width: 40,
      style: {
        alignment: { horizontal: 'right' },
      },
    },
    {
      header: t('text:Total_Number_of_Disbursed_Financing'),
      key: 'totalDisbursedLoanCount',
      width: 40,
      style: {
        alignment: { horizontal: 'right' },
      },
    },
  ];

  const arMergedCells: MergedCellTypes[] = [
    {
      target: 'A:E',
      rowIndex: 1,
      value: t('text:Anchor_Information'),
    },
    {
      target: 'F:I',
      rowIndex: 1,
      value: t('text:Current_Program_Utilization'),
      style: {
        fill: {
          fgColor: { argb: 'FFFFFCDE' },
        },
      },
    },
    {
      target: 'J:M',
      rowIndex: 1,
      value: t('text:Accumulated_Program_Utilization'),
      style: {
        fill: {
          fgColor: { argb: 'FFFFF8BD' },
        },
      },
    },
  ];

  const arPdfTableHeader: HeaderType[] = [
    {
      headerText: t('text:Anchor_Name'),
      colWidths: 170,
    },
    {
      headerText: t('text:Anchor_Client_Code'),
      colWidths: 165,
    },
    {
      headerText: t('text:Anchor_Master_Agreement_Number'),
      colWidths: 165,
    },
    {
      headerText: t('text:Currency'),
      colWidths: 100,
    },
    {
      headerText: t('text:Automatic_Settlement'),
      colWidths: 120,
    },
    {
      headerText: t('text:Outstanding_Financing_Balance'),
      className: 'main100',
      colWidths: 165,
    },
    {
      headerText: t('text:Number_of_Outstanding_Financing'),
      className: 'main100',
      colWidths: 165,
    },
    {
      headerText: t('text:Pending_AR_Amount'),
      className: 'main100',
      colWidths: 165,
    },
    {
      headerText: t('text:Number_of_Pending_AR'),
      className: 'main100',
      colWidths: 165,
    },
    {
      headerText: t('text:Total_Registered_AR_Amount'),
      className: 'main200',
      colWidths: 165,
    },
    {
      headerText: t('text:Total_Number_of_Registered_AR'),
      className: 'main200',
      colWidths: 165,
    },
    {
      headerText: t('text:Total_Financing_Amount'),
      className: 'main200',
      colWidths: 165,
    },
    {
      headerText: t('text:Total_Number_of_Disbursed_Financing'),
      className: 'main200',
      colWidths: 165,
    },
  ];

  const arPdfMergedTableHeader: BigHeaderType[] = [
    {
      headerText: t('text:Anchor_Information'),
      colSpan: 5,
    },
    {
      headerText: t('text:Current_Program_Utilization'),
      colSpan: 4,
      className: 'main100',
    },
    {
      headerText: t('text:Accumulated_Program_Utilization'),
      colSpan: 4,
      className: 'main200',
    },
  ];

  return {
    AR_TABLE_HEADER,
    AR_BIG_TABLE_HEADER,
    excelColumnsAr,
    arMergedCells,
    arPdfTableHeader,
    arPdfMergedTableHeader,
  };
}
