import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

import Button, { ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import { FormBorder } from 'components/stateless/CommonForm/FormBorder';
import { FormContents } from 'components/stateless/CommonForm/FormContents';
import FormInput from 'components/stateless/CommonForm/FormInput';
import { BackGroundType, FormSubtitle } from 'components/stateless/CommonForm/FormSubtitle';
import { FormValue } from 'components/stateless/CommonForm/FormValue';
import GuideMessage from 'components/stateless/GuideMessage/GuideMessage';
import FinancierCitadCodeModal from 'components/stateless/Modal/financier/FinancierCitadCodeModal';
import Pagination from 'components/stateless/Pagination/Pagination';
import TableBody from 'components/stateless/Table/TableBody';
import TableBorder from 'components/stateless/Table/TableBorder';
import type { HeaderType } from 'components/stateless/Table/TableHeader';
import TableHeader from 'components/stateless/Table/TableHeader';
import Td from 'components/stateless/Table/Td';
import Tr from 'components/stateless/Table/Tr';
import { BackHeaderTitle } from 'components/stateless/Title/BackHeaderTitle';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { ROUTES_FI } from 'constants/routes/financier';
import { AUTHORITY_TYPE } from 'enums';
import useMounted from 'hooks/useMounted';
import usePageable from 'hooks/usePageable';
import type Pageable from 'models/Pageable';
import type { AnchorPartnerAccountDetailVOModel } from 'models/vo/AnchorPartnerAccountDetailVO';
import type { BankCodeVOModel } from 'models/vo/BankCodeVO';
import type { WaitingAnchorPartnerAccountVOModel } from 'models/vo/WaitingAnchorPartnerAccountVO';
import { formErrorHandler } from 'utils/error/manager';
import { requestFiAnchorPartnerAccountData } from 'utils/http/api/financier/anchor-partner-accounts';
import {
  requestFinancierWaitingAnchorPartnerAccountEdit,
  requestFinancierWaitingAnchorPartnerAccountList,
} from 'utils/http/api/financier/waiting-anchor-partner-accounts';
import type { FinancierWaitingAnchorPartnerAccountEditRequest } from 'utils/http/api/financier/waiting-anchor-partner-accounts/requests';
import { ModalSize, ModalType } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';
import { getSignIn } from 'utils/storage/LocalStorage';
import { getPaymentSupportText } from 'utils/text';

function FinancierPotentialSupplyChainPartnerBankAccountConfirmedRevisionDetail() {
  const mounted = useMounted();
  const modal = useModal();
  const history = useHistory();
  const { t } = useTranslation(['format']);
  const { anchorPartnerAccountId } = useParams() as any;
  const authorityType = getSignIn()?.authorityType;

  const [anchorPartnerAccountData, setAnchorPartnerAccountData] = useState<AnchorPartnerAccountDetailVOModel>();
  const [waitingAnchorPartnerAccountPageData, setWaitingAnchorPartnerAccountPageData] =
    useState<Pageable<WaitingAnchorPartnerAccountVOModel[]>>();
  const { pageable, setPageable } = usePageable();
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const { register, getValues, setValue, reset, errors, setError, clearErrors } =
    useForm<FinancierWaitingAnchorPartnerAccountEditRequest>({ mode: 'onSubmit', shouldFocusError: true });

  const INFORMATION_EDIT_HISTORY_TABLE_HEADERS: HeaderType[] = [
    {
      headerText: t('text:Edited_Date'),
      colWidths: 100,
    },
    {
      headerText: t('text:Associated_Anchor_Name'),
      colWidths: 120,
    },
    {
      headerText: t('text:Associated_Master_Agreement_of_Anchor_Number'),
      colWidths: 120,
    },
    {
      headerText: t('text:Bank_Code'),
      colWidths: 100,
    },
    {
      headerText: t('text:Bank_Name'),
      colWidths: 100,
    },
    {
      headerText: t('text:Branch_Name'),
      colWidths: 100,
    },
    {
      headerText: t('text:Bank_Account_Number'),
      colWidths: 80,
    },
    {
      headerText: t('text:Bank_Account_Holder'),
      colWidths: 80,
    },
    {
      headerText: t('text:Edit_requested_by_User_ID/Name'),
      colWidths: 100,
    },
    {
      headerText: t('text:Edit_approved_by_User_ID/Name'),
      colWidths: 100,
    },
  ];

  useEffect(() => {
    if (mounted) fetchAll();
  }, [mounted]);

  const fetchAll = async (): Promise<void> => {
    try {
      const [anchorPartnerAccountData, waitingAnchorPartnerAccountPageData] = await Promise.all([
        requestFiAnchorPartnerAccountData(anchorPartnerAccountId),
        requestFinancierWaitingAnchorPartnerAccountList(1, 10, {
          targetAnchorPartnerAccountId: anchorPartnerAccountId,
        }),
      ]);

      ReactDOM.unstable_batchedUpdates(() => {
        setAnchorPartnerAccountData(anchorPartnerAccountData);
        setWaitingAnchorPartnerAccountPageData(waitingAnchorPartnerAccountPageData);
        setPageable(waitingAnchorPartnerAccountPageData);
      });
    } catch (error) {
      modal.show(error);
    }
  };

  const renderSupplyChainPartnerInformationComponent = (): JSX.Element => {
    return (
      <div className="content-area">
        <form>
          <SectionTitle title={t(`text:Uploaded_Partner_Information`)} />
          <FormBorder>
            <FormContents>
              <div className="row">
                <FormValue label={t(`text:Uploaded_Partner_Name`)} value={anchorPartnerAccountData?.partnerName} />
                <FormValue label={t(`text:Tax_Code`)} value={anchorPartnerAccountData?.partnerTaxCode} />
              </div>
              <div className="row">
                <FormValue
                  label={t(`text:Company_Registration_Number`)}
                  value={anchorPartnerAccountData?.partnerBusinessCode}
                />
                <FormValue col={3} label={t(`text:Telephone`)} value={anchorPartnerAccountData?.telephone} />
                <FormValue col={3} label={t(`text:Fax`)} value={anchorPartnerAccountData?.fax} />
              </div>
              <div className="row">
                <FormValue
                  label={t(`text:Legal_Representative_Name`)}
                  value={anchorPartnerAccountData?.representativeName}
                />
                <FormValue
                  col={3}
                  label={t(`text:Legal_Representative_Title`)}
                  value={anchorPartnerAccountData?.representativePosition}
                />
                <FormValue
                  col={3}
                  label={t(`text:Legal_Representative_Email`)}
                  value={anchorPartnerAccountData?.representativeEmail}
                />
              </div>
              <div className="row">
                <FormValue
                  col={12}
                  label={t(`text:Registered_Office_Address`)}
                  value={anchorPartnerAccountData?.address}
                />
              </div>
            </FormContents>
          </FormBorder>
        </form>
      </div>
    );
  };

  const renderDesignatedBankAccountInformationforAPSettlementComponent = (): JSX.Element => {
    const onClickEdit = (e: any) => {
      e.preventDefault();

      setIsEdit(true);
    };

    const onClickCancel = (e: any) => {
      e.preventDefault();

      modal.show(
        <h6>
          {t('text:Would_you_like_to_stop_modifying_the_information?')}
          <br />
          {t('text:If_the_modification_is_aborted_the_entered_content_will_not_be_saved')}
        </h6>,
        {
          modalType: ModalType.CONFIRM,
          closeBtnText: t('text:Close'),
          confirmBtnText: t('text:Confirm'),
          confirmBtnCb: () => {
            setIsEdit(false);
            reset({
              account: anchorPartnerAccountData?.account,
              accountOwner: anchorPartnerAccountData?.accountOwner,
              bankCodeId: anchorPartnerAccountData?.accountBankCodeId,
              accountBankName: anchorPartnerAccountData?.accountBankName,
              accountBankCode: anchorPartnerAccountData?.accountBankCode,
              accountBranchCode: anchorPartnerAccountData?.accountBranchCode,
              accountBranchName: anchorPartnerAccountData?.accountBranchName,
            });
          },
        },
      );
    };

    const onClickSave = (e: any) => {
      e.preventDefault();

      const isNotSelectedBankCodeId = getValues('bankCodeId') === ('' as unknown);
      const alreadyRegisteredBankCodeId = anchorPartnerAccountData?.accountBankCodeId as number;
      const newlySelectedBankCodeId = getValues('bankCodeId');

      const bankCodeId = isNotSelectedBankCodeId ? alreadyRegisteredBankCodeId : newlySelectedBankCodeId;
      const data = { ...getValues(), bankCodeId, targetAnchorPartnerAccountId: anchorPartnerAccountId };

      const onClickConfirmCb = async (): Promise<void> => {
        try {
          await requestFinancierWaitingAnchorPartnerAccountEdit(data);

          modal.show(
            <h6>
              {t('text:Request_for_modification_approval_has_been_completed')}
              <br />
              {t('text:Modification_will_be_completed_after_approval_by_the_Authorizer_or_Admin')}
            </h6>,
            {
              modalType: ModalType.ALERT,
              closeBtnText: t(`text:OK`),
              closeBtnCb: () => history.push(ROUTES_FI.MANAGE_UPLOADED_PARTNER.BANK_ACCOUNT_REVISION_LIST),
            },
          );
        } catch (error) {
          modal.show(error);
          formErrorHandler<FinancierWaitingAnchorPartnerAccountEditRequest>(error, setError, clearErrors);
        }
      };

      modal.show(<h6>{t('text:Would_you_like_to_request_approval_for_the_modification')}</h6>, {
        modalType: ModalType.CONFIRM,
        closeBtnText: t('text:Cancel'),
        confirmBtnText: t('text:Confirm'),
        confirmBtnCb: () => onClickConfirmCb(),
      });
    };

    const onClickFind = (e: any): void => {
      e.preventDefault();

      const setBankCodeData = (data: BankCodeVOModel): void => {
        setValue('bankCodeId', data.id);
        setValue('accountBankCode', data.bankCode);
        setValue('accountBankName', data.bankName);
        setValue('accountBranchCode', data.branchCode);
        setValue('accountBranchName', data.branchName);
      };

      modal.show(<FinancierCitadCodeModal handleBankCodeData={setBankCodeData} modalId={modal.id} />, {
        title: t('text:Find_Bank_Code'),
        modalType: ModalType.ALERT,
        modalSize: ModalSize.L,
        closeBtnText: t('text:Close'),
      });
    };

    return (
      <div className="content-area">
        <form>
          <SectionTitle title={t(`text:Designated_Bank_Account_Information_for_AP_Settlement`)}>
            {isEdit ? (
              <>
                <Button
                  size={ButtonSizeEnum.SM}
                  variant={ButtonVariantEnum.OUTLINED}
                  color={ButtonColorEnum.SECONDARY}
                  onClick={onClickCancel}
                >
                  {t('text:Cancel')}
                </Button>
                <Button size={ButtonSizeEnum.SM} onClick={onClickSave} style={{ width: '60px' }}>
                  {t('text:Save')}
                </Button>
              </>
            ) : (
              (authorityType === AUTHORITY_TYPE.OPERATOR || authorityType === AUTHORITY_TYPE.ADMIN) && (
                <Button size={ButtonSizeEnum.SM} onClick={onClickEdit} style={{ width: '60px' }}>
                  {t('text:Edit')}
                </Button>
              )
            )}
          </SectionTitle>
          <FormBorder editable={isEdit}>
            <FormSubtitle
              backGroundType={BackGroundType.DarkGray}
              title={t('text:ASSOCIATED_ANCHOR_MASTER_AGREEMENT_INFORMATION')}
            />
            <FormContents>
              <div className="row">
                <FormValue label={t('text:Anchor_Name')} value={anchorPartnerAccountData?.anchorName} />
                <FormValue
                  label={t(`text:Anchor_Master_Agreement_Number`)}
                  value={anchorPartnerAccountData?.anchorAgreementContractNo}
                />
              </div>
              <div className="row">
                <FormValue label={t(`text:Currency`)} value={anchorPartnerAccountData?.currencyType} />
                <FormValue
                  label={t(`text:Automatic_Settlement`)}
                  value={getPaymentSupportText(anchorPartnerAccountData?.paymentSupport)}
                />
              </div>
              <div className="row">
                <FormValue
                  label={t(`text:Responsible_Branch_Name`)}
                  value={anchorPartnerAccountData?.responsibleBranchName}
                />
              </div>
            </FormContents>

            <FormSubtitle
              backGroundType={BackGroundType.DarkGray}
              title={t('text:DESIGNATED_BANK_ACCOUNT_INFORMATION')}
            />
            <FormContents>
              <div className="row">
                <input type="text" name="bankCodeId" ref={register} className="d-none" />
                {anchorPartnerAccountData && (
                  <FormInput
                    label={t(`text:Bank_Code`)}
                    name="accountBankCode"
                    disabled={true}
                    defaultValue={anchorPartnerAccountData.accountBankCode}
                    ref={register}
                    error={errors.accountBankCode}
                  >
                    {isEdit && <Button onClick={onClickFind}>{t(`text:Find`)}</Button>}
                  </FormInput>
                )}
                <FormInput
                  label={t(`text:Bank_Name`)}
                  name="accountBankName"
                  defaultValue={anchorPartnerAccountData?.accountBankName}
                  disabled={true}
                  ref={register}
                  error={errors.accountBankName}
                />
              </div>
              <div className="row">
                <FormInput
                  label={t(`text:Branch_Code`)}
                  name="accountBranchCode"
                  defaultValue={anchorPartnerAccountData?.accountBranchCode}
                  disabled={true}
                  ref={register}
                  error={errors.accountBranchCode}
                />
                <FormInput
                  label={t(`text:Branch_Name`)}
                  name="accountBranchName"
                  defaultValue={anchorPartnerAccountData?.accountBranchName}
                  disabled={true}
                  ref={register}
                  error={errors.accountBranchName}
                />
              </div>
              <div className="row">
                <FormInput
                  label={t(`text:Bank_Account_Number`)}
                  name="account"
                  defaultValue={anchorPartnerAccountData?.account}
                  disabled={!isEdit}
                  ref={register}
                  error={errors.account}
                  requiredOptions={{ required: true }}
                  applyUpperCase
                />
                <FormInput
                  label={t(`text:Bank_Account_Holder`)}
                  name="accountOwner"
                  defaultValue={anchorPartnerAccountData?.accountOwner}
                  disabled={!isEdit}
                  ref={register}
                  error={errors.accountOwner}
                  requiredOptions={{ required: true }}
                  applyUpperCase
                />
              </div>
              <div className="row">
                <FormValue
                  label={t(`text:Assigned_Branch_Code`)}
                  value={anchorPartnerAccountData?.assignedBranchCode}
                />
                <FormValue
                  label={t(`text:Assigned_Branch_Name`)}
                  value={anchorPartnerAccountData?.assignedBranchName}
                />
              </div>
            </FormContents>
            {anchorPartnerAccountData?.partnerAgreementContractNo !== undefined && (
              <FormContents backGroundType={BackGroundType.WHITE}>
                <div className="row text-bold-brick-red">
                  {`* ${t(
                    'text:The_settlement_must_be_made_to_the_Partners_repayment_account_below_Check_that_the_designated_bank_account_above_matches_the_repayment_account_below',
                  )}`}
                </div>
                <div className="row">
                  <FormValue
                    label={t(`text:Agreement_Number`)}
                    value={anchorPartnerAccountData?.partnerAgreementContractNo}
                  />
                  <FormValue
                    label={t(`text:Bank_Name`)}
                    value={anchorPartnerAccountData?.principalRepaymentAccountFinancierName}
                  />
                </div>
                <div className="row">
                  <FormValue
                    label={t(`text:Account_Number`)}
                    value={anchorPartnerAccountData?.principalRepaymentAccount}
                  />
                  <FormValue
                    label={t(`text:Account_Holder`)}
                    value={anchorPartnerAccountData?.principalRepaymentAccountOwner}
                  />
                </div>
              </FormContents>
            )}
          </FormBorder>
        </form>
      </div>
    );
  };

  const renderInformationEditHistoryComponent = (): JSX.Element => {
    const paginate = async (page: number, sizePerPage: number): Promise<void> => {
      try {
        const waitingAnchorPartnerAccountPageData = await requestFinancierWaitingAnchorPartnerAccountList(
          page,
          sizePerPage,
          { targetAnchorPartnerAccountId: anchorPartnerAccountId },
        );

        ReactDOM.unstable_batchedUpdates(() => {
          setWaitingAnchorPartnerAccountPageData(waitingAnchorPartnerAccountPageData);
          setPageable(waitingAnchorPartnerAccountPageData);
        });
      } catch (error) {
        modal.show(error);
      }
    };

    const renderResultTable = (): JSX.Element[] | undefined => {
      return waitingAnchorPartnerAccountPageData?.content.map((item, index) => (
        <Tr key={index}>
          <Td data={item.updatedDateTime} format="datetime" />
          <Td data={item.anchorName} />
          <Td data={item.anchorAgreementContractNo} />
          <Td data={item.accountBankCode} />
          <Td data={item.requestedAccountBankName} />
          <Td data={item.requestedAccountBranchName} />
          <Td data={item.account} />
          <Td data={item.accountOwner} />
          <Td data={`${item.operatorUserLoginId}/${item.operatorUserName}`} />
          <Td data={`${item.authorizerUserLoginId}/${item.authorizerUserName}`} />
        </Tr>
      ));
    };

    return (
      <div className="content-area">
        <SectionTitle title={t('text:Information_Edit_History')} />
        <TableBorder>
          <TableHeader header={INFORMATION_EDIT_HISTORY_TABLE_HEADERS} />
          <TableBody numOfCol={INFORMATION_EDIT_HISTORY_TABLE_HEADERS.length}>{renderResultTable()}</TableBody>
        </TableBorder>
        <Pagination pageable={pageable} paginate={paginate} />
      </div>
    );
  };

  return (
    <>
      <BackHeaderTitle title={anchorPartnerAccountData?.partnerName} />
      <GuideMessage
        message={[
          `${t('text:If_the_Uploaded_Partners_bank_account_is_invalid')}, ${t(
            'text:please_click_on_the_Edit_button_next_to_the_Designated_Bank_Account_for_AP_Settlement_section_and_revise_the_account_information',
          )}`,
        ]}
      />
      {renderSupplyChainPartnerInformationComponent()}
      {renderDesignatedBankAccountInformationforAPSettlementComponent()}
      {renderInformationEditHistoryComponent()}
    </>
  );
}

export default FinancierPotentialSupplyChainPartnerBankAccountConfirmedRevisionDetail;
