import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Button, { ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import GuideMessage from 'components/stateless/GuideMessage/GuideMessage';
import Pagination from 'components/stateless/Pagination/Pagination';
import SearchBorder from 'components/stateless/SearchForm/SearchBorder';
import SearchEmpty from 'components/stateless/SearchForm/SearchEmpty';
import SearchInput from 'components/stateless/SearchForm/SearchInput';
import SearchLabel from 'components/stateless/SearchForm/SearchLabel';
import SearchSelect from 'components/stateless/SearchForm/SearchSelect';
import Tab from 'components/stateless/TabManager/Tab';
import TableBody from 'components/stateless/Table/TableBody';
import TableBorder from 'components/stateless/Table/TableBorder';
import type { HeaderType } from 'components/stateless/Table/TableHeader';
import TableHeader from 'components/stateless/Table/TableHeader';
import Td from 'components/stateless/Table/Td';
import TdLink from 'components/stateless/Table/TdLink';
import Tr from 'components/stateless/Table/Tr';
import { HeaderTitle } from 'components/stateless/Title/HeaderTitle';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { ROUTES_AC } from 'constants/routes/anchor';
import getSelectOptions from 'constants/selectOptions';
import type { CURRENCY_TYPE, ENTERPRISE_TYPE } from 'enums';
import { COMMON_APPROVAL_TYPE } from 'enums';
import useMounted from 'hooks/useMounted';
import usePageable from 'hooks/usePageable';
import useProperty from 'hooks/useProperty';
import type Pageable from 'models/Pageable';
import type { AnchorPartnerAccountVOModel } from 'models/vo/AnchorPartnerAccountVO';
import type { WaitingAnchorPartnerAccountVOModel } from 'models/vo/WaitingAnchorPartnerAccountVO';
import getStatusTextClass from 'utils/classNames/getStatusTextClass';
import { setFormValues } from 'utils/form/setFormValues';
import { requestAnchorPartnerBankAccountList } from 'utils/http/api/anchor/anchor-partner-accounts';
import type { AnchorPartnerAccountRequest } from 'utils/http/api/anchor/anchor-partner-accounts/requests';
import { requestWaitingAnchorPartnerAccountList } from 'utils/http/api/anchor/waiting-anchor-partner-accounts';
import type { WaitingAnchorPartnerAccountRequest } from 'utils/http/api/anchor/waiting-anchor-partner-accounts/requests';
import { WAITING_COMMON_APPROVAL_TYPE_ALL_VALUES, setApprovalTypesValue } from 'utils/logic/setApprovalTypesValue';
import useModal from 'utils/modal/useModal';
import {
  addSearchParams,
  getParsedSearchParams,
  makeSearchParamsPattern,
  updateSearchParams,
} from 'utils/searchParams';

const getConstant = () => {
  const { t } = useTranslation();

  const WAITING_FOR_CONFIRMATION_TABLE_HEADERS: HeaderType[] = [
    {
      headerText: t('text:Partner_Name'),
      colWidths: 100,
    },
    {
      headerText: t('text:Partner_Tax_Code'),
      colWidths: 120,
    },
    {
      headerText: t('text:Associated_Master_Agreement_of_Anchor_Number'),
      colWidths: 120,
    },
    {
      headerText: t('text:Currency'),
      colWidths: 100,
    },
    {
      headerText: t('text:Bank_Name'),
      colWidths: 100,
    },
    {
      headerText: t('text:Bank_Account_Number'),
      colWidths: 100,
    },
    {
      headerText: t('text:Bank_Account_Validity'),
      colWidths: 80,
    },
    {
      headerText: t('text:Reviewed_By'),
      colWidths: 80,
    },
    {
      headerText: t('text:Approval_Status'),
      colWidths: 80,
    },
    {
      headerText: '',
      colWidths: 50,
    },
  ];

  const REVISION_CONFIRMED_TABLE_HEADERS: HeaderType[] = [
    {
      headerText: t('text:Partner_Name'),
      colWidths: 100,
    },
    {
      headerText: t('text:Partner_Tax_Code'),
      colWidths: 120,
    },
    {
      headerText: t('text:Associated_Master_Agreement_of_Anchor_Number'),
      colWidths: 120,
    },
    {
      headerText: t('text:Currency'),
      colWidths: 100,
    },
    {
      headerText: t('text:Bank_Name'),
      colWidths: 100,
    },
    {
      headerText: t('text:Bank_Account_Number'),
      colWidths: 100,
    },
    {
      headerText: t('text:Bank_Account_Validity'),
      colWidths: 80,
    },
    {
      headerText: '',
      colWidths: 50,
    },
  ];

  const AC_PARTNER_BANK_ACCOUNT_REVISION_WAITING_LIST_QS_KEY = 'ac-partner-bank-account-revision-waiting-list';
  const AC_PARTNER_BANK_ACCOUNT_REVISION_CONFIRMED_LIST_QS_KEY = 'ac-partner-bank-account-revision-confirmed-list';

  return {
    WAITING_FOR_CONFIRMATION_TABLE_HEADERS,
    REVISION_CONFIRMED_TABLE_HEADERS,
    AC_PARTNER_BANK_ACCOUNT_REVISION_WAITING_LIST_QS_KEY,
    AC_PARTNER_BANK_ACCOUNT_REVISION_CONFIRMED_LIST_QS_KEY,
  };
};

function AnchorPartnerBankAccountList() {
  const {
    WAITING_FOR_CONFIRMATION_TABLE_HEADERS,
    REVISION_CONFIRMED_TABLE_HEADERS,
    AC_PARTNER_BANK_ACCOUNT_REVISION_WAITING_LIST_QS_KEY,
    AC_PARTNER_BANK_ACCOUNT_REVISION_CONFIRMED_LIST_QS_KEY,
  } = getConstant();

  const { t } = useTranslation(['format']);
  const mounted = useMounted();
  const modal = useModal();

  const { pageable: waitingForConfirmationPageable, setPageable: setWaitingForConfirmationPageable } = usePageable(
    AC_PARTNER_BANK_ACCOUNT_REVISION_WAITING_LIST_QS_KEY,
  );
  const { pageable: revisionConfirmedPageable, setPageable: setRevisionConfirmedPageable } = usePageable(
    AC_PARTNER_BANK_ACCOUNT_REVISION_CONFIRMED_LIST_QS_KEY,
  );

  const getWaitingPartnerBankAccountProperty = useProperty<WaitingAnchorPartnerAccountRequest>();
  const getPartnerBankAccountProperty = useProperty<AnchorPartnerAccountRequest>();

  const waitingPartnerBankAccountSearchForm = useForm<WaitingAnchorPartnerAccountRequest>({
    mode: 'onSubmit',
  });
  const partnerBankAccountSearchForm = useForm<AnchorPartnerAccountRequest>({
    mode: 'onSubmit',
  });

  const [waitingPartnerBankAccountList, setWaitingPartnerBankAccountList] =
    useState<Pageable<WaitingAnchorPartnerAccountVOModel[]>>();
  const [waitingPartnerBankAccountListForTabCount, setWaitingPartnerBankAccountListForTabCount] =
    useState<Pageable<WaitingAnchorPartnerAccountVOModel[]>>();
  const [partnerBankAccountList, setPartnerBankAccountList] = useState<Pageable<AnchorPartnerAccountVOModel[]>>();

  useEffect(() => {
    if (mounted) {
      fetchAll();
    }
  }, [mounted]);

  const setWaitingTabSearchValue = (formSearchData?: WaitingAnchorPartnerAccountRequest) => {
    const getSearchFormData = formSearchData ? formSearchData : waitingPartnerBankAccountSearchForm.getValues();

    const { waitingForApprovalTypeData, notCancelledWaitingForApprovalTypeData } = setApprovalTypesValue(
      getSearchFormData.approvalTypes,
    );

    const waitingForApprovalSearchData = {
      ...getSearchFormData,
      approvalTypes: waitingForApprovalTypeData,
    };

    const notCancelledWaitingForApprovalSearchData = {
      ...getSearchFormData,
      approvalTypes: notCancelledWaitingForApprovalTypeData,
    };

    return {
      waitingForApprovalSearchData,
      notCancelledWaitingForApprovalSearchData,
    };
  };
  async function fetchAll() {
    setFormValues<WaitingAnchorPartnerAccountRequest>(
      waitingPartnerBankAccountSearchForm.setValue,
      getParsedSearchParams(AC_PARTNER_BANK_ACCOUNT_REVISION_WAITING_LIST_QS_KEY).formSearchData,
    );

    setFormValues<AnchorPartnerAccountRequest>(
      partnerBankAccountSearchForm.setValue,
      getParsedSearchParams(AC_PARTNER_BANK_ACCOUNT_REVISION_CONFIRMED_LIST_QS_KEY).formSearchData,
    );

    const { waitingForApprovalSearchData, notCancelledWaitingForApprovalSearchData } = setWaitingTabSearchValue();

    const revisionCompletedSearchData = partnerBankAccountSearchForm.getValues();

    const waitingQs = makeSearchParamsPattern(
      {
        ...waitingForApprovalSearchData,
        pageNumber: waitingForConfirmationPageable.currentPage,
        rowCount: waitingForConfirmationPageable.sizePerPage,
      },

      AC_PARTNER_BANK_ACCOUNT_REVISION_WAITING_LIST_QS_KEY,
    );
    const completedQs = makeSearchParamsPattern(
      {
        ...revisionCompletedSearchData,
        pageNumber: revisionConfirmedPageable.currentPage,
        rowCount: revisionConfirmedPageable.sizePerPage,
      },
      AC_PARTNER_BANK_ACCOUNT_REVISION_CONFIRMED_LIST_QS_KEY,
    );
    addSearchParams(waitingQs + completedQs);

    try {
      const [waitingPartnerBankAccountList, partnerBankAccountList, waitingPartnerBankAccountListForTabCount] =
        await Promise.all([
          requestWaitingAnchorPartnerAccountList(
            waitingForConfirmationPageable.currentPage,
            waitingForConfirmationPageable.sizePerPage,
            waitingForApprovalSearchData,
          ),
          requestAnchorPartnerBankAccountList(
            revisionConfirmedPageable.currentPage,
            revisionConfirmedPageable.sizePerPage,
            revisionCompletedSearchData,
          ),
          requestWaitingAnchorPartnerAccountList(0, 1, notCancelledWaitingForApprovalSearchData),
        ]);

      ReactDOM.unstable_batchedUpdates(() => {
        setWaitingPartnerBankAccountList(waitingPartnerBankAccountList);
        setWaitingForConfirmationPageable(waitingPartnerBankAccountList);

        setPartnerBankAccountList(partnerBankAccountList);
        setRevisionConfirmedPageable(partnerBankAccountList);
        setWaitingPartnerBankAccountListForTabCount(waitingPartnerBankAccountListForTabCount);
      });
    } catch (error) {
      modal.show(error);
    }
  }

  const handleWaitingPartnerBankAccountSearchFormSubmit = async () => {
    try {
      const { waitingForApprovalSearchData, notCancelledWaitingForApprovalSearchData } = setWaitingTabSearchValue();

      const [waitingPartnerBankAccountList, waitingPartnerBankAccountListForTabCount] = await Promise.all([
        requestWaitingAnchorPartnerAccountList(
          0,
          waitingForConfirmationPageable.sizePerPage,
          waitingForApprovalSearchData,
        ),
        requestWaitingAnchorPartnerAccountList(0, 1, notCancelledWaitingForApprovalSearchData),
      ]);

      updateSearchParams(
        {
          ...waitingForApprovalSearchData,
          pageNumber: 0,
          rowCount: waitingForConfirmationPageable.sizePerPage,
        },
        AC_PARTNER_BANK_ACCOUNT_REVISION_WAITING_LIST_QS_KEY,
      );
      ReactDOM.unstable_batchedUpdates(() => {
        setWaitingPartnerBankAccountList(waitingPartnerBankAccountList);
        setWaitingForConfirmationPageable(waitingPartnerBankAccountList);
        setWaitingPartnerBankAccountListForTabCount(waitingPartnerBankAccountListForTabCount);
      });
    } catch (error) {
      modal.show(error);
    }
  };

  const fetchPartnerBankAccountList = async (pageNumber: number, rowCount: number) => {
    const data = partnerBankAccountSearchForm.getValues();
    try {
      const partnerBankAccountList = await requestAnchorPartnerBankAccountList(pageNumber, rowCount, data);

      updateSearchParams(
        {
          ...data,
          pageNumber,
          rowCount,
        },
        AC_PARTNER_BANK_ACCOUNT_REVISION_CONFIRMED_LIST_QS_KEY,
      );

      ReactDOM.unstable_batchedUpdates(() => {
        setPartnerBankAccountList(partnerBankAccountList);
        setRevisionConfirmedPageable(partnerBankAccountList);
      });
    } catch (error) {
      modal.show(error);
    }
  };

  const handlePartnerBankAccountSearchFormSubmit = async () => {
    await fetchPartnerBankAccountList(1, revisionConfirmedPageable.sizePerPage);
  };

  const renderWaitingView = () => {
    const onClickRemoveFilter = (e: any) => {
      e.preventDefault();
      waitingPartnerBankAccountSearchForm.reset();
    };

    const waitingForConfirmationPaginate = async (page: number, sizePerPage: number) => {
      const { waitingForApprovalSearchData } = setWaitingTabSearchValue(
        getParsedSearchParams(AC_PARTNER_BANK_ACCOUNT_REVISION_WAITING_LIST_QS_KEY).formSearchData,
      );

      try {
        const waitingPartnerBankAccountList = await requestWaitingAnchorPartnerAccountList(
          page,
          sizePerPage,
          waitingForApprovalSearchData,
        );
        updateSearchParams(
          {
            ...waitingForApprovalSearchData,
            pageNumber: page,
            rowCount: sizePerPage,
          },
          AC_PARTNER_BANK_ACCOUNT_REVISION_WAITING_LIST_QS_KEY,
        );

        ReactDOM.unstable_batchedUpdates(() => {
          setWaitingPartnerBankAccountList(waitingPartnerBankAccountList);
          setWaitingForConfirmationPageable(waitingPartnerBankAccountList);
        });
      } catch (error) {
        modal.show(error);
      }
    };

    return (
      <>
        <div className="content-area">
          <SectionTitle title={t('text:Search')}>
            <Button
              variant={ButtonVariantEnum.OUTLINED}
              color={ButtonColorEnum.SECONDARY}
              onClick={onClickRemoveFilter}
            >
              {t('text:Remove_Filter')}
            </Button>
          </SectionTitle>
          <SearchBorder>
            <div className="row">
              <SearchLabel label={t('text:Partner_Name')} />
              <SearchInput
                name={getWaitingPartnerBankAccountProperty('partnerName')}
                ref={waitingPartnerBankAccountSearchForm.register}
              />
              <SearchLabel label={t('text:Partner_Tax_Code')} />
              <SearchInput
                name={getWaitingPartnerBankAccountProperty('partnerTaxCode')}
                ref={waitingPartnerBankAccountSearchForm.register}
              />
            </div>
            <div className="row">
              <SearchLabel label={t('text:Associated_Master_Agreement_of_Anchor_Number')} />
              <SearchInput
                name={getWaitingPartnerBankAccountProperty('anchorAgreementNo')}
                ref={waitingPartnerBankAccountSearchForm.register}
              />
              <SearchLabel label={t('text:Currency')} />
              <SearchSelect
                selectOptions={getSelectOptions<CURRENCY_TYPE>('CURRENCY_TYPE', 'ALL', true)}
                name={getWaitingPartnerBankAccountProperty('currencyType')}
                ref={waitingPartnerBankAccountSearchForm.register}
              />
            </div>
            <div className="row">
              <SearchLabel label={t('text:Bank_Name')} />
              <SearchInput
                name={getWaitingPartnerBankAccountProperty('anchorPartnerAccountBankName')}
                ref={waitingPartnerBankAccountSearchForm.register}
              />
              <SearchLabel label={t('text:Bank_Account_Number')} />
              <SearchInput
                name={getWaitingPartnerBankAccountProperty('anchorPartnerAccount')}
                ref={waitingPartnerBankAccountSearchForm.register}
              />
            </div>
            <div className="row">
              <SearchLabel label={t('text:Bank_Account_Validity')} />
              <SearchSelect
                selectOptions={getSelectOptions<string>('BOOLEAN', 'ALL', true)}
                name={getWaitingPartnerBankAccountProperty('hasBankCode')}
                ref={waitingPartnerBankAccountSearchForm.register}
              />
              <SearchLabel label={t('text:Reviewed_By')} />
              <SearchSelect
                selectOptions={getSelectOptions<ENTERPRISE_TYPE>('ENTERPRISE_TYPE', 'ALL', true)}
                name={getWaitingPartnerBankAccountProperty('enterpriseType')}
                ref={waitingPartnerBankAccountSearchForm.register}
              />
            </div>
            <div className="row">
              <SearchLabel label={t('text:Approval_Status')} />

              <SearchSelect
                name={getWaitingPartnerBankAccountProperty('approvalTypes')}
                ref={waitingPartnerBankAccountSearchForm.register}
                selectOptions={[
                  {
                    label: t('text:All'),
                    value: WAITING_COMMON_APPROVAL_TYPE_ALL_VALUES,
                  },
                  {
                    label: t('text:Requested'),
                    value: COMMON_APPROVAL_TYPE.OPERATOR_REQUEST,
                  },
                  {
                    label: t('text:Reverted'),
                    value: COMMON_APPROVAL_TYPE.AUTHORIZER_RETURNED,
                  },
                  {
                    label: t('text:Cancelled'),
                    value: COMMON_APPROVAL_TYPE.OPERATOR_CANCEL,
                  },
                ]}
              />
              <SearchEmpty />
            </div>
          </SearchBorder>
          <div className="flex-center mt-3">
            <Button
              size={ButtonSizeEnum.LG}
              onClick={waitingPartnerBankAccountSearchForm.handleSubmit(
                handleWaitingPartnerBankAccountSearchFormSubmit,
              )}
            >
              {t('text:Search')}
            </Button>
          </div>
        </div>
        <div className="division-border" />
        <div className="content-area">
          <SectionTitle title={t('text:Result')} />
          <p className="total-data">
            {t('text:Total')} {waitingPartnerBankAccountList?.totalElements ?? 0}
          </p>
          <TableBorder>
            <TableHeader header={WAITING_FOR_CONFIRMATION_TABLE_HEADERS} />
            <TableBody numOfCol={WAITING_FOR_CONFIRMATION_TABLE_HEADERS.length}>
              {waitingPartnerBankAccountList?.content.map((item, i) => {
                return (
                  <Tr key={i}>
                    <Td data={item.partnerName} />
                    <Td data={item.partnerTaxCode} />
                    <Td data={item.anchorAgreementContractNo} />
                    <Td className="text-center" data={item.currencyType} />
                    <Td data={item.accountBankName} />
                    <Td data={item.account} />
                    <Td data={item.accountBankCodeId ? t('text:Yes') : t('text:No')} />
                    <Td data={t(`code:enterprise-type.${item.operatorEnterpriseType}`)} />
                    <Td
                      className={getStatusTextClass('COMMON_APPROVAL_TYPE', item.approvalType)}
                      data={t(`code:common-approval-type.${item.approvalType}`)}
                      format="code"
                    />
                    <TdLink
                      path={ROUTES_AC.MANAGE_PARTNER.BANK_ACCOUNT_WAITING_DETAIL_BUILD_PATH(
                        item.waitingAnchorPartnerAccountId,
                      )}
                    />
                  </Tr>
                );
              })}
            </TableBody>
          </TableBorder>
          <Pagination pageable={waitingForConfirmationPageable} paginate={waitingForConfirmationPaginate} />
        </div>
      </>
    );
  };

  const renderCompleteView = () => {
    const onClickRemoveFilter = (e: any) => {
      e.preventDefault();
      partnerBankAccountSearchForm.reset({});
    };

    const paginate = async (page: number, sizePerPage: number) => {
      await fetchPartnerBankAccountList(page, sizePerPage);
    };

    return (
      <>
        <div className="content-area">
          <SectionTitle title={t('text:Search')}>
            <Button
              variant={ButtonVariantEnum.OUTLINED}
              color={ButtonColorEnum.SECONDARY}
              onClick={onClickRemoveFilter}
            >
              {t('text:Remove_Filter')}
            </Button>
          </SectionTitle>
          <SearchBorder>
            <div className="row">
              <SearchLabel label={t('text:Partner_Name')} />
              <SearchInput
                name={getPartnerBankAccountProperty('anchorPartnerName')}
                ref={partnerBankAccountSearchForm.register}
              />
              <SearchLabel label={t('text:Partner_Tax_Code')} />
              <SearchInput
                name={getPartnerBankAccountProperty('anchorPartnerTaxCode')}
                ref={partnerBankAccountSearchForm.register}
              />
            </div>
            <div className="row">
              <SearchLabel label={t('text:Associated_Master_Agreement_of_Anchor_Number')} />
              <SearchInput
                name={getPartnerBankAccountProperty('anchorAgreementNo')}
                ref={partnerBankAccountSearchForm.register}
              />
              <SearchLabel label={t('text:Currency')} />
              <SearchSelect
                name={getPartnerBankAccountProperty('currencyType')}
                selectOptions={getSelectOptions<CURRENCY_TYPE>('CURRENCY_TYPE', 'ALL', true)}
                ref={partnerBankAccountSearchForm.register}
              />
            </div>
            <div className="row">
              <SearchLabel label={t('text:Bank_Name')} />
              <SearchInput
                name={getPartnerBankAccountProperty('anchorPartnerAccountBankName')}
                ref={partnerBankAccountSearchForm.register}
              />
              <SearchLabel label={t('text:Bank_Account_Number')} />
              <SearchInput
                name={getPartnerBankAccountProperty('anchorPartnerAccount')}
                ref={partnerBankAccountSearchForm.register}
              />
            </div>
            <div className="row">
              <SearchLabel label={t('text:Bank_Account_Validity')} />
              <SearchSelect
                selectOptions={getSelectOptions<string>('BOOLEAN', 'ALL', true)}
                name={getPartnerBankAccountProperty('hasBankCode')}
                ref={partnerBankAccountSearchForm.register}
              />
              <SearchEmpty />
            </div>
          </SearchBorder>
          <div className="flex-center mt-3">
            <Button
              size={ButtonSizeEnum.LG}
              onClick={partnerBankAccountSearchForm.handleSubmit(handlePartnerBankAccountSearchFormSubmit)}
            >
              {t('text:Search')}
            </Button>
          </div>
        </div>
        <div className="division-border" />
        <div className="content-area">
          <SectionTitle title={t('text:Result')} />
          <p className="total-data">
            {t('text:Total')} {partnerBankAccountList?.totalElements ?? 0}
          </p>
          <TableBorder>
            <TableHeader header={REVISION_CONFIRMED_TABLE_HEADERS} />
            <TableBody numOfCol={REVISION_CONFIRMED_TABLE_HEADERS.length}>
              {partnerBankAccountList?.content.map((item, i) => {
                return (
                  <Tr key={i}>
                    <Td data={item.partnerName} />
                    <Td data={item.partnerTaxCode} />
                    <Td data={item.anchorAgreementContractNo} />
                    <Td className="text-center" data={item.currencyType} />
                    <Td data={item.accountBankName} />
                    <Td data={item.account} />
                    <Td data={item.accountBankCodeId ? t('text:Yes') : t('text:No')} />
                    <TdLink
                      path={ROUTES_AC.MANAGE_PARTNER.BANK_ACCOUNT_CONFIRMED_DETAIL_BUILD_PATH(
                        item.anchorPartnerAccountId,
                      )}
                    />
                  </Tr>
                );
              })}
            </TableBody>
          </TableBorder>
          <Pagination pageable={revisionConfirmedPageable} paginate={paginate} />
        </div>
      </>
    );
  };

  return (
    <>
      <HeaderTitle title={t('text:Partner_Bank_Account')} />
      <GuideMessage
        message={[
          t('text:Please_review_the_bank_account_information_of_partners_in_the_Waiting_for_Approval_tab'),
          t('text:If_the_bank_account_information_is_invalid_payment_will_not_be_made_successfully'),
          t(
            'text:Once_the_Partners_financing_agreement_is_registered_on_the_platform_the_settlement_and_the_repayment_will_be_made_through_the_bank_account_information_on_the_financing_agreement',
          ),
        ]}
      />

      <Tab
        tabType="approvalStatus"
        tabList={[
          {
            tabItemCount:
              (waitingPartnerBankAccountSearchForm.getValues().approvalTypes as any) ===
              COMMON_APPROVAL_TYPE.OPERATOR_CANCEL
                ? 0
                : waitingPartnerBankAccountListForTabCount?.totalElements ?? 0,
          },
          { tabItemCount: revisionConfirmedPageable?.totalRows ?? 0 },
        ]}
        tabViewList={[renderWaitingView(), renderCompleteView()]}
      />
    </>
  );
}

export default AnchorPartnerBankAccountList;
