import type { MouseEventHandler } from 'react';
import { useTranslation } from 'react-i18next';

import Button, { ButtonColorEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import GuideMessage, { MessageType } from 'components/stateless/GuideMessage/GuideMessage';
import { AUTHORITY_TYPE, LOAN_APPROVAL_TYPE, LOAN_STATUS } from 'enums';
import type { SignInModel } from 'models/SignInModel';
import type { LoanDetailVOModel } from 'models/vo/LoanDetailVO';
import { getSignIn } from 'utils/storage/LocalStorage';

import type { UseFinancierFinancingDetailStateReturnType } from '../../useFinancierFinancingDetailState';

type UpdateFinancingStatusActionsKeyType =
  keyof UseFinancierFinancingDetailStateReturnType['updateFinancingStatusActions'];

interface FinancierFinancingDetailGuideMessageProps {
  useInterface: boolean;
  loanDetailData: LoanDetailVOModel;
  updateFinancingStatusActions: {
    [key in UpdateFinancingStatusActionsKeyType]: MouseEventHandler<HTMLButtonElement>;
  };
}

function FinancierFinancingDetailGuideMessage({
  useInterface,
  loanDetailData,
  updateFinancingStatusActions,
}: FinancierFinancingDetailGuideMessageProps) {
  const { t } = useTranslation(['format']);
  const signInModel: SignInModel | null = getSignIn();
  const { ADMIN, AUTHORIZER, OPERATOR } = AUTHORITY_TYPE;
  const { authorityType } = signInModel ?? {};
  const isAdmin = authorityType === ADMIN;
  const isOperator = authorityType === OPERATOR;
  const isAuthorizer = authorityType === AUTHORIZER;
  const {
    loanStatus,
    loanApprovalType,
    returnReason,
    rejectReason,
    cancelReason,
    expirationReason,
    factoringEnable,
    multipleRequested,
    cancelDisbursementReason,
  } = loanDetailData;

  const {
    onClickCancelConfirmRequest,
    onClickRequestFinancierLoanRepayment,
    onClickRequestFinancierLoanOverdue,
    onClickViewRepaymentHistory,
    onClickCancelRepayment,
    onClickConfirmRepaidStatus,
    onClickRevertRepaidStatusRequest,
    onClickCancelOverdue,
    onClickConfirmDelinquentStatus,
    onClickRevertDelinquentStatusRequest,
    onClickRemoveDelinquentStatus,
    onClickCancelOverdueRelease,
    onClickConfirmDelinquentStatusRemovalRequest,
    onClickRevertDelinquentStatusRemovalRequest,
    onClickExpiredStatusRequest,
    onClickCancelExpiredStatusRequest,
    onClickConfirmExpiredStatusRequest,
    onClickRevertExpiredStatusRequest,
    onClickExecuteLoanDisbursement,
    onClickRenewLoanTransactions,
    onClickCancelFinancingApproval,
    onClickCancelRejectionRequest,
    onClickConfirmRejectionRequest,
    onClickRevertRejectionRequest,
    onClickRequestCancelDisbursement,
    onClickCancelDisbursementCancellationRequest,
    onClickApproveDisbursementCancellation,
    onClickRevertDisbursementCancellation,
  } = updateFinancingStatusActions;

  const { DEALER_REQUESTED, DEALER_CANCELLED, REJECTED, APPROVED, DISBURSED, OVERDUE, REPAID, EXPIRATION } =
    LOAN_STATUS;

  const {
    OPERATOR_REPAID,
    OPERATOR_CLEAR_OVERDUE,
    OPERATOR_EXPIRATION,
    AUTHORIZER_RETURNED_EXPIRATION,
    AUTHORIZER_RETURNED_CLEAR_OVERDUE,
    AUTHORIZER_RETURNED_REPAID,
    OPERATOR_OVERDUE,
    AUTHORIZER_RETURNED_OVERDUE,
    OPERATOR_APPROVAL,
    AUTHORIZER_RETURNED_APPROVAL,
    OPERATOR_REQUEST_REJECTED,
    AUTHORIZER_RETURNED_REJECTED,
    OPERATOR_REQUEST_CANCELLATION_DISBURSEMENT,
    AUTHORIZER_RETURNED_CANCELLATION_DISBURSEMENT,
  } = LOAN_APPROVAL_TYPE;

  // 딜러 AUTHORIZER 대출 신청
  const renderRequestedLoanStatusMessage = () => {
    if (loanApprovalType === OPERATOR_APPROVAL) {
      // 은행 OPERATOR 지급 승인 요청
      if (isOperator) {
        return (
          <GuideMessage
            message={[
              t('text:The_request_for_the_Authorizers_financing_approval_has_been_submitted'),
              t('text:To_cancel_the_approval_request_click_on_the_Cancel_Confirm_Request_on_the_right'),
            ]}
          >
            {!multipleRequested
              ? {
                  button: (
                    <Button
                      variant={ButtonVariantEnum.OUTLINED}
                      color={ButtonColorEnum.SECONDARY}
                      onClick={onClickCancelConfirmRequest}
                    >
                      {t('text:Cancel_Confirm_Request')}
                    </Button>
                  ),
                }
              : undefined}
          </GuideMessage>
        );
      } else if (isAuthorizer) {
        return (
          <GuideMessage message={[t('text:The_request_for_the_Authorizers_financing_approval_has_been_submitted')]} />
        );
      }
    } else if (loanApprovalType === AUTHORIZER_RETURNED_APPROVAL) {
      // 은행 OPERATOR 지급 승인 요청 반려
      if (isOperator) {
        return (
          <GuideMessage
            messageType={MessageType.ALERT}
            message={[
              t('text:The_financing_approval_request_has_been_reverted'),
              t('text:Check_the_reason_for_the_revert_below'),
            ]}
            reasonTitle={t('text:REASON_FOR_REVERT')}
            reason={returnReason}
          >
            {!multipleRequested
              ? {
                  button: (
                    <Button
                      variant={ButtonVariantEnum.OUTLINED}
                      color={ButtonColorEnum.SECONDARY}
                      onClick={onClickCancelConfirmRequest}
                    >
                      {t('text:Cancel_Confirm_Request')}
                    </Button>
                  ),
                }
              : undefined}
          </GuideMessage>
        );
      } else if (isAuthorizer) {
        return (
          <GuideMessage
            message={[
              t('text:The_financing_approval_request_has_been_reverted'),
              t('text:Check_the_reason_for_the_revert_below'),
            ]}
            reasonTitle={t('text:REASON_FOR_REVERT')}
            reason={returnReason}
            messageType={MessageType.ALERT}
          />
        );
      }
    } else if (!loanApprovalType) {
      if (isOperator) {
        return (
          <GuideMessage
            message={[
              t('text:Check_the_financing_request_information_below_and_proceed_with_confirmation'),
              t('text:Click_on_the_Confirm_button_to_request_approval_from_the_Financier_Authorizer'),
            ]}
          />
        );
      }
    }
  };

  // 대출 신청 취소
  const renderCanceledLoanStatusMessage = () => {
    return (
      <GuideMessage
        messageType={MessageType.ALERT}
        message={[
          t('text:The_financing_application_has_been_cancelled'),
          t('text:Please_check_the_reason_for_the_cancellation_below'),
        ]}
        reasonTitle={t('text:REASON_FOR_REJECTION')}
        reason={cancelReason}
      />
    );
  };

  // 대출 거절
  const renderRejectedLoanStatusMessage = () => {
    return (
      <GuideMessage
        messageType={MessageType.ALERT}
        message={[
          t('text:The_financing_application_has_been_rejected'),
          t('text:Check_the_reason_for_the_rejection_below'),
        ]}
        reasonTitle={t('text:REASON_FOR_REJECTION')}
        reason={rejectReason}
      />
    );
  };

  // 승인 완료
  const renderApprovedLoanStatusMessage = () => {
    if (loanApprovalType === OPERATOR_REQUEST_REJECTED) {
      // 은행 OPERATOR 대출 승인 취소 요청
      if (isAdmin && !useInterface) {
        return (
          <GuideMessage
            messageType={MessageType.ALERT}
            message={[
              t('text:The_financing_rejection_has_been_requested'),
              t('text:See_the_reason_for_the_rejection_below'),
            ]}
            reasonTitle={t('text:REASON_FOR_REJECTION')}
            reason={rejectReason}
          />
        );
      } else if (isOperator && !useInterface) {
        return (
          <GuideMessage
            messageType={MessageType.ALERT}
            message={[
              t(
                'text:The_financing_rejection_has_been_requested_To_cancel_the_request,_click_on_the_“Cancel_Rejection_Request”_button_on_the_right',
              ),
            ]}
            reasonTitle={t('text:REASON_FOR_REJECTION')}
            reason={rejectReason}
          >
            {{
              button: (
                <Button
                  variant={ButtonVariantEnum.OUTLINED}
                  color={ButtonColorEnum.SECONDARY}
                  onClick={onClickCancelRejectionRequest}
                >
                  {t('text:Cancel_Rejection_Request')}
                </Button>
              ),
            }}
          </GuideMessage>
        );
      } else if (isAuthorizer && !useInterface) {
        return (
          <GuideMessage
            messageType={MessageType.ALERT}
            message={[
              t('text:The_financing_rejection_has_been_requested'),
              t('text:Click_on_the_buttons_to_the_right_to_confirm_or_revert_the_financing_rejection_request'),
            ]}
            reasonTitle={t('text:REASON_FOR_REJECTION')}
            reason={rejectReason}
          >
            {{
              button: (
                <>
                  <Button onClick={onClickConfirmRejectionRequest}>{t('text:Confirm_Rejection_Request')}</Button>
                  <Button
                    variant={ButtonVariantEnum.OUTLINED}
                    color={ButtonColorEnum.SECONDARY}
                    onClick={onClickRevertRejectionRequest}
                  >
                    {t('text:Revert_Rejection_Request')}
                  </Button>
                </>
              ),
            }}
          </GuideMessage>
        );
      }
    } else if (loanApprovalType === AUTHORIZER_RETURNED_REJECTED) {
      // 은행 OPERATOR 대출 승인 취소 요청 반려
      if (isAdmin && !useInterface) {
        return (
          <GuideMessage
            messageType={MessageType.ALERT}
            message={[
              t('text:The_financing_rejection_request_has_been_reverted'),
              t('text:Check_the_reason_for_the_revert_below'),
            ]}
            reasonTitle={t('text:REASON_FOR_REVERT')}
            reason={returnReason}
          />
        );
      } else if (isOperator && !useInterface) {
        return (
          <GuideMessage
            messageType={MessageType.ALERT}
            message={[
              t('text:The_financing_rejection_request_has_been_reverted'),
              t('text:Check_the_reason_for_the_revert_below'),
            ]}
            reasonTitle={t('text:REASON_FOR_REVERT')}
            reason={returnReason}
          >
            {{
              button: (
                <Button
                  variant={ButtonVariantEnum.OUTLINED}
                  color={ButtonColorEnum.SECONDARY}
                  onClick={onClickCancelRejectionRequest}
                >
                  {t('text:Cancel_Rejection_Request')}
                </Button>
              ),
            }}
          </GuideMessage>
        );
      } else if (isAuthorizer && !useInterface) {
        return (
          <GuideMessage
            messageType={MessageType.ALERT}
            message={[
              t('text:The_financing_rejection_request_has_been_reverted'),
              t('text:Check_the_reason_for_the_revert_below'),
            ]}
            reasonTitle={t('text:REASON_FOR_REVERT')}
            reason={returnReason}
          />
        );
      }
    } else if (!loanApprovalType) {
      if (isAdmin) {
        return <GuideMessage message={[t('text:The_financing_application_has_been_approved')]} />;
      } else if (isOperator && !useInterface) {
        return (
          <GuideMessage
            message={[
              t('text:The_financing_application_has_been_approved'),
              t(
                'text:After_the_financing_has_been_disbursed_click_on_the_Disbursement_Confirmed_button_to_the_right_and_enter_the_disbursement_information',
              ),
              t(
                'text:If_the_financing_cannot_be_disbursed_and_the_approval_must_be_cancelled,_click_on_the_“Cancel_Financing_Approval”_button_on_the_right',
              ),
            ]}
          >
            {{
              button: (
                <>
                  <Button onClick={onClickExecuteLoanDisbursement}>{t('text:Disbursement_Confirmed')}</Button>
                  {!multipleRequested && (
                    <Button
                      variant={ButtonVariantEnum.OUTLINED}
                      color={ButtonColorEnum.SECONDARY}
                      onClick={onClickCancelFinancingApproval}
                    >
                      {t('text:Cancel_Financing_Approval')}
                    </Button>
                  )}
                </>
              ),
            }}
          </GuideMessage>
        );
      } else if (isOperator || isAuthorizer) {
        return (
          <GuideMessage
            message={[
              t('text:The_financing_application_has_been_approved'),
              t(
                'text:After_the_financing_has_been_disbursed_click_on_the_Disbursement_Confirmed_button_to_the_right_and_enter_the_disbursement_information',
              ),
            ]}
          >
            {{
              button: <Button onClick={onClickExecuteLoanDisbursement}>{t('text:Disbursement_Confirmed')}</Button>,
            }}
          </GuideMessage>
        );
      }
    }
  };

  // 대출 실행 완료
  const renderDisbursedLoanStatusMessage = () => {
    if (loanApprovalType === OPERATOR_REPAID) {
      // 은행 OPERATOR 대출 상환완료 요청
      if (isOperator && !useInterface) {
        const operatorRepaidStatusOperatorGuideMessage = [
          t('text:The_request_to_change_the_financing_status_to_Repaid_has_been_submitted_to_the_Authorizer'),
          t('text:The_financing_status_will_change_when_the_Authorizer_approves_the_request'),
          t(
            'text:Cancel_the_repaid_status_request_before_the_Authorizers_approval_by_clicking_on_the_Cancel_Repaid_Status_Request_button_on_the_right',
          ),
          !factoringEnable &&
            t(
              'text:If_there_are_any_repayment_transactions_before_the_financing_is_completely_repaid_click_on_the_Update_Repayment_Transaction_button_to_reflect_the_changes',
            ),
        ];

        return (
          <GuideMessage message={operatorRepaidStatusOperatorGuideMessage}>
            {{
              button: (
                <>
                  <Button
                    variant={ButtonVariantEnum.OUTLINED}
                    color={ButtonColorEnum.SECONDARY}
                    onClick={onClickCancelRepayment}
                  >
                    {t('text:Cancel_Repaid_Status_Request')}
                  </Button>
                  {!factoringEnable && (
                    <Button onClick={onClickRenewLoanTransactions}>{t('text:Update_Repayment_Transaction')}</Button>
                  )}
                </>
              ),
              link: !factoringEnable ? (
                <button className="guide-message-link-btn" onClick={onClickViewRepaymentHistory}>
                  {t('text:View_Repayment_History')}
                </button>
              ) : undefined,
            }}
          </GuideMessage>
        );
      } else if (isAuthorizer && !useInterface) {
        const operatorRepaidStatusAuthorizerGuideMessage = [
          t('text:The_repaid_status_has_been_requested'),
          t(
            'text:Check_the_repayment_information_and_approve_the_request_by_clicking_on_the_Confirm_Repaid_Status_Button',
          ),
          t(
            'text:If_the_repayment_has_not_been_completed_click_on_the_Revert_Repaid_Status_Request_button_and_revert_the_request',
          ),
          !factoringEnable &&
            t(
              'text:If_there_are_any_repayment_transactions_before_the_financing_is_completely_repaid_click_on_the_Update_Repayment_Transaction_button_to_reflect_the_changes',
            ),
        ];

        return (
          <GuideMessage message={operatorRepaidStatusAuthorizerGuideMessage}>
            {{
              button: (
                <>
                  <Button onClick={onClickConfirmRepaidStatus}>{t('text:Confirm_Repaid_Status')}</Button>
                  <Button
                    variant={ButtonVariantEnum.OUTLINED}
                    color={ButtonColorEnum.SECONDARY}
                    onClick={onClickRevertRepaidStatusRequest}
                  >
                    {t('text:Revert_Repaid_Status_Request')}
                  </Button>
                  {!factoringEnable && (
                    <Button onClick={onClickRenewLoanTransactions}>{t('text:Update_Repayment_Transaction')}</Button>
                  )}
                </>
              ),
              link: !factoringEnable ? (
                <button className="guide-message-link-btn" onClick={onClickViewRepaymentHistory}>
                  {t('text:View_Repayment_History')}
                </button>
              ) : undefined,
            }}
          </GuideMessage>
        );
      }
    } else if (loanApprovalType === AUTHORIZER_RETURNED_REPAID) {
      // 은행 OPERATOR 대출 상환완료 요청 반려
      if (isOperator && !useInterface) {
        const AuthorizerReturnedRepaidStatusOperatorGuideMessage = [
          t('text:The_repaid_status_request_has_been_reverted'),
          t('text:Check_the_reason_for_the_rejection_below'),
          t(
            'text:Cancel_the_repaid_status_request_before_the_Authorizers_approval_by_clicking_on_the_Cancel_Repaid_Status_Request_button_on_the_right',
          ),
          !factoringEnable &&
            t(
              'text:If_there_are_any_repayment_transactions_before_the_financing_is_completely_repaid_click_on_the_Update_Repayment_Transaction_button_to_reflect_the_changes',
            ),
        ];

        return (
          <GuideMessage
            messageType={MessageType.ALERT}
            message={AuthorizerReturnedRepaidStatusOperatorGuideMessage}
            reasonTitle={t('text:REASON_FOR_REVERT')}
            reason={returnReason}
          >
            {{
              button: (
                <>
                  <Button
                    variant={ButtonVariantEnum.OUTLINED}
                    color={ButtonColorEnum.SECONDARY}
                    onClick={onClickCancelRepayment}
                  >
                    {t('text:Cancel_Repaid_Status_Request')}
                  </Button>
                  {!factoringEnable && (
                    <Button onClick={onClickRenewLoanTransactions}>{t('text:Update_Repayment_Transaction')}</Button>
                  )}
                </>
              ),
              link: !factoringEnable ? (
                <button className="guide-message-link-btn" onClick={onClickViewRepaymentHistory}>
                  {t('text:View_Repayment_History')}
                </button>
              ) : undefined,
            }}
          </GuideMessage>
        );
      } else if (isAuthorizer && !useInterface) {
        const AuthorizerReturnedRepaidStatusAuthorizerGuideMessage = [
          t('text:The_repaid_status_request_has_been_reverted'),
          t('text:Check_the_reason_for_the_revert_below'),
          !factoringEnable &&
            t(
              'text:If_there_are_any_repayment_transactions_before_the_financing_is_completely_repaid_click_on_the_Update_Repayment_Transaction_button_to_reflect_the_changes',
            ),
        ];

        return (
          <GuideMessage
            messageType={MessageType.ALERT}
            message={AuthorizerReturnedRepaidStatusAuthorizerGuideMessage}
            reasonTitle={t('text:REASON_FOR_REVERT')}
            reason={returnReason}
          >
            {{
              button: !factoringEnable ? (
                <Button onClick={onClickRenewLoanTransactions}>{t('text:Update_Repayment_Transaction')}</Button>
              ) : undefined,
              link: !factoringEnable ? (
                <button className="guide-message-link-btn" onClick={onClickViewRepaymentHistory}>
                  {t('text:View_Repayment_History')}
                </button>
              ) : undefined,
            }}
          </GuideMessage>
        );
      }
    } else if (loanApprovalType === OPERATOR_OVERDUE) {
      // 은행 OPERATOR 대출 연체 요청
      if (isOperator && !useInterface) {
        const operatorOverdueStatusOperatorGuideMessage = [
          t('text:The_request_to_change_the_financing_status_to_Delinquent_has_been_submitted_to_the_Authorizer'),
          t('text:The_financing_status_will_change_when_the_Authorizer_approves_the_request'),
          !factoringEnable &&
            t(
              'text:If_there_are_any_repayment_transactions_before_the_financing_is_completely_repaid_click_on_the_Update_Repayment_Transaction_button_to_reflect_the_changes',
            ),
        ];

        return (
          <GuideMessage message={operatorOverdueStatusOperatorGuideMessage}>
            {{
              button: (
                <>
                  <Button
                    variant={ButtonVariantEnum.OUTLINED}
                    color={ButtonColorEnum.SECONDARY}
                    onClick={onClickCancelOverdue}
                  >
                    {t('text:Cancel_Delinquent_Status_Request')}
                  </Button>
                  {!factoringEnable && (
                    <Button onClick={onClickRenewLoanTransactions}>{t('text:Update_Repayment_Transaction')}</Button>
                  )}
                </>
              ),
            }}
          </GuideMessage>
        );
      } else if (isAuthorizer && !useInterface) {
        const operatorOverdueStatusAuthorizerGuideMessage = [
          t('text:The_delinquent_status_has_been_requested'),
          t(
            'text:Check_the_financing_information_and_approve_the_request_by_clicking_on_the_Confirm_Delinquent_Status_button',
          ),
          t(
            'text:If_the_financing_is_not_delinquent_click_on_the_Revert_Delinquent_Status_Request_button_and_revert_the_request',
          ),
          !factoringEnable &&
            t(
              'text:If_there_are_any_repayment_transactions_before_the_financing_is_completely_repaid_click_on_the_Update_Repayment_Transaction_button_to_reflect_the_changes',
            ),
        ];

        return (
          <GuideMessage messageType={MessageType.ALERT} message={operatorOverdueStatusAuthorizerGuideMessage}>
            {{
              button: (
                <>
                  <Button onClick={onClickConfirmDelinquentStatus}>{t('text:Confirm_Delinquent_Status')}</Button>
                  <Button
                    variant={ButtonVariantEnum.OUTLINED}
                    color={ButtonColorEnum.SECONDARY}
                    onClick={onClickRevertDelinquentStatusRequest}
                  >
                    {t('text:Revert_Delinquent_Status_Request')}
                  </Button>
                  {!factoringEnable && (
                    <Button onClick={onClickRenewLoanTransactions}>{t('text:Update_Repayment_Transaction')}</Button>
                  )}
                </>
              ),
            }}
          </GuideMessage>
        );
      }
    } else if (loanApprovalType === AUTHORIZER_RETURNED_OVERDUE) {
      const authorizerReturnedOverdueStatusOperatorGuideMessage = [
        t('text:The_delinquent_status_request_has_been_reverted'),
        t('text:Check_the_reason_for_the_revert_below'),
        !factoringEnable &&
          t(
            'text:If_there_are_any_repayment_transactions_before_the_financing_is_completely_repaid_click_on_the_Update_Repayment_Transaction_button_to_reflect_the_changes',
          ),
      ];
      // 은행 OPERATOR 대출 연체 요청 반려
      if (isOperator && !useInterface) {
        return (
          <GuideMessage
            messageType={MessageType.ALERT}
            message={authorizerReturnedOverdueStatusOperatorGuideMessage}
            reasonTitle={t('text:REASON_FOR_REVERT')}
            reason={returnReason}
          >
            {{
              button: (
                <>
                  <Button
                    variant={ButtonVariantEnum.OUTLINED}
                    color={ButtonColorEnum.SECONDARY}
                    onClick={onClickCancelOverdue}
                  >
                    {t('text:Cancel_Delinquent_Status_Request')}
                  </Button>
                  {!factoringEnable && (
                    <Button onClick={onClickRenewLoanTransactions}>{t('text:Update_Repayment_Transaction')}</Button>
                  )}
                </>
              ),
            }}
          </GuideMessage>
        );
      } else if (isAuthorizer && !useInterface) {
        const authorizerReturnedOverdueStatusAuthorizerGuideMessage = [
          t('text:The_delinquent_status_request_has_been_reverted'),
          t('text:Check_the_reason_for_the_revert_below'),
          !factoringEnable &&
            t(
              'text:If_there_are_any_repayment_transactions_before_the_financing_is_completely_repaid_click_on_the_Update_Repayment_Transaction_button_to_reflect_the_changes',
            ),
        ];

        return (
          <GuideMessage
            messageType={MessageType.ALERT}
            message={authorizerReturnedOverdueStatusAuthorizerGuideMessage}
            reasonTitle={t('text:REASON_FOR_REVERT')}
            reason={returnReason}
          >
            {!factoringEnable
              ? {
                  button: (
                    <Button onClick={onClickRenewLoanTransactions}>{t('text:Update_Repayment_Transaction')}</Button>
                  ),
                }
              : undefined}
          </GuideMessage>
        );
      }
    } else if (loanApprovalType === OPERATOR_REQUEST_CANCELLATION_DISBURSEMENT && !useInterface) {
      if (isOperator) {
        return (
          <GuideMessage
            messageType={MessageType.ALERT}
            message={[
              t('text:The_financing_disbursement_cancellation_has_been_requested'),
              t('text:Click_on_the_Cancel_Disbursement_Cancellation_Request_button_on_the_right'),
            ]}
            reasonTitle={t('text:REASON_FOR_CANCELLATION')}
            reason={cancelDisbursementReason}
          >
            {{
              button: (
                <Button
                  onClick={onClickCancelDisbursementCancellationRequest}
                  variant={ButtonVariantEnum.OUTLINED}
                  color={ButtonColorEnum.SECONDARY}
                >
                  {t('text:Cancel_Disbursement_Cancellation_Request')}
                </Button>
              ),
            }}
          </GuideMessage>
        );
      } else if (isAuthorizer) {
        return (
          <GuideMessage
            messageType={MessageType.ALERT}
            message={[
              t('text:The_financing_disbursement_cancellation_has_been_requested'),
              t(
                'text:Click_on_the_buttons_to_the_right_to_confirm_or_revert_the_financing_disbursement_cancellation_request',
              ),
            ]}
            reasonTitle={t('text:REASON_FOR_CANCELLATION')}
            reason={cancelDisbursementReason}
          >
            {{
              button: (
                <>
                  <Button onClick={onClickApproveDisbursementCancellation}>
                    {t('text:Confirm_Disbursement_Cancellation_Request')}
                  </Button>
                  <Button
                    onClick={onClickRevertDisbursementCancellation}
                    variant={ButtonVariantEnum.OUTLINED}
                    color={ButtonColorEnum.SECONDARY}
                  >
                    {t('text:Revert_Disbursement_Cancellation_Request')}
                  </Button>
                </>
              ),
            }}
          </GuideMessage>
        );
      } else if (isAdmin) {
        return (
          <GuideMessage
            messageType={MessageType.ALERT}
            message={[t('text:The_financing_disbursement_cancellation_has_been_requested')]}
            reasonTitle={t('text:REASON_FOR_CANCELLATION')}
            reason={cancelDisbursementReason}
          />
        );
      }
    } else if (loanApprovalType === AUTHORIZER_RETURNED_CANCELLATION_DISBURSEMENT && !useInterface) {
      if (isOperator) {
        return (
          <GuideMessage
            messageType={MessageType.ALERT}
            message={[
              t('text:The_financing_disbursement_cancellation_has_been_reverted'),
              t('text:Check_the_reason_for_the_revert_below'),
              t(
                'text:Cancel_the_disbursement_cancellation_request_before_the_Authorizers_approval_by_clicking_on_the_Cancel_Disbursement_Cancellation_Request_button_on_the_right',
              ),
            ]}
            reasonTitle={t('text:REASON_FOR_REVERT')}
            reason={returnReason}
          >
            {{
              button: (
                <>
                  <Button
                    onClick={onClickCancelDisbursementCancellationRequest}
                    variant={ButtonVariantEnum.OUTLINED}
                    color={ButtonColorEnum.SECONDARY}
                  >
                    {t('text:Cancel_Disbursement_Cancellation_Request')}
                  </Button>
                  <Button onClick={onClickRenewLoanTransactions}>{t('text:Update_Repayment_Transaction')}</Button>
                </>
              ),
            }}
          </GuideMessage>
        );
      } else if (isAuthorizer) {
        return (
          <GuideMessage
            messageType={MessageType.ALERT}
            message={[
              t('text:The_financing_disbursement_cancellation_has_been_reverted'),
              t('text:Check_the_reason_for_the_revert_below'),
            ]}
            reasonTitle={t('text:REASON_FOR_REVERT')}
            reason={returnReason}
          >
            {{
              button: <Button onClick={onClickRenewLoanTransactions}>{t('text:Update_Repayment_Transaction')}</Button>,
            }}
          </GuideMessage>
        );
      } else if (isAdmin) {
        return (
          <GuideMessage
            messageType={MessageType.ALERT}
            message={[
              t('text:The_financing_disbursement_cancellation_has_been_reverted'),
              t('text:Check_the_reason_for_the_revert_below'),
            ]}
            reasonTitle={t('text:REASON_FOR_REVERT')}
            reason={returnReason}
          />
        );
      }
    } else if (!loanApprovalType) {
      if (isOperator && !useInterface) {
        const noneLoanApprovalTypeOperatorGuideMessage = [
          t('text:The_financing_has_been_disbursed'),
          !factoringEnable &&
            t(
              'text:If_there_are_any_repayment_transactions_before_the_financing_is_completely_repaid_click_on_the_Update_Repayment_Transaction_button_to_reflect_the_changes',
            ),
          t(
            'text:If_the_repayment_has_been_completed_click_on_the_Repaid_Status_Request_button_and_enter_the_repayment_information',
          ),
          t(
            'text:If_the_repayment_is_delinquent_click_on_the_Delinquent_Status_Request_button_and_request_the_Authorizers_approval_to_update_the_financing_status_to_Delinquent',
          ),
          t(
            'text:If_you_would_like_to_request_a_financing_disbursement_cancellation_click_the_Cancel_Financing_Disbursement_button',
          ),
        ];

        return (
          <GuideMessage message={noneLoanApprovalTypeOperatorGuideMessage}>
            {{
              button: (
                <>
                  <Button onClick={onClickRenewLoanTransactions}>{t('text:Update_Repayment_Transaction')}</Button>
                  <Button onClick={onClickRequestFinancierLoanRepayment}>{t('text:Repaid_Status_Request')}</Button>
                  <Button
                    variant={ButtonVariantEnum.OUTLINED}
                    color={ButtonColorEnum.SECONDARY}
                    onClick={onClickRequestFinancierLoanOverdue}
                  >
                    {t('text:Delinquent_Status_Request')}
                  </Button>
                  <Button
                    variant={ButtonVariantEnum.OUTLINED}
                    color={ButtonColorEnum.SECONDARY}
                    onClick={onClickRequestCancelDisbursement}
                  >
                    {t('text:Cancel_Financing_Disbursement')}
                  </Button>
                </>
              ),
            }}
          </GuideMessage>
        );
      } else if (isAuthorizer && !useInterface && !factoringEnable) {
        return (
          <GuideMessage
            message={[
              t(
                'text:If_there_are_any_repayment_transactions_before_the_financing_is_completely_repaid_click_on_the_Update_Repayment_Transaction_button_to_reflect_the_changes',
              ),
            ]}
          >
            {{
              button: <Button onClick={onClickRenewLoanTransactions}>{t('text:Update_Repayment_Transaction')}</Button>,
            }}
          </GuideMessage>
        );
      } else if (useInterface && !isAdmin && !factoringEnable) {
        return (
          <GuideMessage
            message={[
              t('text:The_financing_has_been_disbursed'),
              t(
                'text:If_there_are_any_repayment_transactions_before_the_financing_is_completely_repaid_click_on_the_Update_Repayment_Transaction_button_to_reflect_the_changes',
              ),
            ]}
          >
            {{
              button: <Button onClick={onClickRenewLoanTransactions}>{t('text:Update_Repayment_Transaction')}</Button>,
            }}
          </GuideMessage>
        );
      }
    }
  };

  // 대출 연체
  const renderOverdueLoanStatusMessage = () => {
    if (loanApprovalType === OPERATOR_REPAID) {
      // 은행 OPERATOR 대출 상환완료 요청
      if (!useInterface && isOperator) {
        return (
          <GuideMessage
            messageType={MessageType.ALERT}
            message={[
              t('text:The_repayment_status_has_been_requested'),
              t('text:Depending_on_the_confirmation_by_Authorizer_the_financing_status_will_change'),
              t(
                'text:Cancelling_the_repayment_status_request_is_only_possible_before_confirmation,_and_can_be_done_by_clicking_on_the_button_to_the_left',
              ),
            ]}
            reasonTitle={t('text:REASON_FOR_REVERT')}
            reason={returnReason}
          >
            {{
              button: (
                <Button
                  variant={ButtonVariantEnum.OUTLINED}
                  color={ButtonColorEnum.SECONDARY}
                  onClick={onClickCancelRepayment}
                >
                  {t('text:Cancel_Repayment_Status_Request')}
                </Button>
              ),
              link: !factoringEnable ? (
                <button className="guide-message-link-btn" onClick={onClickViewRepaymentHistory}>
                  {t('text:View_Repayment_History')}
                </button>
              ) : undefined,
            }}
          </GuideMessage>
        );
      } else if (!useInterface && isAuthorizer) {
        return (
          <GuideMessage
            message={[
              t('text:The_repayment_status_has_been_requested'),
              t('text:Please_proceed_with_confirmation_after_checking_the_repayment_information'),
            ]}
          >
            {{
              button: (
                <Button
                  variant={ButtonVariantEnum.OUTLINED}
                  color={ButtonColorEnum.SECONDARY}
                  onClick={onClickRevertRepaidStatusRequest}
                >
                  {t('text:Revert_Repayment_Status_Request')}
                </Button>
              ),
              link: !factoringEnable ? (
                <button className="guide-message-link-btn" onClick={onClickViewRepaymentHistory}>
                  {t('text:View_Repayment_History')}
                </button>
              ) : undefined,
            }}
          </GuideMessage>
        );
      }
    } else if (loanApprovalType === OPERATOR_CLEAR_OVERDUE) {
      // 은행 OPERATOR 대출 연체해제 요청
      if (!useInterface && isOperator) {
        return (
          <GuideMessage
            messageType={MessageType.ALERT}
            message={[t('text:The_delinquent_status_cancellation_has_been_requested')]}
          >
            {{
              button: (
                <Button
                  variant={ButtonVariantEnum.OUTLINED}
                  color={ButtonColorEnum.SECONDARY}
                  onClick={onClickCancelOverdueRelease}
                >
                  {t('text:Cancel_Delinquent_Status_Removal_Request')}
                </Button>
              ),
            }}
          </GuideMessage>
        );
      } else if (!useInterface && isAuthorizer) {
        return (
          <GuideMessage
            messageType={MessageType.ALERT}
            message={[t('text:The_delinquent_status_cancellation_has_been_requested')]}
          >
            {{
              button: (
                <>
                  <Button onClick={onClickConfirmDelinquentStatusRemovalRequest}>
                    {t('text:Confirm_Delinquent_Status_Removal_Request')}
                  </Button>
                  <Button
                    variant={ButtonVariantEnum.OUTLINED}
                    color={ButtonColorEnum.SECONDARY}
                    onClick={onClickRevertDelinquentStatusRemovalRequest}
                  >
                    {t('text:Revert_Delinquent_Status_Removal_Request')}
                  </Button>
                </>
              ),
            }}
          </GuideMessage>
        );
      }
    } else if (loanApprovalType === OPERATOR_EXPIRATION) {
      // 은행 OPERATOR 대출 기타만료 요청
      if (isOperator) {
        return (
          <GuideMessage
            messageType={MessageType.ALERT}
            message={[
              t('text:The_expired_status_has_been_requested'),
              t('text:The_financing_status_will_change_when_the_Authorizer_approves_the_request'),
              t(
                'text:Cancel_the_expired_status_request_before_the_Authorizers_approval_by_clicking_on_the_Cancel_Expired_Status_Request_button_on_the_right',
              ),
            ]}
            reasonTitle={t('text:REASON_FOR_EXPIRATION')}
            reason={expirationReason}
          >
            {{
              button: (
                <Button
                  variant={ButtonVariantEnum.OUTLINED}
                  color={ButtonColorEnum.SECONDARY}
                  onClick={onClickCancelExpiredStatusRequest}
                >
                  {t('text:Cancel_Expired_Status_Request')}
                </Button>
              ),
            }}
          </GuideMessage>
        );
      } else if (isAuthorizer) {
        return (
          <GuideMessage
            messageType={MessageType.ALERT}
            message={[
              t('text:The_expired_status_has_been_requested'),
              t(
                'text:Check_the_reason_for_the_expiration_below_and_click_on_the_Confirm_Expired_Status_Request_button_to_approve_the_request',
              ),
              t(
                'text:If_the_financing_has_not_expired_click_on_the_Revert_Expired_Status_Request_button_and_revert_the_request',
              ),
            ]}
            reasonTitle={t('text:REASON_FOR_EXPIRATION')}
            reason={expirationReason}
          >
            {{
              button: (
                <>
                  <Button onClick={onClickConfirmExpiredStatusRequest}>
                    {t('text:Confirm_Expired_Status_Request')}
                  </Button>
                  <Button
                    variant={ButtonVariantEnum.OUTLINED}
                    color={ButtonColorEnum.SECONDARY}
                    onClick={onClickRevertExpiredStatusRequest}
                  >
                    {t('text:Revert_Expired_Status_Request')}
                  </Button>
                </>
              ),
            }}
          </GuideMessage>
        );
      }
    } else if (loanApprovalType === AUTHORIZER_RETURNED_EXPIRATION) {
      // 은행 OPERATOR 대출 기타만료 요청 반려
      if (isOperator) {
        const authorizerReturnedExpirationStatusOperatorGuideMessage = [
          t('text:The_expired_status_request_has_been_reverted'),
          t('text:Check_the_reason_for_the_revert_below'),
          t(
            'text:Cancel_the_expired_status_request_before_the_Authorizers_approval_by_clicking_on_the_Cancel_Expired_Status_Request_button_on_the_right',
          ),
          !factoringEnable &&
            t(
              'text:If_there_are_any_repayment_transactions_before_the_financing_is_completely_repaid_click_on_the_Update_Repayment_Transaction_button_to_reflect_the_changes',
            ),
        ];

        return (
          <GuideMessage
            messageType={MessageType.ALERT}
            message={authorizerReturnedExpirationStatusOperatorGuideMessage}
            reasonTitle={t('text:REASON_FOR_REVERT')}
            reason={returnReason}
          >
            {{
              button: (
                <>
                  <Button
                    variant={ButtonVariantEnum.OUTLINED}
                    color={ButtonColorEnum.SECONDARY}
                    onClick={onClickCancelExpiredStatusRequest}
                  >
                    {t('text:Cancel_Expired_Status_Request')}
                  </Button>
                  {!factoringEnable && (
                    <Button onClick={onClickRenewLoanTransactions}>{t('text:Update_Repayment_Transaction')}</Button>
                  )}
                </>
              ),
            }}
          </GuideMessage>
        );
      } else if (isAuthorizer) {
        const authorizerReturnedExpirationStatusAuthorizerGuideMessage = [
          t('text:The_expired_status_request_has_been_reverted'),
          t('text:Check_the_reason_for_the_revert_below'),
          !factoringEnable &&
            t(
              'text:If_there_are_any_repayment_transactions_before_the_financing_is_completely_repaid_click_on_the_Update_Repayment_Transaction_button_to_reflect_the_changes',
            ),
        ];

        return (
          <GuideMessage
            messageType={MessageType.ALERT}
            message={authorizerReturnedExpirationStatusAuthorizerGuideMessage}
            reasonTitle={t('text:REASON_FOR_REVERT')}
            reason={returnReason}
          >
            {!factoringEnable
              ? {
                  button: (
                    <Button onClick={onClickRenewLoanTransactions}>{t('text:Update_Repayment_Transaction')}</Button>
                  ),
                }
              : undefined}
          </GuideMessage>
        );
      }
    } else if (loanApprovalType === AUTHORIZER_RETURNED_CLEAR_OVERDUE) {
      // 은행 OPERATOR 대출 연체해제 요청 반려
      if (!useInterface) {
        return (
          <GuideMessage
            messageType={MessageType.ALERT}
            message={[
              t('text:The_delinquent_status_cancellation_request_has_been_reverted'),
              t('text:Check_the_reason_for_the_revert_below'),
            ]}
            reasonTitle={t('text:REASON_FOR_REVERT')}
            reason={returnReason}
          >
            {{
              button: (
                <Button
                  variant={ButtonVariantEnum.OUTLINED}
                  color={ButtonColorEnum.SECONDARY}
                  style={{ display: isOperator ? 'block' : 'none' }}
                  onClick={onClickCancelOverdueRelease}
                >
                  {t('text:Cancel_Delinquent_Status_Request')}
                </Button>
              ),
            }}
          </GuideMessage>
        );
      }
    } else if (!loanApprovalType) {
      if (!useInterface && isOperator) {
        return (
          <GuideMessage messageType={MessageType.ALERT} message={[t('text:The_financing_repayment_is_delinquent')]}>
            {{
              button: (
                <>
                  <Button
                    variant={ButtonVariantEnum.OUTLINED}
                    color={ButtonColorEnum.SECONDARY}
                    onClick={onClickExpiredStatusRequest}
                  >
                    {t('text:Expired_Status_Request')}
                  </Button>
                  <Button
                    variant={ButtonVariantEnum.OUTLINED}
                    color={ButtonColorEnum.SECONDARY}
                    onClick={onClickRemoveDelinquentStatus}
                  >
                    {t('text:Remove_Delinquent_Status')}
                  </Button>
                </>
              ),
            }}
          </GuideMessage>
        );
      } else if (useInterface && isOperator) {
        return (
          <GuideMessage
            messageType={MessageType.ALERT}
            message={[
              t('text:The_financing_repayment_is_delinquent'),
              t(
                'text:To_see_the_overdue_repayment_on_the_financing,_please_click_on_the_Update_Repayment_Transaction_button',
              ),
              t('text:Click_on_the_Expired_Status_Request_button_to_the_right_to_terminate_the_financing'),
            ]}
          >
            {{
              button: (
                <>
                  {!factoringEnable && (
                    <Button onClick={onClickRenewLoanTransactions}>{t('text:Update_Repayment_Transaction')}</Button>
                  )}
                  <Button
                    variant={ButtonVariantEnum.OUTLINED}
                    color={ButtonColorEnum.SECONDARY}
                    onClick={onClickExpiredStatusRequest}
                  >
                    {t('text:Expired_Status_Request')}
                  </Button>
                </>
              ),
            }}
          </GuideMessage>
        );
      } else if (!useInterface && isAuthorizer) {
        return (
          <GuideMessage messageType={MessageType.ALERT} message={[t('text:The_financing_repayment_is_delinquent')]} />
        );
      } else if (useInterface && !isOperator) {
        return (
          <GuideMessage
            messageType={MessageType.ALERT}
            message={[
              t('text:The_financing_repayment_is_delinquent'),
              t(
                'text:To_see_the_overdue_repayment_on_the_financing,_please_click_on_the_Update_Repayment_Transaction_button',
              ),
            ]}
          >
            {!factoringEnable
              ? {
                  button: (
                    <Button onClick={onClickRenewLoanTransactions}>{t('text:Update_Repayment_Transaction')}</Button>
                  ),
                }
              : undefined}
          </GuideMessage>
        );
      }
    }
  };

  // 대출 만기상환 완료
  const renderRepaidLoanStatusMessage = () => {
    if (useInterface && !isAdmin) {
      return (
        <GuideMessage
          message={[
            t(
              'text:If_the_financing_status_changes_in_the_financier_s_system_click_the_Update_Repayment_Transaction_button_on_the_right',
            ),
          ]}
        >
          {!factoringEnable
            ? {
                button: (
                  <Button onClick={onClickRenewLoanTransactions}>{t('text:Update_Repayment_Transaction')}</Button>
                ),
              }
            : undefined}
        </GuideMessage>
      );
    }
  };

  // 대출 기타 만료
  const renderExpirationLoanStatusMessage = () => {
    if (!loanApprovalType) {
      return (
        <GuideMessage
          messageType={MessageType.ALERT}
          message={[t('text:The_financing_has_expired'), t('text:Check_the_reason_for_the_expiration_below')]}
          reasonTitle={t('text:REASON_FOR_EXPIRATION')}
          reason={expirationReason}
        />
      );
    }
  };

  return (
    <>
      {loanStatus === DEALER_REQUESTED && renderRequestedLoanStatusMessage()}
      {loanStatus === DEALER_CANCELLED && renderCanceledLoanStatusMessage()}
      {loanStatus === REJECTED && renderRejectedLoanStatusMessage()}
      {loanStatus === APPROVED && renderApprovedLoanStatusMessage()}
      {loanStatus === DISBURSED && renderDisbursedLoanStatusMessage()}
      {loanStatus === OVERDUE && renderOverdueLoanStatusMessage()}
      {loanStatus === REPAID && renderRepaidLoanStatusMessage()}
      {loanStatus === EXPIRATION && renderExpirationLoanStatusMessage()}
    </>
  );
}

export default FinancierFinancingDetailGuideMessage;
