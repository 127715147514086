import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

import Button, { ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import { FormBorder } from 'components/stateless/CommonForm/FormBorder';
import { FormContents } from 'components/stateless/CommonForm/FormContents';
import FormInput from 'components/stateless/CommonForm/FormInput';
import { BackGroundType, FormSubtitle } from 'components/stateless/CommonForm/FormSubtitle';
import { FormValue } from 'components/stateless/CommonForm/FormValue';
import GuideMessage, { MessageType } from 'components/stateless/GuideMessage/GuideMessage';
import ReasonModal from 'components/stateless/Modal/common/ReasonModal';
import FinancierCitadCodeModal from 'components/stateless/Modal/financier/FinancierCitadCodeModal';
import { BackHeaderTitle } from 'components/stateless/Title/BackHeaderTitle';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { ROUTES_FI } from 'constants/routes/financier';
import { AUTHORITY_TYPE, COMMON_APPROVAL_TYPE, ENTERPRISE_TYPE } from 'enums';
import useMounted from 'hooks/useMounted';
import type { SignInModel } from 'models/SignInModel';
import type { BankCodeVOModel } from 'models/vo/BankCodeVO';
import type { WaitingAnchorPartnerAccountDetailVOModel } from 'models/vo/WaitingAnchorPartnerAccountDetailVO';
import getStatusBadgeClass from 'utils/classNames/getStatusBadgeClass';
import { formErrorHandler } from 'utils/error/manager';
import {
  requestFinancierWaitingAnchorPartnerAccountApprove,
  requestFinancierWaitingAnchorPartnerAccountCancel,
  requestFinancierWaitingAnchorPartnerAccountData,
  requestFinancierWaitingAnchorPartnerAccountDataModify,
  requestFinancierWaitingAnchorPartnerAccountReturn,
} from 'utils/http/api/financier/waiting-anchor-partner-accounts';
import type { FinancierWaitingAnchorPartnerAccountDataModifyRequest } from 'utils/http/api/financier/waiting-anchor-partner-accounts/requests';
import { ModalSize, ModalType } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';
import { getSignIn } from 'utils/storage/LocalStorage';
import { getPaymentSupportText } from 'utils/text';

// TODO :: Test
// RenderManager를 통해 화면을 전체적으로 그려주는 건 프로세스를 이해하기는 좀 더 쉬울 수 있으나 데이터가 API와 연관되어있는 경우 예기치 못한 화면(ex - Requested 화면인데 Revert화면이 노출되고, 이후에 Requested화면이 보임)이 노출된 후에 정상화면이 나올 수 있으므로, 페이지 렌더링 자체를 데이터에 종속시켜보았음.

interface RenderManagerOption {
  currentUserAuthorityType?: AUTHORITY_TYPE | AUTHORITY_TYPE[];
  dataAuthorityType?: AUTHORITY_TYPE | AUTHORITY_TYPE[];
  dataApprovalType?: COMMON_APPROVAL_TYPE | COMMON_APPROVAL_TYPE[];
  dataEnterpriseType?: ENTERPRISE_TYPE | ENTERPRISE_TYPE[];
}

function FinancierPotentialSupplyChainPartnerBankAccountWaitingRevisionDetail() {
  const mounted = useMounted();
  const modal = useModal();
  const history = useHistory();
  const { t } = useTranslation();
  const { waitingAnchorPartnerAccountId } = useParams() as any;
  const signInData: SignInModel | null = getSignIn();
  const userAuthorityType = signInData?.authorityType;

  const [waitingAnchorPartnerAccountData, setWaitingAnchorPartnerAccountData] =
    useState<WaitingAnchorPartnerAccountDetailVOModel>();

  const { register, getValues, setValue, errors, handleSubmit, setError, clearErrors } =
    useForm<FinancierWaitingAnchorPartnerAccountDataModifyRequest>();

  useEffect(() => {
    if (mounted) fetchAll();
  }, [mounted]);

  const fetchAll = async (): Promise<void> => {
    try {
      const waitingAnchorPartnerAccountData = await requestFinancierWaitingAnchorPartnerAccountData(
        waitingAnchorPartnerAccountId,
      );

      setWaitingAnchorPartnerAccountData(waitingAnchorPartnerAccountData);
    } catch (error) {
      modal.show(error);
    }
  };

  const renderManager = (option: RenderManagerOption): boolean => {
    let currentUserAuthorityTypeList = undefined;
    let dataAuthorityTypeList = undefined;
    let dataApprovalTypeList = undefined;
    let dataEnterpriseTypeList = undefined;

    if (option.currentUserAuthorityType) {
      currentUserAuthorityTypeList = Array.isArray(option.currentUserAuthorityType)
        ? option.currentUserAuthorityType
        : [option.currentUserAuthorityType];
    }

    if (option.dataAuthorityType) {
      dataAuthorityTypeList = Array.isArray(option.dataAuthorityType)
        ? option.dataAuthorityType
        : [option.dataAuthorityType];
    }

    if (option.dataApprovalType) {
      dataApprovalTypeList = Array.isArray(option.dataApprovalType)
        ? option.dataApprovalType
        : [option.dataApprovalType];
    }

    if (option.dataEnterpriseType) {
      dataEnterpriseTypeList = Array.isArray(option.dataEnterpriseType)
        ? option.dataEnterpriseType
        : [option.dataEnterpriseType];
    }

    if (
      currentUserAuthorityTypeList &&
      userAuthorityType &&
      !currentUserAuthorityTypeList.includes(userAuthorityType)
    ) {
      return false;
    }

    if (
      dataAuthorityTypeList &&
      waitingAnchorPartnerAccountData?.operatorUserAuthority &&
      !dataAuthorityTypeList.includes(waitingAnchorPartnerAccountData.operatorUserAuthority)
    ) {
      return false;
    }

    if (
      dataApprovalTypeList &&
      waitingAnchorPartnerAccountData?.approvalType &&
      !dataApprovalTypeList.includes(waitingAnchorPartnerAccountData.approvalType)
    ) {
      return false;
    }

    if (
      dataEnterpriseTypeList &&
      waitingAnchorPartnerAccountData?.operatorEnterpriseType &&
      !dataEnterpriseTypeList.includes(waitingAnchorPartnerAccountData.operatorEnterpriseType)
    ) {
      return false;
    }

    return true;
  };

  const getGuideMessage = (): string[] => {
    if (
      renderManager({
        currentUserAuthorityType: [AUTHORITY_TYPE.OPERATOR, AUTHORITY_TYPE.ADMIN],
        dataAuthorityType: AUTHORITY_TYPE.OPERATOR,
        dataApprovalType: COMMON_APPROVAL_TYPE.OPERATOR_REQUEST,
        dataEnterpriseType: ENTERPRISE_TYPE.FI,
      })
    ) {
      return [
        t('text:The_Uploaded_Partners_revision_will_be_completed_when_the_Authorizer_confirms_the_revision_request'),
      ];
    } else if (
      renderManager({
        dataAuthorityType: AUTHORITY_TYPE.ADMIN,
        dataApprovalType: COMMON_APPROVAL_TYPE.OPERATOR_REQUEST,
        dataEnterpriseType: ENTERPRISE_TYPE.FI,
      })
    ) {
      return [t('text:The_Uploaded_Partner_revision_will_be_completed_after_confirmation_from_the_Admin')];
    } else if (
      renderManager({
        currentUserAuthorityType: [AUTHORITY_TYPE.AUTHORIZER],
        dataAuthorityType: [AUTHORITY_TYPE.OPERATOR],
        dataApprovalType: COMMON_APPROVAL_TYPE.OPERATOR_REQUEST,
        dataEnterpriseType: ENTERPRISE_TYPE.FI,
      })
    ) {
      return [
        t('text:The_Uploaded_Partner_information_revision_has_been_requested'),
        t('text:Click_on_the_buttons_at_the_bottom_of_the_page_to_confirm_or_revert_the_revision_request'),
      ];
    } else if (
      renderManager({
        dataApprovalType: COMMON_APPROVAL_TYPE.AUTHORIZER_RETURNED,
        dataEnterpriseType: ENTERPRISE_TYPE.FI,
      })
    ) {
      return [t('text:The_Uploaded_Partner_revision_request_has_been_reverted')];
    } else if (
      renderManager({
        dataApprovalType: COMMON_APPROVAL_TYPE.OPERATOR_REQUEST,
        dataEnterpriseType: ENTERPRISE_TYPE.AC,
      })
    ) {
      return [t('text:The_revision_request_will_be_completed_after_confirmation_from_the_Anchor_Admin')];
    } else if (
      renderManager({
        dataApprovalType: COMMON_APPROVAL_TYPE.AUTHORIZER_RETURNED,
        dataEnterpriseType: ENTERPRISE_TYPE.AC,
      })
    ) {
      return [t('text:The_Anchors_Uploaded_Partner_revision_request_was_reverted')];
    }

    return [''];
  };

  const renderStatusBadgeComponent = (): JSX.Element => {
    const getBadgeText = (): string => {
      switch (waitingAnchorPartnerAccountData?.approvalType) {
        case COMMON_APPROVAL_TYPE.OPERATOR_REQUEST:
          return t('text:Requested');
        case COMMON_APPROVAL_TYPE.OPERATOR_CANCEL:
          return t('text:Request_Cancelled');
        case COMMON_APPROVAL_TYPE.AUTHORIZER_RETURNED:
          return t('text:Request_Reverted');
        case COMMON_APPROVAL_TYPE.AUTHORIZER_APPROVED:
          return t('text:Registered');
        default:
          return '';
      }
    };

    const getBadgeDetailText = (): JSX.Element | null => {
      const renderTitleAndInfo = (
        authority?: AUTHORITY_TYPE,
        userName?: string,
        userLoginId?: string,
        enterpriseName?: string,
      ) => (
        <>
          <span className="supply-chain-partner-requester-info-title">{`${
            authority ? t(`code:authority-type.${authority}`) : '-'
          } (${userName ?? '-'} / ${userLoginId ?? '-'})`}</span>
          <span>{enterpriseName ?? '-'}</span>
        </>
      );

      const {
        approvalType,
        operatorUserAuthority,
        operatorUserName,
        operatorUserLoginId,
        operatorEnterpriseName,
        authorizerUserName,
        authorizerUserLoginId,
        authorizerEnterpriseName,
      } = waitingAnchorPartnerAccountData ?? {};

      switch (approvalType) {
        case COMMON_APPROVAL_TYPE.OPERATOR_REQUEST:
        case COMMON_APPROVAL_TYPE.OPERATOR_CANCEL:
          return renderTitleAndInfo(
            operatorUserAuthority,
            operatorUserName,
            operatorUserLoginId,
            operatorEnterpriseName,
          );
        case COMMON_APPROVAL_TYPE.AUTHORIZER_RETURNED:
          return renderTitleAndInfo(
            operatorUserAuthority,
            authorizerUserName,
            authorizerUserLoginId,
            authorizerEnterpriseName,
          );
        default:
          return null;
      }
    };

    const getBadgeTimeText = (): string => {
      switch (waitingAnchorPartnerAccountData?.approvalType) {
        case COMMON_APPROVAL_TYPE.OPERATOR_REQUEST:
          return t('text:Requested_Date');
        case COMMON_APPROVAL_TYPE.OPERATOR_CANCEL:
          return t('text:Cancelled_Date');
        case COMMON_APPROVAL_TYPE.AUTHORIZER_RETURNED:
          return t('text:Reverted_Date');
        case COMMON_APPROVAL_TYPE.AUTHORIZER_APPROVED:
          return t('text:Registered_Date');
        default:
          return '';
      }
    };

    return (
      <div className="content-area">
        <div className="supply-chain-partner-status-block">
          <span
            className={`me-4 ${getStatusBadgeClass(
              'COMMON_APPROVAL_TYPE',
              waitingAnchorPartnerAccountData?.approvalType,
            )}`}
          >
            {getBadgeText()}
          </span>
          <span className="supply-chain-partner-requester-info">{getBadgeDetailText()}</span>
          <span className="supply-chain-partner-requester-info supply-chain-partner-requester-info-time">
            <span className="supply-chain-partner-requester-info-title">{getBadgeTimeText()}</span>
            <span>
              {t('format:datetime', { value: waitingAnchorPartnerAccountData?.updatedDateTime, key: 'datetime' })}
            </span>
          </span>
        </div>
      </div>
    );
  };

  const renderSupplyChainPartnerInformationComponent = (): JSX.Element => {
    return (
      <div className="content-area">
        <SectionTitle title={t(`text:Uploaded_Partner_Information`)} />
        <FormBorder>
          <FormContents>
            <div className="row">
              <FormValue label={t(`text:Uploaded_Partner_Name`)} value={waitingAnchorPartnerAccountData?.partnerName} />
              <FormValue label={t(`text:Tax_Code`)} value={waitingAnchorPartnerAccountData?.partnerTaxCode} />
            </div>
            <div className="row">
              <FormValue
                label={t(`text:Company_Registration_Number`)}
                value={waitingAnchorPartnerAccountData?.partnerBusinessCode}
              />
              <FormValue col={3} label={t(`text:Telephone`)} value={waitingAnchorPartnerAccountData?.telephone} />
              <FormValue col={3} label={t(`text:Fax`)} value={waitingAnchorPartnerAccountData?.fax} />
            </div>
            <div className="row">
              <FormValue
                label={t(`text:Legal_Representative_Name`)}
                value={waitingAnchorPartnerAccountData?.representativeName}
              />
              <FormValue
                col={3}
                label={t(`text:Legal_Representative_Title`)}
                value={waitingAnchorPartnerAccountData?.representativePosition}
              />
              <FormValue
                col={3}
                label={t(`text:Legal_Representative_Email`)}
                value={waitingAnchorPartnerAccountData?.representativeEmail}
              />
            </div>
            <div className="row">
              <FormValue
                col={12}
                label={t(`text:Registered_Office_Address`)}
                value={waitingAnchorPartnerAccountData?.address}
              />
            </div>
          </FormContents>
        </FormBorder>
      </div>
    );
  };

  const renderDesignatedBankAccountInformationforAPSettlementComponent = (): JSX.Element => {
    const onClickFind = (e: any): void => {
      e.preventDefault();

      const setBankCodeData = (data: BankCodeVOModel): void => {
        setValue('bankCodeId', data.id);
        setValue('bankCode', data.bankCode);
        setValue('bankName', data.bankName);
        setValue('branchCode', data.branchCode);
        setValue('branchName', data.branchName);
      };

      modal.show(<FinancierCitadCodeModal handleBankCodeData={setBankCodeData} modalId={modal.id} />, {
        title: t('text:Find_Bank_Code'),
        modalType: ModalType.ALERT,
        modalSize: ModalSize.L,
        closeBtnText: t('text:Close'),
      });
    };

    return (
      <div className="content-area">
        <SectionTitle title={t(`text:Designated_Bank_Account_Information_for_AP_Settlement`)} />
        <FormBorder
          editable={renderManager({
            currentUserAuthorityType: AUTHORITY_TYPE.OPERATOR,
            dataAuthorityType: AUTHORITY_TYPE.OPERATOR,
            dataApprovalType: COMMON_APPROVAL_TYPE.AUTHORIZER_RETURNED,
            dataEnterpriseType: ENTERPRISE_TYPE.FI,
          })}
        >
          <FormSubtitle
            backGroundType={BackGroundType.DarkGray}
            title={t('text:Associated_Anchor_Master_Agreement_Information')}
          />
          <FormContents>
            <div className="row">
              <FormValue label={t('text:Anchor_Name')} value={waitingAnchorPartnerAccountData?.anchorName} />
              <FormValue
                label={t(`text:Anchor_Master_Agreement_Number`)}
                value={waitingAnchorPartnerAccountData?.anchorAgreementContractNo}
              />
            </div>
            <div className="row">
              <FormValue label={t(`text:Currency`)} value={waitingAnchorPartnerAccountData?.currencyType} />
              <FormValue
                label={t(`text:Automatic_Settlement`)}
                value={getPaymentSupportText(waitingAnchorPartnerAccountData?.paymentSupport)}
              />
            </div>
            <div className="row">
              <FormValue
                label={t(`text:Responsible_Branch_Name`)}
                value={waitingAnchorPartnerAccountData?.responsibleBranchName}
              />
            </div>
          </FormContents>
          <FormSubtitle
            backGroundType={BackGroundType.DarkGray}
            title={t('text:Designated_Bank_Account_Information')}
          />
          <FormContents>
            <div className="row">
              <input type="hidden" name="bankCodeId" ref={register} />
              {waitingAnchorPartnerAccountData && (
                <FormInput
                  label={t(`text:Bank_Code`)}
                  name="bankCode"
                  defaultValue={String(waitingAnchorPartnerAccountData.accountBankCode)}
                  disabled={true}
                  ref={register}
                  error={errors.bankCode}
                >
                  {renderManager({
                    currentUserAuthorityType: AUTHORITY_TYPE.OPERATOR,
                    dataAuthorityType: AUTHORITY_TYPE.OPERATOR,
                    dataApprovalType: COMMON_APPROVAL_TYPE.AUTHORIZER_RETURNED,
                    dataEnterpriseType: ENTERPRISE_TYPE.FI,
                  }) && <Button onClick={onClickFind}>{t(`text:Find`)}</Button>}
                </FormInput>
              )}

              <FormInput
                label={t(`text:Bank_Name`)}
                name="bankName"
                defaultValue={waitingAnchorPartnerAccountData?.accountBankName}
                disabled={true}
                ref={register}
                error={errors.bankName}
              />
            </div>
            <div className="row">
              <FormInput
                label={t(`text:Branch_Code`)}
                name="branchCode"
                defaultValue={waitingAnchorPartnerAccountData?.accountBranchCode}
                disabled={true}
                ref={register}
                error={errors.branchCode}
              />
              <FormInput
                label={t(`text:Branch_Name`)}
                name="branchName"
                defaultValue={waitingAnchorPartnerAccountData?.accountBranchName}
                disabled={true}
                ref={register}
                error={errors.branchName}
              />
            </div>
            <div className="row">
              <FormInput
                label={t(`text:Bank_Account_Number`)}
                name="account"
                defaultValue={waitingAnchorPartnerAccountData?.account}
                disabled={
                  !(
                    renderManager({
                      currentUserAuthorityType: AUTHORITY_TYPE.OPERATOR,
                      dataAuthorityType: AUTHORITY_TYPE.OPERATOR,
                      dataApprovalType: COMMON_APPROVAL_TYPE.AUTHORIZER_RETURNED,
                      dataEnterpriseType: ENTERPRISE_TYPE.FI,
                    }) && <Button onClick={onClickFind}>{t(`text:Find`)}</Button>
                  )
                }
                ref={register}
                error={errors.account}
                applyUpperCase
              />
              <FormInput
                label={t(`text:Bank_Account_Holder`)}
                name="accountOwner"
                defaultValue={waitingAnchorPartnerAccountData?.accountOwner}
                disabled={
                  !(
                    renderManager({
                      currentUserAuthorityType: AUTHORITY_TYPE.OPERATOR,
                      dataAuthorityType: AUTHORITY_TYPE.OPERATOR,
                      dataApprovalType: COMMON_APPROVAL_TYPE.AUTHORIZER_RETURNED,
                      dataEnterpriseType: ENTERPRISE_TYPE.FI,
                    }) && <Button onClick={onClickFind}>{t(`text:Find`)}</Button>
                  )
                }
                ref={register}
                error={errors.accountOwner}
                applyUpperCase
              />
            </div>
            <div className="row">
              <FormValue
                label={t('text:Assigned_Branch_Code')}
                value={waitingAnchorPartnerAccountData?.assignedBranchCode}
              />
              <FormValue
                label={t('text:Assigned_Branch_Name')}
                value={waitingAnchorPartnerAccountData?.assignedBranchName}
              />
            </div>
          </FormContents>
        </FormBorder>
      </div>
    );
  };

  const renderManageButtonComponent = (): JSX.Element | undefined => {
    const onClickCancelRequest = (e: any): void => {
      e.preventDefault();

      const onClickConfirm = async (): Promise<void> => {
        try {
          waitingAnchorPartnerAccountData?.waitingAnchorPartnerAccountId &&
            (await requestFinancierWaitingAnchorPartnerAccountCancel(waitingAnchorPartnerAccountId));

          fetchAll();
        } catch (error) {
          modal.show(error);
        }
      };

      modal.show(
        <h6>
          {t('text:Would_you_like_to_cancel_the_modification_request?')}
          <br />
          {t('text:The_information_will_not_be_saved_if_registration_is_cancelled')}
        </h6>,
        {
          modalType: ModalType.CONFIRM,
          closeBtnText: t('text:Close'),
          confirmBtnText: t('text:Confirm'),
          confirmBtnCb: () => onClickConfirm(),
        },
      );
    };

    const onClickRequestRevision = (): void => {
      const onClickConfirm = async (): Promise<void> => {
        try {
          const isNotSelectedBankCodeId = getValues('bankCodeId') === ('' as unknown);
          const alreadyRegisteredBankCodeId = waitingAnchorPartnerAccountData?.accountBankCodeId as number;
          const newlySelectedBankCodeId = getValues('bankCodeId');

          const bankCodeId = isNotSelectedBankCodeId ? alreadyRegisteredBankCodeId : newlySelectedBankCodeId;
          const data = { ...getValues(), bankCodeId };

          waitingAnchorPartnerAccountData?.waitingAnchorPartnerAccountId &&
            (await requestFinancierWaitingAnchorPartnerAccountDataModify(waitingAnchorPartnerAccountId, data));

          modal.show(
            <h6>
              {t('text:Request_for_modification_approval_has_been_completed')}
              <br />
              {t('text:Modification_will_be_completed_after_approval_by_the_Authorizer')}
            </h6>,
            {
              modalType: ModalType.ALERT,
              closeBtnText: t('text:OK'),
              closeBtnCb: () => fetchAll(),
            },
          );
        } catch (error) {
          modal.show(error);
          formErrorHandler<FinancierWaitingAnchorPartnerAccountDataModifyRequest>(error, setError, clearErrors);
        }
      };

      modal.show(<h6>{t('text:Would_you_like_to_request_approval_for_modification?')}</h6>, {
        modalType: ModalType.CONFIRM,
        closeBtnText: t('text:Cancel'),
        confirmBtnText: t('text:Confirm'),
        confirmBtnCb: () => onClickConfirm(),
      });
    };

    const onClickConfirm = (e: any) => {
      e.preventDefault();

      const onClickConfirm = async (): Promise<void> => {
        try {
          waitingAnchorPartnerAccountData?.waitingAnchorPartnerAccountId &&
            (await requestFinancierWaitingAnchorPartnerAccountApprove(waitingAnchorPartnerAccountId));

          modal.show(
            <h6>{t('text:The_changes_to_the_Uploaded_Partners_bank_account_information_have_been_saved')}</h6>,
            {
              modalType: ModalType.ALERT,
              closeBtnText: t('text:OK'),
              closeBtnCb: () => history.push(ROUTES_FI.MANAGE_UPLOADED_PARTNER.BANK_ACCOUNT_REVISION_LIST),
            },
          );
        } catch (error) {
          modal.show(error);
        }
      };

      modal.show(
        <h6>{t('text:Would_you_like_to_confirm_the_Uploaded_Partners_bank_account_modification_request')}</h6>,
        {
          modalType: ModalType.CONFIRM,
          closeBtnText: t('text:Cancel'),
          confirmBtnText: t('text:Confirm'),
          confirmBtnCb: () => onClickConfirm(),
        },
      );
    };

    const onClickRevert = (e: any) => {
      e.preventDefault();

      let reason: string = '';

      const onClickConfirm = async (): Promise<void> => {
        try {
          waitingAnchorPartnerAccountData?.waitingAnchorPartnerAccountId &&
            (await requestFinancierWaitingAnchorPartnerAccountReturn(waitingAnchorPartnerAccountId, {
              returnReason: reason,
            }));

          modal.show(<h6>{t('text:The_Uploaded_Partner_bank_account_modification_request_has_been_reverted')}</h6>, {
            modalType: ModalType.ALERT,
            closeBtnText: t('text:OK'),
            closeBtnCb: () => fetchAll(),
          });
        } catch (error) {
          modal.show(error);
        }
      };

      modal.show(
        <>
          <h6>
            {t('text:Would_you_like_to_revert_the_modification_request')}
            <br />
            {t('text:Please_type_below_the_reason_for_the_revert')}
            <br />
            {t('text:Click_on_Confirm_to_revert_the_request')}
          </h6>
          <ReasonModal
            modalId={modal.id}
            getReturnReason={(returnReason: string) => {
              reason = returnReason;
            }}
          />
        </>,
        {
          modalType: ModalType.CONFIRM,
          closeBtnText: t('text:Cancel'),
          confirmBtnText: t('text:Confirm'),
          confirmBtnCb: () => onClickConfirm(),
        },
      );
    };

    if (
      renderManager({
        currentUserAuthorityType: AUTHORITY_TYPE.OPERATOR,
        dataAuthorityType: AUTHORITY_TYPE.OPERATOR,
        dataApprovalType: COMMON_APPROVAL_TYPE.OPERATOR_REQUEST,
        dataEnterpriseType: ENTERPRISE_TYPE.FI,
      })
    ) {
      return (
        <Button
          size={ButtonSizeEnum.LG}
          color={ButtonColorEnum.SECONDARY}
          variant={ButtonVariantEnum.OUTLINED}
          onClick={onClickCancelRequest}
        >
          {t('text:Cancel_Request')}
        </Button>
      );
    } else if (
      renderManager({
        currentUserAuthorityType: AUTHORITY_TYPE.OPERATOR,
        dataAuthorityType: AUTHORITY_TYPE.OPERATOR,
        dataApprovalType: COMMON_APPROVAL_TYPE.AUTHORIZER_RETURNED,
        dataEnterpriseType: ENTERPRISE_TYPE.FI,
      })
    ) {
      return (
        <>
          <Button
            size={ButtonSizeEnum.LG}
            color={ButtonColorEnum.SECONDARY}
            variant={ButtonVariantEnum.OUTLINED}
            onClick={onClickCancelRequest}
          >
            {t('text:Cancel_Request')}
          </Button>
          <Button size={ButtonSizeEnum.LG} className="ms-3" onClick={handleSubmit(onClickRequestRevision)}>
            {t('text:Request_Revision')}
          </Button>
        </>
      );
    } else if (
      renderManager({
        currentUserAuthorityType: AUTHORITY_TYPE.AUTHORIZER,
        dataAuthorityType: AUTHORITY_TYPE.OPERATOR,
        dataApprovalType: COMMON_APPROVAL_TYPE.OPERATOR_REQUEST,
        dataEnterpriseType: ENTERPRISE_TYPE.FI,
      })
    ) {
      return (
        <>
          <Button
            size={ButtonSizeEnum.LG}
            onClick={onClickRevert}
            color={ButtonColorEnum.RED}
            variant={ButtonVariantEnum.OUTLINED}
          >
            {t('text:Revert')}
          </Button>
          <Button size={ButtonSizeEnum.LG} className="ms-3" onClick={onClickConfirm}>
            {t('text:Confirm')}
          </Button>
        </>
      );
    } else if (
      renderManager({
        currentUserAuthorityType: AUTHORITY_TYPE.ADMIN,
        dataAuthorityType: AUTHORITY_TYPE.ADMIN,
        dataApprovalType: COMMON_APPROVAL_TYPE.OPERATOR_REQUEST,
        dataEnterpriseType: ENTERPRISE_TYPE.FI,
      })
    ) {
      return (
        <>
          <Button
            size={ButtonSizeEnum.LG}
            color={ButtonColorEnum.SECONDARY}
            variant={ButtonVariantEnum.OUTLINED}
            onClick={onClickCancelRequest}
          >
            {t('text:Cancel_Request')}
          </Button>
          <Button size={ButtonSizeEnum.LG} className="ms-3" onClick={onClickConfirm}>
            {t('text:Confirm')}
          </Button>
        </>
      );
    }

    return;
  };

  return (
    <>
      {waitingAnchorPartnerAccountData && (
        <>
          <BackHeaderTitle title={waitingAnchorPartnerAccountData?.partnerName} />
          {renderManager({
            dataApprovalType: COMMON_APPROVAL_TYPE.OPERATOR_REQUEST,
          }) && <GuideMessage message={getGuideMessage()} />}
          {renderManager({
            dataApprovalType: COMMON_APPROVAL_TYPE.AUTHORIZER_RETURNED,
          }) && (
            <GuideMessage
              message={getGuideMessage()}
              messageType={MessageType.ALERT}
              reasonTitle={t('text:REASON_FOR_REVERT')}
              reason={waitingAnchorPartnerAccountData?.returnReason}
            />
          )}
          {renderStatusBadgeComponent()}
          {renderSupplyChainPartnerInformationComponent()}
          {renderDesignatedBankAccountInformationforAPSettlementComponent()}
          <div className="content-area">
            <div className="flex-end mt-4">{renderManageButtonComponent()}</div>
          </div>
        </>
      )}
    </>
  );
}

export default FinancierPotentialSupplyChainPartnerBankAccountWaitingRevisionDetail;
