import { useEffect } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import GuideMessage from 'components/stateless/GuideMessage/GuideMessage';
import Tab from 'components/stateless/TabManager/Tab';
import { HeaderTitle } from 'components/stateless/Title/HeaderTitle';
import useMounted from 'hooks/useMounted';

import FinancierAnchorDealerAssignmentListAssignedTab from './tabs/FinancierAnchorDealerAssignmentListAssignedTab';
import FinancierAnchorDealerAssignmentListDeclinedTab from './tabs/FinancierAnchorDealerAssignmentListDeclinedTab';
import useFinancierAnchorDealerAssignmentListState from './useFinancierAnchorDealerAssignmentListState';

function FinancierAnchorDealerAssignmentList() {
  const mounted = useMounted();
  const { t } = useTranslation();

  const { declinedTabUtils, assignedTabUtils, fetchAll } = useFinancierAnchorDealerAssignmentListState();

  useEffect(() => {
    if (mounted) {
      fetchAll();
    }
  }, [mounted]);

  const {
    declinedAnchorDealerData,
    declinedTabPageable,
    declinedTabSearchForm,
    handleClickSearchDeclinedList,
    paginateDeclinedList,
  } = declinedTabUtils;

  const {
    assignedAnchorDealerData,
    assignedTabPageable,
    assignedTabSearchForm,
    handleClickSearchAssignedList,
    paginateAssignedList,
    requestDecline,
  } = assignedTabUtils;

  return (
    <>
      <HeaderTitle title={t('text:Uploaded_Partner_Assignment')} />
      <GuideMessage
        message={[
          t(
            'text:The_list_of_Uploaded_Dealers_that_have_been_assigned_by_the_Financier_Admin_for_acquisition_is_shown_below',
          ),
          t('text:Click_on_the_arrow_button_to_view_detailed_information_of_the_Uploaded_Dealers'),
        ]}
      />
      <Tab
        initialActiveTabIndex={0}
        tabList={[
          {
            tabName: t('text:Assigned'),
            tabItemCount: assignedAnchorDealerData?.totalElements ?? 0,
          },
          {
            tabName: t('text:Declined'),
            tabItemCount: declinedAnchorDealerData?.totalElements ?? 0,
          },
        ]}
        tabViewList={[
          <FormProvider {...assignedTabSearchForm} key="assigned">
            <FinancierAnchorDealerAssignmentListAssignedTab
              assignedAnchorDealerList={assignedAnchorDealerData}
              pageable={assignedTabPageable}
              paginate={paginateAssignedList}
              handleClickSearch={handleClickSearchAssignedList}
              requestDecline={requestDecline}
            />
          </FormProvider>,
          <FormProvider {...declinedTabSearchForm} key="declined">
            <FinancierAnchorDealerAssignmentListDeclinedTab
              declinedAnchorDealerList={declinedAnchorDealerData}
              pageable={declinedTabPageable}
              paginate={paginateDeclinedList}
              handleClickSearch={handleClickSearchDeclinedList}
            />
          </FormProvider>,
        ]}
      />
    </>
  );
}

export default FinancierAnchorDealerAssignmentList;
