import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Button, { ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import GuideMessage from 'components/stateless/GuideMessage/GuideMessage';
import Pagination from 'components/stateless/Pagination/Pagination';
import SearchBorder from 'components/stateless/SearchForm/SearchBorder';
import SearchInput from 'components/stateless/SearchForm/SearchInput';
import SearchLabel from 'components/stateless/SearchForm/SearchLabel';
import SearchSelect from 'components/stateless/SearchForm/SearchSelect';
import Tab from 'components/stateless/TabManager/Tab';
import TableBody from 'components/stateless/Table/TableBody';
import TableBorder from 'components/stateless/Table/TableBorder';
import type { HeaderType } from 'components/stateless/Table/TableHeader';
import TableHeader from 'components/stateless/Table/TableHeader';
import Td from 'components/stateless/Table/Td';
import TdLink from 'components/stateless/Table/TdLink';
import Tr from 'components/stateless/Table/Tr';
import { HeaderTitle } from 'components/stateless/Title/HeaderTitle';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { ROUTES_AC } from 'constants/routes/anchor';
import getSelectOptions from 'constants/selectOptions';
import { COMMON_APPROVAL_TYPE, ENTERPRISE_TYPE } from 'enums';
import useMounted from 'hooks/useMounted';
import usePageable from 'hooks/usePageable';
import useProperty from 'hooks/useProperty';
import type Pageable from 'models/Pageable';
import type { AnchorPartnerVOModel } from 'models/vo/AnchorPartnerVO';
import type { WaitingAnchorPartnerVOModel } from 'models/vo/WaitingAnchorPartnerVO';
import getStatusTextClass from 'utils/classNames/getStatusTextClass';
import { setFormValues } from 'utils/form/setFormValues';
import { requestAnchorPartnersPage } from 'utils/http/api/anchor/anchor-partners';
import type { AnchorPartnersPageRequest } from 'utils/http/api/anchor/anchor-partners/requests';
import { requestWaitingAnchorPartnerForModificationPage } from 'utils/http/api/anchor/waiting-anchor-partners';
import type {
  WaitingAnchorPartnerForModificationPageRequest,
  WaitingAnchorPartnerPageRequest,
} from 'utils/http/api/anchor/waiting-anchor-partners/requests';
import { setApprovalTypesValue } from 'utils/logic/setApprovalTypesValue';
import useModal from 'utils/modal/useModal';
import {
  addSearchParams,
  getParsedSearchParams,
  makeSearchParamsPattern,
  updateSearchParams,
} from 'utils/searchParams';

const getConstant = () => {
  const { t } = useTranslation(['format']);

  const WAITING_FOR_CONFIRMATION_TABLE_HEADERS: HeaderType[] = [
    {
      headerText: t('text:Partner_Name'),
      colWidths: 100,
    },
    {
      headerText: t('text:Partner_Tax_Code'),
      colWidths: 120,
    },
    {
      headerText: t('text:Registered_Office_Address'),
      colWidths: 100,
    },
    {
      headerText: t('text:Legal_Representative_Name'),
      colWidths: 100,
    },
    {
      headerText: t('text:Telephone'),
      colWidths: 100,
    },
    {
      headerText: t('text:Reviewed_By'),
      colWidths: 80,
    },
    {
      headerText: t('text:Approval_Status'),
      colWidths: 80,
    },
    {
      headerText: '',
      colWidths: 50,
    },
  ];

  const REVISION_CONFIRMED_TABLE_HEADERS: HeaderType[] = [
    {
      headerText: t('text:Partner_Name'),
      colWidths: 100,
    },
    {
      headerText: t('text:Partner_Tax_Code'),
      colWidths: 120,
    },
    {
      headerText: t('text:Registered_Office_Address'),
      colWidths: 100,
    },
    {
      headerText: t('text:Legal_Representative_Name'),
      colWidths: 100,
    },
    {
      headerText: t('text:Telephone'),
      colWidths: 100,
    },
    {
      headerText: '',
      colWidths: 50,
    },
  ];

  const AC_PARTNER_COMPANY_REVISION_WAITING_LIST_QS_KEY = 'ac-partner-company-revision-waiting-list';
  const AC_PARTNER_COMPANY_REVISION_CONFIRMED_LIST_QS_KEY = 'ac-partner-company-revision-confirmed-list';

  return {
    WAITING_FOR_CONFIRMATION_TABLE_HEADERS,
    REVISION_CONFIRMED_TABLE_HEADERS,
    AC_PARTNER_COMPANY_REVISION_WAITING_LIST_QS_KEY,
    AC_PARTNER_COMPANY_REVISION_CONFIRMED_LIST_QS_KEY,
  };
};

function AnchorPartnerCompanyInfoList() {
  const { t } = useTranslation(['format']);
  const mounted = useMounted();
  const modal = useModal();

  const {
    WAITING_FOR_CONFIRMATION_TABLE_HEADERS,
    REVISION_CONFIRMED_TABLE_HEADERS,
    AC_PARTNER_COMPANY_REVISION_WAITING_LIST_QS_KEY,
    AC_PARTNER_COMPANY_REVISION_CONFIRMED_LIST_QS_KEY,
  } = getConstant();

  const { pageable: waitingForConfirmationPageable, setPageable: setWaitingForConfirmationPageable } = usePageable(
    AC_PARTNER_COMPANY_REVISION_WAITING_LIST_QS_KEY,
  );
  const { pageable: revisionConfirmedPageable, setPageable: setRevisionConfirmedPageable } = usePageable(
    AC_PARTNER_COMPANY_REVISION_CONFIRMED_LIST_QS_KEY,
  );

  const getWaitingPartnerInfoProperty = useProperty<WaitingAnchorPartnerPageRequest>();
  const getPartnerInfoProperty = useProperty<AnchorPartnersPageRequest>();

  const waitingPartnerInfoSearchForm = useForm<WaitingAnchorPartnerForModificationPageRequest>({
    mode: 'onSubmit',
  });
  const partnerInfoSearchForm = useForm<AnchorPartnersPageRequest>({
    mode: 'onSubmit',
  });

  const [waitingPartnerInfoList, setWaitingPartnerInfoList] = useState<Pageable<WaitingAnchorPartnerVOModel[]>>();
  const [partnerInfoList, setPartnerInfoList] = useState<Pageable<AnchorPartnerVOModel[]>>();
  const [waitingPartnerInfoListForTabCount, setWaitingPartnerInfoListForTabCount] =
    useState<Pageable<WaitingAnchorPartnerVOModel[]>>();

  useEffect(() => {
    if (mounted) {
      fetchAll();
    }
  }, [mounted]);

  const setWaitingTabSearchValue = (formSearchData?: WaitingAnchorPartnerForModificationPageRequest) => {
    const getSearchFormData = formSearchData ? formSearchData : waitingPartnerInfoSearchForm.getValues();

    const { waitingForApprovalTypeData, notCancelledWaitingForApprovalTypeData } = setApprovalTypesValue(
      getSearchFormData.approvalTypes,
    );

    const waitingForApprovalSearchData = {
      ...getSearchFormData,
      approvalTypes: waitingForApprovalTypeData,
    };

    const notCancelledWaitingForApprovalSearchData = {
      ...getSearchFormData,
      approvalTypes: notCancelledWaitingForApprovalTypeData,
    };

    return {
      waitingForApprovalSearchData,
      notCancelledWaitingForApprovalSearchData,
    };
  };

  async function fetchAll() {
    setFormValues<WaitingAnchorPartnerPageRequest>(
      waitingPartnerInfoSearchForm.setValue,
      getParsedSearchParams(AC_PARTNER_COMPANY_REVISION_WAITING_LIST_QS_KEY).formSearchData,
    );

    setFormValues<AnchorPartnersPageRequest>(
      partnerInfoSearchForm.setValue,
      getParsedSearchParams(AC_PARTNER_COMPANY_REVISION_CONFIRMED_LIST_QS_KEY).formSearchData,
    );

    const { waitingForApprovalSearchData, notCancelledWaitingForApprovalSearchData } = setWaitingTabSearchValue();
    const waitingQs = makeSearchParamsPattern(
      {
        ...waitingForApprovalSearchData,
        pageNumber: waitingForConfirmationPageable.currentPage,
        rowCount: waitingForConfirmationPageable.sizePerPage,
      },
      AC_PARTNER_COMPANY_REVISION_WAITING_LIST_QS_KEY,
    );
    const completedQs = makeSearchParamsPattern(
      {
        ...partnerInfoSearchForm.getValues(),
        pageNumber: revisionConfirmedPageable.currentPage,
        rowCount: revisionConfirmedPageable.sizePerPage,
      },
      AC_PARTNER_COMPANY_REVISION_CONFIRMED_LIST_QS_KEY,
    );
    addSearchParams(waitingQs + completedQs);

    try {
      const [waitingPartnerInfoList, partnerInfoList, waitingPartnerInfoListForTabCount] = await Promise.all([
        requestWaitingAnchorPartnerForModificationPage(
          waitingForConfirmationPageable.currentPage,
          waitingForConfirmationPageable.sizePerPage,
          waitingForApprovalSearchData,
        ),
        requestAnchorPartnersPage(
          revisionConfirmedPageable.currentPage,
          revisionConfirmedPageable.sizePerPage,
          partnerInfoSearchForm.getValues(),
        ),
        requestWaitingAnchorPartnerForModificationPage(0, 1, notCancelledWaitingForApprovalSearchData),
      ]);

      ReactDOM.unstable_batchedUpdates(() => {
        setWaitingPartnerInfoList(waitingPartnerInfoList);
        setWaitingForConfirmationPageable(waitingPartnerInfoList);

        setPartnerInfoList(partnerInfoList);
        setRevisionConfirmedPageable(partnerInfoList);
        setWaitingPartnerInfoListForTabCount(waitingPartnerInfoListForTabCount);
      });
    } catch (error) {
      modal.show(error);
    }
  }

  const handleWaitingPartnerInfoSearchFormSubmit = async () => {
    const { waitingForApprovalSearchData, notCancelledWaitingForApprovalSearchData } = setWaitingTabSearchValue();
    try {
      const [waitingPartnerInfoList, waitingPartnerInfoListForTabCount] = await Promise.all([
        requestWaitingAnchorPartnerForModificationPage(
          0,
          waitingForConfirmationPageable.sizePerPage,
          waitingForApprovalSearchData,
        ),
        requestWaitingAnchorPartnerForModificationPage(0, 1, notCancelledWaitingForApprovalSearchData),
      ]);

      updateSearchParams(
        {
          ...waitingForApprovalSearchData,
          pageNumber: 0,
          rowCount: waitingForConfirmationPageable.sizePerPage,
        },
        AC_PARTNER_COMPANY_REVISION_WAITING_LIST_QS_KEY,
      );

      ReactDOM.unstable_batchedUpdates(() => {
        setWaitingPartnerInfoList(waitingPartnerInfoList);
        setWaitingForConfirmationPageable(waitingPartnerInfoList);
        setWaitingPartnerInfoListForTabCount(waitingPartnerInfoListForTabCount);
      });
    } catch (error) {
      modal.show(error);
    }
  };

  const fetchPartnerInfoList = async (pageNumber: number, rowCount: number, data: AnchorPartnersPageRequest) => {
    try {
      const partnerInfoList = await requestAnchorPartnersPage(pageNumber, rowCount, data);

      updateSearchParams(
        {
          ...data,
          pageNumber,
          rowCount,
        },
        AC_PARTNER_COMPANY_REVISION_CONFIRMED_LIST_QS_KEY,
      );

      ReactDOM.unstable_batchedUpdates(() => {
        setPartnerInfoList(partnerInfoList);
        setRevisionConfirmedPageable(partnerInfoList);
      });
    } catch (error) {
      modal.show(error);
    }
  };

  const handlePartnerInfoSearchFormSubmit = async () => {
    await fetchPartnerInfoList(1, revisionConfirmedPageable.sizePerPage, partnerInfoSearchForm.getValues());
  };

  const renderWaitingView = () => {
    const onClickRemoveFilter = (e: any) => {
      e.preventDefault();
      waitingPartnerInfoSearchForm.reset();
    };

    const paginate = async (page: number, sizePerPage: number) => {
      const { waitingForApprovalSearchData } = setWaitingTabSearchValue(
        getParsedSearchParams(AC_PARTNER_COMPANY_REVISION_WAITING_LIST_QS_KEY).formSearchData,
      );
      try {
        const waitingPartnerInfoList = await requestWaitingAnchorPartnerForModificationPage(
          page,
          sizePerPage,
          waitingForApprovalSearchData,
        );
        updateSearchParams(
          {
            ...waitingForApprovalSearchData,
            pageNumber: page,
            rowCount: sizePerPage,
          },
          AC_PARTNER_COMPANY_REVISION_WAITING_LIST_QS_KEY,
        );

        ReactDOM.unstable_batchedUpdates(() => {
          setWaitingPartnerInfoList(waitingPartnerInfoList);
          setWaitingForConfirmationPageable(waitingPartnerInfoList);
        });
      } catch (error) {
        modal.show(error);
      }
    };

    return (
      <>
        <div className="content-area">
          <SectionTitle title={t('text:Search')}>
            <Button
              variant={ButtonVariantEnum.OUTLINED}
              color={ButtonColorEnum.SECONDARY}
              onClick={onClickRemoveFilter}
            >
              {t('text:Remove_Filter')}
            </Button>
          </SectionTitle>
          <SearchBorder>
            <div className="row">
              <SearchLabel label={t('text:Partner_Name')} />
              <SearchInput
                name={getWaitingPartnerInfoProperty('partnerName')}
                ref={waitingPartnerInfoSearchForm.register}
              />
              <SearchLabel label={t('text:Partner_Tax_Code')} />
              <SearchInput
                name={getWaitingPartnerInfoProperty('partnerTaxCode')}
                ref={waitingPartnerInfoSearchForm.register}
              />
            </div>
            <div className="row">
              <SearchLabel label={t('text:Legal_Representative_Name')} />
              <SearchInput
                name={getWaitingPartnerInfoProperty('representativeName')}
                ref={waitingPartnerInfoSearchForm.register}
              />
              <SearchLabel label={t('text:Telephone')} />
              <SearchInput
                name={getWaitingPartnerInfoProperty('partnerTelephone')}
                ref={waitingPartnerInfoSearchForm.register}
              />
            </div>
            <div className="row">
              <SearchLabel label={t('text:Reviewed_By')} />
              <SearchSelect
                selectOptions={getSelectOptions<ENTERPRISE_TYPE>(
                  'ENTERPRISE_TYPE',
                  [ENTERPRISE_TYPE.AC, ENTERPRISE_TYPE.FI],
                  true,
                )}
                name={getWaitingPartnerInfoProperty('enterpriseType')}
                ref={waitingPartnerInfoSearchForm.register}
              />
              <SearchLabel label={t('text:Approval_Status')} />
              <SearchSelect
                selectOptions={getSelectOptions<COMMON_APPROVAL_TYPE>('COMMON_APPROVAL_TYPE', 'ALL', true)}
                name={getWaitingPartnerInfoProperty('approvalTypes')}
                ref={waitingPartnerInfoSearchForm.register}
              />
            </div>
          </SearchBorder>
          <div className="flex-center mt-3">
            <Button
              size={ButtonSizeEnum.LG}
              onClick={waitingPartnerInfoSearchForm.handleSubmit(handleWaitingPartnerInfoSearchFormSubmit)}
            >
              {t('text:Search')}
            </Button>
          </div>
        </div>
        <div className="division-border" />
        <div className="content-area">
          <SectionTitle title={t('text:Result')} />
          <p className="total-data">
            {t('text:Total')} {waitingPartnerInfoList?.totalElements}
          </p>
          <TableBorder>
            <TableHeader header={WAITING_FOR_CONFIRMATION_TABLE_HEADERS} />
            <TableBody numOfCol={WAITING_FOR_CONFIRMATION_TABLE_HEADERS.length}>
              {waitingPartnerInfoList?.content.map((item, i) => {
                return (
                  <Tr key={i}>
                    <Td data={item.name} />
                    <Td data={item.partnerTaxCode} />
                    <Td data={item.address} />
                    <Td data={item.representativeName} />
                    <Td data={item.telephone} />
                    <Td data={t(`code:enterprise-type.${item.operatorEnterpriseType}`)} />
                    <Td
                      className={getStatusTextClass('COMMON_APPROVAL_TYPE', item.approvalType)}
                      data={t(`code:common-approval-type.${item.approvalType}`)}
                      format="code"
                    />
                    <TdLink
                      path={ROUTES_AC.MANAGE_PARTNER.COMPANY_INFO_WAITING_DETAIL_BUILD_PATH(
                        item.waitingAnchorPartnerId,
                      )}
                    />
                  </Tr>
                );
              })}
            </TableBody>
          </TableBorder>
          <Pagination pageable={waitingForConfirmationPageable} paginate={paginate} />
        </div>
      </>
    );
  };

  const renderCompleteView = () => {
    const onClickRemoveFilter = (e: any) => {
      e.preventDefault();
      partnerInfoSearchForm.reset();
    };

    const paginate = async (page: number, sizePerPage: number) => {
      await fetchPartnerInfoList(
        page,
        sizePerPage,
        getParsedSearchParams(AC_PARTNER_COMPANY_REVISION_CONFIRMED_LIST_QS_KEY).formSearchData,
      );
    };

    return (
      <>
        <div className="content-area">
          <SectionTitle title={t('text:Search')}>
            <Button
              variant={ButtonVariantEnum.OUTLINED}
              color={ButtonColorEnum.SECONDARY}
              onClick={onClickRemoveFilter}
            >
              {t('text:Remove_Filter')}
            </Button>
          </SectionTitle>
          <SearchBorder>
            <div className="row">
              <SearchLabel label={t('text:Partner_Name')} />
              <SearchInput name={getPartnerInfoProperty('partnerName')} ref={partnerInfoSearchForm.register} />
              <SearchLabel label={t('text:Partner_Tax_Code')} />
              <SearchInput name={getPartnerInfoProperty('partnerTaxCode')} ref={partnerInfoSearchForm.register} />
            </div>
            <div className="row">
              <SearchLabel label={t('text:Legal_Representative_Name')} />
              <SearchInput name={getPartnerInfoProperty('representativeName')} ref={partnerInfoSearchForm.register} />
              <SearchLabel label={t('text:Telephone')} />
              <SearchInput name={getPartnerInfoProperty('partnerTelephone')} ref={partnerInfoSearchForm.register} />
            </div>
          </SearchBorder>
          <div className="flex-center mt-3">
            <Button
              size={ButtonSizeEnum.LG}
              onClick={partnerInfoSearchForm.handleSubmit(handlePartnerInfoSearchFormSubmit)}
            >
              {t('text:Search')}
            </Button>
          </div>
        </div>
        <div className="division-border" />
        <div className="content-area">
          <SectionTitle title={t('text:Result')} />
          <p className="total-data">
            {t('text:Total')} {partnerInfoList?.totalElements ?? 0}
          </p>
          <TableBorder>
            <TableHeader header={REVISION_CONFIRMED_TABLE_HEADERS} />
            <TableBody numOfCol={REVISION_CONFIRMED_TABLE_HEADERS.length}>
              {partnerInfoList?.content.map((item, i) => {
                return (
                  <Tr key={i}>
                    <Td data={item.name} />
                    <Td data={item.partnerTaxCode} />
                    <Td data={item.address} />
                    <Td data={item.representativeName} />
                    <Td data={item.telephone} />
                    <TdLink
                      path={ROUTES_AC.MANAGE_PARTNER.COMPANY_INFO_CONFIRMED_DETAIL_BUILD_PATH(item.anchorPartnerId)}
                    />
                  </Tr>
                );
              })}
            </TableBody>
          </TableBorder>
          <Pagination pageable={revisionConfirmedPageable} paginate={paginate} />
        </div>
      </>
    );
  };

  return (
    <>
      <HeaderTitle title={t('text:Partner_Company_Information')} />
      <GuideMessage
        message={[
          t('text:Search_the_Partner_company_you_wish_to_find_using_the_Search_filters_below'),
          t('text:Click_on_the_arrow_button_to_view_detailed_company_information_of_the_Partner'),
        ]}
      />
      <Tab
        tabType="approvalStatus"
        tabList={[
          {
            tabItemCount:
              (waitingPartnerInfoSearchForm.getValues().approvalTypes as any) === COMMON_APPROVAL_TYPE.OPERATOR_CANCEL
                ? 0
                : waitingPartnerInfoListForTabCount?.totalElements ?? 0,
          },
          { tabItemCount: revisionConfirmedPageable.totalRows ?? 0 },
        ]}
        tabViewList={[renderWaitingView(), renderCompleteView()]}
      />
    </>
  );
}

export default AnchorPartnerCompanyInfoList;
