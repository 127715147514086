import {
  AUTHORITY_TYPE,
  CURRENCY_TYPE,
  ENTERPRISE_TYPE,
  FINANCIER_TYPE,
  LOAN_STATUS,
  SUCCESS_INVOICE_STATUS,
  WAITING_INVOICE_APPROVAL_PHASE_STATUS,
  stringToEnum,
} from 'enums';
import { isLoanDisbursed } from 'utils/status';

import type { InvoiceAdditionalDataVO } from './InvoiceAdditionalDataVO';

export interface SuccessInvoiceDetailVO {
  successInvoiceId: number;
  invoiceNumber: string;
  referenceNumber: string;
  invoiceIssuedDate: string;
  settlementDate: string;
  invoiceAmount: number;
  currencyType: string;
  successInvoiceStatus: string;
  blockedByFinancier: boolean;
  blockedReason: string;
  blockedDateTime: string;
  collateralized: boolean;
  financierEnterpriseId: number;
  financierName: string;
  financierCode: string;
  dealerEnterpriseId: number;
  dealerEnterpriseName: string;
  dealerClientId: number;
  dealerClientName: string;
  dealerClientCode: string;
  dealerClientBusinessCode: string;
  anchorEnterpriseId: number;
  anchorEnterpriseName: string;
  anchorClientId: number;
  anchorClientName: string;
  anchorClientCode: string;
  anchorClientBusinessCode: string;
  scannedInvoiceAttachmentId: number;
  scannedInvoiceAttachmentPath: string;
  scannedInvoiceAttachmentFileName: string;
  currentInvoiceApprovalPhaseStatus: string;
  initialInvoiceApprovalDateTime: string;
  initialUploadEnterpriseId: number;
  initialUploadEnterpriseName: string;
  initialUploadEnterpriseType: string;
  initialUploadUserId: number;
  initialUploadUserName: string;
  initialUploadUserAuthorityType: string;
  currentInvoiceApprovalDateTime: string;
  currentConfirmEnterpriseId: number;
  currentConfirmEnterpriseName: string;
  currentUploadEnterpriseType: string;
  currentConfirmUserId: number;
  currentConfirmUserName: string;
  currentUploadUserAuthorityType: string;
  loanId: number;
  financierLoanId: number;
  createdDateTime: string;
  requestedDateTime: string;
  disbursedDate: string;
  repaymentDate: string;
  repaidDate: string;
  principalAmount: number;
  disbursedAmount: number;
  loanStatus: string;
  scheduledRepaymentDate: string;
  billOfExchangeNo: string;
  invoiceAdditionalDataList: InvoiceAdditionalDataVO[];
}

export interface SuccessInvoiceDetailVOModel extends SuccessInvoiceDetailVO {
  currencyType: CURRENCY_TYPE;
  successInvoiceStatus: SUCCESS_INVOICE_STATUS;
  financierCode: FINANCIER_TYPE;
  currentInvoiceApprovalPhaseStatus: WAITING_INVOICE_APPROVAL_PHASE_STATUS;
  initialUploadEnterpriseType: ENTERPRISE_TYPE;
  initialUploadUserAuthorityType: AUTHORITY_TYPE;
  currentUploadEnterpriseType: ENTERPRISE_TYPE;
  currentUploadUserAuthorityType: AUTHORITY_TYPE;
  loanStatus: LOAN_STATUS;
  // only front
  paid: boolean;
}

export function formattingToSuccessInvoiceDetailVOModel(data: SuccessInvoiceDetailVO): SuccessInvoiceDetailVOModel {
  return {
    ...data,
    currencyType: stringToEnum(CURRENCY_TYPE, data.currencyType),
    successInvoiceStatus: stringToEnum(SUCCESS_INVOICE_STATUS, data.successInvoiceStatus),
    financierCode: stringToEnum(FINANCIER_TYPE, data.financierCode),
    currentInvoiceApprovalPhaseStatus: stringToEnum(
      WAITING_INVOICE_APPROVAL_PHASE_STATUS,
      data.currentInvoiceApprovalPhaseStatus,
    ),
    initialUploadEnterpriseType: stringToEnum(ENTERPRISE_TYPE, data.initialUploadEnterpriseType),
    initialUploadUserAuthorityType: stringToEnum(AUTHORITY_TYPE, data.initialUploadUserAuthorityType),
    currentUploadEnterpriseType: stringToEnum(ENTERPRISE_TYPE, data.currentUploadEnterpriseType),
    currentUploadUserAuthorityType: stringToEnum(AUTHORITY_TYPE, data.currentUploadUserAuthorityType),
    loanStatus: stringToEnum(LOAN_STATUS, data.loanStatus),
    // only front
    paid: isLoanDisbursed(stringToEnum(LOAN_STATUS, data.loanStatus)),
  };
}
