import type { EXPORT_DOCUMENT_TYPE } from 'enums';
import type { BulkImportErrorObjArr } from 'utils/error/bulkImportManager';

import type { Style as ExcelJsStyle } from 'exceljs';

export interface ColumnOption<T> {
  header: string;
  key: keyof T & string;
  width?: number;
  style?: Partial<ExcelJsStyle>;
}

export interface RowHeightTypes {
  position: number;
  height: number;
}

export interface MergedCellTypes {
  target: string;
  rowIndex: number;
  value: any;
  style?: ExcelStylePropertyType;
}

export interface EtcInsertDataTypes {
  target: string;
  value: any;
  style?: Partial<ExcelJsStyle>;
  applyDefaultStyle?: boolean;
}

export interface ExportSpreadSheetProps<T> {
  jsonArrayData: any[];
  columns: ColumnOption<T>[];
  errors?: BulkImportErrorObjArr;
  options?: {
    columnLineNumber?: number;
    rowHeight?: RowHeightTypes[];
    mergedCells?: MergedCellTypes[];
    etcInsertData?: EtcInsertDataTypes[];
  };
  bulkImportOptions?: {
    minDate: Date;
    maxDate: Date;
  };
  exportDocumentType?: EXPORT_DOCUMENT_TYPE[];
}

export enum ExcelTemplatesHeader {
  INVOICE_NUMBER = 'Invoice Number',
  INVOICE_AMOUNT = 'Invoice Amount',
  INVOICE_ISSUED_DATE = 'Invoice Issued Date',
  INVOICE_REFERENCE_NUMBER = 'Invoice Reference Number',
  PAYMENT_DATE = 'Payment Date',
  SETTLEMENT_DATE = 'Settlement Date',
  BILL_OF_EXCHANGE_NUMBER = 'BOE Number',
  PARTNER_CODE = 'Partner Code',
  AP_NUMBER = 'AP Number',
  AP_AMOUNT = 'AP Amount',
  AP_ISSUED_DATE = 'AP Issued Date',
  AR_NUMBER = 'AR Number',
  AR_AMOUNT = 'AR Amount',
  AR_ISSUED_DATE = 'AR Issued Date',
  PARTNER_NAME = 'Partner Name',
  PARTNER_TAX_CODE = 'Partner Tax Code',
  DEALER_NAME = 'Dealer Name',
  DEALER_TAX_CODE = 'Dealer Tax Code',
  UPLOADED_DEALER_NAME = 'Uploaded Dealer Name',
  UPLOADED_DEALER_TAX_CODE = 'Uploaded Dealer Tax Code',
  UPLOADED_PARTNER_NAME = 'Uploaded Partner Name',
  UPLOADED_PARTNER_TAX_CODE = 'Uploaded Partner Tax Code',
  LEGAL_REPRESENTATIVE_NAME = 'Legal Representative Name',
  LEGAL_REPRESENTATIVE_EMAIL = 'Legal Representative Email',
  TELEPHONE = 'Telephone',
  REGISTERED_OFFICE_ADDRESS = 'Registered Office Address',
  BANK_CODE = 'Bank Code',
  BANK_NAME = 'Bank Name',
  BRANCH_NAME = 'Branch Name',
  BANK_ACCOUNT_NUMBER = 'Bank Account Number',
  BANK_ACCOUNT_HOLDER = 'Bank Account Holder',
  INVOICE_DETAIL = 'Invoice Detail',
}

export type ExcelDataType<R> = { [key in ExcelTemplatesHeader]: R }; // {'Invoice Number' : data, 'Invoice Issued Date':data }

export type ExcelJsStyleKey = keyof ExcelJsStyle;

export type ExcelStylePropertyType = Partial<Record<ExcelJsStyleKey, any>>;
