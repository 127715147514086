import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isEmpty } from 'lodash-es';

import Button, { ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import { FormBorder } from 'components/stateless/CommonForm/FormBorder';
import { FormContents } from 'components/stateless/CommonForm/FormContents';
import FormInput from 'components/stateless/CommonForm/FormInput';
import { BackGroundType, FormSubtitle } from 'components/stateless/CommonForm/FormSubtitle';
import { FormValue } from 'components/stateless/CommonForm/FormValue';
import CitadCodeModal from 'components/stateless/Modal/common/CitadCodeModal';
import type { UserVerificationCodeRequest } from 'components/stateless/Modal/common/UserVerificationModal';
import UserVerificationModal from 'components/stateless/Modal/common/UserVerificationModal';
import Pagination from 'components/stateless/Pagination/Pagination';
import TableBody from 'components/stateless/Table/TableBody';
import TableBorder from 'components/stateless/Table/TableBorder';
import type { HeaderType } from 'components/stateless/Table/TableHeader';
import TableHeader from 'components/stateless/Table/TableHeader';
import Td from 'components/stateless/Table/Td';
import Tr from 'components/stateless/Table/Tr';
import { BackHeaderTitle } from 'components/stateless/Title/BackHeaderTitle';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { ROUTES_AC } from 'constants/routes/anchor';
import { AUTHORITY_TYPE, COMMON_APPROVAL_TYPE, OTP_TYPE } from 'enums';
import useMounted from 'hooks/useMounted';
import usePageable from 'hooks/usePageable';
import type Pageable from 'models/Pageable';
import type { AnchorPartnerAccountDetailVOModel } from 'models/vo/AnchorPartnerAccountDetailVO';
import type { BankCodeVOModel } from 'models/vo/BankCodeVO';
import type { FinancierClientAuthSettingVOModel } from 'models/vo/FinancierClientAuthSettingVO';
import type { WaitingAnchorPartnerAccountVOModel } from 'models/vo/WaitingAnchorPartnerAccountVO';
import { formErrorHandler } from 'utils/error/manager';
import { requestAnchorPartnerBankAccountDetail } from 'utils/http/api/anchor/anchor-partner-accounts';
import { requestAnchorClientAuthByFinancierId } from 'utils/http/api/anchor/client-auth-setting';
import {
  requestModifyAnchorPartnerAccountDetail,
  requestWaitingAnchorPartnerAccountList,
} from 'utils/http/api/anchor/waiting-anchor-partner-accounts';
import type { CreateAcWaitingAnchorPartnerAccount } from 'utils/http/api/anchor/waiting-anchor-partner-accounts/requests';
import { ModalSize, ModalType } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';
import { getPaymentSupportText } from 'utils/text';

type UpdateAccountUseForm = {
  account: AnchorPartnerAccountDetailVOModel['account'];
  accountOwner: AnchorPartnerAccountDetailVOModel['accountOwner'];
  requestedAccountBankName: AnchorPartnerAccountDetailVOModel['requestedAccountBankName'];
  requestedAccountBranchName: AnchorPartnerAccountDetailVOModel['requestedAccountBranchName'];
  bankCodeId: SelectedBankAccountInfoForm['bankCodeId']; // error 표시
};

type SelectedBankAccountInfoForm = {
  bankCodeId: BankCodeVOModel['id'];
  bankCode: BankCodeVOModel['bankCode'];
  bankName: BankCodeVOModel['bankName'];
  branchCode: BankCodeVOModel['branchCode'];
  branchName: BankCodeVOModel['branchName'];
};

function AnchorPartnerBankAccountConfirmedDetail() {
  const { t } = useTranslation();
  const modal = useModal();
  const mounted = useMounted();
  const history = useHistory();
  const { pageable: editHistoryPageable, setPageable: setEditHistoryPageable } = usePageable();
  const { anchorPartnerAccountId } = useParams() as any;

  const [anchorPartnerBankAccountDetailInfo, setAnchorPartnerBankAccountDetailInfo] =
    useState<AnchorPartnerAccountDetailVOModel>({} as AnchorPartnerAccountDetailVOModel);
  const [selectedBankAccountInfoForm, setSelectedBankAccountInfoForm] = useState<SelectedBankAccountInfoForm>(
    {} as SelectedBankAccountInfoForm,
  );
  const [editHistoryInfoList, setEditHistoryInfoList] = useState<Pageable<WaitingAnchorPartnerAccountVOModel[]>>();

  const [financierClientAuthSettingInfo, setFinancierClientAuthSettingInfo] =
    useState<FinancierClientAuthSettingVOModel>();

  const [isEditAccountInfo, setIsEditAccountInfo] = useState(false);

  const isHqOperator = financierClientAuthSettingInfo?.currentAuthorityType === AUTHORITY_TYPE.HQ_OPERATOR;
  const isAdmin = financierClientAuthSettingInfo?.currentAuthorityType === AUTHORITY_TYPE.ADMIN;
  const isHqOperatorOrAdmin = isHqOperator || isAdmin;

  const EDIT_HISTORY_TABLE_HEADERS: HeaderType[] = [
    {
      headerText: t('text:Edited_Date'),
      colWidths: 100,
    },
    {
      headerText: t('text:Associated_Anchor_Name'),
      colWidths: 120,
    },
    {
      headerText: t('text:Anchor_Master_Agreement_Number'),
      colWidths: 120,
    },
    {
      headerText: t('text:Currency'),
      colWidths: 100,
    },
    {
      headerText: t('text:Bank_Code'),
      colWidths: 100,
    },
    {
      headerText: t('text:Bank_Name'),
      colWidths: 100,
    },
    {
      headerText: t('text:Branch_Name'),
      colWidths: 100,
    },
    {
      headerText: t('text:Bank_Account_Number'),
      colWidths: 100,
    },
    {
      headerText: t('text:Bank_Account_Holder'),
      colWidths: 100,
    },
    {
      headerText: t('text:Edit_requested_by_User_ID/Name'),
      colWidths: 100,
    },
    {
      headerText: t('text:Edit_approved_by_User_ID/Name'),
      colWidths: 100,
    },
  ];

  const { register, getValues, errors, setError, clearErrors, reset, setValue } = useForm<UpdateAccountUseForm>({
    mode: 'onSubmit',
    shouldFocusError: true,
  });

  useEffect(() => {
    if (mounted) {
      fetchAll(editHistoryPageable.currentPage, editHistoryPageable.sizePerPage);
    }
  }, [mounted]);

  async function fetchAll(editHistoryPageNumber: number = 1, editHistoryPageRowCount: number = 10) {
    try {
      const bankAccountDetailData = await requestAnchorPartnerBankAccountDetail(anchorPartnerAccountId);
      const financierClientAuthSettingInfo = await requestAnchorClientAuthByFinancierId(
        bankAccountDetailData.responsibleFinancierId,
      );
      const editHistoryInfos = await requestWaitingAnchorPartnerAccountList(
        editHistoryPageNumber,
        editHistoryPageRowCount,
        {
          targetAnchorPartnerAccountId: anchorPartnerAccountId,
          approvalTypes: [COMMON_APPROVAL_TYPE.OPERATOR_CANCEL, COMMON_APPROVAL_TYPE.AUTHORIZER_APPROVED],
        },
      );

      ReactDOM.unstable_batchedUpdates(() => {
        setAnchorPartnerBankAccountDetailInfo(bankAccountDetailData);
        setFinancierClientAuthSettingInfo(financierClientAuthSettingInfo);
        setEditHistoryInfoList(editHistoryInfos);
        setEditHistoryPageable(editHistoryInfos);
      });
    } catch (error) {
      modal.show(error);
    }
  }

  const renderSupplyChainPartnerInformation = (): JSX.Element => {
    return (
      <div className="content-area">
        <form>
          <SectionTitle title={t(`text:Partner_Information`)} />
          <FormBorder>
            <FormContents>
              <div className="row">
                <FormValue label={t(`text:Partner_Name`)} value={anchorPartnerBankAccountDetailInfo?.partnerName} />
                <FormValue label={t(`text:Tax_Code`)} value={anchorPartnerBankAccountDetailInfo?.partnerTaxCode} />
              </div>
              <div className="row">
                <FormValue
                  label={t(`text:Company_Registration_Number`)}
                  value={anchorPartnerBankAccountDetailInfo?.partnerBusinessCode}
                />
                <FormValue col={3} label={t(`text:Telephone`)} value={anchorPartnerBankAccountDetailInfo?.telephone} />
                <FormValue col={3} label={t(`text:Fax`)} value={anchorPartnerBankAccountDetailInfo?.fax} />
              </div>
              <div className="row">
                <FormValue
                  label={t(`text:Legal_Representative_Name`)}
                  value={anchorPartnerBankAccountDetailInfo?.representativeName}
                />
                <FormValue
                  col={3}
                  label={t(`text:Legal_Representative_Title`)}
                  value={anchorPartnerBankAccountDetailInfo?.representativePosition}
                />
                <FormValue
                  col={3}
                  label={t(`text:Legal_Representative_Email`)}
                  value={anchorPartnerBankAccountDetailInfo?.representativeEmail}
                />
              </div>
              <div className="row">
                <FormValue
                  col={12}
                  label={t(`text:Registered_Office_Address`)}
                  value={anchorPartnerBankAccountDetailInfo?.address}
                />
              </div>
            </FormContents>
          </FormBorder>
        </form>
      </div>
    );
  };

  const renderDesignatedBankAccountInformationForAPSettlement = (): JSX.Element => {
    const handleClickSaveBtn = () => {
      const financierId = financierClientAuthSettingInfo?.financierId ?? NaN;

      const requestModifyAccountInfo = async (
        successModal: () => void,
        verificationCode?: UserVerificationCodeRequest,
      ) => {
        const bankCodeId =
          selectedBankAccountInfoForm?.bankCodeId ?? anchorPartnerBankAccountDetailInfo?.accountBankCodeId ?? NaN;

        const accountInfo: CreateAcWaitingAnchorPartnerAccount = {
          ...getValues(),
          bankCodeId,
          targetAnchorPartnerAccountId: anchorPartnerAccountId,
          financierId,
          otpCode: verificationCode?.otpCode,
          queryValue: verificationCode?.queryValue,
        };

        try {
          await requestModifyAnchorPartnerAccountDetail(accountInfo);
          clearErrors();
          successModal();
          setIsEditAccountInfo(false);
          fetchAll(editHistoryPageable.currentPage, editHistoryPageable.sizePerPage);
        } catch (error) {
          modal.show(error);
          formErrorHandler<UpdateAccountUseForm>(error, setError, clearErrors);
        }
      };

      const modifySuccessModal = () => {
        modal.show(
          <h6>
            {t('text:The_changes_to_the_Partner_s_Bank_Account_have_been_saved_successfully')}
            <br />
            {t(
              'text:Please_make_sure_to_change_the_Partner_s_Bank_Account_in_the_bank_or_ERP_system_where_the_accounts_receivable_payment_actually_takes_place',
            )}
          </h6>,
        );
      };

      const requestModifySuccessModal = () => {
        modal.show(
          <h6>
            {t('text:Request_for_modification_approval_has_been_completed')}
            <br />
            {t('text:Modification_will_be_completed_after_approval_by_the_Admin')}
          </h6>,
          {
            closeBtnCb: () => {
              history.push(ROUTES_AC.MANAGE_PARTNER.BANK_ACCOUNT_LIST);
            },
          },
        );
      };

      const askSendRequestApprovalForTheModificationModal = () => {
        modal.show(<h6>{t('text:Would_you_like_to_request_approval_for_the_modification')}</h6>, {
          modalType: ModalType.CONFIRM,
          closeBtnText: t(`text:Cancel`),
          confirmBtnCb: () => {
            requestModifyAccountInfo(isAdmin ? modifySuccessModal : requestModifySuccessModal);
          },
        });
      };

      if (isAdmin && financierClientAuthSettingInfo?.otpType !== OTP_TYPE.NONE) {
        const verificationCode: UserVerificationCodeRequest = {};

        modal.show(
          <UserVerificationModal
            modalId={modal.id}
            verificationCode={verificationCode}
            requestIdType="financierId"
            requestId={financierId}
            clientAuthSetting={financierClientAuthSettingInfo as FinancierClientAuthSettingVOModel}
          />,
          {
            modalType: ModalType.CONFIRM,
            title: t('text:User_Verification'),
            closeBtnText: t('text:Cancel'),
            confirmBtnCb: () => requestModifyAccountInfo(modifySuccessModal, verificationCode),
          },
        );
      } else if (isHqOperator || financierClientAuthSettingInfo?.otpType === OTP_TYPE.NONE) {
        askSendRequestApprovalForTheModificationModal();
      }
    };

    const handleClickEditBtn = () => {
      setIsEditAccountInfo(true);
    };

    const handleClickCancelBtn = () => {
      modal.show(
        <h6>
          {t('text:Would_you_like_to_stop_modifying_the_information?')}
          <br />
          {t('text:If_the_modification_is_aborted_the_entered_content_will_not_be_saved')}
        </h6>,
        {
          modalType: ModalType.CONFIRM,
          closeBtnText: t('text:Close'),
          confirmBtnCb: () => {
            setIsEditAccountInfo(false);
            setSelectedBankAccountInfoForm({} as SelectedBankAccountInfoForm);
            reset({
              accountOwner: anchorPartnerBankAccountDetailInfo?.accountOwner,
              account: anchorPartnerBankAccountDetailInfo?.account,
              requestedAccountBankName: anchorPartnerBankAccountDetailInfo?.requestedAccountBankName,
              requestedAccountBranchName: anchorPartnerBankAccountDetailInfo?.requestedAccountBranchName,
            });
          },
        },
      );
    };

    const handelClickFindBtn = () => {
      const setBankCodeData = (data: BankCodeVOModel): void => {
        setSelectedBankAccountInfoForm({
          bankCodeId: data.id,
          bankCode: data.bankCode,
          bankName: data.bankName,
          branchCode: data.branchCode,
          branchName: data.branchName,
        });
      };

      modal.show(
        <CitadCodeModal
          handleBankCodeData={setBankCodeData}
          modalId={modal.id}
          financierId={financierClientAuthSettingInfo?.financierId!}
        />,
        {
          title: t('text:Find_Bank_Code'),
          modalType: ModalType.ALERT,
          modalSize: ModalSize.L,
          closeBtnText: t('text:Close'),
        },
      );
    };

    const renderEditSaveButton = () => {
      return !isEditAccountInfo ? (
        <Button size={ButtonSizeEnum.SM} onClick={handleClickEditBtn} style={{ width: '60px' }}>
          {t('text:Edit')}
        </Button>
      ) : (
        <>
          <Button
            size={ButtonSizeEnum.SM}
            variant={ButtonVariantEnum.OUTLINED}
            color={ButtonColorEnum.SECONDARY}
            onClick={handleClickCancelBtn}
          >
            {t('text:Cancel')}
          </Button>
          <Button size={ButtonSizeEnum.SM} onClick={handleClickSaveBtn} style={{ width: '60px' }}>
            {t('text:Save')}
          </Button>
        </>
      );
    };

    const getDesignatedBankAccountInfo = () => {
      if (isEmpty(selectedBankAccountInfoForm)) {
        const {
          accountBankCode: bankCode,
          accountBankName: bankName,
          accountBranchCode: branchCode,
          accountBranchName: branchName,
        } = anchorPartnerBankAccountDetailInfo;

        return {
          bankCode: bankCode ?? '',
          bankName: bankName ?? '',
          branchCode: branchCode ?? '',
          branchName: branchName ?? '',
        };
      } else {
        const { bankCode, bankName, branchCode, branchName } = selectedBankAccountInfoForm;

        return {
          bankCode: bankCode ?? '',
          bankName: bankName ?? '',
          branchCode: branchCode ?? '',
          branchName: branchName ?? '',
        };
      }
    };

    return (
      <div className="content-area">
        <form>
          <SectionTitle title={t(`text:Designated_Bank_Account_Information_for_AP_Settlement`)}>
            {isHqOperatorOrAdmin && renderEditSaveButton()}
          </SectionTitle>
          <FormBorder editable={isEditAccountInfo}>
            <FormSubtitle
              backGroundType={BackGroundType.DarkGray}
              title={t('text:Associated_Anchor_Master_Agreement_Information')}
            >
              <div className="flex-end align-items-center me-3">
                <span className="me-2">{t('text:Go_to_Agreement_Details')}</span>

                <Button
                  size={ButtonSizeEnum.SM}
                  variant={ButtonVariantEnum.OUTLINED}
                  color={ButtonColorEnum.SECONDARY}
                  className="flex-column-center"
                  onClick={() => {
                    history.push(
                      ROUTES_AC.COMPANY.AGREEMENT_DETAIL_BUILD_PATH(
                        anchorPartnerBankAccountDetailInfo?.anchorAgreementId!,
                      ),
                    );
                  }}
                >
                  <FontAwesomeIcon icon={faArrowRight} />
                </Button>
              </div>
            </FormSubtitle>
            <FormContents>
              <div className="row">
                <FormValue
                  col={12}
                  label={t(`text:Anchor_Master_Agreement_Number`)}
                  value={anchorPartnerBankAccountDetailInfo?.anchorAgreementContractNo}
                />
              </div>
              <div className="row">
                <FormValue
                  label={t(`text:Automatic_Settlement`)}
                  value={getPaymentSupportText(anchorPartnerBankAccountDetailInfo?.paymentSupport)}
                />
                <FormValue label={t(`text:Currency`)} value={anchorPartnerBankAccountDetailInfo?.currencyType} />
              </div>
              <div className="row">
                <FormValue
                  label={t(`text:Responsible_Bank`)}
                  value={anchorPartnerBankAccountDetailInfo?.responsibleFinancierName}
                />
                <FormValue
                  label={t(`text:Responsible_Branch_Name`)}
                  value={anchorPartnerBankAccountDetailInfo?.responsibleBranchName}
                />
              </div>
            </FormContents>

            <FormSubtitle
              backGroundType={BackGroundType.DarkGray}
              title={t('text:Designated_Bank_Account_Information')}
            />
            <FormContents>
              <div className="row">
                <FormInput
                  requiredOptions={{ fixedRequired: isEditAccountInfo }}
                  name="bankCode"
                  label={t(`text:Bank_Code`)}
                  value={getDesignatedBankAccountInfo().bankCode}
                  disabled
                  error={errors.bankCodeId}
                >
                  {isEditAccountInfo && (
                    <Button size={ButtonSizeEnum.SM} onClick={handelClickFindBtn}>
                      {t('text:Find')}
                    </Button>
                  )}
                </FormInput>
                <FormInput
                  requiredOptions={{ fixedRequired: isEditAccountInfo }}
                  name="bankName"
                  label={t(`text:Bank_Name`)}
                  value={getDesignatedBankAccountInfo().bankName}
                  disabled
                  error={errors.bankCodeId}
                />
              </div>
              <div className="row">
                <FormInput
                  requiredOptions={{ fixedRequired: isEditAccountInfo }}
                  name="branchCode"
                  label={t(`text:Branch_Code`)}
                  value={getDesignatedBankAccountInfo().branchCode}
                  disabled
                  error={errors.bankCodeId}
                />
                <FormInput
                  requiredOptions={{ fixedRequired: isEditAccountInfo }}
                  name="branchName"
                  label={t(`text:Branch_Name`)}
                  value={getDesignatedBankAccountInfo().branchName}
                  disabled
                  error={errors.bankCodeId}
                />
              </div>
              <div className="row">
                <FormInput
                  label={t(`text:Bank_Account_Number`)}
                  name="account"
                  defaultValue={anchorPartnerBankAccountDetailInfo?.account}
                  ref={register}
                  requiredOptions={{ required: true }}
                  disabled={!isEditAccountInfo}
                  error={errors.account}
                  applyUpperCase
                />
                <FormInput
                  label={t(`text:Bank_Account_Holder`)}
                  name="accountOwner"
                  defaultValue={anchorPartnerBankAccountDetailInfo?.accountOwner}
                  ref={register}
                  requiredOptions={{ required: true }}
                  disabled={!isEditAccountInfo}
                  error={errors.accountOwner}
                  applyUpperCase
                />
              </div>
              <div className="row">
                <FormInput
                  label={t(`text:Requested_Bank_Name`)}
                  name="requestedAccountBankName"
                  defaultValue={anchorPartnerBankAccountDetailInfo?.requestedAccountBankName}
                  ref={register}
                  disabled={!isEditAccountInfo}
                  error={errors.requestedAccountBankName}
                />
                <FormInput
                  label={t(`text:Requested_Branch_Name`)}
                  name="requestedAccountBranchName"
                  defaultValue={anchorPartnerBankAccountDetailInfo?.requestedAccountBranchName}
                  ref={register}
                  disabled={!isEditAccountInfo}
                  error={errors.requestedAccountBranchName}
                />
              </div>
            </FormContents>
            {anchorPartnerBankAccountDetailInfo?.partnerAgreementContractNo !== undefined && (
              <FormContents backGroundType={BackGroundType.WHITE}>
                <div className="row text-bold-brick-red">
                  {`* ${t(
                    'text:The_settlement_must_be_made_to_the_Partners_repayment_account_below_Check_that_the_designated_bank_account_above_matches_the_repayment_account_below',
                  )}`}
                </div>
                <div className="row">
                  <FormValue
                    label={t(`text:Agreement_Number`)}
                    value={anchorPartnerBankAccountDetailInfo?.partnerAgreementContractNo}
                  />
                  <FormValue
                    label={t(`text:Bank_Name`)}
                    value={anchorPartnerBankAccountDetailInfo?.principalRepaymentAccountFinancierName}
                  />
                </div>
                <div className="row">
                  <FormValue
                    label={t(`text:Account_Number`)}
                    value={anchorPartnerBankAccountDetailInfo?.principalRepaymentAccount}
                  />
                  <FormValue
                    label={t(`text:Account_Holder`)}
                    value={anchorPartnerBankAccountDetailInfo?.principalRepaymentAccountOwner}
                  />
                </div>
              </FormContents>
            )}
          </FormBorder>
        </form>
      </div>
    );
  };

  const renderEditHistoryInformation = () => {
    async function paginate(selectedPageNumber: number, selectedRowCount: number): Promise<void> {
      try {
        const editHistoryInfos = await requestWaitingAnchorPartnerAccountList(selectedPageNumber, selectedRowCount, {
          targetAnchorPartnerAccountId: anchorPartnerAccountId,
          approvalTypes: [COMMON_APPROVAL_TYPE.OPERATOR_CANCEL, COMMON_APPROVAL_TYPE.AUTHORIZER_APPROVED],
        });

        ReactDOM.unstable_batchedUpdates(() => {
          setEditHistoryInfoList(editHistoryInfos);
          setEditHistoryPageable(editHistoryInfos);
        });
      } catch (error) {
        modal.show(error);
      }
    }

    return (
      <div className="content-area">
        <SectionTitle title={t('text:Information_Edit_History')} />
        <TableBorder>
          <TableHeader header={EDIT_HISTORY_TABLE_HEADERS} />
          <TableBody numOfCol={EDIT_HISTORY_TABLE_HEADERS.length}>
            {editHistoryInfoList?.content.map((item, i) => {
              return (
                <Tr key={i}>
                  <Td data={item.updatedDateTime} format="datetime" />
                  <Td data={item.anchorName} />
                  <Td data={item.anchorAgreementContractNo} />
                  <Td data={item.currencyType} />
                  <Td data={item.accountBankCode} />
                  <Td data={item.requestedAccountBankName} />
                  <Td data={item.requestedAccountBranchName} />
                  <Td data={item.account} />
                  <Td data={item.accountOwner} />
                  <Td data={`${item.operatorUserLoginId} / ${item.operatorUserName}`} />
                  <Td data={`${item.authorizerUserLoginId} / ${item.authorizerUserName}`} />
                </Tr>
              );
            })}
          </TableBody>
        </TableBorder>
        <Pagination pageable={editHistoryPageable} paginate={paginate} />
      </div>
    );
  };

  return (
    <>
      <BackHeaderTitle title={anchorPartnerBankAccountDetailInfo?.partnerName} />
      {renderSupplyChainPartnerInformation()}
      {renderDesignatedBankAccountInformationForAPSettlement()}
      {renderEditHistoryInformation()}
    </>
  );
}

export default AnchorPartnerBankAccountConfirmedDetail;
