import { useTranslation } from 'react-i18next';

import { isNil } from 'lodash-es';

import Button, { ButtonSizeEnum } from 'components/stateless/Button/Button';
import { FormBorder } from 'components/stateless/CommonForm/FormBorder';
import { FormContents } from 'components/stateless/CommonForm/FormContents';
import { BackGroundType } from 'components/stateless/CommonForm/FormSubtitle';
import { FormValue } from 'components/stateless/CommonForm/FormValue';
import DelinquentInterestModal from 'components/stateless/Modal/common/loan/DelinquentInterestModal';
import StatusDescriptionModal from 'components/stateless/Modal/common/status/StatusDescriptionModal';
import QuestionButton from 'components/stateless/QuestionButton';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import QuestionTooltip from 'components/stateless/Tooltip/QuestionTooltip';
import { LOAN_STATUS } from 'enums';
import type { LoanDetailVOModel } from 'models/vo/LoanDetailVO';
import type { OverdueInterestRateTermSpreadVOModel } from 'models/vo/OverdueInterestRateTermSpreadVO';
import getStatusBadgeClass from 'utils/classNames/getStatusBadgeClass';
import { getDayTerm } from 'utils/date/date';
import { getTotalInterestRate } from 'utils/logic';
import { ModalSize, ModalType } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';
import { isLoanDisbursed } from 'utils/status';
import { getFilteredDisbursedAmount } from 'utils/valueManager/ValueManager';

interface DealerFinancingDetailSummaryProps {
  data: LoanDetailVOModel;
  factoringEnable: boolean;
  onClickViewExpectedRepaymentAmount: (e: any) => void;
}

function DealerFinancingDetailSummary({
  data,
  factoringEnable,
  onClickViewExpectedRepaymentAmount,
}: DealerFinancingDetailSummaryProps) {
  const { t } = useTranslation(['format']);
  const modal = useModal();
  const isShowNetDisbursementAmount = data.showNetDisbursementAmount ?? false;

  const isExpiredStatus = data.loanStatus === LOAN_STATUS.EXPIRATION;
  const isDisbursedOrOverdue = data.loanStatus === LOAN_STATUS.DISBURSED || data.loanStatus === LOAN_STATUS.OVERDUE; // 예상 상환금액 조회

  const onClickFinancingStatus = () => {
    modal.show(
      <StatusDescriptionModal
        statusDescriptionEnum={Object.values(LOAN_STATUS)}
        statusDescriptionModalType="BOX"
        statusDescriptionEnumType="LOAN_STATUS"
      />,
      {
        modalSize: ModalSize.XL,
        modalType: ModalType.ALERT,
        closeBtnText: t('text:Close'),
      },
    );
  };

  const handleClickExpectedDelinquentInterestAmountDescription = async (
    termSpreads?: OverdueInterestRateTermSpreadVOModel[],
  ) => {
    if (termSpreads && termSpreads.length !== 0) {
      modal.show(<DelinquentInterestModal termSpreads={termSpreads} />, {
        modalType: ModalType.ALERT,
        modalSize: ModalSize.L,
        title: t('text:Delinquent_Interest'),
      });
    } else {
      modal.show(<h6>{t('text:No_term_spread_of_the_delinquent_interest_has_been_set')}</h6>, {
        modalType: ModalType.ALERT,
        title: t('text:Delinquent_Interest'),
      });
    }
  };

  return (
    <div className="content-area">
      <SectionTitle title={t('text:Summary')} />
      <FormBorder>
        <FormContents backGroundType={BackGroundType.WHITE}>
          <div className="row">
            <FormValue
              col={3}
              className="information-form__input border-none pointfont"
              label={t('text:Requested_Financing_Amount')}
              value={data.principalAmount}
              format="number"
            />
            {!factoringEnable && (
              <FormValue
                col={3}
                className="information-form__input border-none pointfont"
                label={t('text:Financing_Amount')}
                value={getFilteredDisbursedAmount(data.loanStatus, data.disbursedAmount)}
                format="number"
              />
            )}
            {isShowNetDisbursementAmount && (
              <FormValue
                col={3}
                className="information-form__input border-none pointfont"
                label={t('text:Advance_Disbursement')}
                value={data.netDisbursementAmount}
                format="number"
                labelChildren={
                  <QuestionTooltip
                    id="summary-netDisbursementAmount-tooltip"
                    place="top"
                    contentText={
                      <>
                        <div>
                          {factoringEnable ? t('text:Requested_Financing_Amount') : t('text:Principal_Amount')} ⓐ
                        </div>
                        <div>{factoringEnable ? t('text:Discount_Charge') : t('text:Prepayment_Interest')} ⓑ</div>
                        <div>-----------------------------------------------------</div>
                        <div>{t('text:Advance_Disbursement')} ⓐ - ⓑ = ⓒ</div>
                      </>
                    }
                  />
                }
              />
            )}
            {factoringEnable && (
              <FormValue
                col={3}
                className="information-form__input border-none pointfont"
                label={t('text:Approved_Financing_Amount')}
                value={data.approvedFinancingAmount}
                format="number"
              />
            )}
            <FormValue
              col={3}
              className={getStatusBadgeClass('LOAN_STATUS', data.loanStatus)}
              label={t('text:Financing_Status')}
              value={t(`code:financing-status.${data.loanStatus}`)}
              format="code"
              hasStatusDescription={true}
              showStatusDescriptionFunc={onClickFinancingStatus}
            />
          </div>
        </FormContents>
        <FormContents>
          <div className="row">
            <FormValue col={3} label={t('text:Platform_Financing_ID')} value={data.loanId} />
            <FormValue col={3} label={t('text:Financier_Financing_ID')} value={data.financierLoanId} />
            <FormValue col={3} label={t('text:Partner_Name')} value={data.dealerClientName} />
            <FormValue col={3} label={t('text:Anchor_Name')} value={data.anchorClientName} />
          </div>
          <div className="row">
            {!factoringEnable && (
              <FormValue
                col={3}
                label={t('text:Total_Interest_Rate_(APR)')}
                value={
                  isLoanDisbursed(data.loanStatus)
                    ? getTotalInterestRate(data.disbursedBasisInterestRate, data.disbursedTotalInterestRateWithoutBasis)
                    : '-'
                }
              />
            )}
            <FormValue col={3} label={t('text:Disbursed_Date')} value={data.disbursedDate} format="date" />
            {isExpiredStatus ? (
              <FormValue col={3} label={t('text:Expired_Date')} value={data.expiredDate} format="date" />
            ) : (
              <FormValue col={3} label={t('text:Repaid_Date')} value={data.repaidDate} format="date" />
            )}
            {isExpiredStatus ? (
              <FormValue
                col={3}
                label={t('text:Financing_Term')}
                value={getDayTerm(data.disbursedDate, data.expiredDate, { withText: true })}
              />
            ) : (
              <FormValue
                col={3}
                label={t('text:Financing_Term')}
                value={
                  data.repaidDate ? `${t('format:number', { value: data.financingPeriod })}  ${t('text:Days')}` : '-'
                }
              />
            )}
          </div>
        </FormContents>
        {!factoringEnable && (
          <FormContents backGroundType={BackGroundType.WHITE}>
            <div className="row">
              <FormValue
                col={3}
                className="information-form__input border-none"
                label={t('text:Outstanding_Principal_Balance')}
                value={isLoanDisbursed(data.loanStatus) ? data.outstandingBalance : '-'}
                format="number"
              />
              <FormValue
                col={3}
                className="information-form__input border-none"
                label={t('text:Total_Interest_Amount')}
                value={isLoanDisbursed(data.loanStatus) ? data.totalInterestAmount : '-'}
                format="number"
              />
              <FormValue
                col={3}
                className="information-form__input border-none"
                label={t('text:Accrued_Interest_Amount')}
                value={isLoanDisbursed(data.loanStatus) ? data.accruedInterestAmount : '-'}
                format="number"
              />
              {isDisbursedOrOverdue && (
                <div className="col-3">
                  <div className="information-form__label">
                    {t('text:Expected_Delinquent_Interest_Amount')}
                    <QuestionButton
                      onClick={() =>
                        handleClickExpectedDelinquentInterestAmountDescription(data.overdueInterestRateTermSpreads)
                      }
                    />
                  </div>
                  <div className="d-flex information-form__input border-none">
                    {t('format:number', {
                      value: data.totalDelayAmount,
                    })}
                    {!isNil(data.totalDelayAmount) && (
                      <div className="text-bold-brick-red ps-2">{data.remainingFinancingTerm}</div>
                    )}
                  </div>
                </div>
              )}
            </div>
            <div className="row">
              <FormValue
                col={3}
                className="information-form__input border-none"
                label={t('text:Repaid_Principal_Amount')}
                value={isLoanDisbursed(data.loanStatus) ? data.repaidPrincipalAmount : '-'}
                format="number"
              />
              <FormValue
                col={3}
                className="information-form__input border-none"
                label={t('text:Repaid_Interest_Amount')}
                value={isLoanDisbursed(data.loanStatus) ? data.repaidInterestAmount : '-'}
                format="number"
              />
              <FormValue
                col={3}
                className="information-form__input border-none"
                label={t('text:Outstanding_Accrued_Interest_Amount')}
                value={isLoanDisbursed(data.loanStatus) ? data.outstandingAccruedInterestAmount : '-'}
                format="number"
              />
              <FormValue
                col={3}
                className="information-form__input border-none"
                label={t('text:Repaid_Delinquent_Interest_Amount')}
                value={isLoanDisbursed(data.loanStatus) ? data.repaidDelayAmount : '-'}
                format="number"
              />
            </div>
          </FormContents>
        )}
        {factoringEnable && isDisbursedOrOverdue && (
          <FormContents backGroundType={BackGroundType.WHITE}>
            <div className="row">
              <div className="col-3">
                <div className="information-form__label">
                  {t('text:Expected_Delinquent_Interest_Amount')}
                  <QuestionButton
                    onClick={() =>
                      handleClickExpectedDelinquentInterestAmountDescription(data.overdueInterestRateTermSpreads)
                    }
                  />
                </div>
                {isDisbursedOrOverdue && (
                  <div className="d-flex information-form__input border-none">
                    {t('format:number', {
                      value: data.totalDelayAmount,
                    })}
                    {!isNil(data.totalDelayAmount) && (
                      <div className="text-bold-brick-red ps-2">{data.remainingFinancingTerm}</div>
                    )}
                  </div>
                )}
              </div>
              <FormValue
                col={3}
                className="information-form__input border-none"
                label={t('text:Repaid_Delinquent_Interest_Amount')}
                value={isLoanDisbursed(data.loanStatus) ? data.repaidDelayAmount : '-'}
                format="number"
              />
            </div>
          </FormContents>
        )}
      </FormBorder>
      {isDisbursedOrOverdue && (
        <div className="row mt-4">
          <div className="col-12">
            <div className="d-flex justify-content-between">
              <div className="information-form__input border-none align-self-center">
                {t(
                  'text:Click_the_button_on_the_right_to_see_the_expected_principal_and_interest_repayment_information',
                )}
              </div>
              <Button size={ButtonSizeEnum.MD} onClick={onClickViewExpectedRepaymentAmount}>
                {t('text:View_Expected_Repayment_Amount')}
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default DealerFinancingDetailSummary;
