import { useTranslation } from 'react-i18next';

import type { SidebarMenuInfoType } from 'components/stateless/Navigation/Sidebar/useSidebarController';
import { ROUTES_FI } from 'constants/routes/financier';
import { AUTHORITY_TYPE, SUPPORTED_COLLATERAL_TYPE } from 'enums';
import {
  getArCommissionEnable,
  getCollateralType,
  getFactoringEnable,
  getLoanRepaymentAggregationEnabled,
  getPotentialPartnerRegistrable,
  getSignIn,
} from 'utils/storage/LocalStorage';

export function fiMenuInfo() {
  const { t } = useTranslation(['format']);
  const signInInfo = getSignIn();
  const collateralType = getCollateralType();
  const isInvoice = collateralType === SUPPORTED_COLLATERAL_TYPE.INVOICE;
  const isAr = collateralType === SUPPORTED_COLLATERAL_TYPE.AR;
  const isEnabledLoanRepaymentAggregation = getLoanRepaymentAggregationEnabled();
  const isOperator = signInInfo?.authorityType === AUTHORITY_TYPE.OPERATOR;
  const isAdmin = signInInfo?.authorityType === AUTHORITY_TYPE.ADMIN;
  const isProductionEnvironment = process.env.REACT_APP_ENVIRONMENT === 'production';
  const isArCommissionEnable = getArCommissionEnable();
  const isFactoringEnable = getFactoringEnable();

  const menuInfo: Array<SidebarMenuInfoType> = [
    {
      mainMenu: { title: t('text:Home'), path: ROUTES_FI.DASHBOARD },
    },
    {
      mainMenu: { title: t('text:Manage_Anchor') },
      subMenu: [
        { title: t('text:Company'), path: ROUTES_FI.MANAGE_ANCHOR.COMPANY_LIST },
        { title: t('text:Master_Agreement'), path: ROUTES_FI.MANAGE_ANCHOR.AGREEMENT_LIST },
        { title: t('text:User'), path: ROUTES_FI.MANAGE_ANCHOR.USER_LIST },
        { title: t('text:Report'), path: ROUTES_FI.MANAGE_ANCHOR.REPORT_LIST },
      ],
    },
    {
      mainMenu: { title: t('text:Manage_Partner') },
      subMenu: [
        { title: t('text:Company'), path: ROUTES_FI.MANAGE_PARTNER.COMPANY_LIST },
        { title: t('text:Master_Agreement'), path: ROUTES_FI.MANAGE_PARTNER.AGREEMENT_LIST },
        { title: t('text:User'), path: ROUTES_FI.MANAGE_PARTNER.USER_LIST },
        { title: t('text:Report'), path: ROUTES_FI.MANAGE_PARTNER.REPORT_LIST },
      ],
    },
    {
      mainMenu: {
        title: t('text:Manage_Uploaded_Partner'),
        hidden: !(!isInvoice && getPotentialPartnerRegistrable()),
      },
      subMenu: [
        { title: t('text:Registration'), path: ROUTES_FI.MANAGE_UPLOADED_PARTNER.REGISTRATION_LIST },
        {
          title: t('text:Acquisition'),
          path: ROUTES_FI.MANAGE_UPLOADED_PARTNER.ACQUISITION_LIST,
        },
        {
          title: t('text:Bank_Account'),
          path: ROUTES_FI.MANAGE_UPLOADED_PARTNER.BANK_ACCOUNT_REVISION_LIST,
        },
        {
          title: t('text:Assignment'),
          path: ROUTES_FI.MANAGE_UPLOADED_PARTNER.ASSIGNMENT_LIST,
          hidden: process.env.REACT_APP_ENVIRONMENT === 'production' || process.env.REACT_APP_ENVIRONMENT === 'demo',
        },
      ],
    },
    {
      mainMenu: {
        title: t('text:Manage_Uploaded_Dealer'),
        hidden: isAr,
      },
      subMenu: [
        {
          title: t('text:Registration'),
          path: ROUTES_FI.MANAGE_DEALER.REGISTRATION_LIST,
        },
        {
          title: t('text:Assignment'),
          path: isAdmin ? ROUTES_FI.MANAGE_DEALER.ASSIGNMENT_LIST_ADMIN : ROUTES_FI.MANAGE_DEALER.ASSIGNMENT_LIST,
        },
      ],
    },
    {
      mainMenu: {
        title: t('text:Register_Invoices'),
        hidden: isAr,
      },
      subMenu: [
        { title: t('text:Registration'), path: ROUTES_FI.REGISTER_INVOICE.REGISTRATION_STEP1, hidden: !isOperator },
        { title: t('text:Confirmation'), path: ROUTES_FI.REGISTER_INVOICE.CONFIRMATION_LIST },
      ],
    },
    {
      mainMenu: {
        title: t('text:Register_AR'),
        hidden: isInvoice,
      },
      subMenu: [{ title: t('text:Confirmation'), path: ROUTES_FI.REGISTER_AR.CONFIRMATION_LIST }],
    },
    {
      mainMenu: {
        title: t('text:Manage_Financing'),
      },
      subMenu: [
        { title: t('text:Approval'), path: ROUTES_FI.MANAGE_FINANCING.APPROVAL_LIST },
        {
          title: t('text:Bulk_Approval'),
          path: ROUTES_FI.MANAGE_FINANCING.BULK_APPROVAL_LIST,
          hidden: !isFactoringEnable,
        },
        {
          title: t('text:Early_Repayment'),
          path: ROUTES_FI.MANAGE_FINANCING.EARLY_REPAYMENT_LIST,
        },
        {
          title: t('text:Extension'),
          path: ROUTES_FI.MANAGE_FINANCING.EXTENSION_LIST,
          hidden: isAr,
        },
        {
          title: t('text:Secured_Inventory_Update'),
          path: ROUTES_FI.MANAGE_FINANCING.SECURED_INVENTORY_UPDATE_LIST,
          hidden: isAr,
        },
      ],
    },
    {
      mainMenu: {
        title: t('text:View_Transactions'),
      },
      subMenu: [
        { title: t('text:AR'), path: ROUTES_FI.VIEW_TRANSACTION.AR_LIST, hidden: isInvoice },
        { title: t('text:Invoice'), path: ROUTES_FI.VIEW_TRANSACTION.INVOICE_LIST, hidden: isAr },
        {
          title: t('text:Invoice_Payment'),
          path: ROUTES_FI.VIEW_TRANSACTION.INVOICE_PAYMENT_LIST,
          hidden: isAr,
        },
        {
          title: t('text:Financing'),
          path: ROUTES_FI.VIEW_TRANSACTION.FINANCING_LIST,
        },
        {
          title: t('text:AR_Settlement'),
          path: ROUTES_FI.VIEW_TRANSACTION.AR_SETTLEMENT_LIST,
          hidden: isInvoice,
        },
        {
          title: t('text:Withdrawal_from_Partner'),
          path: ROUTES_FI.VIEW_TRANSACTION.SETTLEMENT_PAYMENT_LIST,
          hidden: !isArCommissionEnable,
        },
        {
          title: t('text:Interest_Payment'),
          path: ROUTES_FI.VIEW_TRANSACTION.INTEREST_PAYMENT_LIST,
          hidden: !isEnabledLoanRepaymentAggregation,
        },
        {
          title: t('text:Overview_by_Anchor'),
          path: ROUTES_FI.VIEW_TRANSACTION.OVERVIEW_BY_ANCHOR_LIST,
        },
        {
          title: t('text:Overview_by_Partner'),
          path: ROUTES_FI.VIEW_TRANSACTION.OVERVIEW_BY_PARTNER_LIST,
        },
        {
          title: t('text:Confirmation_Notification'),
          path: ROUTES_FI.VIEW_TRANSACTION.DEPT_SELLING_NOTICE_LIST,
          hidden: !isFactoringEnable,
        },
      ],
    },
    {
      mainMenu: {
        title: t('text:Financier_Settings'),
      },
      subMenu: [
        { title: t('text:Branch'), path: ROUTES_FI.SETTINGS.BRANCH_LIST },
        { title: t('text:User'), path: ROUTES_FI.SETTINGS.USER_LIST },
      ],
    },
    {
      mainMenu: {
        title: t('text:Program_Settings'),
      },
      subMenu: [
        { title: t('text:Holidays'), path: ROUTES_FI.PROGRAM_SETTINGS.HOLIDAYS_LIST },
        { title: t('text:Operating_Hours'), path: ROUTES_FI.PROGRAM_SETTINGS.OPERATION_HOURS },
        {
          title: t('text:Base_Interest_Rate'),
          path: ROUTES_FI.PROGRAM_SETTINGS.INTEREST_RATE_FEE_LIST,
        },
        {
          title: t('text:Delinquent_Interest_Rate'),
          path: ROUTES_FI.PROGRAM_SETTINGS.DELINQUENT_INTEREST_RATE_LIST,
        },
        { title: t('text:Bank_Code'), path: ROUTES_FI.PROGRAM_SETTINGS.BANK_CODE_LIST },
        { title: t('text:Document_Templates'), path: ROUTES_FI.PROGRAM_SETTINGS.DOCUMENT_LIST },
        { title: t('text:Financing_Conditions'), path: ROUTES_FI.PROGRAM_SETTINGS.FINANCING },
        {
          title: t('text:Transaction_Fee_Setting'),
          path: ROUTES_FI.PROGRAM_SETTINGS.CHARGE,
          hidden: !isArCommissionEnable,
        },
        {
          title: t('text:Email_Notification'),
          path: ROUTES_FI.PROGRAM_SETTINGS.EMAIL_NOTIFICATION,
        },
      ],
    },
    {
      mainMenu: {
        title: t('text:Billing'),
        path: ROUTES_FI.BILLING,
        hidden: isProductionEnvironment || !isAdmin,
      },
    },
    {
      mainMenu: {
        title: t('text:My_Account'),
        path: ROUTES_FI.MY_ACCOUNT,
      },
    },
  ];

  return { menuInfo };
}
