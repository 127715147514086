import type React from 'react';
import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Button, { ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import Exporter from 'components/stateless/Exporter/Exporter';
import Pagination from 'components/stateless/Pagination/Pagination';
import SearchBorder from 'components/stateless/SearchForm/SearchBorder';
import SearchDatePicker from 'components/stateless/SearchForm/SearchDatePicker';
import SearchInput from 'components/stateless/SearchForm/SearchInput';
import SearchLabel from 'components/stateless/SearchForm/SearchLabel';
import SearchSelect from 'components/stateless/SearchForm/SearchSelect';
import TableBody from 'components/stateless/Table/TableBody';
import TableBorder from 'components/stateless/Table/TableBorder';
import type { BigHeaderType } from 'components/stateless/Table/TableHeader';
import TableHeader from 'components/stateless/Table/TableHeader';
import Td from 'components/stateless/Table/Td';
import Tr from 'components/stateless/Table/Tr';
import { HeaderTitle } from 'components/stateless/Title/HeaderTitle';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import getSelectOptions from 'constants/selectOptions';
import { AUTHORITY_TYPE, ENTERPRISE_TYPE } from 'enums';
import useMounted from 'hooks/useMounted';
import usePageable from 'hooks/usePageable';
import type Pageable from 'models/Pageable';
import type { LoginHistoryVOModel } from 'models/vo/LoginHistoryVO';
import type { PDFExporterProps } from 'utils/exportFile/exportPDF.d';
import { requestSystemLoginHistoryList } from 'utils/http/api/system/login-history';
import type { SyLoginHistoryListRequest } from 'utils/http/api/system/login-history/request';
import useModal from 'utils/modal/useModal';
import { getParsedSearchParams, updateSearchParams } from 'utils/searchParams';
import type { ColumnOption, ExportSpreadSheetProps, MergedCellTypes } from 'utils/spreadSheet/types';

const EXPORT_MAX_ROW_COUNT = 1000;
const PDF_EXPORT_MAX_ROW_COUNT = 150;
const LOGIN_HISTORY_LIST_QS_KEY = 'sy-login-history-list';

const getConstants = () => {
  const mounted = useMounted();
  const modal = useModal();
  const { t } = useTranslation(['format']);

  const SEARCHED_SYSTEM_MONITOR_PLATFORM_LOGIN_HISTORY_TABLE_BIG_HEADERS: BigHeaderType[] = [
    {
      headerText: t('text:Login_Information'),
      colSpan: 7,
    },
    {
      headerText: t('text:User_Information'),
      colSpan: 4,
    },
    {
      headerText: t('text:Enterprise_Information'),
      colSpan: 3,
    },
  ];

  const SEARCHED_SYSTEM_MONITOR_PLATFORM_LOGIN_HISTORY_TABLE_HEADERS = [
    {
      headerText: t('text:Login_Date'),
      colWidths: 120,
    },
    {
      headerText: t('text:Logout_Date'),
      colWidths: 120,
    },
    {
      headerText: t('text:Status'),
      colWidths: 150,
    },
    {
      headerText: t('text:OS'),
      colWidths: 80,
    },
    {
      headerText: t('text:Browser'),
      colWidths: 70,
    },
    {
      headerText: t('text:Country_Name'),
      colWidths: 130,
    },
    {
      headerText: t('text:IP_Address'),
      colWidths: 80,
    },
    {
      headerText: t('text:User_ID'),
      colWidths: 50,
    },
    {
      headerText: t('text:Login_ID'),
      colWidths: 100,
    },
    {
      headerText: t('text:User_Name'),
      colWidths: 120,
    },
    {
      headerText: t('text:User_Authority'),
      colWidths: 80,
    },
    {
      headerText: t('text:Enterprise_ID'),
      colWidths: 80,
    },
    {
      headerText: t('text:Enterprise_Name'),
      colWidths: 80,
    },
    {
      headerText: t('text:Enterprise_Type'),
      colWidths: 80,
    },
  ];

  return {
    mounted,
    modal,
    t,
    SEARCHED_SYSTEM_MONITOR_PLATFORM_LOGIN_HISTORY_TABLE_HEADERS,
    SEARCHED_SYSTEM_MONITOR_PLATFORM_LOGIN_HISTORY_TABLE_BIG_HEADERS,
  };
};

function SystemLoginHistoryList(): JSX.Element {
  const {
    mounted,
    modal,
    t,
    SEARCHED_SYSTEM_MONITOR_PLATFORM_LOGIN_HISTORY_TABLE_HEADERS,
    SEARCHED_SYSTEM_MONITOR_PLATFORM_LOGIN_HISTORY_TABLE_BIG_HEADERS,
  } = getConstants();

  const [systemMonitorPlatformLoginHistoryPage, setSystemMonitorPlatformLoginHistoryPage] =
    useState<Pageable<LoginHistoryVOModel[]>>();

  const {
    register: systemMonitorPlatformLoginHistorySearchFormRegister,
    getValues: getSystemMonitorPlatformLoginHistorySearchFormValues,
    reset: resetSystemMonitorPlatformLoginHistorySearchForm,
    control: systemMonitorPlatformLoginHistorySearchFormControl,
  } = useForm<SyLoginHistoryListRequest>();

  const {
    pageable: searchedSystemMonitorPlatformLoginHistoryPageable,
    setPageable: setSearchedSystemMonitorPlatformLoginHistoryPageable,
  } = usePageable(LOGIN_HISTORY_LIST_QS_KEY);

  useEffect(() => {
    if (mounted) {
      fetchLoginHistoryPage(
        searchedSystemMonitorPlatformLoginHistoryPageable.currentPage,
        searchedSystemMonitorPlatformLoginHistoryPageable.sizePerPage,
        getSystemMonitorPlatformLoginHistorySearchFormValues(),
      );
    }
  }, [mounted]);

  async function fetchLoginHistoryPage(
    pageNumber: number = 1,
    rowCount: number = 10,
    data: SyLoginHistoryListRequest = {},
  ): Promise<void> {
    try {
      const systemMonitorPlatformLoginHistoryResponse = await requestSystemLoginHistoryList(pageNumber, rowCount, data);
      updateSearchParams(
        {
          ...data,
          pageNumber,
          rowCount,
        },
        LOGIN_HISTORY_LIST_QS_KEY,
      );
      ReactDOM.unstable_batchedUpdates(() => {
        setSystemMonitorPlatformLoginHistoryPage(systemMonitorPlatformLoginHistoryResponse);
        setSearchedSystemMonitorPlatformLoginHistoryPageable(systemMonitorPlatformLoginHistoryResponse);
      });
    } catch (error) {
      modal.show(error);
    }
  }

  const onClickSearch = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    e.preventDefault();
    const data = getSystemMonitorPlatformLoginHistorySearchFormValues();
    fetchLoginHistoryPage(1, searchedSystemMonitorPlatformLoginHistoryPageable.sizePerPage, data);
  };

  const searchedSystemMonitorPlatformLoginHistoryPaginate = async (pageNumber: number, rowCount: number) => {
    fetchLoginHistoryPage(pageNumber, rowCount, getParsedSearchParams(LOGIN_HISTORY_LIST_QS_KEY).formSearchData);
  };

  const getAuthorityTypeText = (authorityType: AUTHORITY_TYPE): string => {
    switch (authorityType) {
      case AUTHORITY_TYPE.ADMIN:
        return t('text:Admin');
      case AUTHORITY_TYPE.AUTHORIZER:
        return t('text:Authorizer');
      case AUTHORITY_TYPE.OPERATOR:
        return t('text:Operator');
      case AUTHORITY_TYPE.HQ_OPERATOR:
        return t('text:Admin_Operator');
      default:
        return '';
    }
  };

  const getEnterpriseTypeText = (enterpriseType: ENTERPRISE_TYPE): string => {
    switch (enterpriseType) {
      case ENTERPRISE_TYPE.AC:
        return t('text:Anchor');
      case ENTERPRISE_TYPE.DE:
        return t('text:Partner');
      case ENTERPRISE_TYPE.FI:
        return t('text:Financier');
      case ENTERPRISE_TYPE.SY:
        return t('text:System');
      default:
        return '';
    }
  };

  const renderSearchedSystemMonitorPlatformLoginHistoryTableBody = (
    content: LoginHistoryVOModel[] | undefined,
    isPdf?: boolean,
  ): JSX.Element[] | undefined => {
    return content?.map((data, index) => (
      <Tr key={index} className={isPdf ? 'virtual-table-row' : ''} style={isPdf ? { height: '50.38px' } : {}}>
        <Td data={data.createdDateTime} format="datetime" />
        <Td data={data.logoutDateTime} format="datetime" />
        <Td data={data.success ? t('text:Pass') : t('text:Fail') + `(${data.failedReason})`} />
        <Td data={data.os} />
        <Td data={data.browser} />
        <Td data={data.countryName} />
        <Td data={data.ipAddress} />
        <Td data={data.userId} />
        <Td data={data.loginId} />
        <Td data={data.userName} />
        <Td data={getAuthorityTypeText(data.authorityType)} />
        <Td data={data.enterpriseId} />
        <Td data={data.enterpriseName} />
        <Td data={getEnterpriseTypeText(data.enterpriseType)} />
      </Tr>
    ));
  };

  const onClickExport = async (e: any) => {
    e.preventDefault();

    const data = getParsedSearchParams(LOGIN_HISTORY_LIST_QS_KEY).formSearchData;

    const excelColumns: ColumnOption<LoginHistoryVOModel>[] = [
      {
        header: t('text:Login_Date'),
        key: 'createdDateTime',
        width: 25,
      },
      {
        header: t('text:Logout_Date'),
        key: 'logoutDateTime',
        width: 25,
      },
      {
        header: t('text:Status'),
        key: 'success',
        width: 30,
      },
      {
        header: t('text:OS'),
        key: 'os',
        width: 20,
      },
      {
        header: t('text:Browser'),
        key: 'browser',
        width: 20,
      },
      {
        header: t('text:Country_Name'),
        key: 'countryName',
        width: 30,
      },
      {
        header: t('text:IP_Address'),
        key: 'ipAddress',
        width: 20,
      },
      {
        header: t('text:User_ID'),
        key: 'userId',
        width: 10,
      },
      {
        header: t('text:Login_ID'),
        key: 'loginId',
        width: 20,
      },
      {
        header: t('text:User_Name'),
        key: 'userName',
        width: 25,
      },
      {
        header: t('text:User_Authority'),
        key: 'authorityType',
        width: 20,
      },
      {
        header: t('text:Enterprise_ID'),
        key: 'enterpriseId',
        width: 20,
      },
      {
        header: t('text:Enterprise_Name'),
        key: 'enterpriseName',
        width: 25,
      },
      {
        header: t('text:Enterprise_Type'),
        key: 'enterpriseType',
        width: 20,
      },
    ];
    const mergedCells: MergedCellTypes[] = [
      {
        target: 'A:G',
        rowIndex: 1,
        value: t('text:Login_Information'),
      },
      {
        target: 'H:K',
        rowIndex: 1,
        value: t('text:User_Information'),
      },
      {
        target: 'L:N',
        rowIndex: 1,
        value: t('text:Enterprise_Information'),
      },
    ];

    try {
      const [loginHistoryResponse, loginHistoryPDFResponse] = await Promise.all([
        requestSystemLoginHistoryList(0, EXPORT_MAX_ROW_COUNT, data),
        requestSystemLoginHistoryList(0, PDF_EXPORT_MAX_ROW_COUNT, data),
      ]);

      const jsonArrayData: any[] = loginHistoryResponse.content.map(item => ({
        createdDateTime: t('format:datetime', { value: item.createdDateTime, key: 'datetime' }),
        logoutDateTime: t('format:datetime', { value: item.logoutDateTime, key: 'datetime' }),
        success: item.success ? t('text:Pass') : t('text:Fail') + `(${item.failedReason})`,
        os: item.os,
        browser: item.browser,
        ipAddress: item.ipAddress,
        countryName: item.countryName,
        userId: item.userId,
        loginId: item.loginId,
        userName: item.userName,
        authorityType: getAuthorityTypeText(item.authorityType),
        enterpriseId: item.enterpriseId,
        enterpriseName: item.enterpriseName,
        enterpriseType: getEnterpriseTypeText(item.enterpriseType),
      }));

      const excelExporterProps: ExportSpreadSheetProps<any> = {
        jsonArrayData: jsonArrayData,
        columns: excelColumns,
        options: {
          mergedCells: mergedCells,
          rowHeight: [{ position: 1, height: 30 }],
        },
      };

      const pdfExporterProps: PDFExporterProps = {
        mergedTableHeaders: [SEARCHED_SYSTEM_MONITOR_PLATFORM_LOGIN_HISTORY_TABLE_BIG_HEADERS],
        tableHeaders: SEARCHED_SYSTEM_MONITOR_PLATFORM_LOGIN_HISTORY_TABLE_HEADERS,
        tableBody: renderSearchedSystemMonitorPlatformLoginHistoryTableBody(loginHistoryPDFResponse.content, true),
      };

      modal.show(<Exporter spreadSheetExporterProps={excelExporterProps} pdfExporterProps={pdfExporterProps} />, {
        title: t('text:Export_File'),
        closeBtnText: t('text:Close'),
      });
    } catch (e) {
      modal.show(e);
    }
  };

  return (
    <>
      <HeaderTitle title={t('text:Login_History')} />
      <div className="content-area">
        <SectionTitle title={t('text:Search')}>
          <Button
            variant={ButtonVariantEnum.OUTLINED}
            color={ButtonColorEnum.SECONDARY}
            onClick={() => resetSystemMonitorPlatformLoginHistorySearchForm()}
          >
            {t('text:Remove_Filter')}
          </Button>
        </SectionTitle>
        <form>
          <SearchBorder>
            <div className="row">
              <SearchLabel label={t('text:Login_Date')} />
              <SearchDatePicker
                placeholder={t('text:from')}
                name="createdDateFrom"
                control={systemMonitorPlatformLoginHistorySearchFormControl}
              />
              <SearchDatePicker
                placeholder={t('text:to')}
                name="createdDateTo"
                control={systemMonitorPlatformLoginHistorySearchFormControl}
              />
              <SearchLabel label={t('text:Logout_Date')} />
              <SearchDatePicker
                placeholder={t('text:from')}
                name="logoutDateFrom"
                control={systemMonitorPlatformLoginHistorySearchFormControl}
              />
              <SearchDatePicker
                placeholder={t('text:to')}
                name="logoutDateTo"
                control={systemMonitorPlatformLoginHistorySearchFormControl}
              />
            </div>
            <div className="row">
              <SearchLabel label={t('text:Status')} />
              <SearchSelect
                name="success"
                ref={systemMonitorPlatformLoginHistorySearchFormRegister}
                selectOptions={[
                  {
                    label: t('text:All'),
                    value: '',
                  },
                  {
                    label: t('text:Pass'),
                    value: true,
                  },
                  {
                    label: t('text:Fail'),
                    value: false,
                  },
                ]}
              />
              <SearchLabel label={t('text:OS')} />
              <SearchInput name="os" ref={systemMonitorPlatformLoginHistorySearchFormRegister} />
            </div>
            <div className="row">
              <SearchLabel label={t('text:Browser')} />
              <SearchInput name="browser" ref={systemMonitorPlatformLoginHistorySearchFormRegister} />
              <SearchLabel label={t('text:Country_Name')} />
              <SearchInput name="countryName" ref={systemMonitorPlatformLoginHistorySearchFormRegister} />
            </div>
            <div className="row">
              <SearchLabel label={t('text:IP_Address')} />
              <SearchInput name="ipAddress" ref={systemMonitorPlatformLoginHistorySearchFormRegister} />
              <SearchLabel label={t('text:User_ID')} />
              <SearchInput name="userId" ref={systemMonitorPlatformLoginHistorySearchFormRegister} />
            </div>
            <div className="row">
              <SearchLabel label={t('text:Login_ID')} />
              <SearchInput name="loginId" ref={systemMonitorPlatformLoginHistorySearchFormRegister} />
              <SearchLabel label={t('text:User_Name')} />
              <SearchInput name="userName" ref={systemMonitorPlatformLoginHistorySearchFormRegister} />
            </div>
            <div className="row">
              <SearchLabel label={t('text:User_Authority')} />
              <SearchSelect
                name="authorityType"
                ref={systemMonitorPlatformLoginHistorySearchFormRegister}
                selectOptions={getSelectOptions('AUTHORITY_TYPE', 'ALL', true)}
              />
              <SearchLabel label={t('text:Enterprise_ID')} />
              <SearchInput name="enterpriseId" ref={systemMonitorPlatformLoginHistorySearchFormRegister} />
            </div>
            <div className="row">
              <SearchLabel label={t('text:Enterprise_Name')} />
              <SearchInput name="enterpriseName" ref={systemMonitorPlatformLoginHistorySearchFormRegister} />
              <SearchLabel label={t('text:Enterprise_Type')} />
              <SearchSelect
                name="enterpriseType"
                ref={systemMonitorPlatformLoginHistorySearchFormRegister}
                selectOptions={[
                  ...getSelectOptions('ENTERPRISE_TYPE', 'ALL', true),
                  {
                    label: t('text:System'),
                    value: ENTERPRISE_TYPE.SY,
                  },
                ]}
              />
            </div>
          </SearchBorder>
          <div className="flex-center mt-4">
            <Button size={ButtonSizeEnum.LG} onClick={onClickSearch}>
              {t('text:Search')}
            </Button>
          </div>
        </form>
      </div>
      <div className="division-border" />
      <div className="content-area">
        <SectionTitle title={t('text:Result')} />
        <div className="d-flex mb-2">
          <p className="total-data me-auto">
            {t('text:Total')} : {searchedSystemMonitorPlatformLoginHistoryPageable.totalRows}
          </p>
          <Button onClick={onClickExport} variant={ButtonVariantEnum.OUTLINED}>
            {t('text:Export')}
          </Button>
        </div>
        <TableBorder>
          <TableHeader
            bigHeader={SEARCHED_SYSTEM_MONITOR_PLATFORM_LOGIN_HISTORY_TABLE_BIG_HEADERS}
            header={SEARCHED_SYSTEM_MONITOR_PLATFORM_LOGIN_HISTORY_TABLE_HEADERS}
          />
          <TableBody numOfCol={SEARCHED_SYSTEM_MONITOR_PLATFORM_LOGIN_HISTORY_TABLE_HEADERS.length}>
            {renderSearchedSystemMonitorPlatformLoginHistoryTableBody(systemMonitorPlatformLoginHistoryPage?.content)}
          </TableBody>
        </TableBorder>
        <Pagination
          pageable={searchedSystemMonitorPlatformLoginHistoryPageable}
          paginate={searchedSystemMonitorPlatformLoginHistoryPaginate}
        />
      </div>
    </>
  );
}

export default SystemLoginHistoryList;
