import { useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import dayjs from 'dayjs';
import { CheckPluginValid } from 'digital-signatures/bkav/CheckPluginValid';
import DigitalSignatureOpModal from 'digital-signatures/modals/DigitalSignatureOpModal';
import { CheckPluginGetCert } from 'digital-signatures/utils/checkPluginGetCert';
import { isEmpty } from 'lodash-es';

import UserVerificationModal from 'components/stateless/Modal/common/UserVerificationModal';
import type { UserVerificationCodeRequest } from 'components/stateless/Modal/common/UserVerificationModal';
import { ROUTES_DE } from 'constants/routes/dealer';
import type { DOCUMENT_FORM_TYPE } from 'enums';
import { AUTHORITY_TYPE, LOAN_TERM_TYPE, OTP_TYPE } from 'enums';
import useBeforeUnload from 'hooks/useBeforeUnload';
import useLocationState from 'hooks/useLocationState';
import type Pageable from 'models/Pageable';
import type { CalculateForLoanRequestVOModel } from 'models/vo/CalculateForLoanRequestVO';
import type { DealerAgreementDetailVOModel } from 'models/vo/DealerAgreementDetailVO';
import type { FactoringAttachmentVOModel } from 'models/vo/FactoringAttachmentVO';
import type { FinancierCalenderVOModel } from 'models/vo/FinancierCalenderVO';
import type { FinancierClientAuthSettingVOModel } from 'models/vo/FinancierClientAuthSettingVO';
import type { LoanAdditionalDocumentVOModel } from 'models/vo/LoanAdditionalDocumentVO';
import type { SuccessArDetailVOModel } from 'models/vo/SuccessArDetailVO';
import type { TempLoanVOModel } from 'models/vo/TempLoanVO';
import { convertToServerDateFormat, getDayTerm } from 'utils/date/date';
import { requestFinancierSettingData } from 'utils/http/api/common/financier-common-setting';
import { requestDealerClientAuthByDealerAgreementId } from 'utils/http/api/dealer/client-auth-setting';
import { requestDealerAgreementDetail } from 'utils/http/api/dealer/dealer-agreements';
import { requestDealerFinancierCalendar } from 'utils/http/api/dealer/financier-calendar';
import { requestDealerFinancierDocumentList } from 'utils/http/api/dealer/financier-document-forms';
import { requestDealerLoanAdditionalDocumentList } from 'utils/http/api/dealer/loan-additional-documents';
import {
  requestDealerArCalculateLoanRequest,
  requestDealerArLoanRegistration,
  requestDealerFactoringAttachments,
  requestDealerSuccessArsDetail,
  requestDealerTempLoanByAr,
  requestDealerTempLoanByArRegistration,
} from 'utils/http/api/dealer/success-ars';
import type {
  CreateDeTempLoanByArRequest,
  DealerSuccessArLoanRequest,
} from 'utils/http/api/dealer/success-ars/requests';
import type { purposeOfLoanListType } from 'utils/http/api/dealer/success-ars/requests';
import { showLoadingUI, unShowLoadingUI } from 'utils/loadingUIManager/loadingUIManager';
import { wrappingGetInterestRate } from 'utils/logic';
import { ModalSize, ModalType } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';
import { getSignIn } from 'utils/storage/LocalStorage';
import { requestDTOParser } from 'utils/valueManager/ValueManager';

import {
  getDigitalSignatureTransactionFiles,
  getDisabledRanges,
  getMaxDate,
  isValidTempDisbursementDate,
} from '../utils/loanApplicationUtils';

export interface PurposeFileRowTypes {
  index: number;
  accountFileName?: string;
  purposeFileName?: string;
  accountFileChanged: boolean;
  purposeFileChanged: boolean;
  bankName?: string;
  bankCode?: string;
}

export interface EtcFileRowTypes {
  id: number;
  tempLoanEtcAttachmentId?: number;
  fileName?: string;
  changed: boolean;
}

export interface DealerArApplicationStep2State {
  dealerAgreementDetail: DealerAgreementDetailVOModel;
  dealerArDetail: SuccessArDetailVOModel;
  financierHoliday: Pageable<FinancierCalenderVOModel[]>;
  documentTypes: DOCUMENT_FORM_TYPE[];
  dealerTempLoanByAr: TempLoanVOModel;
  factoringEnable: boolean;
  eSignatureEnable: boolean;
  disbursementDateDisabledRanges: Date[];
  additionalDocumentList: LoanAdditionalDocumentVOModel[];
  dealerArCalculateLoanRequest?: CalculateForLoanRequestVOModel;
  maxDate?: Date;
  minDate?: Date;
  maxLoanableAmount?: number;
  expectedInterestRate?: string;
}

const DEFAULT_REQUESTED_AMOUNT = 1;

export function useDealerArFinancingApplicationStep2State() {
  const userAuthorityType = getSignIn()?.authorityType;
  const modal = useModal();
  const history = useHistory();
  const { t } = useTranslation();

  const [locationState] = useLocationState<{ successArId: number }>(['successArId']);
  const { successArId } = locationState;

  const saveBtnClickRef = useRef(false);
  const digitalSignatureTransactionInfo = useRef<FactoringAttachmentVOModel>({} as FactoringAttachmentVOModel);

  const [dataState, setDataState] = useState<DealerArApplicationStep2State>({
    dealerAgreementDetail: {} as DealerAgreementDetailVOModel,
    dealerArDetail: {} as SuccessArDetailVOModel,
    financierHoliday: {} as Pageable<FinancierCalenderVOModel[]>,
    dealerArCalculateLoanRequest: {} as CalculateForLoanRequestVOModel,
    documentTypes: [] as DOCUMENT_FORM_TYPE[],
    dealerTempLoanByAr: {} as TempLoanVOModel,
    factoringEnable: false,
    eSignatureEnable: false,
    disbursementDateDisabledRanges: [],
    additionalDocumentList: [],
  });

  const purposeRowId = useRef(0);
  const [purposeFileRows, setPurposeFileRows] = useState<PurposeFileRowTypes[]>([
    {
      index: purposeRowId.current,
      accountFileName: undefined,
      purposeFileName: undefined,
      accountFileChanged: false,
      purposeFileChanged: false,
      bankName: undefined,
      bankCode: undefined,
    },
  ]);
  const [etcFileRows, setEtcFileRows] = useState<EtcFileRowTypes[]>([
    {
      id: 0,
      tempLoanEtcAttachmentId: undefined,
      fileName: undefined,
      changed: false,
    },
  ]);
  const [loanAgreementAttachmentChanged, setLoanAgreementAttachmentChanged] = useState(false);
  const [loanRequestAttachmentChanged, setLoanRequestAttachmentChanged] = useState(false);

  const dealerAgreementId = dataState.dealerArDetail?.dealerAgreementId!;

  const dealerApplicationStep2UseForm = useForm<DealerSuccessArLoanRequest>({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
  });

  const {
    reset,
    getValues,
    formState: { dirtyFields },
    watch,
  } = dealerApplicationStep2UseForm;

  const { disbursementDate, requestedLoanAmount } = watch();

  const activeNavigationGuard = !saveBtnClickRef.current && !isEmpty(dirtyFields);
  useBeforeUnload(activeNavigationGuard, [saveBtnClickRef.current, dirtyFields]);

  const fetchAll = async (disbursementDate: string | undefined) => {
    try {
      const documentTypeArr: DOCUMENT_FORM_TYPE[] = [];

      const fetchedArDetail = await requestDealerSuccessArsDetail(successArId);

      const [
        fetchedDealerAgreementDetail,
        fetchedFinancierDocumentList,
        dealerTempLoanByArData,
        financierCommonSettingData,
      ] = await Promise.all([
        requestDealerAgreementDetail(fetchedArDetail.dealerAgreementId),
        requestDealerFinancierDocumentList(fetchedArDetail.financierId),
        requestDealerTempLoanByAr(successArId),
        requestFinancierSettingData(fetchedArDetail.financierId),
      ]);

      const expiryDate = new Date(fetchedDealerAgreementDetail?.expiryDate);
      const settlementDate = new Date(fetchedArDetail?.settlementDate);
      const minDate = financierCommonSettingData.factoringEnable ? dayjs().add(1, 'day').toDate() : new Date();
      const maxDate = getMaxDate(expiryDate, settlementDate);

      const fetchFinancierHoliday = await requestDealerFinancierCalendar(fetchedArDetail.financierId, {
        pageNumber: 0,
        rowCount: Number(getDayTerm(convertToServerDateFormat(minDate), convertToServerDateFormat(maxDate))) + 1,
        fromDate: convertToServerDateFormat(minDate),
        toDate: convertToServerDateFormat(maxDate),
        holiday: true,
      });

      const calculatedDisbursementDate = calculateLoanDisbursementDate(
        fetchedDealerAgreementDetail.loanTermType,
        fetchedArDetail.scheduledDisbursementDate,
        disbursementDate,
      );

      const fetchedCalculateLoanRequest = await requestDealerArCalculateLoanRequest(
        successArId,
        calculatedDisbursementDate,
        DEFAULT_REQUESTED_AMOUNT, //  minLoanableAmount, maxLoanableAmount 값은 requestedAmount 파라미터와는 관련이 없으므로 임의로 디폴트값 지정
      );
      fetchedFinancierDocumentList.content.forEach(item => documentTypeArr.push(item.documentFormType));

      setDataState(prevState => ({
        ...prevState,
        dealerAgreementDetail: fetchedDealerAgreementDetail,
        dealerArDetail: fetchedArDetail,
        financierHoliday: fetchFinancierHoliday,
        maxLoanableAmount: fetchedCalculateLoanRequest.maxLoanableAmount,
        documentTypes: documentTypeArr,
        dealerTempLoanByAr: dealerTempLoanByArData,
        factoringEnable: financierCommonSettingData.factoringEnable,
        eSignatureEnable: financierCommonSettingData.eSignatureEnable,
        disbursementDateDisabledRanges: getDisabledRanges(
          fetchedArDetail.settlementDate,
          fetchFinancierHoliday.content,
        ),
        minDate,
        maxDate,
      }));

      // temp data setting
      const dealerLoanByArData = getValues();

      if (
        dealerTempLoanByArData.disbursementDate &&
        isValidTempDisbursementDate(
          dealerTempLoanByArData.disbursementDate,
          fetchedDealerAgreementDetail.expiryDate,
          fetchedArDetail.settlementDate,
          fetchFinancierHoliday.content,
        )
      ) {
        dealerLoanByArData.disbursementDate = dealerTempLoanByArData.disbursementDate;
      }

      dealerLoanByArData.invoiceAttachmentId = dealerTempLoanByArData.invoiceAttachmentId;
      if (
        fetchedCalculateLoanRequest.minLoanableAmount < dealerTempLoanByArData.requestedLoanAmount &&
        fetchedCalculateLoanRequest.maxLoanableAmount >= dealerTempLoanByArData.requestedLoanAmount
      ) {
        dealerLoanByArData.requestedLoanAmount = dealerTempLoanByArData.requestedLoanAmount;
      }

      if (dealerTempLoanByArData?.tempLoanEtcAttachmentList?.length !== 0) {
        dealerLoanByArData.loanEtcAttachmentDescriptions = dealerTempLoanByArData?.tempLoanEtcAttachmentList?.map(
          ({ description }) => description,
        );
        dealerLoanByArData.tempLoanEtcAttachmentId = dealerTempLoanByArData?.tempLoanEtcAttachmentList?.map(
          ({ tempLoanEtcAttachmentId }) => tempLoanEtcAttachmentId,
        );
      }

      if (dealerTempLoanByArData?.tempPurposeOfLoanList?.length !== 0) {
        dealerLoanByArData.purposeOfLoanList = dealerTempLoanByArData.tempPurposeOfLoanList.map(item => ({
          purposeAmount: item.purposeAmount,
          purposeType: item.purposeType,
          disbursementAccountType: item.disbursementAccountType,
          bankCodeId: item.bankCodeId,
          account: item.account,
          accountOwner: item.accountOwner,
          tempPurposeOfLoanId: item.tempPurposeOfLoanId,
        }));
      }
      reset(dealerLoanByArData);
    } catch (e) {
      modal.show(e);
    }
  };

  const fetchDealerArCalculateLoanRequest = async () => {
    const { scheduledDisbursementDate } = dataState.dealerArDetail;
    const { creditInterestRate, preferentialInterestRate, loanTermType } = dataState.dealerAgreementDetail;

    try {
      const calculatedDisbursementDate = calculateLoanDisbursementDate(
        loanTermType,
        scheduledDisbursementDate,
        disbursementDate,
      );

      const fetchedCalculateLoanRequest = await requestDealerArCalculateLoanRequest(
        successArId,
        calculatedDisbursementDate,
        requestedLoanAmount ?? 0,
      );

      const {
        showExpectedTotalInterestRate,
        hasBasisInterest,
        basisInterestBankCode,
        basisInterestRate,
        termSpreadRate,
      } = fetchedCalculateLoanRequest;

      setDataState(prevState => ({
        ...prevState,
        dealerArCalculateLoanRequest: fetchedCalculateLoanRequest,
        expectedInterestRate: wrappingGetInterestRate({
          showExpectedTotalInterestRate,
          hasBasisInterest,
          basisInterestBankCode,
          basisInterestRate,
          termSpreadRate,
          creditInterestRate,
          preferentialInterestRate,
        }),
      }));
    } catch (e) {
      modal.show(e);
    }
  };

  const fetchDealerFactoringAttachments = async (): Promise<FactoringAttachmentVOModel> => {
    return await requestDealerFactoringAttachments({
      dealerAgreementId,
      desiredDisburseDate: disbursementDate!,
      factoringAttachmentSuccessArDTO: {
        successArId,
        requestedAmount: requestedLoanAmount!,
      },
    });
  };

  const executeDigitalSignature = async () => {
    showLoadingUI();

    try {
      const { transactionNo, ...factoringDocs } = await fetchDealerFactoringAttachments();

      CheckPluginValid.CheckPluginValid((data: any) =>
        CheckPluginGetCert({
          data,
          modal,
          PDFInfos: factoringDocs,
          pdfSignCompletedCb: async (loanAgreementFileList: FileList, loanFactoringNotificationFileList: FileList) => {
            const data = getValues();
            manipulateLoanEtcAttachmentRequestData(data, 'application', etcFileRows);
            manipulateLoanRequiredDocumentAttachmentRequestData(data);

            data.loanAgreementAttachmentChanged = loanAgreementAttachmentChanged;
            data.loanRequestAttachmentChanged = loanRequestAttachmentChanged;

            data.dealerAgreementId = dealerAgreementId;

            data.loanAgreementAttachment = loanAgreementFileList;
            data.loanFactoringNotificationAttachment = loanFactoringNotificationFileList;
            data.transactionNo = transactionNo;

            try {
              requestDTOParser(data);
              await requestDealerArLoanRegistration(successArId, data);
              saveBtnClickRef.current = true;
              showFinancingRequestCompletedModal();
            } catch (e) {
              modal.show(e);
            }
          },
        }),
      );
    } catch (e) {
      unShowLoadingUI();
      modal.show(e);
    }

    return;
  };

  const factoringApplicationSubmit = async () => {
    if (userAuthorityType === AUTHORITY_TYPE.AUTHORIZER) {
      await executeDigitalSignature();
    } else {
      try {
        showLoadingUI();
        const transactionInfo = await fetchDealerFactoringAttachments();
        digitalSignatureTransactionInfo.current = transactionInfo;
        const { loanAgreementAttachment, loanFactoringNotificationAttachment } = transactionInfo;

        unShowLoadingUI();
        modal.show(
          <DigitalSignatureOpModal
            loanAgreementAttachment={loanAgreementAttachment}
            loanFactoringNotificationAttachment={loanFactoringNotificationAttachment}
          />,
          {
            modalSize: ModalSize.DIGITAL_SIGNATURE,
            modalType: ModalType.CONFIRM,
            title: t('text:Document_creation'),
            confirmBtnCb: () => {
              showLoanRequestModal();
            },
          },
        );
      } catch (e) {
        unShowLoadingUI();
        modal.show(e);

        return null;
      }
    }

    return;
  };

  const onFormSubmit = async () => {
    if (dataState.eSignatureEnable) {
      await factoringApplicationSubmit();
    } else {
      if (userAuthorityType === AUTHORITY_TYPE.OPERATOR) {
        showLoanRequestModal();

        return;
      }

      try {
        const clientAuthTypeData = await requestDealerClientAuthByDealerAgreementId(dealerAgreementId);
        if (clientAuthTypeData.otpType !== OTP_TYPE.NONE) {
          showUserVerificationModal(clientAuthTypeData);
        } else {
          showLoanRequestModal();
        }
      } catch (e) {
        modal.show(e);
      }
    }
  };

  const showFinancingRequestCompletedModal = () => {
    if (userAuthorityType === AUTHORITY_TYPE.AUTHORIZER) {
      modal.show(
        <h6>
          {t('text:The_financing_application_has_been_submitted')}
          <br />
          {t(
            'text:You_can_monitor_the_details_and_progress_of_the_financing_applied_from_the_View_Transaction>Financing_menu',
          )}
        </h6>,
        {
          modalType: ModalType.ALERT,
          title: t('text:Notice'),
          closeBtnText: t('text:OK'),
          closeBtnCb: () => history.push(ROUTES_DE.MANAGE_FINANCING.APPLICATION_LIST_STEP1),
        },
      );
    } else {
      modal.show(
        <h6>
          {t('text:The_financing_application_has_been_submitted_to_the_Authorizer')}
          <br />
          {t('text:The_application_will_be_submitted_to_the_Financier_after_the_Authorizer_s_approval')}
        </h6>,
        {
          modalType: ModalType.ALERT,
          title: t('text:Notice'),
          closeBtnText: t('text:OK'),
          closeBtnCb: () => history.push(ROUTES_DE.MANAGE_FINANCING.APPLICATION_LIST_STEP1),
        },
      );
    }
  };

  // OTP Code
  const verificationCode: UserVerificationCodeRequest = {};

  const showUserVerificationModal = (clientAuthTypeData: FinancierClientAuthSettingVOModel) => {
    modal.show(
      <UserVerificationModal
        modalId={modal.id}
        verificationCode={verificationCode}
        requestIdType="dealerAgreementId"
        requestId={dealerAgreementId}
        clientAuthSetting={clientAuthTypeData}
      />,
      {
        modalSize: ModalSize.NONE,
        modalType: ModalType.CONFIRM,
        confirmBtnCb: () => {
          if (!verificationCode) {
            modal.close(modal.id);
            modal.show(t('text:Please_check_the_verification_code_and_enter_it_below'), {
              closeBtnCb: () => {
                showUserVerificationModal(clientAuthTypeData);
              },
            });
          } else {
            showLoanRequestModal();
          }
        },
      },
    );
  };

  const requestRegisterArLoan = async () => {
    const formData = getValues(); // 제출할 폼 데이터 객체
    formData.loanAgreementAttachmentChanged = loanAgreementAttachmentChanged;
    formData.loanRequestAttachmentChanged = loanRequestAttachmentChanged;
    formData.dealerAgreementId = dealerAgreementId;

    // Additional Documents
    manipulateLoanEtcAttachmentRequestData(formData, 'application', etcFileRows);
    manipulateLoanRequiredDocumentAttachmentRequestData(formData);

    // 용처, 일반 대출인 경우에 세팅
    if (!dataState.factoringEnable) {
      manipulatePurposeOfLoanListRequestData(formData, purposeFileRows);
    }

    // 인증 정보
    if (userAuthorityType === AUTHORITY_TYPE.AUTHORIZER) {
      formData.otpCode = verificationCode.otpCode;
      formData.queryValue = verificationCode.queryValue;
    }

    // 전자 서명
    if (dataState.eSignatureEnable) {
      const { transactionNo, loanAgreementFileList, loanFactoringNotificationFileList } =
        getDigitalSignatureTransactionFiles(digitalSignatureTransactionInfo.current);

      formData.loanAgreementAttachment = loanAgreementFileList;
      formData.loanFactoringNotificationAttachment = loanFactoringNotificationFileList;
      formData.transactionNo = transactionNo;
    }

    try {
      requestDTOParser(formData);
      await requestDealerArLoanRegistration(successArId, formData);
      saveBtnClickRef.current = true;
      showFinancingRequestCompletedModal();
    } catch (e) {
      modal.show(e);
    }
  };

  const showLoanRequestModal = () => {
    modal.show(
      <>
        {userAuthorityType === AUTHORITY_TYPE.AUTHORIZER ? (
          <h6>{t('text:Would_you_like_to_submit_the_financing_application?')}</h6>
        ) : (
          <h6>{t('text:Would_you_like_to_submit_the_financing_application_to_the_Authorizer?')}</h6>
        )}
      </>,
      {
        modalType: ModalType.CONFIRM,
        title: t('text:Notice'),
        confirmBtnCb: requestRegisterArLoan,
        closeBtnText: t('text:Cancel'),
      },
    );
  };

  const showCancelFinancingModal = () => {
    modal.show(
      <h6>
        {t('text:Would_you_like_to_cancel_the_financing_application?')}
        <br />
        {t(
          'text:If_you_cancel_the_financing_application_the_contents_will_not_be_saved_and_you_will_have_to_proceed_with_the_financing_application_from_the_beginning',
        )}
      </h6>,
      {
        modalType: ModalType.CONFIRM,
        closeBtnText: t('text:Close'),
        title: t('text:Cancel_Financing_Request'),
        confirmBtnCb: () => history.push(ROUTES_DE.MANAGE_FINANCING.APPLICATION_LIST_STEP1),
      },
    );
  };

  const calculateLoanDisbursementDate = (
    loanTermType: string,
    scheduledDisbursementDate: string | undefined,
    disbursementDate: string | undefined,
  ) => {
    const todayDisburseDate = dayjs().format('YYYY-MM-DD');
    let calculateDisbursementDate: string;

    if (loanTermType === LOAN_TERM_TYPE.RANGE) {
      if (disbursementDate) {
        calculateDisbursementDate = disbursementDate;
      } else {
        calculateDisbursementDate = todayDisburseDate;
      }
    } else {
      calculateDisbursementDate = scheduledDisbursementDate!;
    }

    return calculateDisbursementDate;
  };

  const manipulateLoanRequiredDocumentAttachmentRequestData = (data: DealerSuccessArLoanRequest) => {
    // 파일을 첨부하지 않은 건은 제외
    const additionalDocumentListFormData = data.loanAdditionalDocumentAttachments;

    const isEmptyFile = (attachmentFileList: FileList): boolean => {
      const fileName = attachmentFileList?.item(0)?.name;

      return attachmentFileList.length === 0 && !fileName;
    };

    const manipulatedLoanAdditionalDocumentAttachments = additionalDocumentListFormData?.filter(
      item => !isEmptyFile(item.attachment),
    );

    if (manipulatedLoanAdditionalDocumentAttachments)
      data.loanAdditionalDocumentAttachments = manipulatedLoanAdditionalDocumentAttachments;
  };

  const manipulateLoanEtcAttachmentRequestData = (
    data: DealerSuccessArLoanRequest,
    type: 'application' | 'temp',
    etcFileRows: EtcFileRowTypes[],
  ) => {
    const loanEtcAttachmentsArr: FileList[] = [];
    const loanEtcAttachmentDescriptionsArr: string[] = [];
    const loanEtcAttachmentsChangedArr: boolean[] = [];
    const tempLoanEtcAttachmentIdArr: number[] = [];

    const emptyFile = (id: number, fileName: string | undefined) =>
      data.loanEtcAttachments?.[id]?.length === 0 && !fileName;
    const emptyDescription = (id: number) => !data?.loanEtcAttachmentDescriptions?.[id];

    etcFileRows?.forEach(item => {
      if (type === 'application' && emptyFile(item.id, item.fileName)) return;
      if (emptyFile(item.id, item.fileName) && emptyDescription(item.id)) return;
      loanEtcAttachmentsArr.push((data.loanEtcAttachments as FileList[])?.[item.id]);
      loanEtcAttachmentDescriptionsArr.push(data?.loanEtcAttachmentDescriptions?.[item.id] as string);
      tempLoanEtcAttachmentIdArr.push(data?.tempLoanEtcAttachmentId?.[item.id] as number);
      loanEtcAttachmentsChangedArr.push(item.changed);
    });

    data.tempLoanEtcAttachmentId = tempLoanEtcAttachmentIdArr;
    data.loanEtcAttachments = loanEtcAttachmentsArr;
    data.loanEtcAttachmentDescriptions = loanEtcAttachmentDescriptionsArr;
    data.loanEtcAttachmentsChanged = loanEtcAttachmentsChangedArr;
  };

  const manipulatePurposeOfLoanListRequestData = (
    data: DealerSuccessArLoanRequest,
    purposeFileRows: PurposeFileRowTypes[],
  ) => {
    const accountAttachmentsArr: FileList[] = [];
    const purposeAttachmentsArr: FileList[] = [];
    const accountAttachmentsChanged: boolean[] = [];
    const purposeAttachmentsChanged: boolean[] = [];
    const purposeOfLoanList: purposeOfLoanListType[] = [];

    const emptyAccountFile = (id: number) => (data.accountAttachments as FileList[])?.[id]?.length === 0;
    const emptyPurposeFile = (id: number) => (data.purposeAttachments as FileList[])?.[id]?.length === 0;
    const emptyAllFile = (id: number) => emptyAccountFile(id) && emptyPurposeFile(id);
    const emptyPurposeOfLoan = (id: number) => {
      const purposeOfLoan = data.purposeOfLoanList[id] as purposeOfLoanListType;
      const { tempPurposeOfLoanId, ...rest } = purposeOfLoan;
      const isEmptyPurposeOfLoan = Object.values(rest).every(item => !item);

      return isEmptyPurposeOfLoan;
    };

    purposeFileRows?.forEach(item => {
      if (emptyAllFile(item.index) && emptyPurposeOfLoan(item.index)) return;
      accountAttachmentsArr.push((data.accountAttachments as FileList[])?.[item.index]);
      purposeAttachmentsArr.push((data.purposeAttachments as FileList[])?.[item.index]);
      purposeOfLoanList.push(data.purposeOfLoanList[item.index] as purposeOfLoanListType);
      accountAttachmentsChanged.push(item.accountFileChanged);
      purposeAttachmentsChanged.push(item.purposeFileChanged);
    });

    data.accountAttachments = accountAttachmentsArr;
    data.purposeAttachments = purposeAttachmentsArr;
    data.accountAttachmentsChanged = accountAttachmentsChanged;
    data.purposeAttachmentsChanged = purposeAttachmentsChanged;
    data.purposeOfLoanList = purposeOfLoanList;
  };

  const manipulateSaveRequestData = (): CreateDeTempLoanByArRequest => {
    const dataTransfer = new DataTransfer();
    const data = getValues();

    const tempObj: Required<CreateDeTempLoanByArRequest> = {
      disbursementDate: '',
      requestedLoanAmount: 0,
      invoiceAttachmentId: 0,
      tempPurposeOfLoanList: [],
      purposeAttachments: [dataTransfer.files],
      purposeAttachmentsChanged: [false],
      accountAttachments: [dataTransfer.files],
      accountAttachmentsChanged: [false],
      loanAgreementAttachment: dataTransfer.files,
      loanAgreementAttachmentChanged: false,
      loanRequestAttachment: dataTransfer.files,
      loanRequestAttachmentChanged: false,
      tempLoanEtcAttachmentId: [0],
      loanEtcAttachmentDescriptions: [''],
      loanEtcAttachments: [dataTransfer.files],
      loanEtcAttachmentsChanged: [false],
    };

    manipulateLoanEtcAttachmentRequestData(data, 'temp', etcFileRows);

    if (!dataState.factoringEnable) {
      manipulatePurposeOfLoanListRequestData(data, purposeFileRows);
    }

    data.loanAgreementAttachmentChanged = loanAgreementAttachmentChanged;
    data.loanRequestAttachmentChanged = loanRequestAttachmentChanged;

    // CreateDeTempLoanByArRequest type에 있는 key와 value만 tempData에 저장
    let tempData: CreateDeTempLoanByArRequest = {
      ...(!dataState.factoringEnable && { tempPurposeOfLoanList: data.purposeOfLoanList }),
    };

    let key: keyof DealerSuccessArLoanRequest;
    for (key in data) {
      if (key in tempObj) {
        tempData = { ...tempData, [key]: data[key] };
      }
    }

    return tempData;
  };

  const successSaveModal = () => {
    modal.show(<h6>{t('text:The_information_has_been_saved_successfully')}</h6>, {
      modalType: ModalType.ALERT,
      title: t('text:Notice'),
      closeBtnText: t('text:OK'),
      closeBtnCb: () => history.push(ROUTES_DE.MANAGE_FINANCING.APPLICATION_LIST_STEP1),
    });
  };

  const handleClickSave = async () => {
    modal.show(
      <h6>
        {t('text:Would_you_like_to_save_the_financing_information_you_entered?')}
        <br />
        {t('text:Please_note_that_the_financing_application_will_not_be_submitted_until_you_click_the_Register_button')}
      </h6>,
      {
        modalType: ModalType.CONFIRM,
        closeBtnText: t('text:Cancel'),
        confirmBtnText: t('text:Confirm'),
        confirmBtnCb: async () => {
          try {
            const data = manipulateSaveRequestData();
            await requestDealerTempLoanByArRegistration(successArId, data);
            saveBtnClickRef.current = true;
            successSaveModal();
          } catch (e) {
            modal.show(e);
          }
        },
      },
    );
  };

  const onClickNavigationGuardConfirmBtn = async () => {
    const data = manipulateSaveRequestData();
    await requestDealerTempLoanByArRegistration(successArId, data);
    saveBtnClickRef.current = true;
  };

  const initializeExpectedInterestRate = () => {
    setDataState(prevState => ({
      ...prevState,
      dealerArCalculateLoanRequest: undefined,
      expectedInterestRate: undefined,
    }));
  };

  const fetchLoanAdditionalDocumentList = async () => {
    if (requestedLoanAmount) {
      try {
        const res = await requestDealerLoanAdditionalDocumentList(0, 1000, {
          dealerAgreementId,
          requestedLoanAmount,
          currencyType: dataState.dealerAgreementDetail.currencyType,
        });

        setDataState(prevState => ({
          ...prevState,
          additionalDocumentList: res.content,
        }));
      } catch (e) {
        modal.show(e);
      }
    }
  };

  return {
    state: dataState,
    fetchAll,
    fetchDealerArCalculateLoanRequest,
    fetchLoanAdditionalDocumentList,
    dealerApplicationStep2UseForm,
    etcFileRowsState: {
      etcFileRows,
      setEtcFileRows,
    },
    purposeFileRowsState: {
      purposeFileRows,
      setPurposeFileRows,
      purposeRowId,
    },
    loanAgreementAttachmentChangedState: {
      loanAgreementAttachmentChanged,
      setLoanAgreementAttachmentChanged,
    },
    loanRequestAttachmentChangedState: {
      loanRequestAttachmentChanged,
      setLoanRequestAttachmentChanged,
    },
    actions: {
      onFormSubmit,
      onClickNavigationGuardConfirmBtn,
      showCancelFinancingModal,
      handleClickSave,
      activeNavigationGuard,
      initializeExpectedInterestRate,
    },
  };
}
