import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Button, { ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import SearchBorder from 'components/stateless/SearchForm/SearchBorder';
import SearchEmpty from 'components/stateless/SearchForm/SearchEmpty';
import SearchInput from 'components/stateless/SearchForm/SearchInput';
import SearchLabel from 'components/stateless/SearchForm/SearchLabel';
import SearchSelect from 'components/stateless/SearchForm/SearchSelect';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import getSelectOptions from 'constants/selectOptions';
import type { CURRENCY_TYPE } from 'enums';
import { POTENTIAL_PARTNER_FINANCING_APPLICATION_STATUS } from 'enums';

import { defineBranchInfoRequest, renderBranchCodeText, renderBranchNameText } from '../logics';

import type { AcquisitionTabType } from '../../FinancierScPartnerAcquisitionListTabWrapper';

interface FinancierScPartnerAcquisitionListSearchFormProps {
  tab: AcquisitionTabType;
  onClickSearch: () => Promise<void>;
}

function FinancierScPartnerAcquisitionListSearchForm({
  tab,
  onClickSearch,
}: FinancierScPartnerAcquisitionListSearchFormProps) {
  const { register, reset } = useFormContext();
  const { t } = useTranslation(['format']);

  return (
    <div className="content-area">
      <SectionTitle title={t('text:Search')}>
        <Button
          variant={ButtonVariantEnum.OUTLINED}
          color={ButtonColorEnum.SECONDARY}
          onClick={() => {
            reset({});
          }}
        >
          {t('text:Remove_Filter')}
        </Button>
      </SectionTitle>
      <form>
        <SearchBorder>
          <div className="row">
            <SearchLabel label={t('text:Uploaded_Partner_Name')} />
            <SearchInput name="partnerName" ref={register} />
            <SearchLabel label={t('text:Uploaded_Partner_Tax_Code')} />
            <SearchInput name="partnerTaxCode" ref={register} />
          </div>
          <div className="row">
            <SearchLabel label={t('text:Anchor_Name')} />
            <SearchInput name="anchorClientName" ref={register} />
            <SearchLabel label={t('text:Anchor_Client_Code')} />
            <SearchInput name="anchorClientCode" ref={register} />
          </div>
          <div className="row">
            <SearchLabel label={t('text:Anchor_Master_Agreement_Number')} />
            <SearchInput name="anchorAgreementContractNo" ref={register} />
            <SearchLabel label={t('text:Currency')} />
            <SearchSelect
              name="currencyType"
              selectOptions={getSelectOptions<CURRENCY_TYPE>('CURRENCY_TYPE', 'ALL', true)}
              ref={register}
            />
          </div>
          <div className="row">
            <SearchLabel label={renderBranchCodeText(tab)} />
            <SearchInput name={defineBranchInfoRequest(tab, 'Code')} ref={register} />
            <SearchLabel label={renderBranchNameText(tab)} />
            <SearchInput name={defineBranchInfoRequest(tab, 'Name')} ref={register} />
          </div>
          {tab === 'submitted' && (
            <div className="row">
              <SearchLabel label={t('text:Submission_Cancellation')} />
              <SearchSelect
                name="potentialPartnerFinancingApplicationStatus"
                selectOptions={[
                  {
                    label: t('text:All'),
                    value: [
                      POTENTIAL_PARTNER_FINANCING_APPLICATION_STATUS.SUBMITTED,
                      POTENTIAL_PARTNER_FINANCING_APPLICATION_STATUS.SUBMITTED_CANCEL,
                    ],
                  },
                  {
                    label: t('text:Submitted'),
                    value: POTENTIAL_PARTNER_FINANCING_APPLICATION_STATUS.SUBMITTED,
                  },
                  {
                    label: t('text:Cancelled'),
                    value: POTENTIAL_PARTNER_FINANCING_APPLICATION_STATUS.SUBMITTED_CANCEL,
                  },
                ]}
                ref={register}
              />
              <SearchEmpty />
            </div>
          )}
        </SearchBorder>
        <div className="flex-center mt-4">
          <Button
            size={ButtonSizeEnum.LG}
            onClick={e => {
              e.preventDefault();
              onClickSearch();
            }}
          >
            {t('text:Search')}
          </Button>
        </div>
      </form>
    </div>
  );
}

export default FinancierScPartnerAcquisitionListSearchForm;
