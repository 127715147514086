export function stringToEnum<T>(enumObject: T, str: string): T[keyof T] {
  return str === null ? str : enumObject[str as keyof T];
}

export enum EXPORT_DOCUMENT_TYPE {
  EXCEL = 'EXCEL',
  CSV = 'CSV',
  PDF_A4 = 'PDF_A4',
  PDF_LETTER = 'PDF_LETTER',
}

export enum languageType {
  EN_USA = 'en_usa',
  EN_INDIA = 'en_india',
  KO = 'ko',
  VN = 'vn',
}

export enum languageTextEnum {
  EN_USA = 'EN-USA',
  EN_INDIA = 'EN-SEA',
  VN = 'VN',
}

// FE
export enum REQUEST_RESULT {
  SUCCESS = 'SUCCESS',
  FAIL = 'FAIL',
}

export enum OTP_TYPE {
  NONE = 'NONE',
  AOTP = 'AOTP',
  OTP = 'OTP',
  EMAIL = 'EMAIL',
  SMS = 'SMS',
}

export enum FINANCIER_TYPE {
  KB = 'KB',
  KBC = 'KBC',
  WOORI = 'WOORI',
  SHINHAN = 'SHINHAN',
  KEBHANA = 'KEBHANA',
  CITIBANK = 'CITIBANK',
  MANUAL = 'MANUAL',
}

export enum ANCHOR_AGREEMENT_STATUS {
  ACTIVATED = 'ACTIVATED',
  SUSPENDED = 'SUSPENDED',
}

export enum UPDATE_EVENT_TYPE {
  USER = 'USER',
  SYSTEM = 'SYSTEM',
  BATCH = 'BATCH',
}

export enum AUTHORIZER_STATUS {
  NONE = 'NONE',
  MAIL_SENT = 'MAIL_SENT',
  ACTIVATED = 'ACTIVATED',
}

export enum USER_STATUS {
  REGISTERED = 'REGISTERED',
  INVITED = 'INVITED',
  ACTIVATED = 'ACTIVATED',
  SUSPENDED = 'SUSPENDED',
}

export enum SUCCESS_INVOICE_STATUS {
  REGISTERED = 'REGISTERED',
  SETTLED = 'SETTLED',
  UNSETTLED = 'UNSETTLED',
}

export enum CURRENCY_TYPE {
  ALL = '',
  USD = 'USD',
  KRW = 'KRW',
  INR = 'INR',
  IDR = 'IDR',
  JPY = 'JPY',
  VND = 'VND',
}

export enum WAITING_INVOICE_APPROVAL_PHASE_STATUS {
  INITIATED = 'INITIATED',
  REQUESTED = 'REQUESTED',
  REGISTERED = 'REGISTERED',
  CONFIRMED = 'CONFIRMED',
  REJECTED = 'REJECTED',
  IN_PROGRESS = 'IN_PROGRESS',
}

export enum AUTHORITY_TYPE {
  ADMIN = 'ADMIN',
  AUTHORIZER = 'AUTHORIZER',
  OPERATOR = 'OPERATOR',
  HQ_OPERATOR = 'HQ_OPERATOR',
}

export enum ENTERPRISE_STATUS {
  REGISTERED = 'REGISTERED',
  ACTIVATED = 'ACTIVATED',
  SUSPENDED = 'SUSPENDED',
}

export enum ENTERPRISE_TYPE {
  SY = 'SY',
  FI = 'FI',
  AC = 'AC',
  DE = 'DE',
}

export enum EXTENSION_REQUEST_STATUS {
  REQUESTED = 'REQUESTED',
  PREAPPROVED = 'PREAPPROVED',
  APPROVED = 'APPROVED',
  RETURNED = 'RETURNED',
  REJECTED = 'REJECTED',
  EXTENDED = 'EXTENDED',
}

export enum LOAN_STATUS {
  DEALER_CREATED = 'DEALER_CREATED', // 딜러 OPERATOR 대출 신청
  DEALER_REQUESTED = 'DEALER_REQUESTED', // 딜러 AUTHORIZER 대출 신청
  DEALER_CANCELLED = 'DEALER_CANCELLED', // 대출 신청 취소
  REJECTED = 'REJECTED', // 대출 거절
  APPROVED = 'APPROVED', // 승인 완료
  DISBURSED = 'DISBURSED', // 대출 실행 완료
  OVERDUE = 'OVERDUE', // 대출 연체
  REPAID = 'REPAID', // 대출 만기상환 완료
  EXPIRATION = 'EXPIRATION', // 대출 기타 만료
  RECOURSE_ACTIVATED = 'RECOURSE_ACTIVATED', // 상환 청구권 발효
}

export enum DEALER_AGREEMENT_STATUS {
  ACTIVATED = 'ACTIVATED',
  SUSPENDED = 'SUSPENDED',
}

export enum DEALER_AGREEMENT_TYPE {
  WITH_ANCHOR = 'WITH_ANCHOR',
  WITHOUT_ANCHOR = 'WITHOUT_ANCHOR',
}

export enum LOAN_LIMIT_CHECK_TYPE {
  BOTH = 'BOTH',
  LOAN = 'LOAN',
  INVOICE = 'INVOICE',
  NONE = 'NONE',
}

export enum LOAN_TERM_TYPE {
  YEARLY = 'YEARLY',
  MONTHLY = 'MONTHLY',
  WEEKLY = 'WEEKLY',
  DAILY = 'DAILY',
  RANGE = 'RANGE',
}

export enum INTEREST_REPAYMENT_TYPE {
  EARLIER = 'EARLIER',
  LATTER = 'LATTER',
  MONTHLY = 'MONTHLY',
}

export enum BASIS_INTEREST_TYPE {
  STIME = 'STIME',
  LIBOR = 'LIBOR',
  FIXED = 'FIXED',
  CUSTOM = 'CUSTOM',
}

export enum EARLY_REPAYMENT_REQUEST_STATUS {
  REQUESTED = 'REQUESTED',
  COMPLETED = 'COMPLETED',
  EXPIRED = 'EXPIRED',
}

export enum FINANCIER_CLIENT_STATUS {
  ACTIVATED = 'ACTIVATED',
  SUSPENDED = 'SUSPENDED',
}

export enum FINANCIER_CLIENT_TYPE {
  ANCHOR = 'ANCHOR',
  DEALER = 'DEALER',
}

export enum FINANCIER_INTERFACE_TYPE {
  BRANCH = 'BRANCH',
  USER = 'USER',
  SMS = 'SMS',
  OTP_QUERY = 'OTP_QUERY',
  OTP_VALIDATION = 'OTP_VALIDATION',
  BUSINESS_DATE = 'BUSINESS_DATE',
  DEPOSIT_TRANSACTION = 'DEPOSIT_TRANSACTION',
  ENTERPRISE = 'ENTERPRISE',
  ENTERPRISE_TOTAL_LIMIT = 'ENTERPRISE_TOTAL_LIMIT',
  ANCHOR_AGREEMENT_BY_CONTRACT_NO = 'ANCHOR_AGREEMENT_BY_CONTRACT_NO',
  ANCHOR_AGREEMENT_BY_KEY_UNION = 'ANCHOR_AGREEMENT_BY_KEY_UNION',
  BUYER_AGREEMENT_BY_CONTRACT_NO = 'BUYER_AGREEMENT_BY_CONTRACT_NO',
  BUYER_AGREEMENT_BY_KEY_UNION = 'BUYER_AGREEMENT_BY_KEY_UNION',
  DEALER_AGREEMENT_BY_CONTRACT_NO = 'DEALER_AGREEMENT_BY_CONTRACT_NO',
  DEALER_AGREEMENT_BY_KEY_UNION = 'DEALER_AGREEMENT_BY_KEY_UNION',
  SUPPLIER_AGREEMENT_BY_CONTRACT_NO = 'SUPPLIER_AGREEMENT_BY_CONTRACT_NO',
  SUPPLIER_AGREEMENT_BY_KEY_UNION = 'SUPPLIER_AGREEMENT_BY_KEY_UNION',
  ANCHOR_USER_OF_ANCHOR_AGREEMENT = 'ANCHOR_USER_OF_ANCHOR_AGREEMENT',
  ANCHOR_USER_OF_BUYER_AGREEMENT = 'ANCHOR_USER_OF_BUYER_AGREEMENT',
  ANCHOR_USER_OF_DEALER_AGREEMENT = 'ANCHOR_USER_OF_DEALER_AGREEMENT',
  ANCHOR_USER_OF_SUPPLIER_AGREEMENT = 'ANCHOR_USER_OF_SUPPLIER_AGREEMENT',
  LOAN_APPROVAL = 'LOAN_APPROVAL',
  LOAN_APPROVAL_BY_AR = 'LOAN_APPROVAL_BY_AR',
  LOAN_RESULT = 'LOAN_RESULT',
  LOAN_RESULT_AR = 'LOAN_RESULT_AR',
  LOAN_EXPECTATION = 'LOAN_EXPECTATION',
  LOAN_EXPECTATION_AR = 'LOAN_EXPECTATION_AR',
  LOAN_TRANSACTION = 'LOAN_TRANSACTION',
  INVENTORY_REQUEST = 'INVENTORY_REQUEST',
  LOAN_EXTENSION_APPROVAL = 'LOAN_EXTENSION_APPROVAL',
  LOAN_EXTENSION_RESULT = 'LOAN_EXTENSION_RESULT',
  APPROVE_AR_PAYMENT_REQUEST = 'APPROVE_AR_PAYMENT_REQUEST',
  AR_PAYMENT_RESULT = 'AR_PAYMENT_RESULT',
  SUPPLIER_ACCOUNT_REGISTRATION = 'SUPPLIER_ACCOUNT_REGISTRATION',
  BASIS_INTEREST_RATE = 'BASIS_INTEREST_RATE',
  CURRENCY_EXCHANGE_RATE = 'CURRENCY_EXCHANGE_RATE',
  LOAN_REPAYMENT_AGGREGATION = 'LOAN_REPAYMENT_AGGREGATION',
  LOAN_EARLY_REPAYMENT_REQUEST = 'LOAN_EARLY_REPAYMENT_REQUEST',
  LOAN_EARLY_REPAYMENT_REQUEST_BY_AR = 'LOAN_EARLY_REPAYMENT_REQUEST_BY_AR',
  RECEIVE_LOAN_CHANGE = 'RECEIVE_LOAN_CHANGE',
  RECEIVE_AR_PAYMENT_CHANGE = 'RECEIVE_AR_PAYMENT_CHANGE',
  HEALTH_CHECK = 'HEALTH_CHECK',
  COMPANY_DIVISION = 'COMPANY_DIVISION',
  TRANSFER_REQUEST = 'TRANSFER_REQUEST',
  TRANSFER_RESULT = 'TRANSFER_RESULT',
}

export enum ATTACHMENT_TYPE {
  ERP_INVOICE_EXCEL = 'ERP_INVOICE_EXCEL',
  SCANNED_INVOICE = 'SCANNED_INVOICE',
  SCANNED_AR = 'SCANNED_AR',
  LOAN_AGREEMENT = 'LOAN_AGREEMENT',
  LOAN_REQUEST = 'LOAN_REQUEST',
  INVENTORY_REGISTRATION = 'INVENTORY_REGISTRATION',
  FINANCIER_LOGO = 'FINANCIER_LOGO',
  FINANCIER_DOCUMENT_FORM = 'FINANCIER_DOCUMENT_FORM',
  PURPOSE_OF_LOAN = 'PURPOSE_OF_LOAN',
  BENEFICIARY_ACCOUNT = 'BENEFICIARY_ACCOUNT',
  FACTORING_NOTIFICATION = 'FACTORING_NOTIFICATION',
  LOAN_ETC = 'LOAN_ETC',
}

export enum LOAN_TRANSACTION_TYPE {
  DISBURSED = 'DISBURSED',
  PRINCIPAL = 'PRINCIPAL',
  INTEREST = 'INTEREST',
  DELAY = 'DELAY',
}

export enum ROLE {
  ROLE_ANCHOR = 'ROLE_ANCHOR',
  ROLE_DEALER = 'ROLE_DEALER',
  ROLE_FINANCIER = 'ROLE_FINANCIER',
  ROLE_SYSTEM = 'ROLE_SYSTEM',
}

export enum COMMON_APPROVAL_TYPE {
  OPERATOR_REQUEST = 'OPERATOR_REQUEST',
  OPERATOR_CANCEL = 'OPERATOR_CANCEL',
  AUTHORIZER_RETURNED = 'AUTHORIZER_RETURNED',
  AUTHORIZER_APPROVED = 'AUTHORIZER_APPROVED',
}

export enum LOAN_APPROVAL_TYPE {
  OPERATOR_APPROVAL = 'OPERATOR_APPROVAL', // 은행 OPERATOR 지급 승인 요청
  OPERATOR_OVERDUE = 'OPERATOR_OVERDUE', // 은행 OPERATOR 대출 연체 요청
  OPERATOR_CLEAR_OVERDUE = 'OPERATOR_CLEAR_OVERDUE', // 은행 OPERATOR 대출 연체 해제 요청
  OPERATOR_REPAID = 'OPERATOR_REPAID', // 은행 OPERATOR 대출 상환 완료 요청
  OPERATOR_EXPIRATION = 'OPERATOR_EXPIRATION', // 은행 OPERATOR 대출 기타 만료 요청
  OPERATOR_REQUEST_REJECTED = 'OPERATOR_REQUEST_REJECTED', // 은행 OPERATOR 대출 승인 취소 요청
  AUTHORIZER_RETURNED_APPROVAL = 'AUTHORIZER_RETURNED_APPROVAL', // 은행 OPERATOR 지급 승인 요청 반려
  AUTHORIZER_RETURNED_OVERDUE = 'AUTHORIZER_RETURNED_OVERDUE', // 은행 OPERATOR 대출 연체 요청 반려
  AUTHORIZER_RETURNED_CLEAR_OVERDUE = 'AUTHORIZER_RETURNED_CLEAR_OVERDUE', // 은행 OPERATOR 대출 연체 해제 요청 반려
  AUTHORIZER_RETURNED_REPAID = 'AUTHORIZER_RETURNED_REPAID', // 은행 OPERATOR 대출 상환 완료 요청 반려
  AUTHORIZER_RETURNED_EXPIRATION = 'AUTHORIZER_RETURNED_EXPIRATION', // 은행 OPERATOR 대출 기타 만료 요청 반려
  AUTHORIZER_RETURNED_REJECTED = 'AUTHORIZER_RETURNED_REJECTED', // 은행 OPERATOR 대출 승인 취소 요청 반려
  OPERATOR_REQUEST_CANCELLATION_DISBURSEMENT = 'OPERATOR_REQUEST_CANCELLATION_DISBURSEMENT', // 은행 OPERATOR 대출 지급 취소 요청
  AUTHORIZER_RETURNED_CANCELLATION_DISBURSEMENT = 'AUTHORIZER_RETURNED_CANCELLATION_DISBURSEMENT', // 은행 OPERATOR 대출 지급 취소 요청 반려
}

export enum LOAN_OPTION {
  NONE = 'NONE',
  AUTO_REQUEST = 'AUTO_REQUEST',
  AUTO_APPROVE = 'AUTO_APPROVE',
}

export enum DAY_OF_WEEK {
  MON = 'MON',
  TUE = 'TUE',
  WED = 'WED',
  THU = 'THU',
  FRI = 'FRI',
  SAT = 'SAT',
  SUN = 'SUN',
}

export enum WAITING_INVOICE_STATUS {
  IN_PROGRESS = 'IN_PROGRESS',
  REGISTERED = 'REGISTERED',
  INVALID_SETTLEMENT_DATE = 'INVALID_SETTLEMENT_DATE',
  INVALID_OPERATION_TIME = 'INVALID_OPERATION_TIME',
  DUPLICATE_INVOICE = 'DUPLICATE_INVOICE',
  ALREADY_CONFIRMED_INVOICE = 'ALREADY_CONFIRMED_INVOICE',
  LOAN_REQUEST_FAILED = 'LOAN_REQUEST_FAILED',
  LOAN_APPROVE_FAILED = 'LOAN_APPROVE_FAILED',
  AMOUNT_EXCEED_LIMIT = 'AMOUNT_EXCEED_LIMIT',
  INVALID_INVOICE = 'INVALID_INVOICE',
  SETTLEMENT_DATE_EXCEED_AGREEMENT_EXPIRY_DATE = 'SETTLEMENT_DATE_EXCEED_AGREEMENT_EXPIRY_DATE',
  SETTLEMENT_DATE_EXCEED_MAX_SETTLEMENT_DATE = 'SETTLEMENT_DATE_EXCEED_MAX_SETTLEMENT_DATE',
  SETTLEMENT_DATE_INVOICE_TODAY_NOT_UPLOADABLE = 'SETTLEMENT_DATE_INVOICE_TODAY_NOT_UPLOADABLE',
  INVOICE_REGISTER_FAILED = 'INVOICE_REGISTER_FAILED',
  STOP_SUPPLIER = 'STOP_SUPPLIER',
  REPAYMENT_DATE_EXCEED_DEALER_AGREEMENT_EXPIRY_DATE = 'REPAYMENT_DATE_EXCEED_DEALER_AGREEMENT_EXPIRY_DATE',
  INVOICE_ALREADY_REGISTERED = 'INVOICE_ALREADY_REGISTERED',
  DUPLICATE_REFERENCE_NUMBER = 'DUPLICATE_REFERENCE_NUMBER',
  ALREADY_CONFIRMED_REFERENCE_NUMBER = 'ALREADY_CONFIRMED_REFERENCE_NUMBER',
  PAYMENT_DATE_IS_HOLIDAY = 'PAYMENT_DATE_IS_HOLIDAY',
  INVALID_BILL_OF_EXCHANGE_NO = 'INVALID_BILL_OF_EXCHANGE_NO',
}

export enum SUPPORTED_COLLATERAL_TYPE {
  AR = 'AR',
  INVOICE = 'INVOICE',
  BOTH = 'BOTH',
}

export enum WAITING_AR_APPROVAL_PHASE_STATUS {
  INITIATED = 'INITIATED', // dealer 담당자
  REQUESTED = 'REQUESTED', // dealer 책임자 확정
  REGISTERED = 'REGISTERED', // anchor, financier 담당자
  CONFIRMED = 'CONFIRMED', // anchor, financier 책임자 확정
  IN_PROGRESS = 'IN_PROGRESS',
}

export enum SUCCESS_AR_STATUS {
  REGISTERED = 'REGISTERED',
  SETTLED = 'SETTLED',
  UNSETTLED = 'UNSETTLED',
  FAILED = 'FAILED',
}

export enum WAITING_AR_STATUS {
  IN_PROGRESS = 'IN_PROGRESS',
  REGISTERED = 'REGISTERED',
  INVALID_SETTLEMENT_DATE = 'INVALID_SETTLEMENT_DATE',
  SETTLEMENT_DATE_IS_HOLIDAY = 'SETTLEMENT DATE IS HOLIDAY',
  SETTLEMENT_DATE_EXCEED_MAXIMUM_SETTLEMENT_DATE = 'SETTLEMENT DATE EXCEED MAXIMUM SETTLEMENT DATE',
  SETTLEMENT_DATE_EXCEED_ANCHOR_AGREEMENT_EXPIRY_DATE = 'SETTLEMENT_DATE_EXCEED_ANCHOR_AGREEMENT_EXPIRY_DATE',
  DUPLICATE_AR = 'DUPLICATE_AR',
  ALREADY_CONFIRMED_AR = 'ALREADY_CONFIRMED_AR',
  INVALID_ANCHOR_PARTNER = 'INVALID_ANCHOR_PARTNER',
  INVALID_ANCHOR_PARTNER_ACCOUNT = 'INVALID_ANCHOR_PARTNER_ACCOUNT',
  INVALID_DEALER_AGREEMENT_WITH_POTENTIAL_PARTNER_OPTION_OFF = 'INVALID DEALER AGREEMENT WITH POTENTIAL PARTNER OPTION OFF',
  INVALID_DEALER_AGREEMENT_SETTLEMENT_ACCOUNT = 'INVALID DEALER AGREEMENT SETTLEMENT ACCOUNT',
  SETTLEMENT_REQUEST_FAILED = 'SETTLEMENT REQUEST FAILED',
  AR_REGISTER_FAILED = 'AR REGISTER FAILED',
  INVALID_TAX_CODE = 'INVALID_TAX_CODE',
}

export enum COLLATERAL_TYPE {
  AR = 'AR',
  INVOICE = 'INVOICE',
}

export enum PARTNER_STATUS {
  REGISTERED = 'REGISTERED',
  DUPLICATE_TAX_CODE = 'DUPLICATE_TAX_CODE',
  ALREADY_REGISTERED_ACCOUNT = 'ALREADY_REGISTERED_ACCOUNT',
  INVALID_BANK_CODE = 'INVALID_BANK_CODE',
  ON_APPROVAL_PROGRESS_ANCHOR_PARTNER = 'ON_APPROVAL_PROGRESS_ANCHOR_PARTNER',
  INVALID_APPROVE_REQUEST = 'INVALID_APPROVE_REQUEST',
  FAILED = 'FAILED',
  ANCHOR_PARTNER_INTERFACE_REQUEST_FAILED = 'ANCHOR_PARTNER_INTERFACE_REQUEST_FAILED',
}

export enum BANK_CODE_APPROVAL_TYPE {
  FINANCIER_REQUEST = 'FINANCIER_REQUEST',
  FINANCIER_CANCEL = 'FINANCIER_CANCEL',
  SYSTEM_REJECTED = 'SYSTEM_REJECTED',
  SYSTEM_APPROVED = 'SYSTEM_APPROVED',
}

export enum DOCUMENT_FORM_TYPE {
  DEALER_FINANCING_LOAN_AGREEMENT = 'DEALER_FINANCING_LOAN_AGREEMENT',
  DEALER_FINANCING_LOAN_REQUEST = 'DEALER_FINANCING_LOAN_REQUEST',
  VENDOR_FINANCING_LOAN_AGREEMENT = 'VENDOR_FINANCING_LOAN_AGREEMENT',
  VENDOR_FINANCING_LOAN_REQUEST = 'VENDOR_FINANCING_LOAN_REQUEST',
  ADDITIONAL_DOCUMENT = 'ADDITIONAL_DOCUMENT',
  BULK_TRANSFER = 'BULK_TRANSFER',
  REMITTANCE = 'REMITTANCE',
}

export enum PURPOSE_TYPE {
  BUY_RAW_MATERIALS = 'BUY_RAW_MATERIALS',
  LABOR_COST = 'LABOR_COST',
  UTILITY_COST = 'UTILITY_COST',
  TAXES_AND_FEES = 'TAXES_AND_FEES',
  OTHER_COST = 'OTHER_COST',
}

export enum DISBURSEMENT_ACCOUNT_TYPE {
  BENEFICIARY = 'BENEFICIARY',
  DESIGNATED = 'DESIGNATED',
}

export enum SCHEDULE_EVENT_TYPE {
  ANCHOR_AGREEMENT_EXPIRY = 'ANCHOR_AGREEMENT_EXPIRY',
  PARTNER_AGREEMENT_EXPIRY = 'PARTNER_AGREEMENT_EXPIRY',
  LOAN_REPAYMENT = 'LOAN_REPAYMENT',
  AP_SETTLEMENT = 'AP_SETTLEMENT',
}

// 백엔드와 동일하게 맞추기 위해 주석 처리
export enum APPROVAL_EVENT_TYPE {
  LOAN_REQUEST_TO_FI = 'LOAN_REQUEST_TO_FI',
  RETURNED_LOAN_APPROVAL_REQUEST_BY_OP = 'RETURNED_LOAN_APPROVAL_REQUEST_BY_OP',
  LOAN_REQUEST_TO_FI_BY_AU = 'LOAN_REQUEST_TO_FI_BY_AU',
  EARLY_PAYMENT_REQUEST = 'EARLY_PAYMENT_REQUEST',
  LOAN_EXTENSION_REQUEST = 'LOAN_EXTENSION_REQUEST',
  RETURNED_EXTENSION_APPROVAL_REQUEST_BY_OP = 'RETURNED_EXTENSION_APPROVAL_REQUEST_BY_OP',
  ANCHOR_AGREEMENT_REQUEST = 'ANCHOR_AGREEMENT_REQUEST',
  RETURNED_ANCHOR_AGREEMENT_REQUEST_CANCEL = 'RETURNED_ANCHOR_AGREEMENT_REQUEST_CANCEL',
  PARTNER_AGREEMENT_REQUEST = 'PARTNER_AGREEMENT_REQUEST',
  RETURNED_PARTNER_AGREEMENT_REQUEST_CANCEL = 'RETURNED_PARTNER_AGREEMENT_REQUEST_CANCEL',
  LOAN_APPROVAL_REQUEST = 'LOAN_APPROVAL_REQUEST',
  LOAN_APPROVAL_REQUEST_RETURN = 'LOAN_APPROVAL_REQUEST_RETURN',
  LOAN_EXTENSION_APPROVAL_REQUEST = 'LOAN_EXTENSION_APPROVAL_REQUEST',
  INVOICE_CONFIRMATION_REQUEST_BY_FI = 'INVOICE_CONFIRMATION_REQUEST_BY_FI',
  INVOICE_CONFIRMATION_REQUEST_BY_AC = 'INVOICE_CONFIRMATION_REQUEST_BY_AC',
  AP_CONFIRMATION_REQUEST = 'AP_CONFIRMATION_REQUEST',
  AP_CONFIRMATION_REQUEST_BY_FI = 'AP_CONFIRMATION_REQUEST_BY_FI',
  INVOICE_REGISTRATION_REQUEST = 'INVOICE_REGISTRATION_REQUEST',
  AP_REGISTRATION_REQUEST = 'AP_REGISTRATION_REQUEST',
  LOAN_INTERNAL_REQUEST = 'LOAN_INTERNAL_REQUEST',
  INVOICE_APPROVAL_REQUEST = 'INVOICE_APPROVAL_REQUEST',
  AP_APPROVAL_REQUEST = 'AP_APPROVAL_REQUEST',
  AP_REGISTRATION_REQUEST_BY_AU = 'AP_REGISTRATION_REQUEST_BY_AU',
  AP_REGISTRATION_REQUEST_TO_FI = 'AP_REGISTRATION_REQUEST_TO_FI ',
  MULTIPLE_LOAN_INTERNAL_REQUEST = 'MULTIPLE_LOAN_INTERNAL_REQUEST',
  MULTIPLE_LOAN_REQUEST_TO_FI = 'MULTIPLE_LOAN_REQUEST_TO_FI',
  MULTIPLE_LOAN_APPROVAL_REQUEST = 'MULTIPLE_LOAN_APPROVAL_REQUEST',
  MULTIPLE_LOAN_APPROVAL_REQUEST_RETURN = 'MULTIPLE_LOAN_APPROVAL_REQUEST_RETURN',

  // 파트너 Notification 예상 enum, 추후 변경될 여지 있음
  LOAN_APPROVE = 'LOAN_APPROVE',
  LOAN_REJECT = 'LOAN_REJECT',
  EARLY_PAYMENT_COMPLETE = 'EARLY_PAYMENT_COMPLETE',
  EARLY_PAYMENT_EXPIRE = 'EARLY_PAYMENT_EXPIRE',
  LOAN_EXTENSION_EXTEND = 'LOAN_EXTENSION_EXTEND',
  LOAN_EXTENSION_REJECT = 'LOAN_EXTENSION_REJECT',
  AP_REGISTRATION_CONFIRM = 'AP_REGISTRATION_CONFIRM',
  AP_REGISTRATION_REJECT = 'AP_REGISTRATION_REJECT',
  INVOICE_REGISTRATION_REQUEST_BY_AU = 'INVOICE_REGISTRATION_REQUEST_BY_AU',
  INVOICE_REGISTRATION_CONFIRM = 'INVOICE_REGISTRATION_CONFIRM',
  INVOICE_REGISTRATION_REJECT = 'INVOICE_REGISTRATION_REJECT',
  INVOICE_REGISTRATION_REJECT_BY_AC = 'INVOICE_REGISTRATION_REJECT_BY_AC',
  INVOICE_REGISTRATION_REJECT_BY_FI = 'INVOICE_REGISTRATION_REJECT_BY_FI',
  AP_REGISTRATION_BY_AC_ADMIN = 'AP_REGISTRATION_BY_AC_ADMIN',
  APPROVED_LOAN_REJECTION_REQUEST = 'APPROVED_LOAN_REJECTION_REQUEST', // FI OP -> FI AUTH
  APPROVED_LOAN_REJECTION_REQUEST_RETURN = 'APPROVED_LOAN_REJECTION_REQUEST_RETURN', // FI AUTH -> FI OP
}

export enum DEALER_IDENTIFIER_TYPE {
  DEALER_CODE_BY_ANCHOR = 'DEALER_CODE_BY_ANCHOR',
  TAX_CODE = 'TAX_CODE',
}

export enum FINANCIER_ALERT_TYPE {
  INVOICE_REGISTRATION_BY_PA_OP = 'INVOICE_REGISTRATION_BY_PA_OP',
  INVOICE_REGISTRATION_BY_PA_AU = 'INVOICE_REGISTRATION_BY_PA_AU',
  INVOICE_REGISTRATION_BY_AC_OP = 'INVOICE_REGISTRATION_BY_AC_OP',
  INVOICE_REGISTRATION_BY_AC_AU = 'INVOICE_REGISTRATION_BY_AC_AU',
  INVOICE_REGISTRATION_BY_FI_OP = 'INVOICE_REGISTRATION_BY_FI_OP',
  INVOICE_REGISTRED = 'INVOICE_REGISTRED',
  AR_REGISTRATION_BY_PA_OP = 'AR_REGISTRATION_BY_PA_OP',
  AR_REGISTRATION_BY_PA_AU = 'AR_REGISTRATION_BY_PA_AU',
  AR_REGISTRATION_BY_AC_OP = 'AR_REGISTRATION_BY_AC_OP',
  AR_REGISTRATION_BY_FI_OP = 'AR_REGISTRATION_BY_FI_OP',
  AR_REGISTERED = 'AR_REGISTERED',
  AR_REGISTERED_TO_PP = 'AR_REGISTERED_TO_PP',
  INVOICE_LOAN_CREATED = 'INVOICE_LOAN_CREATED',
  INVOICE_LOAN_REQUESTED = 'INVOICE_LOAN_REQUESTED',
  INVOICE_LOAN_APPROVED_BY_FI_OP = 'INVOICE_LOAN_APPROVED_BY_FI_OP',
  INVOICE_LOAN_APPROVED_BY_FI_AU = 'INVOICE_LOAN_APPROVED_BY_FI_AU',
  INVOICE_LOAN_DISBURSED = 'INVOICE_LOAN_DISBURSED',
  INVOICE_LOAN_REJECTED = 'INVOICE_LOAN_REJECTED',
  INVOICE_LOAN_REPAYMENT_DATE = 'INVOICE_LOAN_REPAYMENT_DATE',
  AR_LOAN_CREATED = 'AR_LOAN_CREATED',
  AR_LOAN_REQUESTED = 'AR_LOAN_REQUESTED',
  AR_LOAN_APPROVED_BY_FI_OP = 'AR_LOAN_APPROVED_BY_FI_OP',
  AR_LOAN_APPROVED_BY_FI_AU = 'AR_LOAN_APPROVED_BY_FI_AU',
  AR_LOAN_DISBURSED = 'AR_LOAN_DISBURSED',
  AR_LOAN_REJECTED = 'AR_LOAN_REJECTED',
  AR_LOAN_REPAYMENT_DATE = 'AR_LOAN_REPAYMENT_DATE',
  INVOICE_EARLY_LOAN_REPAYMENT_REQUEST = 'INVOICE_EARLY_LOAN_REPAYMENT_REQUEST',
  AR_EARLY_LOAN_REPAYMENT_REQUEST = 'AR_EARLY_LOAN_REPAYMENT_REQUEST',
  ANCHOR_AGREEMENT_MODIFICATION = 'ANCHOR_AGREEMENT_MODIFICATION',
  ANCHOR_AGREEMENT_EXPIRATION = 'ANCHOR_AGREEMENT_EXPIRATION',
  DEALER_AGREEMENT_MODIFICATION = 'DEALER_AGREEMENT_MODIFICATION',
  DEALER_AGREEMENT_REGISTRATION = 'DEALER_AGREEMENT_REGISTRATION',
  DEALER_AGREEMENT_EXPIRATION = 'DEALER_AGREEMENT_EXPIRATION',
  AR_UPCOMING_SETTLEMENT_DATE = 'AR_UPCOMING_SETTLEMENT_DATE',
}

export enum REPORT_TYPE {
  UTILIZATION_REPORT = 'UTILIZATION_REPORT',
  REPAYMENT_REPORT = 'REPAYMENT_REPORT',
}

export enum REPORT_RECEIVER_TYPE {
  ANCHOR_USER = 'ANCHOR_USER',
  ANCHOR_REPORT_RECEIVER = 'ANCHOR_REPORT_RECEIVER',
}

export enum EXECUTION_STATUS {
  NONE = 'NONE',
  REGISTERED = 'REGISTERED',
  RUNNING = 'RUNNING',
  FINISHED = 'FINISHED',
  FAILED = 'FAILED',
}

export enum POTENTIAL_PARTNER_FINANCING_APPLICATION_STATUS {
  SUBMITTED_CANCEL = 'SUBMITTED_CANCEL',
  SUBMITTED = 'SUBMITTED',
  IN_PROGRESS = 'IN_PROGRESS',
  REJECTED = 'REJECTED',
  COMPLETED = 'COMPLETED',
}

export enum POTENTIAL_PARTNER_FINANCING_APPLICATION_DOCUMENT_STATUS {
  SUBMITTED = 'SUBMITTED',
  NEED_RESUBMISSION = 'NEED_RESUBMISSION',
  CONFIRMED = 'CONFIRMED',
}

export enum POTENTIAL_PARTNER_FINANCING_DASHBOARD_TYPE {
  POTENTIAL_PARTNER_APPLICATION_REQUEST = 'POTENTIAL_PARTNER_APPLICATION_REQUEST',
  POTENTIAL_PARTNER_DOCUMENT_SUBMISSION = 'POTENTIAL_PARTNER_DOCUMENT_SUBMISSION',
}

export enum EMAIL_TYPE {
  SY_INVITE_FI_AD = 'SY_INVITE_FI_AD',
  FI_INVITE_FI_AU = 'FI_INVITE_FI_AU',
  FI_INVITE_AC_AD = 'FI_INVITE_AC_AD',
  FI_INVITE_AC_AU = 'FI_INVITE_AC_AU',
  FI_INVITE_AC_OP = 'FI_INVITE_AC_OP',
  FI_INVITE_DE_AU = 'FI_INVITE_DE_AU',
  FI_INVITE_FI_OP = 'FI_INVITE_FI_OP',
  DE_INVITE_DE_OP = 'DE_INVITE_DE_OP',
  AC_INVITE_AC_AO = 'AC_INVITE_AC_AO',
  FIND_ID = 'FIND_ID',
  FIND_PWD = 'FIND_PWD',
  PWD_CHANGED = 'PWD_CHANGED',
  SEND_OTP = 'SEND_OTP',
  MODIFY_ANCHOR_AGREEMENT = 'MODIFY_ANCHOR_AGREEMENT',
  MODIFY_PARTNER_AGREEMENT = 'MODIFY_PARTNER_AGREEMENT',
  UPLOAD_INVOICE = 'UPLOAD_INVOICE',
  APPROVE_INVOICE = 'APPROVE_INVOICE',
  UPLOAD_AR = 'UPLOAD_AR',
  APPROVE_AR = 'APPROVE_AR',
  APPROVE_AR_TO_REPRESENTATIVE = 'APPROVE_AR_TO_REPRESENTATIVE',
  LOAN_REQUESTED = 'LOAN_REQUESTED',
  LOAN_APPROVED = 'LOAN_APPROVED',
  FI_LOAN_EXECUTED = 'FI_LOAN_EXECUTED',
  FI_AC_EARLY_LOAN_REPAYMENT_REQUESTED = 'FI_AC_EARLY_LOAN_REPAYMENT_REQUESTED',
  ANCHOR_REPORT_UTILIZATION = 'ANCHOR_REPORT_UTILIZATION',
  ANCHOR_REPORT_REPAYMENT = 'ANCHOR_REPORT_REPAYMENT',
  AGREEMENT_INFO_MISMATCH = 'AGREEMENT_INFO_MISMATCH',
  DEALER_AGREEMENT_REGISTRATION = 'DEALER_AGREEMENT_REGISTRATION',
}

export enum DAY_COUNT_CONVENTION {
  THREE_HUNDRED_SIXTY = 'THREE_HUNDRED_SIXTY',
  THREE_HUNDRED_SIXTY_FIVE = 'THREE_HUNDRED_SIXTY_FIVE',
  ACTUAL = 'ACTUAL',
}

export enum INVOICE_NUMBER_DUPLICATION_POLICY {
  ALWAYS = 'ALWAYS',
  FISCAL_YEAR = 'FISCAL_YEAR',
}

export enum NOTIFICATION_TYPE {
  LOAN_DEALER_CREATED = 'LOAN_DEALER_CREATED', // PA
  LOAN_DEALER_CANCELLED = 'LOAN_DEALER_CANCELLED', // PA
  LOAN_DEALER_REQUESTED = 'LOAN_DEALER_REQUESTED', // PA, FI
  LOAN_REJECTED = 'LOAN_REJECTED', // PA, FI
  LOAN_APPROVAL_REQUEST = 'LOAN_APPROVAL_REQUEST', // FI
  LOAN_APPROVAL_REQUEST_CANCEL = 'LOAN_APPROVAL_REQUEST_CANCEL', // FI
  LOAN_APPROVAL_REQUEST_RETURN = 'LOAN_APPROVAL_REQUEST_RETURN', // FI
  LOAN_APPROVED = 'LOAN_APPROVED', // PA, FI
  LOAN_REJECTION_REQUEST = 'LOAN_REJECTION_REQUEST', // FI
  LOAN_REJECTION_REQUEST_CANCEL = 'LOAN_REJECTION_REQUEST_CANCEL', // FI
  LOAN_REJECTION_REQUEST_RETURNED = 'LOAN_REJECTION_REQUEST_RETURNED', // FI
  LOAN_REJECTION_REQUEST_CONFIRM = 'LOAN_REJECTION_REQUEST_CONFIRM', // PA, FI
  EARLY_PAYMENT_REQUESTED = 'EARLY_PAYMENT_REQUESTED', // FI
  EARLY_PAYMENT_COMPLETED = 'EARLY_PAYMENT_COMPLETED', // PA, FI
  EARLY_PAYMENT_EXPIRED = 'EARLY_PAYMENT_EXPIRED', // PA, FI
  LOAN_EXTENSION_REQUESTED = 'LOAN_EXTENSION_REQUESTED', // FI
  LOAN_EXTENSION_REJECTED = 'LOAN_EXTENSION_REJECTED', // PA, FI
  LOAN_EXTENSION_PREAPPROVED = 'LOAN_EXTENSION_PREAPPROVED', // FI
  LOAN_EXTENSION_RETURNED = 'LOAN_EXTENSION_RETURNED', // FI
  LOAN_EXTENSION_APPROVED = 'LOAN_EXTENSION_APPROVED', // PA, FI
  ANCHOR_AGREEMENT_REQUEST = 'ANCHOR_AGREEMENT_REQUEST', // FI
  ANCHOR_AGREEMENT_REQUEST_CANCEL = 'ANCHOR_AGREEMENT_REQUEST_CANCEL', // FI
  ANCHOR_AGREEMENT_REQUEST_RETURNED = 'ANCHOR_AGREEMENT_REQUEST_RETURNED', // FI
  ANCHOR_AGREEMENT_REQUEST_APPROVED = 'ANCHOR_AGREEMENT_REQUEST_APPROVED', // FI
  PARTNER_AGREEMENT_REQUEST = 'PARTNER_AGREEMENT_REQUEST', // FI
  PARTNER_AGREEMENT_REQUEST_CANCEL = 'PARTNER_AGREEMENT_REQUEST_CANCEL', // FI
  PARTNER_AGREEMENT_REQUEST_RETURNED = 'PARTNER_AGREEMENT_REQUEST_RETURNED', // FI
  PARTNER_AGREEMENT_REQUEST_APPROVED = 'PARTNER_AGREEMENT_REQUEST_APPROVED', // FI
  AR_APPROVAL_REQUEST = 'AR_APPROVAL_REQUEST', // PA
  AR_APPROVAL_REJECT = 'AR_APPROVAL_REJECT', // PA
  AR_REGISTRATION_REQUEST = 'AR_REGISTRATION_REQUEST', // PA, AC
  AR_REGISTRATION_REQUEST_TO_FI = 'AR_REGISTRATION_REQUEST_TO_FI', // PA, FI
  AR_REGISTRATION_REJECT = 'AR_REGISTRATION_REJECT', // PA, AC
  AR_REGISTRATION_REJECT_BY_FI = 'AR_REGISTRATION_REJECT_BY_FI', // PA, FI
  AR_CONFIRMATION_REQUEST = 'AR_CONFIRMATION_REQUEST', // AC
  AR_CONFIRMATION_REQUEST_BY_FI = 'AR_CONFIRMATION_REQUEST_BY_FI', // FI
  AR_CONFIRMATION_REJECT = 'AR_CONFIRMATION_REJECT', // AC
  AR_CONFIRMATION_REJECT_BY_FI = 'AR_CONFIRMATION_REJECT_BY_FI', // PA, FI
  AR_CONFIRMED = 'AR_CONFIRMED', // PA, AC
  AR_CONFIRMED_BY_FI = 'AR_CONFIRMED_BY_FI', // PA, FI
  INVOICE_APPROVAL_REQUEST = 'INVOICE_APPROVAL_REQUEST', // PA
  INVOICE_APPROVAL_REJECT = 'INVOICE_APPROVAL_REJECT', // PA
  INVOICE_REGISTRATION_REQUEST = 'INVOICE_REGISTRATION_REQUEST', // PA, AC, FI
  INVOICE_CONFIRMATION_REQUEST_BY_AC = 'INVOICE_CONFIRMATION_REQUEST_BY_AC', // AC
  INVOICE_REGISTRATION_REJECT_BY_AC = 'INVOICE_REGISTRATION_REJECT_BY_AC', // PA, AC
  INVOICE_CONFIRMATION_REJECT_BY_AC = 'INVOICE_CONFIRMATION_REJECT_BY_AC', // PA, AC
  INVOICE_CONFIRMATION_CANCEL_BY_AC = 'INVOICE_CONFIRMATION_CANCEL_BY_AC', // PA, AC
  INVOICE_CONFIRMED_BY_AC = 'INVOICE_CONFIRMED_BY_AC', // PA, AC
  INVOICE_REGISTRATION_REJECT_BY_FI = 'INVOICE_REGISTRATION_REJECT_BY_FI', // PA, FI
  INVOICE_CONFIRMATION_REQUEST_BY_FI = 'INVOICE_CONFIRMATION_REQUEST_BY_FI', // FI
  INVOICE_CONFIRMATION_REJECT_BY_FI = 'INVOICE_CONFIRMATION_REJECT_BY_FI', // PA, FI
  INVOICE_CONFIRMED_BY_FI = 'INVOICE_CONFIRMED_BY_FI', // PA, FI
  AUTO_LOAN_REQUESTED = 'AUTO_LOAN_REQUESTED', // PA, FI
  AUTO_LOAN_APPROVED = 'AUTO_LOAN_APPROVED', // PA, FI
  MULTIPLE_LOAN_DEALER_CREATED = 'MULTIPLE_LOAN_DEALER_CREATED', // PA
  MULTIPLE_LOAN_DEALER_REQUESTED = 'MULTIPLE_LOAN_DEALER_REQUESTED', // PA, FI
  MULTIPLE_LOAN_DEALER_CANCELLED = 'MULTIPLE_LOAN_DEALER_CANCELLED', // PA
  MULTIPLE_LOAN_APPROVAL_REQUEST = 'MULTIPLE_LOAN_APPROVAL_REQUEST', // FI
  MULTIPLE_LOAN_REJECTED = 'MULTIPLE_LOAN_REJECTED', // PA, FI
  MULTIPLE_LOAN_APPROVAL_REQUEST_CANCEL = 'MULTIPLE_LOAN_APPROVAL_REQUEST_CANCEL', //FI
  MULTIPLE_LOAN_APPROVAL_REQUEST_RETURN = 'MULTIPLE_LOAN_APPROVAL_REQUEST_RETURN', //FI
  MULTIPLE_LOAN_APPROVED = 'MULTIPLE_LOAN_APPROVED', // PA, FI
}

export enum FINANCIAL_SETTING_TYPE {
  DEFAULT = 'DEFAULT',
  ANCHOR = 'ANCHOR',
  PARTNER = 'PARTNER',
}

export enum AR_COMMISSION_STATUS {
  CREATED = 'CREATED',
  PAID = 'PAID',
  WAIVED = 'WAIVED',
}

export enum AR_COMMISSION_CREATE_PHASE {
  AR_CREATED = 'AR_CREATED',
  LOAN_DISBURSED = 'LOAN_DISBURSED',
}

export enum MULTIPLE_LOAN_REQUEST_STATUS {
  CREATED = 'CREATED',
  APPLIED = 'APPLIED',
  REJECTED = 'REJECTED',
  CANCELLED = 'CANCELLED',
  APPROVED = 'APPROVED',
}

export enum EMAIL_SENT_STATUS {
  SUCCESS = 'SUCCESS',
  IN_PROGRESS = 'IN_PROGRESS',
  FAIL = 'FAIL',
}

export enum AWS_EMAIL_EVENT_TYPE {
  BOUNCE = 'BOUNCE',
  COMPLAINT = 'COMPLAINT',
  DELIVERY = 'DELIVERY',
  DELIVERY_DELAY = 'DELIVERY_DELAY',
  REJECT = 'REJECT',
  MAILBOX_FULL = 'MAILBOX_FULL',
  MESSAGE_TOO_LARGE = 'MESSAGE_TOO_LARGE',
  //  FRONT
  NETWORK_ERROR = 'NETWORK_ERROR',
}

export enum FACTORING_NOTICE_CONFIRMATION_STATUS {
  WAITING = 'WAITING',
  CONFIRMED = 'CONFIRMED',
}

export enum DOCUMENT_CONDITION_TYPE {
  SINGLE_AMOUNT = 'SINGLE_AMOUNT',
  ACCUMULATE_AMOUNT = 'ACCUMULATE_AMOUNT',
}

export enum DOCUMENT_CONDITION_AMOUNT_RANGE {
  GT = 'GT',
  GOE = 'GOE',
}

export enum COST_OF_FUND_TYPE {
  FIXED = 'FIXED',
  PRINCIPAL = 'PRINCIPAL',
  BALANCE = 'BALANCE',
}

export enum ANCHOR_DEALER_STATUS {
  REGISTERED = 'REGISTERED',
  ALREADY_REGISTERED_ANCHOR_DEALER = 'ALREADY_REGISTERED_ANCHOR_DEALER',
  ON_APPROVAL_PROGRESS_ANCHOR_DEALER = 'ON_APPROVAL_PROGRESS_ANCHOR_DEALER',
}
