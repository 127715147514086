import type React from 'react';
import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import Button, { ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import Exporter from 'components/stateless/Exporter/Exporter';
import GuideMessage from 'components/stateless/GuideMessage/GuideMessage';
import Pagination from 'components/stateless/Pagination/Pagination';
import SearchBorder from 'components/stateless/SearchForm/SearchBorder';
import SearchDatePicker from 'components/stateless/SearchForm/SearchDatePicker';
import SearchInput from 'components/stateless/SearchForm/SearchInput';
import SearchLabel from 'components/stateless/SearchForm/SearchLabel';
import SearchSelect from 'components/stateless/SearchForm/SearchSelect';
import TableBody from 'components/stateless/Table/TableBody';
import TableBorder from 'components/stateless/Table/TableBorder';
import type { HeaderType } from 'components/stateless/Table/TableHeader';
import TableHeader from 'components/stateless/Table/TableHeader';
import Td from 'components/stateless/Table/Td';
import Tr from 'components/stateless/Table/Tr';
import { HeaderTitle } from 'components/stateless/Title/HeaderTitle';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import getSelectOptions from 'constants/selectOptions';
import type { CURRENCY_TYPE } from 'enums';
import useMounted from 'hooks/useMounted';
import usePageable from 'hooks/usePageable';
import type Pageable from 'models/Pageable';
import type { DealerAgreementRepaidAmountVOModel } from 'models/vo/DealerAgreementRepaidAmountVO';
import type { PDFExporterProps } from 'utils/exportFile/exportPDF.d';
import { setFormValues } from 'utils/form/setFormValues';
import { requestAnchorDealerAgreementRepaidAmountsList } from 'utils/http/api/anchor/dealer-agreement-repaid-amounts';
import type { AnchorDealerAgreementRepaidAmountsListRequest } from 'utils/http/api/anchor/dealer-agreement-repaid-amounts/requests';
import useModal from 'utils/modal/useModal';
import { getParsedSearchParams, updateSearchParams } from 'utils/searchParams';
import type { ColumnOption, ExportSpreadSheetProps } from 'utils/spreadSheet/types';
import { tableValueManage } from 'utils/valueManager/ValueManager';

const getConstants = () => {
  const { t } = useTranslation(['format']);

  const ANCHOR_INTEREST_PAYMENT_TOTAL_DATA_COUNT = 1000;
  const PDF_EXPORT_MAX_ROW_COUNT = 150;
  const AC_INTEREST_PAYMENT_LIST_QS_KEY = 'ac-interest-payment-list';

  const ANCHOR_INTEREST_PAYMENT_SEARCH_RESULT_TABLE_HEADERS: HeaderType[] = [
    { headerText: t('text:Partner_Name'), className: 'text-left', colWidths: 120 },
    { headerText: t('text:Partner_Code_of_Anchor'), className: 'text-left', colWidths: 120 },
    { headerText: t('text:Partner_Master_Agreement_Number'), className: 'text-left', colWidths: 180 },
    { headerText: t('text:Associated_Anchor_Master_Agreement_Number'), className: 'text-left', colWidths: 180 },
    { headerText: t('text:Currency'), className: 'text-left', colWidths: 100 },
    { headerText: t('text:Total_Principal'), className: 'text-left', colWidths: 120 },
    { headerText: t('text:Normal_Interest'), className: 'text-left', colWidths: 120 },
    { headerText: t('text:Delinquent_Interest'), className: 'text-left', colWidths: 120 },
    { headerText: t('text:Total_Interest'), className: 'text-left', colWidths: 120 },
  ];

  const ANCHOR_INTEREST_PAYMENT_PDF_TABLE_HEADERS: HeaderType[] = [
    { headerText: t('text:Partner_Name'), colWidths: 120 },
    { headerText: t('text:Partner_Code_of_Anchor'), colWidths: 120 },
    { headerText: t('text:Partner_Master_Agreement_Number'), colWidths: 180 },
    { headerText: t('text:Associated_Anchor_Master_Agreement_Number'), colWidths: 180 },
    { headerText: t('text:Currency'), colWidths: 100 },
    { headerText: t('text:Total_Principal'), colWidths: 120 },
    { headerText: t('text:Normal_Interest'), colWidths: 120 },
    { headerText: t('text:Delinquent_Interest'), colWidths: 120 },
    { headerText: t('text:Total_Interest'), colWidths: 120 },
  ];

  const ANCHOR_INTEREST_PAYMENT_EXCEL_COLUMNS: ColumnOption<
    DealerAgreementRepaidAmountVOModel & { totalInterestAmount: number }
  >[] = [
    { header: t('text:Partner_Name'), key: 'dealerClientName', width: 20 },
    { header: t('text:Partner_Code_of_Anchor'), key: 'dealerCodeByAnchor', width: 50 },
    { header: t('text:Partner_Master_Agreement_Number'), key: 'dealerAgreementContractNo', width: 50 },
    { header: t('text:Associated_Anchor_Master_Agreement_Number'), key: 'anchorAgreementContractNo', width: 50 },
    { header: t('text:Currency'), key: 'currencyType', width: 20 },
    {
      header: t('text:Total_Principal'),
      key: 'repaidPrincipalAmount',
      width: 30,
      style: {
        alignment: { horizontal: 'right' },
      },
    },
    {
      header: t('text:Normal_Interest'),
      key: 'repaidInterestAmount',
      width: 30,
      style: {
        alignment: { horizontal: 'right' },
      },
    },
    {
      header: t('text:Delinquent_Interest'),
      key: 'repaidDelayAmount',
      width: 30,
      style: {
        alignment: { horizontal: 'right' },
      },
    },
    {
      header: t('text:Total_Interest'),
      key: 'totalInterestAmount',
      width: 30,
      style: {
        alignment: { horizontal: 'right' },
      },
    },
  ];

  return {
    t,
    ANCHOR_INTEREST_PAYMENT_TOTAL_DATA_COUNT,
    PDF_EXPORT_MAX_ROW_COUNT,
    AC_INTEREST_PAYMENT_LIST_QS_KEY,
    ANCHOR_INTEREST_PAYMENT_SEARCH_RESULT_TABLE_HEADERS,
    ANCHOR_INTEREST_PAYMENT_PDF_TABLE_HEADERS,
    ANCHOR_INTEREST_PAYMENT_EXCEL_COLUMNS,
  };
};

function AnchorInterestPaymentList() {
  const mounted = useMounted();
  const modal = useModal();

  const {
    t,
    ANCHOR_INTEREST_PAYMENT_TOTAL_DATA_COUNT,
    ANCHOR_INTEREST_PAYMENT_SEARCH_RESULT_TABLE_HEADERS,
    AC_INTEREST_PAYMENT_LIST_QS_KEY,
    ANCHOR_INTEREST_PAYMENT_PDF_TABLE_HEADERS,
    ANCHOR_INTEREST_PAYMENT_EXCEL_COLUMNS,
    PDF_EXPORT_MAX_ROW_COUNT,
  } = getConstants();

  const [pageData, setPageData] = useState<Pageable<DealerAgreementRepaidAmountVOModel[]>>();

  const { register, getValues, reset, watch, setValue, control } =
    useForm<AnchorDealerAgreementRepaidAmountsListRequest>();

  const { pageable, setPageable } = usePageable(AC_INTEREST_PAYMENT_LIST_QS_KEY);

  const { startDate, endDate } = watch();

  const location = useLocation<AnchorDealerAgreementRepaidAmountsListRequest>();
  const filteredOptions = location.state;

  const koreaDate = new Date();
  koreaDate.setTime(koreaDate.getTime() + 9);
  const defaultEndDate = new Date(koreaDate);
  const [defaultEndDay] = defaultEndDate.toISOString().split('T');
  const defaultStartDate = new Date(koreaDate);
  defaultStartDate.setMonth(defaultStartDate.getMonth() - 1);
  const [defaultStartDay] = defaultStartDate.toISOString().split('T');

  const [searchedDate, setSearchedDate] = useState<{
    searchedStartDate: Date | string;
    searchedEndDate: Date | string;
  }>({
    searchedStartDate: defaultStartDate,
    searchedEndDate: defaultEndDate,
  });

  useEffect(() => {
    if (mounted) {
      setFormValues<AnchorDealerAgreementRepaidAmountsListRequest>(
        setValue,
        filteredOptions ?? getParsedSearchParams(AC_INTEREST_PAYMENT_LIST_QS_KEY).formSearchData,
      );
      fetchAcDealerAgreementRepaidAmountList(pageable.currentPage, pageable.sizePerPage, getValues());
    }
  }, [mounted]);

  useEffect(() => {
    if (mounted) {
      if (startDate !== '') {
        const fromDate = new Date(startDate);
        fromDate.setMonth(fromDate.getMonth() + 6);
        const toDate = new Date(endDate);

        if (fromDate < toDate) {
          const searchFormData = getValues();

          reset({
            ...searchFormData,
            endDate: fromDate.toISOString().split('T')[0],
          });
        }
      }
    }
  }, [mounted, startDate]);

  useEffect(() => {
    if (mounted) {
      if (endDate !== '') {
        const toDate = new Date(endDate);
        toDate.setMonth(toDate.getMonth() - 6);
        const fromDate = new Date(startDate);

        if (fromDate < toDate) {
          const searchFormData = getValues();

          reset({
            ...searchFormData,
            startDate: toDate.toISOString().split('T')[0],
          });
        }
      }
    }
  }, [mounted, endDate]);

  const fetchAcDealerAgreementRepaidAmountList = async (
    selectedPageNumber: number = 1,
    selectedRowCount: number = 10,
    data: AnchorDealerAgreementRepaidAmountsListRequest,
  ) => {
    try {
      const repaidAmountsByDealerAgreementsList = await requestAnchorDealerAgreementRepaidAmountsList(
        selectedPageNumber,
        selectedRowCount,
        data,
      );

      updateSearchParams(
        {
          pageNumber: selectedPageNumber,
          rowCount: selectedRowCount,
          ...data,
        },
        AC_INTEREST_PAYMENT_LIST_QS_KEY,
      );

      ReactDOM.unstable_batchedUpdates(() => {
        setPageData(repaidAmountsByDealerAgreementsList);
        setPageable(repaidAmountsByDealerAgreementsList);
        setSearchedDate({
          searchedStartDate: startDate,
          searchedEndDate: endDate,
        });
      });
    } catch (error) {
      modal.show(error);
    }
  };

  const paginate = async (pageNumber: number, rowCount: number) => {
    await fetchAcDealerAgreementRepaidAmountList(
      pageNumber,
      rowCount,
      getParsedSearchParams(AC_INTEREST_PAYMENT_LIST_QS_KEY).formSearchData,
    );
  };

  const fetchTotalData = async (maxDataCount: number): Promise<Pageable<DealerAgreementRepaidAmountVOModel[]>> => {
    const searchParams = getParsedSearchParams(AC_INTEREST_PAYMENT_LIST_QS_KEY).formSearchData;
    const repaidAmountsByDealerAgreementsList = await requestAnchorDealerAgreementRepaidAmountsList(
      0,
      maxDataCount,
      searchParams,
    );

    return repaidAmountsByDealerAgreementsList;
  };

  const onClickRemoveFilter = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();

    reset({
      startDate: defaultStartDay,
      endDate: defaultEndDay,
    });
  };

  const onClickSearch = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
    fetchAcDealerAgreementRepaidAmountList(1, pageable.sizePerPage, getValues());
  };

  const onClickExport = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();

    const renderPDFTableBodyResult = (data?: DealerAgreementRepaidAmountVOModel[]) => {
      return data?.map((item, index) => (
        <tr key={index} className="virtual-table-row">
          <Td data={item.dealerClientName} />
          <Td data={item.dealerCodeByAnchor} />
          <Td data={item.dealerAgreementContractNo} />
          <Td data={item.anchorAgreementContractNo} />
          <Td data={item.currencyType} />
          <Td data={item.repaidPrincipalAmount} format="number" />
          <Td data={item.repaidInterestAmount} format="number" />
          <Td data={item.repaidDelayAmount} format="number" />
          <Td data={Number(item.repaidInterestAmount) + Number(item.repaidDelayAmount)} format="number" />
        </tr>
      ));
    };

    try {
      const [totalDealerAgreementRepaidAmountsData, totalDealerAgreementRepaidAmountsPDFData] = await Promise.all([
        fetchTotalData(ANCHOR_INTEREST_PAYMENT_TOTAL_DATA_COUNT),
        fetchTotalData(PDF_EXPORT_MAX_ROW_COUNT),
      ]);

      const jsonArrayData = totalDealerAgreementRepaidAmountsData.content.map(item => ({
        dealerClientName: tableValueManage(item.dealerClientName),
        dealerCodeByAnchor: tableValueManage(item.dealerCodeByAnchor),
        dealerAgreementContractNo: tableValueManage(item.dealerAgreementContractNo),
        anchorAgreementContractNo: tableValueManage(item.anchorAgreementContractNo),
        currencyType: tableValueManage(item.currencyType),
        repaidPrincipalAmount: tableValueManage(t('format:number', { value: item.repaidPrincipalAmount })),
        repaidInterestAmount: tableValueManage(t('format:number', { value: item.repaidInterestAmount })),
        repaidDelayAmount: tableValueManage(t('format:number', { value: item.repaidDelayAmount })),
        totalInterestAmount: tableValueManage(
          t('format:number', { value: Number(item.repaidInterestAmount) + Number(item.repaidDelayAmount) }),
        ),
      }));

      const excelExporterProps: ExportSpreadSheetProps<
        DealerAgreementRepaidAmountVOModel & { totalInterestAmount: number }
      > = {
        jsonArrayData: jsonArrayData,
        columns: ANCHOR_INTEREST_PAYMENT_EXCEL_COLUMNS,
        options: {
          rowHeight: [{ position: 1, height: 30 }],
        },
      };

      const pdfExporterProps: PDFExporterProps = {
        tableHeaders: ANCHOR_INTEREST_PAYMENT_PDF_TABLE_HEADERS,
        tableBody: renderPDFTableBodyResult(totalDealerAgreementRepaidAmountsPDFData.content),
      };

      modal.show(<Exporter spreadSheetExporterProps={excelExporterProps} pdfExporterProps={pdfExporterProps} />, {
        title: t('text:Export_File'),
        closeBtnText: t('text:Close'),
      });
    } catch (error) {
      modal.show(error);
    }
  };

  const isExportButtonDisabled = pageData?.content.length === 0;

  const renderAnchorInterestPaymentSearchResultTable = () => {
    return pageData?.content.map((item, index) => (
      <Tr key={index}>
        <Td data={item.dealerClientName} />
        <Td data={item.dealerCodeByAnchor} />
        <Td data={item.dealerAgreementContractNo} />
        <Td data={item.anchorAgreementContractNo} />
        <Td data={item.currencyType} />
        <Td data={item.repaidPrincipalAmount} format="number" />
        <Td data={item.repaidInterestAmount} format="number" />
        <Td data={item.repaidDelayAmount} format="number" />
        <Td data={Number(item.repaidInterestAmount) + Number(item.repaidDelayAmount)} format="number" />
      </Tr>
    ));
  };

  return (
    <>
      <HeaderTitle title={t('text:Interest_Payment')} />
      <GuideMessage
        message={[
          t(
            'text:See_the_total_principal_and_interest_amount_each_Partner_repaid_for_their_respective_Partner_Master_Agreements_during_the_selected_date_range',
          ),
          t('text:The_maximum_date_range_that_can_be_selected_is_6_months'),
        ]}
      />
      <div className="content-area">
        <form>
          <SectionTitle title={t('text:Search')}>
            <Button
              variant={ButtonVariantEnum.OUTLINED}
              color={ButtonColorEnum.SECONDARY}
              onClick={onClickRemoveFilter}
            >
              {t('text:Remove_Filter')}
            </Button>
          </SectionTitle>
          <SearchBorder>
            <div className="row">
              <SearchLabel label={t('text:Partner_Name')} />
              <SearchInput ref={register} name="dealerClientName" />
              <SearchLabel label={t('text:Partner_Code_of_Anchor')} />
              <SearchInput ref={register} name="dealerCodeByAnchor" />
            </div>
            <div className="row">
              <SearchLabel label={t('text:Partner_Master_Agreement_Number')} />
              <SearchInput ref={register} name="dealerAgreementContractNo" />
              <SearchLabel label={t('text:Associated_Anchor_Master_Agreement_Number')} />
              <SearchInput ref={register} name="anchorAgreementContractNo" />
            </div>
            <div className="row">
              <SearchLabel label={t('text:Currency')} />
              <SearchSelect
                ref={register}
                name="currencyType"
                selectOptions={getSelectOptions<CURRENCY_TYPE>('CURRENCY_TYPE', 'ALL', true)}
              />
              <SearchLabel label={t('text:Date')} />
              <SearchDatePicker
                control={control}
                name="startDate"
                placeholder={t('text:from')}
                defaultValue={defaultStartDay}
                maxDate={koreaDate}
              />
              <SearchDatePicker
                control={control}
                name="endDate"
                placeholder={t('text:to')}
                defaultValue={defaultEndDay}
                maxDate={koreaDate}
              />
            </div>
          </SearchBorder>
          <div className="flex-center mt-4">
            <Button size={ButtonSizeEnum.LG} onClick={onClickSearch}>
              {t('text:Search')}
            </Button>
          </div>
        </form>
      </div>
      <div className="division-border" />
      <div className="content-area">
        <SectionTitle title={t('text:Result')} />
        <div className="d-flex mb-2">
          <p className="total-data me-auto">
            {t('text:Date')} : {t('format:date', { key: 'date', value: searchedDate.searchedStartDate })} ~{' '}
            {t('format:date', { key: 'date', value: searchedDate.searchedEndDate })}
          </p>
          <Button onClick={onClickExport} variant={ButtonVariantEnum.OUTLINED} disabled={isExportButtonDisabled}>
            {t('text:Export')}
          </Button>
        </div>
        <TableBorder>
          <TableHeader header={ANCHOR_INTEREST_PAYMENT_SEARCH_RESULT_TABLE_HEADERS} />
          <TableBody numOfCol={ANCHOR_INTEREST_PAYMENT_SEARCH_RESULT_TABLE_HEADERS.length}>
            {renderAnchorInterestPaymentSearchResultTable()}
          </TableBody>
        </TableBorder>
        <Pagination pageable={pageable} paginate={paginate} />
      </div>
    </>
  );
}

export default AnchorInterestPaymentList;
