import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Button, { ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import { FormBorder } from 'components/stateless/CommonForm/FormBorder';
import { FormContents } from 'components/stateless/CommonForm/FormContents';
import FormInput from 'components/stateless/CommonForm/FormInput';
import { BackGroundType, FormSubtitle } from 'components/stateless/CommonForm/FormSubtitle';
import { FormValue } from 'components/stateless/CommonForm/FormValue';
import GuideMessage, { MessageType } from 'components/stateless/GuideMessage/GuideMessage';
import CitadCodeModal from 'components/stateless/Modal/common/CitadCodeModal';
import ReasonModal from 'components/stateless/Modal/common/ReasonModal';
import type { UserVerificationCodeRequest } from 'components/stateless/Modal/common/UserVerificationModal';
import UserVerificationModal from 'components/stateless/Modal/common/UserVerificationModal';
import { BackHeaderTitle } from 'components/stateless/Title/BackHeaderTitle';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { ROUTES_AC } from 'constants/routes/anchor';
import { AUTHORITY_TYPE, COMMON_APPROVAL_TYPE, ENTERPRISE_TYPE, OTP_TYPE } from 'enums';
import useMounted from 'hooks/useMounted';
import type { BankCodeVOModel } from 'models/vo/BankCodeVO';
import type { FinancierClientAuthSettingVOModel } from 'models/vo/FinancierClientAuthSettingVO';
import type { WaitingAnchorPartnerAccountDetailVOModel } from 'models/vo/WaitingAnchorPartnerAccountDetailVO';
import getStatusBadgeClass from 'utils/classNames/getStatusBadgeClass';
import { formErrorHandler } from 'utils/error/manager';
import { requestAnchorClientAuthByFinancierId } from 'utils/http/api/anchor/client-auth-setting';
import {
  requestApproveWaitingAnchorPartnerAccountDetail,
  requestCancelWaitingAnchorPartnerAccountDetail,
  requestReturnWaitingAnchorPartnerAccountDetail,
  requestUpdateWaitingAnchorPartnerAccountDetail,
  requestWaitingAnchorPartnerAccountDetail,
} from 'utils/http/api/anchor/waiting-anchor-partner-accounts';
import type { UpdateAcWaitingAnchorPartnerAccount } from 'utils/http/api/anchor/waiting-anchor-partner-accounts/requests';
import { ModalSize, ModalType } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';
import { getPaymentSupportText } from 'utils/text';

type DataRelatedToTheSelectedBankCode = {
  bankCodeId: BankCodeVOModel['id'];
  bankCode: BankCodeVOModel['bankCode'];
  bankName: BankCodeVOModel['bankName'];
  branchCode: BankCodeVOModel['branchCode'];
  branchName: BankCodeVOModel['branchName'];
};

function AnchorPartnerBankAccountWaitingDetail() {
  const { t } = useTranslation();
  const modal = useModal();
  const mounted = useMounted();
  const history = useHistory();

  const { waitingAnchorPartnerAccountId } = useParams() as any;
  const [waitingAnchorPartnerBankAccountDetailInfo, setWaitingAnchorPartnerBankAccountDetailInfo] =
    useState<WaitingAnchorPartnerAccountDetailVOModel>();
  const [financierClientAuthSettingInfo, setFinancierClientAuthSettingInfo] =
    useState<FinancierClientAuthSettingVOModel>();
  const [dataRelatedToTheSelectedBankCode, setDataRelatedToTheSelectedBankCode] =
    useState<DataRelatedToTheSelectedBankCode>();

  const { register, getValues, errors, setError, clearErrors } = useForm<UpdateAcWaitingAnchorPartnerAccount>({
    mode: 'onSubmit',
    shouldFocusError: true,
  });

  const isHqOperator = financierClientAuthSettingInfo?.currentAuthorityType === AUTHORITY_TYPE.HQ_OPERATOR;
  const isAdmin = financierClientAuthSettingInfo?.currentAuthorityType === AUTHORITY_TYPE.ADMIN;
  const isHqOperatorOrAdmin = isHqOperator || isAdmin;

  const approvalType = waitingAnchorPartnerBankAccountDetailInfo?.approvalType;
  const isRequested = approvalType === COMMON_APPROVAL_TYPE.OPERATOR_REQUEST;
  const isCanceled = approvalType === COMMON_APPROVAL_TYPE.OPERATOR_CANCEL;
  const isReverted = approvalType === COMMON_APPROVAL_TYPE.AUTHORIZER_RETURNED;

  const isOperatorEnterpriseTypeAnchor =
    waitingAnchorPartnerBankAccountDetailInfo?.operatorEnterpriseType === ENTERPRISE_TYPE.AC;

  const isEditable = isReverted && isHqOperator && isOperatorEnterpriseTypeAnchor;

  useEffect(() => {
    if (mounted) {
      fetchAll();
    }
  }, [mounted]);

  async function fetchAll() {
    try {
      const bankAccountDetaildata = await requestWaitingAnchorPartnerAccountDetail(waitingAnchorPartnerAccountId);
      const financierClientAuthSettingInfo = await requestAnchorClientAuthByFinancierId(
        bankAccountDetaildata.responsibleFinancierId,
      );

      ReactDOM.unstable_batchedUpdates(() => {
        setWaitingAnchorPartnerBankAccountDetailInfo(bankAccountDetaildata);
        setFinancierClientAuthSettingInfo(financierClientAuthSettingInfo);
      });
    } catch (error) {
      modal.show(error);
    }
  }

  const getGuideMessage = (): JSX.Element | null => {
    switch (approvalType) {
      case COMMON_APPROVAL_TYPE.OPERATOR_REQUEST:
        if (waitingAnchorPartnerBankAccountDetailInfo?.operatorEnterpriseType === ENTERPRISE_TYPE.AC) {
          if (isHqOperator) {
            return (
              <GuideMessage
                message={[
                  t('text:The_Supply_Chain_Partners_company_information_modification_has_been_requested'),
                  t('text:Modification_will_be_completed_when_the_Authorizer_confirms_the_modification_request'),
                ]}
              />
            );
          }

          if (isAdmin) {
            return (
              <GuideMessage
                message={[
                  t('text:The_Supply_Chain_Partners_company_information_modification_has_been_requested'),
                  t('text:Click_on_the_Confirm_button_at_the_bottom_of_the_page_to_complete_the_modification'),
                  t(
                    'text:Click_on_the_Revert_button_at_the_bottom_of_the_page_to_return_the_modification_request_to_the_HQ_Operator',
                  ),
                ]}
              />
            );
          }
        } else if (waitingAnchorPartnerBankAccountDetailInfo?.operatorEnterpriseType === ENTERPRISE_TYPE.FI) {
          return (
            <GuideMessage
              message={[t('text:The_Partners_revision_will_be_completed_after_confirmation_from_the_Financier')]}
            />
          );
        }

        return null;
      case COMMON_APPROVAL_TYPE.AUTHORIZER_RETURNED:
        if (
          waitingAnchorPartnerBankAccountDetailInfo?.operatorEnterpriseType === ENTERPRISE_TYPE.AC &&
          isHqOperatorOrAdmin
        ) {
          return (
            <GuideMessage
              messageType={MessageType.ALERT}
              message={[t('text:The_Supply_Chain_Partner_modification_request_has_been_reverted')]}
              reason={waitingAnchorPartnerBankAccountDetailInfo?.returnReason}
              reasonTitle={t('text:REASON_FOR_REVERT')}
            />
          );
        } else if (waitingAnchorPartnerBankAccountDetailInfo?.operatorEnterpriseType === ENTERPRISE_TYPE.FI) {
          return (
            <GuideMessage
              messageType={MessageType.ALERT}
              message={[t('text:The_Financiers_revision_request_was_reverted')]}
              reason={waitingAnchorPartnerBankAccountDetailInfo.returnReason}
              reasonTitle={t('text:REASON_FOR_REVERT')}
            />
          );
        }

        return null;
      default:
        return null;
    }
  };

  const getApprovalRequestStatusInfo = () => {
    const hqOperatorInfo = `${t('text:Admin_Operator')} (${
      waitingAnchorPartnerBankAccountDetailInfo?.operatorUserName
    } / ${waitingAnchorPartnerBankAccountDetailInfo?.operatorUserLoginId}) `;

    const adminInfo = `${t('text:Admin')} (${waitingAnchorPartnerBankAccountDetailInfo?.authorizerUserName} / ${
      waitingAnchorPartnerBankAccountDetailInfo?.authorizerUserLoginId
    }) `;

    const hqOperatorEnterpriseInfo = waitingAnchorPartnerBankAccountDetailInfo?.operatorEnterpriseName;

    const adminEnterpriseInfo = waitingAnchorPartnerBankAccountDetailInfo?.authorizerEnterpriseName;

    switch (approvalType) {
      case COMMON_APPROVAL_TYPE.OPERATOR_REQUEST:
        return {
          badgeTitle: t('text:Requested'),
          requestor: hqOperatorInfo,
          enterpriseName: hqOperatorEnterpriseInfo,
          timeTitle: t('text:Requested_Date'),
        };
      case COMMON_APPROVAL_TYPE.AUTHORIZER_RETURNED:
        return {
          badgeTitle: t('text:Reverted'),
          requestor: adminInfo,
          enterpriseName: adminEnterpriseInfo,
          timeTitle: t('text:Reverted_Date'),
        };
      case COMMON_APPROVAL_TYPE.OPERATOR_CANCEL:
        return {
          badgeTitle: t('text:Cancelled'),
          requestor: hqOperatorInfo,
          enterpriseName: hqOperatorEnterpriseInfo,
          timeTitle: t('text:Cancelled_Date'),
        };
      default:
        return;
    }
  };

  const getBadgeDetailText = (): JSX.Element | null => {
    const renderTitleAndInfo = (
      authority: string,
      userName?: string,
      userLoginId?: string,
      enterpriseName?: string,
    ) => {
      return (
        <>
          <span className="supply-chain-partner-requester-info-title">{`${authority} (${userName ?? '-'} / ${
            userLoginId ?? '-'
          })`}</span>
          <span>{enterpriseName}</span>
        </>
      );
    };

    const {
      approvalType,
      operatorUserName,
      operatorUserLoginId,
      operatorEnterpriseName,
      authorizerUserName,
      authorizerUserLoginId,
      authorizerEnterpriseName,
    } = waitingAnchorPartnerBankAccountDetailInfo ?? {};

    switch (approvalType) {
      case COMMON_APPROVAL_TYPE.OPERATOR_REQUEST:
      case COMMON_APPROVAL_TYPE.OPERATOR_CANCEL:
        return renderTitleAndInfo(
          isOperatorEnterpriseTypeAnchor ? t('text:Admin_Operator') : t('text:Operator'),
          operatorUserName,
          operatorUserLoginId,
          operatorEnterpriseName,
        );
      case COMMON_APPROVAL_TYPE.AUTHORIZER_RETURNED:
        return renderTitleAndInfo(
          isOperatorEnterpriseTypeAnchor ? t('text:Admin') : t('text:Authorizer'),
          authorizerUserName,
          authorizerUserLoginId,
          authorizerEnterpriseName,
        );

      default:
        return null;
    }
  };

  const renderRegistrationRequestStateBlock = (): JSX.Element | null => {
    const approvalRequestStatusInfo = getApprovalRequestStatusInfo();

    return (
      <>
        {getGuideMessage()}
        <div className="content-area">
          <div className="supply-chain-partner-status-block">
            <span
              className={`me-4 ${getStatusBadgeClass(
                'COMMON_APPROVAL_TYPE',
                waitingAnchorPartnerBankAccountDetailInfo?.approvalType,
              )}`}
            >
              {approvalRequestStatusInfo?.badgeTitle}
            </span>
            <span className="supply-chain-partner-requester-info">
              <span className="supply-chain-partner-requester-info">{getBadgeDetailText()}</span>
            </span>
            <span className="supply-chain-partner-requester-info supply-chain-partner-requester-info-time">
              <span className="supply-chain-partner-requester-info-title">{approvalRequestStatusInfo?.timeTitle}</span>
              <span>
                {t('format:datetime', {
                  value: waitingAnchorPartnerBankAccountDetailInfo?.updatedDateTime,
                  key: 'datetime',
                })}
              </span>
            </span>
          </div>
        </div>
      </>
    );
  };

  const renderSupplyChainPartnerInformation = (): JSX.Element => {
    return (
      <div className="content-area">
        <form>
          <SectionTitle title={t(`text:Partner_Information`)} />
          <FormBorder>
            <FormContents>
              <div className="row">
                <FormValue
                  label={t(`text:Partner_Name`)}
                  value={waitingAnchorPartnerBankAccountDetailInfo?.partnerName}
                />
                <FormValue
                  label={t(`text:Tax_Code`)}
                  value={waitingAnchorPartnerBankAccountDetailInfo?.partnerTaxCode}
                />
              </div>
              <div className="row">
                <FormValue
                  label={t(`text:Company_Registration_Number`)}
                  value={waitingAnchorPartnerBankAccountDetailInfo?.partnerBusinessCode}
                />
                <FormValue
                  col={3}
                  label={t(`text:Telephone`)}
                  value={waitingAnchorPartnerBankAccountDetailInfo?.telephone}
                />
                <FormValue col={3} label={t(`text:Fax`)} value={waitingAnchorPartnerBankAccountDetailInfo?.fax} />
              </div>
              <div className="row">
                <FormValue
                  label={t(`text:Legal_Representative_Name`)}
                  value={waitingAnchorPartnerBankAccountDetailInfo?.representativeName}
                />
                <FormValue
                  col={3}
                  label={t(`text:Legal_Representative_Title`)}
                  value={waitingAnchorPartnerBankAccountDetailInfo?.representativePosition}
                />
                <FormValue
                  col={3}
                  label={t(`text:Legal_Representative_Email`)}
                  value={waitingAnchorPartnerBankAccountDetailInfo?.representativeEmail}
                />
              </div>
              <div className="row">
                <FormValue
                  col={12}
                  label={t(`text:Registered_Office_Address`)}
                  value={waitingAnchorPartnerBankAccountDetailInfo?.address}
                />
              </div>
            </FormContents>
          </FormBorder>
        </form>
      </div>
    );
  };

  const renderDesignatedBankAccountInformationForAPSettlement = (): JSX.Element => {
    const onClickFind = (e: any): void => {
      e.preventDefault();

      const setBankCodeData = (data: BankCodeVOModel): void => {
        setDataRelatedToTheSelectedBankCode({
          bankCodeId: data.id,
          bankCode: data.bankCode,
          bankName: data.bankName,
          branchCode: data.branchCode,
          branchName: data.branchName,
        });
      };

      modal.show(
        <CitadCodeModal
          handleBankCodeData={setBankCodeData}
          modalId={modal.id}
          financierId={financierClientAuthSettingInfo?.financierId!}
        />,
        {
          title: t('text:Find_Bank_Code'),
          modalType: ModalType.ALERT,
          modalSize: ModalSize.L,
          closeBtnText: t('text:Close'),
        },
      );
    };

    return (
      <div className="content-area">
        <form>
          <SectionTitle title={t(`text:Designated_Bank_Account_Information_for_AP_Settlement`)} />
          <FormBorder editable={isEditable}>
            <FormSubtitle
              backGroundType={BackGroundType.DarkGray}
              title={t('text:Associated_Anchor_Master_Agreement_Information')}
            >
              <div className="flex-end align-items-center me-3">
                <span className="me-2">{t('text:Go_to_Agreement_Details')}</span>
                <Button
                  size={ButtonSizeEnum.SM}
                  variant={ButtonVariantEnum.OUTLINED}
                  color={ButtonColorEnum.SECONDARY}
                  className="flex-column-center"
                  onClick={() => {
                    history.push(
                      ROUTES_AC.COMPANY.AGREEMENT_DETAIL_BUILD_PATH(
                        waitingAnchorPartnerBankAccountDetailInfo?.anchorAgreementId!,
                      ),
                    );
                  }}
                >
                  <FontAwesomeIcon icon={faArrowRight} />
                </Button>
              </div>
            </FormSubtitle>
            <FormContents>
              <div className="row">
                <FormValue
                  label={t(`text:Anchor_Master_Agreement_Number`)}
                  value={waitingAnchorPartnerBankAccountDetailInfo?.anchorAgreementContractNo}
                />
              </div>
              <div className="row">
                <FormValue
                  label={t(`text:Automatic_Settlement`)}
                  value={getPaymentSupportText(waitingAnchorPartnerBankAccountDetailInfo?.paymentSupport)}
                />
                <FormValue label={t(`text:Currency`)} value={waitingAnchorPartnerBankAccountDetailInfo?.currencyType} />
              </div>
              <div className="row">
                <FormValue
                  label={t(`text:Responsible_Bank`)}
                  value={waitingAnchorPartnerBankAccountDetailInfo?.responsibleFinancierName}
                />
                <FormValue
                  label={t(`text:Responsible_Branch_Name`)}
                  value={waitingAnchorPartnerBankAccountDetailInfo?.responsibleBranchName}
                />
              </div>
            </FormContents>
            <FormSubtitle
              backGroundType={BackGroundType.DarkGray}
              title={t('text:Designated_Bank_Account_Information')}
            />
            <FormContents>
              <div className="row">
                <FormInput
                  requiredOptions={{ fixedRequired: isEditable }}
                  name="bankCode"
                  label={t(`text:Bank_Code`)}
                  value={
                    dataRelatedToTheSelectedBankCode?.bankCode ??
                    waitingAnchorPartnerBankAccountDetailInfo?.accountBankCode
                  }
                  disabled
                  error={errors.bankCodeId}
                >
                  {isEditable && (
                    <Button size={ButtonSizeEnum.SM} onClick={onClickFind}>
                      {t('text:Find')}
                    </Button>
                  )}
                </FormInput>
                <FormInput
                  requiredOptions={{ fixedRequired: isEditable }}
                  name="bankName"
                  label={t(`text:Bank_Name`)}
                  value={
                    dataRelatedToTheSelectedBankCode?.bankName ??
                    waitingAnchorPartnerBankAccountDetailInfo?.accountBankName
                  }
                  disabled
                />
              </div>
              <div className="row">
                <FormInput
                  requiredOptions={{ fixedRequired: isEditable }}
                  name="branchCode"
                  label={t(`text:Branch_Code`)}
                  value={
                    dataRelatedToTheSelectedBankCode?.branchCode ??
                    waitingAnchorPartnerBankAccountDetailInfo?.accountBranchCode
                  }
                  disabled
                />
                <FormInput
                  requiredOptions={{ fixedRequired: isEditable }}
                  name="branchName"
                  label={t(`text:Branch_Name`)}
                  value={
                    dataRelatedToTheSelectedBankCode?.branchName ??
                    waitingAnchorPartnerBankAccountDetailInfo?.accountBranchName
                  }
                  disabled
                />
              </div>
              <div className="row">
                <FormInput
                  label={t(`text:Bank_Account_Holder`)}
                  name="accountOwner"
                  defaultValue={waitingAnchorPartnerBankAccountDetailInfo?.accountOwner}
                  ref={register}
                  requiredOptions={{ required: true }}
                  disabled={!isEditable}
                  error={errors.accountOwner}
                  applyUpperCase
                />
                <FormInput
                  label={t(`text:Bank_Account_Number`)}
                  name="account"
                  defaultValue={waitingAnchorPartnerBankAccountDetailInfo?.account}
                  ref={register}
                  requiredOptions={{ required: true }}
                  disabled={!isEditable}
                  error={errors.account}
                  applyUpperCase
                />
              </div>
              <div className="row">
                <FormInput
                  label={t(`text:Requested_Bank_Name`)}
                  name="requestedAccountBankName"
                  defaultValue={waitingAnchorPartnerBankAccountDetailInfo?.requestedAccountBankName}
                  ref={register}
                  requiredOptions={{ required: true }}
                  disabled={!isEditable}
                  error={errors.requestedAccountBankName}
                />
                <FormInput
                  label={t(`text:Requested_Branch_Name`)}
                  name="requestedAccountBranchName"
                  defaultValue={waitingAnchorPartnerBankAccountDetailInfo?.requestedAccountBranchName}
                  ref={register}
                  requiredOptions={{ required: true }}
                  disabled={!isEditable}
                  error={errors.requestedAccountBranchName}
                />
              </div>
            </FormContents>
          </FormBorder>
        </form>
      </div>
    );
  };

  const renderHqOperatorAuthorityButton = () => {
    if (isCanceled) return null;

    const cancelAnchorPartnerAccountRevision = async () => {
      try {
        await requestCancelWaitingAnchorPartnerAccountDetail(waitingAnchorPartnerAccountId);
        await fetchAll();
      } catch (e) {
        modal.show(e);
      }
    };

    const onClickCancelRequest = () => {
      modal.show(
        <h6>
          {t('text:Would_you_like_to_cancel_the_modification_request?')}
          <br />
          {t('text:The_information_will_not_be_saved_if_modification_is_cancelled')}
        </h6>,
        {
          modalType: ModalType.CONFIRM,
          confirmBtnCb: cancelAnchorPartnerAccountRevision,
        },
      );
    };

    const successChangeRequestModal = () => {
      modal.show(
        <h6>
          {t('text:Request_for_modification_approval_has_been_completed')}
          <br />
          {t('text:Modification_will_be_completed_after_approval_by_the_HQ_Authorizer')}
        </h6>,
        {
          closeBtnCb: fetchAll,
        },
      );
    };

    const requestUpdateBankAccountInfo = async () => {
      const bankCodeId =
        dataRelatedToTheSelectedBankCode?.bankCodeId ??
        waitingAnchorPartnerBankAccountDetailInfo?.accountBankCodeId ??
        NaN;

      const accountInfo: UpdateAcWaitingAnchorPartnerAccount = { ...getValues(), bankCodeId };

      try {
        await requestUpdateWaitingAnchorPartnerAccountDetail(waitingAnchorPartnerAccountId, accountInfo);
        clearErrors();
        successChangeRequestModal();
      } catch (e) {
        modal.show(e);
        formErrorHandler<UpdateAcWaitingAnchorPartnerAccount>(e, setError, clearErrors);
      }
    };

    const onClickChangeRequest = () => {
      modal.show(<h6>{t('text:Would_you_like_to_request_approval_for_the_modification')}</h6>, {
        modalType: ModalType.CONFIRM,
        confirmBtnCb: requestUpdateBankAccountInfo,
      });
    };

    if (isRequested) {
      return (
        <div className="content-area flex-end">
          <Button
            size={ButtonSizeEnum.LG}
            onClick={onClickCancelRequest}
            variant={ButtonVariantEnum.OUTLINED}
            color={ButtonColorEnum.SECONDARY}
          >
            {t('text:Cancel_Request')}
          </Button>
        </div>
      );
    }

    if (isReverted) {
      return (
        <div className="content-area flex-end mt-3">
          <Button
            size={ButtonSizeEnum.LG}
            onClick={onClickCancelRequest}
            variant={ButtonVariantEnum.OUTLINED}
            color={ButtonColorEnum.SECONDARY}
            className="me-2"
          >
            {t('text:Cancel_Request')}
          </Button>
          <Button size={ButtonSizeEnum.LG} onClick={onClickChangeRequest}>
            {t('text:Request_Revision')}
          </Button>
        </div>
      );
    }
  };

  const renderAdminAuthorityButton = () => {
    if (!isRequested) return null;

    const onClickConfirm = () => {
      const financierId = financierClientAuthSettingInfo?.financierId ?? NaN;

      const successfullyConfirmedModal = () => {
        modal.show(<h6>{t('text:The_changes_to_the_Supply_Chain_Partner_information_have_been_saved')}</h6>, {
          closeBtnCb: () => {
            history.push(ROUTES_AC.MANAGE_PARTNER.BANK_ACCOUNT_LIST);
          },
        });
      };

      const approveAnchorPartnerAccountRevision = async (verificationCode?: UserVerificationCodeRequest) => {
        const otpData = {
          financierId,
          otpCode: verificationCode?.otpCode,
          queryValue: verificationCode?.queryValue,
        };

        try {
          await requestApproveWaitingAnchorPartnerAccountDetail(waitingAnchorPartnerAccountId, otpData);
          successfullyConfirmedModal();
        } catch (e) {
          modal.show(e);
        }
      };

      const showOtpVerificationModal = () => {
        const verificationCode: UserVerificationCodeRequest = {};

        modal.show(
          <UserVerificationModal
            modalId={modal.id}
            verificationCode={verificationCode}
            requestIdType="financierId"
            requestId={financierId}
            clientAuthSetting={financierClientAuthSettingInfo as FinancierClientAuthSettingVOModel}
          />,
          {
            modalType: ModalType.CONFIRM,
            title: t('text:User_Verification'),
            closeBtnText: t('text:Cancel'),
            confirmBtnCb: () => approveAnchorPartnerAccountRevision(verificationCode),
          },
        );
      };

      modal.show(
        <h6>{t('text:Would_you_like_to_confirm_the_Supply_Chain_Partner_information_modification_request')}</h6>,
        {
          modalType: ModalType.CONFIRM,
          closeBtnText: t(`text:Cancel`),
          confirmBtnCb: () =>
            financierClientAuthSettingInfo?.otpType !== OTP_TYPE.NONE
              ? showOtpVerificationModal()
              : approveAnchorPartnerAccountRevision(),
        },
      );
    };

    const onClickRevert = () => {
      let reason = '';

      const successfullyRevertedModal = () => {
        modal.show(<h6>{t('text:The_Supply_Chain_Partner_modification_request_has_been_reverted')}</h6>);
      };

      const revertAnchorParnterAccountRevision = async () => {
        try {
          await requestReturnWaitingAnchorPartnerAccountDetail(waitingAnchorPartnerAccountId, reason);
          await fetchAll();
          successfullyRevertedModal();
        } catch (e) {
          modal.show(e);
        }
      };

      modal.show(
        <>
          <h6>
            {t('text:Would_you_like_to_revert_the_modification_request')}
            <br />
            {t('text:Please_type_below_the_reason_for_the_revert')}
            <br />
            {t('text:Click_on_Confirm_to_revert_the_request')}
          </h6>
          <ReasonModal
            modalId={modal.id}
            getReturnReason={(returnReason: string) => {
              reason = returnReason;
            }}
          />
        </>,
        {
          modalType: ModalType.CONFIRM,
          confirmBtnCb: revertAnchorParnterAccountRevision,
        },
      );
    };

    return (
      <div className="flex-end mt-3 content-area">
        <Button
          size={ButtonSizeEnum.LG}
          onClick={onClickRevert}
          variant={ButtonVariantEnum.OUTLINED}
          color={ButtonColorEnum.RED}
          className="me-2"
        >
          {t('text:Revert')}
        </Button>
        <Button size={ButtonSizeEnum.LG} onClick={onClickConfirm}>
          {t('text:Confirm')}
        </Button>
      </div>
    );
  };

  return (
    <>
      <BackHeaderTitle title={waitingAnchorPartnerBankAccountDetailInfo?.partnerName} />
      {isHqOperatorOrAdmin && renderRegistrationRequestStateBlock()}
      {renderSupplyChainPartnerInformation()}
      {renderDesignatedBankAccountInformationForAPSettlement()}
      {isHqOperator && isOperatorEnterpriseTypeAnchor && renderHqOperatorAuthorityButton()}
      {isAdmin && isOperatorEnterpriseTypeAnchor && renderAdminAuthorityButton()}
    </>
  );
}

export default AnchorPartnerBankAccountWaitingDetail;
