import type { MouseEventHandler } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Button, { ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import Exporter from 'components/stateless/Exporter/Exporter';
import Pagination from 'components/stateless/Pagination/Pagination';
import SearchBorder from 'components/stateless/SearchForm/SearchBorder';
import SearchDatePicker from 'components/stateless/SearchForm/SearchDatePicker';
import SearchEmpty from 'components/stateless/SearchForm/SearchEmpty';
import SearchInput from 'components/stateless/SearchForm/SearchInput';
import SearchLabel from 'components/stateless/SearchForm/SearchLabel';
import SearchSelect from 'components/stateless/SearchForm/SearchSelect';
import TableBody from 'components/stateless/Table/TableBody';
import TableBorder from 'components/stateless/Table/TableBorder';
import TableHeader from 'components/stateless/Table/TableHeader';
import type { HeaderType } from 'components/stateless/Table/TableHeader';
import Td from 'components/stateless/Table/Td';
import Tr from 'components/stateless/Table/Tr';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import getSelectOptions from 'constants/selectOptions';
import { ANCHOR_DEALER_STATUS } from 'enums';
import type { PageableType } from 'hooks/usePageable';
import type Pageable from 'models/Pageable';
import type { WaitingAnchorDealerVOModel } from 'models/vo/WaitingAnchorDealerVO';
import useModal from 'utils/modal/useModal';
import type { ColumnOption, ExportSpreadSheetProps } from 'utils/spreadSheet/types';
import { tableValueManage } from 'utils/valueManager/ValueManager';

interface AnchorDealerRegistrationListFailedTabProps {
  data: Pageable<WaitingAnchorDealerVOModel[]>;
  pageable: PageableType;
  paginate(page: number, sizePerPage: number): void;
  handleClickSearch: MouseEventHandler<HTMLButtonElement>;
  getExcelExportFailedList(): Promise<Pageable<WaitingAnchorDealerVOModel[]>>;
}
const PDF_EXPORT_MAX_ROW_COUNT = 150;
function AnchorDealerRegistrationListFailedTab({
  data,
  pageable,
  paginate,
  handleClickSearch,
  getExcelExportFailedList,
}: AnchorDealerRegistrationListFailedTabProps) {
  const { register, reset, control } = useFormContext();
  const { t } = useTranslation(['format']);
  const modal = useModal();

  const FAILED_TAB_TABLE_HEADERS: HeaderType[] = [
    {
      headerText: t('text:Registration_Date'),
    },
    {
      headerText: t('text:Dealer_Name'),
    },
    {
      headerText: t('text:Dealer_Tax_Code'),
    },
    {
      headerText: t('text:Legal_Representative_Name'),
    },
    {
      headerText: t('text:Legal_Representative_Email'),
    },
    {
      headerText: t('text:Telephone'),
    },
    {
      headerText: t('text:Registered_Office_Address'),
    },
    {
      headerText: t('text:Responsible_Financier_Name'),
    },
    {
      headerText: t('text:Reason_for_Failure'),
      colWidths: 200,
    },
  ];

  const onClickFailedViewExportButton = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();

    const excelExportFailedList = await getExcelExportFailedList();
    const getRegistrationConfirmedTableHeaders = (isPDFHeader = false) => {
      const headers = [
        {
          headerText: t('text:Registration_Date'),
          colWidths: 100,
        },
        {
          headerText: t('text:Dealer_Name'),
          colWidths: 100,
        },
        {
          headerText: t('text:Dealer_Tax_Code'),
          colWidths: 120,
        },
        {
          headerText: t('text:Legal_Representative_Name'),
          colWidths: 100,
        },
        {
          headerText: t('text:Legal_Representative_Email'),
          colWidths: 100,
        },
        {
          headerText: t('text:Telephone'),
          colWidths: 100,
        },
        {
          headerText: t('text:Registered_Office_Address'),
          colWidths: 100,
        },
        {
          headerText: t('text:Responsible_Financier_Name'),
          colWidths: 100,
        },
        {
          headerText: t('text:Reason_for_Failure'),
          colWidths: 100,
        },
      ];

      return isPDFHeader ? headers : [...headers, { headerText: '', colWidths: 50 }];
    };

    const CONFIRMED_VIEW_EXCEL_COLUMNS: ColumnOption<WaitingAnchorDealerVOModel>[] = [
      {
        header: t('text:Registration_Date'),
        key: 'createdDateTime',
        width: 25,
      },
      {
        header: t('text:Dealer_Name'),
        key: 'name',
        width: 20,
      },
      {
        header: t('text:Dealer_Tax_Code'),
        key: 'taxCode',
        width: 30,
      },

      {
        header: t('text:Legal_Representative_Name'),
        key: 'representativeName',
        width: 30,
      },
      {
        header: t('text:Legal_Representative_Email'),
        key: 'representativeEmail',
        width: 30,
      },
      {
        header: t('text:Telephone'),
        key: 'telephone',
        width: 20,
      },
      {
        header: t('text:Registered_Office_Address'),
        key: 'address',
        width: 30,
      },
      {
        header: t('text:Responsible_Financier_Name'),
        key: 'financierName',
        width: 30,
      },
      {
        header: t('text:Reason_for_Failure'),
        key: 'anchorDealerStatus',
        width: 30,
      },
    ];

    const renderPDFTableBodyResult = (data?: WaitingAnchorDealerVOModel[]) => {
      return data?.map((item, index) => (
        <tr key={index} className="virtual-table-row">
          <Td data={item.createdDateTime} format="datetime" />
          <Td data={item.name} />
          <Td data={item.taxCode} />
          <Td data={item.representativeName} />
          <Td data={item.representativeEmail} />
          <Td data={item.telephone} />
          <Td data={item.address} />
          <Td data={item.financierName} />
          <Td data={t(`code:anchor-dealer-status.${item?.anchorDealerStatus}`)} />
        </tr>
      ));
    };

    try {
      const jsonArrayData = excelExportFailedList.content.map(confirmedAnchorDealerData => ({
        createdDateTime: tableValueManage(
          confirmedAnchorDealerData.createdDateTime,
          t('format:datetime', {
            value: confirmedAnchorDealerData.createdDateTime,
            key: 'datetime',
          }),
        ),
        name: tableValueManage(confirmedAnchorDealerData.name),
        taxCode: tableValueManage(confirmedAnchorDealerData.taxCode),
        address: tableValueManage(confirmedAnchorDealerData.address),
        representativeName: tableValueManage(confirmedAnchorDealerData.representativeName),
        representativeEmail: tableValueManage(confirmedAnchorDealerData.representativeEmail),
        telephone: tableValueManage(confirmedAnchorDealerData.telephone),
        financierName: tableValueManage(confirmedAnchorDealerData.financierName),
        anchorDealerStatus: t(`code:anchor-dealer-status.${confirmedAnchorDealerData?.anchorDealerStatus}`),
      }));

      const excelExporterProps: ExportSpreadSheetProps<WaitingAnchorDealerVOModel> = {
        jsonArrayData: jsonArrayData,
        columns: CONFIRMED_VIEW_EXCEL_COLUMNS,
        options: {
          rowHeight: [{ position: 1, height: 30 }],
        },
      };

      const confirmedAnchorDealerPDFPage = excelExportFailedList.content.slice(0, PDF_EXPORT_MAX_ROW_COUNT);

      const pdfExporterProps = {
        tableHeaders: getRegistrationConfirmedTableHeaders(true),
        tableBody: renderPDFTableBodyResult(confirmedAnchorDealerPDFPage),
      };

      modal.show(<Exporter spreadSheetExporterProps={excelExporterProps} pdfExporterProps={pdfExporterProps} />, {
        title: t('text:Export_File'),
        closeBtnText: t('text:Close'),
      });
    } catch (error) {
      modal.show(error);
    }
  };

  return (
    <>
      <div className="content-area">
        <SectionTitle title={t('text:Search')}>
          <Button
            variant={ButtonVariantEnum.OUTLINED}
            color={ButtonColorEnum.SECONDARY}
            onClick={() => {
              reset({});
            }}
          >
            {t('text:Remove_Filter')}
          </Button>
        </SectionTitle>
        <form>
          <SearchBorder>
            <div className="row">
              <SearchLabel label={t('text:Registration_Date')} />
              <SearchDatePicker placeholder={t('text:from')} name="fromDate" control={control} />
              <SearchDatePicker placeholder={t('text:to')} name="toDate" control={control} />
              <SearchLabel label={t('text:Dealer_Name')} />
              <SearchInput name="name" ref={register} />
            </div>
            <div className="row">
              <SearchLabel label={t('text:Dealer_Tax_Code')} />
              <SearchInput name="taxCode" ref={register} />
              <SearchLabel label={t('text:Legal_Representative_Name')} />
              <SearchInput name="representativeName" ref={register} />
            </div>
            <div className="row">
              <SearchLabel label={t('text:Legal_Representative_Email')} />
              <SearchInput name="representativeEmail" ref={register} />
              <SearchLabel label={t('text:Telephone')} />
              <SearchInput name="telephone" ref={register} />
            </div>
            <div className="row">
              <SearchLabel label={t('text:Registered_Office_Address')} />
              <SearchInput name="address" ref={register} />
              <SearchLabel label={t('text:Responsible_Financier_Name')} />
              <SearchInput name="financierName" ref={register} />
            </div>
            <div className="row">
              <SearchLabel label={t('text:Reason_for_Failure')} />
              <SearchSelect
                name="anchorDealerStatuses"
                selectOptions={[
                  {
                    label: t('text:All'),
                    value: [
                      ANCHOR_DEALER_STATUS.ALREADY_REGISTERED_ANCHOR_DEALER,
                      ANCHOR_DEALER_STATUS.ON_APPROVAL_PROGRESS_ANCHOR_DEALER,
                    ],
                  },
                  {
                    label: t('code:anchor-dealer-status.ALREADY_REGISTERED_ANCHOR_DEALER'),
                    value: ANCHOR_DEALER_STATUS.ALREADY_REGISTERED_ANCHOR_DEALER,
                  },
                  {
                    label: t('code:anchor-dealer-status.ON_APPROVAL_PROGRESS_ANCHOR_DEALER'),
                    value: [ANCHOR_DEALER_STATUS.ON_APPROVAL_PROGRESS_ANCHOR_DEALER],
                  },
                ]}
                ref={register}
              />
              <SearchEmpty />
            </div>
          </SearchBorder>
          <div className="flex-center mt-4">
            <Button size={ButtonSizeEnum.LG} onClick={handleClickSearch}>
              {t('text:Search')}
            </Button>
          </div>
        </form>
      </div>
      <div className="division-border" />
      <div className="content-area">
        <SectionTitle title={t('text:Result')} />
        <div className="d-flex mb-2">
          <p className="total-data me-auto">
            {t('text:Total')} : {data.totalElements}
          </p>
          <Button onClick={onClickFailedViewExportButton} variant={ButtonVariantEnum.OUTLINED}>
            {t('text:Export')}
          </Button>
        </div>
        <TableBorder>
          <TableHeader header={FAILED_TAB_TABLE_HEADERS} />
          <TableBody numOfCol={FAILED_TAB_TABLE_HEADERS.length}>
            {data?.content?.map((item: WaitingAnchorDealerVOModel, index: number) => (
              <Tr key={index}>
                <Td data={item.createdDateTime} format="datetime" />
                <Td data={item.name} />
                <Td data={item.taxCode} />
                <Td data={item.representativeName} />
                <Td data={item.representativeEmail} />
                <Td data={item.telephone} />
                <Td data={item.address} />
                <Td data={item.financierName} />
                <Td data={t(`code:anchor-dealer-status.${item?.anchorDealerStatus}`)} />
              </Tr>
            ))}
          </TableBody>
        </TableBorder>
        <Pagination pageable={pageable} paginate={paginate} />
      </div>
    </>
  );
}

export default AnchorDealerRegistrationListFailedTab;
