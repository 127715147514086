import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { isEmpty } from 'lodash-es';

import Button, { ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import Exporter from 'components/stateless/Exporter/Exporter';
import StatusDescriptionModal from 'components/stateless/Modal/common/status/StatusDescriptionModal';
import Pagination from 'components/stateless/Pagination/Pagination';
import SearchBorder from 'components/stateless/SearchForm/SearchBorder';
import SearchDatePicker from 'components/stateless/SearchForm/SearchDatePicker';
import SearchInput from 'components/stateless/SearchForm/SearchInput';
import SearchLabel from 'components/stateless/SearchForm/SearchLabel';
import SearchSelect from 'components/stateless/SearchForm/SearchSelect';
import TableBody from 'components/stateless/Table/TableBody';
import TableBorder from 'components/stateless/Table/TableBorder';
import type { HeaderType } from 'components/stateless/Table/TableHeader';
import TableHeader from 'components/stateless/Table/TableHeader';
import Td from 'components/stateless/Table/Td';
import TdLink from 'components/stateless/Table/TdLink';
import Tr from 'components/stateless/Table/Tr';
import { HeaderTitle } from 'components/stateless/Title/HeaderTitle';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { ROUTES_FI } from 'constants/routes/financier';
import getSelectOptions from 'constants/selectOptions';
import type { COLLATERAL_TYPE, CURRENCY_TYPE } from 'enums';
import { FINANCIER_INTERFACE_TYPE, LOAN_APPROVAL_TYPE, LOAN_STATUS } from 'enums';
import useMounted from 'hooks/useMounted';
import usePageable from 'hooks/usePageable';
import type Pageable from 'models/Pageable';
import type { LoanVOModel } from 'models/vo/LoanVO';
import getStatusTextClass from 'utils/classNames/getStatusTextClass';
import type { PDFExporterProps } from 'utils/exportFile/exportPDF.d';
import { setFormValues } from 'utils/form/setFormValues';
import { requestFinancierLoanList } from 'utils/http/api/financier/loans';
import type { FinancierLoanListRequest } from 'utils/http/api/financier/loans/request';
import { ModalSize, ModalType } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';
import { getParsedSearchParams, updateSearchParams } from 'utils/searchParams';
import type { ColumnOption, ExportSpreadSheetProps } from 'utils/spreadSheet/types';
import { checkInterfaceType } from 'utils/storage/LocalStorage';
import { getFilteredDisbursedAmount, tableValueManage } from 'utils/valueManager/ValueManager';

const EXCEL_EXPORT_MAX_ROW_COUNT = 1000;
const PDF_EXPORT_MAX_ROW_COUNT = 150;

const LoanStatusCheckBoxOptions = [
  LOAN_STATUS.DEALER_REQUESTED,
  LOAN_STATUS.REJECTED,
  LOAN_STATUS.APPROVED,
  LOAN_STATUS.DISBURSED,
  LOAN_STATUS.REPAID,
  LOAN_STATUS.OVERDUE,
  LOAN_STATUS.EXPIRATION,
  LOAN_STATUS.RECOURSE_ACTIVATED,
];

const LoanApprovalTypeCheckBoxOptions = [
  LOAN_APPROVAL_TYPE.OPERATOR_APPROVAL,
  LOAN_APPROVAL_TYPE.AUTHORIZER_RETURNED_APPROVAL,
  LOAN_APPROVAL_TYPE.OPERATOR_REQUEST_REJECTED,
  LOAN_APPROVAL_TYPE.AUTHORIZER_RETURNED_REJECTED,
  LOAN_APPROVAL_TYPE.OPERATOR_OVERDUE,
  LOAN_APPROVAL_TYPE.AUTHORIZER_RETURNED_OVERDUE,
  LOAN_APPROVAL_TYPE.OPERATOR_REPAID,
  LOAN_APPROVAL_TYPE.AUTHORIZER_RETURNED_REPAID,
  LOAN_APPROVAL_TYPE.OPERATOR_CLEAR_OVERDUE,
  LOAN_APPROVAL_TYPE.AUTHORIZER_RETURNED_CLEAR_OVERDUE,
  LOAN_APPROVAL_TYPE.OPERATOR_EXPIRATION,
  LOAN_APPROVAL_TYPE.AUTHORIZER_RETURNED_EXPIRATION,
  LOAN_APPROVAL_TYPE.OPERATOR_REQUEST_CANCELLATION_DISBURSEMENT,
  LOAN_APPROVAL_TYPE.AUTHORIZER_RETURNED_CANCELLATION_DISBURSEMENT,
];

// 전문 연동인 경우 체크박스
const LoanApprovalTypeCheckBoxOptionsForInterface = [
  LOAN_APPROVAL_TYPE.OPERATOR_APPROVAL,
  LOAN_APPROVAL_TYPE.AUTHORIZER_RETURNED_APPROVAL,
  LOAN_APPROVAL_TYPE.OPERATOR_EXPIRATION,
  LOAN_APPROVAL_TYPE.AUTHORIZER_RETURNED_EXPIRATION,
];

const NUM_OF_CHECKBOX_LOAN_STATUS = LoanStatusCheckBoxOptions.length; // 체크박스 옵션 개수
const NUM_OF_CHECKBOX_LOAN_APPROVAL_TYPE = LoanApprovalTypeCheckBoxOptions.length; // 체크박스 옵션 개수
const NUM_OF_CHECKBOX_LOAN_APPROVAL_TYPE_INTERFACE = LoanApprovalTypeCheckBoxOptionsForInterface.length;

const FI_FINANCING_APPROVAL_LIST_QS_KEY = 'fi-financing-approval-list';

function FinancierFinancingApprovalList() {
  const { t } = useTranslation(['format']);
  const modal = useModal();
  const mounted = useMounted();

  const [loanInterface] = useState<boolean>(checkInterfaceType(FINANCIER_INTERFACE_TYPE.LOAN_RESULT)); // 전문 연동

  const [FinancingPage, setFinancingPage] = useState<Pageable<LoanVOModel[]>>();
  const { pageable, setPageable } = usePageable(FI_FINANCING_APPROVAL_LIST_QS_KEY);

  const { register, reset, getValues, setValue, watch, control } = useForm<FinancierLoanListRequest>();

  const { loanStatusConditions: loanStatusWatchCheckbox, loanApprovalConditions: LoanApprovalTypeWatchCheckbox } =
    watch();

  useEffect(() => {
    if (mounted) {
      setDefaultSearchValue();
      fetchFinancierLoanList(pageable.currentPage, pageable.sizePerPage, getValues());
    }

    return () => {};
  }, [mounted]);

  const setDefaultSearchValue = () => {
    const defaultSearchValue: FinancierLoanListRequest = {
      loanStatusConditions: loanInterface
        ? [LOAN_STATUS.DEALER_REQUESTED, LOAN_STATUS.APPROVED, LOAN_STATUS.OVERDUE]
        : [LOAN_STATUS.DEALER_REQUESTED, LOAN_STATUS.APPROVED, LOAN_STATUS.DISBURSED, LOAN_STATUS.OVERDUE],
    };
    setFormValues<FinancierLoanListRequest>(
      setValue,
      isEmpty(getParsedSearchParams(FI_FINANCING_APPROVAL_LIST_QS_KEY).formSearchData)
        ? defaultSearchValue
        : getParsedSearchParams(FI_FINANCING_APPROVAL_LIST_QS_KEY).formSearchData,
    );
  };

  async function fetchFinancierLoanList(
    pageNumber: number = 1,
    sizePerPage: number = 10,
    searchData: FinancierLoanListRequest = {},
  ) {
    try {
      const loanList = await requestFinancierLoanList(pageNumber, sizePerPage, searchData);
      updateSearchParams(
        {
          ...searchData,
          pageNumber,
          rowCount: sizePerPage,
        },
        FI_FINANCING_APPROVAL_LIST_QS_KEY,
      );

      ReactDOM.unstable_batchedUpdates(() => {
        setFinancingPage(loanList);
        setPageable(loanList);
      });
    } catch (error) {
      modal.show(error);
    }
  }

  const handleCheckAllLoanStatus = (e: any) => {
    if (e.target.checked) {
      setValue('loanStatusConditions', LoanStatusCheckBoxOptions);
    } else {
      setValue('loanStatusConditions', []);
    }
  };

  const handleCheckAllLoanApprovalType = (e: any) => {
    if (e.target.checked) {
      setValue('loanApprovalConditions', LoanApprovalTypeCheckBoxOptions);
    } else {
      setValue('loanApprovalConditions', []);
    }
  };

  const onRemoveFilter = (e: any) => {
    e.preventDefault();
    reset({});
  };

  const onSearchSubmit = async () => {
    const data = getValues();
    await fetchFinancierLoanList(1, pageable.sizePerPage, data);
  };

  const paginate = async (page: number, sizePerPage: number) => {
    await fetchFinancierLoanList(
      page,
      sizePerPage,
      getParsedSearchParams(FI_FINANCING_APPROVAL_LIST_QS_KEY).formSearchData,
    );
  };

  const onClickFinancingStatus = (): void => {
    modal.show(
      <StatusDescriptionModal
        statusDescriptionEnum={Object.values(LOAN_STATUS)}
        statusDescriptionModalType="TEXT"
        statusDescriptionEnumType="LOAN_STATUS"
      />,
      {
        modalSize: ModalSize.XL,
        modalType: ModalType.ALERT,
        closeBtnText: t('text:Close'),
      },
    );
  };

  const onClickFinancingApprovalStatus = (): void => {
    modal.show(
      <StatusDescriptionModal
        statusDescriptionEnum={
          loanInterface
            ? [
                LOAN_APPROVAL_TYPE.OPERATOR_APPROVAL,
                LOAN_APPROVAL_TYPE.AUTHORIZER_RETURNED_APPROVAL,
                LOAN_APPROVAL_TYPE.OPERATOR_EXPIRATION,
                LOAN_APPROVAL_TYPE.AUTHORIZER_RETURNED_EXPIRATION,
              ]
            : [
                LOAN_APPROVAL_TYPE.OPERATOR_APPROVAL,
                LOAN_APPROVAL_TYPE.AUTHORIZER_RETURNED_APPROVAL,
                LOAN_APPROVAL_TYPE.OPERATOR_REQUEST_REJECTED,
                LOAN_APPROVAL_TYPE.AUTHORIZER_RETURNED_REJECTED,
                LOAN_APPROVAL_TYPE.OPERATOR_REPAID,
                LOAN_APPROVAL_TYPE.AUTHORIZER_RETURNED_REPAID,
                LOAN_APPROVAL_TYPE.OPERATOR_OVERDUE,
                LOAN_APPROVAL_TYPE.AUTHORIZER_RETURNED_OVERDUE,
                LOAN_APPROVAL_TYPE.OPERATOR_EXPIRATION,
                LOAN_APPROVAL_TYPE.AUTHORIZER_RETURNED_EXPIRATION,
                LOAN_APPROVAL_TYPE.OPERATOR_CLEAR_OVERDUE,
                LOAN_APPROVAL_TYPE.AUTHORIZER_RETURNED_CLEAR_OVERDUE,
                LOAN_APPROVAL_TYPE.OPERATOR_REQUEST_CANCELLATION_DISBURSEMENT,
                LOAN_APPROVAL_TYPE.AUTHORIZER_RETURNED_CANCELLATION_DISBURSEMENT,
              ]
        }
        statusDescriptionModalType="TEXT"
        statusDescriptionEnumType="LOAN_APPROVAL_TYPE"
      />,
      {
        modalSize: ModalSize.XL,
        modalType: ModalType.ALERT,
        closeBtnText: t('text:Close'),
      },
    );
  };

  const renderCheckBoxInterface = () => {
    return (
      <div className="checkbox-form">
        <div className="form-check-inline">
          <input
            className="form-check-input"
            type="checkbox"
            value=""
            id="allCheck2"
            onChange={handleCheckAllLoanApprovalType}
            checked={LoanApprovalTypeWatchCheckbox?.length === NUM_OF_CHECKBOX_LOAN_APPROVAL_TYPE_INTERFACE}
          />
          <label className="form-check-label" htmlFor="allCheck2">
            {t('text:All')}
          </label>
        </div>
        {LoanApprovalTypeCheckBoxOptionsForInterface?.map((checkBoxValue, index) => {
          return (
            <div className="form-check-inline" key={index}>
              <input
                className="form-check-input"
                type="checkbox"
                value={checkBoxValue}
                id={`flexCheckDefault2${index}`}
                name="loanApprovalConditions"
                ref={register}
              />
              <label className="form-check-label" htmlFor={`flexCheckDefault2${index}`}>
                {t(`code:approval-status.${LoanApprovalTypeCheckBoxOptionsForInterface[index]}`)}
              </label>
            </div>
          );
        })}
      </div>
    );
  };

  const onClickExportButton = async (e: any) => {
    e.preventDefault();
    const searchData = getParsedSearchParams(FI_FINANCING_APPROVAL_LIST_QS_KEY).formSearchData;
    const excelColumns: ColumnOption<LoanVOModel>[] = [
      {
        header: t('text:Program_Type'),
        key: 'collateralType',
        width: 30,
      },
      {
        header: t('text:Platform_Financing_ID'),
        key: 'loanId',
        width: 30,
      },
      {
        header: t('text:Financier_Financing_ID'),
        key: 'financierLoanId',
        width: 35,
      },
      {
        header: t('text:Invoice_Number'),
        key: 'invoiceNumber',
        width: 25,
      },
      {
        header: t('text:Invoice_Reference_Number'),
        key: 'referenceNumber',
        width: 30,
      },
      {
        header: t('text:AR_Number'),
        key: 'arNumber',
        width: 20,
      },
      {
        header: t('text:Currency'),
        key: 'currencyType',
        width: 13,
      },
      {
        header: t('text:Partner_Name'),
        key: 'dealerName',
        width: 15,
      },
      {
        header: t('text:Partner_Client_Code'),
        key: 'dealerClientCode',
        width: 25,
      },
      {
        header: t('text:Associated_Anchor_Name'),
        key: 'anchorClientName',
        width: 30,
      },
      {
        header: t('text:Associated_Anchor_Code'),
        key: 'anchorClientCode',
        width: 30,
      },
      {
        header: t('text:Requested_Financing_Amount'),
        key: 'principalAmount',
        width: 25,
        style: {
          alignment: { horizontal: 'right' },
        },
      },
      {
        header: t('text:Financing_Amount'),
        key: 'disbursedAmount',
        width: 25,
        style: {
          alignment: { horizontal: 'right' },
        },
      },
      {
        header: t('text:Advance_Disbursement'),
        key: 'netDisbursementAmount',
        width: 25,
        style: {
          alignment: { horizontal: 'right' },
        },
      },
      {
        header: t('text:Applied_Date'),
        key: 'requestedDateTime',
        width: 20,
      },
      {
        header: t('text:Scheduled_Disbursement_Date'),
        key: 'desiredDisburseDate',
        width: 35,
      },
      {
        header: t('text:Scheduled_Repayment_Date'),
        key: 'repaymentDate',
        width: 30,
      },
      {
        header: t('text:Repaid_Date'),
        key: 'repaidDate',
        width: 15,
      },
      {
        header: t('text:Financing_Status'),
        key: 'loanStatus',
        width: 20,
      },
      {
        header: t('text:Approval_Status'),
        key: 'loanApprovalType',
        width: 35,
      },
    ];

    const pdfTableHeaders: HeaderType[] = [
      {
        headerText: t('text:Program_Type'),
        colWidths: 100,
      },
      {
        headerText: t('text:Platform_Financing_ID'),
        colWidths: 100,
      },
      {
        headerText: t('text:Financier_Financing_ID'),
        colWidths: 100,
      },
      {
        headerText: t('text:Invoice_Number'),
        colWidths: 100,
      },
      {
        headerText: t('text:Invoice_Reference_Number'),
        colWidths: 100,
      },
      {
        headerText: t('text:AR_Number'),
        colWidths: 100,
      },
      {
        headerText: t('text:Currency'),
        colWidths: 96,
      },
      {
        headerText: t('text:Partner_Name'),
        colWidths: 180,
      },
      {
        headerText: t('text:Partner_Client_Code'),
        colWidths: 100,
      },
      {
        headerText: t('text:Associated_Anchor_Name'),
        colWidths: 180,
      },
      {
        headerText: t('text:Associated_Anchor_Client_Code'),
        colWidths: 100,
      },
      {
        headerText: t('text:Requested_Financing_Amount'),
        colWidths: 120,
      },
      {
        headerText: t('text:Financing_Amount'),
        colWidths: 120,
      },
      {
        headerText: t('text:Advance_Disbursement'),
        colWidths: 120,
      },
      {
        headerText: t('text:Applied_Date'),
        colWidths: 120,
      },
      {
        headerText: t('text:Scheduled_Disbursement_Date'),
        colWidths: 120,
      },
      {
        headerText: t('text:Scheduled_Repayment_Date'),
        colWidths: 120,
      },
      {
        headerText: t('text:Repaid_Date'),
        colWidths: 120,
      },
      {
        headerText: t('text:Financing_Status'),
        colWidths: 90,
      },
      {
        headerText: t('text:Approval_Status'),
        colWidths: 140,
      },
    ];

    const renderPDFTableBodyResult = (data?: LoanVOModel[]): JSX.Element[] | undefined => {
      return data?.map((item, index) => (
        <tr key={index} className="virtual-table-row">
          <Td data={t(`code:collateral-type.${item?.collateralType}`)} format="code" />
          <Td data={item.loanId} />
          <Td data={item.financierLoanId} />
          <Td data={item.invoiceNumber} />
          <Td data={item.referenceNumber} />
          <Td data={item.arNumber} />
          <Td data={item.currencyType} />
          <Td data={item.dealerClientName} />
          <Td data={item.dealerClientCode} />
          <Td data={item.anchorClientName} />
          <Td data={item.anchorClientCode} />
          <Td data={item.principalAmount} format="number" />
          <Td data={getFilteredDisbursedAmount(item.loanStatus, item.disbursedAmount)} format="number" />
          <Td data={item.netDisbursementAmount} format="number" />
          <Td data={item.requestedDateTime} format="date" />
          <Td data={item.desiredDisburseDate} format="date" />
          <Td data={item.repaymentDate} format="date" />
          <Td data={item.repaidDate} format="date" />
          <Td
            data={t(`code:financing-status.${item.loanStatus}`)}
            className={getStatusTextClass('LOAN_STATUS', item.loanStatus)}
            format="code"
          />
          <Td
            data={t(`code:approval-status.${item.loanApprovalType}`)}
            className={getStatusTextClass('LOAN_APPROVAL_TYPE', item.loanApprovalType)}
            format="code"
          />
        </tr>
      ));
    };

    try {
      const [fiLoanResponse, fiLoanPDFResponse] = await Promise.all([
        requestFinancierLoanList(0, EXCEL_EXPORT_MAX_ROW_COUNT, searchData),
        requestFinancierLoanList(0, PDF_EXPORT_MAX_ROW_COUNT, searchData),
      ]);
      const jsonArrayData: any[] = fiLoanResponse.content.map(loanData => ({
        collateralType: tableValueManage(t(`code:collateral-type.${loanData.collateralType}`)),
        loanId: tableValueManage(loanData.loanId),
        financierLoanId: tableValueManage(loanData.financierLoanId),
        invoiceNumber: tableValueManage(loanData.invoiceNumber),
        referenceNumber: tableValueManage(loanData.referenceNumber),
        arNumber: tableValueManage(loanData.arNumber),
        currencyType: tableValueManage(loanData.currencyType),
        dealerName: tableValueManage(loanData.dealerName),
        dealerClientCode: tableValueManage(loanData.dealerClientCode),
        anchorClientName: tableValueManage(loanData.anchorClientName),
        anchorClientCode: tableValueManage(loanData.anchorClientCode),
        principalAmount: tableValueManage(loanData.principalAmount),
        disbursedAmount: tableValueManage(getFilteredDisbursedAmount(loanData.loanStatus, loanData.disbursedAmount)),
        netDisbursementAmount: tableValueManage(loanData.netDisbursementAmount),
        requestedDateTime: t('format:original-date', {
          value: loanData.requestedDateTime,
          key: 'original-date',
        }),
        desiredDisburseDate: t('format:original-date', {
          value: loanData.desiredDisburseDate,
          key: 'original-date',
        }),
        repaymentDate: t('format:original-date', {
          value: loanData.repaymentDate,
          key: 'original-date',
        }),
        repaidDate: t('format:original-date', {
          value: loanData.repaidDate,
          key: 'original-date',
        }),
        loanStatus: tableValueManage(loanData.loanStatus, t(`code:financing-status.${loanData.loanStatus}`)),
        loanApprovalType: tableValueManage(
          loanData.loanApprovalType,
          t(`code:approval-status.${loanData.loanApprovalType}`),
        ),
      }));

      const excelExporterProps: ExportSpreadSheetProps<any> = {
        jsonArrayData: jsonArrayData,
        columns: excelColumns,
        options: {
          rowHeight: [{ position: 1, height: 30 }],
        },
      };

      const pdfExporterProps: PDFExporterProps = {
        tableHeaders: pdfTableHeaders,
        tableBody: renderPDFTableBodyResult(fiLoanPDFResponse.content),
      };

      modal.show(<Exporter spreadSheetExporterProps={excelExporterProps} pdfExporterProps={pdfExporterProps} />, {
        title: t('text:Export_File'),
        closeBtnText: t('text:Close'),
      });
    } catch (e) {
      modal.show(e);
    }
  };

  const tableHeaders: HeaderType[] = [
    {
      headerText: t('text:Program_Type'),
      colWidths: 100,
    },
    {
      headerText: t('text:Platform_Financing_ID'),
      colWidths: 100,
    },
    {
      headerText: t('text:Financier_Financing_ID'),
      colWidths: 100,
    },
    {
      headerText: t('text:Invoice_Number'),
      colWidths: 100,
    },
    {
      headerText: t('text:Invoice_Reference_Number'),
      colWidths: 100,
    },
    {
      headerText: t('text:AR_Number'),
      colWidths: 100,
    },
    {
      headerText: t('text:Currency'),
      colWidths: 96,
    },
    {
      headerText: t('text:Partner_Name'),
      colWidths: 180,
    },
    {
      headerText: t('text:Partner_Client_Code'),
      colWidths: 100,
    },
    {
      headerText: t('text:Associated_Anchor_Name'),
      colWidths: 180,
    },
    {
      headerText: t('text:Associated_Anchor_Client_Code'),
      colWidths: 100,
    },
    {
      headerText: t('text:Requested_Financing_Amount'),
      colWidths: 120,
    },
    {
      headerText: t('text:Financing_Amount'),
      colWidths: 120,
    },
    {
      headerText: t('text:Advance_Disbursement'),
      colWidths: 120,
    },
    {
      headerText: t('text:Applied_Date'),
      colWidths: 120,
    },
    {
      headerText: t('text:Scheduled_Disbursement_Date'),
      colWidths: 120,
    },
    {
      headerText: t('text:Scheduled_Repayment_Date'),
      colWidths: 120,
    },
    {
      headerText: t('text:Repaid_Date'),
      colWidths: 120,
    },
    {
      headerText: t('text:Financing_Status'),
      colWidths: 90,
      hasStatusDescription: true,
      showStatusDescriptionFunc: onClickFinancingStatus,
    },
    {
      headerText: t('text:Approval_Status'),
      colWidths: 140,
      hasStatusDescription: true,
      showStatusDescriptionFunc: onClickFinancingApprovalStatus,
    },
    {
      headerText: '',
      colWidths: 50,
    },
  ];

  const renderResultTable = () => {
    return FinancingPage?.content.map((item, i) => {
      return (
        <Tr key={i}>
          <Td data={t(`code:collateral-type.${item?.collateralType}`)} format="code" />
          <Td data={item.loanId} />
          <Td data={item.financierLoanId} />
          <Td data={item.invoiceNumber} />
          <Td data={item.referenceNumber} />
          <Td data={item.arNumber} />
          <Td data={item.currencyType} />
          <Td data={item.dealerClientName} />
          <Td data={item.dealerClientCode} />
          <Td data={item.anchorClientName} />
          <Td data={item.anchorClientCode} />
          <Td data={item.principalAmount} format="number" />
          <Td data={getFilteredDisbursedAmount(item.loanStatus, item.disbursedAmount)} format="number" />
          <Td data={item.netDisbursementAmount} format="number" />
          <Td data={item.requestedDateTime} format="date" />
          <Td data={item.desiredDisburseDate} format="date" />
          <Td data={item.repaymentDate} format="date" />
          <Td data={item.repaidDate} format="date" />
          <Td
            data={t(`code:financing-status.${item.loanStatus}`)}
            className={getStatusTextClass('LOAN_STATUS', item.loanStatus)}
            format="code"
          />
          <Td
            data={t(`code:approval-status.${item.loanApprovalType}`)}
            className={getStatusTextClass('LOAN_APPROVAL_TYPE', item.loanApprovalType)}
            format="code"
          />
          <TdLink path={ROUTES_FI.MANAGE_FINANCING.APPROVAL_DETAIL_BUILD_PATH(item.loanId)} />
        </Tr>
      );
    });
  };

  return (
    <>
      <HeaderTitle title={t('text:Financing_Approval')} />
      <div className="content-area">
        <form>
          <SectionTitle title={t('text:Search')}>
            <Button variant={ButtonVariantEnum.OUTLINED} color={ButtonColorEnum.SECONDARY} onClick={onRemoveFilter}>
              {t('text:Remove_Filter')}
            </Button>
          </SectionTitle>
          <SearchBorder>
            <div className="row">
              <SearchLabel label={t('text:Platform_Financing_ID')} />
              <SearchInput name="loanId" ref={register} />
              <SearchLabel label={t('text:Financier_Financing_ID')} />
              <SearchInput name="financierLoanId" ref={register} />
            </div>
            <div className="row">
              <SearchLabel label={t('text:Invoice_Number')} />
              <SearchInput name="invoiceNumber" ref={register} />
              <SearchLabel label={t('text:Invoice_Reference_Number')} />
              <SearchInput name="referenceNumber" ref={register} />
            </div>
            <div className="row">
              <SearchLabel label={t('text:AR_Number')} />
              <SearchInput name="arNumber" ref={register} />
              <SearchLabel label={t('text:Currency')} />
              <SearchSelect
                selectOptions={getSelectOptions<CURRENCY_TYPE>('CURRENCY_TYPE', 'ALL', true)}
                name="currencyType"
                ref={register}
              />
            </div>
            <div className="row">
              <SearchLabel label={t('text:Partner_Name')} />
              <SearchInput name="dealerClientName" ref={register} />
              <SearchLabel label={t('text:Partner_Client_Code')} />
              <SearchInput name="dealerClientCode" ref={register} />
            </div>
            <div className="row">
              <SearchLabel label={t('text:Associated_Anchor_Name')} />
              <SearchInput name="anchorClientName" ref={register} />
              <SearchLabel label={t('text:Associated_Anchor_Code')} />
              <SearchInput name="anchorClientCode" ref={register} />
            </div>
            <div className="row">
              <SearchLabel label={t('text:Applied_Date')} />
              <SearchDatePicker placeholder={t('text:from')} name="requestedDateFrom" control={control} />
              <SearchDatePicker placeholder={t('text:to')} name="requestedDateTo" control={control} />
              <SearchLabel label={t('text:Scheduled_Repayment_Date')} />
              <SearchDatePicker placeholder={t('text:from')} name="repaymentDateFrom" control={control} />
              <SearchDatePicker placeholder={t('text:to')} name="repaymentDateTo" control={control} />
            </div>
            <div className="row">
              <SearchLabel label={t('text:Repaid_Date')} />
              <SearchDatePicker placeholder={t('text:from')} name="repaidDateFrom" control={control} />
              <SearchDatePicker placeholder={t('text:to')} name="repaidDateTo" control={control} />
              <SearchLabel label={t('text:Program_Type')} />
              <SearchSelect
                name="collateralType"
                selectOptions={getSelectOptions<COLLATERAL_TYPE>('COLLATERAL_TYPE', 'ALL', true)}
                ref={register}
              />
            </div>
            <div className="row">
              <SearchLabel
                className="search-form__label-wrap auto-height col-2"
                label={t('text:Financing_Status')}
                hasStatusDescription={true}
                showStatusDescriptionFunc={onClickFinancingStatus}
              />
              <div className="search-form__item__input col-10">
                <div className="checkbox-form">
                  <div className="form-check-inline">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                      id="allCheck"
                      onChange={handleCheckAllLoanStatus}
                      checked={loanStatusWatchCheckbox?.length === NUM_OF_CHECKBOX_LOAN_STATUS}
                    />
                    <label className="form-check-label" htmlFor="financingStatusAll">
                      {t('text:All')}
                    </label>
                  </div>
                  {LoanStatusCheckBoxOptions?.map((checkBoxValue, index) => {
                    return (
                      <div className="form-check-inline" key={index}>
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value={checkBoxValue}
                          id={`flexCheckDefault${index}`}
                          name="loanStatusConditions"
                          ref={register}
                        />
                        <label className="form-check-label" htmlFor={`flexCheckDefault${index}`}>
                          {t(`code:financing-status.${LoanStatusCheckBoxOptions[index]}`)}
                        </label>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="row">
              <SearchLabel
                className="search-form__label-wrap auto-height col-2"
                label={t('text:Approval_Status')}
                hasStatusDescription={true}
                showStatusDescriptionFunc={onClickFinancingApprovalStatus}
              />
              <div className="search-form__item__input col-10">
                {loanInterface ? (
                  renderCheckBoxInterface()
                ) : (
                  <div className="checkbox-form">
                    <div className="form-check-inline">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value=""
                        id="allCheck2"
                        onChange={handleCheckAllLoanApprovalType}
                        checked={LoanApprovalTypeWatchCheckbox?.length === NUM_OF_CHECKBOX_LOAN_APPROVAL_TYPE}
                      />
                      <label className="form-check-label" htmlFor="allCheck2">
                        {t('text:All')}
                      </label>
                    </div>
                    {LoanApprovalTypeCheckBoxOptions?.map((checkBoxValue, index) => {
                      return (
                        <div className="form-check-inline" key={index}>
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value={checkBoxValue}
                            id={`flexCheckDefault${index + NUM_OF_CHECKBOX_LOAN_STATUS}`}
                            name="loanApprovalConditions"
                            ref={register}
                          />
                          <label
                            className="form-check-label"
                            htmlFor={`flexCheckDefault${index + NUM_OF_CHECKBOX_LOAN_STATUS}`}
                          >
                            {t(`code:approval-status.${LoanApprovalTypeCheckBoxOptions[index]}`)}
                          </label>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            </div>
          </SearchBorder>
          <div className="flex-center mt-4">
            <Button size={ButtonSizeEnum.LG} onClick={onSearchSubmit}>
              {t('text:Search')}
            </Button>
          </div>
        </form>
      </div>
      <div className="division-border" />
      <div className="content-area">
        <SectionTitle title={t('text:Results')} />
        <div className="d-flex mb-2">
          <p className="total-data me-auto">
            {t('text:Total')} : {FinancingPage?.totalElements}
          </p>
          <Button onClick={onClickExportButton} variant={ButtonVariantEnum.OUTLINED}>
            {t('text:Export')}
          </Button>
        </div>
        <TableBorder>
          <TableHeader header={tableHeaders} />
          <TableBody numOfCol={tableHeaders.length}>{renderResultTable()}</TableBody>
        </TableBorder>
        <Pagination pageable={pageable} paginate={paginate} />
      </div>
    </>
  );
}

export default FinancierFinancingApprovalList;
