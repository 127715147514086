import type { Dispatch, SetStateAction } from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { intersectionBy } from 'lodash-es';

import Button, { ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import Pagination from 'components/stateless/Pagination/Pagination';
import SearchBorder from 'components/stateless/SearchForm/SearchBorder';
import SearchEmpty from 'components/stateless/SearchForm/SearchEmpty';
import SearchInput from 'components/stateless/SearchForm/SearchInput';
import SearchLabel from 'components/stateless/SearchForm/SearchLabel';
import SearchSelect from 'components/stateless/SearchForm/SearchSelect';
import type { SelectOptionType } from 'components/stateless/Select/Select';
import { TableBody, TableBorder, TableHeader, Td, TdLink, Tr } from 'components/stateless/Table';
import type { HeaderType } from 'components/stateless/Table/TableHeader';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { ROUTES_AC } from 'constants/routes/anchor';
import getSelectOptions from 'constants/selectOptions';
import type { CURRENCY_TYPE } from 'enums';
import { AUTHORITY_TYPE, COMMON_APPROVAL_TYPE, ENTERPRISE_TYPE } from 'enums';
import type { PageableType } from 'hooks/usePageable';
import useProperty from 'hooks/useProperty';
import type Pageable from 'models/Pageable';
import type { WaitingAnchorPartnerVOModel } from 'models/vo/WaitingAnchorPartnerVO';
import getStatusTextClass from 'utils/classNames/getStatusTextClass';
import type { WaitingAnchorPartnerPageRequest } from 'utils/http/api/anchor/waiting-anchor-partners/requests';
import { WAITING_COMMON_APPROVAL_TYPE_ALL_VALUES } from 'utils/logic/setApprovalTypesValue';
import { getSignIn } from 'utils/storage/LocalStorage';

import type { RenderManagerOption } from '../AnchorPartnerRegistrationList';

type WaitingViewProps = {
  waitingViewStates: {
    waitingAnchorPartnerPage?: Pageable<WaitingAnchorPartnerVOModel[]>;
    waitingAnchorPartnerForTabCount?: Pageable<WaitingAnchorPartnerVOModel[]>;
    checkedWaitingAnchorPartner: CheckBoxWaitingAnchorPartnerVOModel[];
    anchorRelatedFinancierNames: SelectOptionType[];
    waitingAnchorPartnerPageable: PageableType;
    setCheckedWaitingAnchorPartner: Dispatch<SetStateAction<CheckBoxWaitingAnchorPartnerVOModel[]>>;
  };
  waitingViewUtils: {
    renderManager: (option: RenderManagerOption, item: WaitingAnchorPartnerVOModel) => boolean;
    waitingPaginate: (page: number, sizePerPage: number) => Promise<void>;
    onClickConfirm: () => Promise<void>;
    onClickRevert: () => void;
    onClickCancelRequest: () => void;
    onClickWaitingViewSearch: (e: any) => Promise<void>;
    onChangeCheckbox: (e: any, item: WaitingAnchorPartnerVOModel) => Promise<void>;
    onClickAllCheckbox: (e: any) => Promise<void>;
  };
};

interface CheckBoxWaitingAnchorPartnerVOModel extends WaitingAnchorPartnerVOModel {
  disabled?: boolean;
  pageNumber?: number;
  sizePerPage?: number;
  itemIndex?: number;
}

const WaitingView = ({ waitingViewStates, waitingViewUtils }: WaitingViewProps) => {
  const { t } = useTranslation();
  const getWaitingAnchorPartnerProperty = useProperty<WaitingAnchorPartnerPageRequest>();
  const authorityType = getSignIn()?.authorityType;

  const [isAllChecked, setIsAllChecked] = useState<boolean>(false);

  const { reset, register } = useFormContext();

  const {
    checkedWaitingAnchorPartner,
    waitingAnchorPartnerPageable,
    waitingAnchorPartnerPage,
    anchorRelatedFinancierNames,
    setCheckedWaitingAnchorPartner,
  } = waitingViewStates;

  const {
    waitingPaginate,
    onClickConfirm,
    onClickRevert,
    onClickCancelRequest,
    onClickWaitingViewSearch,
    onChangeCheckbox,
    onClickAllCheckbox,
    renderManager,
  } = waitingViewUtils;

  const availAbleCheckedList = useCallback(() => {
    return waitingAnchorPartnerPage?.content.filter(item => {
      return (
        renderManager(
          {
            targetAuthorityType: undefined,
            targetApprovalType: [COMMON_APPROVAL_TYPE.OPERATOR_REQUEST],
          },
          item,
        ) && item.operatorEnterpriseType === ENTERPRISE_TYPE.AC
      );
    });
  }, [waitingAnchorPartnerPage]);

  useEffect(() => {
    setCheckedWaitingAnchorPartner([]);
  }, [waitingAnchorPartnerPageable.currentPage]);

  useEffect(() => {
    const newCheckedWaitingAnchorPartner = intersectionBy(
      availAbleCheckedList(),
      checkedWaitingAnchorPartner,
      'waitingAnchorPartnerId',
    );
    setCheckedWaitingAnchorPartner(newCheckedWaitingAnchorPartner);
  }, [waitingAnchorPartnerPageable.sizePerPage]);

  useEffect(() => {
    if (availAbleCheckedList()?.length === 0 || availAbleCheckedList()?.length !== checkedWaitingAnchorPartner.length) {
      setIsAllChecked(false);
    } else {
      setIsAllChecked(true);
    }
  }, [checkedWaitingAnchorPartner]);

  const renderButtons = (): JSX.Element | null => {
    if (authorityType === AUTHORITY_TYPE.ADMIN) {
      return (
        <div className="flex-end mt-3">
          <Button
            size={ButtonSizeEnum.LG}
            onClick={onClickRevert}
            disabled={!checkedWaitingAnchorPartner.length}
            variant={ButtonVariantEnum.OUTLINED}
            color={ButtonColorEnum.RED}
            className="me-2"
          >
            {t('text:Revert')}
          </Button>
          <Button size={ButtonSizeEnum.LG} onClick={onClickConfirm} disabled={!checkedWaitingAnchorPartner.length}>
            {t('text:Confirm')}
          </Button>
        </div>
      );
    } else if (authorityType === AUTHORITY_TYPE.HQ_OPERATOR) {
      return (
        <Button
          size={ButtonSizeEnum.LG}
          onClick={onClickCancelRequest}
          disabled={!(authorityType === AUTHORITY_TYPE.HQ_OPERATOR)}
          variant={ButtonVariantEnum.OUTLINED}
          color={ButtonColorEnum.SECONDARY}
        >
          {t('text:Cancel_Request')}
        </Button>
      );
    } else {
      return null;
    }
  };

  const renderWaitingForConfirmationSearchTable = (): JSX.Element => {
    const onClickRemoveFilter = (e: any) => {
      e.preventDefault();

      reset({});
    };

    return (
      <form>
        <SectionTitle title={t('text:Search')}>
          <Button variant={ButtonVariantEnum.OUTLINED} color={ButtonColorEnum.SECONDARY} onClick={onClickRemoveFilter}>
            {t('text:Remove_Filter')}
          </Button>
        </SectionTitle>
        <SearchBorder>
          <div className="row">
            <SearchLabel label={t('text:Partner_Name')} />
            <SearchInput name={getWaitingAnchorPartnerProperty('partnerName')} ref={register} />
            <SearchLabel label={t('text:Partner_Tax_Code')} />
            <SearchInput name={getWaitingAnchorPartnerProperty('partnerTaxCode')} ref={register} />
          </div>
          <div className="row">
            <SearchLabel label={t('text:Associated_Master_Agreement_of_Anchor_Number')} />
            <SearchInput name={getWaitingAnchorPartnerProperty('anchorAgreementNo')} ref={register} />
            <SearchLabel label={t('text:Currency')} />
            <SearchSelect
              selectOptions={getSelectOptions<CURRENCY_TYPE>('CURRENCY_TYPE', 'ALL', true)}
              name={getWaitingAnchorPartnerProperty('currencyType')}
              ref={register}
            />
          </div>
          <div className="row">
            <SearchLabel label={t('text:Responsible_Financier_Name')} />
            <SearchSelect
              name={getWaitingAnchorPartnerProperty('responsibleFinancierName')}
              selectOptions={anchorRelatedFinancierNames}
              ref={register}
            />
            <SearchLabel label={t('text:Responsible_Branch_Name')} />
            <SearchInput name={getWaitingAnchorPartnerProperty('responsibleBranchName')} ref={register} />
          </div>
          <div className="row">
            <SearchLabel label={t('text:Bank_Account_Validity')} />
            <SearchSelect
              selectOptions={getSelectOptions<string>('BOOLEAN', 'ALL', true)}
              name={getWaitingAnchorPartnerProperty('hasBankCode')}
              ref={register}
            />
            <SearchLabel label={t('text:Registered_By')} />
            <SearchSelect
              selectOptions={getSelectOptions<ENTERPRISE_TYPE>(
                'ENTERPRISE_TYPE',
                [ENTERPRISE_TYPE.AC, ENTERPRISE_TYPE.FI],
                true,
              )}
              name={getWaitingAnchorPartnerProperty('enterpriseType')}
              ref={register}
            />
          </div>
          <div className="row">
            <SearchLabel label={t('text:Registration_Status')} />
            <SearchSelect
              selectOptions={[
                {
                  label: t('text:All'),
                  value: WAITING_COMMON_APPROVAL_TYPE_ALL_VALUES,
                },
                {
                  label: t('text:Requested'),
                  value: COMMON_APPROVAL_TYPE.OPERATOR_REQUEST,
                },
                {
                  label: t('text:Reverted'),
                  value: COMMON_APPROVAL_TYPE.AUTHORIZER_RETURNED,
                },
                {
                  label: t('text:Cancelled'),
                  value: COMMON_APPROVAL_TYPE.OPERATOR_CANCEL,
                },
              ]}
              name={getWaitingAnchorPartnerProperty('approvalTypes')}
              ref={register}
            />
            <SearchEmpty />
          </div>
        </SearchBorder>
        <div className="flex-center mt-3">
          <Button size={ButtonSizeEnum.LG} onClick={onClickWaitingViewSearch}>
            {t('text:Search')}
          </Button>
        </div>
      </form>
    );
  };

  const renderWaitingForConfirmationTable = (): JSX.Element => {
    const renderWaitingResultTable = (): JSX.Element[] | undefined => {
      const getEnterpriseTypeText = (type: ENTERPRISE_TYPE): string => {
        switch (type) {
          case ENTERPRISE_TYPE.AC:
            return t('text:Anchor');
          case ENTERPRISE_TYPE.DE:
            return t('text:Dealer');
          case ENTERPRISE_TYPE.FI:
            return t('text:Financier');
          case ENTERPRISE_TYPE.SY:
            return t('text:System');
          default:
            return '';
        }
      };

      const getApprovalTypeText = (type: COMMON_APPROVAL_TYPE): string => {
        switch (type) {
          case COMMON_APPROVAL_TYPE.OPERATOR_REQUEST:
            return t('text:Requested');
          case COMMON_APPROVAL_TYPE.OPERATOR_CANCEL:
            return t('text:Cancelled');
          case COMMON_APPROVAL_TYPE.AUTHORIZER_APPROVED:
            return t('text:Approved');
          case COMMON_APPROVAL_TYPE.AUTHORIZER_RETURNED:
            return t('text:Reverted');
          default:
            return '';
        }
      };

      return waitingAnchorPartnerPage?.content.map((item, index) => (
        <Tr key={index}>
          <Td>
            {renderManager(
              {
                targetAuthorityType: undefined,
                targetApprovalType: [COMMON_APPROVAL_TYPE.OPERATOR_REQUEST],
              },
              item,
            ) && item.operatorEnterpriseType === ENTERPRISE_TYPE.AC ? (
              <div className="text-center">
                <input
                  className="form-check-input m-0"
                  type="checkbox"
                  onChange={e => onChangeCheckbox(e, item)}
                  checked={checkedWaitingAnchorPartner
                    .map(data => data.waitingAnchorPartnerId)
                    .includes(item.waitingAnchorPartnerId)}
                />
              </div>
            ) : (
              <></>
            )}
          </Td>
          <Td data={item.name} />
          <Td data={item.partnerTaxCode} />
          <Td data={item.anchorAgreementContractNo} />
          <Td className="text-center" data={item.currencyType} />
          <Td data={item.responsibleFinancierName} />
          <Td data={item.responsibleBranchName} />
          <Td data={item.accountBankCodeId ? t('text:Yes') : t('text:No')} />
          <Td data={getEnterpriseTypeText(item.operatorEnterpriseType)} />
          <Td
            className={getStatusTextClass('COMMON_APPROVAL_TYPE', item.approvalType)}
            data={getApprovalTypeText(item.approvalType)}
          />
          <TdLink
            path={ROUTES_AC.MANAGE_PARTNER.REGISTRATION_WAITING_DETAIL_BUILD_PATH(
              item.waitingAnchorPartnerId,
              item.responsibleFinancierId,
            )}
          />
        </Tr>
      ));
    };

    const WAITING_FOR_CONFIRMATION_TABLE_HEADERS: HeaderType[] = [
      {
        headerText: '',
        className: 'text-center',
        colWidths: 50,
        hasCheckBox: true,
        handleClickAllCheckBox: onClickAllCheckbox,
        checkBoxId: String(waitingAnchorPartnerPageable.currentPage),
        isAllChecked,
      },
      {
        headerText: t('text:Partner_Name'),
        colWidths: 100,
      },
      {
        headerText: t('text:Partner_Tax_Code'),
        colWidths: 120,
      },
      {
        headerText: t('text:Associated_Master_Agreement_of_Anchor_Number'),
        colWidths: 120,
      },
      {
        headerText: t('text:Currency'),
        colWidths: 100,
      },
      {
        headerText: t('text:Responsible_Financier_Name'),
        colWidths: 100,
      },
      {
        headerText: t('text:Responsible_Branch_Name'),
        colWidths: 100,
      },
      {
        headerText: t('text:Bank_Account_Validity'),
        colWidths: 80,
      },
      {
        headerText: t('text:Registered_By'),
        colWidths: 80,
      },
      {
        headerText: t('text:Registration_Status'),
        colWidths: 100,
      },
      {
        headerText: '',
        colWidths: 50,
      },
    ];

    return (
      <>
        <SectionTitle title={t('text:Result')} />
        <p className="total-data">
          {t('text:Total')} {waitingAnchorPartnerPage?.totalElements ? waitingAnchorPartnerPage?.totalElements : '0'}
        </p>
        <TableBorder>
          <TableHeader header={WAITING_FOR_CONFIRMATION_TABLE_HEADERS} />
          <TableBody numOfCol={WAITING_FOR_CONFIRMATION_TABLE_HEADERS.length}>{renderWaitingResultTable()}</TableBody>
        </TableBorder>
        <Pagination pageable={waitingAnchorPartnerPageable} paginate={waitingPaginate} />
      </>
    );
  };

  return (
    <>
      <div className="content-area">{renderWaitingForConfirmationSearchTable()}</div>
      <div className="division-border" />
      <div className="content-area">{renderWaitingForConfirmationTable()}</div>
      <div className="content-area clearfix">{renderButtons()}</div>
    </>
  );
};

export default WaitingView;
