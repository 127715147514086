import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Button, { ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import GuideMessage, { MessageType } from 'components/stateless/GuideMessage/GuideMessage';
import Pagination from 'components/stateless/Pagination/Pagination';
import SearchBorder from 'components/stateless/SearchForm/SearchBorder';
import SearchEmpty from 'components/stateless/SearchForm/SearchEmpty';
import SearchInput from 'components/stateless/SearchForm/SearchInput';
import SearchLabel from 'components/stateless/SearchForm/SearchLabel';
import SearchSelect from 'components/stateless/SearchForm/SearchSelect';
import Tab from 'components/stateless/TabManager/Tab';
import TableBody from 'components/stateless/Table/TableBody';
import TableBorder from 'components/stateless/Table/TableBorder';
import type { HeaderType } from 'components/stateless/Table/TableHeader';
import TableHeader from 'components/stateless/Table/TableHeader';
import Td from 'components/stateless/Table/Td';
import TdLink from 'components/stateless/Table/TdLink';
import Tr from 'components/stateless/Table/Tr';
import { HeaderTitle } from 'components/stateless/Title/HeaderTitle';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { ROUTES_FI } from 'constants/routes/financier';
import getSelectOptions from 'constants/selectOptions';
import type { CURRENCY_TYPE } from 'enums';
import { AUTHORITY_TYPE } from 'enums';
import useMounted from 'hooks/useMounted';
import usePageable from 'hooks/usePageable';
import type Pageable from 'models/Pageable';
import type { SignInModel } from 'models/SignInModel';
import type { AnchorPartnerAccountVOModel } from 'models/vo/AnchorPartnerAccountVO';
import AssignBranchModal from 'pages/financier/manage-uploaded-partner/assignment/modal/AssignBranchModal';
import { setFormValues } from 'utils/form/setFormValues';
import { requestFiAnchorPartnerAccountList } from 'utils/http/api/financier/anchor-partner-accounts';
import type { AnchorPartnerAccountListRequest } from 'utils/http/api/financier/anchor-partner-accounts/requests';
import { ModalSize, ModalType } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';
import {
  addSearchParams,
  getParsedSearchParams,
  makeSearchParamsPattern,
  updateSearchParams,
} from 'utils/searchParams';
import { getSignIn } from 'utils/storage/LocalStorage';

const getConstant = () => {
  const { t } = useTranslation(['format']);
  const FI_POTENTIAL_PARTNER_ASSIGNMENT_TAB1_LIST_QS_KEY = 'fi-pp-waiting-assignment-list';
  const FI_POTENTIAL_PARTNER_ASSIGNMENT_TAB2_LIST_QS_KEY = 'fi-pp-assigned-list';

  const NOT_ADMIN_TABLE_HEADERS: HeaderType[] = [
    {
      headerText: t('text:Uploaded_Partner_Name'),
      colWidths: 100,
    },
    {
      headerText: t('text:Uploaded_Partner_Tax_Code'),
      colWidths: 120,
    },
    {
      headerText: t('text:Associated_Anchor_Name'),
      colWidths: 100,
    },
    {
      headerText: t('text:Associated_Master_Agreement_of_Anchor_Number'),
      colWidths: 120,
    },
    {
      headerText: t('text:Currency'),
      colWidths: 100,
    },
    {
      headerText: t('text:Anchor_Agreement_Branch_Code'),
      colWidths: 100,
    },
    {
      headerText: t('text:Anchor_Agreement_Branch_Name'),
      colWidths: 100,
    },
    {
      headerText: '',
      colWidths: 50,
    },
  ];

  const WAITING_FOR_CONFIRMATION_TABLE_HEADERS: HeaderType[] = [
    {
      headerText: '',
      colWidths: 50,
    },
    {
      headerText: t('text:Uploaded_Partner_Name'),
      colWidths: 100,
    },
    {
      headerText: t('text:Uploaded_Partner_Tax_Code'),
      colWidths: 120,
    },
    {
      headerText: t('text:Associated_Anchor_Name'),
      colWidths: 100,
    },
    {
      headerText: t('text:Associated_Master_Agreement_of_Anchor_Number'),
      colWidths: 120,
    },
    {
      headerText: t('text:Currency'),
      colWidths: 100,
    },
    {
      headerText: t('text:Anchor_Agreement_Branch_Code'),
      colWidths: 100,
    },
    {
      headerText: t('text:Anchor_Agreement_Branch_Name'),
      colWidths: 100,
    },
    {
      headerText: t('text:Bank_Account_Validity'),
      colWidths: 80,
    },
    {
      headerText: '',
      colWidths: 50,
    },
  ];

  const ASSIGNED_TABLE_HEADERS: HeaderType[] = [
    {
      headerText: t('text:Uploaded_Partner_Name'),
      colWidths: 100,
    },
    {
      headerText: t('text:Uploaded_Partner_Tax_Code'),
      colWidths: 120,
    },
    {
      headerText: t('text:Assigned_Branch_Code'),
      colWidths: 120,
    },
    {
      headerText: t('text:Assigned_Branch_Name'),
      colWidths: 120,
    },
    {
      headerText: t('text:Associated_Anchor_Name'),
      colWidths: 100,
    },
    {
      headerText: t('text:Associated_Master_Agreement_of_Anchor_Number'),
      colWidths: 120,
    },
    {
      headerText: t('text:Currency'),
      colWidths: 100,
    },
    {
      headerText: t('text:Anchor_Agreement_Branch_Code'),
      colWidths: 100,
    },
    {
      headerText: t('text:Anchor_Agreement_Branch_Name'),
      colWidths: 120,
    },
    {
      headerText: t('text:Declined_Status'),
      colWidths: 80,
    },
    {
      headerText: '',
      colWidths: 50,
    },
  ];

  return {
    WAITING_FOR_CONFIRMATION_TABLE_HEADERS,
    ASSIGNED_TABLE_HEADERS,
    NOT_ADMIN_TABLE_HEADERS,
    FI_POTENTIAL_PARTNER_ASSIGNMENT_TAB1_LIST_QS_KEY,
    FI_POTENTIAL_PARTNER_ASSIGNMENT_TAB2_LIST_QS_KEY,
  };
};

function FinancierPotentialSupplyChainPartnerAssignmentList() {
  const {
    WAITING_FOR_CONFIRMATION_TABLE_HEADERS,
    ASSIGNED_TABLE_HEADERS,
    NOT_ADMIN_TABLE_HEADERS,
    FI_POTENTIAL_PARTNER_ASSIGNMENT_TAB1_LIST_QS_KEY,
    FI_POTENTIAL_PARTNER_ASSIGNMENT_TAB2_LIST_QS_KEY,
  } = getConstant();
  const mounted = useMounted();
  const { t } = useTranslation();
  const modal = useModal();
  const signInData: SignInModel | null = getSignIn();
  const isAdmin =
    signInData?.authorityType === AUTHORITY_TYPE.ADMIN || signInData?.authorityType === AUTHORITY_TYPE.HQ_OPERATOR;

  const tabOneSearchFormData = useForm<AnchorPartnerAccountListRequest>();
  const tabTwoSearchFormData = useForm<AnchorPartnerAccountListRequest>();

  const [waitingAnchorPartnerAccountPageData, setWaitingAnchorPartnerAccountPageData] =
    useState<Pageable<AnchorPartnerAccountVOModel[]>>();
  const [anchorPartnerAccountPageData, setAnchorPartnerAccountPageData] =
    useState<Pageable<AnchorPartnerAccountVOModel[]>>();
  const [checkedWaitingAnchorPartnerAccountData, setCheckedWaitingAnchorPartnerAccountData] = useState<
    AnchorPartnerAccountVOModel[]
  >([]);

  const { pageable: waitingPageable, setPageable: setWaitingPageable } = usePageable(
    FI_POTENTIAL_PARTNER_ASSIGNMENT_TAB1_LIST_QS_KEY,
  );
  const { pageable, setPageable } = usePageable(FI_POTENTIAL_PARTNER_ASSIGNMENT_TAB2_LIST_QS_KEY);

  useEffect(() => {
    if (mounted) {
      fetchAll();
    }
  }, [mounted]);

  const fetchAll = async (): Promise<void> => {
    setFormValues<AnchorPartnerAccountListRequest>(
      tabOneSearchFormData.setValue,
      getParsedSearchParams(FI_POTENTIAL_PARTNER_ASSIGNMENT_TAB1_LIST_QS_KEY).formSearchData,
    );
    setFormValues<AnchorPartnerAccountListRequest>(
      tabTwoSearchFormData.setValue,
      getParsedSearchParams(FI_POTENTIAL_PARTNER_ASSIGNMENT_TAB2_LIST_QS_KEY).formSearchData,
    );

    const addFiPotentialSCPartnerAssignmentListQs = () => {
      const waitingQs = makeSearchParamsPattern(
        {
          ...tabOneSearchFormData.getValues(),
          pageNumber: waitingPageable.currentPage,
          rowCount: waitingPageable.sizePerPage,
        },
        FI_POTENTIAL_PARTNER_ASSIGNMENT_TAB1_LIST_QS_KEY,
      );
      const completedQs = makeSearchParamsPattern(
        {
          ...tabTwoSearchFormData.getValues(),
          pageNumber: pageable.currentPage,
          rowCount: pageable.sizePerPage,
        },
        FI_POTENTIAL_PARTNER_ASSIGNMENT_TAB2_LIST_QS_KEY,
      );
      addSearchParams(waitingQs + completedQs);
    };
    addFiPotentialSCPartnerAssignmentListQs();

    const waitingTabSearchData = tabOneSearchFormData.getValues();
    const assignedTabSearchData = tabTwoSearchFormData.getValues();

    try {
      const [waitingAnchorPartnerAccountPageData, anchorPartnerAccountPageData] = await Promise.all(
        isAdmin
          ? [
              requestFiAnchorPartnerAccountList(waitingPageable.currentPage, waitingPageable.sizePerPage, {
                ...waitingTabSearchData,
                assigned: false,
              }),
              requestFiAnchorPartnerAccountList(pageable.currentPage, pageable.sizePerPage, {
                ...assignedTabSearchData,
                assigned: true,
              }),
            ]
          : [
              requestFiAnchorPartnerAccountList(waitingPageable.currentPage, waitingPageable.sizePerPage, {
                ...waitingTabSearchData,
                assigned: true,
                declined: false,
              }),
              requestFiAnchorPartnerAccountList(pageable.currentPage, pageable.sizePerPage, {
                ...assignedTabSearchData,
                assigned: true,
                declined: true,
              }),
            ],
      );

      ReactDOM.unstable_batchedUpdates(() => {
        setWaitingAnchorPartnerAccountPageData(waitingAnchorPartnerAccountPageData);
        setWaitingPageable(waitingAnchorPartnerAccountPageData);
        setAnchorPartnerAccountPageData(anchorPartnerAccountPageData);
        setPageable(anchorPartnerAccountPageData);
      });
    } catch (error) {
      modal.show(error);
    }
  };

  const renderGuideMessage = (guideMessage: string[]): JSX.Element => {
    return <GuideMessage messageType={MessageType.BASIC} message={guideMessage} />;
  };

  const fetchFiAnchorPartnerAccountList = async (
    pageNumber: number = 1,
    rowCount: number = 10,
    searchData: AnchorPartnerAccountListRequest,
    tabType: 'inProgress' | 'declined' | 'waiting' | 'assigned',
  ): Promise<void> => {
    const isTab1Type = tabType === 'waiting' || tabType === 'inProgress';

    try {
      const defaultSearchValue = {
        inProgress: {
          assigned: true,
          declined: false,
        },
        declined: {
          assigned: true,
          declined: true,
        },
        waiting: {
          assigned: false,
        },
        assigned: {
          assigned: true,
        },
      };

      const searchValue: AnchorPartnerAccountListRequest = {
        ...searchData,
        ...defaultSearchValue[tabType],
      };

      const response = await requestFiAnchorPartnerAccountList(pageNumber, rowCount, searchValue);
      updateSearchParams(
        {
          ...searchValue,
          pageNumber,
          rowCount,
        },
        isTab1Type
          ? FI_POTENTIAL_PARTNER_ASSIGNMENT_TAB1_LIST_QS_KEY
          : FI_POTENTIAL_PARTNER_ASSIGNMENT_TAB2_LIST_QS_KEY,
      );

      ReactDOM.unstable_batchedUpdates(() => {
        if (isTab1Type) {
          setWaitingAnchorPartnerAccountPageData(response);
          setWaitingPageable(response);
        } else {
          setAnchorPartnerAccountPageData(response);
          setPageable(response);
        }
      });
    } catch (error) {
      modal.show(error);
    }
  };

  const renderAssignmentListPage = (): JSX.Element => {
    const getGuideMessage = (): string[] => {
      return [
        t(
          'text:The_list_of_Uploaded_Partners_that_have_been_assigned_by_the_Financier_Admin_for_acquisition_is_shown_below',
        ),
        t('text:Click_on_the_arrow_button_to_view_detailed_information_of_the_Uploaded_Partners'),
      ];
    };

    const renderInProgressView = (): JSX.Element => {
      const onClickRemoveFilter = (e: any): void => {
        e.preventDefault();

        tabOneSearchFormData.reset();
      };

      const onClickSearch = (e: any): void => {
        e.preventDefault();
        fetchFiAnchorPartnerAccountList(1, pageable.sizePerPage, tabOneSearchFormData.getValues(), 'inProgress');
      };

      return (
        <>
          <div className="content-area">
            <form>
              <SectionTitle title={t('text:Search')}>
                <Button
                  variant={ButtonVariantEnum.OUTLINED}
                  color={ButtonColorEnum.SECONDARY}
                  onClick={onClickRemoveFilter}
                >
                  {t('text:Remove_Filter')}
                </Button>
              </SectionTitle>
              <SearchBorder>
                <div className="row">
                  <SearchLabel label={t('text:Uploaded_Partner_Name')} />
                  <SearchInput name="anchorPartnerName" ref={tabOneSearchFormData.register} />
                  <SearchLabel label={t('text:Uploaded_Partner_Tax_Code')} />
                  <SearchInput name="anchorPartnerTaxCode" ref={tabOneSearchFormData.register} />
                </div>
                <div className="row">
                  <SearchLabel label={t('text:Associated_Anchor_Name')} />
                  <SearchInput name="anchorName" ref={tabOneSearchFormData.register} />
                  <SearchLabel label={t('text:Associated_Master_Agreement_of_Anchor_Number')} />
                  <SearchInput name="anchorAgreementNo" ref={tabOneSearchFormData.register} />
                </div>
                <div className="row">
                  <SearchLabel label={t('text:Currency')} />
                  <SearchSelect
                    selectOptions={getSelectOptions<CURRENCY_TYPE>('CURRENCY_TYPE', 'ALL', true)}
                    name="currencyType"
                    ref={tabOneSearchFormData.register}
                  />
                  <SearchLabel label={t('text:Anchor_Agreement_Branch_Code')} />
                  <SearchInput name="responsibleBranchCode" ref={tabOneSearchFormData.register} />
                </div>
                <div className="row">
                  <SearchLabel label={t('text:Anchor_Agreement_Branch_Name')} />
                  <SearchInput name="responsibleBranchName" ref={tabOneSearchFormData.register} />
                  <SearchEmpty />
                </div>
              </SearchBorder>
              <div className="flex-center mt-4">
                <Button size={ButtonSizeEnum.LG} onClick={onClickSearch}>
                  {t('text:Search')}
                </Button>
              </div>
            </form>
          </div>
          <div className="division-border" />
          <div className="content-area">
            <SectionTitle title={t('text:Result')} />
            <p className="total-data">
              {t('text:Total')} {waitingAnchorPartnerAccountPageData?.totalElements}
            </p>
            <TableBorder>
              <TableHeader header={NOT_ADMIN_TABLE_HEADERS} />
              <TableBody numOfCol={NOT_ADMIN_TABLE_HEADERS.length}>
                {waitingAnchorPartnerAccountPageData?.content.map((item, index) => (
                  <Tr key={index}>
                    <Td data={item.partnerName} />
                    <Td data={item.partnerTaxCode} />
                    <Td data={item.anchorName} />
                    <Td data={item.anchorAgreementContractNo} />
                    <Td data={item.currencyType} />
                    <Td data={item.responsibleBranchCode} />
                    <Td data={item.responsibleBranchName} />
                    <TdLink
                      path={ROUTES_FI.MANAGE_UPLOADED_PARTNER.ASSIGNMENT_DETAIL_BUILD_PATH(item.anchorPartnerAccountId)}
                    />
                  </Tr>
                ))}
              </TableBody>
            </TableBorder>
            <Pagination pageable={waitingPageable} paginate={paginateInProgress} />
          </div>
        </>
      );
    };

    const renderDeclinedView = (): JSX.Element => {
      const onClickRemoveFilter = (e: any): void => {
        e.preventDefault();

        tabTwoSearchFormData.reset();
      };

      const onClickSearch = (e: any): void => {
        e.preventDefault();

        fetchFiAnchorPartnerAccountList(1, pageable.sizePerPage, tabTwoSearchFormData.getValues(), 'declined');
      };

      return (
        <>
          <div className="content-area">
            <form>
              <SectionTitle title={t('text:Search')}>
                <Button
                  variant={ButtonVariantEnum.OUTLINED}
                  color={ButtonColorEnum.SECONDARY}
                  onClick={onClickRemoveFilter}
                >
                  {t('text:Remove_Filter')}
                </Button>
              </SectionTitle>
              <SearchBorder>
                <div className="row">
                  <SearchLabel label={t('text:Uploaded_Partner_Name')} />
                  <SearchInput name="anchorPartnerName" ref={tabTwoSearchFormData.register} />
                  <SearchLabel label={t('text:Uploaded_Partner_Tax_Code')} />
                  <SearchInput name="anchorPartnerTaxCode" ref={tabTwoSearchFormData.register} />
                </div>
                <div className="row">
                  <SearchLabel label={t('text:Associated_Anchor_Name')} />
                  <SearchInput name="anchorName" ref={tabTwoSearchFormData.register} />
                  <SearchLabel label={t('text:Associated_Master_Agreement_of_Anchor_Number')} />
                  <SearchInput name="anchorAgreementNo" ref={tabTwoSearchFormData.register} />
                </div>
                <div className="row">
                  <SearchLabel label={t('text:Currency')} />
                  <SearchSelect
                    selectOptions={getSelectOptions<CURRENCY_TYPE>('CURRENCY_TYPE', 'ALL', true)}
                    name="currencyType"
                    ref={tabTwoSearchFormData.register}
                  />
                  <SearchLabel label={t('text:Anchor_Agreement_Branch_Code')} />
                  <SearchInput name="responsibleBranchCode" ref={tabTwoSearchFormData.register} />
                </div>
                <div className="row">
                  <SearchLabel label={t('text:Anchor_Agreement_Branch_Name')} />
                  <SearchInput name="responsibleBranchName" ref={tabTwoSearchFormData.register} />
                  <SearchEmpty />
                </div>
              </SearchBorder>
              <div className="flex-center mt-4">
                <Button size={ButtonSizeEnum.LG} onClick={onClickSearch}>
                  {t('text:Search')}
                </Button>
              </div>
            </form>
          </div>
          <div className="division-border" />
          <div className="content-area">
            <SectionTitle title={t('text:Result')} />
            <p className="total-data">
              {t('text:Total')} {anchorPartnerAccountPageData?.totalElements}
            </p>
            <TableBorder>
              <TableHeader header={NOT_ADMIN_TABLE_HEADERS} />
              <TableBody numOfCol={NOT_ADMIN_TABLE_HEADERS.length}>
                {anchorPartnerAccountPageData?.content.map((item, index) => (
                  <Tr key={index}>
                    <Td data={item.partnerName} />
                    <Td data={item.partnerTaxCode} />
                    <Td data={item.anchorName} />
                    <Td data={item.anchorAgreementContractNo} />
                    <Td data={item.currencyType} />
                    <Td data={item.responsibleBranchCode} />
                    <Td data={item.responsibleBranchName} />
                    <TdLink
                      path={ROUTES_FI.MANAGE_UPLOADED_PARTNER.ASSIGNMENT_DETAIL_BUILD_PATH(item.anchorPartnerAccountId)}
                    />
                  </Tr>
                ))}
              </TableBody>
            </TableBorder>
            <Pagination pageable={pageable} paginate={paginateDeclined} />
          </div>
        </>
      );
    };

    const paginateInProgress = (pageNumber: number, rowCount: number) => {
      fetchFiAnchorPartnerAccountList(
        pageNumber,
        rowCount,
        getParsedSearchParams(FI_POTENTIAL_PARTNER_ASSIGNMENT_TAB1_LIST_QS_KEY).formSearchData,
        'inProgress',
      );
    };

    const paginateDeclined = (pageNumber: number, rowCount: number) => {
      fetchFiAnchorPartnerAccountList(
        pageNumber,
        rowCount,
        getParsedSearchParams(FI_POTENTIAL_PARTNER_ASSIGNMENT_TAB2_LIST_QS_KEY).formSearchData,
        'declined',
      );
    };

    return (
      <>
        {renderGuideMessage(getGuideMessage())}
        <Tab
          tabType="approvalStatus"
          tabList={[
            { tabName: t('text:In_Progress'), tabItemCount: waitingAnchorPartnerAccountPageData?.totalElements ?? 0 },
            { tabName: t('text:Declined'), tabItemCount: anchorPartnerAccountPageData?.totalElements ?? 0 },
          ]}
          tabViewList={[renderInProgressView(), renderDeclinedView()]}
        />
      </>
    );
  };

  const renderAdminAssignmentListPage = (): JSX.Element => {
    const getGuideMessage = (): string[] => {
      return [
        t(
          'text:Please_assign_the_Uploaded_Partners_below_to_branches_in_order_to_manage_and_operate_individual_Uploaded',
        ),
        t('text:Each_Uploaded_Partner_may_only_be_assigned_if_their_bank_account_information_is_valid'),
        t(
          'text:To_assign_multiple_Uploaded_Partners_at_once_select_the_companies_you_wish_to_assign_and_click_on_the_Assign_Branch_button',
        ),
        t('text:Please_click_on_the_arrow_button_to_see_detailed_information_of_the_Uploaded_Partners'),
      ];
    };

    const onClickAssignBranch = (e: any): void => {
      e.preventDefault();

      const refreshFunction = () => {
        setCheckedWaitingAnchorPartnerAccountData([]);
        fetchAll();
      };

      modal.show(
        <AssignBranchModal
          currentModalId={modal.id}
          anchorPartnerAccountIds={checkedWaitingAnchorPartnerAccountData.map(data => data.anchorPartnerAccountId)}
          refreshFunction={refreshFunction}
        />,
        {
          title: t('text:Branch_Assignment'),
          modalType: ModalType.ALERT,
          modalSize: ModalSize.XL,
          closeBtnText: t(`text:Close`),
        },
      );
    };

    const renderWaitingForAssignmentView = (): JSX.Element => {
      const onClickRemoveFilter = (e: any): void => {
        e.preventDefault();

        tabOneSearchFormData.reset();
      };

      const onClickSearch = (e: any): void => {
        e.preventDefault();

        fetchFiAnchorPartnerAccountList(0, waitingPageable.sizePerPage, tabOneSearchFormData.getValues(), 'waiting');
      };

      const onChangeCheckbox = (e: any, item: AnchorPartnerAccountVOModel): void => {
        if (e.target.checked) {
          setCheckedWaitingAnchorPartnerAccountData(prev => {
            const newArr = [...prev];

            newArr.push(item);

            return newArr;
          });
        } else {
          setCheckedWaitingAnchorPartnerAccountData(prev => {
            const newArr = [...prev];

            newArr.splice(prev.map(data => data.anchorPartnerAccountId).indexOf(item.anchorPartnerAccountId), 1);

            return newArr;
          });
        }
      };

      return (
        <>
          <div className="content-area">
            <form>
              <SectionTitle title={t('text:Search')}>
                <Button
                  variant={ButtonVariantEnum.OUTLINED}
                  color={ButtonColorEnum.SECONDARY}
                  onClick={onClickRemoveFilter}
                >
                  {t('text:Remove_Filter')}
                </Button>
              </SectionTitle>
              <SearchBorder>
                <div className="row">
                  <SearchLabel label={t('text:Uploaded_Partner_Name')} />
                  <SearchInput name="anchorPartnerName" ref={tabOneSearchFormData.register} />
                  <SearchLabel label={t('text:Uploaded_Partner_Tax_Code')} />
                  <SearchInput name="anchorPartnerTaxCode" ref={tabOneSearchFormData.register} />
                </div>
                <div className="row">
                  <SearchLabel label={t('text:Associated_Anchor_Name')} />
                  <SearchInput name="anchorName" ref={tabOneSearchFormData.register} />
                  <SearchLabel label={t('text:Associated_Master_Agreement_of_Anchor_Number')} />
                  <SearchInput name="anchorAgreementNo" ref={tabOneSearchFormData.register} />
                </div>
                <div className="row">
                  <SearchLabel label={t('text:Currency')} />
                  <SearchSelect
                    selectOptions={getSelectOptions<CURRENCY_TYPE>('CURRENCY_TYPE', 'ALL', true)}
                    name="currencyType"
                    ref={tabOneSearchFormData.register}
                  />
                  <SearchLabel label={t('text:Anchor_Agreement_Branch_Code')} />
                  <SearchInput name="responsibleBranchCode" ref={tabOneSearchFormData.register} />
                </div>
                <div className="row">
                  <SearchLabel label={t('text:Anchor_Agreement_Branch_Name')} />
                  <SearchInput name="responsibleBranchName" ref={tabOneSearchFormData.register} />
                  <SearchLabel label={t('text:Bank_Account_Validity')} />
                  <SearchSelect
                    name="hasBankCode"
                    ref={tabOneSearchFormData.register}
                    selectOptions={getSelectOptions<string>('BOOLEAN', 'ALL', true)}
                  />
                </div>
              </SearchBorder>
              <div className="flex-center mt-4">
                <Button size={ButtonSizeEnum.LG} onClick={onClickSearch}>
                  {t('text:Search')}
                </Button>
              </div>
            </form>
          </div>
          <div className="division-border" />
          <div className="content-area">
            <SectionTitle title={t('text:Result')} />
            <p className="total-data">
              {t('text:Total')} {waitingAnchorPartnerAccountPageData?.totalElements ?? 0}
            </p>
            <TableBorder>
              <TableHeader header={WAITING_FOR_CONFIRMATION_TABLE_HEADERS} />
              <TableBody numOfCol={WAITING_FOR_CONFIRMATION_TABLE_HEADERS?.length}>
                {waitingAnchorPartnerAccountPageData?.content.map((item, index) => (
                  <Tr key={index}>
                    <Td>
                      <div className="text-center">
                        <input
                          className="form-check-input m-0"
                          type="checkbox"
                          onChange={e => onChangeCheckbox(e, item)}
                          checked={checkedWaitingAnchorPartnerAccountData
                            .map(item => item.anchorPartnerAccountId)
                            .includes(item.anchorPartnerAccountId)}
                          disabled={
                            item.accountBankCodeId === null ||
                            item.accountBankCodeId === undefined ||
                            item.accountBankCodeId === ('' as any)
                          }
                        />
                      </div>
                    </Td>
                    <Td data={item.partnerName} />
                    <Td data={item.partnerTaxCode} />
                    <Td data={item.anchorName} />
                    <Td data={item.anchorAgreementContractNo} />
                    <Td data={item.currencyType} />
                    <Td data={item.responsibleBranchCode} />
                    <Td data={item.responsibleBranchName} />
                    <Td data={item.accountBankCodeId ? 'Y' : 'N'} />
                    <TdLink
                      path={ROUTES_FI.MANAGE_UPLOADED_PARTNER.ASSIGNMENT_DETAIL_BUILD_PATH(item.anchorPartnerAccountId)}
                    />
                  </Tr>
                ))}
              </TableBody>
            </TableBorder>
            <Pagination pageable={waitingPageable} paginate={paginateWaitingForAssignment} />
          </div>
          <div className="content-area flex-end">
            <Button
              size={ButtonSizeEnum.LG}
              onClick={onClickAssignBranch}
              disabled={checkedWaitingAnchorPartnerAccountData.length === 0}
            >
              {t('text:Assign_Branch')}
            </Button>
          </div>
        </>
      );
    };

    const renderAssignedForm = (): JSX.Element => {
      const onClickRemoveFilter = (e: any): void => {
        e.preventDefault();

        tabTwoSearchFormData.reset();
      };

      const onClickSearch = (e: any): void => {
        e.preventDefault();
        fetchFiAnchorPartnerAccountList(0, pageable.sizePerPage, tabTwoSearchFormData.getValues(), 'assigned');
      };

      return (
        <>
          <div className="content-area">
            <form>
              <SectionTitle title={t('text:Search')}>
                <Button
                  variant={ButtonVariantEnum.OUTLINED}
                  color={ButtonColorEnum.SECONDARY}
                  onClick={onClickRemoveFilter}
                >
                  {t('text:Remove_Filter')}
                </Button>
              </SectionTitle>
              <SearchBorder>
                <div className="row">
                  <SearchLabel label={t('text:Uploaded_Partner_Name')} />
                  <SearchInput name="anchorPartnerName" ref={tabTwoSearchFormData.register} />
                  <SearchLabel label={t('text:Uploaded_Partner_Tax_Code')} />
                  <SearchInput name="anchorPartnerTaxCode" ref={tabTwoSearchFormData.register} />
                </div>
                <div className="row">
                  <SearchLabel label={t('text:Assigned_Branch_Code')} />
                  <SearchInput name="assignedBranchCode" ref={tabTwoSearchFormData.register} />
                  <SearchLabel label={t('text:Assigned_Branch_Name')} />
                  <SearchInput name="assignedBranchName" ref={tabTwoSearchFormData.register} />
                </div>
                <div className="row">
                  <SearchLabel label={t('text:Associated_Anchor_Name')} />
                  <SearchInput name="anchorName" ref={tabTwoSearchFormData.register} />
                  <SearchLabel label={t('text:Associated_Master_Agreement_of_Anchor_Number')} />
                  <SearchInput name="anchorAgreementNo" ref={tabTwoSearchFormData.register} />
                </div>
                <div className="row">
                  <SearchLabel label={t('text:Currency')} />
                  <SearchSelect
                    selectOptions={getSelectOptions<CURRENCY_TYPE>('CURRENCY_TYPE', 'ALL', true)}
                    name="currencyType"
                    ref={tabTwoSearchFormData.register}
                  />
                  <SearchLabel label={t('text:Anchor_Agreement_Branch_Code')} />
                  <SearchInput name="responsibleBranchCode" ref={tabTwoSearchFormData.register} />
                </div>
                <div className="row">
                  <SearchLabel label={t('text:Anchor_Agreement_Branch_Name')} />
                  <SearchInput name="resposibleBranchName" ref={tabTwoSearchFormData.register} />
                  <SearchLabel label={t('text:Declined_Status')} />
                  <SearchSelect
                    name="declined"
                    ref={tabTwoSearchFormData.register}
                    selectOptions={getSelectOptions<string>('BOOLEAN', 'ALL', true)}
                  />
                </div>
              </SearchBorder>
              <div className="flex-center mt-4">
                <Button size={ButtonSizeEnum.LG} onClick={onClickSearch}>
                  {t('text:Search')}
                </Button>
              </div>
            </form>
          </div>
          <div className="division-border" />
          <div className="content-area">
            <SectionTitle title={t('text:Result')} />
            <p className="total-data">
              {t('text:Total')} {anchorPartnerAccountPageData?.totalElements}
            </p>
            <TableBorder>
              <TableHeader header={ASSIGNED_TABLE_HEADERS} />
              <TableBody numOfCol={ASSIGNED_TABLE_HEADERS?.length}>
                {anchorPartnerAccountPageData?.content.map((item, index) => (
                  <Tr key={index}>
                    <Td data={item.partnerName} />
                    <Td data={item.partnerTaxCode} />
                    <Td data={item.assignedBranchCode} />
                    <Td data={item.assignedBranchName} />
                    <Td data={item.anchorName} />
                    <Td data={item.anchorAgreementContractNo} />
                    <Td data={item.currencyType} />
                    <Td data={item.responsibleBranchCode} />
                    <Td data={item.responsibleBranchName} />
                    <Td data={item.declined ? 'Y' : 'N'} />
                    <TdLink
                      path={ROUTES_FI.MANAGE_UPLOADED_PARTNER.ASSIGNMENT_DETAIL_BUILD_PATH(item.anchorPartnerAccountId)}
                    />
                  </Tr>
                ))}
              </TableBody>
            </TableBorder>
            <Pagination pageable={pageable} paginate={paginateAssigned} />
          </div>
        </>
      );
    };

    const paginateWaitingForAssignment = (pageNumber: number, rowCount: number) => {
      fetchFiAnchorPartnerAccountList(
        pageNumber,
        rowCount,
        getParsedSearchParams(FI_POTENTIAL_PARTNER_ASSIGNMENT_TAB1_LIST_QS_KEY).formSearchData,
        'waiting',
      );
    };

    const paginateAssigned = (pageNumber: number, rowCount: number) => {
      fetchFiAnchorPartnerAccountList(
        pageNumber,
        rowCount,
        getParsedSearchParams(FI_POTENTIAL_PARTNER_ASSIGNMENT_TAB2_LIST_QS_KEY).formSearchData,
        'assigned',
      );
    };

    return (
      <>
        {renderGuideMessage(getGuideMessage())}
        <Tab
          tabType="approvalStatus"
          tabList={[
            {
              tabName: t('text:Waiting_for_Assignment'),
              tabItemCount: waitingAnchorPartnerAccountPageData?.totalElements ?? 0,
            },
            {
              tabName: t('text:Assigned'),
              tabItemCount: anchorPartnerAccountPageData?.totalElements ?? 0,
            },
          ]}
          tabViewList={[renderWaitingForAssignmentView(), renderAssignedForm()]}
        />
      </>
    );
  };

  return (
    <>
      <HeaderTitle title={t('text:Uploaded_Partner_Assignment')} />
      {isAdmin ? renderAdminAssignmentListPage() : renderAssignmentListPage()}
    </>
  );
}

export default FinancierPotentialSupplyChainPartnerAssignmentList;
