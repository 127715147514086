import type { MouseEvent } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Button, { ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import IconButton from 'components/stateless/Button/IconButton';
import Exporter from 'components/stateless/Exporter/Exporter';
import Pagination from 'components/stateless/Pagination/Pagination';
import SearchBorder from 'components/stateless/SearchForm/SearchBorder';
import SearchEmpty from 'components/stateless/SearchForm/SearchEmpty';
import SearchInput from 'components/stateless/SearchForm/SearchInput';
import SearchLabel from 'components/stateless/SearchForm/SearchLabel';
import SearchSelect from 'components/stateless/SearchForm/SearchSelect';
import TableBody from 'components/stateless/Table/TableBody';
import TableBorder from 'components/stateless/Table/TableBorder';
import TableHeader from 'components/stateless/Table/TableHeader';
import Td from 'components/stateless/Table/Td';
import Tr from 'components/stateless/Table/Tr';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { ROUTES_FI } from 'constants/routes/financier';
import getSelectOptions from 'constants/selectOptions';
import type { CURRENCY_TYPE } from 'enums';
import { COLLATERAL_TYPE, LOAN_STATUS, SUCCESS_INVOICE_STATUS } from 'enums';
import type { PageableType } from 'hooks/usePageable';
import useProperty from 'hooks/useProperty';
import type Pageable from 'models/Pageable';
import type { StatisticsOfDealerAgreementVOModel } from 'models/vo/StatisticsOfDealerAgreementVO';
import type { PDFExporterProps } from 'utils/exportFile/exportPDF.d';
import { requestFiStatisticsOfDealerAgreementsList } from 'utils/http/api/financier/statistics-of-dealer-agreements';
import type { FinancierStatisticsOfDealerAgreementListRequest } from 'utils/http/api/financier/statistics-of-dealer-agreements/request';
import {
  getChannelTotalRemainingLimit,
  getTotalInterestRateByStaticsOfDealerAgreement,
  isNoneLoanLimitCheckType,
} from 'utils/logic';
import useModal from 'utils/modal/useModal';
import { getParsedSearchParams } from 'utils/searchParams';
import type { ExportSpreadSheetProps } from 'utils/spreadSheet/types';
import { tableValueManage } from 'utils/valueManager/ValueManager';

import { useFinancierOverviewByPartnerListChannelTabConstants } from './constants';
import {
  FI_OVERVIEW_BY_PARTNER_CHANNEL_TAB_QS_KEY,
  FI_OVERVIEW_BY_PARTNER_EXCEL_EXPORT_MAX_ROW_COUNT,
  FI_OVERVIEW_BY_PARTNER_PDF_EXPORT_MAX_ROW_COUNT,
} from '../../FinancierOverviewByPartnerList';

interface FinancierOverviewByPartnerListChannelTabProps {
  onClickSearch: (event: MouseEvent<HTMLButtonElement>) => Promise<void>;
  overviewByPartnerPageData?: Pageable<StatisticsOfDealerAgreementVOModel[]>;
  pageable: PageableType;
  paginate: (selectedPageNumber: number, selectedRowCount: number) => void;
}

function FinancierOverviewByPartnerListChannelTab({
  onClickSearch,
  overviewByPartnerPageData,
  pageable,
  paginate,
}: FinancierOverviewByPartnerListChannelTabProps) {
  const { t } = useTranslation(['format']);
  const modal = useModal();
  const { register, reset } = useFormContext();
  const getProperty = useProperty<FinancierStatisticsOfDealerAgreementListRequest>();

  const {
    excelColumnsInvoice,
    invoiceMergedCells,
    invoiceMergedPdfTableHeader,
    invoicePdfTableHeader,
    INVOICE_TABLE_HEADER,
    INVOICE_BIG_TABLE_HEADER,
  } = useFinancierOverviewByPartnerListChannelTabConstants();

  const onClickExport = async (e: any) => {
    e.preventDefault();

    const searchData = getParsedSearchParams(FI_OVERVIEW_BY_PARTNER_CHANNEL_TAB_QS_KEY).formSearchData;

    searchData.collateralType = COLLATERAL_TYPE.INVOICE;

    try {
      const [dealerMonitoringPage, dealerMonitoringPDFPage] = await Promise.all([
        requestFiStatisticsOfDealerAgreementsList(0, FI_OVERVIEW_BY_PARTNER_EXCEL_EXPORT_MAX_ROW_COUNT, searchData),
        requestFiStatisticsOfDealerAgreementsList(0, FI_OVERVIEW_BY_PARTNER_PDF_EXPORT_MAX_ROW_COUNT, searchData),
      ]);

      const jsonArrayData: any[] = dealerMonitoringPage.content.map(item => ({
        anchorClientName: tableValueManage(item.anchorClientName),
        anchorClientCode: tableValueManage(item.anchorClientCode),
        anchorAgreementContractNo: tableValueManage(item.anchorAgreementContractNo),
        dealerClientName: tableValueManage(item.dealerClientName),
        dealerClientCode: tableValueManage(item.dealerClientCode),
        contractNo: tableValueManage(item.contractNo),
        startDate: tableValueManage(
          item.startDate,
          t('format:date', {
            value: item.startDate,
            key: 'date',
          }),
        ),
        expiryDate: tableValueManage(
          item.expiryDate,
          t('format:date', {
            value: item.expiryDate,
            key: 'date',
          }),
        ),
        currencyType: tableValueManage(item.currencyType),
        basisInterestRate: getTotalInterestRateByStaticsOfDealerAgreement(item),
        loanLimitAmount: isNoneLoanLimitCheckType(item.loanLimitCheckType) ? '-' : item.loanLimitAmount,
        adhocLimitStartDate: item.adhocLimitAllowable
          ? tableValueManage(
              item.adhocLimitStartDate,
              t('format:date', {
                value: item.adhocLimitStartDate,
                key: 'date',
              }),
            )
          : '-',
        adhocLimitEndDate: item.adhocLimitAllowable
          ? tableValueManage(
              item.adhocLimitEndDate,
              t('format:date', {
                value: item.adhocLimitEndDate,
                key: 'date',
              }),
            )
          : '-',
        adhocLimitAmount: isNoneLoanLimitCheckType(item.loanLimitCheckType) ? '-' : item.adhocLimitAmount,
        currentLoanBalanceAmount: item.currentLoanBalanceAmount,
        currentDisbursedLoanCount: item.currentDisbursedLoanCount,
        remainingLimit: isNoneLoanLimitCheckType(item.loanLimitCheckType) ? '-' : item.remainingLimit,
        totalRemainingLimit: isNoneLoanLimitCheckType(item.loanLimitCheckType)
          ? '-'
          : getChannelTotalRemainingLimit(
              item.adhocLimitStartDate,
              item.adhocLimitEndDate,
              item.remainingLimit,
              item.totalRemainingLimit,
              true,
            ),
        currentSettlementWaitingInvoiceAmount: item.currentSettlementWaitingInvoiceAmount,
        currentSettlementWaitingInvoiceCount: item.currentSettlementWaitingInvoiceCount,
        registeredInvoiceAmount: item.registeredInvoiceAmount,
        registeredInvoiceCount: item.registeredInvoiceCount,
        totalDisbursedLoanAmount: item.totalDisbursedLoanAmount,
        totalDisbursedLoanCount: item.totalDisbursedLoanCount,
        currentOverdueLoanCount: item.currentOverdueLoanCount > 0 ? 'Y' : 'N',
        totalUnsettledInvoiceAmount: item.totalUnsettledInvoiceAmount,
        totalUnsettledInvoiceCount: item.totalUnsettledInvoiceCount,
      }));

      const excelExporterProps: ExportSpreadSheetProps<any> = {
        jsonArrayData: jsonArrayData,
        columns: excelColumnsInvoice,
        options: {
          mergedCells: invoiceMergedCells,
          rowHeight: [
            { position: 1, height: 20 },
            { position: 2, height: 15 },
          ],
        },
      };

      const renderInvoiceTableBodyResult = (): JSX.Element[] | undefined => {
        return dealerMonitoringPDFPage?.content?.map((item, index) => (
          <Tr key={index} className="virtual-table-row">
            <Td data={item.dealerClientName} />
            <Td data={item.dealerClientCode} />
            <Td data={item.contractNo} />
            <Td data={item.anchorClientName} />
            <Td data={item.anchorClientCode} />
            <Td data={item.anchorAgreementContractNo} />
            <Td data={item.currencyType} />
            <Td data={item.startDate} format="date" />
            <Td data={item.expiryDate} format="date" />
            <Td data={getTotalInterestRateByStaticsOfDealerAgreement(item)} />
            <Td data={isNoneLoanLimitCheckType(item.loanLimitCheckType) ? '-' : item.loanLimitAmount} format="number" />
            <Td
              data={isNoneLoanLimitCheckType(item.loanLimitCheckType) ? '-' : item.adhocLimitAmount}
              format="number"
            />
            <Td
              data={
                item.adhocLimitAllowable
                  ? tableValueManage(
                      item.adhocLimitStartDate,
                      t('format:date', { value: item.adhocLimitStartDate, key: 'date' }),
                    )
                  : '-'
              }
            />
            <Td
              data={
                item.adhocLimitAllowable
                  ? tableValueManage(
                      item.adhocLimitEndDate,
                      t('format:date', { value: item.adhocLimitEndDate, key: 'date' }),
                    )
                  : '-'
              }
            />
            <Td data={item.currentSettlementWaitingInvoiceAmount} format="number" />
            <Td data={item.currentSettlementWaitingInvoiceCount} format="number" />
            <Td data={item.currentLoanBalanceAmount} format="number" />
            <Td data={item.currentDisbursedLoanCount} format="number" />
            <Td data={isNoneLoanLimitCheckType(item.loanLimitCheckType) ? '-' : item.remainingLimit} format="number" />
            <Td
              className="text-end"
              data={
                isNoneLoanLimitCheckType(item.loanLimitCheckType)
                  ? '-'
                  : getChannelTotalRemainingLimit(
                      item.adhocLimitStartDate,
                      item.adhocLimitEndDate,
                      item.remainingLimit,
                      item.totalRemainingLimit,
                    )
              }
            />
            <Td data={item.currentOverdueLoanCount > 0 ? 'Y' : 'N'} />
            <Td data={item.registeredInvoiceAmount} format="number" />
            <Td data={item.registeredInvoiceCount} format="number" />
            <Td data={item.totalDisbursedLoanAmount} format="number" />
            <Td data={item.totalDisbursedLoanCount} format="number" />
            <Td data={item.totalUnsettledInvoiceAmount} format="number" />
            <Td data={item.totalUnsettledInvoiceCount} format="number" />
          </Tr>
        ));
      };

      const pdfExporterProps: PDFExporterProps = {
        mergedTableHeaders: [invoiceMergedPdfTableHeader],
        tableHeaders: invoicePdfTableHeader,
        tableBody: renderInvoiceTableBodyResult(),
      };

      modal.show(<Exporter spreadSheetExporterProps={excelExporterProps} pdfExporterProps={pdfExporterProps} />, {
        title: t('text:Export_File'),
        closeBtnText: t('text:Close'),
      });
    } catch (error) {
      modal.show(error);
    }
  };

  return (
    <>
      <div className="content-area">
        <form>
          <SectionTitle title={t('text:Search')}>
            <Button
              variant={ButtonVariantEnum.OUTLINED}
              color={ButtonColorEnum.SECONDARY}
              onClick={e => {
                e.preventDefault();
                reset();
              }}
            >
              {t('text:Remove_Filter')}
            </Button>
          </SectionTitle>
          <SearchBorder>
            <div className="row">
              <SearchLabel label={t('text:Partner_Name')} />
              <SearchInput name={getProperty('dealerFinancierClientName')} ref={register} />
              <SearchLabel label={t('text:Partner_Client_Code')} />
              <SearchInput name={getProperty('dealerFinancierClientCode')} ref={register} />
            </div>
            <div className="row">
              <SearchLabel label={t('text:Associated_Anchor_Name')} />
              <SearchInput name={getProperty('anchorFinancierClientName')} ref={register} />
              <SearchLabel label={t('text:Associated_Anchor_Client_Code')} />
              <SearchInput name={getProperty('anchorFinancierClientCode')} ref={register} />
            </div>

            <div className="row">
              <SearchLabel label={t('text:Associated_Anchor_Master_Agreement_Number')} />
              <SearchInput name={getProperty('anchorAgreementContractNo')} ref={register} />
              <SearchLabel label={t('text:Currency')} />
              <SearchSelect
                name={getProperty('currencyType')}
                ref={register}
                selectOptions={getSelectOptions<CURRENCY_TYPE>('CURRENCY_TYPE', 'ALL', true)}
              />
            </div>
            <div className="row">
              <SearchLabel label={t('text:Delinquent_Status')} />
              <SearchSelect
                name={getProperty('overdue')}
                ref={register}
                selectOptions={getSelectOptions<string>('BOOLEAN', 'ALL', true)}
              />
              <SearchEmpty />
            </div>
          </SearchBorder>
          <div className="flex-center mt-4">
            <Button size={ButtonSizeEnum.LG} onClick={onClickSearch}>
              {t('text:Search')}
            </Button>
          </div>
        </form>
      </div>
      <div className="division-border" />
      <div className="content-area">
        <SectionTitle title={t('text:Result')} />
        <div className="d-flex mb-2">
          <p className="total-data me-auto">
            {t('text:Total')} {overviewByPartnerPageData?.totalElements ? overviewByPartnerPageData.totalElements : '0'}
          </p>
          <Button onClick={onClickExport} variant={ButtonVariantEnum.OUTLINED}>
            {t('text:Export')}
          </Button>
        </div>
        <TableBorder>
          <TableHeader header={INVOICE_TABLE_HEADER} bigHeader={INVOICE_BIG_TABLE_HEADER} />
          <TableBody numOfCol={INVOICE_TABLE_HEADER.length}>
            {overviewByPartnerPageData?.content.map((item, i) => {
              return (
                <Tr key={i}>
                  <Td data={item.dealerClientName} />
                  <Td data={item.dealerClientCode} />
                  <Td data={item.contractNo} />
                  <Td data={item.anchorClientName} />
                  <Td data={item.anchorClientCode} />
                  <Td data={item.anchorAgreementContractNo} />
                  <Td data={item.currencyType} />
                  <Td data={item.startDate} format="date" />
                  <Td data={item.expiryDate} format="date" />
                  <Td data={getTotalInterestRateByStaticsOfDealerAgreement(item)} />
                  <Td
                    data={isNoneLoanLimitCheckType(item.loanLimitCheckType) ? '-' : item.loanLimitAmount}
                    format="number"
                  />
                  <Td
                    data={isNoneLoanLimitCheckType(item.loanLimitCheckType) ? '-' : item.adhocLimitAmount}
                    format="number"
                  />
                  <td>
                    {item.adhocLimitAllowable
                      ? `${t('format:date', { value: item.adhocLimitStartDate, key: 'date' })}~${t('format:date', {
                          value: item.adhocLimitEndDate,
                          key: 'date',
                        })}`
                      : '-'}
                  </td>
                  <td className="text-end">
                    {tableValueManage(
                      item.currentSettlementWaitingInvoiceAmount,
                      t('format:number', { value: item.currentSettlementWaitingInvoiceAmount }),
                    )}
                    {' / '}
                    {tableValueManage(
                      item.currentSettlementWaitingInvoiceCount,
                      t('format:number', { value: item.currentSettlementWaitingInvoiceCount }),
                    )}
                    <IconButton
                      className="go-to-link-button"
                      to={ROUTES_FI.VIEW_TRANSACTION.INVOICE_LIST}
                      state={{
                        anchorName: item.anchorClientName,
                        anchorClientCode: item.anchorClientCode,
                        dealerName: item.dealerClientName,
                        dealerClientCode: item.dealerClientCode,
                        currencyType: item.currencyType,
                        successInvoiceStatus: SUCCESS_INVOICE_STATUS.REGISTERED,
                      }}
                    >
                      <FontAwesomeIcon icon={faExternalLinkAlt} />
                    </IconButton>
                  </td>
                  <td className="text-end">
                    {tableValueManage(
                      item.currentLoanBalanceAmount,
                      t('format:number', {
                        value: item.currentLoanBalanceAmount,
                      }),
                    )}
                    {' / '}
                    {tableValueManage(
                      item.currentDisbursedLoanCount,
                      t('format:number', { value: item.currentDisbursedLoanCount }),
                    )}
                    <IconButton
                      className="go-to-link-button"
                      to={ROUTES_FI.VIEW_TRANSACTION.FINANCING_LIST}
                      state={{
                        anchorClientName: item.anchorClientName,
                        anchorClientCode: item.anchorClientCode,
                        dealerClientName: item.dealerClientName,
                        dealerClientCode: item.dealerClientCode,
                        currencyType: item.currencyType,
                        loanStatusConditions: [LOAN_STATUS.DISBURSED, LOAN_STATUS.OVERDUE],
                        collateralType: item.collateralType,
                      }}
                    >
                      <FontAwesomeIcon icon={faExternalLinkAlt} />
                    </IconButton>
                  </td>
                  {/* remaining limit */}
                  <Td
                    data={isNoneLoanLimitCheckType(item.loanLimitCheckType) ? '-' : item.remainingLimit}
                    format="number"
                  />
                  <Td
                    className="text-end"
                    data={
                      isNoneLoanLimitCheckType(item.loanLimitCheckType)
                        ? '-'
                        : getChannelTotalRemainingLimit(
                            item.adhocLimitStartDate,
                            item.adhocLimitEndDate,
                            item.remainingLimit,
                            item.totalRemainingLimit,
                          )
                    }
                  />
                  <Td data={item.currentOverdueLoanCount > 0 ? 'Y' : 'N'} />
                  <td className="text-end">
                    {tableValueManage(
                      item.registeredInvoiceAmount,
                      t('format:number', { value: item.registeredInvoiceAmount }),
                    )}
                    {' / '}
                    {tableValueManage(
                      item.registeredInvoiceCount,
                      t('format:number', { value: item.registeredInvoiceCount }),
                    )}
                    <IconButton
                      className="go-to-link-button"
                      to={ROUTES_FI.VIEW_TRANSACTION.INVOICE_LIST}
                      state={{
                        anchorName: item.anchorClientName,
                        anchorClientCode: item.anchorClientCode,
                        dealerName: item.dealerClientName,
                        dealerClientCode: item.dealerClientCode,
                        currencyType: item.currencyType,
                      }}
                    >
                      <FontAwesomeIcon icon={faExternalLinkAlt} />
                    </IconButton>
                  </td>
                  <td className="text-end">
                    {tableValueManage(
                      item.totalDisbursedLoanAmount,
                      t('format:number', { value: item.totalDisbursedLoanAmount }),
                    )}
                    {' / '}
                    {tableValueManage(
                      item.totalDisbursedLoanCount,
                      t('format:number', { value: item.totalDisbursedLoanCount }),
                    )}
                    <IconButton
                      className="go-to-link-button"
                      to={ROUTES_FI.VIEW_TRANSACTION.FINANCING_LIST}
                      state={{
                        anchorClientName: item.anchorClientName,
                        anchorClientCode: item.anchorClientCode,
                        dealerClientName: item.dealerClientName,
                        dealerClientCode: item.dealerClientCode,
                        currencyType: item.currencyType,
                        loanStatusConditions: [
                          LOAN_STATUS.DISBURSED,
                          LOAN_STATUS.OVERDUE,
                          LOAN_STATUS.EXPIRATION,
                          LOAN_STATUS.REPAID,
                        ],
                        collateralType: item.collateralType,
                      }}
                    >
                      <FontAwesomeIcon icon={faExternalLinkAlt} />
                    </IconButton>
                  </td>
                  <td className="text-end">
                    {tableValueManage(
                      item.totalUnsettledInvoiceAmount,
                      t('format:number', { value: item.totalUnsettledInvoiceAmount }),
                    )}
                    {' / '}
                    {tableValueManage(
                      item.totalUnsettledInvoiceCount,
                      t('format:number', { value: item.totalUnsettledInvoiceCount }),
                    )}
                    <IconButton
                      className="go-to-link-button"
                      to={ROUTES_FI.VIEW_TRANSACTION.INVOICE_LIST}
                      state={{
                        anchorName: item.anchorClientName,
                        anchorClientCode: item.anchorClientCode,
                        dealerName: item.dealerClientName,
                        dealerClientCode: item.dealerClientCode,
                        currencyType: item.currencyType,
                        successInvoiceStatus: SUCCESS_INVOICE_STATUS.UNSETTLED,
                      }}
                    >
                      <FontAwesomeIcon icon={faExternalLinkAlt} />
                    </IconButton>
                  </td>
                </Tr>
              );
            })}
          </TableBody>
        </TableBorder>
        <Pagination pageable={pageable} paginate={paginate} />
      </div>
    </>
  );
}

export default FinancierOverviewByPartnerListChannelTab;
