import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Tab from 'components/stateless/TabManager/Tab';
import { HeaderTitle } from 'components/stateless/Title/HeaderTitle';
import { COLLATERAL_TYPE } from 'enums';
import useMounted from 'hooks/useMounted';
import usePageable from 'hooks/usePageable';
import type Pageable from 'models/Pageable';
import type { StatisticsOfDealerAgreementVOModel } from 'models/vo/StatisticsOfDealerAgreementVO';
import { setFormValues } from 'utils/form/setFormValues';
import { requestFiStatisticsOfDealerAgreementsList } from 'utils/http/api/financier/statistics-of-dealer-agreements';
import type { FinancierStatisticsOfDealerAgreementListRequest } from 'utils/http/api/financier/statistics-of-dealer-agreements/request';
import useModal from 'utils/modal/useModal';
import {
  addSearchParams,
  getParsedSearchParams,
  makeSearchParamsPattern,
  updateSearchParams,
} from 'utils/searchParams';

import FinancierOverviewByPartnerListChannelTab from './sections/channel-tab';
import FinancierOverviewByPartnerListVendorTab from './sections/vendor-tab';

export const FI_OVERVIEW_BY_PARTNER_CHANNEL_TAB_QS_KEY = 'fi-overview-by-partner-channel-tab';
export const FI_OVERVIEW_BY_PARTNER_VENDOR_TAB_QS_KEY = 'fi-overview-by-partner-vendor-tab';

export const FI_OVERVIEW_BY_PARTNER_EXCEL_EXPORT_MAX_ROW_COUNT = 1000;
export const FI_OVERVIEW_BY_PARTNER_PDF_EXPORT_MAX_ROW_COUNT = 150;

function FinancierOverviewByPartnerList() {
  const { t } = useTranslation(['format']);
  const mounted = useMounted();
  const modal = useModal();

  const [overviewByPartnerVendorPage, setOverviewByPartnerVendorPage] =
    useState<Pageable<StatisticsOfDealerAgreementVOModel[]>>();
  const [overviewByPartnerChannelPage, setOverviewByPartnerChannelPage] =
    useState<Pageable<StatisticsOfDealerAgreementVOModel[]>>();

  const { pageable: overviewByPartnerVendorPageable, setPageable: setOverviewByPartnerVendorPageable } = usePageable(
    FI_OVERVIEW_BY_PARTNER_VENDOR_TAB_QS_KEY,
  );
  const { pageable: overviewByPartnerChannelPageable, setPageable: setOverviewByPartnerChannelPageable } = usePageable(
    FI_OVERVIEW_BY_PARTNER_CHANNEL_TAB_QS_KEY,
  );

  const vendorTabSearchForm = useForm<FinancierStatisticsOfDealerAgreementListRequest>();

  const channelTabSearchForm = useForm<FinancierStatisticsOfDealerAgreementListRequest>();

  useEffect(() => {
    if (mounted) {
      setFormValues<FinancierStatisticsOfDealerAgreementListRequest>(
        vendorTabSearchForm.setValue,
        getParsedSearchParams(FI_OVERVIEW_BY_PARTNER_VENDOR_TAB_QS_KEY).formSearchData,
      );
      setFormValues<FinancierStatisticsOfDealerAgreementListRequest>(
        channelTabSearchForm.setValue,
        getParsedSearchParams(FI_OVERVIEW_BY_PARTNER_CHANNEL_TAB_QS_KEY).formSearchData,
      );
      fetchAll();
    }
  }, [mounted]);

  async function fetchAll() {
    const addFiOverviewByPartnerPageQs = () => {
      const vendorTabQs = makeSearchParamsPattern(
        {
          ...vendorTabSearchForm.getValues(),
          pageNumber: overviewByPartnerVendorPageable.currentPage,
          rowCount: overviewByPartnerVendorPageable.sizePerPage,
        },
        FI_OVERVIEW_BY_PARTNER_VENDOR_TAB_QS_KEY,
      );
      const dealerTabQs = makeSearchParamsPattern(
        {
          ...channelTabSearchForm.getValues(),
          pageNumber: overviewByPartnerChannelPageable.currentPage,
          rowCount: overviewByPartnerChannelPageable.sizePerPage,
        },
        FI_OVERVIEW_BY_PARTNER_CHANNEL_TAB_QS_KEY,
      );
      addSearchParams(vendorTabQs + dealerTabQs);
    };
    addFiOverviewByPartnerPageQs();

    try {
      const [fetchOverviewByPartnerVendorPage, fetchOverviewByPartnerChannelPage] = await Promise.all([
        requestFiStatisticsOfDealerAgreementsList(
          overviewByPartnerVendorPageable.currentPage,
          overviewByPartnerVendorPageable.sizePerPage,
          {
            ...vendorTabSearchForm.getValues(),
            collateralType: COLLATERAL_TYPE.AR,
          },
        ),
        requestFiStatisticsOfDealerAgreementsList(
          overviewByPartnerChannelPageable.currentPage,
          overviewByPartnerChannelPageable.sizePerPage,
          {
            ...channelTabSearchForm.getValues(),
            collateralType: COLLATERAL_TYPE.INVOICE,
          },
        ),
      ]);

      ReactDOM.unstable_batchedUpdates(() => {
        setOverviewByPartnerVendorPage(fetchOverviewByPartnerVendorPage);
        setOverviewByPartnerVendorPageable(fetchOverviewByPartnerVendorPage);

        setOverviewByPartnerChannelPage(fetchOverviewByPartnerChannelPage);
        setOverviewByPartnerChannelPageable(fetchOverviewByPartnerChannelPage);
      });
    } catch (error) {
      modal.show(error);
    }
  }

  const fetchFiPartnerStatisticsVendorTab = async (
    pageNumber: number = 0,
    rowCount: number = 10,
    data: FinancierStatisticsOfDealerAgreementListRequest,
  ) => {
    const searchData = {
      ...data,
      collateralType: COLLATERAL_TYPE.AR,
    };
    try {
      const response = await requestFiStatisticsOfDealerAgreementsList(pageNumber, rowCount, searchData);
      updateSearchParams(
        {
          ...searchData,
          pageNumber,
          rowCount,
        },
        FI_OVERVIEW_BY_PARTNER_VENDOR_TAB_QS_KEY,
      );

      setOverviewByPartnerVendorPage(response);
      setOverviewByPartnerVendorPageable(response);
    } catch (error) {
      modal.show(error);
    }
  };

  const onClickSearchVendorTab = async (e: any) => {
    e.preventDefault();
    await fetchFiPartnerStatisticsVendorTab(
      1,
      overviewByPartnerVendorPageable.sizePerPage,
      vendorTabSearchForm.getValues(),
    );
  };

  const paginateVendorTab = (selectedPageNumber: number, selectedRowCount: number) => {
    fetchFiPartnerStatisticsVendorTab(
      selectedPageNumber,
      selectedRowCount,
      getParsedSearchParams(FI_OVERVIEW_BY_PARTNER_VENDOR_TAB_QS_KEY).formSearchData,
    );
  };

  const fetchFiPartnerStatisticsChannelTab = async (
    pageNumber: number = 0,
    rowCount: number = 10,
    data: FinancierStatisticsOfDealerAgreementListRequest,
  ) => {
    const searchData = {
      ...data,
      collateralType: COLLATERAL_TYPE.INVOICE,
    };
    try {
      const response = await requestFiStatisticsOfDealerAgreementsList(pageNumber, rowCount, searchData);
      updateSearchParams(
        {
          ...searchData,
          pageNumber,
          rowCount,
        },
        FI_OVERVIEW_BY_PARTNER_CHANNEL_TAB_QS_KEY,
      );

      setOverviewByPartnerChannelPage(response);
      setOverviewByPartnerChannelPageable(response);
    } catch (error) {
      modal.show(error);
    }
  };

  const onClickSearchChannelTab = async (e: any) => {
    e.preventDefault();
    await fetchFiPartnerStatisticsChannelTab(
      1,
      overviewByPartnerChannelPageable.sizePerPage,
      channelTabSearchForm.getValues(),
    );
  };

  const paginateChannelTab = (selectedPageNumber: number, selectedRowCount: number) => {
    fetchFiPartnerStatisticsChannelTab(
      selectedPageNumber,
      selectedRowCount,
      getParsedSearchParams(FI_OVERVIEW_BY_PARTNER_CHANNEL_TAB_QS_KEY).formSearchData,
    );
  };

  return (
    <>
      <HeaderTitle title={t('text:Overview_by_Partner')} />
      <Tab
        tabType="financeType"
        tabViewList={[
          <FormProvider {...vendorTabSearchForm} key="vendor">
            <FinancierOverviewByPartnerListVendorTab
              onClickSearch={onClickSearchVendorTab}
              overviewByPartnerPageData={overviewByPartnerVendorPage}
              pageable={overviewByPartnerVendorPageable}
              paginate={paginateVendorTab}
            />
          </FormProvider>,
          <FormProvider {...channelTabSearchForm} key="dealer">
            <FinancierOverviewByPartnerListChannelTab
              onClickSearch={onClickSearchChannelTab}
              overviewByPartnerPageData={overviewByPartnerChannelPage}
              pageable={overviewByPartnerChannelPageable}
              paginate={paginateChannelTab}
            />
          </FormProvider>,
        ]}
      />
    </>
  );
}

export default FinancierOverviewByPartnerList;
