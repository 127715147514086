import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';

import GuideMessage, { MessageType } from 'components/stateless/GuideMessage/GuideMessage';
import { BackHeaderTitle } from 'components/stateless/Title/BackHeaderTitle';
import { AUTHORITY_TYPE, POTENTIAL_PARTNER_FINANCING_APPLICATION_STATUS } from 'enums';
import useMounted from 'hooks/useMounted';
import { getSignIn } from 'utils/storage/LocalStorage';

import FinancierSCPartnerAcquisitionDetailAnchorAgreement from './sections/associated-anchor-agreement';
import FinancierSCPartnerAcquisitionDetailPartnerInfo from './sections/partner-information';
import FinancierSCPartnerAcquisitionDetailPartnerMasterAgreement from './sections/partner-master-agreement';
import FinancierSCPartnerAcquisitionDetailSubmittedDocuments from './sections/submitted-documents';
import FinancierSCPartnerAcquisitionDetailSummary from './sections/summary';
import FinancierSCPartnerAcquisitionDetailButtons from './sections/update-submission-status-buttons';
import { useFinancierScPartnerAcquisitionDetailState } from './useFinancierScPartnerAcquisitionDetailState';

function FinancierScPartnerAcquisitionDetailPage() {
  const CURRENT_USER_AUTHORITY: AUTHORITY_TYPE | undefined = getSignIn()?.authorityType;
  const isAdmin = CURRENT_USER_AUTHORITY === AUTHORITY_TYPE.ADMIN;
  const { t } = useTranslation(['format']);
  const mounted = useMounted();
  const location = useLocation();
  const { potentialPartnerFinancingApplicationId } = useParams() as { potentialPartnerFinancingApplicationId: string };

  const { state, fetchAll, updateApplicationSubmitStatusApis, updateApplicationDocumentStatus } =
    useFinancierScPartnerAcquisitionDetailState(Number(potentialPartnerFinancingApplicationId));

  const applicationStatus = state.applicationSummaryInfo.potentialPartnerFinancingApplicationStatus;

  useEffect(() => {
    if (mounted) {
      fetchAll();
    }

    return () => {};
  }, [mounted, location.pathname]);

  const renderGuideMessageByStatus = (status: POTENTIAL_PARTNER_FINANCING_APPLICATION_STATUS) => {
    switch (status) {
      case POTENTIAL_PARTNER_FINANCING_APPLICATION_STATUS.SUBMITTED: {
        return (
          <GuideMessage
            message={[
              t(
                'text:To_proceed_with_the_review_of_the_Uploaded_Partner_s_submission_click_on_the_Review_Submission_button_at_the_bottom_of_the_page',
              ),
            ]}
          />
        );
      }
      case POTENTIAL_PARTNER_FINANCING_APPLICATION_STATUS.SUBMITTED_CANCEL: {
        return (
          <GuideMessage
            messageType={MessageType.ALERT}
            message={[t('text:The_Uploaded_Partner_has_cancelled_its_submission')]}
          />
        );
      }
      case POTENTIAL_PARTNER_FINANCING_APPLICATION_STATUS.IN_PROGRESS: {
        return (
          <GuideMessage
            message={[
              t('text:The_review_of_the_Uploaded_Partner_s_submission_is_in_progress'),
              t('text:The_branch_reviewing_the_submission_can_cancel_the_review_or_reject_the_submission'),
            ]}
          />
        );
      }
      case POTENTIAL_PARTNER_FINANCING_APPLICATION_STATUS.REJECTED: {
        return (
          <GuideMessage
            messageType={MessageType.ALERT}
            message={[
              t('text:The_Uploaded_Partner_submission_has_been_rejected'),
              t(
                'text:The_branch_that_reviewed_and_rejected_the_submission_can_cancel_the_rejection_if_the_Uploaded_Partner_hasn_t_submitted_another_request_already',
              ),
            ]}
            reasonTitle={t('text:REASON_FOR_REJECTION')}
            reason={state.applicationSummaryInfo.rejectedReason}
          />
        );
      }

      case POTENTIAL_PARTNER_FINANCING_APPLICATION_STATUS.COMPLETED: {
        return (
          <GuideMessage
            message={[
              t(
                'text:A_Partner_Master_Agreement_has_been_registered_on_the_platform_for_this_Uploaded_Partner_s_submission',
              ),
              t(
                'text:The_branch_that_reviewed_and_rejected_the_submission_can_cancel_the_rejection_if_the_Uploaded_Partner_hasn_t_submitted_another_request_already',
              ),
            ]}
          />
        );
      }
    }
  };

  return (
    <>
      <BackHeaderTitle title={state.partnerInfo.anchorPartnerName} />
      {renderGuideMessageByStatus(applicationStatus)}
      <FinancierSCPartnerAcquisitionDetailSummary applicationSummaryInfo={state.applicationSummaryInfo} />
      <FinancierSCPartnerAcquisitionDetailPartnerInfo partnerInfo={state.partnerInfo} />
      {applicationStatus === POTENTIAL_PARTNER_FINANCING_APPLICATION_STATUS.COMPLETED && (
        <FinancierSCPartnerAcquisitionDetailPartnerMasterAgreement dealerAgreementInfo={state.dealerAgreementInfo} />
      )}
      <FinancierSCPartnerAcquisitionDetailAnchorAgreement anchorAgreementInfo={state.anchorAgreementInfo} />
      <FinancierSCPartnerAcquisitionDetailSubmittedDocuments
        documentLists={state.documentLists}
        reviewedBranchId={state.applicationSummaryInfo.reviewedBranchId}
        applicationStatus={applicationStatus}
        updateApplicationDocumentStatus={updateApplicationDocumentStatus}
      />
      {!isAdmin && (
        <FinancierSCPartnerAcquisitionDetailButtons
          updateApplicationSubmitStatusApis={updateApplicationSubmitStatusApis}
          applicationStatus={applicationStatus}
        />
      )}
    </>
  );
}

export default FinancierScPartnerAcquisitionDetailPage;
