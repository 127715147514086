import { useEffect } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import Button from 'components/stateless/Button/Button';
import GuideMessage from 'components/stateless/GuideMessage/GuideMessage';
import Tab from 'components/stateless/TabManager/Tab';
import { HeaderTitle } from 'components/stateless/Title/HeaderTitle';
import { ROUTES_FI } from 'constants/routes/financier';
import { AUTHORITY_TYPE, COMMON_APPROVAL_TYPE } from 'enums';
import useMounted from 'hooks/useMounted';
import { getSignIn } from 'utils/storage/LocalStorage';

import FinancierAnchorDealerRegistrationListCompletedTab from './tabs/FinancierAnchorDealerRegistrationListCompletedTab';
import FinancierAnchorDealerRegistrationListFailedTab from './tabs/FinancierAnchorDealerRegistrationListFailedTab';
import FinancierAnchorDealerRegistrationListWaitingTab from './tabs/FinancierAnchorDealerRegistrationListWaitingTab';
import { useFinancierAnchorDealerRegistrationListPageState } from './useFinancierAnchorDealerRegistrationListPageState';

function FinancierAnchorDealerRegistrationListPage() {
  const { t } = useTranslation(['format']);
  const mounted = useMounted();
  const history = useHistory();
  const authorityType = getSignIn()?.authorityType;
  const isOperator = authorityType === AUTHORITY_TYPE.OPERATOR;

  const { state, fetchAll, useForms, pageableObjects, fetchUtils } =
    useFinancierAnchorDealerRegistrationListPageState();

  const { waitingListResult, failedListResult, completedListResult } = state;
  const { waitingListPageable, completedListPageable, failedListPageable } = pageableObjects;
  const { waitingListFetchUtils, completedListFetchUtils, failedListFetchUtils } = fetchUtils;

  const { waitingListSearchForm, completedListSearchForm, failedListSearchForm } = useForms;

  useEffect(() => {
    if (mounted) {
      fetchAll();
    }

    return () => {};
  }, [mounted]);

  return (
    <>
      <HeaderTitle title={t('text:Uploaded_Dealer_Registration')} />
      <GuideMessage
        message={[
          isOperator &&
            t(
              'text:Click_on_the_Register_Uploaded_Dealer_button_to_register_the_information_of_Anchors_new_Dealer_to_the_platform',
            ),
          t('text:Click_on_the_arrow_button_to_view_detailed_information_of_the_Uploaded_Dealer'),
        ]}
      >
        {isOperator
          ? {
              button: (
                <Button
                  onClick={() => {
                    history.push(ROUTES_FI.MANAGE_DEALER.REGISTRATION_STEP1);
                  }}
                >
                  {t('text:Register_Uploaded_Dealer')}
                </Button>
              ),
            }
          : undefined}
      </GuideMessage>
      <Tab
        initialActiveTabIndex={0}
        tabList={[
          {
            tabName: t('text:Waiting_for_Approval'),
            tabItemCount:
              (waitingListSearchForm.getValues('approvalTypes') as any) === COMMON_APPROVAL_TYPE.OPERATOR_CANCEL
                ? 0
                : state.waitingListResultForTabCount?.totalElements ?? 0,
          },
          {
            tabName: t('text:Registration_Completed'),
            tabItemCount: completedListResult.totalElements ?? 0,
          },
          {
            tabName: t('text:Registration_Failed'),
            tabItemCount: failedListResult.totalElements ?? 0,
          },
        ]}
        tabViewList={[
          <FormProvider {...waitingListSearchForm} key="waiting">
            <FinancierAnchorDealerRegistrationListWaitingTab
              {...waitingListFetchUtils()}
              pageable={waitingListPageable}
              waitingAnchorDealerList={waitingListResult}
            />
          </FormProvider>,

          <FormProvider {...completedListSearchForm} key="completed">
            <FinancierAnchorDealerRegistrationListCompletedTab
              {...completedListFetchUtils()}
              pageable={completedListPageable}
              data={completedListResult}
            />
          </FormProvider>,
          <FormProvider {...failedListSearchForm} key="failed">
            <FinancierAnchorDealerRegistrationListFailedTab
              {...failedListFetchUtils()}
              pageable={failedListPageable}
              data={failedListResult}
            />
          </FormProvider>,
        ]}
      />
    </>
  );
}

export default FinancierAnchorDealerRegistrationListPage;
