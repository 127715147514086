import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import dayjs from 'dayjs';
import { isNil } from 'lodash-es';
import { Bar, Line } from 'recharts';

import Badge from 'components/stateless/Badge/Badge';
import BasicTableBody from 'components/stateless/BasicTable/BasicTableBody';
import BasicTableBorder from 'components/stateless/BasicTable/BasicTableBorder';
import BasicTableHeader from 'components/stateless/BasicTable/BasicTableHeader';
import Card from 'components/stateless/Card/Card';
import CardBody from 'components/stateless/Card/CardBody';
import CardGroup from 'components/stateless/Card/CardGroup';
import CardHeader from 'components/stateless/Card/CardHeader';
import BarLineChart, { getDataKey } from 'components/stateless/Charts/BarLineChart';
import CustomSelect from 'components/stateless/CustomSelect/CustomSelect';
import Tab from 'components/stateless/TabManager/Tab';
import type { HeaderType } from 'components/stateless/Table/TableHeader';
import Td from 'components/stateless/Table/Td';
import Tr from 'components/stateless/Table/Tr';
import { HeaderTitle } from 'components/stateless/Title/HeaderTitle';
import { ROUTES_FI } from 'constants/routes/financier';
import getSelectOptions from 'constants/selectOptions';
import type { CURRENCY_TYPE } from 'enums';
import { COMMON_APPROVAL_TYPE } from 'enums';
import {
  APPROVAL_EVENT_TYPE,
  AUTHORITY_TYPE,
  COLLATERAL_TYPE,
  LOAN_APPROVAL_TYPE,
  LOAN_STATUS,
  POTENTIAL_PARTNER_FINANCING_DASHBOARD_TYPE,
  SCHEDULE_EVENT_TYPE,
  SUPPORTED_COLLATERAL_TYPE,
} from 'enums';
import useMounted from 'hooks/useMounted';
import useNonInitialEffect from 'hooks/useNonInitialEffect';
import type Pageable from 'models/Pageable';
import type { DashBoardApprovalVOModel } from 'models/vo/DashBoardApprovalVO';
import type { DashBoardScheduleVOModel } from 'models/vo/DashBoardScheduleVO';
import type { DashboardFinancierPerformanceVOModel } from 'models/vo/DashboardFinancierPerformanceVO';
import type { DashboardPotentialPartnerFinancingVOModel } from 'models/vo/DashboardPotentialPartnerFinancingVO';
import type { LoanVOModel } from 'models/vo/LoanVO';
import { requestBranchList } from 'utils/http/api/financier/branches';
import {
  requestFinancierClientRequest,
  requestFinancierInternalApproval,
  requestFinancierPerformance,
  requestFinancierTaskSchedule,
} from 'utils/http/api/financier/dashboard';
import { requestFiDashboardPotentialPartnerFinancing } from 'utils/http/api/financier/dashboard-potential-partner-financing';
import { requestFinancierLoanList } from 'utils/http/api/financier/loans';
import { getCurrencyByLocale } from 'utils/localeCurrencyType';
import useModal from 'utils/modal/useModal';
import { getCollateralType, getSignIn } from 'utils/storage/LocalStorage';
import { tableValueManage } from 'utils/valueManager/ValueManager';

import './FinancierDashboard.scss';

const getConstant = () => {
  const { t } = useTranslation(['format']);

  const DELINQUENT_HEADERS: HeaderType[] = [
    {
      headerText: t('text:Anchor_Name'),
      colWidths: 100,
    },
    {
      headerText: t('text:Partner_Name'),
      colWidths: 100,
    },
    {
      headerText: t('text:Repayment_Date'),
      colWidths: 100,
    },
    {
      headerText: t('text:Days_Overdue'),
      colWidths: 60,
    },
    {
      headerText: t('text:Financing_Amount'),
      colWidths: 100,
      className: 'text-end',
    },
    {
      headerText: t('text:Repaid_Principal_Amount'),
      colWidths: 100,
      className: 'text-end',
    },
  ];

  const CLIENT_REQUEST_HEADERS: HeaderType[] = [
    {
      headerText: t('text:Requested_Date'),
      colWidths: 100,
    },

    {
      headerText: t('text:Client'),
      colWidths: 200,
    },
    {
      headerText: t('text:Request'),
      colWidths: 200,
    },
  ];

  const INTERNAL_APPROVAL_HEADERS: HeaderType[] = [
    {
      headerText: t('text:Requested_Date'),
      colWidths: 100,
    },
    {
      headerText: t('text:Request'),
      colWidths: 200,
    },
    {
      headerText: t('text:Status'),
      colWidths: 100,
    },
    {
      headerText: t('text:Requested_by'),
      colWidths: 100,
    },
  ];

  const TASK_SCHEDULER_HEADERS: HeaderType[] = [
    {
      headerText: t('text:Date'),
      colWidths: 100,
    },
    {
      headerText: t('text:Event'),
      colWidths: 160,
    },
  ];

  const SCHEDULE_EVENT_OPTIONS = [
    {
      eventName: t('text:All'),
      scheduleEventTypes: Object.values(SCHEDULE_EVENT_TYPE),
    },
    {
      eventName: t('text:Agreement'),
      scheduleEventTypes: [SCHEDULE_EVENT_TYPE.ANCHOR_AGREEMENT_EXPIRY, SCHEDULE_EVENT_TYPE.PARTNER_AGREEMENT_EXPIRY],
    },
    {
      eventName: t('text:Settlement'),
      scheduleEventTypes: [SCHEDULE_EVENT_TYPE.AP_SETTLEMENT],
    },
    {
      eventName: t('text:Repayment'),
      scheduleEventTypes: [SCHEDULE_EVENT_TYPE.LOAN_REPAYMENT],
    },
  ];

  const POTENTIAL_PARTNER_HEADERS: HeaderType[] = [
    {
      headerText: t('text:Submitted_Date'),
      colWidths: 100,
    },
    {
      headerText: t('text:Uploaded_Partner'),
      colWidths: 100,
    },
    {
      headerText: t('text:Associated_Anchor'),
      colWidths: 100,
    },
    {
      headerText: t('text:Request'),
      colWidths: 85,
    },
  ];

  const TABLE_HEIGHT_OVERFLOW_MARGIN = 176;

  return {
    t,
    DELINQUENT_HEADERS,
    CLIENT_REQUEST_HEADERS,
    INTERNAL_APPROVAL_HEADERS,
    TASK_SCHEDULER_HEADERS,
    SCHEDULE_EVENT_OPTIONS,
    POTENTIAL_PARTNER_HEADERS,
    TABLE_HEIGHT_OVERFLOW_MARGIN,
  };
};

type PerformanceData = Pick<
  DashboardFinancierPerformanceVOModel,
  | 'anchorAgreementCount'
  | 'partnerAgreementCount'
  | 'disbursedLoanAmount'
  | 'disbursedLoanCount'
  | 'overdueLoanAmount'
  | 'overdueLoanCount'
  | 'accumDisbursedLoanAmount'
  | 'accumDisbursedLoanCount'
>;

function FinancierDashboard() {
  const mounted = useMounted();
  const modal = useModal();
  const history = useHistory();

  const signInInfo = getSignIn();
  const signInBranchId = getSignIn()?.branchId;
  const name = getSignIn()?.name;
  const collateralType = getCollateralType();
  const isAdmin = signInInfo?.authorityType === AUTHORITY_TYPE.ADMIN;

  const {
    t,
    DELINQUENT_HEADERS,
    CLIENT_REQUEST_HEADERS,
    INTERNAL_APPROVAL_HEADERS,
    TASK_SCHEDULER_HEADERS,
    SCHEDULE_EVENT_OPTIONS,
    POTENTIAL_PARTNER_HEADERS,
    TABLE_HEIGHT_OVERFLOW_MARGIN,
  } = getConstant();

  const getInitialRequestCollateralType = (): COLLATERAL_TYPE => {
    if (collateralType === SUPPORTED_COLLATERAL_TYPE.BOTH || collateralType === SUPPORTED_COLLATERAL_TYPE.AR) {
      return COLLATERAL_TYPE.AR;
    } else if (collateralType === SUPPORTED_COLLATERAL_TYPE.INVOICE) {
      return COLLATERAL_TYPE.INVOICE;
    } else {
      return COLLATERAL_TYPE.AR;
    }
  };

  const [requestCollateralType, setRequestCollateralType] = useState<COLLATERAL_TYPE>(
    getInitialRequestCollateralType(),
  );

  const [selectedBranchValue, setSelectedBranchValue] = useState<{ branchId: number | undefined; branchName: string }>({
    branchId: signInBranchId,
    branchName: t('text:All_Branches'),
  });
  const [selectBranchOptions, setSelectBranchOptions] = useState<
    { branchId: number | undefined; branchName: string }[]
  >([
    {
      branchId: undefined,
      branchName: t('text:All_Branches'),
    },
  ]);

  const initialScheduleEventValue = {
    eventName: t('text:All'),
    scheduleEventTypes: Object.values(SCHEDULE_EVENT_TYPE),
  };

  const [selectedScheduleEventValue, setSelectedScheduleEventValue] = useState<{
    eventName: string;
    scheduleEventTypes: SCHEDULE_EVENT_TYPE[];
  }>(initialScheduleEventValue);

  const selectedScheduleEventOptions: {
    eventName: string;
    scheduleEventTypes: SCHEDULE_EVENT_TYPE[];
  }[] = SCHEDULE_EVENT_OPTIONS;

  const [selectedCurrencyValue, setSelectedCurrencyValue] = useState<{ label: string; value: CURRENCY_TYPE }>({
    label: getCurrencyByLocale(),
    value: getCurrencyByLocale(), // TODO: 국가별로 default 통화 지정
  });
  const selectedCurrencyOptions: { label: string; value: CURRENCY_TYPE }[] =
    getSelectOptions<CURRENCY_TYPE>('CURRENCY_TYPE');

  const [loanList, setLoanList] = useState<Pageable<LoanVOModel[]>>();
  const [clientRequestList, setClientRequestList] = useState<Pageable<DashBoardApprovalVOModel[]>>();
  const [internalApprovalList, setInternalApprovalList] = useState<Pageable<DashBoardApprovalVOModel[]>>();
  const [taskScheduleList, setTaskScheduleList] = useState<Pageable<DashBoardScheduleVOModel[]>>();
  const [performanceList, setPerformanceList] = useState<Pageable<DashboardFinancierPerformanceVOModel[]>>();
  const [potentialPartnerList, setPotentialPartnerList] =
    useState<Pageable<DashboardPotentialPartnerFinancingVOModel[]>>();

  useEffect(() => {
    if (mounted) {
      isAdmin ? fetchAllAndSetWithAllBranches() : fetchAllAndSetWithSelectedBranch();
    }
  }, [mounted]);

  useNonInitialEffect(() => {
    if (isAdmin) {
      setSelectedScheduleEventValue(initialScheduleEventValue);
      fetchAllAndSetChangedBranch();
    }
  }, [selectedBranchValue]);

  useNonInitialEffect(() => {
    fetchAndSetTaskSchedule();
  }, [selectedScheduleEventValue]);

  useNonInitialEffect(() => {
    fetchAndSetPerformance();
  }, [requestCollateralType, selectedCurrencyValue]);

  const [today] = dayjs().format().split('T');
  const [aWeekAgo] = dayjs().subtract(7, 'day').format().split('T');
  const a30DaysLater = dayjs(today).add(30, 'd').format('YYYY-MM-DD');

  async function fetchAllAndSetWithAllBranches() {
    try {
      const [branchList, loanList, clientRequestList, internalApprovalList, taskScheduleList, potentialPartnerList] =
        await Promise.all([
          requestBranchList(1, 1000),
          requestFinancierLoanList(1, 20, { loanStatusConditions: [LOAN_STATUS.OVERDUE] }),
          requestFinancierClientRequest(1, 20),
          requestFinancierInternalApproval(1, 20),
          requestFinancierTaskSchedule(1, 20, {
            scheduleEventTypes: selectedScheduleEventValue.scheduleEventTypes,
            toDate: a30DaysLater,
          }),
          requestFiDashboardPotentialPartnerFinancing(1, 20),
        ]);

      const selectOptions = branchList.content?.map(item => {
        const { branchId, branchName } = item;

        return { branchId, branchName };
      });

      ReactDOM.unstable_batchedUpdates(() => {
        setSelectBranchOptions([selectedBranchValue, ...selectOptions]);
        setLoanList(loanList);
        setClientRequestList(clientRequestList);
        setInternalApprovalList(internalApprovalList);
        setTaskScheduleList(taskScheduleList);
        setPotentialPartnerList(potentialPartnerList);
      });
    } catch (error) {
      modal.show(error);
    }
  }

  async function fetchAllAndSetWithSelectedBranch() {
    try {
      const [
        loanList,
        clientRequestList,
        internalApprovalList,
        taskScheduleList,
        performanceList,
        potentialPartnerList,
      ] = await Promise.all([
        requestFinancierLoanList(1, 20, {
          branchId: selectedBranchValue.branchId,
          loanStatusConditions: [LOAN_STATUS.OVERDUE],
        }),
        requestFinancierClientRequest(1, 20, selectedBranchValue.branchId),
        requestFinancierInternalApproval(1, 20, selectedBranchValue.branchId),
        requestFinancierTaskSchedule(1, 20, {
          branchId: selectedBranchValue.branchId,
          scheduleEventTypes: selectedScheduleEventValue.scheduleEventTypes,
          toDate: a30DaysLater,
        }),
        requestFinancierPerformance(1, 8, {
          branchId: selectedBranchValue.branchId,
          collateralType: requestCollateralType,
          currencyType: selectedCurrencyValue.value,
          toDate: today,
          fromDate: aWeekAgo,
        }),
        requestFiDashboardPotentialPartnerFinancing(1, 20),
      ]);

      ReactDOM.unstable_batchedUpdates(() => {
        setSelectedBranchValue({ branchId: selectedBranchValue.branchId, branchName: '' });
        setLoanList(loanList);
        setClientRequestList(clientRequestList);
        setInternalApprovalList(internalApprovalList);
        setTaskScheduleList(taskScheduleList);
        setPerformanceList(performanceList);
        setPotentialPartnerList(potentialPartnerList);
      });
    } catch (error) {
      modal.show(error);
    }
  }

  async function fetchAllAndSetChangedBranch() {
    try {
      const [loanList, clientRequestList, internalApprovalList, taskScheduleList, performanceList] = await Promise.all([
        requestFinancierLoanList(1, 20, {
          branchId: selectedBranchValue.branchId,
          loanStatusConditions: [LOAN_STATUS.OVERDUE],
        }),
        requestFinancierClientRequest(1, 20, selectedBranchValue.branchId),
        requestFinancierInternalApproval(1, 20, selectedBranchValue.branchId),
        requestFinancierTaskSchedule(1, 20, {
          branchId: selectedBranchValue.branchId,
          scheduleEventTypes: Object.values(SCHEDULE_EVENT_TYPE),
          toDate: a30DaysLater,
        }),
        requestFinancierPerformance(1, 8, {
          branchId: selectedBranchValue.branchId,
          collateralType: requestCollateralType,
          currencyType: selectedCurrencyValue.value,
          toDate: today,
          fromDate: aWeekAgo,
        }),
      ]);

      ReactDOM.unstable_batchedUpdates(() => {
        setLoanList(loanList);
        setClientRequestList(clientRequestList);
        setInternalApprovalList(internalApprovalList);
        setTaskScheduleList(taskScheduleList);
        setPerformanceList(performanceList);
      });
    } catch (error) {
      modal.show(error);
    }
  }

  async function fetchAndSetTaskSchedule() {
    try {
      const taskScheduleList = await requestFinancierTaskSchedule(1, 20, {
        branchId: selectedBranchValue.branchId,
        scheduleEventTypes: selectedScheduleEventValue.scheduleEventTypes,
        toDate: a30DaysLater,
      });

      setTaskScheduleList(taskScheduleList);
    } catch (error) {
      modal.show(error);
    }
  }

  async function fetchAndSetPerformance() {
    try {
      const performanceList = await requestFinancierPerformance(1, 8, {
        branchId: selectedBranchValue.branchId,
        collateralType: requestCollateralType,
        currencyType: selectedCurrencyValue.value,
        toDate: today,
        fromDate: aWeekAgo,
      });

      setPerformanceList(performanceList);
    } catch (error) {
      modal.show(error);
    }
  }

  const renderFinancingTypeBadge = (collateralType: COLLATERAL_TYPE): JSX.Element | null => {
    if (collateralType === COLLATERAL_TYPE.AR) {
      return (
        <Badge
          content="VENDOR"
          variant="square"
          bgColor="LIGHT_TURQUOISE"
          fontColor="TURQUOISE"
          style={{ marginLeft: 0, marginRight: '8px' }}
        />
      );
    } else if (collateralType === COLLATERAL_TYPE.INVOICE) {
      return (
        <Badge
          content="DEALER"
          variant="square"
          bgColor="LIGHT_OCHER"
          fontColor="OCHER"
          style={{ marginLeft: 0, marginRight: '8px' }}
        />
      );
    } else {
      return null;
    }
  };

  const getClientRequestEventTypeText = (eventType: APPROVAL_EVENT_TYPE): string => {
    switch (eventType) {
      case APPROVAL_EVENT_TYPE.LOAN_REQUEST_TO_FI:
        return t('text:Financing_Request');
      case APPROVAL_EVENT_TYPE.EARLY_PAYMENT_REQUEST:
        return t('text:Early_Repayment_Request');
      case APPROVAL_EVENT_TYPE.LOAN_EXTENSION_REQUEST:
        return t('text:Financing_Extension_Request');
      case APPROVAL_EVENT_TYPE.INVOICE_REGISTRATION_REQUEST:
        return t('text:Invoice_Registration_Request');
      case APPROVAL_EVENT_TYPE.MULTIPLE_LOAN_REQUEST_TO_FI:
        return t('text:Bulk_Financing_Request');
      case APPROVAL_EVENT_TYPE.AP_REGISTRATION_REQUEST_TO_FI:
        return t('text:AR_Registration_Request');
      default:
        return '-';
    }
  };

  const getInternalApprovalEventTypeText = (eventType: APPROVAL_EVENT_TYPE): string => {
    switch (eventType) {
      case APPROVAL_EVENT_TYPE.ANCHOR_AGREEMENT_REQUEST:
        return t('text:Anchor_Agreement_Review');
      case APPROVAL_EVENT_TYPE.PARTNER_AGREEMENT_REQUEST:
        return t('text:Partner_Agreement_Review');
      case APPROVAL_EVENT_TYPE.LOAN_APPROVAL_REQUEST:
      case APPROVAL_EVENT_TYPE.LOAN_APPROVAL_REQUEST_RETURN:
        return t('text:Financing_Approval_Request');
      case APPROVAL_EVENT_TYPE.LOAN_EXTENSION_APPROVAL_REQUEST:
        return t('text:Financing_Extension_Approval_Request');
      case APPROVAL_EVENT_TYPE.INVOICE_CONFIRMATION_REQUEST_BY_FI:
        return t('text:Invoice_Registration_Request');
      case APPROVAL_EVENT_TYPE.APPROVED_LOAN_REJECTION_REQUEST_RETURN:
      case APPROVAL_EVENT_TYPE.APPROVED_LOAN_REJECTION_REQUEST:
        return t('text:Financing_Rejection_Request');
      case APPROVAL_EVENT_TYPE.MULTIPLE_LOAN_APPROVAL_REQUEST:
      case APPROVAL_EVENT_TYPE.MULTIPLE_LOAN_APPROVAL_REQUEST_RETURN:
        return t('text:Bulk_Financing_Request');
      case APPROVAL_EVENT_TYPE.AP_CONFIRMATION_REQUEST_BY_FI:
        return t('text:AR_Registration_Request');
      default:
        return '-';
    }
  };

  const getInternalApprovalCurrentEventStatusText = (
    eventType: APPROVAL_EVENT_TYPE,
    currentEventStatus: string,
  ): string => {
    switch (eventType) {
      case APPROVAL_EVENT_TYPE.ANCHOR_AGREEMENT_REQUEST: // COMMON_APPROVAL_TYPE
        return t(`code:common-approval-type.${currentEventStatus}`);
      case APPROVAL_EVENT_TYPE.PARTNER_AGREEMENT_REQUEST: // COMMON_APPROVAL_TYPE
        return t(`code:common-approval-type.${currentEventStatus}`);
      case APPROVAL_EVENT_TYPE.LOAN_APPROVAL_REQUEST: // LOAN_APPROVAL_TYPE
      case APPROVAL_EVENT_TYPE.LOAN_APPROVAL_REQUEST_RETURN:
        return t(`code:approval-status.${LOAN_APPROVAL_TYPE.OPERATOR_APPROVAL}`); // eventStatus = DEALER_REQUESTED
      case APPROVAL_EVENT_TYPE.LOAN_EXTENSION_APPROVAL_REQUEST: // EXTENSION_REQUEST_STATUS
        return t(`code:extension-request-status.${currentEventStatus}`);
      case APPROVAL_EVENT_TYPE.INVOICE_CONFIRMATION_REQUEST_BY_FI: // WAITING_INVOICE_APPROVAL_PHASE_STATUS
        return t(`code:waiting-invoice-approval-phase-status-by-financier.${currentEventStatus}`);
      case APPROVAL_EVENT_TYPE.APPROVED_LOAN_REJECTION_REQUEST:
        return t(`text:Requested`);
      case APPROVAL_EVENT_TYPE.APPROVED_LOAN_REJECTION_REQUEST_RETURN:
        return t(`text:Reverted`);
      case APPROVAL_EVENT_TYPE.MULTIPLE_LOAN_APPROVAL_REQUEST:
      case APPROVAL_EVENT_TYPE.MULTIPLE_LOAN_APPROVAL_REQUEST_RETURN:
        return t(`code:multiple-loan-request-approval-type.${COMMON_APPROVAL_TYPE.OPERATOR_REQUEST}`);
      case APPROVAL_EVENT_TYPE.AP_CONFIRMATION_REQUEST_BY_FI:
        return t('text:Reviewed_by_Financier_Operator');
      default:
        return '-';
    }
  };

  const getTaskSchedulerEventTypeText = (eventType: SCHEDULE_EVENT_TYPE): string => {
    switch (eventType) {
      case SCHEDULE_EVENT_TYPE.ANCHOR_AGREEMENT_EXPIRY:
      case SCHEDULE_EVENT_TYPE.PARTNER_AGREEMENT_EXPIRY:
        return t('text:Agreement');
      case SCHEDULE_EVENT_TYPE.LOAN_REPAYMENT:
        return t('text:Repayment');
      case SCHEDULE_EVENT_TYPE.AP_SETTLEMENT:
        return t('text:Settlement');
      default:
        return '-';
    }
  };

  const getTaskSchedulerEventTypeSubText = (eventType: SCHEDULE_EVENT_TYPE, eventIdentifier: string): string => {
    switch (eventType) {
      case SCHEDULE_EVENT_TYPE.ANCHOR_AGREEMENT_EXPIRY:
      case SCHEDULE_EVENT_TYPE.PARTNER_AGREEMENT_EXPIRY:
        return `${t('text:Agreement')} #: ${eventIdentifier}`;
      case SCHEDULE_EVENT_TYPE.LOAN_REPAYMENT:
        return `${t('text:Loan')} #: ${isNil(eventIdentifier) ? 'Not provided' : eventIdentifier}`;
      case SCHEDULE_EVENT_TYPE.AP_SETTLEMENT:
        return `${t('text:AP')} #: ${eventIdentifier}`;
      default:
        return '-';
    }
  };

  const getPotentialPartnerFinancingDashboardTypeText = (
    dashBoardType: POTENTIAL_PARTNER_FINANCING_DASHBOARD_TYPE,
  ): string => {
    switch (dashBoardType) {
      case POTENTIAL_PARTNER_FINANCING_DASHBOARD_TYPE.POTENTIAL_PARTNER_APPLICATION_REQUEST:
        return t('text:New_Submission');
      case POTENTIAL_PARTNER_FINANCING_DASHBOARD_TYPE.POTENTIAL_PARTNER_DOCUMENT_SUBMISSION:
        return t('text:Document_Review');
      default:
        return '-';
    }
  };

  const getTodayPerformanceData = (dataKey: keyof PerformanceData | 'accumRepaidAmount' | 'accumRepaidCount') => {
    const data = performanceList?.content[0];

    if (data) {
      const {
        anchorAgreementCount,
        partnerAgreementCount,
        disbursedLoanAmount,
        disbursedLoanCount,
        overdueLoanAmount,
        overdueLoanCount,
        accumDisbursedLoanAmount,
        accumDisbursedLoanCount,
      } = data;

      const accumRepaidAmount = accumDisbursedLoanAmount - disbursedLoanAmount;
      const accumRepaidCount = accumDisbursedLoanCount - disbursedLoanCount;

      switch (dataKey) {
        case 'anchorAgreementCount':
          return tableValueManage(anchorAgreementCount);
        case 'partnerAgreementCount':
          return tableValueManage(partnerAgreementCount);
        case 'disbursedLoanAmount':
          return tableValueManage(disbursedLoanAmount, t('format:number', { value: disbursedLoanAmount }));
        case 'disbursedLoanCount':
          return tableValueManage(disbursedLoanCount);
        case 'overdueLoanAmount':
          return tableValueManage(overdueLoanAmount, t('format:number', { value: overdueLoanAmount }));
        case 'overdueLoanCount':
          return tableValueManage(overdueLoanCount);
        case 'accumDisbursedLoanAmount':
          return tableValueManage(accumDisbursedLoanAmount, t('format:number', { value: accumDisbursedLoanAmount }));
        case 'accumDisbursedLoanCount':
          return tableValueManage(accumDisbursedLoanCount);
        case 'accumRepaidAmount':
          return tableValueManage(accumRepaidAmount, t('format:number', { value: accumRepaidAmount }));
        case 'accumRepaidCount':
          return tableValueManage(accumRepaidCount);
        default:
          return '-';
      }
    }

    return '-';
  };

  const formatXaxis = (tickItem: string | number) => {
    return t('format:date', {
      value: tickItem,
      key: 'date',
    });
  };

  const formatLeftYaxis = (tickItem: string | number) => {
    return t('format:number', { value: tickItem });
  };

  const formatRightYaxis = (tickItem: string | number) => {
    return String(tickItem);
  };

  const longestLeftYaxisLabelLength = performanceList?.content
    ?.map(data => Math.ceil(data.disbursedLoanAmount))
    .reduce((acc, cur) => (String(cur).length > acc ? String(cur).length : acc), 0);

  const leftYaxisLabelWidth = isNil(longestLeftYaxisLabelLength) ? 60 : longestLeftYaxisLabelLength * 9; // default 60

  const goToDelinquencyDetail = (loanId: number) => {
    history.push(ROUTES_FI.VIEW_TRANSACTION.FINANCING_DETAIL_BUILD_PATH(loanId));
  };

  const goToClientRequestDetail = (item: DashBoardApprovalVOModel) => {
    const { eventId, approvalEventType, relatedPartnerAgreementId, relatedFinancierId } = item;

    switch (approvalEventType) {
      case APPROVAL_EVENT_TYPE.LOAN_REQUEST_TO_FI:
        history.push(ROUTES_FI.VIEW_TRANSACTION.FINANCING_DETAIL_BUILD_PATH(eventId));
        break;
      case APPROVAL_EVENT_TYPE.EARLY_PAYMENT_REQUEST:
        history.push(ROUTES_FI.MANAGE_FINANCING.EARLY_REPAYMENT_DETAIL_BUILD_PATH(eventId));
        break;
      case APPROVAL_EVENT_TYPE.LOAN_EXTENSION_REQUEST:
        history.push(ROUTES_FI.MANAGE_FINANCING.EXTENSION_DETAIL_BUILD_PATH(eventId));
        break;
      case APPROVAL_EVENT_TYPE.INVOICE_REGISTRATION_REQUEST:
        history.push(ROUTES_FI.REGISTER_INVOICE.CONFIRMATION_DETAIL_BUILD_PATH(eventId, relatedFinancierId), {
          dealerAgreementId: relatedPartnerAgreementId,
        });
        break;
      case APPROVAL_EVENT_TYPE.MULTIPLE_LOAN_REQUEST_TO_FI:
        history.push(ROUTES_FI.MANAGE_FINANCING.BULK_APPROVAL_DETAIL_BUILD_PATH(eventId));
        break;
      case APPROVAL_EVENT_TYPE.AP_REGISTRATION_REQUEST_TO_FI:
        history.push(ROUTES_FI.REGISTER_AR.CONFIRMATION_DETAIL_BUILD_PATH(eventId));
        break;
      default:
        break;
    }
  };

  const goToInternalApprovalDetail = (item: DashBoardApprovalVOModel) => {
    const { eventId, requestedEnterpriseId, approvalEventType, collateralType, relatedPartnerAgreementId } = item;

    switch (approvalEventType) {
      case APPROVAL_EVENT_TYPE.ANCHOR_AGREEMENT_REQUEST:
        history.push(ROUTES_FI.MANAGE_ANCHOR.AGREEMENT_WAITING_DETAIL_BUILD_PATH(eventId), {
          supportedCollateralType: collateralType,
        });
        break;
      case APPROVAL_EVENT_TYPE.PARTNER_AGREEMENT_REQUEST:
        history.push(ROUTES_FI.MANAGE_PARTNER.AGREEMENT_WAITING_DETAIL_BUILD_PATH(eventId), {
          supportedCollateralType: collateralType,
        });
        break;
      case APPROVAL_EVENT_TYPE.LOAN_APPROVAL_REQUEST:
      case APPROVAL_EVENT_TYPE.LOAN_APPROVAL_REQUEST_RETURN:
      case APPROVAL_EVENT_TYPE.APPROVED_LOAN_REJECTION_REQUEST:
      case APPROVAL_EVENT_TYPE.APPROVED_LOAN_REJECTION_REQUEST_RETURN:
        history.push(ROUTES_FI.MANAGE_FINANCING.APPROVAL_DETAIL_BUILD_PATH(eventId));
        break;
      case APPROVAL_EVENT_TYPE.LOAN_EXTENSION_APPROVAL_REQUEST:
        history.push(ROUTES_FI.MANAGE_FINANCING.EXTENSION_DETAIL_BUILD_PATH(eventId));
        break;
      case APPROVAL_EVENT_TYPE.INVOICE_CONFIRMATION_REQUEST_BY_FI:
        history.push(ROUTES_FI.REGISTER_INVOICE.CONFIRMATION_DETAIL_BUILD_PATH(eventId, requestedEnterpriseId), {
          dealerAgreementId: relatedPartnerAgreementId,
        });
        break;
      case APPROVAL_EVENT_TYPE.MULTIPLE_LOAN_APPROVAL_REQUEST:
      case APPROVAL_EVENT_TYPE.MULTIPLE_LOAN_APPROVAL_REQUEST_RETURN:
        history.push(ROUTES_FI.MANAGE_FINANCING.BULK_APPROVAL_DETAIL_BUILD_PATH(eventId));
        break;
      case APPROVAL_EVENT_TYPE.AP_CONFIRMATION_REQUEST_BY_FI:
        history.push(ROUTES_FI.REGISTER_AR.CONFIRMATION_DETAIL_BUILD_PATH(eventId));
        break;

      default:
        break;
    }
  };

  const goToTaskSchedulerDetail = (item: DashBoardScheduleVOModel) => {
    const { eventId, scheduleEventType, collateralType } = item;

    switch (scheduleEventType) {
      case SCHEDULE_EVENT_TYPE.ANCHOR_AGREEMENT_EXPIRY:
        history.push(ROUTES_FI.MANAGE_ANCHOR.AGREEMENT_REGISTERED_DETAIL_BUILD_PATH(eventId), {
          supportedCollateralType: collateralType,
        });
        break;
      case SCHEDULE_EVENT_TYPE.PARTNER_AGREEMENT_EXPIRY:
        history.push(ROUTES_FI.MANAGE_PARTNER.AGREEMENT_REGISTERED_DETAIL_BUILD_PATH(eventId), {
          supportedCollateralType: collateralType,
        });
        break;
      case SCHEDULE_EVENT_TYPE.LOAN_REPAYMENT:
        history.push(ROUTES_FI.VIEW_TRANSACTION.FINANCING_DETAIL_BUILD_PATH(eventId));
        break;
      case SCHEDULE_EVENT_TYPE.AP_SETTLEMENT:
        if (collateralType === COLLATERAL_TYPE.AR) {
          history.push(ROUTES_FI.VIEW_TRANSACTION.AR_DETAIL_BUILD_PATH(eventId));
        } else if (collateralType === COLLATERAL_TYPE.INVOICE) {
          history.push(ROUTES_FI.VIEW_TRANSACTION.INVOICE_DETAIL_BUILD_PATH(eventId));
        }
        break;
      default:
        break;
    }
  };

  const isUploadedPartnerApplicationSectionRender = potentialPartnerList && potentialPartnerList.totalElements > 0;

  const isDelinquencyManagementSectionRender = !!(loanList?.content && loanList?.content.length > 0);

  const SECTION_HEIGHT = {
    DELINQUENCY_MANAGEMENT: isDelinquencyManagementSectionRender ? 300 : 0,
    UPLOADED_PARTNER_APPLICATION: isUploadedPartnerApplicationSectionRender ? 340 : 0,
    CLIENT_REQUEST: 340,
    INTERVAL_APPROVAL: 340,
  };

  const upcomingTransactionsTableMaxHeight =
    Object.values(SECTION_HEIGHT).reduce((acc, height) => acc + height, 0) - TABLE_HEIGHT_OVERFLOW_MARGIN;

  return (
    <div className="bg-gray-wrap">
      <HeaderTitle title={`${t('text:Welcome')}, ${name}`} />
      <div className="content-area mb-0">
        {isAdmin && (
          <CustomSelect
            style={{ width: '200px', marginBottom: '20px' }}
            options={selectBranchOptions}
            value={selectedBranchValue}
            getCurrentViewFunc={o => <div>{o.branchName}</div>}
            getOptionViewFunc={o => <div onClick={() => setSelectedBranchValue(o)}>{o.branchName}</div>}
          />
        )}
        <div className="row">
          <CardGroup className="simple-card-left-group" col={8}>
            {isDelinquencyManagementSectionRender && (
              <Card style={{ height: `${SECTION_HEIGHT.DELINQUENCY_MANAGEMENT}px` }}>
                <CardHeader>
                  <h2 className="simple-card-title">{t('text:Delinquency_Management')}</h2>
                  <Badge content={loanList?.totalElements ?? 0} variant="ellipse" fontColor="WHITE" bgColor="RED" />
                </CardHeader>
                <CardBody>
                  <BasicTableBorder basicTableWrapMaxHeight="152px">
                    <BasicTableHeader header={DELINQUENT_HEADERS} />
                    <BasicTableBody numOfCol={DELINQUENT_HEADERS.length}>
                      {loanList?.content.map((item, index) => (
                        <Tr
                          key={index}
                          onClick={() => {
                            goToDelinquencyDetail(item.loanId);
                          }}
                        >
                          <Td data={item.anchorName} />
                          <Td data={item.dealerName} />
                          <Td data={item.repaymentDate} format="date" />
                          <Td data={`+ ${item.overdueDays}`} className="warning" />
                          <Td data={item.disbursedAmount} format="number" />
                          <Td data={item.repaidPrincipalAmount} format="number" />
                        </Tr>
                      ))}
                    </BasicTableBody>
                  </BasicTableBorder>
                </CardBody>
              </Card>
            )}
            {isUploadedPartnerApplicationSectionRender && (
              <Card style={{ height: `${SECTION_HEIGHT.UPLOADED_PARTNER_APPLICATION}px` }}>
                <CardHeader>
                  <h2 className="simple-card-title">{t('text:Uploaded_Partner_Application')}</h2>
                  <Badge
                    content={potentialPartnerList?.totalElements ?? 0}
                    variant="ellipse"
                    fontColor="WHITE"
                    bgColor="YELLOW"
                  />
                </CardHeader>
                <CardBody>
                  <BasicTableBorder>
                    <BasicTableHeader header={POTENTIAL_PARTNER_HEADERS} />
                    <BasicTableBody numOfCol={POTENTIAL_PARTNER_HEADERS.length}>
                      {potentialPartnerList?.content.map((item, index) => (
                        <Tr
                          key={index}
                          onClick={() => {
                            history.push(
                              ROUTES_FI.MANAGE_UPLOADED_PARTNER.ACQUISITION_DETAIL_BUILD_PATH(
                                item.potentialPartnerFinancingApplicationId,
                              ),
                            );
                          }}
                        >
                          <Td data={item.createDateTime} format="datetime" />
                          <Td data={item.potentialPartnerName} />
                          <Td data={item.anchorClientName} />
                          <Td
                            className="fw-bold"
                            data={getPotentialPartnerFinancingDashboardTypeText(
                              item.potentialPartnerFinancingDashboardType,
                            )}
                          />
                        </Tr>
                      ))}
                    </BasicTableBody>
                  </BasicTableBorder>
                </CardBody>
              </Card>
            )}
            <Card style={{ height: `${SECTION_HEIGHT.CLIENT_REQUEST}px` }}>
              <CardHeader>
                <h2 className="simple-card-title">{t('text:Client_Request')}</h2>
                <Badge
                  content={clientRequestList?.totalElements ?? 0}
                  variant="ellipse"
                  fontColor="WHITE"
                  bgColor="YELLOW"
                />
              </CardHeader>
              <CardBody>
                <BasicTableBorder>
                  <BasicTableHeader header={CLIENT_REQUEST_HEADERS} />
                  <BasicTableBody numOfCol={CLIENT_REQUEST_HEADERS.length}>
                    {clientRequestList?.content.map((item, index) => (
                      <Tr
                        key={index}
                        onClick={() => {
                          goToClientRequestDetail(item);
                        }}
                      >
                        <Td data={item.updatedDateTime} format="datetime" />
                        <Td
                          data={
                            // 금융사 대시보드에 자동 대출 신청이 생성될 때, 요청 고객은 앵커 기업이 아닌 파트너 기업 이어야 함.
                            item.approvalEventType === APPROVAL_EVENT_TYPE.LOAN_REQUEST_TO_FI
                              ? item.relatedPartnerClientName
                              : item.requestedEnterpriseName
                          }
                        />
                        <Td>
                          <div className="d-flex align-items-center">
                            {renderFinancingTypeBadge(item.collateralType)}
                            {getClientRequestEventTypeText(item.approvalEventType)}
                          </div>
                        </Td>
                      </Tr>
                    ))}
                  </BasicTableBody>
                </BasicTableBorder>
              </CardBody>
            </Card>
            <Card style={{ height: `${SECTION_HEIGHT.INTERVAL_APPROVAL}px` }}>
              <CardHeader>
                <h2 className="simple-card-title">{t('text:Internal_Approval')}</h2>
                <Badge
                  content={internalApprovalList?.totalElements ?? 0}
                  variant="ellipse"
                  fontColor="WHITE"
                  bgColor="YELLOW"
                />
              </CardHeader>
              <CardBody>
                <BasicTableBorder>
                  <BasicTableHeader header={INTERNAL_APPROVAL_HEADERS} />
                  <BasicTableBody numOfCol={INTERNAL_APPROVAL_HEADERS.length}>
                    {internalApprovalList?.content.map((item, index) => (
                      <Tr
                        key={index}
                        onClick={() => {
                          goToInternalApprovalDetail(item);
                        }}
                      >
                        <Td data={item.updatedDateTime} format="datetime" />
                        <Td>
                          <div className="d-flex align-items-center">
                            {renderFinancingTypeBadge(item.collateralType)}
                            {getInternalApprovalEventTypeText(item.approvalEventType)}
                          </div>
                        </Td>
                        <Td
                          className="fw-bold"
                          data={getInternalApprovalCurrentEventStatusText(
                            item.approvalEventType,
                            item.currentEventStatus,
                          )}
                        />
                        <Td data={item.requestedUserName} />
                      </Tr>
                    ))}
                  </BasicTableBody>
                </BasicTableBorder>
              </CardBody>
            </Card>
          </CardGroup>
          <CardGroup className="simple-card-right-group" col={4}>
            <Card style={{ height: '100%' }}>
              <CardHeader>
                <h2 className="simple-card-title">{t('text:Upcoming_Transactions')}</h2>
              </CardHeader>
              <CardBody>
                <>
                  <div className="custom-select-wrapper mb-4">
                    <CustomSelect
                      style={{
                        width: 'max-width',
                        fontSize: '12px',
                        border: '1px solid #E0E0E0',
                        borderRadius: '3px',
                      }}
                      options={selectedScheduleEventOptions}
                      value={selectedScheduleEventValue}
                      getCurrentViewFunc={o => <div>{o.eventName}</div>}
                      getOptionViewFunc={o => <div onClick={() => setSelectedScheduleEventValue(o)}>{o.eventName}</div>}
                    />
                  </div>
                  <BasicTableBorder basicTableWrapMaxHeight={upcomingTransactionsTableMaxHeight}>
                    <BasicTableHeader header={TASK_SCHEDULER_HEADERS} />
                    <BasicTableBody numOfCol={TASK_SCHEDULER_HEADERS.length}>
                      {taskScheduleList?.content.map((item, index) => (
                        <Tr
                          key={index}
                          onClick={() => {
                            goToTaskSchedulerDetail(item);
                          }}
                        >
                          <Td className="fw-bold" data={item.eventDate} format="date" />
                          <Td>
                            <div className="d-flex align-items-center fw-bold">
                              {renderFinancingTypeBadge(item.collateralType)}
                              {getTaskSchedulerEventTypeText(item.scheduleEventType)}
                            </div>
                            <div className="sub-content">
                              {getTaskSchedulerEventTypeSubText(item.scheduleEventType, item.eventIdentifier)}
                            </div>
                          </Td>
                        </Tr>
                      ))}
                    </BasicTableBody>
                  </BasicTableBorder>
                </>
              </CardBody>
            </Card>
          </CardGroup>
        </div>
        {!isNil(selectedBranchValue.branchId) && (
          <Card style={{ marginTop: '16px' }}>
            <CardHeader>
              <h2 className="simple-card-title">{t('text:Performance')}</h2>
            </CardHeader>
            <CardBody>
              <>
                <Tab
                  tabType="financeType"
                  className="w-100"
                  tabList={[
                    {
                      onClickTab: () => {
                        setRequestCollateralType(COLLATERAL_TYPE.AR);
                      },
                    },
                    {
                      onClickTab: () => {
                        setRequestCollateralType(COLLATERAL_TYPE.INVOICE);
                      },
                    },
                  ]}
                  tabViewList={[]}
                />
                <div className="custom-select-wrapper mt-4 mb-4 ms-2">
                  <div className="custom-select-caption">{t('text:Agreement_Currency')}</div>
                  <CustomSelect
                    style={{
                      width: 'max-width',
                      fontSize: '12px',
                      border: '1px solid #E0E0E0',
                      borderRadius: '3px',
                    }}
                    options={selectedCurrencyOptions}
                    value={selectedCurrencyValue}
                    getCurrentViewFunc={o => <div>{o.label}</div>}
                    getOptionViewFunc={o => <div onClick={() => setSelectedCurrencyValue(o)}>{o.label}</div>}
                  />
                </div>
                <Card bgColor="#F6F6F6">
                  <CardBody>
                    <div className="d-flex">
                      <div className="w-50 me-4 ">
                        <div className="d-flex">
                          <div className="chart-info p-3 me-4 bg-white">
                            <div className="chart-info-title">{t('text:Anchor_Master_Agreement')}</div>
                            <div className="chart-info-lg-data">{getTodayPerformanceData('anchorAgreementCount')}</div>
                          </div>
                          <div className="chart-info p-3 bg-white">
                            <div className="chart-info-title">{t('text:Partner_Master_Agreement')}</div>
                            <div className="chart-info-lg-data">{getTodayPerformanceData('partnerAgreementCount')}</div>
                          </div>
                        </div>
                        <div className="mt-4 p-3 bg-white">
                          <div className="chart-info-title">{t('text:Financing_Info')}</div>
                          <div className="d-flex mt-2">
                            <div className="d-flex w-100">
                              <div className="chart-info pt-3 pb-3 me-4 border-bottom-light-gray">
                                <div className="chart-info-sub-title">{t('text:Accum_Financing_Amt')}</div>
                                <div className="chart-info-sm-data">
                                  {getTodayPerformanceData('accumDisbursedLoanAmount')}
                                </div>
                              </div>
                              <div className="chart-info pt-3 pb-3 border-bottom-light-gray">
                                <div className="chart-info-sub-title">{`${t('text:Accum_Financing')} #`}</div>
                                <div className="chart-info-sm-data">
                                  {getTodayPerformanceData('accumDisbursedLoanCount')}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="d-flex">
                            <div className="d-flex w-100">
                              <div className="chart-info pt-3 pb-3 me-4 border-bottom-light-gray">
                                <div className="chart-info-sub-title">{t('text:Accum_Repaid_Amt')}</div>
                                <div className="chart-info-sm-data">{getTodayPerformanceData('accumRepaidAmount')}</div>
                              </div>
                              <div className="chart-info pt-3 pb-3 border-bottom-light-gray">
                                <div className="chart-info-sub-title">{`${t('text:Accum_Repaid')} #`}</div>
                                <div className="chart-info-sm-data">{getTodayPerformanceData('accumRepaidCount')}</div>
                              </div>
                            </div>
                          </div>
                          <div className="d-flex">
                            <div className="d-flex w-100">
                              <div className="chart-info pt-3 pb-3 me-4 border-bottom-light-gray">
                                <div className="chart-info-sub-title">{t('text:Outst_Balance')}</div>
                                <div className="chart-info-sm-data">
                                  {getTodayPerformanceData('disbursedLoanAmount')}
                                </div>
                              </div>
                              <div className="chart-info pt-3 pb-3 border-bottom-light-gray">
                                <div className="chart-info-sub-title">{`${t('text:Outst_Financing')} #`}</div>
                                <div className="chart-info-sm-data">
                                  {getTodayPerformanceData('disbursedLoanCount')}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="d-flex">
                            <div className="d-flex w-100">
                              <div className="chart-info pt-3 pb-3 me-4 border-bottom-light-gray">
                                <div className="chart-info-sub-title">{t('text:Outst_Delinquent_Amt')}</div>
                                <div className="chart-info-sm-data">{getTodayPerformanceData('overdueLoanAmount')}</div>
                              </div>
                              <div className="chart-info pt-3 pb-3 border-bottom-light-gray">
                                <div className="chart-info-sub-title">{`${t('text:Outst_Delinquent')} #`}</div>
                                <div className="chart-info-sm-data">{getTodayPerformanceData('overdueLoanCount')}</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="w-50 bg-white">
                        <BarLineChart
                          chartData={[...(performanceList?.content || [])].reverse()}
                          leftYaxisId="amount"
                          rightYaxisId="agreementCount"
                          xAxisDataKey={getDataKey<DashboardFinancierPerformanceVOModel>('baseDate')}
                          mainLabelKey={getDataKey<DashboardFinancierPerformanceVOModel>('baseDate')}
                          formatXaxis={formatXaxis}
                          formatLeftYaxis={formatLeftYaxis}
                          formatRightYaxis={formatRightYaxis}
                          rightYAxisAllowDecimals={false}
                          leftYaxisLabelWidth={leftYaxisLabelWidth}
                          additionalPayLoad={[
                            { name: `${t('text:Anchor_Master_Agreement')} #`, key: 'anchorAgreementCount' },
                          ]}
                        >
                          <>
                            <Bar
                              name={t('text:Outst_Balance')}
                              yAxisId="amount"
                              dataKey={getDataKey<DashboardFinancierPerformanceVOModel>('disbursedLoanAmount')}
                              barSize={30}
                              fill="#74B9DD"
                              animationDuration={500}
                            />
                            <Line
                              name={`${t('text:Partner_Master_Agreement')} #`}
                              yAxisId="agreementCount"
                              type="monotone"
                              dataKey={getDataKey<DashboardFinancierPerformanceVOModel>('partnerAgreementCount')}
                              stroke="#1F749E"
                              animationDuration={500}
                            />
                          </>
                        </BarLineChart>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </>
            </CardBody>
          </Card>
        )}
      </div>
    </div>
  );
}

export default FinancierDashboard;
