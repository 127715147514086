import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { isEmpty } from 'lodash-es';

import Button from 'components/stateless/Button/Button';
import { FormBorder } from 'components/stateless/CommonForm/FormBorder';
import { FormContents } from 'components/stateless/CommonForm/FormContents';
import FormSelect from 'components/stateless/CommonForm/FormSelect';
import QuestionButton from 'components/stateless/QuestionButton';
import SectionMessage from 'components/stateless/SectionMessage/SectionMessage';
import getSelectOptions from 'constants/selectOptions';
import { EXPORT_DOCUMENT_TYPE } from 'enums';
import { exportPDF } from 'utils/exportFile/exportPDF';
import type { PDFExporterProps } from 'utils/exportFile/exportPDF.d';
import { showLoadingUI, unShowLoadingUI } from 'utils/loadingUIManager/loadingUIManager';
import useModal from 'utils/modal/useModal';
import exportCSVFile from 'utils/spreadSheet/export/exportCSVFile';
import exportExcelFile from 'utils/spreadSheet/export/exportExcelFile';
import type { ExportSpreadSheetProps } from 'utils/spreadSheet/types';

import './Exporter.scss';

type ExporterDocumentType = keyof typeof EXPORT_DOCUMENT_TYPE;

type BulkImportExporterDocumentType = EXPORT_DOCUMENT_TYPE.EXCEL | EXPORT_DOCUMENT_TYPE.CSV;

interface ExporterProps<T> {
  spreadSheetExporterProps?: ExportSpreadSheetProps<T>;
  pdfExporterProps?: PDFExporterProps;
}

interface ExporterSelectOptions<T> {
  documentType: T;
}

function Exporter<T>({ spreadSheetExporterProps, pdfExporterProps }: ExporterProps<T>): JSX.Element {
  const { t } = useTranslation(['format']);
  const modal = useModal();

  const isBulkImport = !isEmpty(spreadSheetExporterProps?.bulkImportOptions);
  const hasCustomExcelExportDocumentType = !isEmpty(spreadSheetExporterProps?.exportDocumentType);
  const { register, getValues, watch } = useForm<ExporterSelectOptions<ExporterDocumentType>>();

  const formWatcher = watch();

  const onClickDownload = async (): Promise<void> => {
    const data = getValues();
    const { EXCEL, CSV, PDF_A4, PDF_LETTER } = EXPORT_DOCUMENT_TYPE;

    if (data.documentType === EXCEL && spreadSheetExporterProps) {
      showLoadingUI();
      await exportExcelFile(spreadSheetExporterProps, isBulkImport);
      unShowLoadingUI();
    } else if (data.documentType === CSV && spreadSheetExporterProps) {
      showLoadingUI();
      await exportCSVFile(spreadSheetExporterProps, isBulkImport);
      unShowLoadingUI();
    } else if ((data.documentType === PDF_A4 || data.documentType === PDF_LETTER) && pdfExporterProps) {
      try {
        showLoadingUI();
        const pdf = exportPDF(data.documentType);
        await pdf.viewWithPdf();
        unShowLoadingUI();
      } catch (error) {
        modal.show(error);
        unShowLoadingUI();
      }
    } else {
      modal.show("Critical error, please ask to Fin2b's developer");
    }
  };

  const disabledExportButton = (): boolean => {
    const { documentType } = formWatcher;
    const { EXCEL, CSV, PDF_A4, PDF_LETTER } = EXPORT_DOCUMENT_TYPE;
    const emptyExcelExporterProps = spreadSheetExporterProps === undefined;
    const emptyPdfExporterProps = pdfExporterProps === undefined;

    if (documentType === undefined) return true;
    if (documentType === EXCEL && emptyExcelExporterProps) return true;
    if (documentType === CSV && emptyExcelExporterProps) return true;
    if (documentType === PDF_A4 && emptyPdfExporterProps) return true;
    if (documentType === PDF_LETTER && emptyPdfExporterProps) return true;

    return false;
  };

  const renderTableHeader = () => {
    return (
      <>
        <colgroup>
          {pdfExporterProps?.tableHeaders?.map((value, index) => (
            <col key={index} style={{ width: `${value.colWidths}px` }} />
          ))}
        </colgroup>
        <thead>
          {pdfExporterProps?.mergedTableHeaders &&
            pdfExporterProps?.mergedTableHeaders.map((data, outerIndex) => {
              return (
                <tr key={outerIndex} className="merged-virtual-table-header">
                  {data.map((innerData, index) => (
                    <th key={`mergedTable${index}`} colSpan={innerData.colSpan} className={innerData.className}>
                      {innerData.headerText}
                    </th>
                  ))}
                </tr>
              );
            })}
          <tr id="virtual-table-header">
            {pdfExporterProps?.tableHeaders?.map((value, index) => (
              <th scope="col" key={index} className={value.className}>
                {value.headerText}
                {value.hasStatusDescription && <QuestionButton onClick={value.showStatusDescriptionFunc} />}
                {value.hasCheckBox && (
                  <div className="text-center">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      onChange={e => {
                        value.handleClickAllCheckBox !== undefined && value.handleClickAllCheckBox(e);
                      }}
                    />
                  </div>
                )}
              </th>
            ))}
          </tr>
        </thead>
      </>
    );
  };

  const getSelectFormatOptions = () => {
    if (hasCustomExcelExportDocumentType) {
      return getSelectOptions<EXPORT_DOCUMENT_TYPE>(
        'EXPORT_DOCUMENT_TYPE',
        Object.values(spreadSheetExporterProps?.exportDocumentType!),
      );
    }

    if (isBulkImport) {
      return getSelectOptions<BulkImportExporterDocumentType>('BULK_IMPORT_EXPORT_DOCUMENT_TYPE');
    }

    return getSelectOptions<EXPORT_DOCUMENT_TYPE>('EXPORT_DOCUMENT_TYPE');
  };

  return (
    <div className="modal-container">
      <div className="mb-3">
        <SectionMessage message={[t('text:Select_the_file_format_below_and_click_on_the_Export_button_to_download')]} />
      </div>
      <FormBorder>
        <FormContents>
          <div className="row">
            <FormSelect
              col={12}
              label={t('text:Select_Format')}
              placeholderOptions={{ show: true }}
              name="documentType"
              ref={register}
              selectOptions={getSelectFormatOptions()}
            />
          </div>
          <div className="row">
            <Button onClick={onClickDownload} disabled={disabledExportButton()} fullWidth>
              {t('text:Export')}
            </Button>
          </div>
        </FormContents>
      </FormBorder>
      <div className="clearfix" />
      {/* PDF Export (Start, Now showed on screen) */}
      {pdfExporterProps && (
        <div className="pdf-containment">
          <div className="pdf-container">
            <table className="table-border" id="pdf-target">
              {renderTableHeader()}
              <tbody id="virtual-table-body" />
            </table>
            <table className="table-border">
              {renderTableHeader()}
              <tbody id="original-data">
                {isEmpty(pdfExporterProps.tableBody) ? (
                  <tr className="virtual-table-row">
                    <td colSpan={pdfExporterProps.tableHeaders?.length} className="text-center">
                      {t('text:no_data_available')}
                    </td>
                  </tr>
                ) : (
                  pdfExporterProps.tableBody
                )}
              </tbody>
            </table>
          </div>
        </div>
      )}
      {/* PDF Export (END) */}
    </div>
  );
}

export default Exporter;
