import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import AnchorDealerRegistrationResultModal from 'components/stateless/Modal/common/dealer/AnchorDealerRegistrationResultModal';
import { ANCHOR_DEALER_STATUS, REQUEST_RESULT } from 'enums';
import usePageable from 'hooks/usePageable';
import type Pageable from 'models/Pageable';
import type { AnchorDealerVOModel } from 'models/vo/AnchorDealerVO';
import type { WaitingAnchorDealerVOModel } from 'models/vo/WaitingAnchorDealerVO';
import { setFormValues } from 'utils/form/setFormValues';
import { requestAnchorDealerList } from 'utils/http/api/anchor/anchor-dealers';
import type { AnchorDealerListRequest } from 'utils/http/api/anchor/anchor-dealers/request';
import {
  requestAnchorWaitingAnchorDealerApprove,
  requestAnchorWaitingAnchorDealerCancel,
  requestAnchorWaitingAnchorDealerList,
  requestAnchorWaitingAnchorDealerReject,
} from 'utils/http/api/anchor/waiting-anchor-dealers';
import type { AnchorWaitingAnchorDealerApproveRequest } from 'utils/http/api/anchor/waiting-anchor-dealers/request';
import type { AnchorWaitingAnchorDealerListRequest } from 'utils/http/api/anchor/waiting-anchor-dealers/request';
import { setApprovalTypesValue } from 'utils/logic/setApprovalTypesValue';
import { ModalSize } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';
import {
  addSearchParams,
  getParsedSearchParams,
  makeSearchParamsPattern,
  updateSearchParams,
} from 'utils/searchParams';

const EXCEL_EXPORT_MAX_ROW_COUNT = 1000;

interface AnchorDealerRegistrationListPageStateType {
  waitingListResult: Pageable<WaitingAnchorDealerVOModel[]>;
  waitingListResultForTabCount: Pageable<WaitingAnchorDealerVOModel[]>;
  failedListResult: Pageable<WaitingAnchorDealerVOModel[]>;
  completedListResult: Pageable<AnchorDealerVOModel[]>;
}

const WAITING_LIST_QS_KEY = 'ac-waiting-list';
const COMPLETED_LIST_QS_KEY = 'ac-completed-list';
const FAILED_LIST_QS_KEY = 'ac-failed-list';

export function useAnchorDealerRegistrationListPageState() {
  const modal = useModal();
  const { t } = useTranslation();

  const { pageable: waitingListPageable, setPageable: setWaitingListPageable } = usePageable(WAITING_LIST_QS_KEY);
  const { pageable: completedListPageable, setPageable: setCompletedListPageable } = usePageable(COMPLETED_LIST_QS_KEY);
  const { pageable: failedListPageable, setPageable: setFailedListPageable } = usePageable(FAILED_LIST_QS_KEY);

  const waitingListSearchForm = useForm<AnchorWaitingAnchorDealerListRequest>();
  const completedListSearchForm = useForm<AnchorDealerListRequest>();
  const failedListSearchForm = useForm<AnchorWaitingAnchorDealerListRequest>();

  const getFailedTabSearchValues = (searchData?: AnchorWaitingAnchorDealerListRequest) => {
    if (searchData?.anchorDealerStatuses) {
      return {
        ...searchData,
        hasTargetAnchorDealer: false,
      };
    } else
      return {
        ...searchData,
        hasTargetAnchorDealer: false,
        anchorDealerStatuses: [
          ANCHOR_DEALER_STATUS.ALREADY_REGISTERED_ANCHOR_DEALER,
          ANCHOR_DEALER_STATUS.ON_APPROVAL_PROGRESS_ANCHOR_DEALER,
        ],
      };
  };

  const setWaitingTabSearchValue = (formSearchData?: AnchorWaitingAnchorDealerListRequest) => {
    const getSearchFormData: AnchorWaitingAnchorDealerListRequest = formSearchData
      ? formSearchData
      : waitingListSearchForm.getValues();

    const { waitingForApprovalTypeData, notCancelledWaitingForApprovalTypeData } = setApprovalTypesValue(
      getSearchFormData.approvalTypes,
    );

    const waitingForApprovalSearchData: AnchorWaitingAnchorDealerListRequest = {
      ...getSearchFormData,
      approvalTypes: waitingForApprovalTypeData,
      hasTargetAnchorDealer: false,
      anchorDealerStatuses: [ANCHOR_DEALER_STATUS.REGISTERED],
    };

    const notCancelledWaitingForApprovalSearchData: AnchorWaitingAnchorDealerListRequest = {
      ...getSearchFormData,
      approvalTypes: notCancelledWaitingForApprovalTypeData,
      hasTargetAnchorDealer: false,
      anchorDealerStatuses: [ANCHOR_DEALER_STATUS.REGISTERED],
    };

    return {
      waitingForApprovalSearchData,
      notCancelledWaitingForApprovalSearchData,
    };
  };

  const [dataState, setDataState] = useState<AnchorDealerRegistrationListPageStateType>({
    waitingListResult: {} as Pageable<WaitingAnchorDealerVOModel[]>,
    failedListResult: {} as Pageable<WaitingAnchorDealerVOModel[]>,
    completedListResult: {} as Pageable<AnchorDealerVOModel[]>,
    waitingListResultForTabCount: {} as Pageable<WaitingAnchorDealerVOModel[]>,
  });

  const constructAndAddSearchParams = () => {
    const { waitingForApprovalSearchData } = setWaitingTabSearchValue();

    const waitingTabQs = makeSearchParamsPattern(
      {
        ...waitingForApprovalSearchData,
        pageNumber: waitingListPageable.currentPage,
        rowCount: waitingListPageable.sizePerPage,
      },
      WAITING_LIST_QS_KEY,
    );
    const failedTabQs = makeSearchParamsPattern(
      {
        ...getFailedTabSearchValues(),
        pageNumber: failedListPageable.currentPage,
        rowCount: failedListPageable.sizePerPage,
      },
      FAILED_LIST_QS_KEY,
    );

    const completedTabQs = makeSearchParamsPattern(
      {
        pageNumber: completedListPageable.currentPage,
        rowCount: completedListPageable.sizePerPage,
      },
      COMPLETED_LIST_QS_KEY,
    );

    addSearchParams(waitingTabQs + failedTabQs + completedTabQs);
  };
  const fetchAll = async (): Promise<void> => {
    setFormValues<AnchorWaitingAnchorDealerListRequest>(
      waitingListSearchForm.setValue,
      getParsedSearchParams(WAITING_LIST_QS_KEY).formSearchData,
    );
    setFormValues<AnchorWaitingAnchorDealerListRequest>(
      failedListSearchForm.setValue,
      getParsedSearchParams(FAILED_LIST_QS_KEY).formSearchData,
    );
    setFormValues<AnchorWaitingAnchorDealerListRequest>(
      completedListSearchForm.setValue,
      getParsedSearchParams(COMPLETED_LIST_QS_KEY).formSearchData,
    );

    constructAndAddSearchParams();

    const { waitingForApprovalSearchData, notCancelledWaitingForApprovalSearchData } = setWaitingTabSearchValue();

    try {
      const [waitingList, waitingListForTabCount, failedList, completedList] = await Promise.all([
        requestAnchorWaitingAnchorDealerList(
          waitingListPageable.currentPage,
          waitingListPageable.sizePerPage,
          waitingForApprovalSearchData,
        ),
        requestAnchorWaitingAnchorDealerList(0, 1, notCancelledWaitingForApprovalSearchData),
        requestAnchorWaitingAnchorDealerList(
          failedListPageable.currentPage,
          failedListPageable.sizePerPage,
          getFailedTabSearchValues(failedListSearchForm.getValues()),
        ),

        requestAnchorDealerList(
          completedListPageable.currentPage,
          completedListPageable.sizePerPage,
          completedListSearchForm.getValues(),
        ),
      ]);

      setDataState(prevState => ({
        ...prevState,
        waitingListResult: waitingList,
        completedListResult: completedList,
        failedListResult: failedList,
        waitingListResultForTabCount: waitingListForTabCount,
      }));

      setWaitingListPageable(waitingList);
      setFailedListPageable(failedList);
      setCompletedListPageable(completedList);
    } catch (error) {
      modal.show(error);
    }
  };

  const waitingListFetchUtils = () => {
    const fetchWaitingList = async (
      pageNumber: number,
      rowCount: number,
      data: AnchorWaitingAnchorDealerListRequest,
    ) => {
      try {
        const { waitingForApprovalSearchData, notCancelledWaitingForApprovalSearchData } =
          setWaitingTabSearchValue(data);

        const [waitingList, waitingListForTabCount] = await Promise.all([
          requestAnchorWaitingAnchorDealerList(pageNumber, rowCount, waitingForApprovalSearchData),
          requestAnchorWaitingAnchorDealerList(0, 1, notCancelledWaitingForApprovalSearchData),
        ]);

        updateSearchParams(
          {
            ...data,
            pageNumber,
            rowCount,
          },
          WAITING_LIST_QS_KEY,
        );
        setDataState(prevState => ({
          ...prevState,
          waitingListResult: waitingList,
          waitingListResultForTabCount: waitingListForTabCount,
        }));

        setWaitingListPageable(waitingList);
      } catch (error) {
        modal.show(error);
      }
    };

    const handleClickSearchWaitingList = async () => {
      await fetchWaitingList(0, waitingListPageable.sizePerPage, waitingListSearchForm.getValues());
    };

    const paginateWaitingList = async (pageNumber: number, rowCount: number) => {
      await fetchWaitingList(pageNumber, rowCount, getParsedSearchParams(WAITING_LIST_QS_KEY).formSearchData);
    };

    const cancelRequest = async (ids: number[]): Promise<REQUEST_RESULT> => {
      try {
        await requestAnchorWaitingAnchorDealerCancel(ids);
        fetchAll();

        return REQUEST_RESULT.SUCCESS;
      } catch (error) {
        modal.show(error);

        return REQUEST_RESULT.FAIL;
      }
    };

    const revertRequest = async (ids: number[], reason: string): Promise<REQUEST_RESULT> => {
      try {
        await requestAnchorWaitingAnchorDealerReject(ids, reason);
        fetchAll();

        return REQUEST_RESULT.SUCCESS;
      } catch (error) {
        modal.show(error);

        return REQUEST_RESULT.FAIL;
      }
    };

    const approveRequest = async (data: AnchorWaitingAnchorDealerApproveRequest): Promise<REQUEST_RESULT> => {
      try {
        const response = await requestAnchorWaitingAnchorDealerApprove(data);
        modal.show(<AnchorDealerRegistrationResultModal requestResult={response} />, {
          modalSize: ModalSize.XL,
          closeBtnText: t('text:OK'),
          closeBtnCb: () => fetchAll(),
        });

        return REQUEST_RESULT.SUCCESS;
      } catch (error) {
        modal.show(error);

        return REQUEST_RESULT.FAIL;
      }
    };

    const getExcelExportWaitingList = async () => {
      const { waitingForApprovalSearchData } = setWaitingTabSearchValue(waitingListSearchForm.getValues());

      return await requestAnchorWaitingAnchorDealerList(0, EXCEL_EXPORT_MAX_ROW_COUNT, waitingForApprovalSearchData);
    };

    return {
      handleClickSearch: handleClickSearchWaitingList,
      paginate: paginateWaitingList,
      cancelRequest,
      revertRequest,
      approveRequest,
      fetchWaitingList,
      getExcelExportWaitingList,
    };
  };

  const failedListFetchUtils = () => {
    const fetchFailedList = async (
      pageNumber: number,
      rowCount: number,
      data: AnchorWaitingAnchorDealerListRequest,
    ) => {
      try {
        const failedList = await requestAnchorWaitingAnchorDealerList(
          pageNumber,
          rowCount,
          getFailedTabSearchValues(data),
        );
        updateSearchParams(
          {
            ...data,
            pageNumber,
            rowCount,
          },
          FAILED_LIST_QS_KEY,
        );

        setDataState(prevState => ({
          ...prevState,
          failedListResult: failedList,
        }));

        setFailedListPageable(failedList);
      } catch (error) {
        modal.show(error);
      }
    };

    const onClickSearchFailedList = async () => {
      await fetchFailedList(0, failedListPageable.sizePerPage, failedListSearchForm.getValues());
    };

    const paginateFailedList = async (pageNumber: number, rowCount: number) => {
      await fetchFailedList(pageNumber, rowCount, getParsedSearchParams(FAILED_LIST_QS_KEY).formSearchData);
    };

    const getExcelExportFailedList = async () => {
      return await requestAnchorWaitingAnchorDealerList(
        0,
        EXCEL_EXPORT_MAX_ROW_COUNT,
        getFailedTabSearchValues(failedListSearchForm.getValues()),
      );
    };

    return {
      handleClickSearch: onClickSearchFailedList,
      paginate: paginateFailedList,
      getExcelExportFailedList,
    };
  };

  const completedListFetchUtils = () => {
    const fetchCompletedList = async (pageNumber: number, rowCount: number, data: any) => {
      try {
        const completedList = await requestAnchorDealerList(pageNumber, rowCount, data);

        updateSearchParams(
          {
            ...data,
            pageNumber,
            rowCount,
          },
          COMPLETED_LIST_QS_KEY,
        );

        setDataState(prevState => ({
          ...prevState,
          completedListResult: completedList,
        }));

        setCompletedListPageable(completedList);
      } catch (error) {
        modal.show(error);
      }
    };

    const onClickSearchCompletedList = async () => {
      await fetchCompletedList(0, completedListPageable.sizePerPage, completedListSearchForm.getValues());
    };

    const paginateCompletedList = async (pageNumber: number, rowCount: number) => {
      await fetchCompletedList(pageNumber, rowCount, getParsedSearchParams(COMPLETED_LIST_QS_KEY).formSearchData);
    };

    return {
      handleClickSearch: onClickSearchCompletedList,
      paginate: paginateCompletedList,
    };
  };

  return {
    fetchAll,
    state: dataState,
    useForms: {
      waitingListSearchForm,
      completedListSearchForm,
      failedListSearchForm,
    },
    fetchUtils: {
      waitingListFetchUtils,
      completedListFetchUtils,
      failedListFetchUtils,
    },
    pageableObjects: {
      waitingListPageable,
      completedListPageable,
      failedListPageable,
    },
  };
}
