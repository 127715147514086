import { useTranslation } from 'react-i18next';

import TableBody from 'components/stateless/Table/TableBody';
import TableBorder from 'components/stateless/Table/TableBorder';
import type { HeaderType } from 'components/stateless/Table/TableHeader';
import TableHeader from 'components/stateless/Table/TableHeader';
import Td from 'components/stateless/Table/Td';
import TdLink from 'components/stateless/Table/TdLink';
import Tr from 'components/stateless/Table/Tr';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { ROUTES_FI } from 'constants/routes/financier';
import type { DealerAgreementVOModel } from 'models/vo/DealerAgreementVO';

interface FinancierSCPartnerAcquisitionDetailPartnerMasterAgreementProps {
  dealerAgreementInfo: DealerAgreementVOModel;
}
function FinancierSCPartnerAcquisitionDetailPartnerMasterAgreement({
  dealerAgreementInfo,
}: FinancierSCPartnerAcquisitionDetailPartnerMasterAgreementProps) {
  const { t } = useTranslation(['format']);

  const renderPartnerAgreement = () => {
    const acAgreementListTableHeaders: HeaderType[] = [
      {
        headerText: t('text:Partner_Name'),
        colWidths: 120,
      },
      {
        headerText: t('text:Partner_Master_Agreement_Number'),
        colWidths: 200,
      },
      {
        headerText: t('text:Currency'),
        colWidths: 120,
      },
      {
        headerText: t('text:Responsible_Branch_Code'),
        colWidths: 120,
      },
      {
        headerText: t('text:Responsible_Branch_Name'),
        colWidths: 180,
      },
      {
        headerText: t('text:Agreement_Suspension'),
        colWidths: 150,
      },
      {
        headerText: '',
        colWidths: 70,
      },
    ];

    return (
      <TableBorder>
        <TableHeader header={acAgreementListTableHeaders} />
        <TableBody numOfCol={acAgreementListTableHeaders.length}>
          <Tr>
            <Td data={dealerAgreementInfo.dealerFinancierClientName} />
            <Td data={dealerAgreementInfo.contractNo} />
            <Td data={dealerAgreementInfo.currencyType} />
            <Td data={dealerAgreementInfo.branchCode} />
            <Td data={dealerAgreementInfo.branchName} />
            <Td data={dealerAgreementInfo.dealerAgreementStatus ? t('text:Not_Suspended') : t('text:Suspended')} />
            <TdLink
              path={ROUTES_FI.MANAGE_PARTNER.AGREEMENT_REGISTERED_DETAIL_BUILD_PATH(
                dealerAgreementInfo.dealerAgreementId,
              )}
              state={{ supportedCollateralType: dealerAgreementInfo.collateralType }}
            />
          </Tr>
        </TableBody>
      </TableBorder>
    );
  };

  return (
    <div className="content-area">
      <SectionTitle title={t('text:Partner_Master_Agreement')} />
      {renderPartnerAgreement()}
    </div>
  );
}

export default FinancierSCPartnerAcquisitionDetailPartnerMasterAgreement;
